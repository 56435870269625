// ChatbotChartCell.js
import React, { useEffect, useState } from "react";
import ChatbotPriceChart from "./PriceChart";
import { useDispatch, useSelector } from "react-redux";


// Module-level cache: keys are of the form `${type}-${symbol}`
const chartDataCache = {};

// Global concurrency limiter: allow only 3 concurrent fetches.
const MAX_CONCURRENT_FETCHES = 3;
let concurrentFetchCount = 0;
const fetchQueue = [];

function runNextFetch() {
  if (fetchQueue.length > 0 && concurrentFetchCount < MAX_CONCURRENT_FETCHES) {
    concurrentFetchCount++;
    const { fn, resolve, reject } = fetchQueue.shift();
    fn()
      .then(resolve)
      .catch(reject)
      .finally(() => {
        concurrentFetchCount--;
        runNextFetch();
      });
  }
}

function limitedFetch(fn) {
  return new Promise((resolve, reject) => {
    fetchQueue.push({ fn, resolve, reject });
    runNextFetch();
  });
}

export default function ChatbotChartCell({ type, symbol, containerStyle, endDate }) {
  const [data, setData] = useState(null);
  const csrfToken = useSelector((state) => state.csrfToken);

  // Encapsulated fetch function using our limitedFetch
  async function fetchChartData() {
    const cacheKey = `${type}-${symbol}`;
    if (chartDataCache[cacheKey]) {
      setData(chartDataCache[cacheKey]);
      return;
    }
    try {
      const resp = await limitedFetch(() =>fetch(`${window.config.base_api_url}/api/outshort/chatbot/response/${symbol}/test`, {
        headers: {
          'Content-Type': 'text/csv',
          'xsrf-token': csrfToken,
        },
      }));

      const json = await resp.json();
      console.log("Fetched chart data for symbol=", symbol, "type=", type, json);
      chartDataCache[cacheKey] = json.plot_data;
      setData(json.plot_data);
    } catch (err) {
      console.error("Error fetching chart data:", err);
    }
  }

  useEffect(() => {
    fetchChartData();
  }, [symbol, type]);

  if (!data) {
    return <div>Loading chart...</div>;
  }

  let chartTitle;
  let chartData;
  // Filter the API data so that only datapoints with time <= endDate are included.
  switch (type) {
    case "outshort":
      chartTitle = "%short";
      chartData = [
        (data.short || []).filter((item) => item.time <= endDate),
        (data.outstanding_short || []).filter((item) => item.time <= endDate)
      ];
      break;
    case "price":
      chartTitle = "Last";
      chartData = (data.price || []).filter((item) => item.time <= endDate);
      break;
    case "predict":
      chartTitle = "Predicted Trend";
      chartData = (data.flow_accum || []).filter((item) => item.time <= endDate);
      break;
    default:
      chartTitle = "Unknown";
      chartData = [];
  }

  // Use containerStyle passed as prop or a default.
  const chartContainerStyle = containerStyle || {
    width: "300px",
    height: "120px",
    border: "1px solid #ddd",
    
  };

  return (
    <div style={chartContainerStyle}>
      <ChatbotPriceChart
        priceData={chartData}
        chartTitle={chartTitle}
        symbol={symbol}
      />
    </div>
  );
}
