import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import DataTable from './DataTable';

import { Box, Typography, TextField, Button, Input } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { useIsMobile, useIsTablet, useIsPortraitTablet } from "../../components/hooks";




const Chart = ({ data, upperExceedData, lowerExceedData }) => {
  const chartContainer1Ref = useRef(null);
  const chartContainer2Ref = useRef(null);
  const chart1Ref = useRef(null);
  const chart2Ref = useRef(null);
  const series1Ref = useRef(null);
  const series2Ref = useRef(null);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();




  useEffect(() => {
    if (!data || !chartContainer1Ref.current || !chartContainer2Ref.current) return;
    const adjustToLocalTime = (timestamp) => {
      const date = new Date(timestamp * 1000);
      const timezoneOffset = date.getTimezoneOffset() * 60; // Offset in seconds
      return timestamp - timezoneOffset;
    };

    // Helper function to format and sort data by time, removing duplicates
    const formatAndSortData = (data, valueKey) => {
      const uniqueData = data
        .map(item => ({
          time: adjustToLocalTime(new Date(item.time).getTime() / 1000), // แปลงเวลาเป็น timestamp พร้อมปรับ timezone
          value: item[valueKey],
        }))
        .filter((value, index, self) => {
          return index === self.findIndex((t) => t.time === value.time);
        })
        .sort((a, b) => a.time - b.time); // เรียงข้อมูลตามเวลา

      return uniqueData;
    };

    // Create chart 1
    chart1Ref.current = createChart(chartContainer1Ref.current, {
      height: chartContainer1Ref.current.clientHeight,
      width: chartContainer1Ref.current.clientWidth,
      layout: {
        background: { type: 'solid', color: '#575b69' }, // เปลี่ยนสีพื้นหลัง
        textColor: 'white',
      },
      timeScale: {
        visible: true,
        timeVisible: true,  // ทำให้แสดงเวลา
        secondsVisible: true, // แสดงวินาที (ถ้าต้องการ)

      },
      crosshair: { mode: 1 },
      priceLineVisible: false,
      localization: {
        priceFormatter: (price) =>
          price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
      //   rightPriceScale: { visible: true }, // เปิดฝั่งขวา
      leftPriceScale: { visible: true },  // เปิดฝั่งซ้าย
    });

    series1Ref.current = chart1Ref.current.addLineSeries({
      color: '#FFD37F',
      priceLineVisible: false,
      localization: {
        priceFormatter: (price) =>
          price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScaleId: 'left',
      title: 'Last',
    });
    const lastData = formatAndSortData(data, 'last');
    series1Ref.current.setData(lastData);



    // Create chart 2
    chart2Ref.current = createChart(chartContainer2Ref.current, {
      height: chartContainer2Ref.current.clientHeight,
      width: chartContainer2Ref.current.clientWidth,
      layout: {
        background: { type: 'solid', color: '#575b69' }, // เปลี่ยนสีพื้นหลัง
        textColor: 'white',
      },

      timeScale: {
        visible: true,
        timeVisible: true,  // ทำให้แสดงเวลา
        secondsVisible: true, // แสดงวินาที (ถ้าต้องการ)
      },
      crosshair: { mode: 1 },
      priceLineVisible: false,
      localization: {
        priceFormatter: (price) =>
          price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
      rightPriceScale: { visible: true }, // เปิดฝั่งขวา
      leftPriceScale: { visible: true },  // เปิดฝั่งซ้าย
    });

    series2Ref.current = chart2Ref.current.addLineSeries({
      color: '#ffeeaa',
      priceLineVisible: false,
      localization: {
        priceFormatter: (price) =>
          price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScaleId: 'left',
      title: 'Spread Volume',
    });
    const spreadData = formatAndSortData(data, 'spread_vol');
    const upperData = formatAndSortData(data, 'spread_upper_bound');
    const lowerData = formatAndSortData(data, 'spread_lower_bound');
    const flowData = formatAndSortData(data, 'flow');

    series2Ref.current.setData(spreadData);




    // Add auxiliary lines for upper and lower bounds
    const upperLine = chart2Ref.current.addLineSeries({
      color: '#5efee8',
      lineStyle: 1,
      priceLineVisible: false,
      priceScaleId: 'left',
      title: 'Upper Spread',
    });
    const lowerLine = chart2Ref.current.addLineSeries({
      color: '#e6c2ff',
      lineStyle: 1,
      priceLineVisible: false,
      priceScaleId: 'left',
      title: 'Lower Spread',
    });

    const flowLine = chart2Ref.current.addLineSeries({
      color: '#95fe51',
      priceLineVisible: false,
      priceScaleId: 'right',
      title: 'Flow',
    });

    upperLine.setData(upperData);
    lowerLine.setData(lowerData);
    flowLine.setData(flowData);


    // Sync crosshair between two charts
    const syncCrosshair = (chart, series, dataPoint) => {
      if (dataPoint) {
        chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
      } else {
        chart.clearCrosshairPosition();
      }
    };

    chart1Ref.current.subscribeCrosshairMove(param => {
      const dataPoint = param.seriesData.get(series1Ref.current);
      syncCrosshair(chart2Ref.current, series2Ref.current, dataPoint);
    });

    chart2Ref.current.subscribeCrosshairMove(param => {
      const dataPoint = param.seriesData.get(series2Ref.current);
      syncCrosshair(chart1Ref.current, series1Ref.current, dataPoint);
    });

    // Sync visible range between two charts
    const syncVisibleRange = (source, target) => (range) => {
      if (!range) return;
      target.timeScale().setVisibleLogicalRange(range);
    };

    const source1VisibleRangeHandler = syncVisibleRange(chart1Ref.current, chart2Ref.current);
    const source2VisibleRangeHandler = syncVisibleRange(chart2Ref.current, chart1Ref.current);

    chart1Ref.current.timeScale().subscribeVisibleLogicalRangeChange(source1VisibleRangeHandler);
    chart2Ref.current.timeScale().subscribeVisibleLogicalRangeChange(source2VisibleRangeHandler);

    const resizeObserver = new ResizeObserver(() => {
      if (chart1Ref.current && chart2Ref.current) {
        chart1Ref.current.applyOptions({
          width: chartContainer1Ref.current.clientWidth,
          height: chartContainer1Ref.current.clientHeight,
        });

        chart2Ref.current.applyOptions({
          width: chartContainer2Ref.current.clientWidth,
          height: chartContainer2Ref.current.clientHeight,
        });
      }
    });

    resizeObserver.observe(chartContainer1Ref.current);
    resizeObserver.observe(chartContainer2Ref.current);

    return () => {
      resizeObserver.disconnect();
      chart1Ref.current.remove();
      chart2Ref.current.remove();
    };

  }, [data]);

  return (
    <div >

      {(!isMobile && !isPTablet) && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              gap: '20px',
              marginTop: '-10px',
              // marginLeft: '79px',
              // marginBottom: '10px',
            }}
          >
            {/* เส้น Last */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                width: '150px',
                marginTop: '0px',
                // marginLeft: '-105px',
                marginBottom: '10px',
                maxHeight: '40px',

                gap: '20px',
                padding: '10px 10px',
                border: '2px solid #ddd',
                borderRadius: '10px',
                // boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.7)',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span>Last :</span>
                <div
                  style={{
                    width: '50px',
                    height: '5px',
                    backgroundColor: '#FFD37F',
                  }}
                ></div>
              </div>
            </div>
            {/* เส้น volume */}
            <div
              style={{
                display: 'flex',
                // justifyContent: 'left',
                flexDirection: (isMobile || isTablet) ? 'column' : 'row',
                width: (isMobile || isTablet) ? 'unset' : '680px',
                alignContent:'center',
                justifyContent:'center',
                marginTop: '0px',
                // marginLeft: '600px',
                marginBottom: '10px',
                gap: '20px',
                padding: '10px 20px',
                border: '2px solid #ddd',
                borderRadius: '10px',
                // boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.7)',
              }}
            >

              {/* {(!isMobile && !isTablet) && (
            <>

              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span>Spread Volume :</span>
                <div
                  style={{
                    width: '50px',
                    height: '5px',
                    backgroundColor: 'purple',
                  }}
                ></div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span>Upper Spread :</span>
                <div
                  style={{
                    width: '50px',
                    height: '1px',
                    borderTop: '3px dashed green',
                  }}
                ></div>
              </div>


              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span>Lower Spread :</span>
                <div
                  style={{
                    width: '50px',
                    height: '1px',
                    borderTop: '3px dashed red',
                  }}
                ></div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span>Flow :</span>
                <div
                  style={{
                    width: '50px',
                    height: '5px',
                    backgroundColor: 'blue',
                  }}
                ></div>
              </div>

            </>
          )} */}
              {(!isPTablet || isTablet) && (
                <>
                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <span>Spread Volume :</span>
                      <div
                        style={{
                          width: '50px',
                          height: '5px',
                          backgroundColor: '#ffeeaa',
                        }}
                      ></div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <span>Upper Spread :</span>
                      <div
                        style={{
                          width: '50px',
                          height: '1px',
                          borderTop: '3px dashed #5efee8',
                        }}
                      ></div>
                    </div>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <span>Lower Spread :</span>
                      <div
                        style={{
                          width: '50px',
                          height: '1px',
                          borderTop: '3px dashed #e6c2ff',
                        }}
                      ></div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <span>Flow :</span>
                      <div
                        style={{
                          width: '50px',
                          height: '5px',
                          backgroundColor: '#95fe51',
                        }}
                      ></div>
                    </div>
                  </Box>
                </>
              )}




            </div>


          </div>

          <div style={{
            display: 'flex', gap: '10px', padding: '0px',
            // marginLeft: '-25px'
          }}>
            {(!isMobile && !isPTablet) && (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  <div
                    ref={chartContainer1Ref}
                    style={{
                      width: '100%',
                      height: (isMobile || isTablet) ? '200px' : '400px',
                      marginTop: '10px',
                      boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3), -5px -5px 5px rgba(255, 255, 255, 0.7)',
                      borderRadius: '16px', // ปรับความมนของกรอบ
                      overflow: 'hidden', // ป้องกันเนื้อหาล้นออกมา
                      border: '2px solid #ffffff33', // เส้นขอบโปร่งใส
                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', // เงานุ่ม ๆ3
                    }}
                  ></div>
                  <DataTable exceedData={upperExceedData} title="Spread Volume > Upper Bound" />
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                  <div
                    ref={chartContainer2Ref}
                    style={{
                      width: '100%',
                      height: (isMobile || isTablet) ? '200px' : '400px',
                      marginTop: '10px',
                      boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.3), -5px -5px 5px rgba(255, 255, 255, 0.7)',
                      borderRadius: '16px', // ปรับความมนของกรอบ
                      overflow: 'hidden', // ป้องกันเนื้อหาล้นออกมา
                      border: '2px solid #ffffff33', // เส้นขอบโปร่งใส
                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', // เงานุ่ม ๆ
                    }}
                  ></div>
                  <DataTable exceedData={lowerExceedData} title="Spread Volume < Lower Bound" />
                </Box>
              </>
            )}


          </div>
        </>
      )}
      {(isMobile || isPTablet) && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
              gap: '20px',
              marginTop: '-10px',
              // marginLeft: '79px',
              // marginBottom: '10px',
            }}
          >
            {/* เส้น Last */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                width: '150px',
                marginTop: '0px',
                // marginLeft: '-105px',
                marginBottom: '10px',
                maxHeight: '40px',

                gap: '20px',
                padding: '10px 10px',
                border: '2px solid #ddd',
                borderRadius: '10px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.7)',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <span>Last :</span>
                <div
                  style={{
                    width: '50px',
                    height: '5px',
                    backgroundColor: 'orange',
                  }}
                ></div>
              </div>
            </div>
            {/* เส้น volume */}
            <div
              style={{
                display: 'flex',
                // justifyContent: 'left',
                flexDirection: (isMobile || isTablet) ? 'column' : 'row',
                width: (isMobile || isTablet) ? 'unset' : '700px',
                marginTop: '0px',
                // marginLeft: '600px',
                marginBottom: '10px',
                gap: '20px',
                padding: '10px 20px',
                border: '2px solid #ddd',
                borderRadius: '10px',
                boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.7)',
              }}
            >
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <span>Spread Volume :</span>
                  <div
                    style={{
                      width: '50px',
                      height: '5px',
                      backgroundColor: 'purple',
                    }}
                  ></div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <span>Upper Spread :</span>
                  <div
                    style={{
                      width: '50px',
                      height: '1px',
                      borderTop: '3px dashed green',
                    }}
                  ></div>
                </div>
              </Box>
              <Box sx={{ display: 'flex', gap: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <span>Lower Spread :</span>
                  <div
                    style={{
                      width: '50px',
                      height: '1px',
                      borderTop: '3px dashed red',
                    }}
                  ></div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <span>Flow :</span>
                  <div
                    style={{
                      width: '50px',
                      height: '5px',
                      backgroundColor: 'blue',
                    }}
                  ></div>
                </div>
              </Box>
            </div>
          </div>

          <div style={{
            display: 'flex', gap: '10px', padding: '0px',
            // marginLeft: '-25px'
          }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div
                ref={chartContainer1Ref}
                style={{
                  width: '100%',
                  height: (isMobile || isTablet) ? '200px' : '400px',
                  marginTop: '10px',
                  // boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.3), 0px 0px 5px rgba(255, 255, 255, 0.4)',
                }}
              ></div>
              <DataTable exceedData={upperExceedData} title="Spread Volume > Upper Bound" />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div
                ref={chartContainer2Ref}
                style={{
                  width: '100%',
                  height: (isMobile || isTablet) ? '200px' : '400px',
                  marginTop: '10px',
                  // boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.3), 0px 0px 5px rgba(255, 255, 255, 0.4)',
                }}
              ></div>
              <DataTable exceedData={lowerExceedData} title="Spread Volume < Lower Bound" />
            </Box>
          </div>
        </>
      )
      }

    </div>
  );
};

export default Chart;