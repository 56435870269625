import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';

// const LightweightChart = ({ options, seriesOptions, title }) => {
const LightweightChart = forwardRef(({ options, seriesOptions, title }, ref) => {
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const seriesRefs = useRef([]);

    useEffect(() => {
        const chart = createChart(chartContainerRef.current, options);
        chartRef.current = chart;

        seriesOptions.forEach((seriesOption, index) => {
            // console.log(title, seriesOption.data.length)
            if (seriesOption.data.length <= 0) {

                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 20,
                        horzAlign: 'top',
                        vertAlign: 'center',
                        color: 'rgba(255, 255, 255, 0.5)',
                        text: `${title}:ไม่มีข้อมูล`,
                        fontFamily: 'Lato, Sans-serif',
                    },
                });
            } else {

                const lineSeries = chart.addLineSeries(seriesOption.options);
                lineSeries.setData(seriesOption.data);
                seriesRefs.current[index] = lineSeries;

                lineSeries.applyOptions({
                    priceFormat: {
                        type: 'custom',
                        formatter: (price) => {
                            let shortenedPrice = price / 1_000_000_000;
                            return shortenedPrice.toLocaleString('en-US', {
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                            }) + 'B';

                        },
                    },

                });
                chart.applyOptions({
                    watermark: {
                        visible: true,
                        fontSize: 20,
                        horzAlign: 'center',
                        vertAlign: 'top',
                        color: 'rgba(255, 255, 255, 0.5)',
                        text: `${title}`,
                        fontFamily: 'Lato, Sans-serif',
                    },
                });
            }




        });

        return () => {
            chart.remove();
        };
    }, [options, seriesOptions]);

    useImperativeHandle(ref, () => ({
        getChartRef: () => chartRef.current,
        getSeriesRefs: () => seriesRefs.current,
    }));

    return <div ref={chartContainerRef} style={{ width: '100%', height: '300px' }} />;

});

export default LightweightChart;
