import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ChartSectionWithMultipleLinesPre from './ChartSectionWithMultipleLinesPre';
import { Box, Grid, Typography } from '@mui/material';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../../components/hooks';

const DashboardNonOut = () => {
  const location = useLocation(); // ดึงข้อมูลจาก URL
  const navigate = useNavigate(); // ใช้ในการเปลี่ยน URL
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const dateParam = queryParams.get('date');

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  // แปลง date ที่ได้รับจาก URL
  const initialDate = dateParam ? new Date(dateParam) : new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate); // ใช้ initialDate จาก URL
  const [data, setData] = useState(null); // ใช้เก็บข้อมูลที่ดึงจาก API
  const [isLoading, setIsLoading] = useState(false); // เช็คสถานะกำลังโหลดข้อมูล
  const chartConfigs = [
    { title: 'NON-SET100 Up', market: 'NON-SET100', type: 'in' },
  ];

  // ฟังก์ชันดึงข้อมูลจาก API
  const fetchDataForSelectedDate = async (date) => {
    try {
      setIsLoading(true);
      const formattedDate = date.toISOString().split('T')[0]; // แปลงวันที่เป็น yyyy-MM-dd
      const response = await fetch(`${window.config.base_api_url}/money_in_out/hist/${market}?date=${formattedDate}`);
      const result = await response.json();
      setData(result); // เก็บข้อมูลที่ได้จาก API
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // เรียกใช้ฟังก์ชันดึงข้อมูลเมื่อวันที่เปลี่ยน
  useEffect(() => {
    fetchDataForSelectedDate(selectedDate);
  }, [selectedDate]);

  // ฟังก์ชันเปลี่ยนวันที่และอัปเดต URL
  const handleDateChange = (date) => {
    setSelectedDate(date);

    // อัปเดต `date` ใน URL โดยรักษาค่าอื่น ๆ
    const formattedDate = date.toISOString(); // แปลงเป็นรูปแบบ ISO
    queryParams.set('date', formattedDate); // เปลี่ยนค่า `date`
    const newUrl = `${location.pathname}?${queryParams.toString()}`;

    navigate(newUrl); // เปลี่ยน URL
    window.location.reload(); // รีเฟรชหน้า
  };

  const chartGridStyle = {
    display: 'grid',
    // gridTemplateColumns: isTablet ? '1fr' : '1fr 1fr',
    gridTemplateColumns: '1fr',
    gap: '20px',
  };

  // CSS-in-JS สำหรับการตั้งค่าปุ่มเลือกวันที่ให้ติดบนสุด
  const datePickerStyle = {
    position: 'sticky',
    top: '0', // ติดอยู่ที่บนสุด
    zIndex: '1000', // ให้มีลำดับการแสดงผลสูง
    marginTop: '-20px',
    padding: '10px 0', // การตั้งค่าระยะห่าง
    marginBottom: isPTablet || isMobile ? '0px' : '20px', // ระยะห่างจากเนื้อหาข้างล่าง
    textAlign: 'left', // จัดตําแหน่งข้อความให้อยู่ซ้าย
  };

  return (
    <div className="dashboard">
      {/* <h1 style={{ marginLeft: '540px', fontFamily: 'Arial, Helvetica, sans-serif' }}>
        Ideatradepoint history
      </h1> */}
      <Box className="Header-dateInput" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant='h3' sx={{ display: 'flex', justifyContent: 'center', fontFamily: 'Lato, Helvetica, sans-serif', fontWeight: 'bold', padding: '10px 0 20px 0', }}>Ideatradepoint history</Typography>
        {/* ปุ่มเลือกวันที่อยู่บนสุด */}
        <div className="date-picker-container" style={datePickerStyle}>
          <label htmlFor="date-picker" style={{ fontFamily: 'Lato, Helvetica, sans-serif' }}>
            Select Date :
          </label>
          <input
            id="date-picker"
            type="date"
            value={selectedDate.toISOString().split('T')[0]} // แสดงวันที่ในรูปแบบ yyyy-MM-dd
            onChange={(e) => handleDateChange(new Date(e.target.value))} // เปลี่ยนวันที่ใน State และรีเฟรชหน้า
          />
        </div>

      </Box>


      {/* แบ่งแถว 2 คอลัมน์ */}
      <div className="chart-grid" style={chartGridStyle}>
        {chartConfigs.map((config, index) => (
          <ChartSectionWithMultipleLinesPre
            key={index}
            title={config.title}
            market={config.market}
            type={config.type}
            selectedDate={selectedDate} // ส่งวันที่ไปเป็น props
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardNonOut;