import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import {
    useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, useMediaQuery,
    FormControl, InputLabel, Select, MenuItem, Popover

} from '@mui/material';
import Header from "../../components/Header";
import LightweightChart from "../../components/LightweightChart";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import CardContent from '@mui/material/CardContent';
import MenuIcon from '@mui/icons-material/Menu';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import StatBox from "../../components/StatBox";
import EmailIcon from "@mui/icons-material/Email";

import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import { __fetching_data__ } from '../../components/api.js';

import dayjs from 'dayjs';

import { Formik } from 'formik'
import * as yup from "yup";


import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import TableContainer from '@mui/material/TableContainer';
import Slider from '@mui/material/Slider';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ZoomInIcon from '@mui/icons-material/ZoomIn'; // ไอคอนสำหรับปุ่มแว่นขยาย

// import Preloader from '../../components/Preloader';
import Preloading from '../../components/Preloading'

import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';

// import { useProSidebar } from "react-pro-sidebar"; 
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';

const generateData = (data, date_columm = 'datetime', field, haveTime = false) => {
    const timeZoneOffset = 7 * 60 * 60 * 1000;
    const cdata = data.map(d => {
        let time = timeToTz(d[date_columm], 'Asia/Bangkok')

        const dd = new Date(d[date_columm])
        const year = dd.getFullYear();
        const month = String(dd.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
        const day = String(dd.getDate()).padStart(2, '0');
        const hour = String(dd.getHours()).padStart(2, '0');
        const minute = String(dd.getMinutes()).padStart(2, '0');
        const second = String(dd.getSeconds()).padStart(2, '0');

        // let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000
        let time2 = `${year}-${month}-${day}`;
        if (haveTime) {
            // time2 = `${time2} ${hour}:${minute}:${second}`;
            time2 = Math.floor((dd.getTime() + timeZoneOffset) / 1000)
        }

        return { time: time2, value: parseFloat(d[field]) || 0 }
    });
    return cdata;
}
function timeToTz(originalTime, timeZone) {
    const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
    // const options = {
    //     timeZone: timeZone,
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     second: 'numeric',
    //     hour12: false,
    // };
    // const formatter = new Intl.DateTimeFormat('en-US', options);
    return zonedDate.getTime() / 1000;
}

function syncCrosshair(chart, series, dataPoint) {
    if (dataPoint) {
        chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
        return;
    }
    chart.clearCrosshairPosition();
}

const getCrosshairDataPoint = (series, param) => {
    if (!param.time) {
        return null;
    }
    const dataPoint = param.seriesData.get(series);
    return dataPoint || null;
};

function generateDarkColorsFromArrayLength(len) {
    const colors = [];
    function generateColor() {
        let r, g, b;
        r = Math.floor(Math.random() * 200);
        g = Math.floor(Math.random() * 200);
        b = Math.floor(Math.random() * 200);
        while (r === 0 && g === 0 && b === 0) {
            r = Math.floor(Math.random() * 200);
            g = Math.floor(Math.random() * 200);
            b = Math.floor(Math.random() * 200);
        }
        return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1).toUpperCase()}`;
    }
    for (let i = 0; i < len; i++) {
        // colors.push(generateColor());
        colors.push(i % 2 === 0 ? "#82f755" : "#f8c953");
    }
    return colors;
}

const formatDate = (date) => {
    if (typeof date === 'object') {
        return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
    }
    return date;
};

const SectorRotation = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const csrfToken = useSelector((state) => state.csrfToken);
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');

    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isPTablet = useIsPortraitTablet();

    // const isSmallScreen = useMediaQuery('(max-width:600px)');
    // const isLargeScreen = useMediaQuery('(min-width:1200px)');

    const chartRefs = useRef([]);
    const chartContainerRefs = useRef([]);
    const [seriesOptions, setSeriesOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('SET');

    const sectors_set = [
        'AGRI',
        'AUTO', 
        'BANK', 'COMM', 'CONMAT',
        'CONS', 'ENERG', 'ETRON', 'FASHION', 'FIN',
        'FOOD', 'HELTH', 'HOME', 'ICT', 'IMM',
        'INSUR', 'MEDIA', 'MINE', 'PAPER', 'PERSON',
        'PETRO', 'PF&REIT', 'PKG', 'PROF', 'PROP',
        'REHABCO', 'STEEL', 'TOURISM', 'TRANS'

    ];
    // ['AGRO', 'AUTO', 'BANK', 'COMM', 'CONS', 'CONSUMP', 'ENERG', 'FASHION', 'FIN', 'FINCIAL', 'FOOD', 'HELTH', 'HOME', 'ICT', 'IMM',
    //      'INDUS', 'INSUR', 'MEDIA', 'PERSON', 'PETRO', 'PF&REIT', 'PKG', 'PROP', 'PROPCON', 'RESOURC', 'SERVICE', 'STEEL', 'TECH', 'TOURISM', 'TRANS']
    const sectors_mai = ['AGRO', 'CONSUMP', 'FINCIAL', 'INDUS', 'PROPCON', 'RESOURC', 'SERVICE', 'TECH'];
    const sectors_set_and_mai = ['SET', 'MAI'];
    const [sectors, setSectors] = useState(sectors_set);
    // const sectors = sectors.slice(0, 1);
    const [sectorsChunks, setSectorsChunks] = useState([]);
    const [selectedDate, setSelectedDate] = useState(now);
    const [itemsPerRow, setItemsPerRow] = useState(2);
    const getItemsPerRow = (chartCount) => {
        if (chartCount >= 10) {
            return window.innerWidth > 1366 ? 7 : window.innerWidth > 900 ? 5 : 2;
        } else if (chartCount === 8) {
            return 2; // 2 คอลัมน์ 4 แถว
        } else if (chartCount === 2) {
            return 2; // 1 แถว 2 คอลัมน์
        } else {
            return window.innerWidth > 1366 ? 7 : window.innerWidth > 900 ? 5 : 2;
        }
    };

    const [chartData, setChartData] = useState(sectors.map(() => []));
    const [chartSymbolList, setChartSymbolList] = useState(sectors.map(() => []));
    const [chartDataPrice, setChartDataPrice] = useState(sectors.map(() => []));

    const [loading, setLoading] = useState(true);
    const [isReady, setIsReady] = useState(false);

    let start_td = 0;
    const commonOptions = {
        width: 150,
        height: 200,

        crosshair: {
            mode: 0
        },
        timeScale: {
            visible: true,
            timeVisible: true,
            secondsVisible: true,
        },
        localization: {
            locale: "en-US"
        },
        layout: {
            background: {
                type: 'solid',
                color: '#575b69',
            },
            textColor: '#ffffff',
        },
        grid: {
            vertLines: { color: '#323856' },
            horzLines: { color: '#323856' },
        },
        title: {
            color: 'white',
        },
        rightPriceScale: {
            visible: false,
        },
        leftPriceScale: {
            visible: false,
        },
        localization: {
            locale: 'th-TH',
        },
    };

    const content = document.getElementsByClassName('content')[0];
    const resizeHandler = () => {
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef && chartRef.getChartRef) {
                const chart = chartRef.getChartRef();
                try {
                    const container = chartContainerRefs.current[index]
                    if (container) {
                        chart.resize(container.clientWidth, container.clientHeight);
                    }
                } catch (error) {
                    console.error('Error resizing chart:', error);
                }
            }
        });
    };

    useEffect(() => {
        if (content) {
            const resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver.observe(content);
            return () => {
                if (resizeObserver) {
                    resizeObserver.disconnect();
                }
            };
        }
    }, [content]);

    useEffect(() => {
        // console.log(today)
        const fetchData = async () => {
            try {
                const responses = await Promise.all(
                    sectors.map((sector) => {
                        let paramsQuery = {
                            market: selectedOption,
                            sector: encodeURIComponent(sector),
                            date: formatDate(selectedDate),
                        };
                        // console.log(paramsQuery)
                        return fetch(`${window.config.base_api_url}/sector_rotation/intraday?${new URLSearchParams(paramsQuery)}`)
                            .then((res) => {
                                if (!res.ok) {
                                    return [];
                                }
                                return res.json().then((data) => {
                                    return data.data ? data : [];
                                });
                            })
                            .catch(() => { return []; });
                    })
                );
            
                return responses
            } catch (error) {
            } finally {
            }
        };
        const fetchDataAndUpdateState = async () => {
            setLoading(true);
            const responses = await fetchData();
            const updatedData = responses
                .map((data) => data?.data?.data || [])

            const updatedSymbols = responses
                .map((data) => data?.data?.symbols || [])

            const updatedDataPrice = responses
                .map((data) => data?.data?.price || [])

            const newItemsPerRow = getItemsPerRow(updatedData.length);
            setItemsPerRow(newItemsPerRow);

            const colors = generateDarkColorsFromArrayLength(newItemsPerRow);

            const line_option = colors.map((color, index) => ({
                ...(seriesOptions[index] || {}),
                'color': color,
                'lineWidth': 3
            }));

            setSeriesOptions(line_option);
            setChartData(updatedData);
            setChartSymbolList(updatedSymbols);
            setChartDataPrice(updatedDataPrice);
            setSectorsChunks(Array.from({ length: Math.ceil(sectors.length / itemsPerRow) }, (_, i) => sectors.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow)));

        }

        fetchDataAndUpdateState();
        setLoading(false);
    }, [selectedDate, selectedOption]);

    useEffect(() => {
        // console.log("Chart Length:", chartRefs.current.length)
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef && chartRef.getChartRef) {
                // console.log(`Chart ${index + 1} instance:`, chartRef);
                const chart = chartRef.getChartRef();
                const series = chartRef.getSeriesRefs();

                const container = chartContainerRefs.current[index];
                if (container) {
                    container.style.outline = "2px solid #9b9b9b"; // สีขอบ
                    container.style.borderRadius = "10px"; // ขอบมน
                    container.style.overflow = "hidden"; // ซ่อนส่วนที่เกินออกจากกราฟ
                    container.style.boxSizing = "border-box"; // เพื่อให้กราฟไม่ล้นออกจากขอบ

                    // คำนวณจำนวนแถวและคอลัมน์ตามจำนวนกราฟในหน้า
                    const itemsPerRow = getItemsPerRow(chartData.length); // คำนวณคอลัมน์
                    const rows = Math.ceil(chartData.length / itemsPerRow); // คำนวณแถว
                    const containerHeight = window.innerHeight; // ความสูงเต็มจอ
                    // console.log('containerHeight = ', containerHeight);

                    // ปรับขนาดกราฟให้เหมาะสม โดยลดความสูงลงเล็กน้อย
                    const itemHeight = (containerHeight / rows) * 0.8; // ลดความสูงลง 10%
                    // console.log('itemHeight = ', itemHeight);
                    container.style.height = (isMobile || isPTablet) ? '200px' : `${itemHeight}px`; // ปรับความสูงของกราฟตามจำนวนแถว

                    // ปรับขนาดกราฟ
                    const width = container.clientWidth;
                    const height = container.clientHeight;
                    chart.resize(width, height);
                }

                const width = container.clientWidth;
                const height = container.clientHeight;
                chart.resize(width, height);

                chart.subscribeCrosshairMove(param => {
                    const dataPoint = getCrosshairDataPoint(series[0], param);
                    chartRefs.current.forEach((_, otherIndex) => {
                        if (otherIndex !== index) {
                            if (chartRefs.current && chartRefs.current[otherIndex] && chartRefs.current[otherIndex].getChartRef) {
                                const otherChart = chartRefs.current[otherIndex].getChartRef();
                                const otherSeries = chartRefs.current[otherIndex].getSeriesRefs();
                                otherSeries.forEach(otherSeries => {
                                    syncCrosshair(otherChart, otherSeries, dataPoint);
                                });
                            }
                        }
                    });
                });

                chart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                    chartRefs.current.forEach((_, otherIndex) => {
                        if (otherIndex !== index) {
                            if (chartRefs.current && chartRefs.current[otherIndex] && chartRefs.current[otherIndex].getChartRef) {
                                const otherChart = chartRefs.current[otherIndex].getChartRef();
                                otherChart.timeScale().setVisibleLogicalRange(timeRange);
                            }
                        }
                    });
                });
                if (series[0]) {
                    const seriesData = series[0].data();
                    const lastTime = new Date(seriesData[seriesData.length - 1].time).getTime();
                    const firstTime = new Date(seriesData[0].time).getTime();

                    const visibleRangeSize = 50//1 * 24 * 60 * 60 * 1000;
                    const from = Math.max(lastTime - visibleRangeSize, firstTime);
                    const to = lastTime;

                    chart.timeScale().setVisibleLogicalRange({ from, to });
                    chart.timeScale().scrollToPosition(4);
                    // chart.timeScale().fitContent();
                }

            }
        });
        const timeout_ready = setTimeout(() => {
            setIsReady(true);
        }, 100);

        return () => {
            // chartRefs.current.forEach(chartRef => {
            //     if (chartRef && chartRef.getChartRef) {
            //         const chart = chartRef.getChartRef();
            //         // chart.unsubscribeAll();
            //     }
            // });
            clearInterval(timeout_ready);
        };
    }, [chartData, selectedOption]);


    useEffect(() => {
        console.log(selectedDate)
        const isToday = selectedDate.isSame(dayjs(), 'day');
        console.log(isToday)
      
        const fetchDataRealtime = async () => {
            const responses = await Promise.all(chartRefs.current.map((chartRef, index) => {
                if (!chartRef || !chartRef.getChartRef) return null;
                let paramsQuery = {
                    market: selectedOption,
                    sector: encodeURIComponent(sectors[index]),
                    date: formatDate(selectedDate),
                };
                let url = `${window.config.base_api_url}/sector_rotation/intraday?${new URLSearchParams(paramsQuery)}`;
                return fetch(url).then(res => res.json());
            }));

            chartRefs.current.forEach((chartRef, index) => {
                if (!chartRef || !chartRef.getChartRef || !responses[index]) return;

                const data = responses[index];
                if (data?.data?.length > 0) {
                    let x = generateData(data.data, 'datetime', 'cumsum', true);
                    console.log(x)
                    series.forEach(s => {
                        try {
                            s.setData(x);
                        } catch (error) {
                            console.error("Error updating series:", error);
                        }
                    });
                }
            });


        }

        if (isToday) {
            const interval = setInterval(fetchDataRealtime, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [isReady, selectedDate, selectedOption]);

    const moveToLastData = () => {
        chartRefs.current.forEach((chartWrapper, index) => {
            const chart = chartWrapper.getChartRef();
            const series = chartWrapper.getSeriesRefs();

            if (series.length > 0) {
                const seriesData = series[0].data();

                if (seriesData.length > 0) {
                    const lastTime = new Date(seriesData[seriesData.length - 1].time).getTime();
                    const firstTime = new Date(seriesData[0].time).getTime();

                    const visibleRangeSize = 50//1 * 24 * 60 * 60 * 1000;
                    const from = Math.max(lastTime - visibleRangeSize, firstTime);
                    const to = lastTime;

                    chart.timeScale().setVisibleLogicalRange({ from, to });
                    chart.timeScale().scrollToPosition(2); //LineMai
                    // chart.timeScale().fitContent();
                }
            }
        });
    };

    const moveToFirstData = () => {
        chartRefs.current.forEach((chartWrapper) => {
            const chart = chartWrapper.getChartRef();
            const series = chartWrapper.getSeriesRefs();
            if (series.length > 0) {
                const seriesData = series[0].data();
                if (seriesData.length > 0) {
                    const firstTime = new Date(seriesData[0].time).getTime();
                    const visibleRangeSize = 7 * 24 * 60 * 60 * 1000;

                    const from = firstTime;
                    const to = from + visibleRangeSize;
                    chart.timeScale().setVisibleLogicalRange({
                        from: 1,
                        to: 100,
                    });
                    chart.timeScale().scrollToPosition(-seriesData.length + 85);
                }
            }
        });
    };

    const handleChange = (event) => {
        setLoading(true);
        const value = event.target.value
        chartRefs.current = [];
        chartContainerRefs.current = [];

        setTimeout(() => {
            setSelectedOption(value);
            setSectors(value === 'SET' ? sectors_set : value === 'MAI' ? sectors_mai : sectors_set_and_mai);
        }, 500);
    };

    return (
        <Box m="20px">
            <Preloading open={loading} />

            {(!isMobile && !isTablet) && (
                <>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Box
                            sx={{
                                display: "flex",
                                flex: "1",
                                justifyContent: "center",
                                ml: "1rem"
                            }}>
                            <Box mb="30px" width={"50%"}>
                                {/* <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}> */}
                                <FormControl sx={{ m: 1, minWidth: 120  }} size="small" >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DatePicker
                                            id="date"
                                            name="date"
                                            label="Select Date"
                                            size='small'
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={selectedDate}
                                            // maxDate={today_value}
                                            onChange={(value) => {
                                                setSelectedDate(value)
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                                {/* </Grid> */}
                            </Box>
                            <Box mb="30px" width={"100%"}>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
                                    <InputLabel id="dropdown-label">Select Market</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        value={selectedOption}
                                        onChange={handleChange}
                                        label="Select Market"
                                    >
                                        <MenuItem value="SET">SET</MenuItem>
                                        <MenuItem value="MAI">MAI</MenuItem>
                                        <MenuItem value="SET_MAI">SET&MAI</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        <Box flex="1" display="flex" justifyContent="center">
                            <Header title="Sector Rotation Intraday" subtitle="" />
                        </Box>
                        <Box flex="1" display="flex" justifyContent="end"
                            sx={{
                                mr: "1rem"
                            }}>
                            <Box mb="30px">
                                <Button
                                    onClick={moveToFirstData}
                                    sx={{
                                        variant: "h2",
                                        backgroundColor: colors.greenAccent[700],
                                        color: colors.grey[100],
                                        // fontSize: "14px",
                                        fontWeight: "bold",
                                        // padding: "10px 20px",
                                        m: "0 0 5px 0"
                                    }}
                                >
                                    First Data
                                </Button>
                            </Box>
                            <Box mb="30px" ml="3px">
                                <Button
                                    onClick={moveToLastData}
                                    sx={{
                                        variant: "h2",
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        // fontSize: "14px",
                                        fontWeight: "bold",
                                        // padding: "10px 20px",
                                        m: "0 0 5px 0"
                                    }}
                                >
                                    Last Data
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
            {(isMobile || isTablet) && (
                <>
                    <Box display="flex" flexDirection='column' justifyContent="space-between" alignItems="center" width="100%">

                        <Box flex="1" display="flex" justifyContent="center">
                            {/* <Header title="Sector Rotation" /> */}
                            <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                                Sector Rotation
                            </Typography>
                        </Box>
                        <Box flex="1" display="flex" justifyContent="center"
                            sx={{
                                // mr: "1rem"
                                alignItems: 'center'
                            }}>

                            <Box mb="30px" width={"100%"}>
                                <FormControl sx={{ m: 0.5, minWidth: 120 }} size="small" >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DatePicker
                                            id="date"
                                            name="date"
                                            label="Select Date"
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={selectedDate}
                                            // maxDate={today_value}
                                            onChange={(value) => {
                                                setSelectedDate(value)
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormControl>
                            </Box>

                            <Box
                                mb="30px"
                                width={"100%"}>
                                <FormControl sx={{ m: 0.5, minWidth: 120 }} size="small" >
                                    <InputLabel id="dropdown-label">Select Market</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        value={selectedOption}
                                        onChange={handleChange}
                                        label="Select Market"
                                    >
                                        <MenuItem value="SET">SET</MenuItem>
                                        <MenuItem value="MAI">MAI</MenuItem>
                                        <MenuItem value="SET_MAI">SET&MAI</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box
                            mb="30px"
                            >
                                <Button
                                    onClick={moveToFirstData}
                                    sx={{
                                        variant: "h2",
                                        backgroundColor: colors.greenAccent[700],
                                        color: colors.grey[100],
                                        // fontSize: "14px",
                                        fontWeight: "bold",
                                        // padding: "10px 20px",
                                        m: "0 0 5px 0",
                                        width: '80px'
                                    }}
                                >
                                    First Data
                                </Button>
                            </Box>
                            <Box
                                mb="30px"
                                ml="3px">
                                <Button
                                    onClick={moveToLastData}
                                    sx={{
                                        variant: "h3",
                                        backgroundColor: colors.blueAccent[700],
                                        color: colors.grey[100],
                                        // fontSize: "14px",
                                        fontWeight: "bold",
                                        // padding: "10px 20px",
                                        m: "0 0 5px 0",
                                        width: '80px'
                                    }}
                                >
                                    Last Data
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}

            <Box p="0" display="Box"
                sx={{
                    height:
                        {
                            xs: '80vh', // height for extra-small screens
                            sm: '85vh', // height for small screens
                            md: '90vh', // height for medium screens
                            lg: '90vh', // height for large screens
                            xl: '90vh', // height for extra-large screens
                        },
                        // '750px',

                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '-1rem',
                }}>
                <Container maxWidth={false} sx={{
                    flex: 1, display: 'flex', flexDirection: 'column',
                    overflowY: 'auto',


                    maxHeight: {
                        xs: '80vh', // height for extra-small screens
                        sm: '85vh', // height for small screens
                        md: '80vh', // height for medium screens
                        lg: '90vh', // height for large screens
                        xl: '100vh', // height for extra-large screens
                    },
                }}>
                    <Paper
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            '::-webkit-scrollbar': {
                                width: '5px',
                            },
                            '::-webkit-scrollbar-thumb': {
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                            },
                            '::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#555',
                            },
                            width: '100%',
                            maxWidth: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,
                            maxHeight: (isTablet && !isPTablet) ? '75vh' : '90vh',
                            maxHeight: {
                                // xs: '50vh', // height for extra-small screens
                                // sm: '60vh', // height for small screens
                                // xs: '90vh',
                                // sm: '90vh',
                                // md: '90vh', // height for medium screens
                                // lg: '90vh', // height for large screens
                                // xl: '100vh', // height for extra-large screens
                            },
                        }}
                    >

                        {!loading && sectorsChunks.map((_r, row) => {
                            const currentTd = start_td;
                            start_td += itemsPerRow;
                            const filledData = chartData.slice(currentTd, currentTd + itemsPerRow)

                            return (
                                <>
                                    <Grid container direction="row" spacing={0} key={"chart_row" + row} wrap="nowrap"
                                        sx={{
                                            width: '100%',
                                            margin: 0,
                                            padding: 0,
                                            marginTop: '-5px',
                                            ustifyContent: 'space-between',
                                            alignItems: 'flex-start',

                                        }}

                                    >
                                        {filledData.map((data, index) => {
                                            const dataIndex = currentTd + index;
                                            return (
                                                <Grid item key={"chart_number" + dataIndex}
                                                    xs={12} md={12 / itemsPerRow}
                                                    sx={{

                                                        padding: '3px',
                                                        margin: '5px',
                                                        // border: '0.5px solid #9b9b9b',
                                                        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                                                        borderRadius: '4px',
                                                        paddingX: '1px',
                                                        position: 'relative',
                                                        marginLeft: '-1px',
                                                    }}

                                                >

                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            padding: 0,
                                                            margin: 0,
                                                            height: '8rem',
                                                            position: 'relative',

                                                        }}
                                                        ref={(el) => (chartContainerRefs.current[dataIndex] = el)}
                                                    >

                                                        {(() => {
                                                            let seriesOptions_second_scale = [
                                                                {
                                                                    options: {
                                                                        color: seriesOptions[index].color || 'black',
                                                                        lineWidth: seriesOptions[index].lineWidth || 1,
                                                                        priceLineVisible: false,
                                                                        priceScaleId: "right",
                                                                    },
                                                                    data: generateData(chartData[dataIndex], 'datetime', 'cumsum', true),
                                                                }
                                                            ];

                                                            // if (chartDataPrice && chartDataPrice[dataIndex]) {
                                                            //     seriesOptions_second_scale[0]['options']['title'] = "flow";
                                                            //     seriesOptions_second_scale.push({
                                                            //         options: {
                                                            //             color: "#5efee8",
                                                            //             lineWidth: seriesOptions[index].lineWidth || 1,
                                                            //             priceLineVisible: false,
                                                            //             priceScaleId: "left",
                                                            //             title: "price"
                                                            //         },
                                                            //         data: generateData(chartDataPrice[dataIndex], 'datetime', 'value', true),
                                                            //     });
                                                            // }

                                                            return (
                                                                <>
                                                                    <LightweightChart
                                                                        ref={(el) => (chartRefs.current[dataIndex] = el)}
                                                                        options={{ ...commonOptions, fontSize: 32, textColor: 'white' }}
                                                                        seriesOptions={seriesOptions_second_scale}
                                                                        title={sectors[dataIndex]}
                                                                    />

                                                                    <Box
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: 2,
                                                                            right: 2,
                                                                            zIndex: 10,
                                                                        }}
                                                                    >
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                console.log(chartDataPrice[dataIndex])
                                                                                const allData = generateData(chartData[dataIndex], 'datetime', 'cumsum', true);
                                                                                const allDataPrice = chartDataPrice[dataIndex] ? generateData(chartDataPrice[dataIndex], 'datetime', 'value', true) : null;

                                                                                const allDataSymbolList = chartSymbolList[dataIndex];
                                                                                localStorage.setItem("chartMarket", selectedOption);
                                                                                localStorage.setItem("chartData", JSON.stringify(allData));
                                                                                localStorage.setItem("chartDataPrice", JSON.stringify(allDataPrice));
                                                                                localStorage.setItem("chartTitle", sectors[dataIndex]);
                                                                                localStorage.setItem("chartDataSymbols", JSON.stringify(allDataSymbolList));
                                                                                localStorage.setItem("chartType", "intraday");
                                                                                
                                                                                const link = `/full-chart`;
                                                                                window.open(link, "_blank");
                                                                            }}
                                                                            sx={{
                                                                                width: 25,
                                                                                height: 25,
                                                                                padding: 0,
                                                                                backgroundColor: 'transparent',
                                                                                border: 'none',
                                                                                boxShadow: 'none',
                                                                                '&:hover': {
                                                                                    backgroundColor: 'transparent',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <ZoomInIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </>
                                                            );
                                                        })()}



                                                    </Box>

                                                </Grid>

                                            )





                                        })}






                                    </Grid>
                                </>
                            );
                        })}









                    </Paper >
                </Container>
            </Box>
        </Box >
    );
}



export default SectorRotation;