import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // ใช้ hook navigate
import ChartSectionWithMultipleLines from './ChartSectionWithMultipleLines';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

import { FaSearch, FaRegWindowRestore } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยาย
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, useMediaQuery, useTheme, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LoopIcon from '@mui/icons-material/Loop';  // นำเข้า LoopIcon
import FontLoader from '../../components/FontLoader';


const DashboardHis = () => {
  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();

  const [selectedDate, setSelectedDate] = useState(new Date()); // State ของวันที่ร่วมกัน
  const navigate = useNavigate(); // hook สำหรับ navigate

  const [isCollapsed, setIsCollapsed] = useState(false);
  const chartRef = useRef(null);

  // const toggleCollapse = () => {
  //   setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  // };

  const chartConfigs = [
    { title: 'SET100  Up', market: 'SET100', type: 'in' },
    { title: 'SET100 Down', market: 'SET100', type: 'out' },
    { title: 'NON-SET100 Up', market: 'NON-SET100', type: 'in' },
    { title: 'NON-SET100 Down', market: 'NON-SET100', type: 'out' },
    { title: 'MAI Up', market: 'MAI', type: 'in' },
    { title: 'MAI Down', market: 'MAI', type: 'out' },
    { title: 'WARRANT Up', market: 'WARRANT', type: 'in' },
    { title: 'WARRANT Down', market: 'WARRANT', type: 'out' },
  ];

  const handleExpand = (title, market, type, date) => {
    // กำหนด path ตามประเภทของกราฟ
    let path = '';
    if (market === 'SET100') {
      path = type === 'in' ? '/set-in' : '/set-out';
    } else if (market === 'NON-SET100') {
      path = type === 'in' ? '/non-in' : '/non-out';
    } else if (market === 'MAI') {
      path = type === 'in' ? '/mai-in' : '/mai-out';
    } else if (market === 'WARRANT') {
      path = type === 'in' ? '/warrant-in' : '/warrant-out';
    }

    // ส่ง selectedDate ไปพร้อมกับการเปิดแท็บใหม่
    const formattedDate = date.toISOString();  // ส่ง date ในรูปแบบ ISO string
    const url = `${window.location.origin}${path}?title=${encodeURIComponent(title)}&market=${encodeURIComponent(market)}&type=${encodeURIComponent(type)}&date=${encodeURIComponent(formattedDate)}`;

    // เปิดกราฟในแท็บใหม่
    window.open(url, '_blank');


  };

  const handleRezoom = () => {
    if (!chartRef.current || !chartRef.current.timeScale) return;

    const minTime = 1610000000000;
    const maxTime = 1620000000000;

    chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
    chartRef.current.timeScale().fitContent();
    chartRef.current.timeScale().applyOptions({ rightOffset: 50 });
  };

  const itemsPerPage = 4; // จำนวนกราฟต่อหน้า

  const groupedConfigs = [];
  for (let i = 0; i < chartConfigs.length; i += itemsPerPage) {
    groupedConfigs.push(chartConfigs.slice(i, i + itemsPerPage));
  }

  return (
    <div className="dashboard" style={{ padding: isMobile || isTablet ? '10px' : '30px', marginTop: '-5px', }}>
      <FontLoader />
      <Typography variant="h3" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', fontFamily: 'Lato, Sans-serif', fontWeight: 'bold' }}>Ideatradepoint History</Typography>
      <Box className="date-picker-container" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} style={(isMobile || isTablet) ? datePickerStyle2 : datePickerStyle}>
        <label htmlFor="date-picker" style={{ fontFamily: 'Lato, Sans-serif', }}>Select Date : </label>
        <input
          id="date-picker"
          type="date"
          value={selectedDate.toISOString().split('T')[0]} // แปลง selectedDate เป็นรูปแบบ yyyy-MM-dd
          onChange={(e) => setSelectedDate(new Date(e.target.value))} // อัปเดต selectedDate เมื่อเลือกวันที่ใหม่
        />
      </Box>

      {/* แบ่งแถว 2 คอลัมน์ */}
      {/* แสดงกราฟและปุ่มแว่นขยาย */}
      <div className="chart-grid" style={isMobile || isTablet ? chartGridStyle2 : chartGridStyle}>
        {(!isMobile && !isTablet) && (<>
          {chartConfigs.map((config, index) => (
            <div key={index} style={chartItemStyle}>
              <div style={chartTitleStyle}>
                {/* ใช้ Box เพื่อจัดเรียงปุ่ม */}
                <Box display="flex" alignItems="center">
                  {/* ปุ่มแว่นขยาย */}
                  <FaSearch
                    style={{ ...iconStyle, marginRight: '40px' }}  // เพิ่ม marginRight เพื่อเว้นระยะระหว่างปุ่ม
                    onClick={() => handleExpand(config.title, config.market, config.type, selectedDate)}
                  />
                  {/* ปุ่ม Rezoom */}
                  <LoopIcon
                    style={{ ...iconStyle, color: '#febc69', fontSize: '23px' }}
                    onClick={handleRezoom}
                  />
                </Box>
              </div>


              <ChartSectionWithMultipleLines
                title={config.title}
                market={config.market}
                type={config.type}
                selectedDate={selectedDate}
              />
            </div>
          ))}
        </>)}

        {(isMobile || isPTablet) && (
          <Swiper

            style={{ maxWidth: '100%' }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onSwiper={(swiper) => {
              window.mySwiper = swiper;
            }}
          >
            {chartConfigs.reduce((result, _, index) => {
              if (index % 2 === 0) {
                // Group charts into pairs of 2
                result.push(chartConfigs.slice(index, index + 2));
              }
              return result;
            }, []).map((group, groupIndex) => (
              <SwiperSlide key={groupIndex}>
                <div style={{ display: 'flex', flexDirection: 'column', }}>
                  {group.map((config, index) => (
                    <div key={index} style={chartItemStyle}>
                      <div style={chartTitleStyle}>
                        {/* ปุ่มแว่นขยายข้างๆ ชื่อกราฟ */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', position: 'absolute', top: '15px', left: '40%' }}>
                          <FaSearch
                            style={{}}
                            onClick={() => handleExpand(config.title, config.market, config.type, selectedDate)}
                          />
                          <LoopIcon
                            style={{ color: '#febc69', fontSize: '23px' }}
                            onClick={handleRezoom}
                          />
                        </Box>
                        {/* <IconButton
                          variant="contained"
                          onClick={toggleCollapse}
                        // sx={{ marginBottom: '10px' }}
                        >
                          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton> */}
                      </div>
                      <ChartSectionWithMultipleLines
                        title={config.title}
                        market={config.market}
                        type={config.type}
                        selectedDate={selectedDate}
                      />
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}

        {(isTablet && !isPTablet) && (
          <Swiper
            style={{ width: '100%', maxWidth: '1200px' }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onSwiper={(swiper) => {
              window.mySwiper = swiper;
            }}
          >
            {groupedConfigs.map((group, pageIndex) => (
              <SwiperSlide key={pageIndex}>
                <div style={chartItemStyle2}>
                  {group.map((config, index) => (
                    <div key={index} style={chartItemStyle}>
                      <div style={chartTitleStyle}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', position: 'absolute', top: '15px', left: '45%' }}>
                          <FaSearch
                            style={{}}
                            onClick={() => handleExpand(config.title, config.market, config.type, selectedDate)}
                          />
                          <LoopIcon
                            style={{ color: '#febc69', fontSize: '23px' }}
                            onClick={handleRezoom}
                          />
                        </Box>
                      </div>
                      <ChartSectionWithMultipleLines
                        title={config.title}
                        market={config.market}
                        type={config.type}
                        selectedDate={selectedDate}
                      />
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}


      </div>
    </div>
  );
};

// CSS-in-JS สำหรับการตั้งค่าปุ่มเลือกวันที่ให้ติดบนสุด
const datePickerStyle = {
  position: 'sticky',
  top: '0',
  zIndex: '1000',
  padding: '10px 0',
  marginBottom: '20px',
  textAlign: 'left',
};

const datePickerStyle2 = {
  position: 'sticky',
  top: '0',
  zIndex: '1000',
  padding: '10px 0',
  // marginBottom: '20px',
  textAlign: 'left',
};

const chartGridStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '7px', // ช่องว่างระหว่างแถว
  columnGap: '90px', // ช่องว่างระหว่างคอลัมน์
};

const chartGridStyle2 = {
  display: 'grid',
  gridTemplateColumns: '1fr',
};

// const chartItemStyle = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'flex-start',
// };

const chartItemStyle2 = {

  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '10px'
};

const chartItemStyle = {
  position: 'relative', // ทำให้ปุ่มแว่นขยายอิงตำแหน่งจากกล่องกราฟ
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
};

const chartTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
};

// const iconStyle = {
//   // position:'fixed',
//   cursor: 'pointer',
//   marginLeft: '330px',
//   marginBottom: '-50px',
//   fontSize: '20px',
//   zIndex: '1000',
// };

const iconStyle = {
  position: 'absolute', // ทำให้ปุ่มอยู่บนสุดของกล่องกราฟ
  top: '10px', // ปรับตำแหน่งให้อยู่ขอบบน
  right: '300px', // ปรับตำแหน่งให้อยู่ขอบขวา
  cursor: 'pointer',
  fontSize: '20px',
  zIndex: '10', // ให้ปุ่มอยู่เหนือกราฟ
};

export default DashboardHis;