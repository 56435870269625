import React, { useState, useEffect, useRef, useCallback, useMemo  } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Slider, CircularProgress, Input } from '@mui/material';

import { tokens } from "../../theme";
import { __fetching_data__ } from '../../components/api.js';
import dayjs from 'dayjs';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import Preloading from '../../components/Preloading';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import dayjs from "dayjs";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

function createData(vol_bid, bid, ask, vol_ask) {
    return { vol_bid, bid, ask, vol_ask };
}

function createDataTickmatch(open, high, low, close) {
    return { open, high, low, close };
}

function convertData(data, date_column = 'time', field = 'value', timezone = true) {
    const timeZoneOffset = timezone ? 7 * 60 * 60 * 1000 : 0;
    return data.map(d => ({
        time: (new Date(d[date_column]).getTime() + timeZoneOffset) / 1000,
        value: parseFloat(d[field]) || 0,
    }));
}

// function TradingViewChart({ lineSeriesData, symbol, date, onClick }) {
//     const chartContainerRef = useRef();
//     const theme = useTheme();
//     const [allTimeRange, setAllTimeRange] = useState({ from: null, to: null });

//     useEffect(() => {
//         const chart = createChart(chartContainerRef.current, {
//             layout: {
//                 background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
//                 textColor: '#FFFFFF',
//             },
//             grid: {
//                 vertLines: { color: theme.palette.neutral.dark },
//                 horzLines: { color: theme.palette.neutral.dark },
//             },
//             rightPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 } },
//             leftPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 }, visible: false },
//             timeScale: {
//                 rightOffset: lineSeriesData.price.length < 100 ? 1 : 5,
//                 barSpacing: 3,
//                 lockVisibleTimeRangeOnResize: true,
//                 rightBarStaysOnScroll: true,
//                 borderVisible: true,
//                 visible: true,
//                 timeVisible: true,
//                 secondsVisible: true,
//                 handleScroll: false,
//             },
//             watermark: {
//                 color: theme.palette.chart.watermark,
//                 visible: true,
//                 text: (symbol || '').toUpperCase(),
//                 fontSize: 24,
//                 horzAlign: 'center',
//                 vertAlign: 'top',
//             },
//         });

//         const updateChartSize = () => {
//             if (!chartContainerRef.current) return;
//             chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight });
//         };
//         const resizeObserver = new ResizeObserver(() => requestAnimationFrame(updateChartSize));
//         resizeObserver.observe(chartContainerRef.current);
//         updateChartSize();

//         const priceLineSeries = chart.addLineSeries({ color: '#66d9ff', priceLineVisible: false, lineWidth: 2, priceScaleId: 'right' });
//         priceLineSeries.setData(lineSeriesData.price);

//         const flowLineSeries = chart.addLineSeries({ color: '#ffddaa', priceLineVisible: false, lineWidth: 2, priceScaleId: 'left', priceFormat: { type: 'volume' } });
//         flowLineSeries.setData(lineSeriesData.flow);

//         // ✅ คำนวณช่วงเวลาทั้งหมดของข้อมูล
//         const allTimes = [...lineSeriesData.price.map(d => d.time), ...lineSeriesData.flow.map(d => d.time)];
//         if (allTimes.length > 0) {
//             const minTime = Math.min(...allTimes);
//             const maxTime = Math.max(...allTimes);

//             chart.timeScale().setVisibleRange({ from: minTime, to: maxTime });
//             chart.timeScale().applyOptions({ rightOffset: 0 });
//             chart.timeScale().fitContent();
//         }

//         return () => {
//             resizeObserver.disconnect();
//             chart.remove();
//         };
//     }, [lineSeriesData, symbol, date]);
//     return (
//         <div className='flowChartDiv' style={{ position: 'relative', height: '100%', width: '100%' }}>
//             <div className='flowChart' ref={chartContainerRef} style={{ height: '100%', width: '100%' }} onClick={onClick} />
//             {/* ✅ ปุ่ม Reset Zoom */}
//             {/* <button
//                 onClick={() => {
//                     if (chartContainerRef.current && allTimeRange.from && allTimeRange.to) {
//                         chart.timeScale().setVisibleRange(allTimeRange);
//                         chart.timeScale().applyOptions({ rightOffset: 5 });
//                     }
//                 }}
//                 style={{
//                     position: 'absolute',
//                     top: '10px',
//                     right: '10px',
//                     backgroundColor: '#FF5733',
//                     color: '#FFFFFF',
//                     border: 'none',
//                     padding: '8px 12px',
//                     borderRadius: '5px',
//                     cursor: 'pointer',
//                     transition: 'background-color 0.3s ease',
//                 }}
//                 onMouseOver={(e) => e.target.style.backgroundColor = '#D43F00'}
//                 onMouseOut={(e) => e.target.style.backgroundColor = '#FF5733'}
//             >
//                 Reset Zoom
//             </button> */}
//         </div>
//     );
// }

function TradingViewChart({ lineSeriesData, symbol, date, onClick }) {
    const chartContainerRef = useRef();
    const chartRef = useRef(null); // 🔥 เก็บ instance ของ chart ไว้ที่นี่
    const priceLineRef = useRef(null);
    const flowLineRef = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        if (!chartContainerRef.current) return;

        // ถ้า chart ยังไม่มี ให้สร้างใหม่
        if (!chartRef.current) {
            chartRef.current = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
                    textColor: '#FFFFFF',
                },
                grid: {
                    vertLines: { color: theme.palette.neutral.dark },
                    horzLines: { color: theme.palette.neutral.dark },
                },
                rightPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 } },
                leftPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 }, visible: false },
                timeScale: {
                    rightOffset: lineSeriesData.price.length < 100 ? 1 : 5,
                    barSpacing: 3,
                    lockVisibleTimeRangeOnResize: true,
                    rightBarStaysOnScroll: true,
                    borderVisible: true,
                    visible: true,
                    timeVisible: true,
                    secondsVisible: true,
                    handleScroll: false,
                },
                watermark: {
                    color: theme.palette.chart.watermark,
                    visible: true,
                    text: (symbol || '').toUpperCase(),
                    fontSize: 24,
                    horzAlign: 'center',
                    vertAlign: 'top',
                },
            });

            // 🔥 เพิ่ม Series สำหรับเส้นราคาและ Flow
            priceLineRef.current = chartRef.current.addLineSeries({ color: '#66d9ff', priceLineVisible: false, lineWidth: 2, priceScaleId: 'right' });
            flowLineRef.current = chartRef.current.addLineSeries({ color: '#ffddaa', priceLineVisible: false, lineWidth: 2, priceScaleId: 'left', priceFormat: { type: 'volume' } });
        }

        // 🔥 อัปเดตข้อมูลที่มีอยู่ แทนที่จะสร้าง chart ใหม่
        if (priceLineRef.current) {
            priceLineRef.current.setData(lineSeriesData.price);
        }
        if (flowLineRef.current) {
            flowLineRef.current.setData(lineSeriesData.flow);
        }

        // ✅ คำนวณช่วงเวลาทั้งหมดของข้อมูล
        const allTimes = [...lineSeriesData.price.map(d => d.time), ...lineSeriesData.flow.map(d => d.time)];
        if (allTimes.length > 0) {
            const minTime = Math.min(...allTimes);
            const maxTime = Math.max(...allTimes);
            // chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
            // chartRef.current.timeScale().applyOptions({ rightOffset: 0 });
            chartRef.current.timeScale().fitContent();
        }

        return () => {
            // ไม่ลบ chart แต่ให้ Clean up เมื่อ Component ถูก unmount
            if (chartRef.current) {
                chartRef.current.remove();
                chartRef.current = null;
            }
        };
    }, [lineSeriesData]); // 🔥 Dependencies มีเฉพาะ `lineSeriesData`

    return (
        <div className='flowChartDiv' style={{ position: 'relative', height: '100%', width: '100%' }}>
            <div className='flowChart' ref={chartContainerRef} style={{ height: '100%', width: '100%' }} onClick={onClick} />
        </div>
    );
}


function ChartContainer({ chartId, selectedDate, symbol, lineSeriesData, onSubmit }) {
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        if (selectedDate && symbol) {
            setIsSearching(true);
            onSubmit(selectedDate, symbol).finally(() => {
                setIsSearching(false);
            });
        }
    }, [selectedDate, symbol, onSubmit]);

    return (
        <div style={{ display: 'none', flexDirection: 'column', maxWidth: '600px', margin: '0.2vw', backgroundColor: 'white' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', height: '3vh', alignItems: 'center', marginTop: '1%' }}>
                <Box sx={{ display: 'flex', marginBottom: '5px', width: '100%' }}>
                    <input
                        type="date"
                        value={selectedDate || ''}
                        className="form-control"
                        style={{ width: '120px', margin: '0.1vw 5px' }}
                        disabled
                    />
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Symbol here"
                        value={symbol || ''}
                        style={{ width: '120px', margin: '0.1vw 5px' }}
                        disabled
                    />
                </Box>
                {isSearching && (
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress />
                    </div>
                )}
            </div>

            {/* {lineSeriesData && (
                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                    <TradingViewChart
                        lineSeriesData={lineSeriesData}
                        symbol={symbol || ''}
                        date={selectedDate || ''}
                    />
                </Box>
            )} */}
        </div>
    );
}

const ShortTermTrading = () => {
    const [symbol, setSymbol] = useState('');
    const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [date2, setDate2] = useState(dayjs());
    // const [date, setDate] = useState(dayjs());
    const [intradayData, setIntradayData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dailyData, setDailyData] = useState(null);
    const [sliderValue, setSliderValue] = useState(0);
    const priceChartRef = useRef();
    const intradayNetChartRef = useRef();
    const intraday15MinChartRef = useRef();
    const csrfToken = useSelector((state) => state.csrfToken);
    const [Time2Show, setTime2Show] = useState('00:00:00');
    const dejavuRef = useRef();

    
    const fetchDailyData = async (symbol) => {
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setDailyData(data.dailyData);
            console.log("VOT Daily response data:", data);
        } catch (error) {
            console.error('Error fetching daily data:', error);
        }
    };
    const fetchIntradayData = async (symbol, date) => {
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}&date=${date}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setIntradayData(data);
            console.log("Intraday response data:", data);
        } catch (error) {
            console.error('Error fetching intraday data:', error);
        }
    };
    const fetchData = async () => {
        if (!symbol || !date) {
            console.error('Both Symbol and Date are required');
            return;
        }

        setLoading(true);
        try {
            await fetchDailyData(symbol);
            await fetchIntradayData(symbol, date);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    const sortByTimeAscending = (data) => {
        return data.sort((a, b) => a.time - b.time);
    };
    const aggregateToHourly = (data) => {
        const hourlyData = [];
        data.forEach((item) => {
            const date = new Date(item.time);
            const roundedTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours()).getTime() / 1000;

            const existingEntry = hourlyData.find((entry) => entry.time === roundedTime);
            if (existingEntry) {
                existingEntry.value = item.last;
            } else {
                hourlyData.push({
                    time: roundedTime,
                    value: item.last,
                });
            }
        });
        return hourlyData;
    };
    const aggregateTo15Min = (data) => {
        const fifteenMinData = [];
        data.forEach((item) => {
            const date = new Date(item.time);
            const roundedTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), Math.floor(date.getMinutes() / 15) * 15).getTime() / 1000;
            console.log('round Date Time',roundedTime)
            const existingEntry = fifteenMinData.find((entry) => entry.time === roundedTime);
            if (existingEntry) {
                existingEntry.value = item.last;
            } else {
                fifteenMinData.push({
                    time: roundedTime,
                    value: item.last,
                });
            }
        });
        return fifteenMinData;
    };
    const createChartContainer = (container, options) => {
        const chart = createChart(container, options);
        container._chart = chart;
        return chart;
    };
    const createPriceChart = (container, priceData,) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
            // timeScale: {
            //     rightOffset: 5,
            // },
            layout: {
                background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
                textColor: "#FFFFFF",
            },
            grid: {
                vertLines: { visible: true, color: theme.palette.neutral.dark },
                horzLines: { visible: true, color: theme.palette.neutral.dark },
            },
            watermark: {
                visible: true,
                text: symbol.toUpperCase(), // ใช้ symbol เป็นลายน้ำ
                fontSize: 24,
                color: 'rgba(255, 255, 255, 0.4)',
                horzAlign: 'center',
                vertAlign: 'top',
            },
            crosshair: {
                mode: 0, // Normal crosshair mode
            },

        });
        const series = chart.addLineSeries({
            color: '#b3ffe6',
            priceLineVisible: false,
        });
        const formattedData = priceData.map(item => ({
            time: Math.floor(new Date(item.date).getTime() / 1000),
            value: item.close,
        }));
        series.setData(formattedData);

        if (formattedData.length > 0) {
            const minTime = Math.min(...formattedData.map(d => d.time));
            const maxTime = Math.max(...formattedData.map(d => d.time));
            chart.timeScale().setVisibleRange({ from: minTime, to: maxTime });
            chart.timeScale().applyOptions({ rightOffset: 35 });
            chart.timeScale().fitContent();
        }
        container._chart = { chart, series };
        return { chart, series };
    };
    const createHourlyPriceChart = (container, priceData) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
            layout: {
                background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
                textColor: "#FFFFFF",
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
                // rightOffset: 5,
                backgroundColor: theme.palette.table.even,
                utcTime: true,
            },
            grid: {
                vertLines: { visible: true, color: theme.palette.neutral.dark },
                horzLines: { visible: true, color: theme.palette.neutral.dark },
            },
            watermark: {
                visible: true,
                text: symbol.toUpperCase(), // ใช้ symbol เป็นลายน้ำ
                fontSize: 24,
                color: 'rgba(255, 255, 255, 0.4)',
                horzAlign: 'center',
                vertAlign: 'top',
            },
        });
        const series = chart.addLineSeries({ color: '#99ccff', priceLineVisible: false });
        const hourlyData = aggregateToHourly(priceData);
        series.setData(sortByTimeAscending(hourlyData));
        chart.timeScale().fitContent();
        return { chart, series };
    };
    const create15MinPriceChart = (container, priceData) => {
        const chart = createChartContainer(container, {
            width: container.clientWidth,
            height: container.clientHeight,
            layout: {
                background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
                textColor: "#FFFFFF",
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: false,
                // rightOffset: 5,
                backgroundColor: theme.palette.table.even,
                utcTime: true,
            },
            grid: {
                vertLines: { visible: true, color: theme.palette.neutral.dark },
                horzLines: { visible: true, color: theme.palette.neutral.dark },
            },
            watermark: {
                visible: true,
                text: symbol.toUpperCase(), // ใช้ symbol เป็นลายน้ำ
                fontSize: 24,
                color: 'rgba(255, 255, 255, 0.4)',
                horzAlign: 'center',
                vertAlign: 'top',
            },
        });
        const series = chart.addLineSeries({ color: '#ffeeaa', priceLineVisible: false, });
        const fifteenMinData = aggregateTo15Min(priceData);
        series.setData(sortByTimeAscending(fifteenMinData));
        chart.timeScale().fitContent();
        return { chart, series };
    };
    const syncVisibleRange = (chart1, chart2) => {
        chart1.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            chart2.timeScale().setVisibleLogicalRange(timeRange);
            chart2.timeScale().applyOptions({ rightOffset: 5 });
        });

        chart2.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            chart1.timeScale().setVisibleLogicalRange(timeRange);
            chart1.timeScale().applyOptions({ rightOffset: 5 });
        });
    };
    const syncCrosshairMove = (chart1, series1, chart2, series2, chart3, series3) => {
        chart1.subscribeCrosshairMove((param) => {
            const dataPoint = param.seriesData.get(series1);
            if (dataPoint) {
                const date = new Date(dataPoint.time * 1000);
                const currentDate = new Date();

                if (date.toDateString() === currentDate.toDateString()) {
                    chart2.setCrosshairPosition(dataPoint.value, dataPoint.time, series2);
                    chart3.setCrosshairPosition(dataPoint.value, dataPoint.time, series3);
                } else {
                    date.setDate(date.getDate() - 1);
                    const adjustedTime = date.getTime() / 1000;

                    chart2.setCrosshairPosition(dataPoint.value, dataPoint.time, series2);
                    chart3.setCrosshairPosition(dataPoint.value, dataPoint.time, series3);
                    chart1.setCrosshairPosition(dataPoint.value, adjustedTime, series1);
                }

                const time = date.toISOString().substr(11, 8);
                setTime2Show(time);
            }
        });

        chart2.subscribeCrosshairMove((param) => {
            const dataPoint = param.seriesData.get(series2);
            if (dataPoint) {
                const date = new Date(dataPoint.time * 1000);
                const currentDate = new Date();

                if (date.toDateString() === currentDate.toDateString()) {
                    chart1.setCrosshairPosition(dataPoint.value, dataPoint.time, series1);
                    chart3.setCrosshairPosition(dataPoint.value, dataPoint.time, series3);
                } else {
                    date.setDate(date.getDate() - 1);
                    const adjustedTime = date.getTime() / 1000;

                    chart1.setCrosshairPosition(dataPoint.value, adjustedTime, series1);
                    chart3.setCrosshairPosition(dataPoint.value, dataPoint.time, series3);
                }

                const time = date.toISOString().substr(11, 8);
                setTime2Show(time);
            }
        });

        chart3.subscribeCrosshairMove((param) => {
            const dataPoint = param.seriesData.get(series3);
            if (dataPoint) {
                const date = new Date(dataPoint.time * 1000);
                const currentDate = new Date();

                if (date.toDateString() === currentDate.toDateString()) {
                    chart1.setCrosshairPosition(dataPoint.value, dataPoint.time, series1);
                    chart2.setCrosshairPosition(dataPoint.value, dataPoint.time, series2);
                } else {
                    date.setDate(date.getDate() - 1);
                    const adjustedTime = date.getTime() / 1000;

                    chart1.setCrosshairPosition(dataPoint.value, adjustedTime, series1);
                    chart2.setCrosshairPosition(dataPoint.value, dataPoint.time, series2);
                }

                const time = date.toISOString().substr(11, 8);
                setTime2Show(time);
            }
        });
    };
    useEffect(() => {
        if (dailyData && !priceChartRef.current._chart) {
            const { chart: priceChart, series: priceSeries } = createPriceChart(priceChartRef.current, dailyData.dailyPrice);

            if (intradayNetChartRef.current._chart && intraday15MinChartRef.current._chart) {
                const { chart: hourlyPriceChart, series: hourlyPriceSeries } = intradayNetChartRef.current._chart;
                const { chart: fifteenMinPriceChart, series: fifteenMinPriceSeries } = intraday15MinChartRef.current._chart;

                syncCrosshairMove(priceChart, priceSeries, hourlyPriceChart, hourlyPriceSeries, fifteenMinPriceChart, fifteenMinPriceSeries);
            }
        }

        if (intradayData && !intradayNetChartRef.current._chart && !intraday15MinChartRef.current._chart) {
            const { chart: hourlyPriceChart, series: hourlyPriceSeries } = createHourlyPriceChart(
                intradayNetChartRef.current,
                intradayData.priceData
            );

            const { chart: fifteenMinPriceChart, series: fifteenMinPriceSeries } = create15MinPriceChart(
                intraday15MinChartRef.current,
                intradayData.priceData
            );

            syncVisibleRange(hourlyPriceChart, fifteenMinPriceChart);

            if (priceChartRef.current._chart) {
                const { chart: priceChart, series: priceSeries } = priceChartRef.current._chart;

                syncVisibleRange(hourlyPriceChart, fifteenMinPriceChart);
                syncCrosshairMove(priceChart, priceSeries, hourlyPriceChart, hourlyPriceSeries, fifteenMinPriceChart, fifteenMinPriceSeries);
            }
        }
    }, [dailyData, intradayData,]);
    /////////////////////////////////////////////
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const today_value = dayjs(today).subtract(0, 'day');
    const initialRow = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialInRange = [
        createDataTickmatch(0, 0, 0, 0),
    ]
    const initialActual = [
        createDataTickmatch(0, 0, 0, 0),
    ]
    const [rows, setRows] = useState(initialRow);
    const [rowsInRange, setRowsInRange] = useState(initialInRange);
    const [rowsActual, setRowsActual] = useState(initialActual);
    const [initialValues, setInitialValues] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: "10:00",        // กำหนด start_time คงที่ที่ 10:00
        end_time: "16:30",          // กำหนด end_time คงที่ที่ 16:30
        symbol: "",
        speed: 1
    })
    const formatTime = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const objectToQueryString = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const [countRecordBidAsk, setCountRecordBidAsk] = useState(0);
    const [bidaskLength, setBidAskLength] = useState(1);
    const [resultBidAsk, setResultBidAsk] = useState([]);
    const [resultTickMatch, setResultTickMatch] = useState([]);
    const resetProcess = () => {
        previousBidAsk.current = {};
        previousTickMatchInRange.current = {};
        previousTickMatchActual.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows(initialRow);
        setRowsInRange(initialInRange);
        setRowsActual(initialActual);
        setCountRecordBidAsk(0);
        setIsPlaying(false);
        setTime2Show('00:00:00');
    }
    const handleFetchAndSearch = async () => {
        if (!symbol || !date) {
            console.error("Both Symbol and Date are required");
            return;
        }
        setLoading(true);
        resetProcess();
        try {
            await fetchDailyData(symbol);
            await fetchIntradayData(symbol, date);
            await handleSubmitForm1(symbol, date)

            const values = {
                symbol: symbol,
                start_date: date,
                end_date: date,
                start_time: "10:00",
                end_time: "16:30",
                speed: 1
            };
            const response = await __fetching_data__({
                url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString(values)}`,
                method: "GET",
                headers: { "xsrf-token": csrfToken }
            });
            if (response.status) {
                setResultBidAsk(response.data.bidask);
                setResultTickMatch(response.data.tickmatch);
                setBidAskLength(response.data.bidask.length);
                setIsPlaying(true);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (dayjs(initialValues.start_date).isSame(dayjs(), 'day')) {
            setInitialValues((prevData) => ({
                ...prevData,
                start_time: "10:00",  // ล็อกเวลาเริ่มต้น
                end_time: "16:30",    // ล็อกเวลาสิ้นสุด
            }));
        }
    }, [initialValues.start_date]);
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            backgroundColor: 'rgba(53, 60, 83, 1)',//"#B6DBFB",//theme.palette.common.black,
            color: theme.palette.common.white,//"#1B7FD6",//theme.palette.common.white,
            fontWeight: 'bold',

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            backgroundColor: 'rgba(53, 60, 83, 1)',
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
    }));
    const [isPlaying, setIsPlaying] = useState(false);
    const handleSliderChange = (event, newValue) => {
        setCountRecordBidAsk(newValue);

        if (resultBidAsk[newValue - 1]) {
            const newTime = dayjs(resultBidAsk[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show(newTime);
        }
    };
    const NumberWithCommasAndDecimal = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }
    const hightLow = (values, key) => {
        var min = Math.min(...values.map(item => parseFloat(item[key])));
        var max = Math.max(...values.map(item => parseFloat(item[key])));
        return {
            max: max,
            min: min
        }
    }
    const previousBidAsk = useRef({});
    const previousTickMatchInRange = useRef({});
    const previousTickMatchActual = useRef({});
    const extractAndConvertChildren = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const compareData = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged, setSpeedChanged] = useState(false);
    const prevSpeedRef = useRef(initialValues.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues.speed == 0 || initialValues.speed == null ? 1 : initialValues.speed;
        if (isPlaying) {
            interval = setInterval(async () => {
                speed = initialValues.speed == 0 || initialValues.speed == null ? 1 : initialValues.speed;
                if (speedChanged) {
                    setLoading(true);
                    await delay(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk(prevCount => {
                    if (prevCount >= bidaskLength - 1) {
                        setIsPlaying(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show(time2show);

                        const dateToCheck = dayjs(formatDate(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = []
                        const updatedRows = [...rows];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk5))
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk4))
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk3))
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk2))
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk1))
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                )
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren);
                                if (!isEmpty(previousBidAsk.current)) {
                                    let compData = compareData(previousBidAsk.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                    cell.style.color = 'black';
                                                    setTimeout(() => {
                                                        cell.style.color = ''; // ใช้ค่าเริ่มต้นของตาราง (ไม่รีเซ็ตเร็วเกินไป)
                                                    }, 1000); // 1 วินาที
                                                }
                                            });
                                        })
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk.current = extractedRows
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk10))
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk9))
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk8))
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk7))
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk6))
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk5))
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk4))
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk3))
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk2))
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk1))
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                )
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren);
                                if (!isEmpty(previousBidAsk.current)) {
                                    let compData = compareData(previousBidAsk.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                    cell.style.color = 'black';
                                                    setTimeout(() => {
                                                        cell.style.color = ''; // ใช้ค่าเริ่มต้นของตาราง (ไม่รีเซ็ตเร็วเกินไป)
                                                    }, 1100); // 1 วินาที
                                                }
                                            });
                                        })
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk.current = extractedRows
                            }
                        }
                        setRows(updatedRows);
                        if (resultTickMatch != undefined) {
                            let tickmatch_hight_low;
                            let tickmatch_per_min = resultTickMatch.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            })
                            let tickmatch_in_range = resultTickMatch.filter((x) => {
                                let x1 = dayjs(x.DtMinOfRec);
                                let x21 = dayjs(formatDate(initialValues.start_date) + " " + formatTime(initialValues.start_time));
                                let x22 = dayjs(formatDate(initialValues.end_date) + " " + formatTime(initialValues.end_time));
                                return (x1 >= x21 && x1 <= x22)
                            });
                            if (tickmatch_per_min.length > 0) {
                                tickmatch_hight_low = hightLow(tickmatch_per_min, 'Last');
                            }
                            const updatedRowsInRange = [...rowsInRange];
                            let InRagneTickmatch = createDataTickmatch(
                                tickmatch_in_range.length > 0 ? NumberWithCommasAndDecimal(tickmatch_in_range[0].Last, 2) : 0,
                                tickmatch_hight_low?.max ?? previousTickMatchInRange?.current?.high ?? 0,
                                tickmatch_hight_low?.min ?? previousTickMatchInRange?.current?.low ?? 0,
                                tickmatch_in_range.length > 0 ? NumberWithCommasAndDecimal(tickmatch_in_range[tickmatch_in_range.length - 1].Last, 2) : 0
                            )
                            updatedRowsInRange[0] = InRagneTickmatch;
                            previousTickMatchInRange.current = {
                                open: InRagneTickmatch.open && InRagneTickmatch.open.props ? parseFloat(InRagneTickmatch.open.props.children) : 0,
                                high: parseFloat(InRagneTickmatch.high),
                                low: parseFloat(InRagneTickmatch.low),
                                close: InRagneTickmatch.close && InRagneTickmatch.close.props ? parseFloat(InRagneTickmatch.close.props.children) : 0
                            }
                            setRowsInRange(updatedRowsInRange);

                            const updatedRowsActual = [...rowsActual];
                            let allTickmatchHighLow = hightLow(resultTickMatch, 'Last');
                            let ActualTickmatch = createDataTickmatch(
                                resultTickMatch.length > 0 ? NumberWithCommasAndDecimal(resultTickMatch[0].Last, 2) : 0,
                                allTickmatchHighLow?.max ?? previousTickMatchActual?.current?.high ?? 0,
                                allTickmatchHighLow?.min ?? previousTickMatchActual?.current?.low ?? 0,
                                resultTickMatch.length > 0 ? NumberWithCommasAndDecimal(resultTickMatch[resultTickMatch.length - 1].Last, 2) : 0
                            )
                            updatedRowsActual[0] = ActualTickmatch
                            previousTickMatchActual.current = {
                                open: ActualTickmatch.open && ActualTickmatch.open.props ? parseFloat(ActualTickmatch.open.props.children) : 0,
                                high: ActualTickmatch.high ? parseFloat(ActualTickmatch.high) : 0,
                                low: ActualTickmatch.low ? parseFloat(ActualTickmatch.low) : 0,
                                close: ActualTickmatch.close && ActualTickmatch.close.props ? parseFloat(ActualTickmatch.close.props.children) : 0
                            }
                            setRowsActual(updatedRowsActual)
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef.current) {
            setSpeedChanged(true);
        } else {
            setSpeedChanged(false);
        }
        prevSpeedRef.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying, countRecordBidAsk, initialValues.speed]);
    ///////////////////////////////////////////
    const isMobile = useIsMobile();
    const isPTablet = useIsPortraitTablet();
    const isTablet = useIsTablet();
    const isSyncing = useRef(false);
    const syncScrollRef = useRef([]);
    const handleScroll = (e, sourceRef) => {
        if (isSyncing.current) {
            syncScrollRef.current.forEach((ref) => {
                if (ref && ref !== sourceRef) {
                    ref.scrollTop = e.target.scrollTop;
                }
            });
        }
    };
    useEffect(() => {
        if (!isSyncing && syncScrollRef.current) {
            syncScrollRef.current.forEach((ref) => {
                if (ref) {
                    ref.removeEventListener("scroll", handleScroll);
                }
            });
        }
    }, [isSyncing]);
    function createData1(time, last, vol, type, acc_cum_vol) {
        return { time, last, vol, type, acc_cum_vol };
    }
    const [rows1, setRows1] = useState([]);
    const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            backgroundColor: 'rgba(53, 60, 83, 1)',//"#B6DBFB",//theme.palette.common.black,
            color: theme.palette.common.white,//"#1B7FD6",//theme.palette.common.white,
            fontWeight: 'bold',
            paddingTop: 8, // Set top padding to 0
            paddingBottom: 8, // Set bottom padding to 0
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            backgroundColor: 'rgba(53, 60, 83, 1)',
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
    }));
    const now1 = dayjs();
    const today1 = now1.format('YYYY-MM-DD');
    const today_value1 = dayjs(today1);
    const [initialValues1, setInitialValues1] = useState({
        symbol1: symbol || "",
        date1: today_value1.format('YYYY-MM-DD'),
    });
    useEffect(() => {
        setInitialValues1({
            symbol1: symbol,
            date1: date ? dayjs(date).format('YYYY-MM-DD') : today_value1.format('YYYY-MM-DD'),
        });
    }, [symbol, date]);
    const [sumBuy, setSumBuy] = useState([0, 0]);
    const [sumSell, setSumSell] = useState([0, 0]);
    const formatDate1 = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const objectToQueryString1 = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }
    const [isFetch, setIsFetch] = useState({
        'form1': false,
    });
    const endProcess = () => {
        setIsFetch({
            'form1': false,
        });
    }
    const handleSubmitForm1 = async () => {
        setRows1([]);
        setIsFetch(prevState => ({
            ...prevState,
            'form1': true,
        }));
        const values = {
            symbol: initialValues1.symbol1,
            start_date: formatDate1(initialValues1.date1),
            end_date: formatDate1(initialValues1.date1)
        };
        const response = await __fetching_data__({ url: `${base_api_url}/tickmatch_dejavu_report1?${objectToQueryString1(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        if (response.status) {
            let newData = [];
            response.data.tickmatch?.map((value, index) => {
                newData.push(createData1(value.Time, value.Last, value.Vol.toLocaleString(), value.Type, value.acc_cum_vol.toLocaleString()))
            });
            console.log(newData);
            setSumBuy(prevState => prevState.map((item, index) => index === 0 ? response.data.tickmatch_sum?.[0]?.sum_buy.toLocaleString() || 0 : item));
            setSumSell(prevState => prevState.map((item, index) => index === 0 ? response.data.tickmatch_sum?.[0]?.sum_sell.toLocaleString() || 0 : item));
            setRows1(newData);
        }
        endProcess();
    }
    const DataTable = ({ rows1, isFetching, syncScrollRef }) => {
        const tableRef = useRef(null);
        return (
            <Grid item md={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <TableContainer
                    component={Paper}
                    ref={(ref) => {
                        tableRef.current = ref;
                        if (syncScrollRef && !syncScrollRef.current.includes(ref)) {
                            syncScrollRef.current.push(ref);
                        }
                    }}
                    onScroll={handleScroll}
                    sx={{
                        width: '100%',
                        height: '100%',
                        '@media (min-width: 600px)': {
                            width: '100%',
                            height: '100%',
                        },
                        maxHeight: '20vh',
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555',
                        },
                    }}
                >
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow >
                                {['Time', 'Last', 'Vol', 'Type', 'Sum'].map((header) => (
                                    <StyledTableCell1
                                        key={header}
                                        align="center"
                                        sx={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}
                                    >
                                        {header}
                                    </StyledTableCell1>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isFetching ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>

                            ) : (
                                rows1.map((row, index) => (
                                    <TableRow key={index} sx={{
                                        td: {
                                            padding: '4px', fontSize: {
                                                xs: 12,
                                                sm: 12,
                                                md: 10,
                                                lg: 12,
                                                xl: 12
                                            }, fontFamily: 'Source Sans Pro, sans-serif'
                                        },
                                        backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even,
                                    }}>
                                        <TableCell align="center" style={{ padding: '4px' }}>{row.time}</TableCell>
                                        <TableCell align="center" style={{ padding: '4px' }}>{row.last}</TableCell>
                                        <TableCell align="center" style={{ textAlign: 'right', padding: '4px' }}>
                                            {row.vol}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ padding: '4px', color: row.type === 'BUY' ? '#00FF00' : '#FF4500' }}  // ใช้สีเขียวและแดงสว่าง
                                        >
                                            {row.type}
                                        </TableCell>

                                        <TableCell
                                            align="center"
                                            style={{
                                                padding: '4px',
                                                color: parseFloat(row.acc_cum_vol.replace(/,/g, '')) > 0 ? '#00FF00' : '#FF4500'  // ใช้สีเขียวและแดงสว่าง
                                            }}
                                        >
                                            {row.acc_cum_vol}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    }
    ///////////////////////////////////
    const [selectedDate, setSelectedDate] = useState('');
    const [lineSeriesData, setLineSeriesData] = useState(null);
    const memoizedLineSeriesData = useMemo(() => lineSeriesData, [lineSeriesData]);
    const FlowfetchData = async (date, symbol) => {
        if (!date || !symbol) {
            console.error('Please provide both symbol and date');
            return null;
        }
        const flowUrl = `${window.config.base_api_url}/flow?symbol=${symbol}&date=${date}`;
        try {
            const response = await fetch(flowUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const rawData = await response.json();
            if (!rawData || rawData.length === 0) {
                console.error('No data received');
                return null;
            }
            return rawData;
        } catch (error) {
            console.error('Error during fetch operation:', error.message);
            return null;
        }
    };
    const handleSubmit = async (date, symbol) => {
        const rawData = await FlowfetchData(date, symbol);
        if (!rawData) return;
        const newLineSeriesData = {
            price: convertData(rawData, 'time', 'last'),
            flow: convertData(rawData, 'time', 'flow'),
        };
        setLineSeriesData(newLineSeriesData);
    };

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '1800px', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 1 }}>
            {/* <h2>Short term trading</h2> */}
            <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%', alignItems: 'flex-start' }}>
                {(!isMobile && !isPTablet) && (<>
                    {/* Left Content */}
                    <div style={{ flexBasis: '50%' }}>
                        <div style={{ marginTop: 0, display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <Box>
                                    <label>Symbol:</label>
                                    <Input
                                        value={symbol}
                                        onChange={(e) => setSymbol(e.target.value)}
                                        placeholder="Enter symbol"
                                        style={{ marginLeft: '10px' }}
                                    />
                                </Box>
                                <Box>
                                    <label>Select date:</label>
                                    <Input
                                        type="date"
                                        value={date}
                                        onChange={(e) => setDate(e.target.value)}
                                        style={{ marginLeft: '10px' }}
                                    />
                                </Box>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: colors.blueAccent[500],
                                        width: "auto",
                                        height: "auto"
                                    }}
                                    type="button"
                                    onClick={handleFetchAndSearch}
                                    disabled={!symbol || !date}
                                >
                                    Search
                                </Button>
                            </div>
                            {loading && <p>Loading...</p>}
                        </div>
                        <Box sx={{ marginTop: 0 }}>
                            <Preloading open={loading} />
                            <Grid>
                                <Grid display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: 1 }}>
                                </Grid>
                                <Grid className='bidask-table'>
                                    <Grid item md={6}>
                                        <Card fullWidth>
                                            <Grid>
                                                <TableContainer>
                                                    <Table aria-label="simple table" className="change-tracking">
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                <StyledTableCell align="center">BID</StyledTableCell>
                                                                <StyledTableCell align="center">ASK</StyledTableCell>
                                                                <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rows.map((row, index) => {
                                                                if (index < 10) {
                                                                    return (
                                                                        <TableRow key={index} sx={{
                                                                            backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even, //สีแบบสลับ คู่ คี่
                                                                        }}>
                                                                            <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px', width: '30%', fontSize: '14px' }}>{row.vol_bid}</TableCell>
                                                                            <TableCell id={`cell-${index}-bid`} align="center" style={{ padding: '4px', width: '20%', fontSize: '14px' }}>{row.bid}</TableCell>
                                                                            <TableCell id={`cell-${index}-ask`} align="center" style={{ padding: '4px', width: '20%', fontSize: '14px' }}>{row.ask}</TableCell>
                                                                            <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px', width: '30%', fontSize: '14px' }}>{row.vol_ask}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                } if (index === 11) {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <StyledTableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px', fontSize: '14px' }}>{row.vol_bid}</StyledTableCell>
                                                                            <StyledTableCell id={`cell-${index}-bid`} align="center" style={{ padding: '4px', fontSize: '14px' }}>{row.bid}</StyledTableCell>
                                                                            <StyledTableCell id={`cell-${index}-ask`} align="center" style={{ padding: '4px', fontSize: '14px' }}>{row.ask}</StyledTableCell>
                                                                            <StyledTableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px', fontSize: '14px' }}>{row.vol_ask}</StyledTableCell>
                                                                        </TableRow>
                                                                    )
                                                                }
                                                                else {
                                                                    return (
                                                                        <TableRow key={index}>
                                                                            <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px', fontSize: '14px' }}>{row.vol_bid}</TableCell>
                                                                            <TableCell align="center" style={{ padding: '4px', fontSize: '14px' }}>{row.bid}</TableCell>
                                                                            <TableCell align="center" style={{ padding: '4px', fontSize: '14px' }}>{row.ask}</TableCell>
                                                                            <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px', fontSize: '14px' }}>{row.vol_ask}</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                }
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box >
                        {/* <br /> */}
                        <Box sx={{ marginTop: 1 }}>
                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12} md={9}>
                                    <DataTable rows1={rows1} isFetching={isFetch.form1} syncScrollRef={syncScrollRef} isSyncing={isSyncing} />
                                </Grid>

                                <Grid item xs={12} md={3} container direction="column" alignItems="flex-end">
                                    <Grid item sx={{ width: "100%", marginBottom: "0.5rem" }}>
                                        <TextField
                                            fullWidth
                                            label="Sum Buy"
                                            variant="outlined"
                                            size="small"
                                            value={sumBuy[0]}
                                            InputProps={{ readOnly: true }}
                                            sx={{
                                                marginTop: '20px',
                                                marginBottom: '5px',
                                                '& .MuiInputLabel-root': {
                                                    color: colors.greenChart[100],
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: colors.greenChart[100],
                                                },
                                                '& .MuiInputBase-root': {
                                                    display: 'flex',
                                                    alignItems: 'center', // จัดให้อยู่ตรงกลางแนวตั้ง
                                                    justifyContent: 'center', // จัดให้อยู่ตรงกลางแนวนอน
                                                    height: '48px', // กำหนดความสูงของกรอบ
                                                },
                                                '& .MuiInputBase-input': {
                                                    textAlign: 'center', // จัดข้อความให้อยู่ตรงกลาง
                                                    fontSize: 'calc(1rem - 1px)',
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                    color: colors.greenChart[100],
                                                    height: '48px', // กำหนดความสูงของ input
                                                    padding: '0', // กำจัด padding ที่อาจทำให้ข้อความไม่ตรงกลาง
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: colors.greenChart[100],
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: colors.greenChart[100],
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: colors.greenChart[100],
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <TextField
                                            fullWidth
                                            label="Sum Sell"
                                            variant="outlined"
                                            size="small"
                                            value={sumSell[0]}
                                            InputProps={{ readOnly: true }}
                                            sx={{
                                                '& .MuiInputLabel-root': {
                                                    color: 'rgba(255, 80, 80, 1)',
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: 'rgba(255, 80, 80, 1)',
                                                },
                                                '& .MuiInputBase-root': {
                                                    display: 'flex',
                                                    alignItems: 'center', // จัดให้อยู่ตรงกลางแนวตั้ง
                                                    justifyContent: 'center', // จัดให้อยู่ตรงกลางแนวนอน
                                                    height: '48px', // เพิ่มความสูงของกรอบ
                                                },
                                                '& .MuiInputBase-input': {
                                                    textAlign: 'center', // จัดข้อความให้อยู่ตรงกลาง
                                                    fontSize: '20px', // เพิ่มขนาดตัวอักษร
                                                    fontWeight: 'bold',
                                                    color: 'rgba(255, 80, 80, 1)',
                                                    height: '48px', // กำหนดความสูงของ input
                                                    padding: '0', // ปรับ padding ให้ข้อความอยู่ตรงกลางจริงๆ
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: 'rgba(255, 80, 80, 1)',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: 'rgba(255, 80, 80, 1)',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: 'rgba(255, 80, 80, 1)',
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* <br /> */}
                        <Grid container sx={{ marginTop: 1 }}
                            style={{
                                position: 'relative',
                                height: '110px',
                                width: '100%',
                                border: '2px solid #ccc',
                                borderRadius: '8px',
                                overflow: 'hidden', // ซ่อนส่วนที่ล้น
                            }}>
                            <ChartContainer
                                chartId="chart1"
                                selectedDate={date}
                                symbol={symbol}
                                lineSeriesData={lineSeriesData}
                                onSubmit={handleSubmit}
                            />
                            {lineSeriesData  && (
                                <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <TradingViewChart
                                        lineSeriesData={lineSeriesData}
                                        symbol={symbol || ''}
                                        date={selectedDate || ''}
                                    />
                                </Box>
                            )}
                            <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, }}>Flowdaily</div>
                        </Grid>
                    </div>
                    {/* Right Content */}
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', }}>
                        <Typography variant='h3' sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', }}>{Time2Show}</Typography>
                        <div
                            ref={priceChartRef}
                            style={{
                                position: 'relative',
                                height: '218px',
                                width: '100%',
                                border: '2px solid #ccc',
                                borderRadius: '8px',
                                overflow: 'hidden', // ซ่อนส่วนที่ล้น
                            }}
                        >
                            <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, }}>Day</div>
                        </div>
                        <div
                            ref={intradayNetChartRef}
                            style={{
                                position: 'relative',
                                height: '218px',
                                width: '100%',
                                border: '2px solid #ccc',
                                borderRadius: '8px',
                                overflow: 'hidden', // ซ่อนส่วนที่ล้น
                            }}
                        >
                            <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, }}>Hourly Price</div>
                        </div>
                        <div
                            ref={intraday15MinChartRef}
                            style={{
                                position: 'relative',
                                height: '218px',
                                width: '100%',
                                border: '2px solid #ccc',
                                borderRadius: '8px',
                                overflow: 'hidden', // ซ่อนส่วนที่ล้น
                            }}
                        >
                            <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, }}>15-Minute Price</div>
                        </div>
                        <Slider
                            aria-labelledby="continuous-slider"
                            style={{ marginTop: '-10px', width: '50%' }}
                            defaultValue={1}
                            aria-label="Time Slider"
                            valueLabelDisplay="auto"
                            min={1}
                            max={bidaskLength}
                            value={countRecordBidAsk}
                            onChange={handleSliderChange}
                            valueLabelFormat={(value) => `${Time2Show}`}
                        />
                    </div>
                </>

                )}


                {(isMobile || isPTablet) && (
                    <>
                        <Swiper
                            style={{ width: '100%', maxWidth: isMobile ? '600px' : '80vw' }}
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={20}
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                                dynamicBullets: true,
                            }}
                            onSwiper={(swiper) => {
                                window.mySwiper = swiper;
                            }}
                        >
                            <SwiperSlide>
                                <div style={{}}>
                                    <div style={{ marginTop: 0, display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', }}>
                                            <Box>
                                                {/* <label>Symbol:</label> */}
                                                {/* <TextField
                                                    label='Symbol'
                                                    value={symbol}
                                                    onChange={(e) => setSymbol(e.target.value)}
                                                    placeholder="Enter symbol"
                                                /> */}


                                                <Input
                                                    value={symbol}
                                                    onChange={(e) => setSymbol(e.target.value)}
                                                    placeholder="Enter symbol"
                                                    style={{ marginLeft: '10px' }}
                                                />
                                            </Box>
                                            <Box>
                                                {/* <label>Select date:</label> */}
                                                <Input
                                                    type="date"
                                                    value={date}
                                                    onChange={(e) => setDate(e.target.value)}
                                                    style={{ marginLeft: '10px' }}
                                                />

                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                                    <DatePicker
                                                        label="Select Date"
                                                        value={dayjs(date)}
                                                        onChange={(newDate) => setDate(newDate)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider> */}
                                            </Box>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{
                                                    backgroundColor: colors.blueAccent[500],
                                                    width: "auto",
                                                    height: "auto"
                                                }}
                                                type="button"
                                                onClick={handleFetchAndSearch}
                                                disabled={!symbol || !date}
                                            >
                                                Search
                                            </Button>
                                        </div>
                                        {loading && <p>Loading...</p>}
                                    </div>
                                    <Box sx={{ marginTop: 0 }}>
                                        <Preloading open={loading} />
                                        <Grid>
                                            <Grid display="flex" alignItems="center" justifyContent="center" sx={{ marginTop: 1 }}>
                                            </Grid>
                                            <Grid className='bidask-table'>
                                                <Grid item md={6}>
                                                    <Card fullWidth>
                                                        <Grid>
                                                            <TableContainer>
                                                                <Table aria-label="simple table" className="change-tracking">
                                                                    <TableRow>
                                                                        <StyledTableCell align="center">Vol BID</StyledTableCell>
                                                                        <StyledTableCell align="center">BID</StyledTableCell>
                                                                        <StyledTableCell align="center">ASK</StyledTableCell>
                                                                        <StyledTableCell align="center">Vol ASK</StyledTableCell>
                                                                    </TableRow>
                                                                    <TableBody>
                                                                        {rows.map((row, index) => {
                                                                            if (index < 10) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px', width: '30%' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell id={`cell-${index}-bid`} align="center" style={{ padding: '4px', width: '20%' }}>{row.bid}</TableCell>
                                                                                        <TableCell id={`cell-${index}-ask`} align="center" style={{ padding: '4px', width: '20%' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px', width: '30%' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            } if (index === 11) {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <StyledTableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px' }}>{row.vol_bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-bid`} align="center" style={{ padding: '4px' }}>{row.bid}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-ask`} align="center" style={{ padding: '4px' }}>{row.ask}</StyledTableCell>
                                                                                        <StyledTableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px' }}>{row.vol_ask}</StyledTableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                            else {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '4px' }}>{row.vol_bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '4px' }}>{row.bid}</TableCell>
                                                                                        <TableCell align="center" style={{ padding: '4px' }}>{row.ask}</TableCell>
                                                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '4px' }}>{row.vol_ask}</TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box >
                                    {/* <br /> */}
                                    <Box sx={{ marginTop: 1 }}>
                                        <Grid container spacing={2} alignItems="flex-start">
                                            <Grid item xs={12} md={9}>
                                                <DataTable rows1={rows1} isFetching={isFetch.form1} syncScrollRef={syncScrollRef} isSyncing={isSyncing} />
                                            </Grid>

                                            <Grid item xs={12} md={3} container gap="1rem" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Grid item xs={4} sm={4} >
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Buy"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumBuy[0]}
                                                        InputProps={{ readOnly: true }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': { color: 'green' },
                                                            '& .MuiInputLabel-root.Mui-focused': { color: 'green' },
                                                            '& .MuiInputBase-root': { backgroundColor: 'rgba(144, 238, 144, 0.3)' },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: 'green',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': { borderColor: 'green' },
                                                                '&:hover fieldset': { borderColor: 'green' },
                                                                '&.Mui-focused fieldset': { borderColor: 'green' },
                                                            },
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={4} sm={4}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Sell"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumSell[0]}
                                                        InputProps={{ readOnly: true }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': { color: 'red' },
                                                            '& .MuiInputLabel-root.Mui-focused': { color: 'red' },
                                                            '& .MuiInputBase-root': { backgroundColor: 'rgba(255, 0, 0, 0.1)' },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: 'red',
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': { borderColor: 'red' },
                                                                '&:hover fieldset': { borderColor: 'red' },
                                                                '&.Mui-focused fieldset': { borderColor: 'red' },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {/* <br /> */}
                                    <Grid container sx={{ marginTop: 1 }}
                                        style={{
                                            position: 'relative',
                                            height: '110px',
                                            width: '100%',
                                            border: '1px solid #ccc',
                                            borderRadius: '8px',
                                        }}>
                                        <ChartContainer
                                            chartId="chart1"
                                            selectedDate={date}
                                            symbol={symbol}
                                            lineSeriesData={lineSeriesData}
                                            onSubmit={handleSubmit}
                                        />
                                        {lineSeriesData && (
                                            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                                                <TradingViewChart
                                                    lineSeriesData={lineSeriesData}
                                                    symbol={symbol || ''}
                                                    date={selectedDate || ''}
                                                />
                                            </Box>
                                        )}
                                        <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, }}>Flowdaily</div>
                                    </Grid>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Typography variant='h3' sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>{Time2Show}</Typography>
                                    <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0 10px 0 10px' }}>
                                        <div
                                            ref={priceChartRef}
                                            style={{
                                                position: 'relative',
                                                height: '210px',
                                                width: '100%',
                                                border: '1px solid #ccc',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, }}>Day</div>
                                        </div>
                                        <div
                                            ref={intradayNetChartRef}
                                            style={{
                                                position: 'relative',
                                                height: '210px',
                                                width: '100%',
                                                border: '1px solid #ccc',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, }}>Hourly Price</div>
                                        </div>
                                        <div
                                            ref={intraday15MinChartRef}
                                            style={{
                                                position: 'relative',
                                                height: '210px',
                                                width: '100%',
                                                border: '1px solid #ccc',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20, }}>15-Minute Price</div>
                                        </div>

                                    </Box>


                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Slider
                                            aria-labelledby="continuous-slider"
                                            style={{ width: '50%', }}
                                            defaultValue={1}
                                            aria-label="Time Slider"
                                            valueLabelDisplay="auto"
                                            min={1}
                                            max={bidaskLength}
                                            value={countRecordBidAsk}
                                            onChange={handleSliderChange}
                                            valueLabelFormat={(value) => `${Time2Show}`}
                                        />

                                    </Box>

                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </>
                )}
            </div>
        </div>
    );
};

export default ShortTermTrading;
