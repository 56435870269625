import React, { useState, useRef, useEffect } from 'react';
import { createChart } from 'lightweight-charts';
import { tokens } from '../../theme';
import { useTheme, Box, Typography, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';

// ------------------- Data Object ----------------------
export const symbolData = {
  2020: {
    S50H20: {
      volumeData: [
        {
          "date": "27/9/2562",
          "callPut": 51.0,
          "futurePrice": 1087.9
        },
        {
          "date": "30/9/2562",
          "callPut": -15.0,
          "futurePrice": 1084.8
        },
        {
          "date": "1/10/2562",
          "callPut": 31.0,
          "futurePrice": 1074.9
        },
        {
          "date": "2/10/2562",
          "callPut": -60.0,
          "futurePrice": 1067.1
        },
        {
          "date": "3/10/2562",
          "callPut": -7.0,
          "futurePrice": 1067.8
        },
        {
          "date": "4/10/2562",
          "callPut": 33.0,
          "futurePrice": 1064.8
        },
        {
          "date": "7/10/2562",
          "callPut": -12.0,
          "futurePrice": 1069.2
        },
        {
          "date": "8/10/2562",
          "callPut": 24.0,
          "futurePrice": 1067.6
        },
        {
          "date": "9/10/2562",
          "callPut": 57.0,
          "futurePrice": 1074.5
        },
        {
          "date": "10/10/2562",
          "callPut": -6.0,
          "futurePrice": 1066.3
        },
        {
          "date": "11/10/2562",
          "callPut": -132.0,
          "futurePrice": 1079.5
        },
        {
          "date": "15/10/2562",
          "callPut": 72.0,
          "futurePrice": 1083.1
        },
        {
          "date": "16/10/2562",
          "callPut": 98.0,
          "futurePrice": 1084.7
        },
        {
          "date": "17/10/2562",
          "callPut": 0.0,
          "futurePrice": 1088.1
        },
        {
          "date": "18/10/2562",
          "callPut": -52.0,
          "futurePrice": 1085.6
        },
        {
          "date": "21/10/2562",
          "callPut": 55.0,
          "futurePrice": 1079.3
        },
        {
          "date": "22/10/2562",
          "callPut": 13.0,
          "futurePrice": 1085.2
        },
        {
          "date": "24/10/2562",
          "callPut": 103.0,
          "futurePrice": 1082.4
        },
        {
          "date": "25/10/2562",
          "callPut": 103.0,
          "futurePrice": 1064.1
        },
        {
          "date": "28/10/2562",
          "callPut": 119.0,
          "futurePrice": 1067.5
        },
        {
          "date": "29/10/2562",
          "callPut": -22.0,
          "futurePrice": 1064.4
        },
        {
          "date": "30/10/2562",
          "callPut": 252.0,
          "futurePrice": 1074.5
        },
        {
          "date": "31/10/2562",
          "callPut": 108.0,
          "futurePrice": 1068.3
        },
        {
          "date": "1/11/2562",
          "callPut": 57.0,
          "futurePrice": 1067.4
        },
        {
          "date": "4/11/2562",
          "callPut": 58.0,
          "futurePrice": 1092.4
        },
        {
          "date": "5/11/2562",
          "callPut": -40.0,
          "futurePrice": 1096.7
        },
        {
          "date": "6/11/2562",
          "callPut": -82.0,
          "futurePrice": 1092.6
        },
        {
          "date": "7/11/2562",
          "callPut": 32.0,
          "futurePrice": 1103.4
        },
        {
          "date": "8/11/2562",
          "callPut": -28.0,
          "futurePrice": 1102.5
        },
        {
          "date": "11/11/2562",
          "callPut": 56.0,
          "futurePrice": 1094.0
        },
        {
          "date": "12/11/2562",
          "callPut": 27.0,
          "futurePrice": 1094.0
        },
        {
          "date": "13/11/2562",
          "callPut": 280.0,
          "futurePrice": 1088.8
        },
        {
          "date": "14/11/2562",
          "callPut": 63.0,
          "futurePrice": 1085.5
        },
        {
          "date": "15/11/2562",
          "callPut": -369.0,
          "futurePrice": 1083.3
        },
        {
          "date": "18/11/2562",
          "callPut": -46.0,
          "futurePrice": 1086.7
        },
        {
          "date": "19/11/2562",
          "callPut": -25.0,
          "futurePrice": 1085.3
        },
        {
          "date": "20/11/2562",
          "callPut": -153.0,
          "futurePrice": 1075.2
        },
        {
          "date": "21/11/2562",
          "callPut": 150.0,
          "futurePrice": 1073.8
        },
        {
          "date": "22/11/2562",
          "callPut": -9.0,
          "futurePrice": 1076.4
        },
        {
          "date": "25/11/2562",
          "callPut": -22.0,
          "futurePrice": 1086.0
        },
        {
          "date": "26/11/2562",
          "callPut": -304.0,
          "futurePrice": 1085.0
        },
        {
          "date": "27/11/2562",
          "callPut": -4.0,
          "futurePrice": 1087.2
        },
        {
          "date": "28/11/2562",
          "callPut": -242.0,
          "futurePrice": 1079.8
        },
        {
          "date": "29/11/2562",
          "callPut": -72.0,
          "futurePrice": 1072.1
        },
        {
          "date": "2/12/2562",
          "callPut": 329.0,
          "futurePrice": 1055.1
        },
        {
          "date": "3/12/2562",
          "callPut": 272.0,
          "futurePrice": 1053.7
        },
        {
          "date": "4/12/2562",
          "callPut": 89.0,
          "futurePrice": 1054.9
        },
        {
          "date": "6/12/2562",
          "callPut": 320.0,
          "futurePrice": 1047.5
        },
        {
          "date": "9/12/2562",
          "callPut": 173.0,
          "futurePrice": 1046.9
        },
        {
          "date": "11/12/2562",
          "callPut": 91.0,
          "futurePrice": 1048.7
        },
        {
          "date": "12/12/2562",
          "callPut": 549.0,
          "futurePrice": 1059.1
        },
        {
          "date": "13/12/2562",
          "callPut": 920.0,
          "futurePrice": 1069.2
        },
        {
          "date": "16/12/2562",
          "callPut": 720.0,
          "futurePrice": 1046.9
        },
        {
          "date": "17/12/2562",
          "callPut": 761.0,
          "futurePrice": 1051.9
        },
        {
          "date": "18/12/2562",
          "callPut": 1253.0,
          "futurePrice": 1061.4
        },
        {
          "date": "19/12/2562",
          "callPut": 990.0,
          "futurePrice": 1069.7
        },
        {
          "date": "20/12/2562",
          "callPut": 450.0,
          "futurePrice": 1064.9
        },
        {
          "date": "23/12/2562",
          "callPut": 128.0,
          "futurePrice": 1070.3
        },
        {
          "date": "24/12/2562",
          "callPut": 158.0,
          "futurePrice": 1066.0
        },
        {
          "date": "25/12/2562",
          "callPut": 449.0,
          "futurePrice": 1064.9
        },
        {
          "date": "26/12/2562",
          "callPut": 535.0,
          "futurePrice": 1067.0
        },
        {
          "date": "27/12/2562",
          "callPut": 374.0,
          "futurePrice": 1068.8
        },
        {
          "date": "30/12/2562",
          "callPut": 423.0,
          "futurePrice": 1066.9
        },
        {
          "date": "2/1/2563",
          "callPut": 519.0,
          "futurePrice": 1076.6
        },
        {
          "date": "3/1/2563",
          "callPut": 56.0,
          "futurePrice": 1075.4
        },
        {
          "date": "6/1/2563",
          "callPut": 1637.0,
          "futurePrice": 1059.2
        },
        {
          "date": "7/1/2563",
          "callPut": 296.0,
          "futurePrice": 1070.9
        },
        {
          "date": "8/1/2563",
          "callPut": 683.0,
          "futurePrice": 1054.2
        },
        {
          "date": "9/1/2563",
          "callPut": 1940.0,
          "futurePrice": 1068.0
        },
        {
          "date": "10/1/2563",
          "callPut": 369.0,
          "futurePrice": 1068.7
        },
        {
          "date": "13/1/2563",
          "callPut": -823.0,
          "futurePrice": 1071.8
        },
        {
          "date": "14/1/2563",
          "callPut": 705.0,
          "futurePrice": 1071.8
        },
        {
          "date": "15/1/2563",
          "callPut": 170.0,
          "futurePrice": 1064.5
        },
        {
          "date": "16/1/2563",
          "callPut": 95.0,
          "futurePrice": 1072.8
        },
        {
          "date": "17/1/2563",
          "callPut": -159.0,
          "futurePrice": 1077.0
        },
        {
          "date": "20/1/2563",
          "callPut": 751.0,
          "futurePrice": 1069.9
        },
        {
          "date": "21/1/2563",
          "callPut": 793.0,
          "futurePrice": 1060.5
        },
        {
          "date": "22/1/2563",
          "callPut": 1061.0,
          "futurePrice": 1060.5
        },
        {
          "date": "23/1/2563",
          "callPut": 848.0,
          "futurePrice": 1058.5
        },
        {
          "date": "24/1/2563",
          "callPut": 195.0,
          "futurePrice": 1055.3
        },
        {
          "date": "27/1/2563",
          "callPut": 3042.0,
          "futurePrice": 1017.3
        },
        {
          "date": "28/1/2563",
          "callPut": 625.0,
          "futurePrice": 1013.2
        },
        {
          "date": "29/1/2563",
          "callPut": 560.0,
          "futurePrice": 1024.1
        },
        {
          "date": "30/1/2563",
          "callPut": 914.0,
          "futurePrice": 1018.9
        },
        {
          "date": "31/1/2563",
          "callPut": 394.0,
          "futurePrice": 1013.2
        },
        {
          "date": "3/2/2563",
          "callPut": 1613.0,
          "futurePrice": 1001.4
        },
        {
          "date": "4/2/2563",
          "callPut": 1111.0,
          "futurePrice": 1017.9
        },
        {
          "date": "5/2/2563",
          "callPut": 1045.0,
          "futurePrice": 1027.3
        },
        {
          "date": "6/2/2563",
          "callPut": 1201.0,
          "futurePrice": 1024.0
        },
        {
          "date": "7/2/2563",
          "callPut": 442.0,
          "futurePrice": 1023.6
        },
        {
          "date": "11/2/2563",
          "callPut": -58.0,
          "futurePrice": 1017.7
        },
        {
          "date": "12/2/2563",
          "callPut": 32.0,
          "futurePrice": 1028.2
        },
        {
          "date": "13/2/2563",
          "callPut": 222.0,
          "futurePrice": 1023.9
        },
        {
          "date": "14/2/2563",
          "callPut": 116.0,
          "futurePrice": 1019.6
        },
        {
          "date": "17/2/2563",
          "callPut": 165.0,
          "futurePrice": 1020.2
        },
        {
          "date": "18/2/2563",
          "callPut": -374.0,
          "futurePrice": 1012.5
        },
        {
          "date": "19/2/2563",
          "callPut": -480.0,
          "futurePrice": 1010.8
        },
        {
          "date": "20/2/2563",
          "callPut": -216.0,
          "futurePrice": 995.9
        },
        {
          "date": "21/2/2563",
          "callPut": 313.0,
          "futurePrice": 1000.8
        },
        {
          "date": "24/2/2563",
          "callPut": 698.0,
          "futurePrice": 953.6
        },
        {
          "date": "25/2/2563",
          "callPut": 803.0,
          "futurePrice": 962.1
        },
        {
          "date": "26/2/2563",
          "callPut": 2760.0,
          "futurePrice": 918.9
        },
        {
          "date": "27/2/2563",
          "callPut": 1650.0,
          "futurePrice": 932.6
        },
        {
          "date": "28/2/2563",
          "callPut": 1483.0,
          "futurePrice": 894.3
        },
        {
          "date": "2/3/2563",
          "callPut": 2030.0,
          "futurePrice": 893.3
        },
        {
          "date": "3/3/2563",
          "callPut": 661.0,
          "futurePrice": 924.3
        },
        {
          "date": "4/3/2563",
          "callPut": 3516.0,
          "futurePrice": 927.4
        },
        {
          "date": "5/3/2563",
          "callPut": 1326.0,
          "futurePrice": 931.8
        },
        {
          "date": "6/3/2563",
          "callPut": 124.0,
          "futurePrice": 913.7
        },
        {
          "date": "9/3/2563",
          "callPut": 1380.0,
          "futurePrice": 829.8
        },
        {
          "date": "10/3/2563",
          "callPut": 941.0,
          "futurePrice": 843.3
        },
        {
          "date": "11/3/2563",
          "callPut": 2357.0,
          "futurePrice": 821.6
        },
        {
          "date": "12/3/2563",
          "callPut": 3747.0,
          "futurePrice": 722.6
        },
        {
          "date": "13/3/2563",
          "callPut": 2871.0,
          "futurePrice": 745.2
        },
        {
          "date": "16/3/2563",
          "callPut": 4684.0,
          "futurePrice": 681.3
        },
        {
          "date": "17/3/2563",
          "callPut": 2808.0,
          "futurePrice": 676.5
        },
        {
          "date": "18/3/2563",
          "callPut": 1578.0,
          "futurePrice": 692.6
        },
        {
          "date": "19/3/2563",
          "callPut": 991.0,
          "futurePrice": 688.5
        },
        {
          "date": "20/3/2563",
          "callPut": 2069.0,
          "futurePrice": 757.4
        },
        {
          "date": "23/3/2563",
          "callPut": 391.0,
          "futurePrice": 672.0
        },
        {
          "date": "24/3/2563",
          "callPut": 1688.0,
          "futurePrice": 696.1
        },
        {
          "date": "25/3/2563",
          "callPut": 648.0,
          "futurePrice": 723.9
        },
        {
          "date": "26/3/2563",
          "callPut": 178.0,
          "futurePrice": 737.7
        },
        {
          "date": "27/3/2563",
          "callPut": 347.0,
          "futurePrice": 731.6
        },
        {
          "date": "30/3/2563",
          "callPut": 64.0,
          "futurePrice": 722.0
        },
        {
          "date": "31/3/2563",
          "callPut": 0.0,
          "futurePrice": 742.9
        },
        {
          "date": "1/4/2563",
          "callPut": 0.0,
          "futurePrice": 731.1
        }
      ],
      openInterestData: [
        {
          "date": "27/9/2562",
          "OI": 51.0,
          "futurePrice": 1087.9
        },
        {
          "date": "30/9/2562",
          "OI": 36.0,
          "futurePrice": 1084.8
        },
        {
          "date": "1/10/2562",
          "OI": 52.0,
          "futurePrice": 1074.9
        },
        {
          "date": "2/10/2562",
          "OI": 28.0,
          "futurePrice": 1067.1
        },
        {
          "date": "3/10/2562",
          "OI": 10.0,
          "futurePrice": 1067.8
        },
        {
          "date": "4/10/2562",
          "OI": 66.0,
          "futurePrice": 1064.8
        },
        {
          "date": "7/10/2562",
          "OI": 52.0,
          "futurePrice": 1069.2
        },
        {
          "date": "8/10/2562",
          "OI": 82.0,
          "futurePrice": 1067.6
        },
        {
          "date": "9/10/2562",
          "OI": 150.0,
          "futurePrice": 1074.5
        },
        {
          "date": "10/10/2562",
          "OI": 132.0,
          "futurePrice": 1066.3
        },
        {
          "date": "11/10/2562",
          "OI": 110.0,
          "futurePrice": 1079.5
        },
        {
          "date": "15/10/2562",
          "OI": 137.0,
          "futurePrice": 1083.1
        },
        {
          "date": "16/10/2562",
          "OI": 232.0,
          "futurePrice": 1084.7
        },
        {
          "date": "17/10/2562",
          "OI": 222.0,
          "futurePrice": 1088.1
        },
        {
          "date": "18/10/2562",
          "OI": 232.0,
          "futurePrice": 1085.6
        },
        {
          "date": "21/10/2562",
          "OI": 194.0,
          "futurePrice": 1079.3
        },
        {
          "date": "22/10/2562",
          "OI": 229.0,
          "futurePrice": 1085.2
        },
        {
          "date": "24/10/2562",
          "OI": 297.0,
          "futurePrice": 1082.4
        },
        {
          "date": "25/10/2562",
          "OI": 193.0,
          "futurePrice": 1064.1
        },
        {
          "date": "28/10/2562",
          "OI": 268.0,
          "futurePrice": 1067.5
        },
        {
          "date": "29/10/2562",
          "OI": 239.0,
          "futurePrice": 1064.4
        },
        {
          "date": "30/10/2562",
          "OI": 446.0,
          "futurePrice": 1074.5
        },
        {
          "date": "31/10/2562",
          "OI": 395.0,
          "futurePrice": 1068.3
        },
        {
          "date": "1/11/2562",
          "OI": 437.0,
          "futurePrice": 1067.4
        },
        {
          "date": "4/11/2562",
          "OI": 532.0,
          "futurePrice": 1092.4
        },
        {
          "date": "5/11/2562",
          "OI": 365.0,
          "futurePrice": 1096.7
        },
        {
          "date": "6/11/2562",
          "OI": 354.0,
          "futurePrice": 1092.6
        },
        {
          "date": "7/11/2562",
          "OI": 446.0,
          "futurePrice": 1103.4
        },
        {
          "date": "8/11/2562",
          "OI": 468.0,
          "futurePrice": 1102.5
        },
        {
          "date": "11/11/2562",
          "OI": 484.0,
          "futurePrice": 1094.0
        },
        {
          "date": "12/11/2562",
          "OI": 411.0,
          "futurePrice": 1094.0
        },
        {
          "date": "13/11/2562",
          "OI": 467.0,
          "futurePrice": 1088.8
        },
        {
          "date": "14/11/2562",
          "OI": 362.0,
          "futurePrice": 1085.5
        },
        {
          "date": "15/11/2562",
          "OI": -18.0,
          "futurePrice": 1083.3
        },
        {
          "date": "18/11/2562",
          "OI": -48.0,
          "futurePrice": 1086.7
        },
        {
          "date": "19/11/2562",
          "OI": -70.0,
          "futurePrice": 1085.3
        },
        {
          "date": "20/11/2562",
          "OI": -134.0,
          "futurePrice": 1075.2
        },
        {
          "date": "21/11/2562",
          "OI": -8.0,
          "futurePrice": 1073.8
        },
        {
          "date": "22/11/2562",
          "OI": -59.0,
          "futurePrice": 1076.4
        },
        {
          "date": "25/11/2562",
          "OI": 7.0,
          "futurePrice": 1086.0
        },
        {
          "date": "26/11/2562",
          "OI": -221.0,
          "futurePrice": 1085.0
        },
        {
          "date": "27/11/2562",
          "OI": -224.0,
          "futurePrice": 1087.2
        },
        {
          "date": "28/11/2562",
          "OI": -475.0,
          "futurePrice": 1079.8
        },
        {
          "date": "29/11/2562",
          "OI": -662.0,
          "futurePrice": 1072.1
        },
        {
          "date": "2/12/2562",
          "OI": -797.0,
          "futurePrice": 1055.1
        },
        {
          "date": "3/12/2562",
          "OI": -835.0,
          "futurePrice": 1053.7
        },
        {
          "date": "4/12/2562",
          "OI": -874.0,
          "futurePrice": 1054.9
        },
        {
          "date": "6/12/2562",
          "OI": -892.0,
          "futurePrice": 1047.5
        },
        {
          "date": "9/12/2562",
          "OI": -858.0,
          "futurePrice": 1046.9
        },
        {
          "date": "11/12/2562",
          "OI": -940.0,
          "futurePrice": 1048.7
        },
        {
          "date": "12/12/2562",
          "OI": -978.0,
          "futurePrice": 1059.1
        },
        {
          "date": "13/12/2562",
          "OI": -607.0,
          "futurePrice": 1069.2
        },
        {
          "date": "16/12/2562",
          "OI": -788.0,
          "futurePrice": 1046.9
        },
        {
          "date": "17/12/2562",
          "OI": -386.0,
          "futurePrice": 1051.9
        },
        {
          "date": "18/12/2562",
          "OI": 124.0,
          "futurePrice": 1061.4
        },
        {
          "date": "19/12/2562",
          "OI": 390.0,
          "futurePrice": 1069.7
        },
        {
          "date": "20/12/2562",
          "OI": 483.0,
          "futurePrice": 1064.9
        },
        {
          "date": "23/12/2562",
          "OI": 423.0,
          "futurePrice": 1070.3
        },
        {
          "date": "24/12/2562",
          "OI": 298.0,
          "futurePrice": 1066.0
        },
        {
          "date": "25/12/2562",
          "OI": 283.0,
          "futurePrice": 1064.9
        },
        {
          "date": "26/12/2562",
          "OI": 428.0,
          "futurePrice": 1067.0
        },
        {
          "date": "27/12/2562",
          "OI": 295.0,
          "futurePrice": 1068.8
        },
        {
          "date": "30/12/2562",
          "OI": 515.0,
          "futurePrice": 1066.9
        },
        {
          "date": "2/1/2563",
          "OI": 112.0,
          "futurePrice": 1076.6
        },
        {
          "date": "3/1/2563",
          "OI": -339.0,
          "futurePrice": 1075.4
        },
        {
          "date": "6/1/2563",
          "OI": -781.0,
          "futurePrice": 1059.2
        },
        {
          "date": "7/1/2563",
          "OI": -1137.0,
          "futurePrice": 1070.9
        },
        {
          "date": "8/1/2563",
          "OI": -1723.0,
          "futurePrice": 1054.2
        },
        {
          "date": "9/1/2563",
          "OI": -1641.0,
          "futurePrice": 1068.0
        },
        {
          "date": "10/1/2563",
          "OI": -1864.0,
          "futurePrice": 1068.7
        },
        {
          "date": "13/1/2563",
          "OI": -1967.0,
          "futurePrice": 1071.8
        },
        {
          "date": "14/1/2563",
          "OI": -2423.0,
          "futurePrice": 1071.8
        },
        {
          "date": "15/1/2563",
          "OI": -2612.0,
          "futurePrice": 1064.5
        },
        {
          "date": "16/1/2563",
          "OI": -2344.0,
          "futurePrice": 1072.8
        },
        {
          "date": "17/1/2563",
          "OI": -2948.0,
          "futurePrice": 1077.0
        },
        {
          "date": "20/1/2563",
          "OI": -3038.0,
          "futurePrice": 1069.9
        },
        {
          "date": "21/1/2563",
          "OI": -3203.0,
          "futurePrice": 1060.5
        },
        {
          "date": "22/1/2563",
          "OI": -3287.0,
          "futurePrice": 1060.5
        },
        {
          "date": "23/1/2563",
          "OI": -3593.0,
          "futurePrice": 1058.5
        },
        {
          "date": "24/1/2563",
          "OI": -3425.0,
          "futurePrice": 1055.3
        },
        {
          "date": "27/1/2563",
          "OI": -3744.0,
          "futurePrice": 1017.3
        },
        {
          "date": "28/1/2563",
          "OI": -3212.0,
          "futurePrice": 1013.2
        },
        {
          "date": "29/1/2563",
          "OI": -2841.0,
          "futurePrice": 1024.1
        },
        {
          "date": "30/1/2563",
          "OI": -3045.0,
          "futurePrice": 1018.9
        },
        {
          "date": "31/1/2563",
          "OI": -3661.0,
          "futurePrice": 1013.2
        },
        {
          "date": "3/2/2563",
          "OI": -3631.0,
          "futurePrice": 1001.4
        },
        {
          "date": "4/2/2563",
          "OI": -3883.0,
          "futurePrice": 1017.9
        },
        {
          "date": "5/2/2563",
          "OI": -4151.0,
          "futurePrice": 1027.3
        },
        {
          "date": "6/2/2563",
          "OI": -4325.0,
          "futurePrice": 1024.0
        },
        {
          "date": "7/2/2563",
          "OI": -5010.0,
          "futurePrice": 1023.6
        },
        {
          "date": "11/2/2563",
          "OI": -4958.0,
          "futurePrice": 1017.7
        },
        {
          "date": "12/2/2563",
          "OI": -5054.0,
          "futurePrice": 1028.2
        },
        {
          "date": "13/2/2563",
          "OI": -5363.0,
          "futurePrice": 1023.9
        },
        {
          "date": "14/2/2563",
          "OI": -5665.0,
          "futurePrice": 1019.6
        },
        {
          "date": "17/2/2563",
          "OI": -5620.0,
          "futurePrice": 1020.2
        },
        {
          "date": "18/2/2563",
          "OI": -6577.0,
          "futurePrice": 1012.5
        },
        {
          "date": "19/2/2563",
          "OI": -7084.0,
          "futurePrice": 1010.8
        },
        {
          "date": "20/2/2563",
          "OI": -7350.0,
          "futurePrice": 995.9
        },
        {
          "date": "21/2/2563",
          "OI": -7846.0,
          "futurePrice": 1000.8
        },
        {
          "date": "24/2/2563",
          "OI": -4519.0,
          "futurePrice": 953.6
        },
        {
          "date": "25/2/2563",
          "OI": -4155.0,
          "futurePrice": 962.1
        },
        {
          "date": "26/2/2563",
          "OI": -1863.0,
          "futurePrice": 918.9
        },
        {
          "date": "27/2/2563",
          "OI": -1585.0,
          "futurePrice": 932.6
        },
        {
          "date": "28/2/2563",
          "OI": -864.0,
          "futurePrice": 894.3
        },
        {
          "date": "2/3/2563",
          "OI": 108.0,
          "futurePrice": 893.3
        },
        {
          "date": "3/3/2563",
          "OI": -1371.0,
          "futurePrice": 924.3
        },
        {
          "date": "4/3/2563",
          "OI": -2510.0,
          "futurePrice": 927.4
        },
        {
          "date": "5/3/2563",
          "OI": -4157.0,
          "futurePrice": 931.8
        },
        {
          "date": "6/3/2563",
          "OI": -4321.0,
          "futurePrice": 913.7
        },
        {
          "date": "9/3/2563",
          "OI": -1027.0,
          "futurePrice": 829.8
        },
        {
          "date": "10/3/2563",
          "OI": -1658.0,
          "futurePrice": 843.3
        },
        {
          "date": "11/3/2563",
          "OI": -1661.0,
          "futurePrice": 821.6
        },
        {
          "date": "12/3/2563",
          "OI": 1624.0,
          "futurePrice": 722.6
        },
        {
          "date": "13/3/2563",
          "OI": 2157.0,
          "futurePrice": 745.2
        },
        {
          "date": "16/3/2563",
          "OI": 1120.0,
          "futurePrice": 681.3
        },
        {
          "date": "17/3/2563",
          "OI": 637.0,
          "futurePrice": 676.5
        },
        {
          "date": "18/3/2563",
          "OI": 591.0,
          "futurePrice": 692.6
        },
        {
          "date": "19/3/2563",
          "OI": 541.0,
          "futurePrice": 688.5
        },
        {
          "date": "20/3/2563",
          "OI": -289.0,
          "futurePrice": 757.4
        },
        {
          "date": "23/3/2563",
          "OI": 1146.0,
          "futurePrice": 672.0
        },
        {
          "date": "24/3/2563",
          "OI": 962.0,
          "futurePrice": 696.1
        },
        {
          "date": "25/3/2563",
          "OI": 655.0,
          "futurePrice": 723.9
        },
        {
          "date": "26/3/2563",
          "OI": 342.0,
          "futurePrice": 737.7
        },
        {
          "date": "27/3/2563",
          "OI": -218.0,
          "futurePrice": 731.6
        },
        {
          "date": "30/3/2563",
          "OI": 0.0,
          "futurePrice": 722.0
        },
        {
          "date": "31/3/2563",
          "OI": 0.0,
          "futurePrice": 742.9
        },
        {
          "date": "1/4/2563",
          "OI": 0.0,
          "futurePrice": 731.1
        }
      ]
    },
    S50M20: {
      volumeData: [
        {
          "date": "27/12/2562",
          "callPut": 54.0,
          "futurePrice": 1068.8
        },
        {
          "date": "30/12/2562",
          "callPut": 83.0,
          "futurePrice": 1066.9
        },
        {
          "date": "2/1/2563",
          "callPut": 74.0,
          "futurePrice": 1076.6
        },
        {
          "date": "3/1/2563",
          "callPut": 78.0,
          "futurePrice": 1075.4
        },
        {
          "date": "6/1/2563",
          "callPut": 248.0,
          "futurePrice": 1059.2
        },
        {
          "date": "7/1/2563",
          "callPut": 49.0,
          "futurePrice": 1070.9
        },
        {
          "date": "8/1/2563",
          "callPut": -1.0,
          "futurePrice": 1054.2
        },
        {
          "date": "9/1/2563",
          "callPut": 38.0,
          "futurePrice": 1068.0
        },
        {
          "date": "10/1/2563",
          "callPut": -43.0,
          "futurePrice": 1068.7
        },
        {
          "date": "13/1/2563",
          "callPut": 52.0,
          "futurePrice": 1071.8
        },
        {
          "date": "14/1/2563",
          "callPut": 25.0,
          "futurePrice": 1071.8
        },
        {
          "date": "15/1/2563",
          "callPut": 27.0,
          "futurePrice": 1064.5
        },
        {
          "date": "16/1/2563",
          "callPut": 148.0,
          "futurePrice": 1072.8
        },
        {
          "date": "17/1/2563",
          "callPut": -35.0,
          "futurePrice": 1077.0
        },
        {
          "date": "20/1/2563",
          "callPut": -122.0,
          "futurePrice": 1069.9
        },
        {
          "date": "21/1/2563",
          "callPut": -63.0,
          "futurePrice": 1060.5
        },
        {
          "date": "22/1/2563",
          "callPut": -6.0,
          "futurePrice": 1060.5
        },
        {
          "date": "23/1/2563",
          "callPut": -41.0,
          "futurePrice": 1058.5
        },
        {
          "date": "24/1/2563",
          "callPut": -56.0,
          "futurePrice": 1055.3
        },
        {
          "date": "27/1/2563",
          "callPut": 281.0,
          "futurePrice": 1017.3
        },
        {
          "date": "28/1/2563",
          "callPut": 253.0,
          "futurePrice": 1013.2
        },
        {
          "date": "29/1/2563",
          "callPut": -77.0,
          "futurePrice": 1024.1
        },
        {
          "date": "30/1/2563",
          "callPut": -21.0,
          "futurePrice": 1018.9
        },
        {
          "date": "31/1/2563",
          "callPut": -32.0,
          "futurePrice": 1013.2
        },
        {
          "date": "3/2/2563",
          "callPut": -112.0,
          "futurePrice": 1001.4
        },
        {
          "date": "4/2/2563",
          "callPut": 26.0,
          "futurePrice": 1017.9
        },
        {
          "date": "5/2/2563",
          "callPut": -82.0,
          "futurePrice": 1027.3
        },
        {
          "date": "6/2/2563",
          "callPut": 132.0,
          "futurePrice": 1024.0
        },
        {
          "date": "7/2/2563",
          "callPut": -20.0,
          "futurePrice": 1023.6
        },
        {
          "date": "11/2/2563",
          "callPut": 25.0,
          "futurePrice": 1017.7
        },
        {
          "date": "12/2/2563",
          "callPut": 91.0,
          "futurePrice": 1028.2
        },
        {
          "date": "13/2/2563",
          "callPut": 106.0,
          "futurePrice": 1023.9
        },
        {
          "date": "14/2/2563",
          "callPut": 43.0,
          "futurePrice": 1019.6
        },
        {
          "date": "17/2/2563",
          "callPut": 121.0,
          "futurePrice": 1020.2
        },
        {
          "date": "18/2/2563",
          "callPut": 16.0,
          "futurePrice": 1012.5
        },
        {
          "date": "19/2/2563",
          "callPut": 0.0,
          "futurePrice": 1010.8
        },
        {
          "date": "20/2/2563",
          "callPut": 126.0,
          "futurePrice": 995.9
        },
        {
          "date": "21/2/2563",
          "callPut": -39.0,
          "futurePrice": 1000.8
        },
        {
          "date": "24/2/2563",
          "callPut": 523.0,
          "futurePrice": 953.6
        },
        {
          "date": "25/2/2563",
          "callPut": -225.0,
          "futurePrice": 962.1
        },
        {
          "date": "26/2/2563",
          "callPut": -226.0,
          "futurePrice": 918.9
        },
        {
          "date": "27/2/2563",
          "callPut": 464.0,
          "futurePrice": 932.6
        },
        {
          "date": "28/2/2563",
          "callPut": 521.0,
          "futurePrice": 894.3
        },
        {
          "date": "2/3/2563",
          "callPut": 240.0,
          "futurePrice": 893.3
        },
        {
          "date": "3/3/2563",
          "callPut": 1056.0,
          "futurePrice": 924.3
        },
        {
          "date": "4/3/2563",
          "callPut": 546.0,
          "futurePrice": 927.4
        },
        {
          "date": "5/3/2563",
          "callPut": -26.0,
          "futurePrice": 931.8
        },
        {
          "date": "6/3/2563",
          "callPut": -305.0,
          "futurePrice": 913.7
        },
        {
          "date": "9/3/2563",
          "callPut": 1055.0,
          "futurePrice": 829.8
        },
        {
          "date": "10/3/2563",
          "callPut": 189.0,
          "futurePrice": 843.3
        },
        {
          "date": "11/3/2563",
          "callPut": 1006.0,
          "futurePrice": 821.6
        },
        {
          "date": "12/3/2563",
          "callPut": 747.0,
          "futurePrice": 722.6
        },
        {
          "date": "13/3/2563",
          "callPut": 882.0,
          "futurePrice": 745.2
        },
        {
          "date": "16/3/2563",
          "callPut": 1216.0,
          "futurePrice": 681.3
        },
        {
          "date": "17/3/2563",
          "callPut": 572.0,
          "futurePrice": 676.5
        },
        {
          "date": "18/3/2563",
          "callPut": 404.0,
          "futurePrice": 692.6
        },
        {
          "date": "19/3/2563",
          "callPut": 473.0,
          "futurePrice": 688.5
        },
        {
          "date": "20/3/2563",
          "callPut": 1123.0,
          "futurePrice": 757.4
        },
        {
          "date": "23/3/2563",
          "callPut": 372.0,
          "futurePrice": 672.0
        },
        {
          "date": "24/3/2563",
          "callPut": 165.0,
          "futurePrice": 696.1
        },
        {
          "date": "25/3/2563",
          "callPut": 428.0,
          "futurePrice": 723.9
        },
        {
          "date": "26/3/2563",
          "callPut": 175.0,
          "futurePrice": 737.7
        },
        {
          "date": "27/3/2563",
          "callPut": 309.0,
          "futurePrice": 731.6
        },
        {
          "date": "30/3/2563",
          "callPut": 231.0,
          "futurePrice": 722.0
        },
        {
          "date": "31/3/2563",
          "callPut": 752.0,
          "futurePrice": 742.9
        },
        {
          "date": "1/4/2563",
          "callPut": 1022.0,
          "futurePrice": 731.1
        },
        {
          "date": "2/4/2563",
          "callPut": 1308.0,
          "futurePrice": 757.8
        },
        {
          "date": "3/4/2563",
          "callPut": 664.0,
          "futurePrice": 761.7
        },
        {
          "date": "7/4/2563",
          "callPut": 2287.0,
          "futurePrice": 822.2
        },
        {
          "date": "8/4/2563",
          "callPut": 378.0,
          "futurePrice": 812.5
        },
        {
          "date": "9/4/2563",
          "callPut": 1166.0,
          "futurePrice": 813.9
        },
        {
          "date": "10/4/2563",
          "callPut": 653.0,
          "futurePrice": 826.3
        },
        {
          "date": "13/4/2563",
          "callPut": -73.0,
          "futurePrice": 827.2
        },
        {
          "date": "14/4/2563",
          "callPut": 136.0,
          "futurePrice": 841.1
        },
        {
          "date": "15/4/2563",
          "callPut": -172.0,
          "futurePrice": 824.0
        },
        {
          "date": "16/4/2563",
          "callPut": -295.0,
          "futurePrice": 797.7
        },
        {
          "date": "17/4/2563",
          "callPut": -445.0,
          "futurePrice": 834.6
        },
        {
          "date": "20/4/2563",
          "callPut": -188.0,
          "futurePrice": 850.9
        },
        {
          "date": "21/4/2563",
          "callPut": -696.0,
          "futurePrice": 835.5
        },
        {
          "date": "22/4/2563",
          "callPut": -777.0,
          "futurePrice": 841.4
        },
        {
          "date": "23/4/2563",
          "callPut": -64.0,
          "futurePrice": 850.6
        },
        {
          "date": "24/4/2563",
          "callPut": -319.0,
          "futurePrice": 840.5
        },
        {
          "date": "27/4/2563",
          "callPut": -366.0,
          "futurePrice": 845.5
        },
        {
          "date": "28/4/2563",
          "callPut": -122.0,
          "futurePrice": 854.4
        },
        {
          "date": "29/4/2563",
          "callPut": -295.0,
          "futurePrice": 857.7
        },
        {
          "date": "30/4/2563",
          "callPut": -438.0,
          "futurePrice": 872.5
        },
        {
          "date": "5/5/2563",
          "callPut": -1923.0,
          "futurePrice": 853.3
        },
        {
          "date": "7/5/2563",
          "callPut": -2103.0,
          "futurePrice": 841.9
        },
        {
          "date": "8/5/2563",
          "callPut": -17.0,
          "futurePrice": 845.3
        },
        {
          "date": "11/5/2563",
          "callPut": -385.0,
          "futurePrice": 859.4
        },
        {
          "date": "12/5/2563",
          "callPut": -383.0,
          "futurePrice": 867.6
        },
        {
          "date": "13/5/2563",
          "callPut": -662.0,
          "futurePrice": 864.0
        },
        {
          "date": "14/5/2563",
          "callPut": -205.0,
          "futurePrice": 855.1
        },
        {
          "date": "15/5/2563",
          "callPut": -258.0,
          "futurePrice": 846.1
        },
        {
          "date": "18/5/2563",
          "callPut": -232.0,
          "futurePrice": 858.5
        },
        {
          "date": "19/5/2563",
          "callPut": -877.0,
          "futurePrice": 875.2
        },
        {
          "date": "20/5/2563",
          "callPut": -353.0,
          "futurePrice": 882.8
        },
        {
          "date": "21/5/2563",
          "callPut": -1008.0,
          "futurePrice": 878.6
        },
        {
          "date": "22/5/2563",
          "callPut": -1032.0,
          "futurePrice": 861.5
        },
        {
          "date": "25/5/2563",
          "callPut": -262.0,
          "futurePrice": 876.2
        },
        {
          "date": "26/5/2563",
          "callPut": -440.0,
          "futurePrice": 886.8
        },
        {
          "date": "27/5/2563",
          "callPut": -285.0,
          "futurePrice": 894.8
        },
        {
          "date": "28/5/2563",
          "callPut": -672.0,
          "futurePrice": 882.4
        },
        {
          "date": "29/5/2563",
          "callPut": -718.0,
          "futurePrice": 892.4
        },
        {
          "date": "1/6/2563",
          "callPut": 390.0,
          "futurePrice": 900.5
        },
        {
          "date": "2/6/2563",
          "callPut": -79.0,
          "futurePrice": 918.5
        },
        {
          "date": "4/6/2563",
          "callPut": 1207.0,
          "futurePrice": 945.8
        },
        {
          "date": "5/6/2563",
          "callPut": 328.0,
          "futurePrice": 962.2
        },
        {
          "date": "8/6/2563",
          "callPut": 914.0,
          "futurePrice": 964.1
        },
        {
          "date": "9/6/2563",
          "callPut": -411.0,
          "futurePrice": 939.3
        },
        {
          "date": "10/6/2563",
          "callPut": 14.0,
          "futurePrice": 950.6
        },
        {
          "date": "11/6/2563",
          "callPut": -935.0,
          "futurePrice": 931.1
        },
        {
          "date": "12/6/2563",
          "callPut": -1754.0,
          "futurePrice": 917.1
        },
        {
          "date": "15/6/2563",
          "callPut": -825.0,
          "futurePrice": 891.7
        },
        {
          "date": "16/6/2563",
          "callPut": 1772.0,
          "futurePrice": 912.2
        },
        {
          "date": "17/6/2563",
          "callPut": -657.0,
          "futurePrice": 908.6
        },
        {
          "date": "18/6/2563",
          "callPut": -360.0,
          "futurePrice": 909.4
        },
        {
          "date": "19/6/2563",
          "callPut": -25.0,
          "futurePrice": 910.0
        },
        {
          "date": "22/6/2563",
          "callPut": -221.0,
          "futurePrice": 896.8
        },
        {
          "date": "23/6/2563",
          "callPut": -519.0,
          "futurePrice": 896.8
        },
        {
          "date": "24/6/2563",
          "callPut": -398.0,
          "futurePrice": 878.9
        },
        {
          "date": "25/6/2563",
          "callPut": -1369.0,
          "futurePrice": 871.6
        },
        {
          "date": "26/6/2563",
          "callPut": -81.0,
          "futurePrice": 872.3
        },
        {
          "date": "29/6/2563",
          "callPut": -1214.0,
          "futurePrice": 874.5
        },
        {
          "date": "30/6/2563",
          "callPut": 0.0,
          "futurePrice": 876.6
        },
        {
          "date": "1/7/2563",
          "callPut": 0.0,
          "futurePrice": 884.5
        }
      ],
      openInterestData: [
        {
          "date": "27/12/2562",
          "OI": 55.0,
          "futurePrice": 1068.8
        },
        {
          "date": "30/12/2562",
          "OI": 145.0,
          "futurePrice": 1066.9
        },
        {
          "date": "2/1/2563",
          "OI": 238.0,
          "futurePrice": 1076.6
        },
        {
          "date": "3/1/2563",
          "OI": 319.0,
          "futurePrice": 1075.4
        },
        {
          "date": "6/1/2563",
          "OI": 527.0,
          "futurePrice": 1059.2
        },
        {
          "date": "7/1/2563",
          "OI": 503.0,
          "futurePrice": 1070.9
        },
        {
          "date": "8/1/2563",
          "OI": 465.0,
          "futurePrice": 1054.2
        },
        {
          "date": "9/1/2563",
          "OI": 434.0,
          "futurePrice": 1068.0
        },
        {
          "date": "10/1/2563",
          "OI": 426.0,
          "futurePrice": 1068.7
        },
        {
          "date": "13/1/2563",
          "OI": 442.0,
          "futurePrice": 1071.8
        },
        {
          "date": "14/1/2563",
          "OI": 450.0,
          "futurePrice": 1071.8
        },
        {
          "date": "15/1/2563",
          "OI": 451.0,
          "futurePrice": 1064.5
        },
        {
          "date": "16/1/2563",
          "OI": 558.0,
          "futurePrice": 1072.8
        },
        {
          "date": "17/1/2563",
          "OI": 592.0,
          "futurePrice": 1077.0
        },
        {
          "date": "20/1/2563",
          "OI": 441.0,
          "futurePrice": 1069.9
        },
        {
          "date": "21/1/2563",
          "OI": 360.0,
          "futurePrice": 1060.5
        },
        {
          "date": "22/1/2563",
          "OI": 357.0,
          "futurePrice": 1060.5
        },
        {
          "date": "23/1/2563",
          "OI": 300.0,
          "futurePrice": 1058.5
        },
        {
          "date": "24/1/2563",
          "OI": 220.0,
          "futurePrice": 1055.3
        },
        {
          "date": "27/1/2563",
          "OI": 232.0,
          "futurePrice": 1017.3
        },
        {
          "date": "28/1/2563",
          "OI": 232.0,
          "futurePrice": 1013.2
        },
        {
          "date": "29/1/2563",
          "OI": 138.0,
          "futurePrice": 1024.1
        },
        {
          "date": "30/1/2563",
          "OI": 164.0,
          "futurePrice": 1018.9
        },
        {
          "date": "31/1/2563",
          "OI": 162.0,
          "futurePrice": 1013.2
        },
        {
          "date": "3/2/2563",
          "OI": 140.0,
          "futurePrice": 1001.4
        },
        {
          "date": "4/2/2563",
          "OI": 47.0,
          "futurePrice": 1017.9
        },
        {
          "date": "5/2/2563",
          "OI": -254.0,
          "futurePrice": 1027.3
        },
        {
          "date": "6/2/2563",
          "OI": -249.0,
          "futurePrice": 1024.0
        },
        {
          "date": "7/2/2563",
          "OI": -239.0,
          "futurePrice": 1023.6
        },
        {
          "date": "11/2/2563",
          "OI": -221.0,
          "futurePrice": 1017.7
        },
        {
          "date": "12/2/2563",
          "OI": -245.0,
          "futurePrice": 1028.2
        },
        {
          "date": "13/2/2563",
          "OI": -237.0,
          "futurePrice": 1023.9
        },
        {
          "date": "14/2/2563",
          "OI": -195.0,
          "futurePrice": 1019.6
        },
        {
          "date": "17/2/2563",
          "OI": -120.0,
          "futurePrice": 1020.2
        },
        {
          "date": "18/2/2563",
          "OI": 3.0,
          "futurePrice": 1012.5
        },
        {
          "date": "19/2/2563",
          "OI": -11.0,
          "futurePrice": 1010.8
        },
        {
          "date": "20/2/2563",
          "OI": -207.0,
          "futurePrice": 995.9
        },
        {
          "date": "21/2/2563",
          "OI": -266.0,
          "futurePrice": 1000.8
        },
        {
          "date": "24/2/2563",
          "OI": -243.0,
          "futurePrice": 953.6
        },
        {
          "date": "25/2/2563",
          "OI": -348.0,
          "futurePrice": 962.1
        },
        {
          "date": "26/2/2563",
          "OI": -429.0,
          "futurePrice": 918.9
        },
        {
          "date": "27/2/2563",
          "OI": -383.0,
          "futurePrice": 932.6
        },
        {
          "date": "28/2/2563",
          "OI": -205.0,
          "futurePrice": 894.3
        },
        {
          "date": "2/3/2563",
          "OI": -224.0,
          "futurePrice": 893.3
        },
        {
          "date": "3/3/2563",
          "OI": -5.0,
          "futurePrice": 924.3
        },
        {
          "date": "4/3/2563",
          "OI": 26.0,
          "futurePrice": 927.4
        },
        {
          "date": "5/3/2563",
          "OI": -107.0,
          "futurePrice": 931.8
        },
        {
          "date": "6/3/2563",
          "OI": -614.0,
          "futurePrice": 913.7
        },
        {
          "date": "9/3/2563",
          "OI": -413.0,
          "futurePrice": 829.8
        },
        {
          "date": "10/3/2563",
          "OI": -290.0,
          "futurePrice": 843.3
        },
        {
          "date": "11/3/2563",
          "OI": -164.0,
          "futurePrice": 821.6
        },
        {
          "date": "12/3/2563",
          "OI": 177.0,
          "futurePrice": 722.6
        },
        {
          "date": "13/3/2563",
          "OI": 210.0,
          "futurePrice": 745.2
        },
        {
          "date": "16/3/2563",
          "OI": 710.0,
          "futurePrice": 681.3
        },
        {
          "date": "17/3/2563",
          "OI": 845.0,
          "futurePrice": 676.5
        },
        {
          "date": "18/3/2563",
          "OI": 1088.0,
          "futurePrice": 692.6
        },
        {
          "date": "19/3/2563",
          "OI": 1099.0,
          "futurePrice": 688.5
        },
        {
          "date": "20/3/2563",
          "OI": 862.0,
          "futurePrice": 757.4
        },
        {
          "date": "23/3/2563",
          "OI": 657.0,
          "futurePrice": 672.0
        },
        {
          "date": "24/3/2563",
          "OI": 628.0,
          "futurePrice": 696.1
        },
        {
          "date": "25/3/2563",
          "OI": 546.0,
          "futurePrice": 723.9
        },
        {
          "date": "26/3/2563",
          "OI": 451.0,
          "futurePrice": 737.7
        },
        {
          "date": "27/3/2563",
          "OI": 354.0,
          "futurePrice": 731.6
        },
        {
          "date": "30/3/2563",
          "OI": 299.0,
          "futurePrice": 722.0
        },
        {
          "date": "31/3/2563",
          "OI": 366.0,
          "futurePrice": 742.9
        },
        {
          "date": "1/4/2563",
          "OI": 260.0,
          "futurePrice": 731.1
        },
        {
          "date": "2/4/2563",
          "OI": 112.0,
          "futurePrice": 757.8
        },
        {
          "date": "3/4/2563",
          "OI": 202.0,
          "futurePrice": 761.7
        },
        {
          "date": "7/4/2563",
          "OI": -310.0,
          "futurePrice": 822.2
        },
        {
          "date": "8/4/2563",
          "OI": -625.0,
          "futurePrice": 812.5
        },
        {
          "date": "9/4/2563",
          "OI": -1000.0,
          "futurePrice": 813.9
        },
        {
          "date": "10/4/2563",
          "OI": -1277.0,
          "futurePrice": 826.3
        },
        {
          "date": "13/4/2563",
          "OI": -1719.0,
          "futurePrice": 827.2
        },
        {
          "date": "14/4/2563",
          "OI": -2229.0,
          "futurePrice": 841.1
        },
        {
          "date": "15/4/2563",
          "OI": -2584.0,
          "futurePrice": 824.0
        },
        {
          "date": "16/4/2563",
          "OI": -2789.0,
          "futurePrice": 797.7
        },
        {
          "date": "17/4/2563",
          "OI": -3113.0,
          "futurePrice": 834.6
        },
        {
          "date": "20/4/2563",
          "OI": -3544.0,
          "futurePrice": 850.9
        },
        {
          "date": "21/4/2563",
          "OI": -4036.0,
          "futurePrice": 835.5
        },
        {
          "date": "22/4/2563",
          "OI": -4271.0,
          "futurePrice": 841.4
        },
        {
          "date": "23/4/2563",
          "OI": -4432.0,
          "futurePrice": 850.6
        },
        {
          "date": "24/4/2563",
          "OI": -4559.0,
          "futurePrice": 840.5
        },
        {
          "date": "27/4/2563",
          "OI": -4744.0,
          "futurePrice": 845.5
        },
        {
          "date": "28/4/2563",
          "OI": -4792.0,
          "futurePrice": 854.4
        },
        {
          "date": "29/4/2563",
          "OI": -4983.0,
          "futurePrice": 857.7
        },
        {
          "date": "30/4/2563",
          "OI": -5499.0,
          "futurePrice": 872.5
        },
        {
          "date": "5/5/2563",
          "OI": -6614.0,
          "futurePrice": 853.3
        },
        {
          "date": "7/5/2563",
          "OI": -8061.0,
          "futurePrice": 841.9
        },
        {
          "date": "8/5/2563",
          "OI": -8380.0,
          "futurePrice": 845.3
        },
        {
          "date": "11/5/2563",
          "OI": -8731.0,
          "futurePrice": 859.4
        },
        {
          "date": "12/5/2563",
          "OI": -9043.0,
          "futurePrice": 867.6
        },
        {
          "date": "13/5/2563",
          "OI": -9589.0,
          "futurePrice": 864.0
        },
        {
          "date": "14/5/2563",
          "OI": -9882.0,
          "futurePrice": 855.1
        },
        {
          "date": "15/5/2563",
          "OI": -10054.0,
          "futurePrice": 846.1
        },
        {
          "date": "18/5/2563",
          "OI": -10242.0,
          "futurePrice": 858.5
        },
        {
          "date": "19/5/2563",
          "OI": -10793.0,
          "futurePrice": 875.2
        },
        {
          "date": "20/5/2563",
          "OI": -11482.0,
          "futurePrice": 882.8
        },
        {
          "date": "21/5/2563",
          "OI": -12384.0,
          "futurePrice": 878.6
        },
        {
          "date": "22/5/2563",
          "OI": -12982.0,
          "futurePrice": 861.5
        },
        {
          "date": "25/5/2563",
          "OI": -13578.0,
          "futurePrice": 876.2
        },
        {
          "date": "26/5/2563",
          "OI": -14633.0,
          "futurePrice": 886.8
        },
        {
          "date": "27/5/2563",
          "OI": -15534.0,
          "futurePrice": 894.8
        },
        {
          "date": "28/5/2563",
          "OI": -16223.0,
          "futurePrice": 882.4
        },
        {
          "date": "29/5/2563",
          "OI": -17182.0,
          "futurePrice": 892.4
        },
        {
          "date": "1/6/2563",
          "OI": -17623.0,
          "futurePrice": 900.5
        },
        {
          "date": "2/6/2563",
          "OI": -17940.0,
          "futurePrice": 918.5
        },
        {
          "date": "4/6/2563",
          "OI": -17400.0,
          "futurePrice": 945.8
        },
        {
          "date": "5/6/2563",
          "OI": -18034.0,
          "futurePrice": 962.2
        },
        {
          "date": "8/6/2563",
          "OI": -18078.0,
          "futurePrice": 964.1
        },
        {
          "date": "9/6/2563",
          "OI": -17964.0,
          "futurePrice": 939.3
        },
        {
          "date": "10/6/2563",
          "OI": -18006.0,
          "futurePrice": 950.6
        },
        {
          "date": "11/6/2563",
          "OI": -18392.0,
          "futurePrice": 931.1
        },
        {
          "date": "12/6/2563",
          "OI": -18313.0,
          "futurePrice": 917.1
        },
        {
          "date": "15/6/2563",
          "OI": -17853.0,
          "futurePrice": 891.7
        },
        {
          "date": "16/6/2563",
          "OI": -17980.0,
          "futurePrice": 912.2
        },
        {
          "date": "17/6/2563",
          "OI": -18207.0,
          "futurePrice": 908.6
        },
        {
          "date": "18/6/2563",
          "OI": -18559.0,
          "futurePrice": 909.4
        },
        {
          "date": "19/6/2563",
          "OI": -18664.0,
          "futurePrice": 910.0
        },
        {
          "date": "22/6/2563",
          "OI": -18586.0,
          "futurePrice": 896.8
        },
        {
          "date": "23/6/2563",
          "OI": -18525.0,
          "futurePrice": 896.8
        },
        {
          "date": "24/6/2563",
          "OI": -18167.0,
          "futurePrice": 878.9
        },
        {
          "date": "25/6/2563",
          "OI": -17821.0,
          "futurePrice": 871.6
        },
        {
          "date": "26/6/2563",
          "OI": -18065.0,
          "futurePrice": 872.3
        },
        {
          "date": "29/6/2563",
          "OI": 0.0,
          "futurePrice": 874.5
        },
        {
          "date": "30/6/2563",
          "OI": 0.0,
          "futurePrice": 876.6
        },
        {
          "date": "1/7/2563",
          "OI": 0.0,
          "futurePrice": 884.5
        }
      ]
    }
  },
  2022: {
    S50H22: {
      volumeData: [
        {
          "date": "29/9/2564",
          "callPut": -5.0,
          "futurePrice": 971.1
        },
        {
          "date": "30/9/2564",
          "callPut": 102.0,
          "futurePrice": 962.2
        },
        {
          "date": "1/10/2564",
          "callPut": -597.0,
          "futurePrice": 960.1
        },
        {
          "date": "4/10/2564",
          "callPut": -126.0,
          "futurePrice": 967.0
        },
        {
          "date": "5/10/2564",
          "callPut": -48.0,
          "futurePrice": 974.6
        },
        {
          "date": "6/10/2564",
          "callPut": 95.0,
          "futurePrice": 966.3
        },
        {
          "date": "7/10/2564",
          "callPut": -169.0,
          "futurePrice": 979.7
        },
        {
          "date": "8/10/2564",
          "callPut": -89.0,
          "futurePrice": 983.2
        },
        {
          "date": "11/10/2564",
          "callPut": -62.0,
          "futurePrice": 981.2
        },
        {
          "date": "12/10/2564",
          "callPut": -71.0,
          "futurePrice": 987.4
        },
        {
          "date": "14/10/2564",
          "callPut": -423.0,
          "futurePrice": 987.4
        },
        {
          "date": "15/10/2564",
          "callPut": -15.0,
          "futurePrice": 986.5
        },
        {
          "date": "18/10/2564",
          "callPut": -220.0,
          "futurePrice": 990.9
        },
        {
          "date": "19/10/2564",
          "callPut": -216.0,
          "futurePrice": 981.8
        },
        {
          "date": "20/10/2564",
          "callPut": -423.0,
          "futurePrice": 983.3
        },
        {
          "date": "21/10/2564",
          "callPut": -239.0,
          "futurePrice": 988.2
        },
        {
          "date": "25/10/2564",
          "callPut": -512.0,
          "futurePrice": 980.2
        },
        {
          "date": "26/10/2564",
          "callPut": -259.0,
          "futurePrice": 984.0
        },
        {
          "date": "27/10/2564",
          "callPut": -333.0,
          "futurePrice": 975.9
        },
        {
          "date": "28/10/2564",
          "callPut": -530.0,
          "futurePrice": 975.0
        },
        {
          "date": "29/10/2564",
          "callPut": 278.0,
          "futurePrice": 974.7
        },
        {
          "date": "1/11/2564",
          "callPut": -241.0,
          "futurePrice": 970.8
        },
        {
          "date": "2/11/2564",
          "callPut": -41.0,
          "futurePrice": 971.5
        },
        {
          "date": "3/11/2564",
          "callPut": -147.0,
          "futurePrice": 971.9
        },
        {
          "date": "4/11/2564",
          "callPut": -18.0,
          "futurePrice": 978.9
        },
        {
          "date": "5/11/2564",
          "callPut": -144.0,
          "futurePrice": 978.6
        },
        {
          "date": "8/11/2564",
          "callPut": -29.0,
          "futurePrice": 978.2
        },
        {
          "date": "9/11/2564",
          "callPut": -106.0,
          "futurePrice": 979.0
        },
        {
          "date": "10/11/2564",
          "callPut": -133.0,
          "futurePrice": 977.2
        },
        {
          "date": "11/11/2564",
          "callPut": -142.0,
          "futurePrice": 979.1
        },
        {
          "date": "12/11/2564",
          "callPut": -1014.0,
          "futurePrice": 980.4
        },
        {
          "date": "15/11/2564",
          "callPut": 478.0,
          "futurePrice": 983.3
        },
        {
          "date": "16/11/2564",
          "callPut": -197.0,
          "futurePrice": 986.5
        },
        {
          "date": "17/11/2564",
          "callPut": 232.0,
          "futurePrice": 984.2
        },
        {
          "date": "18/11/2564",
          "callPut": -34.0,
          "futurePrice": 986.6
        },
        {
          "date": "19/11/2564",
          "callPut": 29.0,
          "futurePrice": 982.8
        },
        {
          "date": "22/11/2564",
          "callPut": -49.0,
          "futurePrice": 987.3
        },
        {
          "date": "23/11/2564",
          "callPut": -1708.0,
          "futurePrice": 986.3
        },
        {
          "date": "24/11/2564",
          "callPut": -390.0,
          "futurePrice": 988.3
        },
        {
          "date": "25/11/2564",
          "callPut": -23.0,
          "futurePrice": 987.4
        },
        {
          "date": "26/11/2564",
          "callPut": -2381.0,
          "futurePrice": 961.1
        },
        {
          "date": "29/11/2564",
          "callPut": -2013.0,
          "futurePrice": 946.5
        },
        {
          "date": "30/11/2564",
          "callPut": -714.0,
          "futurePrice": 929.3
        },
        {
          "date": "1/12/2564",
          "callPut": -2375.0,
          "futurePrice": 945.5
        },
        {
          "date": "2/12/2564",
          "callPut": -552.0,
          "futurePrice": 947.4
        },
        {
          "date": "3/12/2564",
          "callPut": 376.0,
          "futurePrice": 942.3
        },
        {
          "date": "7/12/2564",
          "callPut": -202.0,
          "futurePrice": 956.0
        },
        {
          "date": "8/12/2564",
          "callPut": 27.0,
          "futurePrice": 962.5
        },
        {
          "date": "9/12/2564",
          "callPut": -406.0,
          "futurePrice": 962.8
        },
        {
          "date": "13/12/2564",
          "callPut": -996.0,
          "futurePrice": 964.9
        },
        {
          "date": "14/12/2564",
          "callPut": -576.0,
          "futurePrice": 966.2
        },
        {
          "date": "15/12/2564",
          "callPut": -34.0,
          "futurePrice": 963.3
        },
        {
          "date": "16/12/2564",
          "callPut": 18.0,
          "futurePrice": 978.4
        },
        {
          "date": "17/12/2564",
          "callPut": -111.0,
          "futurePrice": 975.6
        },
        {
          "date": "20/12/2564",
          "callPut": -869.0,
          "futurePrice": 957.9
        },
        {
          "date": "21/12/2564",
          "callPut": -483.0,
          "futurePrice": 962.4
        },
        {
          "date": "22/12/2564",
          "callPut": -255.0,
          "futurePrice": 963.9
        },
        {
          "date": "23/12/2564",
          "callPut": -726.0,
          "futurePrice": 974.3
        },
        {
          "date": "24/12/2564",
          "callPut": 14.0,
          "futurePrice": 976.0
        },
        {
          "date": "27/12/2564",
          "callPut": -116.0,
          "futurePrice": 974.6
        },
        {
          "date": "28/12/2564",
          "callPut": 543.0,
          "futurePrice": 975.4
        },
        {
          "date": "29/12/2564",
          "callPut": 123.0,
          "futurePrice": 983.0
        },
        {
          "date": "30/12/2564",
          "callPut": -782.0,
          "futurePrice": 986.8
        },
        {
          "date": "4/1/2565",
          "callPut": 747.0,
          "futurePrice": 993.5
        },
        {
          "date": "5/1/2565",
          "callPut": -644.0,
          "futurePrice": 996.3
        },
        {
          "date": "6/1/2565",
          "callPut": -4719.0,
          "futurePrice": 979.1
        },
        {
          "date": "7/1/2565",
          "callPut": -479.0,
          "futurePrice": 977.3
        },
        {
          "date": "10/1/2565",
          "callPut": -708.0,
          "futurePrice": 978.0
        },
        {
          "date": "11/1/2565",
          "callPut": -443.0,
          "futurePrice": 984.2
        },
        {
          "date": "12/1/2565",
          "callPut": -165.0,
          "futurePrice": 990.2
        },
        {
          "date": "13/1/2565",
          "callPut": -474.0,
          "futurePrice": 990.4
        },
        {
          "date": "14/1/2565",
          "callPut": -831.0,
          "futurePrice": 986.5
        },
        {
          "date": "17/1/2565",
          "callPut": -1359.0,
          "futurePrice": 990.4
        },
        {
          "date": "18/1/2565",
          "callPut": -1545.0,
          "futurePrice": 980.3
        },
        {
          "date": "19/1/2565",
          "callPut": -1719.0,
          "futurePrice": 983.9
        },
        {
          "date": "20/1/2565",
          "callPut": 1384.0,
          "futurePrice": 982.4
        },
        {
          "date": "21/1/2565",
          "callPut": -2461.0,
          "futurePrice": 980.2
        },
        {
          "date": "24/1/2565",
          "callPut": -3231.0,
          "futurePrice": 976.9
        },
        {
          "date": "25/1/2565",
          "callPut": -4838.0,
          "futurePrice": 978.6
        },
        {
          "date": "26/1/2565",
          "callPut": -1495.0,
          "futurePrice": 985.1
        },
        {
          "date": "27/1/2565",
          "callPut": -2638.0,
          "futurePrice": 979.0
        },
        {
          "date": "28/1/2565",
          "callPut": -508.0,
          "futurePrice": 981.4
        },
        {
          "date": "31/1/2565",
          "callPut": -444.0,
          "futurePrice": 986.6
        },
        {
          "date": "1/2/2565",
          "callPut": -1359.0,
          "futurePrice": 988.4
        },
        {
          "date": "2/2/2565",
          "callPut": -249.0,
          "futurePrice": 990.8
        },
        {
          "date": "3/2/2565",
          "callPut": -652.0,
          "futurePrice": 990.7
        },
        {
          "date": "4/2/2565",
          "callPut": -788.0,
          "futurePrice": 995.7
        },
        {
          "date": "7/2/2565",
          "callPut": -1526.0,
          "futurePrice": 996.4
        },
        {
          "date": "8/2/2565",
          "callPut": -362.0,
          "futurePrice": 1005.0
        },
        {
          "date": "9/2/2565",
          "callPut": 450.0,
          "futurePrice": 1022.4
        },
        {
          "date": "10/2/2565",
          "callPut": -2744.0,
          "futurePrice": 1023.8
        },
        {
          "date": "11/2/2565",
          "callPut": 320.0,
          "futurePrice": 1021.1
        },
        {
          "date": "14/2/2565",
          "callPut": -984.0,
          "futurePrice": 1014.1
        },
        {
          "date": "15/2/2565",
          "callPut": 956.0,
          "futurePrice": 1024.3
        },
        {
          "date": "17/2/2565",
          "callPut": -1769.0,
          "futurePrice": 1029.2
        },
        {
          "date": "18/2/2565",
          "callPut": -69.0,
          "futurePrice": 1031.9
        },
        {
          "date": "21/2/2565",
          "callPut": -431.0,
          "futurePrice": 1014.9
        },
        {
          "date": "22/2/2565",
          "callPut": -2802.0,
          "futurePrice": 1009.9
        },
        {
          "date": "23/2/2565",
          "callPut": -1651.0,
          "futurePrice": 1016.8
        },
        {
          "date": "24/2/2565",
          "callPut": -5649.0,
          "futurePrice": 1001.5
        },
        {
          "date": "25/2/2565",
          "callPut": -2195.0,
          "futurePrice": 1008.4
        },
        {
          "date": "28/2/2565",
          "callPut": 104.0,
          "futurePrice": 1008.2
        },
        {
          "date": "1/3/2565",
          "callPut": -1771.0,
          "futurePrice": 1017.3
        },
        {
          "date": "2/3/2565",
          "callPut": -3367.0,
          "futurePrice": 1019.8
        },
        {
          "date": "3/3/2565",
          "callPut": -1437.0,
          "futurePrice": 1023.3
        },
        {
          "date": "4/3/2565",
          "callPut": -1753.0,
          "futurePrice": 1007.9
        },
        {
          "date": "7/3/2565",
          "callPut": -8600.0,
          "futurePrice": 984.0
        },
        {
          "date": "8/3/2565",
          "callPut": -10877.0,
          "futurePrice": 978.4
        },
        {
          "date": "9/3/2565",
          "callPut": 813.0,
          "futurePrice": 989.1
        },
        {
          "date": "10/3/2565",
          "callPut": 281.0,
          "futurePrice": 994.4
        },
        {
          "date": "11/3/2565",
          "callPut": -1056.0,
          "futurePrice": 1002.7
        },
        {
          "date": "14/3/2565",
          "callPut": 98.0,
          "futurePrice": 1007.0
        },
        {
          "date": "15/3/2565",
          "callPut": -1409.0,
          "futurePrice": 995.4
        },
        {
          "date": "16/3/2565",
          "callPut": -1196.0,
          "futurePrice": 1009.3
        },
        {
          "date": "17/3/2565",
          "callPut": 381.0,
          "futurePrice": 1018.1
        },
        {
          "date": "18/3/2565",
          "callPut": -415.0,
          "futurePrice": 1015.8
        },
        {
          "date": "21/3/2565",
          "callPut": -795.0,
          "futurePrice": 1011.7
        },
        {
          "date": "22/3/2565",
          "callPut": -691.0,
          "futurePrice": 1012.2
        },
        {
          "date": "23/3/2565",
          "callPut": -1571.0,
          "futurePrice": 1006.8
        },
        {
          "date": "24/3/2565",
          "callPut": -372.0,
          "futurePrice": 1012.2
        },
        {
          "date": "25/3/2565",
          "callPut": -600.0,
          "futurePrice": 1009.9
        },
        {
          "date": "28/3/2565",
          "callPut": 579.0,
          "futurePrice": 1014.9
        },
        {
          "date": "29/3/2565",
          "callPut": -424.0,
          "futurePrice": 1011.1
        },
        {
          "date": "30/3/2565",
          "callPut": 610.0,
          "futurePrice": 1018.1
        },
        {
          "date": "31/3/2565",
          "callPut": 0.0,
          "futurePrice": 1016.7
        },
        {
          "date": "1/4/2565",
          "callPut": 0.0,
          "futurePrice": 1018.7
        }
      ],
      openInterestData: [
        {
          "date": "29/9/2564",
          "OI": -5.0,
          "futurePrice": 971.1
        },
        {
          "date": "30/9/2564",
          "OI": -25.0,
          "futurePrice": 962.2
        },
        {
          "date": "1/10/2564",
          "OI": -551.0,
          "futurePrice": 960.1
        },
        {
          "date": "4/10/2564",
          "OI": -669.0,
          "futurePrice": 967.0
        },
        {
          "date": "5/10/2564",
          "OI": -721.0,
          "futurePrice": 974.6
        },
        {
          "date": "6/10/2564",
          "OI": -627.0,
          "futurePrice": 966.3
        },
        {
          "date": "7/10/2564",
          "OI": -674.0,
          "futurePrice": 979.7
        },
        {
          "date": "8/10/2564",
          "OI": -705.0,
          "futurePrice": 983.2
        },
        {
          "date": "11/10/2564",
          "OI": -747.0,
          "futurePrice": 981.2
        },
        {
          "date": "12/10/2564",
          "OI": -801.0,
          "futurePrice": 987.4
        },
        {
          "date": "14/10/2564",
          "OI": -1168.0,
          "futurePrice": 987.4
        },
        {
          "date": "15/10/2564",
          "OI": -1197.0,
          "futurePrice": 986.5
        },
        {
          "date": "18/10/2564",
          "OI": -1353.0,
          "futurePrice": 990.9
        },
        {
          "date": "19/10/2564",
          "OI": -1522.0,
          "futurePrice": 981.8
        },
        {
          "date": "20/10/2564",
          "OI": -1959.0,
          "futurePrice": 983.3
        },
        {
          "date": "21/10/2564",
          "OI": -2110.0,
          "futurePrice": 988.2
        },
        {
          "date": "25/10/2564",
          "OI": -2565.0,
          "futurePrice": 980.2
        },
        {
          "date": "26/10/2564",
          "OI": -2957.0,
          "futurePrice": 984.0
        },
        {
          "date": "27/10/2564",
          "OI": -3274.0,
          "futurePrice": 975.9
        },
        {
          "date": "28/10/2564",
          "OI": -3853.0,
          "futurePrice": 975.0
        },
        {
          "date": "29/10/2564",
          "OI": -3813.0,
          "futurePrice": 974.7
        },
        {
          "date": "1/11/2564",
          "OI": -4089.0,
          "futurePrice": 970.8
        },
        {
          "date": "2/11/2564",
          "OI": -4141.0,
          "futurePrice": 971.5
        },
        {
          "date": "3/11/2564",
          "OI": -4335.0,
          "futurePrice": 971.9
        },
        {
          "date": "4/11/2564",
          "OI": -4326.0,
          "futurePrice": 978.9
        },
        {
          "date": "5/11/2564",
          "OI": -4232.0,
          "futurePrice": 978.6
        },
        {
          "date": "8/11/2564",
          "OI": -4264.0,
          "futurePrice": 978.2
        },
        {
          "date": "9/11/2564",
          "OI": -4313.0,
          "futurePrice": 979.0
        },
        {
          "date": "10/11/2564",
          "OI": -4406.0,
          "futurePrice": 977.2
        },
        {
          "date": "11/11/2564",
          "OI": -4472.0,
          "futurePrice": 979.1
        },
        {
          "date": "12/11/2564",
          "OI": -5326.0,
          "futurePrice": 980.4
        },
        {
          "date": "15/11/2564",
          "OI": -5027.0,
          "futurePrice": 983.3
        },
        {
          "date": "16/11/2564",
          "OI": -5079.0,
          "futurePrice": 986.5
        },
        {
          "date": "17/11/2564",
          "OI": -5203.0,
          "futurePrice": 984.2
        },
        {
          "date": "18/11/2564",
          "OI": -5195.0,
          "futurePrice": 986.6
        },
        {
          "date": "19/11/2564",
          "OI": -5043.0,
          "futurePrice": 982.8
        },
        {
          "date": "22/11/2564",
          "OI": -5076.0,
          "futurePrice": 987.3
        },
        {
          "date": "23/11/2564",
          "OI": -6284.0,
          "futurePrice": 986.3
        },
        {
          "date": "24/11/2564",
          "OI": -6387.0,
          "futurePrice": 988.3
        },
        {
          "date": "25/11/2564",
          "OI": -6665.0,
          "futurePrice": 987.4
        },
        {
          "date": "26/11/2564",
          "OI": -7560.0,
          "futurePrice": 961.1
        },
        {
          "date": "29/11/2564",
          "OI": -6610.0,
          "futurePrice": 946.5
        },
        {
          "date": "30/11/2564",
          "OI": -6792.0,
          "futurePrice": 929.3
        },
        {
          "date": "1/12/2564",
          "OI": -6046.0,
          "futurePrice": 945.5
        },
        {
          "date": "2/12/2564",
          "OI": -6609.0,
          "futurePrice": 947.4
        },
        {
          "date": "3/12/2564",
          "OI": -6283.0,
          "futurePrice": 942.3
        },
        {
          "date": "7/12/2564",
          "OI": -6460.0,
          "futurePrice": 956.0
        },
        {
          "date": "8/12/2564",
          "OI": -6296.0,
          "futurePrice": 962.5
        },
        {
          "date": "9/12/2564",
          "OI": -6701.0,
          "futurePrice": 962.8
        },
        {
          "date": "13/12/2564",
          "OI": -7070.0,
          "futurePrice": 964.9
        },
        {
          "date": "14/12/2564",
          "OI": -7505.0,
          "futurePrice": 966.2
        },
        {
          "date": "15/12/2564",
          "OI": -7552.0,
          "futurePrice": 963.3
        },
        {
          "date": "16/12/2564",
          "OI": -7539.0,
          "futurePrice": 978.4
        },
        {
          "date": "17/12/2564",
          "OI": -7774.0,
          "futurePrice": 975.6
        },
        {
          "date": "20/12/2564",
          "OI": -7895.0,
          "futurePrice": 957.9
        },
        {
          "date": "21/12/2564",
          "OI": -8102.0,
          "futurePrice": 962.4
        },
        {
          "date": "22/12/2564",
          "OI": -8181.0,
          "futurePrice": 963.9
        },
        {
          "date": "23/12/2564",
          "OI": -7900.0,
          "futurePrice": 974.3
        },
        {
          "date": "24/12/2564",
          "OI": -7989.0,
          "futurePrice": 976.0
        },
        {
          "date": "27/12/2564",
          "OI": -7944.0,
          "futurePrice": 974.6
        },
        {
          "date": "28/12/2564",
          "OI": -8069.0,
          "futurePrice": 975.4
        },
        {
          "date": "29/12/2564",
          "OI": -7734.0,
          "futurePrice": 983.0
        },
        {
          "date": "30/12/2564",
          "OI": -8601.0,
          "futurePrice": 986.8
        },
        {
          "date": "4/1/2565",
          "OI": -9970.0,
          "futurePrice": 993.5
        },
        {
          "date": "5/1/2565",
          "OI": -11107.0,
          "futurePrice": 996.3
        },
        {
          "date": "6/1/2565",
          "OI": -13901.0,
          "futurePrice": 979.1
        },
        {
          "date": "7/1/2565",
          "OI": -14397.0,
          "futurePrice": 977.3
        },
        {
          "date": "10/1/2565",
          "OI": -14331.0,
          "futurePrice": 978.0
        },
        {
          "date": "11/1/2565",
          "OI": -14818.0,
          "futurePrice": 984.2
        },
        {
          "date": "12/1/2565",
          "OI": -14539.0,
          "futurePrice": 990.2
        },
        {
          "date": "13/1/2565",
          "OI": -14739.0,
          "futurePrice": 990.4
        },
        {
          "date": "14/1/2565",
          "OI": -15481.0,
          "futurePrice": 986.5
        },
        {
          "date": "17/1/2565",
          "OI": -16118.0,
          "futurePrice": 990.4
        },
        {
          "date": "18/1/2565",
          "OI": -16874.0,
          "futurePrice": 980.3
        },
        {
          "date": "19/1/2565",
          "OI": -18173.0,
          "futurePrice": 983.9
        },
        {
          "date": "20/1/2565",
          "OI": -16926.0,
          "futurePrice": 982.4
        },
        {
          "date": "21/1/2565",
          "OI": -17486.0,
          "futurePrice": 980.2
        },
        {
          "date": "24/1/2565",
          "OI": -18739.0,
          "futurePrice": 976.9
        },
        {
          "date": "25/1/2565",
          "OI": -17903.0,
          "futurePrice": 978.6
        },
        {
          "date": "26/1/2565",
          "OI": -18954.0,
          "futurePrice": 985.1
        },
        {
          "date": "27/1/2565",
          "OI": -19374.0,
          "futurePrice": 979.0
        },
        {
          "date": "28/1/2565",
          "OI": -19879.0,
          "futurePrice": 981.4
        },
        {
          "date": "31/1/2565",
          "OI": -20197.0,
          "futurePrice": 986.6
        },
        {
          "date": "1/2/2565",
          "OI": -20576.0,
          "futurePrice": 988.4
        },
        {
          "date": "2/2/2565",
          "OI": -20693.0,
          "futurePrice": 990.8
        },
        {
          "date": "3/2/2565",
          "OI": -20715.0,
          "futurePrice": 990.7
        },
        {
          "date": "4/2/2565",
          "OI": -22131.0,
          "futurePrice": 995.7
        },
        {
          "date": "7/2/2565",
          "OI": -22194.0,
          "futurePrice": 996.4
        },
        {
          "date": "8/2/2565",
          "OI": -23680.0,
          "futurePrice": 1005.0
        },
        {
          "date": "9/2/2565",
          "OI": -28379.0,
          "futurePrice": 1022.4
        },
        {
          "date": "10/2/2565",
          "OI": -30809.0,
          "futurePrice": 1023.8
        },
        {
          "date": "11/2/2565",
          "OI": -29879.0,
          "futurePrice": 1021.1
        },
        {
          "date": "14/2/2565",
          "OI": -30308.0,
          "futurePrice": 1014.1
        },
        {
          "date": "15/2/2565",
          "OI": -31272.0,
          "futurePrice": 1024.3
        },
        {
          "date": "17/2/2565",
          "OI": -34534.0,
          "futurePrice": 1029.2
        },
        {
          "date": "18/2/2565",
          "OI": -35271.0,
          "futurePrice": 1031.9
        },
        {
          "date": "21/2/2565",
          "OI": -35081.0,
          "futurePrice": 1014.9
        },
        {
          "date": "22/2/2565",
          "OI": -34657.0,
          "futurePrice": 1009.9
        },
        {
          "date": "23/2/2565",
          "OI": -35269.0,
          "futurePrice": 1016.8
        },
        {
          "date": "24/2/2565",
          "OI": -33946.0,
          "futurePrice": 1001.5
        },
        {
          "date": "25/2/2565",
          "OI": -34478.0,
          "futurePrice": 1008.4
        },
        {
          "date": "28/2/2565",
          "OI": -36978.0,
          "futurePrice": 1008.2
        },
        {
          "date": "1/3/2565",
          "OI": -39366.0,
          "futurePrice": 1017.3
        },
        {
          "date": "2/3/2565",
          "OI": -41331.0,
          "futurePrice": 1019.8
        },
        {
          "date": "3/3/2565",
          "OI": -43642.0,
          "futurePrice": 1023.3
        },
        {
          "date": "4/3/2565",
          "OI": -42679.0,
          "futurePrice": 1007.9
        },
        {
          "date": "7/3/2565",
          "OI": -39153.0,
          "futurePrice": 984.0
        },
        {
          "date": "8/3/2565",
          "OI": -33847.0,
          "futurePrice": 978.4
        },
        {
          "date": "9/3/2565",
          "OI": -34062.0,
          "futurePrice": 989.1
        },
        {
          "date": "10/3/2565",
          "OI": -35633.0,
          "futurePrice": 994.4
        },
        {
          "date": "11/3/2565",
          "OI": -36355.0,
          "futurePrice": 1002.7
        },
        {
          "date": "14/3/2565",
          "OI": -37090.0,
          "futurePrice": 1007.0
        },
        {
          "date": "15/3/2565",
          "OI": -37293.0,
          "futurePrice": 995.4
        },
        {
          "date": "16/3/2565",
          "OI": -37498.0,
          "futurePrice": 1009.3
        },
        {
          "date": "17/3/2565",
          "OI": -37399.0,
          "futurePrice": 1018.1
        },
        {
          "date": "18/3/2565",
          "OI": -37332.0,
          "futurePrice": 1015.8
        },
        {
          "date": "21/3/2565",
          "OI": -37480.0,
          "futurePrice": 1011.7
        },
        {
          "date": "22/3/2565",
          "OI": -37326.0,
          "futurePrice": 1012.2
        },
        {
          "date": "23/3/2565",
          "OI": -37363.0,
          "futurePrice": 1006.8
        },
        {
          "date": "24/3/2565",
          "OI": -37014.0,
          "futurePrice": 1012.2
        },
        {
          "date": "25/3/2565",
          "OI": -37259.0,
          "futurePrice": 1009.9
        },
        {
          "date": "28/3/2565",
          "OI": -38198.0,
          "futurePrice": 1014.9
        },
        {
          "date": "29/3/2565",
          "OI": -38537.0,
          "futurePrice": 1011.1
        },
        {
          "date": "30/3/2565",
          "OI": -39824.0,
          "futurePrice": 1018.1
        },
        {
          "date": "31/3/2565",
          "OI": 0.0,
          "futurePrice": 1016.7
        },
        {
          "date": "1/4/2565",
          "OI": 0.0,
          "futurePrice": 1018.7
        }
      ]
    },
    S50M22: {
      volumeData: [
        {
          "date": "29/12/2564",
          "callPut": -523.0,
          "futurePrice": 983.0
        },
        {
          "date": "30/12/2564",
          "callPut": 133.0,
          "futurePrice": 986.8
        },
        {
          "date": "4/1/2565",
          "callPut": 377.0,
          "futurePrice": 993.5
        },
        {
          "date": "5/1/2565",
          "callPut": -169.0,
          "futurePrice": 996.3
        },
        {
          "date": "6/1/2565",
          "callPut": -299.0,
          "futurePrice": 979.1
        },
        {
          "date": "7/1/2565",
          "callPut": -87.0,
          "futurePrice": 977.3
        },
        {
          "date": "10/1/2565",
          "callPut": -111.0,
          "futurePrice": 978.0
        },
        {
          "date": "11/1/2565",
          "callPut": -168.0,
          "futurePrice": 984.2
        },
        {
          "date": "12/1/2565",
          "callPut": -259.0,
          "futurePrice": 990.2
        },
        {
          "date": "13/1/2565",
          "callPut": -144.0,
          "futurePrice": 990.4
        },
        {
          "date": "14/1/2565",
          "callPut": -8.0,
          "futurePrice": 986.5
        },
        {
          "date": "17/1/2565",
          "callPut": -144.0,
          "futurePrice": 990.4
        },
        {
          "date": "18/1/2565",
          "callPut": -383.0,
          "futurePrice": 980.3
        },
        {
          "date": "19/1/2565",
          "callPut": -392.0,
          "futurePrice": 983.9
        },
        {
          "date": "20/1/2565",
          "callPut": -176.0,
          "futurePrice": 982.4
        },
        {
          "date": "21/1/2565",
          "callPut": -671.0,
          "futurePrice": 980.2
        },
        {
          "date": "24/1/2565",
          "callPut": 242.0,
          "futurePrice": 976.9
        },
        {
          "date": "25/1/2565",
          "callPut": -570.0,
          "futurePrice": 978.6
        },
        {
          "date": "26/1/2565",
          "callPut": 10.0,
          "futurePrice": 985.1
        },
        {
          "date": "27/1/2565",
          "callPut": -35.0,
          "futurePrice": 979.0
        },
        {
          "date": "28/1/2565",
          "callPut": 43.0,
          "futurePrice": 981.4
        },
        {
          "date": "31/1/2565",
          "callPut": 16.0,
          "futurePrice": 986.6
        },
        {
          "date": "1/2/2565",
          "callPut": -115.0,
          "futurePrice": 988.4
        },
        {
          "date": "2/2/2565",
          "callPut": -79.0,
          "futurePrice": 990.8
        },
        {
          "date": "3/2/2565",
          "callPut": -29.0,
          "futurePrice": 990.7
        },
        {
          "date": "4/2/2565",
          "callPut": -93.0,
          "futurePrice": 995.7
        },
        {
          "date": "7/2/2565",
          "callPut": -196.0,
          "futurePrice": 996.4
        },
        {
          "date": "8/2/2565",
          "callPut": -97.0,
          "futurePrice": 1005.0
        },
        {
          "date": "9/2/2565",
          "callPut": 528.0,
          "futurePrice": 1022.4
        },
        {
          "date": "10/2/2565",
          "callPut": 355.0,
          "futurePrice": 1023.8
        },
        {
          "date": "11/2/2565",
          "callPut": 48.0,
          "futurePrice": 1021.1
        },
        {
          "date": "14/2/2565",
          "callPut": -32.0,
          "futurePrice": 1014.1
        },
        {
          "date": "15/2/2565",
          "callPut": 100.0,
          "futurePrice": 1024.3
        },
        {
          "date": "17/2/2565",
          "callPut": 78.0,
          "futurePrice": 1029.2
        },
        {
          "date": "18/2/2565",
          "callPut": -75.0,
          "futurePrice": 1031.9
        },
        {
          "date": "21/2/2565",
          "callPut": -164.0,
          "futurePrice": 1014.9
        },
        {
          "date": "22/2/2565",
          "callPut": -818.0,
          "futurePrice": 1009.9
        },
        {
          "date": "23/2/2565",
          "callPut": -216.0,
          "futurePrice": 1016.8
        },
        {
          "date": "24/2/2565",
          "callPut": 443.0,
          "futurePrice": 1001.5
        },
        {
          "date": "25/2/2565",
          "callPut": 21.0,
          "futurePrice": 1008.4
        },
        {
          "date": "28/2/2565",
          "callPut": -137.0,
          "futurePrice": 1008.2
        },
        {
          "date": "1/3/2565",
          "callPut": -239.0,
          "futurePrice": 1017.3
        },
        {
          "date": "2/3/2565",
          "callPut": -187.0,
          "futurePrice": 1019.8
        },
        {
          "date": "3/3/2565",
          "callPut": -283.0,
          "futurePrice": 1023.3
        },
        {
          "date": "4/3/2565",
          "callPut": -221.0,
          "futurePrice": 1007.9
        },
        {
          "date": "7/3/2565",
          "callPut": -1003.0,
          "futurePrice": 984.0
        },
        {
          "date": "8/3/2565",
          "callPut": -545.0,
          "futurePrice": 978.4
        },
        {
          "date": "9/3/2565",
          "callPut": -80.0,
          "futurePrice": 989.1
        },
        {
          "date": "10/3/2565",
          "callPut": -189.0,
          "futurePrice": 994.4
        },
        {
          "date": "11/3/2565",
          "callPut": -427.0,
          "futurePrice": 1002.7
        },
        {
          "date": "14/3/2565",
          "callPut": -181.0,
          "futurePrice": 1007.0
        },
        {
          "date": "15/3/2565",
          "callPut": -413.0,
          "futurePrice": 995.4
        },
        {
          "date": "16/3/2565",
          "callPut": -401.0,
          "futurePrice": 1009.3
        },
        {
          "date": "17/3/2565",
          "callPut": -402.0,
          "futurePrice": 1018.1
        },
        {
          "date": "18/3/2565",
          "callPut": -305.0,
          "futurePrice": 1015.8
        },
        {
          "date": "21/3/2565",
          "callPut": -106.0,
          "futurePrice": 1011.7
        },
        {
          "date": "22/3/2565",
          "callPut": -528.0,
          "futurePrice": 1012.2
        },
        {
          "date": "23/3/2565",
          "callPut": -108.0,
          "futurePrice": 1006.8
        },
        {
          "date": "24/3/2565",
          "callPut": -164.0,
          "futurePrice": 1012.2
        },
        {
          "date": "25/3/2565",
          "callPut": 597.0,
          "futurePrice": 1009.9
        },
        {
          "date": "28/3/2565",
          "callPut": -582.0,
          "futurePrice": 1014.9
        },
        {
          "date": "29/3/2565",
          "callPut": -585.0,
          "futurePrice": 1011.1
        },
        {
          "date": "30/3/2565",
          "callPut": -975.0,
          "futurePrice": 1018.1
        },
        {
          "date": "31/3/2565",
          "callPut": 483.0,
          "futurePrice": 1016.7
        },
        {
          "date": "1/4/2565",
          "callPut": -932.0,
          "futurePrice": 1018.7
        },
        {
          "date": "4/4/2565",
          "callPut": -783.0,
          "futurePrice": 1018.5
        },
        {
          "date": "5/4/2565",
          "callPut": -791.0,
          "futurePrice": 1016.4
        },
        {
          "date": "7/4/2565",
          "callPut": -696.0,
          "futurePrice": 1003.4
        },
        {
          "date": "8/4/2565",
          "callPut": -674.0,
          "futurePrice": 1002.9
        },
        {
          "date": "11/4/2565",
          "callPut": -708.0,
          "futurePrice": 997.3
        },
        {
          "date": "12/4/2565",
          "callPut": -629.0,
          "futurePrice": 994.9
        },
        {
          "date": "18/4/2565",
          "callPut": 276.0,
          "futurePrice": 988.5
        },
        {
          "date": "19/4/2565",
          "callPut": 372.0,
          "futurePrice": 989.3
        },
        {
          "date": "20/4/2565",
          "callPut": 413.0,
          "futurePrice": 991.7
        },
        {
          "date": "21/4/2565",
          "callPut": -440.0,
          "futurePrice": 997.8
        },
        {
          "date": "22/4/2565",
          "callPut": 244.0,
          "futurePrice": 990.4
        },
        {
          "date": "25/4/2565",
          "callPut": -404.0,
          "futurePrice": 978.4
        },
        {
          "date": "26/4/2565",
          "callPut": 16.0,
          "futurePrice": 981.3
        },
        {
          "date": "27/4/2565",
          "callPut": 1565.0,
          "futurePrice": 976.7
        },
        {
          "date": "28/4/2565",
          "callPut": -1277.0,
          "futurePrice": 977.9
        },
        {
          "date": "29/4/2565",
          "callPut": -1716.0,
          "futurePrice": 978.1
        },
        {
          "date": "3/5/2565",
          "callPut": -474.0,
          "futurePrice": 975.1
        },
        {
          "date": "5/5/2565",
          "callPut": -178.0,
          "futurePrice": 971.8
        },
        {
          "date": "6/5/2565",
          "callPut": -611.0,
          "futurePrice": 964.0
        },
        {
          "date": "9/5/2565",
          "callPut": -133.0,
          "futurePrice": 948.0
        },
        {
          "date": "10/5/2565",
          "callPut": -1298.0,
          "futurePrice": 959.5
        },
        {
          "date": "11/5/2565",
          "callPut": 2630.0,
          "futurePrice": 957.2
        },
        {
          "date": "12/5/2565",
          "callPut": -54.0,
          "futurePrice": 944.1
        },
        {
          "date": "13/5/2565",
          "callPut": -1957.0,
          "futurePrice": 948.5
        },
        {
          "date": "17/5/2565",
          "callPut": 347.0,
          "futurePrice": 967.5
        },
        {
          "date": "18/5/2565",
          "callPut": 63.0,
          "futurePrice": 973.1
        },
        {
          "date": "19/5/2565",
          "callPut": -1131.0,
          "futurePrice": 961.1
        },
        {
          "date": "20/5/2565",
          "callPut": 201.0,
          "futurePrice": 976.1
        },
        {
          "date": "23/5/2565",
          "callPut": -637.0,
          "futurePrice": 983.9
        },
        {
          "date": "24/5/2565",
          "callPut": 157.0,
          "futurePrice": 977.2
        },
        {
          "date": "25/5/2565",
          "callPut": -319.0,
          "futurePrice": 974.5
        },
        {
          "date": "26/5/2565",
          "callPut": -23.0,
          "futurePrice": 980.0
        },
        {
          "date": "27/5/2565",
          "callPut": -305.0,
          "futurePrice": 985.8
        },
        {
          "date": "30/5/2565",
          "callPut": -2917.0,
          "futurePrice": 995.8
        },
        {
          "date": "31/5/2565",
          "callPut": -568.0,
          "futurePrice": 998.0
        },
        {
          "date": "1/6/2565",
          "callPut": -1196.0,
          "futurePrice": 1001.1
        },
        {
          "date": "2/6/2565",
          "callPut": -1140.0,
          "futurePrice": 996.2
        },
        {
          "date": "6/6/2565",
          "callPut": 347.0,
          "futurePrice": 994.0
        },
        {
          "date": "7/6/2565",
          "callPut": 1113.0,
          "futurePrice": 985.1
        },
        {
          "date": "8/6/2565",
          "callPut": 925.0,
          "futurePrice": 985.4
        },
        {
          "date": "9/6/2565",
          "callPut": -245.0,
          "futurePrice": 991.2
        },
        {
          "date": "10/6/2565",
          "callPut": -1047.0,
          "futurePrice": 986.5
        },
        {
          "date": "13/6/2565",
          "callPut": -5119.0,
          "futurePrice": 967.8
        },
        {
          "date": "14/6/2565",
          "callPut": -1243.0,
          "futurePrice": 966.8
        },
        {
          "date": "15/6/2565",
          "callPut": -580.0,
          "futurePrice": 964.9
        },
        {
          "date": "16/6/2565",
          "callPut": -2482.0,
          "futurePrice": 940.7
        },
        {
          "date": "17/6/2565",
          "callPut": -4897.0,
          "futurePrice": 942.9
        },
        {
          "date": "20/6/2565",
          "callPut": -1477.0,
          "futurePrice": 942.5
        },
        {
          "date": "21/6/2565",
          "callPut": 102.0,
          "futurePrice": 953.5
        },
        {
          "date": "22/6/2565",
          "callPut": -341.0,
          "futurePrice": 943.0
        },
        {
          "date": "23/6/2565",
          "callPut": -1215.0,
          "futurePrice": 942.7
        },
        {
          "date": "24/6/2565",
          "callPut": -12.0,
          "futurePrice": 948.0
        },
        {
          "date": "27/6/2565",
          "callPut": 97.0,
          "futurePrice": 958.4
        },
        {
          "date": "28/6/2565",
          "callPut": 372.0,
          "futurePrice": 956.9
        },
        {
          "date": "29/6/2565",
          "callPut": 192.0,
          "futurePrice": 952.4
        },
        {
          "date": "30/6/2565",
          "callPut": 0.0,
          "futurePrice": 939.6
        },
        {
          "date": "1/7/2565",
          "callPut": 0.0,
          "futurePrice": 944.8
        }
      ],
      openInterestData: [
        {
          "date": "29/12/2564",
          "OI": -521.0,
          "futurePrice": 983.0
        },
        {
          "date": "30/12/2564",
          "OI": -462.0,
          "futurePrice": 986.8
        },
        {
          "date": "4/1/2565",
          "OI": -237.0,
          "futurePrice": 993.5
        },
        {
          "date": "5/1/2565",
          "OI": -468.0,
          "futurePrice": 996.3
        },
        {
          "date": "6/1/2565",
          "OI": -717.0,
          "futurePrice": 979.1
        },
        {
          "date": "7/1/2565",
          "OI": -795.0,
          "futurePrice": 977.3
        },
        {
          "date": "10/1/2565",
          "OI": -873.0,
          "futurePrice": 978.0
        },
        {
          "date": "11/1/2565",
          "OI": -983.0,
          "futurePrice": 984.2
        },
        {
          "date": "12/1/2565",
          "OI": -1140.0,
          "futurePrice": 990.2
        },
        {
          "date": "13/1/2565",
          "OI": -1229.0,
          "futurePrice": 990.4
        },
        {
          "date": "14/1/2565",
          "OI": -1234.0,
          "futurePrice": 986.5
        },
        {
          "date": "17/1/2565",
          "OI": -1281.0,
          "futurePrice": 990.4
        },
        {
          "date": "18/1/2565",
          "OI": -1639.0,
          "futurePrice": 980.3
        },
        {
          "date": "19/1/2565",
          "OI": -1882.0,
          "futurePrice": 983.9
        },
        {
          "date": "20/1/2565",
          "OI": -1924.0,
          "futurePrice": 982.4
        },
        {
          "date": "21/1/2565",
          "OI": -2317.0,
          "futurePrice": 980.2
        },
        {
          "date": "24/1/2565",
          "OI": -2090.0,
          "futurePrice": 976.9
        },
        {
          "date": "25/1/2565",
          "OI": -2475.0,
          "futurePrice": 978.6
        },
        {
          "date": "26/1/2565",
          "OI": -2435.0,
          "futurePrice": 985.1
        },
        {
          "date": "27/1/2565",
          "OI": -2468.0,
          "futurePrice": 979.0
        },
        {
          "date": "28/1/2565",
          "OI": -2433.0,
          "futurePrice": 981.4
        },
        {
          "date": "31/1/2565",
          "OI": -2396.0,
          "futurePrice": 986.6
        },
        {
          "date": "1/2/2565",
          "OI": -2473.0,
          "futurePrice": 988.4
        },
        {
          "date": "2/2/2565",
          "OI": -2532.0,
          "futurePrice": 990.8
        },
        {
          "date": "3/2/2565",
          "OI": -2548.0,
          "futurePrice": 990.7
        },
        {
          "date": "4/2/2565",
          "OI": -2587.0,
          "futurePrice": 995.7
        },
        {
          "date": "7/2/2565",
          "OI": -2662.0,
          "futurePrice": 996.4
        },
        {
          "date": "8/2/2565",
          "OI": -2724.0,
          "futurePrice": 1005.0
        },
        {
          "date": "9/2/2565",
          "OI": -2640.0,
          "futurePrice": 1022.4
        },
        {
          "date": "10/2/2565",
          "OI": -2908.0,
          "futurePrice": 1023.8
        },
        {
          "date": "11/2/2565",
          "OI": -2871.0,
          "futurePrice": 1021.1
        },
        {
          "date": "14/2/2565",
          "OI": -2608.0,
          "futurePrice": 1014.1
        },
        {
          "date": "15/2/2565",
          "OI": -2674.0,
          "futurePrice": 1024.3
        },
        {
          "date": "17/2/2565",
          "OI": -2638.0,
          "futurePrice": 1029.2
        },
        {
          "date": "18/2/2565",
          "OI": -2640.0,
          "futurePrice": 1031.9
        },
        {
          "date": "21/2/2565",
          "OI": -2789.0,
          "futurePrice": 1014.9
        },
        {
          "date": "22/2/2565",
          "OI": -2981.0,
          "futurePrice": 1009.9
        },
        {
          "date": "23/2/2565",
          "OI": -2862.0,
          "futurePrice": 1016.8
        },
        {
          "date": "24/2/2565",
          "OI": -2256.0,
          "futurePrice": 1001.5
        },
        {
          "date": "25/2/2565",
          "OI": -2244.0,
          "futurePrice": 1008.4
        },
        {
          "date": "28/2/2565",
          "OI": -2280.0,
          "futurePrice": 1008.2
        },
        {
          "date": "1/3/2565",
          "OI": -2414.0,
          "futurePrice": 1017.3
        },
        {
          "date": "2/3/2565",
          "OI": -2460.0,
          "futurePrice": 1019.8
        },
        {
          "date": "3/3/2565",
          "OI": -2567.0,
          "futurePrice": 1023.3
        },
        {
          "date": "4/3/2565",
          "OI": -2653.0,
          "futurePrice": 1007.9
        },
        {
          "date": "7/3/2565",
          "OI": -2955.0,
          "futurePrice": 984.0
        },
        {
          "date": "8/3/2565",
          "OI": -2777.0,
          "futurePrice": 978.4
        },
        {
          "date": "9/3/2565",
          "OI": -2894.0,
          "futurePrice": 989.1
        },
        {
          "date": "10/3/2565",
          "OI": -3127.0,
          "futurePrice": 994.4
        },
        {
          "date": "11/3/2565",
          "OI": -3157.0,
          "futurePrice": 1002.7
        },
        {
          "date": "14/3/2565",
          "OI": -3352.0,
          "futurePrice": 1007.0
        },
        {
          "date": "15/3/2565",
          "OI": -3402.0,
          "futurePrice": 995.4
        },
        {
          "date": "16/3/2565",
          "OI": -3292.0,
          "futurePrice": 1009.3
        },
        {
          "date": "17/3/2565",
          "OI": -3715.0,
          "futurePrice": 1018.1
        },
        {
          "date": "18/3/2565",
          "OI": -3855.0,
          "futurePrice": 1015.8
        },
        {
          "date": "21/3/2565",
          "OI": -4012.0,
          "futurePrice": 1011.7
        },
        {
          "date": "22/3/2565",
          "OI": -4333.0,
          "futurePrice": 1012.2
        },
        {
          "date": "23/3/2565",
          "OI": -4208.0,
          "futurePrice": 1006.8
        },
        {
          "date": "24/3/2565",
          "OI": -4298.0,
          "futurePrice": 1012.2
        },
        {
          "date": "25/3/2565",
          "OI": -4216.0,
          "futurePrice": 1009.9
        },
        {
          "date": "28/3/2565",
          "OI": -4395.0,
          "futurePrice": 1014.9
        },
        {
          "date": "29/3/2565",
          "OI": -4562.0,
          "futurePrice": 1011.1
        },
        {
          "date": "30/3/2565",
          "OI": -4994.0,
          "futurePrice": 1018.1
        },
        {
          "date": "31/3/2565",
          "OI": -5302.0,
          "futurePrice": 1016.7
        },
        {
          "date": "1/4/2565",
          "OI": -5782.0,
          "futurePrice": 1018.7
        },
        {
          "date": "4/4/2565",
          "OI": -6079.0,
          "futurePrice": 1018.5
        },
        {
          "date": "5/4/2565",
          "OI": -6625.0,
          "futurePrice": 1016.4
        },
        {
          "date": "7/4/2565",
          "OI": -6900.0,
          "futurePrice": 1003.4
        },
        {
          "date": "8/4/2565",
          "OI": -7421.0,
          "futurePrice": 1002.9
        },
        {
          "date": "11/4/2565",
          "OI": -7776.0,
          "futurePrice": 997.3
        },
        {
          "date": "12/4/2565",
          "OI": -8036.0,
          "futurePrice": 994.9
        },
        {
          "date": "18/4/2565",
          "OI": -7314.0,
          "futurePrice": 988.5
        },
        {
          "date": "19/4/2565",
          "OI": -6672.0,
          "futurePrice": 989.3
        },
        {
          "date": "20/4/2565",
          "OI": -6340.0,
          "futurePrice": 991.7
        },
        {
          "date": "21/4/2565",
          "OI": -6273.0,
          "futurePrice": 997.8
        },
        {
          "date": "22/4/2565",
          "OI": -5701.0,
          "futurePrice": 990.4
        },
        {
          "date": "25/4/2565",
          "OI": -5344.0,
          "futurePrice": 978.4
        },
        {
          "date": "26/4/2565",
          "OI": -5644.0,
          "futurePrice": 981.3
        },
        {
          "date": "27/4/2565",
          "OI": -4697.0,
          "futurePrice": 976.7
        },
        {
          "date": "28/4/2565",
          "OI": -4566.0,
          "futurePrice": 977.9
        },
        {
          "date": "29/4/2565",
          "OI": -4310.0,
          "futurePrice": 978.1
        },
        {
          "date": "3/5/2565",
          "OI": -4190.0,
          "futurePrice": 975.1
        },
        {
          "date": "5/5/2565",
          "OI": -4124.0,
          "futurePrice": 971.8
        },
        {
          "date": "6/5/2565",
          "OI": -3928.0,
          "futurePrice": 964.0
        },
        {
          "date": "9/5/2565",
          "OI": -2163.0,
          "futurePrice": 948.0
        },
        {
          "date": "10/5/2565",
          "OI": -1641.0,
          "futurePrice": 959.5
        },
        {
          "date": "11/5/2565",
          "OI": 371.0,
          "futurePrice": 957.2
        },
        {
          "date": "12/5/2565",
          "OI": 2806.0,
          "futurePrice": 944.1
        },
        {
          "date": "13/5/2565",
          "OI": 2971.0,
          "futurePrice": 948.5
        },
        {
          "date": "17/5/2565",
          "OI": 1901.0,
          "futurePrice": 967.5
        },
        {
          "date": "18/5/2565",
          "OI": 2123.0,
          "futurePrice": 973.1
        },
        {
          "date": "19/5/2565",
          "OI": 1939.0,
          "futurePrice": 961.1
        },
        {
          "date": "20/5/2565",
          "OI": 1672.0,
          "futurePrice": 976.1
        },
        {
          "date": "23/5/2565",
          "OI": 1266.0,
          "futurePrice": 983.9
        },
        {
          "date": "24/5/2565",
          "OI": 1824.0,
          "futurePrice": 977.2
        },
        {
          "date": "25/5/2565",
          "OI": 1972.0,
          "futurePrice": 974.5
        },
        {
          "date": "26/5/2565",
          "OI": 862.0,
          "futurePrice": 980.0
        },
        {
          "date": "27/5/2565",
          "OI": 685.0,
          "futurePrice": 985.8
        },
        {
          "date": "30/5/2565",
          "OI": -1409.0,
          "futurePrice": 995.8
        },
        {
          "date": "31/5/2565",
          "OI": -1577.0,
          "futurePrice": 998.0
        },
        {
          "date": "1/6/2565",
          "OI": -2786.0,
          "futurePrice": 1001.1
        },
        {
          "date": "2/6/2565",
          "OI": -3568.0,
          "futurePrice": 996.2
        },
        {
          "date": "6/6/2565",
          "OI": -3334.0,
          "futurePrice": 994.0
        },
        {
          "date": "7/6/2565",
          "OI": -3806.0,
          "futurePrice": 985.1
        },
        {
          "date": "8/6/2565",
          "OI": -3592.0,
          "futurePrice": 985.4
        },
        {
          "date": "9/6/2565",
          "OI": -3852.0,
          "futurePrice": 991.2
        },
        {
          "date": "10/6/2565",
          "OI": -3767.0,
          "futurePrice": 986.5
        },
        {
          "date": "13/6/2565",
          "OI": -2278.0,
          "futurePrice": 967.8
        },
        {
          "date": "14/6/2565",
          "OI": -1702.0,
          "futurePrice": 966.8
        },
        {
          "date": "15/6/2565",
          "OI": -1759.0,
          "futurePrice": 964.9
        },
        {
          "date": "16/6/2565",
          "OI": 1130.0,
          "futurePrice": 940.7
        },
        {
          "date": "17/6/2565",
          "OI": 3583.0,
          "futurePrice": 942.9
        },
        {
          "date": "20/6/2565",
          "OI": 4991.0,
          "futurePrice": 942.5
        },
        {
          "date": "21/6/2565",
          "OI": 5165.0,
          "futurePrice": 953.5
        },
        {
          "date": "22/6/2565",
          "OI": 5979.0,
          "futurePrice": 943.0
        },
        {
          "date": "23/6/2565",
          "OI": 5978.0,
          "futurePrice": 942.7
        },
        {
          "date": "24/6/2565",
          "OI": 5815.0,
          "futurePrice": 948.0
        },
        {
          "date": "27/6/2565",
          "OI": 5918.0,
          "futurePrice": 958.4
        },
        {
          "date": "28/6/2565",
          "OI": 5406.0,
          "futurePrice": 956.9
        },
        {
          "date": "29/6/2565",
          "OI": 4911.0,
          "futurePrice": 952.4
        },
        {
          "date": "30/6/2565",
          "OI": 0.0,
          "futurePrice": 939.6
        },
        {
          "date": "1/7/2565",
          "OI": 0.0,
          "futurePrice": 944.8
        }
      ]
    },
    S50U22: {
      volumeData: [
        {
          "date": "30/3/2565",
          "callPut": -22.0,
          "futurePrice": 1018.1
        },
        {
          "date": "31/3/2565",
          "callPut": 261.0,
          "futurePrice": 1016.7
        },
        {
          "date": "1/4/2565",
          "callPut": 250.0,
          "futurePrice": 1018.7
        },
        {
          "date": "4/4/2565",
          "callPut": 43.0,
          "futurePrice": 1018.5
        },
        {
          "date": "5/4/2565",
          "callPut": 4.0,
          "futurePrice": 1016.4
        },
        {
          "date": "7/4/2565",
          "callPut": -38.0,
          "futurePrice": 1003.4
        },
        {
          "date": "8/4/2565",
          "callPut": -447.0,
          "futurePrice": 1002.9
        },
        {
          "date": "11/4/2565",
          "callPut": 40.0,
          "futurePrice": 997.3
        },
        {
          "date": "12/4/2565",
          "callPut": -85.0,
          "futurePrice": 994.9
        },
        {
          "date": "18/4/2565",
          "callPut": -107.0,
          "futurePrice": 988.5
        },
        {
          "date": "19/4/2565",
          "callPut": 11.0,
          "futurePrice": 989.3
        },
        {
          "date": "20/4/2565",
          "callPut": -19.0,
          "futurePrice": 991.7
        },
        {
          "date": "21/4/2565",
          "callPut": -150.0,
          "futurePrice": 997.8
        },
        {
          "date": "22/4/2565",
          "callPut": -153.0,
          "futurePrice": 990.4
        },
        {
          "date": "25/4/2565",
          "callPut": -139.0,
          "futurePrice": 978.4
        },
        {
          "date": "26/4/2565",
          "callPut": 70.0,
          "futurePrice": 981.3
        },
        {
          "date": "27/4/2565",
          "callPut": 63.0,
          "futurePrice": 976.7
        },
        {
          "date": "28/4/2565",
          "callPut": -13.0,
          "futurePrice": 977.9
        },
        {
          "date": "29/4/2565",
          "callPut": -55.0,
          "futurePrice": 978.1
        },
        {
          "date": "3/5/2565",
          "callPut": -66.0,
          "futurePrice": 975.1
        },
        {
          "date": "5/5/2565",
          "callPut": 74.0,
          "futurePrice": 971.8
        },
        {
          "date": "6/5/2565",
          "callPut": -725.0,
          "futurePrice": 964.0
        },
        {
          "date": "9/5/2565",
          "callPut": -637.0,
          "futurePrice": 948.0
        },
        {
          "date": "10/5/2565",
          "callPut": -478.0,
          "futurePrice": 959.5
        },
        {
          "date": "11/5/2565",
          "callPut": -195.0,
          "futurePrice": 957.2
        },
        {
          "date": "12/5/2565",
          "callPut": -192.0,
          "futurePrice": 944.1
        },
        {
          "date": "13/5/2565",
          "callPut": 14.0,
          "futurePrice": 948.5
        },
        {
          "date": "17/5/2565",
          "callPut": -129.0,
          "futurePrice": 967.5
        },
        {
          "date": "18/5/2565",
          "callPut": -410.0,
          "futurePrice": 973.1
        },
        {
          "date": "19/5/2565",
          "callPut": -460.0,
          "futurePrice": 961.1
        },
        {
          "date": "20/5/2565",
          "callPut": -165.0,
          "futurePrice": 976.1
        },
        {
          "date": "23/5/2565",
          "callPut": -239.0,
          "futurePrice": 983.9
        },
        {
          "date": "24/5/2565",
          "callPut": -236.0,
          "futurePrice": 977.2
        },
        {
          "date": "25/5/2565",
          "callPut": -326.0,
          "futurePrice": 974.5
        },
        {
          "date": "26/5/2565",
          "callPut": -105.0,
          "futurePrice": 980.0
        },
        {
          "date": "27/5/2565",
          "callPut": -331.0,
          "futurePrice": 985.8
        },
        {
          "date": "30/5/2565",
          "callPut": -810.0,
          "futurePrice": 995.8
        },
        {
          "date": "31/5/2565",
          "callPut": -446.0,
          "futurePrice": 998.0
        },
        {
          "date": "1/6/2565",
          "callPut": -44.0,
          "futurePrice": 1001.1
        },
        {
          "date": "2/6/2565",
          "callPut": -468.0,
          "futurePrice": 996.2
        },
        {
          "date": "6/6/2565",
          "callPut": -352.0,
          "futurePrice": 994.0
        },
        {
          "date": "7/6/2565",
          "callPut": 38.0,
          "futurePrice": 985.1
        },
        {
          "date": "8/6/2565",
          "callPut": -114.0,
          "futurePrice": 985.4
        },
        {
          "date": "9/6/2565",
          "callPut": 103.0,
          "futurePrice": 991.2
        },
        {
          "date": "10/6/2565",
          "callPut": -759.0,
          "futurePrice": 986.5
        },
        {
          "date": "13/6/2565",
          "callPut": -933.0,
          "futurePrice": 967.8
        },
        {
          "date": "14/6/2565",
          "callPut": 105.0,
          "futurePrice": 966.8
        },
        {
          "date": "15/6/2565",
          "callPut": -264.0,
          "futurePrice": 964.9
        },
        {
          "date": "16/6/2565",
          "callPut": 213.0,
          "futurePrice": 940.7
        },
        {
          "date": "17/6/2565",
          "callPut": -714.0,
          "futurePrice": 942.9
        },
        {
          "date": "20/6/2565",
          "callPut": -508.0,
          "futurePrice": 942.5
        },
        {
          "date": "21/6/2565",
          "callPut": -410.0,
          "futurePrice": 953.5
        },
        {
          "date": "22/6/2565",
          "callPut": -268.0,
          "futurePrice": 943.0
        },
        {
          "date": "23/6/2565",
          "callPut": 7.0,
          "futurePrice": 942.7
        },
        {
          "date": "24/6/2565",
          "callPut": -433.0,
          "futurePrice": 948.0
        },
        {
          "date": "27/6/2565",
          "callPut": -571.0,
          "futurePrice": 958.4
        },
        {
          "date": "28/6/2565",
          "callPut": -159.0,
          "futurePrice": 956.9
        },
        {
          "date": "29/6/2565",
          "callPut": -1806.0,
          "futurePrice": 952.4
        },
        {
          "date": "30/6/2565",
          "callPut": -712.0,
          "futurePrice": 939.6
        },
        {
          "date": "1/7/2565",
          "callPut": -1103.0,
          "futurePrice": 944.8
        },
        {
          "date": "4/7/2565",
          "callPut": 421.0,
          "futurePrice": 941.3
        },
        {
          "date": "5/7/2565",
          "callPut": -417.0,
          "futurePrice": 934.6
        },
        {
          "date": "6/7/2565",
          "callPut": -488.0,
          "futurePrice": 937.7
        },
        {
          "date": "7/7/2565",
          "callPut": -253.0,
          "futurePrice": 949.4
        },
        {
          "date": "8/7/2565",
          "callPut": 96.0,
          "futurePrice": 950.1
        },
        {
          "date": "11/7/2565",
          "callPut": 193.0,
          "futurePrice": 949.6
        },
        {
          "date": "12/7/2565",
          "callPut": -4.0,
          "futurePrice": 941.7
        },
        {
          "date": "14/7/2565",
          "callPut": -469.0,
          "futurePrice": 935.2
        },
        {
          "date": "15/7/2565",
          "callPut": -518.0,
          "futurePrice": 933.9
        },
        {
          "date": "18/7/2565",
          "callPut": 991.0,
          "futurePrice": 940.9
        },
        {
          "date": "19/7/2565",
          "callPut": -2240.0,
          "futurePrice": 934.2
        },
        {
          "date": "20/7/2565",
          "callPut": -32.0,
          "futurePrice": 934.3
        },
        {
          "date": "21/7/2565",
          "callPut": -269.0,
          "futurePrice": 937.8
        },
        {
          "date": "22/7/2565",
          "callPut": 1456.0,
          "futurePrice": 945.8
        },
        {
          "date": "25/7/2565",
          "callPut": 3116.0,
          "futurePrice": 947.6
        },
        {
          "date": "26/7/2565",
          "callPut": 3115.0,
          "futurePrice": 945.5
        },
        {
          "date": "27/7/2565",
          "callPut": 1038.0,
          "futurePrice": 951.9
        },
        {
          "date": "1/8/2565",
          "callPut": 1108.0,
          "futurePrice": 958.8
        },
        {
          "date": "2/8/2565",
          "callPut": 1171.0,
          "futurePrice": 958.4
        },
        {
          "date": "3/8/2565",
          "callPut": 864.0,
          "futurePrice": 962.1
        },
        {
          "date": "4/8/2565",
          "callPut": 499.0,
          "futurePrice": 961.7
        },
        {
          "date": "5/8/2565",
          "callPut": -20.0,
          "futurePrice": 962.4
        },
        {
          "date": "8/8/2565",
          "callPut": 605.0,
          "futurePrice": 971.1
        },
        {
          "date": "9/8/2565",
          "callPut": -3713.0,
          "futurePrice": 977.3
        },
        {
          "date": "10/8/2565",
          "callPut": -3219.0,
          "futurePrice": 975.0
        },
        {
          "date": "11/8/2565",
          "callPut": -2987.0,
          "futurePrice": 979.0
        },
        {
          "date": "15/8/2565",
          "callPut": -1880.0,
          "futurePrice": 983.0
        },
        {
          "date": "16/8/2565",
          "callPut": -297.0,
          "futurePrice": 981.0
        },
        {
          "date": "17/8/2565",
          "callPut": -1326.0,
          "futurePrice": 985.7
        },
        {
          "date": "18/8/2565",
          "callPut": 229.0,
          "futurePrice": 987.1
        },
        {
          "date": "19/8/2565",
          "callPut": 756.0,
          "futurePrice": 978.4
        },
        {
          "date": "22/8/2565",
          "callPut": 44.0,
          "futurePrice": 970.2
        },
        {
          "date": "23/8/2565",
          "callPut": -1440.0,
          "futurePrice": 983.5
        },
        {
          "date": "24/8/2565",
          "callPut": 156.0,
          "futurePrice": 984.2
        },
        {
          "date": "25/8/2565",
          "callPut": -704.0,
          "futurePrice": 990.8
        },
        {
          "date": "26/8/2565",
          "callPut": -265.0,
          "futurePrice": 991.3
        },
        {
          "date": "29/8/2565",
          "callPut": -1525.0,
          "futurePrice": 978.0
        },
        {
          "date": "30/8/2565",
          "callPut": -873.0,
          "futurePrice": 989.1
        },
        {
          "date": "31/8/2565",
          "callPut": -3100.0,
          "futurePrice": 989.4
        },
        {
          "date": "1/9/2565",
          "callPut": -3159.0,
          "futurePrice": 980.8
        },
        {
          "date": "2/9/2565",
          "callPut": -1672.0,
          "futurePrice": 977.5
        },
        {
          "date": "5/9/2565",
          "callPut": -2190.0,
          "futurePrice": 977.4
        },
        {
          "date": "6/9/2565",
          "callPut": -2350.0,
          "futurePrice": 985.5
        },
        {
          "date": "7/9/2565",
          "callPut": -1713.0,
          "futurePrice": 984.8
        },
        {
          "date": "8/9/2565",
          "callPut": -1713.0,
          "futurePrice": 983.3
        },
        {
          "date": "9/9/2565",
          "callPut": -1042.0,
          "futurePrice": 990.9
        },
        {
          "date": "12/9/2565",
          "callPut": 80.0,
          "futurePrice": 1000.1
        },
        {
          "date": "13/9/2565",
          "callPut": 171.0,
          "futurePrice": 1001.2
        },
        {
          "date": "14/9/2565",
          "callPut": -2569.0,
          "futurePrice": 995.7
        },
        {
          "date": "15/9/2565",
          "callPut": -3370.0,
          "futurePrice": 985.2
        },
        {
          "date": "16/9/2565",
          "callPut": -2956.0,
          "futurePrice": 979.5
        },
        {
          "date": "19/9/2565",
          "callPut": -503.0,
          "futurePrice": 978.8
        },
        {
          "date": "20/9/2565",
          "callPut": -807.0,
          "futurePrice": 982.1
        },
        {
          "date": "21/9/2565",
          "callPut": -916.0,
          "futurePrice": 982.9
        },
        {
          "date": "22/9/2565",
          "callPut": -1807.0,
          "futurePrice": 987.5
        },
        {
          "date": "23/9/2565",
          "callPut": -3954.0,
          "futurePrice": 978.1
        },
        {
          "date": "26/9/2565",
          "callPut": -3444.0,
          "futurePrice": 971.8
        },
        {
          "date": "27/9/2565",
          "callPut": -2334.0,
          "futurePrice": 969.2
        },
        {
          "date": "28/9/2565",
          "callPut": -5919.0,
          "futurePrice": 961.5
        },
        {
          "date": "29/9/2565",
          "callPut": -2769.0,
          "futurePrice": 957.6
        },
        {
          "date": "30/9/2565",
          "callPut": 0.0,
          "futurePrice": 951.8
        },
        {
          "date": "3/10/2565",
          "callPut": 0.0,
          "futurePrice": 940.0
        }
      ],
      openInterestData: [
        {
          "date": "30/3/2565",
          "OI": -25.0,
          "futurePrice": 1018.1
        },
        {
          "date": "31/3/2565",
          "OI": 105.0,
          "futurePrice": 1016.7
        },
        {
          "date": "1/4/2565",
          "OI": 290.0,
          "futurePrice": 1018.7
        },
        {
          "date": "4/4/2565",
          "OI": 341.0,
          "futurePrice": 1018.5
        },
        {
          "date": "5/4/2565",
          "OI": 361.0,
          "futurePrice": 1016.4
        },
        {
          "date": "7/4/2565",
          "OI": 395.0,
          "futurePrice": 1003.4
        },
        {
          "date": "8/4/2565",
          "OI": -92.0,
          "futurePrice": 1002.9
        },
        {
          "date": "11/4/2565",
          "OI": -70.0,
          "futurePrice": 997.3
        },
        {
          "date": "12/4/2565",
          "OI": -195.0,
          "futurePrice": 994.9
        },
        {
          "date": "18/4/2565",
          "OI": -391.0,
          "futurePrice": 988.5
        },
        {
          "date": "19/4/2565",
          "OI": -394.0,
          "futurePrice": 989.3
        },
        {
          "date": "20/4/2565",
          "OI": -384.0,
          "futurePrice": 991.7
        },
        {
          "date": "21/4/2565",
          "OI": -515.0,
          "futurePrice": 997.8
        },
        {
          "date": "22/4/2565",
          "OI": -588.0,
          "futurePrice": 990.4
        },
        {
          "date": "25/4/2565",
          "OI": -661.0,
          "futurePrice": 978.4
        },
        {
          "date": "26/4/2565",
          "OI": -546.0,
          "futurePrice": 981.3
        },
        {
          "date": "27/4/2565",
          "OI": -558.0,
          "futurePrice": 976.7
        },
        {
          "date": "28/4/2565",
          "OI": -582.0,
          "futurePrice": 977.9
        },
        {
          "date": "29/4/2565",
          "OI": -576.0,
          "futurePrice": 978.1
        },
        {
          "date": "3/5/2565",
          "OI": -560.0,
          "futurePrice": 975.1
        },
        {
          "date": "5/5/2565",
          "OI": -518.0,
          "futurePrice": 971.8
        },
        {
          "date": "6/5/2565",
          "OI": -1278.0,
          "futurePrice": 964.0
        },
        {
          "date": "9/5/2565",
          "OI": -1835.0,
          "futurePrice": 948.0
        },
        {
          "date": "10/5/2565",
          "OI": -2321.0,
          "futurePrice": 959.5
        },
        {
          "date": "11/5/2565",
          "OI": -2314.0,
          "futurePrice": 957.2
        },
        {
          "date": "12/5/2565",
          "OI": -2371.0,
          "futurePrice": 944.1
        },
        {
          "date": "13/5/2565",
          "OI": -2348.0,
          "futurePrice": 948.5
        },
        {
          "date": "17/5/2565",
          "OI": -2460.0,
          "futurePrice": 967.5
        },
        {
          "date": "18/5/2565",
          "OI": -2686.0,
          "futurePrice": 973.1
        },
        {
          "date": "19/5/2565",
          "OI": -2878.0,
          "futurePrice": 961.1
        },
        {
          "date": "20/5/2565",
          "OI": -2962.0,
          "futurePrice": 976.1
        },
        {
          "date": "23/5/2565",
          "OI": -3245.0,
          "futurePrice": 983.9
        },
        {
          "date": "24/5/2565",
          "OI": -3243.0,
          "futurePrice": 977.2
        },
        {
          "date": "25/5/2565",
          "OI": -3464.0,
          "futurePrice": 974.5
        },
        {
          "date": "26/5/2565",
          "OI": -3495.0,
          "futurePrice": 980.0
        },
        {
          "date": "27/5/2565",
          "OI": -3409.0,
          "futurePrice": 985.8
        },
        {
          "date": "30/5/2565",
          "OI": -3507.0,
          "futurePrice": 995.8
        },
        {
          "date": "31/5/2565",
          "OI": -3775.0,
          "futurePrice": 998.0
        },
        {
          "date": "1/6/2565",
          "OI": -3776.0,
          "futurePrice": 1001.1
        },
        {
          "date": "2/6/2565",
          "OI": -3842.0,
          "futurePrice": 996.2
        },
        {
          "date": "6/6/2565",
          "OI": -3495.0,
          "futurePrice": 994.0
        },
        {
          "date": "7/6/2565",
          "OI": -3159.0,
          "futurePrice": 985.1
        },
        {
          "date": "8/6/2565",
          "OI": -3308.0,
          "futurePrice": 985.4
        },
        {
          "date": "9/6/2565",
          "OI": -2731.0,
          "futurePrice": 991.2
        },
        {
          "date": "10/6/2565",
          "OI": -2955.0,
          "futurePrice": 986.5
        },
        {
          "date": "13/6/2565",
          "OI": -3138.0,
          "futurePrice": 967.8
        },
        {
          "date": "14/6/2565",
          "OI": -2725.0,
          "futurePrice": 966.8
        },
        {
          "date": "15/6/2565",
          "OI": -2890.0,
          "futurePrice": 964.9
        },
        {
          "date": "16/6/2565",
          "OI": -2572.0,
          "futurePrice": 940.7
        },
        {
          "date": "17/6/2565",
          "OI": -2703.0,
          "futurePrice": 942.9
        },
        {
          "date": "20/6/2565",
          "OI": -2707.0,
          "futurePrice": 942.5
        },
        {
          "date": "21/6/2565",
          "OI": -2913.0,
          "futurePrice": 953.5
        },
        {
          "date": "22/6/2565",
          "OI": -2822.0,
          "futurePrice": 943.0
        },
        {
          "date": "23/6/2565",
          "OI": -3053.0,
          "futurePrice": 942.7
        },
        {
          "date": "24/6/2565",
          "OI": -3176.0,
          "futurePrice": 948.0
        },
        {
          "date": "27/6/2565",
          "OI": -3009.0,
          "futurePrice": 958.4
        },
        {
          "date": "28/6/2565",
          "OI": -2925.0,
          "futurePrice": 956.9
        },
        {
          "date": "29/6/2565",
          "OI": -4146.0,
          "futurePrice": 952.4
        },
        {
          "date": "30/6/2565",
          "OI": -4227.0,
          "futurePrice": 939.6
        },
        {
          "date": "1/7/2565",
          "OI": -4111.0,
          "futurePrice": 944.8
        },
        {
          "date": "4/7/2565",
          "OI": -3604.0,
          "futurePrice": 941.3
        },
        {
          "date": "5/7/2565",
          "OI": -3173.0,
          "futurePrice": 934.6
        },
        {
          "date": "6/7/2565",
          "OI": -3214.0,
          "futurePrice": 937.7
        },
        {
          "date": "7/7/2565",
          "OI": -2586.0,
          "futurePrice": 949.4
        },
        {
          "date": "8/7/2565",
          "OI": -2670.0,
          "futurePrice": 950.1
        },
        {
          "date": "11/7/2565",
          "OI": -2462.0,
          "futurePrice": 949.6
        },
        {
          "date": "12/7/2565",
          "OI": -2325.0,
          "futurePrice": 941.7
        },
        {
          "date": "14/7/2565",
          "OI": -1986.0,
          "futurePrice": 935.2
        },
        {
          "date": "15/7/2565",
          "OI": -2000.0,
          "futurePrice": 933.9
        },
        {
          "date": "18/7/2565",
          "OI": -1527.0,
          "futurePrice": 940.9
        },
        {
          "date": "19/7/2565",
          "OI": -2941.0,
          "futurePrice": 934.2
        },
        {
          "date": "20/7/2565",
          "OI": -2777.0,
          "futurePrice": 934.3
        },
        {
          "date": "21/7/2565",
          "OI": -2842.0,
          "futurePrice": 937.8
        },
        {
          "date": "22/7/2565",
          "OI": -2766.0,
          "futurePrice": 945.8
        },
        {
          "date": "25/7/2565",
          "OI": -1587.0,
          "futurePrice": 947.6
        },
        {
          "date": "26/7/2565",
          "OI": 488.0,
          "futurePrice": 945.5
        },
        {
          "date": "27/7/2565",
          "OI": 926.0,
          "futurePrice": 951.9
        },
        {
          "date": "1/8/2565",
          "OI": 1019.0,
          "futurePrice": 958.8
        },
        {
          "date": "2/8/2565",
          "OI": 1131.0,
          "futurePrice": 958.4
        },
        {
          "date": "3/8/2565",
          "OI": 1780.0,
          "futurePrice": 962.1
        },
        {
          "date": "4/8/2565",
          "OI": 1281.0,
          "futurePrice": 961.7
        },
        {
          "date": "5/8/2565",
          "OI": 649.0,
          "futurePrice": 962.4
        },
        {
          "date": "8/8/2565",
          "OI": -275.0,
          "futurePrice": 971.1
        },
        {
          "date": "9/8/2565",
          "OI": -2178.0,
          "futurePrice": 977.3
        },
        {
          "date": "10/8/2565",
          "OI": -3715.0,
          "futurePrice": 975.0
        },
        {
          "date": "11/8/2565",
          "OI": -5658.0,
          "futurePrice": 979.0
        },
        {
          "date": "15/8/2565",
          "OI": -7259.0,
          "futurePrice": 983.0
        },
        {
          "date": "16/8/2565",
          "OI": -8008.0,
          "futurePrice": 981.0
        },
        {
          "date": "17/8/2565",
          "OI": -9089.0,
          "futurePrice": 985.7
        },
        {
          "date": "18/8/2565",
          "OI": -9380.0,
          "futurePrice": 987.1
        },
        {
          "date": "19/8/2565",
          "OI": -9462.0,
          "futurePrice": 978.4
        },
        {
          "date": "22/8/2565",
          "OI": -9765.0,
          "futurePrice": 970.2
        },
        {
          "date": "23/8/2565",
          "OI": -11521.0,
          "futurePrice": 983.5
        },
        {
          "date": "24/8/2565",
          "OI": -12317.0,
          "futurePrice": 984.2
        },
        {
          "date": "25/8/2565",
          "OI": -16924.0,
          "futurePrice": 990.8
        },
        {
          "date": "26/8/2565",
          "OI": -17902.0,
          "futurePrice": 991.3
        },
        {
          "date": "29/8/2565",
          "OI": -19115.0,
          "futurePrice": 978.0
        },
        {
          "date": "30/8/2565",
          "OI": -21931.0,
          "futurePrice": 989.1
        },
        {
          "date": "31/8/2565",
          "OI": -24319.0,
          "futurePrice": 989.4
        },
        {
          "date": "1/9/2565",
          "OI": -25071.0,
          "futurePrice": 980.8
        },
        {
          "date": "2/9/2565",
          "OI": -25394.0,
          "futurePrice": 977.5
        },
        {
          "date": "5/9/2565",
          "OI": -25759.0,
          "futurePrice": 977.4
        },
        {
          "date": "6/9/2565",
          "OI": -27301.0,
          "futurePrice": 985.5
        },
        {
          "date": "7/9/2565",
          "OI": -28146.0,
          "futurePrice": 984.8
        },
        {
          "date": "8/9/2565",
          "OI": -29284.0,
          "futurePrice": 983.3
        },
        {
          "date": "9/9/2565",
          "OI": -32266.0,
          "futurePrice": 990.9
        },
        {
          "date": "12/9/2565",
          "OI": -35495.0,
          "futurePrice": 1000.1
        },
        {
          "date": "13/9/2565",
          "OI": -36585.0,
          "futurePrice": 1001.2
        },
        {
          "date": "14/9/2565",
          "OI": -36611.0,
          "futurePrice": 995.7
        },
        {
          "date": "15/9/2565",
          "OI": -36671.0,
          "futurePrice": 985.2
        },
        {
          "date": "16/9/2565",
          "OI": -35233.0,
          "futurePrice": 979.5
        },
        {
          "date": "19/9/2565",
          "OI": -34524.0,
          "futurePrice": 978.8
        },
        {
          "date": "20/9/2565",
          "OI": -34805.0,
          "futurePrice": 982.1
        },
        {
          "date": "21/9/2565",
          "OI": -34607.0,
          "futurePrice": 982.9
        },
        {
          "date": "22/9/2565",
          "OI": -34096.0,
          "futurePrice": 987.5
        },
        {
          "date": "23/9/2565",
          "OI": -34338.0,
          "futurePrice": 978.1
        },
        {
          "date": "26/9/2565",
          "OI": -34034.0,
          "futurePrice": 971.8
        },
        {
          "date": "27/9/2565",
          "OI": -33645.0,
          "futurePrice": 969.2
        },
        {
          "date": "28/9/2565",
          "OI": -29347.0,
          "futurePrice": 961.5
        },
        {
          "date": "29/9/2565",
          "OI": -27527.0,
          "futurePrice": 957.6
        },
        {
          "date": "30/9/2565",
          "OI": 0.0,
          "futurePrice": 951.8
        },
        {
          "date": "3/10/2565",
          "OI": 0.0,
          "futurePrice": 940.0
        }
      ]
    },
    S50Z22: {
      volumeData: [
        {
          "date": "29/6/2565",
          "callPut": -459.0,
          "futurePrice": 952.4
        },
        {
          "date": "30/6/2565",
          "callPut": 39.0,
          "futurePrice": 939.6
        },
        {
          "date": "1/7/2565",
          "callPut": -96.0,
          "futurePrice": 944.8
        },
        {
          "date": "4/7/2565",
          "callPut": 193.0,
          "futurePrice": 941.3
        },
        {
          "date": "5/7/2565",
          "callPut": 194.0,
          "futurePrice": 934.6
        },
        {
          "date": "6/7/2565",
          "callPut": -81.0,
          "futurePrice": 937.7
        },
        {
          "date": "7/7/2565",
          "callPut": -93.0,
          "futurePrice": 949.4
        },
        {
          "date": "8/7/2565",
          "callPut": -47.0,
          "futurePrice": 950.1
        },
        {
          "date": "11/7/2565",
          "callPut": 31.0,
          "futurePrice": 949.6
        },
        {
          "date": "12/7/2565",
          "callPut": 41.0,
          "futurePrice": 941.7
        },
        {
          "date": "14/7/2565",
          "callPut": -119.0,
          "futurePrice": 935.2
        },
        {
          "date": "15/7/2565",
          "callPut": -676.0,
          "futurePrice": 933.9
        },
        {
          "date": "18/7/2565",
          "callPut": -145.0,
          "futurePrice": 940.9
        },
        {
          "date": "19/7/2565",
          "callPut": -943.0,
          "futurePrice": 934.2
        },
        {
          "date": "20/7/2565",
          "callPut": -335.0,
          "futurePrice": 934.3
        },
        {
          "date": "21/7/2565",
          "callPut": -106.0,
          "futurePrice": 937.8
        },
        {
          "date": "22/7/2565",
          "callPut": -167.0,
          "futurePrice": 945.8
        },
        {
          "date": "25/7/2565",
          "callPut": -136.0,
          "futurePrice": 947.6
        },
        {
          "date": "26/7/2565",
          "callPut": -50.0,
          "futurePrice": 945.5
        },
        {
          "date": "27/7/2565",
          "callPut": -177.0,
          "futurePrice": 951.9
        },
        {
          "date": "1/8/2565",
          "callPut": -279.0,
          "futurePrice": 958.8
        },
        {
          "date": "2/8/2565",
          "callPut": -54.0,
          "futurePrice": 958.4
        },
        {
          "date": "3/8/2565",
          "callPut": -90.0,
          "futurePrice": 962.1
        },
        {
          "date": "4/8/2565",
          "callPut": -154.0,
          "futurePrice": 961.7
        },
        {
          "date": "5/8/2565",
          "callPut": -22.0,
          "futurePrice": 962.4
        },
        {
          "date": "8/8/2565",
          "callPut": 163.0,
          "futurePrice": 971.1
        },
        {
          "date": "9/8/2565",
          "callPut": 507.0,
          "futurePrice": 977.3
        },
        {
          "date": "10/8/2565",
          "callPut": -11.0,
          "futurePrice": 975.0
        },
        {
          "date": "11/8/2565",
          "callPut": 389.0,
          "futurePrice": 979.0
        },
        {
          "date": "15/8/2565",
          "callPut": 166.0,
          "futurePrice": 983.0
        },
        {
          "date": "16/8/2565",
          "callPut": 181.0,
          "futurePrice": 981.0
        },
        {
          "date": "17/8/2565",
          "callPut": -121.0,
          "futurePrice": 985.7
        },
        {
          "date": "18/8/2565",
          "callPut": 0.0,
          "futurePrice": 987.1
        },
        {
          "date": "19/8/2565",
          "callPut": 261.0,
          "futurePrice": 978.4
        },
        {
          "date": "22/8/2565",
          "callPut": 12.0,
          "futurePrice": 970.2
        },
        {
          "date": "23/8/2565",
          "callPut": -267.0,
          "futurePrice": 983.5
        },
        {
          "date": "24/8/2565",
          "callPut": -266.0,
          "futurePrice": 984.2
        },
        {
          "date": "25/8/2565",
          "callPut": 508.0,
          "futurePrice": 990.8
        },
        {
          "date": "26/8/2565",
          "callPut": -240.0,
          "futurePrice": 991.3
        },
        {
          "date": "29/8/2565",
          "callPut": -201.0,
          "futurePrice": 978.0
        },
        {
          "date": "30/8/2565",
          "callPut": 129.0,
          "futurePrice": 989.1
        },
        {
          "date": "31/8/2565",
          "callPut": 68.0,
          "futurePrice": 989.4
        },
        {
          "date": "1/9/2565",
          "callPut": -237.0,
          "futurePrice": 980.8
        },
        {
          "date": "2/9/2565",
          "callPut": 90.0,
          "futurePrice": 977.5
        },
        {
          "date": "5/9/2565",
          "callPut": -88.0,
          "futurePrice": 977.4
        },
        {
          "date": "6/9/2565",
          "callPut": -201.0,
          "futurePrice": 985.5
        },
        {
          "date": "7/9/2565",
          "callPut": -236.0,
          "futurePrice": 984.8
        },
        {
          "date": "8/9/2565",
          "callPut": -117.0,
          "futurePrice": 983.3
        },
        {
          "date": "9/9/2565",
          "callPut": -325.0,
          "futurePrice": 990.9
        },
        {
          "date": "12/9/2565",
          "callPut": -264.0,
          "futurePrice": 1000.1
        },
        {
          "date": "13/9/2565",
          "callPut": -1426.0,
          "futurePrice": 1001.2
        },
        {
          "date": "14/9/2565",
          "callPut": -1334.0,
          "futurePrice": 995.7
        },
        {
          "date": "15/9/2565",
          "callPut": 996.0,
          "futurePrice": 985.2
        },
        {
          "date": "16/9/2565",
          "callPut": 239.0,
          "futurePrice": 979.5
        },
        {
          "date": "19/9/2565",
          "callPut": -407.0,
          "futurePrice": 978.8
        },
        {
          "date": "20/9/2565",
          "callPut": -1017.0,
          "futurePrice": 982.1
        },
        {
          "date": "21/9/2565",
          "callPut": 1068.0,
          "futurePrice": 982.9
        },
        {
          "date": "22/9/2565",
          "callPut": -1143.0,
          "futurePrice": 987.5
        },
        {
          "date": "23/9/2565",
          "callPut": -2725.0,
          "futurePrice": 978.1
        },
        {
          "date": "26/9/2565",
          "callPut": -510.0,
          "futurePrice": 971.8
        },
        {
          "date": "27/9/2565",
          "callPut": -1607.0,
          "futurePrice": 969.2
        },
        {
          "date": "28/9/2565",
          "callPut": -3699.0,
          "futurePrice": 961.5
        },
        {
          "date": "29/9/2565",
          "callPut": -7485.0,
          "futurePrice": 957.6
        },
        {
          "date": "30/9/2565",
          "callPut": -4398.0,
          "futurePrice": 951.8
        },
        {
          "date": "3/10/2565",
          "callPut": -392.0,
          "futurePrice": 940.0
        },
        {
          "date": "4/10/2565",
          "callPut": 157.0,
          "futurePrice": 943.5
        },
        {
          "date": "5/10/2565",
          "callPut": -365.0,
          "futurePrice": 942.0
        },
        {
          "date": "6/10/2565",
          "callPut": -161.0,
          "futurePrice": 947.6
        },
        {
          "date": "7/10/2565",
          "callPut": -481.0,
          "futurePrice": 946.1
        },
        {
          "date": "10/10/2565",
          "callPut": -4341.0,
          "futurePrice": 937.1
        },
        {
          "date": "11/10/2565",
          "callPut": 435.0,
          "futurePrice": 931.8
        },
        {
          "date": "12/10/2565",
          "callPut": -733.0,
          "futurePrice": 936.6
        },
        {
          "date": "17/10/2565",
          "callPut": -726.0,
          "futurePrice": 940.7
        },
        {
          "date": "18/10/2565",
          "callPut": -2572.0,
          "futurePrice": 949.1
        },
        {
          "date": "19/10/2565",
          "callPut": 417.0,
          "futurePrice": 951.0
        },
        {
          "date": "20/10/2565",
          "callPut": -268.0,
          "futurePrice": 958.5
        },
        {
          "date": "21/10/2565",
          "callPut": -2979.0,
          "futurePrice": 959.2
        },
        {
          "date": "25/10/2565",
          "callPut": -1959.0,
          "futurePrice": 965.2
        },
        {
          "date": "26/10/2565",
          "callPut": -920.0,
          "futurePrice": 965.8
        },
        {
          "date": "27/10/2565",
          "callPut": -1116.0,
          "futurePrice": 973.6
        },
        {
          "date": "28/10/2565",
          "callPut": -2114.0,
          "futurePrice": 977.1
        },
        {
          "date": "31/10/2565",
          "callPut": -2443.0,
          "futurePrice": 979.1
        },
        {
          "date": "1/11/2565",
          "callPut": -5432.0,
          "futurePrice": 992.1
        },
        {
          "date": "2/11/2565",
          "callPut": -342.0,
          "futurePrice": 991.1
        },
        {
          "date": "3/11/2565",
          "callPut": -2119.0,
          "futurePrice": 988.7
        },
        {
          "date": "4/11/2565",
          "callPut": -931.0,
          "futurePrice": 985.9
        },
        {
          "date": "7/11/2565",
          "callPut": -3164.0,
          "futurePrice": 988.5
        },
        {
          "date": "8/11/2565",
          "callPut": -1360.0,
          "futurePrice": 996.6
        },
        {
          "date": "9/11/2565",
          "callPut": -1453.0,
          "futurePrice": 988.8
        },
        {
          "date": "10/11/2565",
          "callPut": -779.0,
          "futurePrice": 987.8
        },
        {
          "date": "11/11/2565",
          "callPut": -3575.0,
          "futurePrice": 1003.5
        },
        {
          "date": "14/11/2565",
          "callPut": -1679.0,
          "futurePrice": 992.8
        },
        {
          "date": "15/11/2565",
          "callPut": -1775.0,
          "futurePrice": 993.7
        },
        {
          "date": "16/11/2565",
          "callPut": -1541.0,
          "futurePrice": 985.2
        },
        {
          "date": "17/11/2565",
          "callPut": -3259.0,
          "futurePrice": 980.9
        },
        {
          "date": "18/11/2565",
          "callPut": -1056.0,
          "futurePrice": 983.7
        },
        {
          "date": "21/11/2565",
          "callPut": -944.0,
          "futurePrice": 984.8
        },
        {
          "date": "22/11/2565",
          "callPut": -1966.0,
          "futurePrice": 983.6
        },
        {
          "date": "23/11/2565",
          "callPut": -2746.0,
          "futurePrice": 991.7
        },
        {
          "date": "24/11/2565",
          "callPut": 79.0,
          "futurePrice": 988.5
        },
        {
          "date": "25/11/2565",
          "callPut": -1816.0,
          "futurePrice": 982.8
        },
        {
          "date": "28/11/2565",
          "callPut": -1721.0,
          "futurePrice": 979.5
        },
        {
          "date": "29/11/2565",
          "callPut": -1602.0,
          "futurePrice": 987.1
        },
        {
          "date": "30/11/2565",
          "callPut": -1887.0,
          "futurePrice": 994.8
        },
        {
          "date": "1/12/2565",
          "callPut": -1447.0,
          "futurePrice": 994.3
        },
        {
          "date": "2/12/2565",
          "callPut": -435.0,
          "futurePrice": 988.5
        },
        {
          "date": "6/12/2565",
          "callPut": -417.0,
          "futurePrice": 984.4
        },
        {
          "date": "7/12/2565",
          "callPut": -1665.0,
          "futurePrice": 980.9
        },
        {
          "date": "8/12/2565",
          "callPut": -990.0,
          "futurePrice": 976.5
        },
        {
          "date": "9/12/2565",
          "callPut": -1720.0,
          "futurePrice": 981.2
        },
        {
          "date": "13/12/2565",
          "callPut": -324.0,
          "futurePrice": 981.3
        },
        {
          "date": "14/12/2565",
          "callPut": -957.0,
          "futurePrice": 986.5
        },
        {
          "date": "15/12/2565",
          "callPut": -2515.0,
          "futurePrice": 977.9
        },
        {
          "date": "16/12/2565",
          "callPut": -850.0,
          "futurePrice": 981.9
        },
        {
          "date": "19/12/2565",
          "callPut": 781.0,
          "futurePrice": 980.3
        },
        {
          "date": "20/12/2565",
          "callPut": 518.0,
          "futurePrice": 975.1
        },
        {
          "date": "21/12/2565",
          "callPut": -85.0,
          "futurePrice": 974.0
        },
        {
          "date": "22/12/2565",
          "callPut": -172.0,
          "futurePrice": 977.3
        },
        {
          "date": "23/12/2565",
          "callPut": 300.0,
          "futurePrice": 981.3
        },
        {
          "date": "26/12/2565",
          "callPut": 318.0,
          "futurePrice": 985.4
        },
        {
          "date": "27/12/2565",
          "callPut": 4778.0,
          "futurePrice": 998.6
        },
        {
          "date": "28/12/2565",
          "callPut": 1738.0,
          "futurePrice": 997.1
        },
        {
          "date": "29/12/2565",
          "callPut": 2064.0,
          "futurePrice": 1002.4
        },
        {
          "date": "30/12/2565",
          "callPut": 0.0,
          "futurePrice": 1007.3
        },
        {
          "date": "2/1/2566",
          "callPut": 0.0,
          "futurePrice": null
        }
      ],
      openInterestData: [
        {
          "date": "29/6/2565",
          "OI": -439.0,
          "futurePrice": 952.4
        },
        {
          "date": "30/6/2565",
          "OI": -347.0,
          "futurePrice": 939.6
        },
        {
          "date": "1/7/2565",
          "OI": -427.0,
          "futurePrice": 944.8
        },
        {
          "date": "4/7/2565",
          "OI": -199.0,
          "futurePrice": 941.3
        },
        {
          "date": "5/7/2565",
          "OI": -12.0,
          "futurePrice": 934.6
        },
        {
          "date": "6/7/2565",
          "OI": -63.0,
          "futurePrice": 937.7
        },
        {
          "date": "7/7/2565",
          "OI": -107.0,
          "futurePrice": 949.4
        },
        {
          "date": "8/7/2565",
          "OI": -126.0,
          "futurePrice": 950.1
        },
        {
          "date": "11/7/2565",
          "OI": -91.0,
          "futurePrice": 949.6
        },
        {
          "date": "12/7/2565",
          "OI": -46.0,
          "futurePrice": 941.7
        },
        {
          "date": "14/7/2565",
          "OI": -154.0,
          "futurePrice": 935.2
        },
        {
          "date": "15/7/2565",
          "OI": -748.0,
          "futurePrice": 933.9
        },
        {
          "date": "18/7/2565",
          "OI": -855.0,
          "futurePrice": 940.9
        },
        {
          "date": "19/7/2565",
          "OI": -1689.0,
          "futurePrice": 934.2
        },
        {
          "date": "20/7/2565",
          "OI": -1878.0,
          "futurePrice": 934.3
        },
        {
          "date": "21/7/2565",
          "OI": -1907.0,
          "futurePrice": 937.8
        },
        {
          "date": "22/7/2565",
          "OI": -1932.0,
          "futurePrice": 945.8
        },
        {
          "date": "25/7/2565",
          "OI": -2010.0,
          "futurePrice": 947.6
        },
        {
          "date": "26/7/2565",
          "OI": -2051.0,
          "futurePrice": 945.5
        },
        {
          "date": "27/7/2565",
          "OI": -2184.0,
          "futurePrice": 951.9
        },
        {
          "date": "1/8/2565",
          "OI": -2220.0,
          "futurePrice": 958.8
        },
        {
          "date": "2/8/2565",
          "OI": -2245.0,
          "futurePrice": 958.4
        },
        {
          "date": "3/8/2565",
          "OI": -2291.0,
          "futurePrice": 962.1
        },
        {
          "date": "4/8/2565",
          "OI": -2323.0,
          "futurePrice": 961.7
        },
        {
          "date": "5/8/2565",
          "OI": -2309.0,
          "futurePrice": 962.4
        },
        {
          "date": "8/8/2565",
          "OI": -2117.0,
          "futurePrice": 971.1
        },
        {
          "date": "9/8/2565",
          "OI": -1610.0,
          "futurePrice": 977.3
        },
        {
          "date": "10/8/2565",
          "OI": -1581.0,
          "futurePrice": 975.0
        },
        {
          "date": "11/8/2565",
          "OI": -1328.0,
          "futurePrice": 979.0
        },
        {
          "date": "15/8/2565",
          "OI": -1043.0,
          "futurePrice": 983.0
        },
        {
          "date": "16/8/2565",
          "OI": -989.0,
          "futurePrice": 981.0
        },
        {
          "date": "17/8/2565",
          "OI": -1067.0,
          "futurePrice": 985.7
        },
        {
          "date": "18/8/2565",
          "OI": -1079.0,
          "futurePrice": 987.1
        },
        {
          "date": "19/8/2565",
          "OI": -926.0,
          "futurePrice": 978.4
        },
        {
          "date": "22/8/2565",
          "OI": -950.0,
          "futurePrice": 970.2
        },
        {
          "date": "23/8/2565",
          "OI": -894.0,
          "futurePrice": 983.5
        },
        {
          "date": "24/8/2565",
          "OI": -1149.0,
          "futurePrice": 984.2
        },
        {
          "date": "25/8/2565",
          "OI": -599.0,
          "futurePrice": 990.8
        },
        {
          "date": "26/8/2565",
          "OI": -744.0,
          "futurePrice": 991.3
        },
        {
          "date": "29/8/2565",
          "OI": -564.0,
          "futurePrice": 978.0
        },
        {
          "date": "30/8/2565",
          "OI": -753.0,
          "futurePrice": 989.1
        },
        {
          "date": "31/8/2565",
          "OI": -851.0,
          "futurePrice": 989.4
        },
        {
          "date": "1/9/2565",
          "OI": -903.0,
          "futurePrice": 980.8
        },
        {
          "date": "2/9/2565",
          "OI": -1086.0,
          "futurePrice": 977.5
        },
        {
          "date": "5/9/2565",
          "OI": -1054.0,
          "futurePrice": 977.4
        },
        {
          "date": "6/9/2565",
          "OI": -1132.0,
          "futurePrice": 985.5
        },
        {
          "date": "7/9/2565",
          "OI": -1342.0,
          "futurePrice": 984.8
        },
        {
          "date": "8/9/2565",
          "OI": -1696.0,
          "futurePrice": 983.3
        },
        {
          "date": "9/9/2565",
          "OI": -1889.0,
          "futurePrice": 990.9
        },
        {
          "date": "12/9/2565",
          "OI": -2167.0,
          "futurePrice": 1000.1
        },
        {
          "date": "13/9/2565",
          "OI": -3277.0,
          "futurePrice": 1001.2
        },
        {
          "date": "14/9/2565",
          "OI": -3924.0,
          "futurePrice": 995.7
        },
        {
          "date": "15/9/2565",
          "OI": -4567.0,
          "futurePrice": 985.2
        },
        {
          "date": "16/9/2565",
          "OI": -4951.0,
          "futurePrice": 979.5
        },
        {
          "date": "19/9/2565",
          "OI": -5470.0,
          "futurePrice": 978.8
        },
        {
          "date": "20/9/2565",
          "OI": -5949.0,
          "futurePrice": 982.1
        },
        {
          "date": "21/9/2565",
          "OI": -4525.0,
          "futurePrice": 982.9
        },
        {
          "date": "22/9/2565",
          "OI": -4649.0,
          "futurePrice": 987.5
        },
        {
          "date": "23/9/2565",
          "OI": -6108.0,
          "futurePrice": 978.1
        },
        {
          "date": "26/9/2565",
          "OI": -6454.0,
          "futurePrice": 971.8
        },
        {
          "date": "27/9/2565",
          "OI": -8377.0,
          "futurePrice": 969.2
        },
        {
          "date": "28/9/2565",
          "OI": -8903.0,
          "futurePrice": 961.5
        },
        {
          "date": "29/9/2565",
          "OI": -13664.0,
          "futurePrice": 957.6
        },
        {
          "date": "30/9/2565",
          "OI": -15114.0,
          "futurePrice": 951.8
        },
        {
          "date": "3/10/2565",
          "OI": -13522.0,
          "futurePrice": 940.0
        },
        {
          "date": "4/10/2565",
          "OI": -13969.0,
          "futurePrice": 943.5
        },
        {
          "date": "5/10/2565",
          "OI": -13809.0,
          "futurePrice": 942.0
        },
        {
          "date": "6/10/2565",
          "OI": -13826.0,
          "futurePrice": 947.6
        },
        {
          "date": "7/10/2565",
          "OI": -14061.0,
          "futurePrice": 946.1
        },
        {
          "date": "10/10/2565",
          "OI": -10901.0,
          "futurePrice": 937.1
        },
        {
          "date": "11/10/2565",
          "OI": -9546.0,
          "futurePrice": 931.8
        },
        {
          "date": "12/10/2565",
          "OI": -9858.0,
          "futurePrice": 936.6
        },
        {
          "date": "17/10/2565",
          "OI": -10320.0,
          "futurePrice": 940.7
        },
        {
          "date": "18/10/2565",
          "OI": -11973.0,
          "futurePrice": 949.1
        },
        {
          "date": "19/10/2565",
          "OI": -12606.0,
          "futurePrice": 951.0
        },
        {
          "date": "20/10/2565",
          "OI": -13993.0,
          "futurePrice": 958.5
        },
        {
          "date": "21/10/2565",
          "OI": -15244.0,
          "futurePrice": 959.2
        },
        {
          "date": "25/10/2565",
          "OI": -16713.0,
          "futurePrice": 965.2
        },
        {
          "date": "26/10/2565",
          "OI": -17312.0,
          "futurePrice": 965.8
        },
        {
          "date": "27/10/2565",
          "OI": -19657.0,
          "futurePrice": 973.6
        },
        {
          "date": "28/10/2565",
          "OI": -21758.0,
          "futurePrice": 977.1
        },
        {
          "date": "31/10/2565",
          "OI": -24872.0,
          "futurePrice": 979.1
        },
        {
          "date": "1/11/2565",
          "OI": -30524.0,
          "futurePrice": 992.1
        },
        {
          "date": "2/11/2565",
          "OI": -32509.0,
          "futurePrice": 991.1
        },
        {
          "date": "3/11/2565",
          "OI": -34588.0,
          "futurePrice": 988.7
        },
        {
          "date": "4/11/2565",
          "OI": -36024.0,
          "futurePrice": 985.9
        },
        {
          "date": "7/11/2565",
          "OI": -36953.0,
          "futurePrice": 988.5
        },
        {
          "date": "8/11/2565",
          "OI": -38022.0,
          "futurePrice": 996.6
        },
        {
          "date": "9/11/2565",
          "OI": -38569.0,
          "futurePrice": 988.8
        },
        {
          "date": "10/11/2565",
          "OI": -38521.0,
          "futurePrice": 987.8
        },
        {
          "date": "11/11/2565",
          "OI": -39302.0,
          "futurePrice": 1003.5
        },
        {
          "date": "14/11/2565",
          "OI": -40209.0,
          "futurePrice": 992.8
        },
        {
          "date": "15/11/2565",
          "OI": -40844.0,
          "futurePrice": 993.7
        },
        {
          "date": "16/11/2565",
          "OI": -41368.0,
          "futurePrice": 985.2
        },
        {
          "date": "17/11/2565",
          "OI": -42977.0,
          "futurePrice": 980.9
        },
        {
          "date": "18/11/2565",
          "OI": -43224.0,
          "futurePrice": 983.7
        },
        {
          "date": "21/11/2565",
          "OI": -42650.0,
          "futurePrice": 984.8
        },
        {
          "date": "22/11/2565",
          "OI": -43130.0,
          "futurePrice": 983.6
        },
        {
          "date": "23/11/2565",
          "OI": -43659.0,
          "futurePrice": 991.7
        },
        {
          "date": "24/11/2565",
          "OI": -43480.0,
          "futurePrice": 988.5
        },
        {
          "date": "25/11/2565",
          "OI": -43670.0,
          "futurePrice": 982.8
        },
        {
          "date": "28/11/2565",
          "OI": -43569.0,
          "futurePrice": 979.5
        },
        {
          "date": "29/11/2565",
          "OI": -44061.0,
          "futurePrice": 987.1
        },
        {
          "date": "30/11/2565",
          "OI": -45846.0,
          "futurePrice": 994.8
        },
        {
          "date": "1/12/2565",
          "OI": -45832.0,
          "futurePrice": 994.3
        },
        {
          "date": "2/12/2565",
          "OI": -46415.0,
          "futurePrice": 988.5
        },
        {
          "date": "6/12/2565",
          "OI": -46169.0,
          "futurePrice": 984.4
        },
        {
          "date": "7/12/2565",
          "OI": -44727.0,
          "futurePrice": 980.9
        },
        {
          "date": "8/12/2565",
          "OI": -42646.0,
          "futurePrice": 976.5
        },
        {
          "date": "9/12/2565",
          "OI": -41590.0,
          "futurePrice": 981.2
        },
        {
          "date": "13/12/2565",
          "OI": -41809.0,
          "futurePrice": 981.3
        },
        {
          "date": "14/12/2565",
          "OI": -42368.0,
          "futurePrice": 986.5
        },
        {
          "date": "15/12/2565",
          "OI": -41571.0,
          "futurePrice": 977.9
        },
        {
          "date": "16/12/2565",
          "OI": -41439.0,
          "futurePrice": 981.9
        },
        {
          "date": "19/12/2565",
          "OI": -40568.0,
          "futurePrice": 980.3
        },
        {
          "date": "20/12/2565",
          "OI": -39762.0,
          "futurePrice": 975.1
        },
        {
          "date": "21/12/2565",
          "OI": -39803.0,
          "futurePrice": 974.0
        },
        {
          "date": "22/12/2565",
          "OI": -39152.0,
          "futurePrice": 977.3
        },
        {
          "date": "23/12/2565",
          "OI": -39014.0,
          "futurePrice": 981.3
        },
        {
          "date": "26/12/2565",
          "OI": -38983.0,
          "futurePrice": 985.4
        },
        {
          "date": "27/12/2565",
          "OI": -41403.0,
          "futurePrice": 998.6
        },
        {
          "date": "28/12/2565",
          "OI": -42855.0,
          "futurePrice": 997.1
        },
        {
          "date": "29/12/2565",
          "OI": -44543.0,
          "futurePrice": 1002.4
        },
        {
          "date": "30/12/2565",
          "OI": 0.0,
          "futurePrice": 1007.3
        },
        {
          "date": "2/1/2566",
          "OI": 0.0,
          "futurePrice": null
        }
      ]
    }
  },
  2023: {
    S50H23: {
      volumeData: [
        {
          "date": "29/9/2565",
          "callPut": -121.0,
          "futurePrice": 957.6
        },
        {
          "date": "30/9/2565",
          "callPut": -167.0,
          "futurePrice": 951.8
        },
        {
          "date": "3/10/2565",
          "callPut": -2289.0,
          "futurePrice": 940.0
        },
        {
          "date": "4/10/2565",
          "callPut": -159.0,
          "futurePrice": 943.5
        },
        {
          "date": "5/10/2565",
          "callPut": -75.0,
          "futurePrice": 942.0
        },
        {
          "date": "6/10/2565",
          "callPut": -141.0,
          "futurePrice": 947.6
        },
        {
          "date": "7/10/2565",
          "callPut": -105.0,
          "futurePrice": 946.1
        },
        {
          "date": "10/10/2565",
          "callPut": -213.0,
          "futurePrice": 937.1
        },
        {
          "date": "11/10/2565",
          "callPut": -487.0,
          "futurePrice": 931.8
        },
        {
          "date": "12/10/2565",
          "callPut": 11.0,
          "futurePrice": 936.6
        },
        {
          "date": "17/10/2565",
          "callPut": -270.0,
          "futurePrice": 940.7
        },
        {
          "date": "18/10/2565",
          "callPut": 75.0,
          "futurePrice": 949.1
        },
        {
          "date": "19/10/2565",
          "callPut": 25.0,
          "futurePrice": 951.0
        },
        {
          "date": "20/10/2565",
          "callPut": 55.0,
          "futurePrice": 958.5
        },
        {
          "date": "21/10/2565",
          "callPut": 23.0,
          "futurePrice": 959.2
        },
        {
          "date": "25/10/2565",
          "callPut": -39.0,
          "futurePrice": 965.2
        },
        {
          "date": "26/10/2565",
          "callPut": 209.0,
          "futurePrice": 965.8
        },
        {
          "date": "27/10/2565",
          "callPut": 287.0,
          "futurePrice": 973.6
        },
        {
          "date": "28/10/2565",
          "callPut": 60.0,
          "futurePrice": 977.1
        },
        {
          "date": "31/10/2565",
          "callPut": -66.0,
          "futurePrice": 979.1
        },
        {
          "date": "1/11/2565",
          "callPut": 415.0,
          "futurePrice": 992.1
        },
        {
          "date": "2/11/2565",
          "callPut": 40.0,
          "futurePrice": 991.1
        },
        {
          "date": "3/11/2565",
          "callPut": 21.0,
          "futurePrice": 988.7
        },
        {
          "date": "4/11/2565",
          "callPut": 176.0,
          "futurePrice": 985.9
        },
        {
          "date": "7/11/2565",
          "callPut": 3.0,
          "futurePrice": 988.5
        },
        {
          "date": "8/11/2565",
          "callPut": 17.0,
          "futurePrice": 996.6
        },
        {
          "date": "9/11/2565",
          "callPut": 58.0,
          "futurePrice": 988.8
        },
        {
          "date": "10/11/2565",
          "callPut": -187.0,
          "futurePrice": 987.8
        },
        {
          "date": "11/11/2565",
          "callPut": 246.0,
          "futurePrice": 1003.5
        },
        {
          "date": "14/11/2565",
          "callPut": -126.0,
          "futurePrice": 992.8
        },
        {
          "date": "15/11/2565",
          "callPut": 133.0,
          "futurePrice": 993.7
        },
        {
          "date": "16/11/2565",
          "callPut": -36.0,
          "futurePrice": 985.2
        },
        {
          "date": "17/11/2565",
          "callPut": 24.0,
          "futurePrice": 980.9
        },
        {
          "date": "18/11/2565",
          "callPut": 29.0,
          "futurePrice": 983.7
        },
        {
          "date": "21/11/2565",
          "callPut": 149.0,
          "futurePrice": 984.8
        },
        {
          "date": "22/11/2565",
          "callPut": -102.0,
          "futurePrice": 983.6
        },
        {
          "date": "23/11/2565",
          "callPut": 129.0,
          "futurePrice": 991.7
        },
        {
          "date": "24/11/2565",
          "callPut": -43.0,
          "futurePrice": 988.5
        },
        {
          "date": "25/11/2565",
          "callPut": -53.0,
          "futurePrice": 982.8
        },
        {
          "date": "28/11/2565",
          "callPut": 35.0,
          "futurePrice": 979.5
        },
        {
          "date": "29/11/2565",
          "callPut": -649.0,
          "futurePrice": 987.1
        },
        {
          "date": "30/11/2565",
          "callPut": -23.0,
          "futurePrice": 994.8
        },
        {
          "date": "1/12/2565",
          "callPut": -194.0,
          "futurePrice": 994.3
        },
        {
          "date": "2/12/2565",
          "callPut": -235.0,
          "futurePrice": 988.5
        },
        {
          "date": "6/12/2565",
          "callPut": -820.0,
          "futurePrice": 984.4
        },
        {
          "date": "7/12/2565",
          "callPut": -493.0,
          "futurePrice": 980.9
        },
        {
          "date": "8/12/2565",
          "callPut": 199.0,
          "futurePrice": 976.5
        },
        {
          "date": "9/12/2565",
          "callPut": -690.0,
          "futurePrice": 981.2
        },
        {
          "date": "13/12/2565",
          "callPut": -688.0,
          "futurePrice": 981.3
        },
        {
          "date": "14/12/2565",
          "callPut": -611.0,
          "futurePrice": 986.5
        },
        {
          "date": "15/12/2565",
          "callPut": 167.0,
          "futurePrice": 977.9
        },
        {
          "date": "16/12/2565",
          "callPut": -610.0,
          "futurePrice": 981.9
        },
        {
          "date": "19/12/2565",
          "callPut": -327.0,
          "futurePrice": 980.3
        },
        {
          "date": "20/12/2565",
          "callPut": -625.0,
          "futurePrice": 975.1
        },
        {
          "date": "21/12/2565",
          "callPut": -27.0,
          "futurePrice": 974.0
        },
        {
          "date": "22/12/2565",
          "callPut": -410.0,
          "futurePrice": 977.3
        },
        {
          "date": "23/12/2565",
          "callPut": 121.0,
          "futurePrice": 981.3
        },
        {
          "date": "26/12/2565",
          "callPut": -335.0,
          "futurePrice": 985.4
        },
        {
          "date": "27/12/2565",
          "callPut": 971.0,
          "futurePrice": 998.6
        },
        {
          "date": "28/12/2565",
          "callPut": 1479.0,
          "futurePrice": 997.1
        },
        {
          "date": "29/12/2565",
          "callPut": -476.0,
          "futurePrice": 1002.4
        },
        {
          "date": "30/12/2565",
          "callPut": 790.0,
          "futurePrice": 1007.3
        },
        {
          "date": "3/1/2566",
          "callPut": -820.0,
          "futurePrice": 1008.1
        },
        {
          "date": "4/1/2566",
          "callPut": 360.0,
          "futurePrice": 1005.1
        },
        {
          "date": "5/1/2566",
          "callPut": -497.0,
          "futurePrice": 999.5
        },
        {
          "date": "6/1/2566",
          "callPut": 1034.0,
          "futurePrice": 1003.6
        },
        {
          "date": "9/1/2566",
          "callPut": 494.0,
          "futurePrice": 1014.6
        },
        {
          "date": "10/1/2566",
          "callPut": 3689.0,
          "futurePrice": 1012.5
        },
        {
          "date": "11/1/2566",
          "callPut": -951.0,
          "futurePrice": 1008.5
        },
        {
          "date": "12/1/2566",
          "callPut": 54.0,
          "futurePrice": 1009.8
        },
        {
          "date": "13/1/2566",
          "callPut": 104.0,
          "futurePrice": 1003.6
        },
        {
          "date": "16/1/2566",
          "callPut": -3542.0,
          "futurePrice": 1003.5
        },
        {
          "date": "17/1/2566",
          "callPut": -349.0,
          "futurePrice": 1002.5
        },
        {
          "date": "18/1/2566",
          "callPut": -167.0,
          "futurePrice": 1004.6
        },
        {
          "date": "19/1/2566",
          "callPut": -622.0,
          "futurePrice": 1005.8
        },
        {
          "date": "20/1/2566",
          "callPut": -1542.0,
          "futurePrice": 1000.2
        },
        {
          "date": "23/1/2566",
          "callPut": -4664.0,
          "futurePrice": 1004.4
        },
        {
          "date": "24/1/2566",
          "callPut": -1961.0,
          "futurePrice": 1001.6
        },
        {
          "date": "25/1/2566",
          "callPut": -2978.0,
          "futurePrice": 1002.1
        },
        {
          "date": "26/1/2566",
          "callPut": 1197.0,
          "futurePrice": 995.3
        },
        {
          "date": "27/1/2566",
          "callPut": 12.0,
          "futurePrice": 1000.0
        },
        {
          "date": "30/1/2566",
          "callPut": -1403.0,
          "futurePrice": 998.9
        },
        {
          "date": "31/1/2566",
          "callPut": -1353.0,
          "futurePrice": 990.3
        },
        {
          "date": "1/2/2566",
          "callPut": -4732.0,
          "futurePrice": 997.5
        },
        {
          "date": "2/2/2566",
          "callPut": -472.0,
          "futurePrice": 996.1
        },
        {
          "date": "3/2/2566",
          "callPut": 112.0,
          "futurePrice": 1001.1
        },
        {
          "date": "6/2/2566",
          "callPut": -58.0,
          "futurePrice": 995.0
        },
        {
          "date": "7/2/2566",
          "callPut": 523.0,
          "futurePrice": 995.3
        },
        {
          "date": "8/2/2566",
          "callPut": 1481.0,
          "futurePrice": 988.3
        },
        {
          "date": "9/2/2566",
          "callPut": 3554.0,
          "futurePrice": 987.0
        },
        {
          "date": "10/2/2566",
          "callPut": 228.0,
          "futurePrice": 982.7
        },
        {
          "date": "13/2/2566",
          "callPut": 978.0,
          "futurePrice": 984.5
        },
        {
          "date": "14/2/2566",
          "callPut": 732.0,
          "futurePrice": 984.3
        },
        {
          "date": "15/2/2566",
          "callPut": 795.0,
          "futurePrice": 983.8
        },
        {
          "date": "16/2/2566",
          "callPut": 462.0,
          "futurePrice": 990.9
        },
        {
          "date": "17/2/2566",
          "callPut": 1019.0,
          "futurePrice": 988.4
        },
        {
          "date": "20/2/2566",
          "callPut": -933.0,
          "futurePrice": 989.1
        },
        {
          "date": "21/2/2566",
          "callPut": 1574.0,
          "futurePrice": 994.7
        },
        {
          "date": "22/2/2566",
          "callPut": 2129.0,
          "futurePrice": 986.4
        },
        {
          "date": "23/2/2566",
          "callPut": 1122.0,
          "futurePrice": 983.1
        },
        {
          "date": "24/2/2566",
          "callPut": 3443.0,
          "futurePrice": 967.7
        },
        {
          "date": "27/2/2566",
          "callPut": -3537.0,
          "futurePrice": 967.5
        },
        {
          "date": "28/2/2566",
          "callPut": 194.0,
          "futurePrice": 965.8
        },
        {
          "date": "1/3/2566",
          "callPut": 808.0,
          "futurePrice": 961.4
        },
        {
          "date": "2/3/2566",
          "callPut": 1093.0,
          "futurePrice": 962.3
        },
        {
          "date": "3/3/2566",
          "callPut": -548.0,
          "futurePrice": 957.8
        },
        {
          "date": "7/3/2566",
          "callPut": 2495.0,
          "futurePrice": 964.9
        },
        {
          "date": "8/3/2566",
          "callPut": -576.0,
          "futurePrice": 959.6
        },
        {
          "date": "9/3/2566",
          "callPut": -667.0,
          "futurePrice": 960.1
        },
        {
          "date": "10/3/2566",
          "callPut": -2640.0,
          "futurePrice": 954.1
        },
        {
          "date": "13/3/2566",
          "callPut": -1908.0,
          "futurePrice": 932.4
        },
        {
          "date": "14/3/2566",
          "callPut": -23857.0,
          "futurePrice": 905.4
        },
        {
          "date": "15/3/2566",
          "callPut": -3211.0,
          "futurePrice": 932.3
        },
        {
          "date": "16/3/2566",
          "callPut": -2443.0,
          "futurePrice": 928.7
        },
        {
          "date": "17/3/2566",
          "callPut": -3481.0,
          "futurePrice": 939.5
        },
        {
          "date": "20/3/2566",
          "callPut": -3563.0,
          "futurePrice": 933.2
        },
        {
          "date": "21/3/2566",
          "callPut": -286.0,
          "futurePrice": 948.4
        },
        {
          "date": "22/3/2566",
          "callPut": 2261.0,
          "futurePrice": 953.9
        },
        {
          "date": "23/3/2566",
          "callPut": 4564.0,
          "futurePrice": 959.1
        },
        {
          "date": "24/3/2566",
          "callPut": 7345.0,
          "futurePrice": 955.8
        },
        {
          "date": "27/3/2566",
          "callPut": 500.0,
          "futurePrice": 959.7
        },
        {
          "date": "28/3/2566",
          "callPut": 2499.0,
          "futurePrice": 966.7
        },
        {
          "date": "29/3/2566",
          "callPut": 2808.0,
          "futurePrice": 967.5
        },
        {
          "date": "30/3/2566",
          "callPut": 2335.0,
          "futurePrice": 963.9
        },
        {
          "date": "31/3/2566",
          "callPut": 0.0,
          "futurePrice": 968.3
        },
        {
          "date": "3/4/2566",
          "callPut": 0.0,
          "futurePrice": 962.3
        }
      ],
      openInterestData: [
        {
          "date": "29/9/2565",
          "OI": -101.0,
          "futurePrice": 957.6
        },
        {
          "date": "30/9/2565",
          "OI": -255.0,
          "futurePrice": 951.8
        },
        {
          "date": "3/10/2565",
          "OI": -2410.0,
          "futurePrice": 940.0
        },
        {
          "date": "4/10/2565",
          "OI": -2521.0,
          "futurePrice": 943.5
        },
        {
          "date": "5/10/2565",
          "OI": -2564.0,
          "futurePrice": 942.0
        },
        {
          "date": "6/10/2565",
          "OI": -2659.0,
          "futurePrice": 947.6
        },
        {
          "date": "7/10/2565",
          "OI": -2730.0,
          "futurePrice": 946.1
        },
        {
          "date": "10/10/2565",
          "OI": -2925.0,
          "futurePrice": 937.1
        },
        {
          "date": "11/10/2565",
          "OI": -3392.0,
          "futurePrice": 931.8
        },
        {
          "date": "12/10/2565",
          "OI": -3374.0,
          "futurePrice": 936.6
        },
        {
          "date": "17/10/2565",
          "OI": -3594.0,
          "futurePrice": 940.7
        },
        {
          "date": "18/10/2565",
          "OI": -3482.0,
          "futurePrice": 949.1
        },
        {
          "date": "19/10/2565",
          "OI": -3460.0,
          "futurePrice": 951.0
        },
        {
          "date": "20/10/2565",
          "OI": -3369.0,
          "futurePrice": 958.5
        },
        {
          "date": "21/10/2565",
          "OI": -3352.0,
          "futurePrice": 959.2
        },
        {
          "date": "25/10/2565",
          "OI": -3379.0,
          "futurePrice": 965.2
        },
        {
          "date": "26/10/2565",
          "OI": -3229.0,
          "futurePrice": 965.8
        },
        {
          "date": "27/10/2565",
          "OI": -2958.0,
          "futurePrice": 973.6
        },
        {
          "date": "28/10/2565",
          "OI": -2909.0,
          "futurePrice": 977.1
        },
        {
          "date": "31/10/2565",
          "OI": -2918.0,
          "futurePrice": 979.1
        },
        {
          "date": "1/11/2565",
          "OI": -2583.0,
          "futurePrice": 992.1
        },
        {
          "date": "2/11/2565",
          "OI": -2658.0,
          "futurePrice": 991.1
        },
        {
          "date": "3/11/2565",
          "OI": -2734.0,
          "futurePrice": 988.7
        },
        {
          "date": "4/11/2565",
          "OI": -2757.0,
          "futurePrice": 985.9
        },
        {
          "date": "7/11/2565",
          "OI": -2737.0,
          "futurePrice": 988.5
        },
        {
          "date": "8/11/2565",
          "OI": -2627.0,
          "futurePrice": 996.6
        },
        {
          "date": "9/11/2565",
          "OI": -2582.0,
          "futurePrice": 988.8
        },
        {
          "date": "10/11/2565",
          "OI": -2657.0,
          "futurePrice": 987.8
        },
        {
          "date": "11/11/2565",
          "OI": -2389.0,
          "futurePrice": 1003.5
        },
        {
          "date": "14/11/2565",
          "OI": -2459.0,
          "futurePrice": 992.8
        },
        {
          "date": "15/11/2565",
          "OI": -2371.0,
          "futurePrice": 993.7
        },
        {
          "date": "16/11/2565",
          "OI": -2328.0,
          "futurePrice": 985.2
        },
        {
          "date": "17/11/2565",
          "OI": -2445.0,
          "futurePrice": 980.9
        },
        {
          "date": "18/11/2565",
          "OI": -2415.0,
          "futurePrice": 983.7
        },
        {
          "date": "21/11/2565",
          "OI": -2402.0,
          "futurePrice": 984.8
        },
        {
          "date": "22/11/2565",
          "OI": -2503.0,
          "futurePrice": 983.6
        },
        {
          "date": "23/11/2565",
          "OI": -2465.0,
          "futurePrice": 991.7
        },
        {
          "date": "24/11/2565",
          "OI": -2495.0,
          "futurePrice": 988.5
        },
        {
          "date": "25/11/2565",
          "OI": -2536.0,
          "futurePrice": 982.8
        },
        {
          "date": "28/11/2565",
          "OI": -2510.0,
          "futurePrice": 979.5
        },
        {
          "date": "29/11/2565",
          "OI": -2641.0,
          "futurePrice": 987.1
        },
        {
          "date": "30/11/2565",
          "OI": -2362.0,
          "futurePrice": 994.8
        },
        {
          "date": "1/12/2565",
          "OI": -2619.0,
          "futurePrice": 994.3
        },
        {
          "date": "2/12/2565",
          "OI": -2945.0,
          "futurePrice": 988.5
        },
        {
          "date": "6/12/2565",
          "OI": -3191.0,
          "futurePrice": 984.4
        },
        {
          "date": "7/12/2565",
          "OI": -3634.0,
          "futurePrice": 980.9
        },
        {
          "date": "8/12/2565",
          "OI": -3415.0,
          "futurePrice": 976.5
        },
        {
          "date": "9/12/2565",
          "OI": -3931.0,
          "futurePrice": 981.2
        },
        {
          "date": "13/12/2565",
          "OI": -4731.0,
          "futurePrice": 981.3
        },
        {
          "date": "14/12/2565",
          "OI": -5227.0,
          "futurePrice": 986.5
        },
        {
          "date": "15/12/2565",
          "OI": -5060.0,
          "futurePrice": 977.9
        },
        {
          "date": "16/12/2565",
          "OI": -5545.0,
          "futurePrice": 981.9
        },
        {
          "date": "19/12/2565",
          "OI": -5715.0,
          "futurePrice": 980.3
        },
        {
          "date": "20/12/2565",
          "OI": -6956.0,
          "futurePrice": 975.1
        },
        {
          "date": "21/12/2565",
          "OI": -7013.0,
          "futurePrice": 974.0
        },
        {
          "date": "22/12/2565",
          "OI": -7457.0,
          "futurePrice": 977.3
        },
        {
          "date": "23/12/2565",
          "OI": -7416.0,
          "futurePrice": 981.3
        },
        {
          "date": "26/12/2565",
          "OI": -7821.0,
          "futurePrice": 985.4
        },
        {
          "date": "27/12/2565",
          "OI": -8085.0,
          "futurePrice": 998.6
        },
        {
          "date": "28/12/2565",
          "OI": -7421.0,
          "futurePrice": 997.1
        },
        {
          "date": "29/12/2565",
          "OI": -9396.0,
          "futurePrice": 1002.4
        },
        {
          "date": "30/12/2565",
          "OI": -10393.0,
          "futurePrice": 1007.3
        },
        {
          "date": "3/1/2566",
          "OI": -10736.0,
          "futurePrice": 1008.1
        },
        {
          "date": "4/1/2566",
          "OI": -10695.0,
          "futurePrice": 1005.1
        },
        {
          "date": "5/1/2566",
          "OI": -11722.0,
          "futurePrice": 999.5
        },
        {
          "date": "6/1/2566",
          "OI": -11942.0,
          "futurePrice": 1003.6
        },
        {
          "date": "9/1/2566",
          "OI": -9320.0,
          "futurePrice": 1014.6
        },
        {
          "date": "10/1/2566",
          "OI": -7377.0,
          "futurePrice": 1012.5
        },
        {
          "date": "11/1/2566",
          "OI": -8812.0,
          "futurePrice": 1008.5
        },
        {
          "date": "12/1/2566",
          "OI": -9534.0,
          "futurePrice": 1009.8
        },
        {
          "date": "13/1/2566",
          "OI": -9169.0,
          "futurePrice": 1003.6
        },
        {
          "date": "16/1/2566",
          "OI": -12322.0,
          "futurePrice": 1003.5
        },
        {
          "date": "17/1/2566",
          "OI": -12832.0,
          "futurePrice": 1002.5
        },
        {
          "date": "18/1/2566",
          "OI": -12960.0,
          "futurePrice": 1004.6
        },
        {
          "date": "19/1/2566",
          "OI": -13829.0,
          "futurePrice": 1005.8
        },
        {
          "date": "20/1/2566",
          "OI": -13602.0,
          "futurePrice": 1000.2
        },
        {
          "date": "23/1/2566",
          "OI": -17822.0,
          "futurePrice": 1004.4
        },
        {
          "date": "24/1/2566",
          "OI": -19677.0,
          "futurePrice": 1001.6
        },
        {
          "date": "25/1/2566",
          "OI": -22306.0,
          "futurePrice": 1002.1
        },
        {
          "date": "26/1/2566",
          "OI": -21061.0,
          "futurePrice": 995.3
        },
        {
          "date": "27/1/2566",
          "OI": -21547.0,
          "futurePrice": 1000.0
        },
        {
          "date": "30/1/2566",
          "OI": -21780.0,
          "futurePrice": 998.9
        },
        {
          "date": "31/1/2566",
          "OI": -23294.0,
          "futurePrice": 990.3
        },
        {
          "date": "1/2/2566",
          "OI": -27840.0,
          "futurePrice": 997.5
        },
        {
          "date": "2/2/2566",
          "OI": -28455.0,
          "futurePrice": 996.1
        },
        {
          "date": "3/2/2566",
          "OI": -28583.0,
          "futurePrice": 1001.1
        },
        {
          "date": "6/2/2566",
          "OI": -28346.0,
          "futurePrice": 995.0
        },
        {
          "date": "7/2/2566",
          "OI": -28785.0,
          "futurePrice": 995.3
        },
        {
          "date": "8/2/2566",
          "OI": -27647.0,
          "futurePrice": 988.3
        },
        {
          "date": "9/2/2566",
          "OI": -24968.0,
          "futurePrice": 987.0
        },
        {
          "date": "10/2/2566",
          "OI": -24087.0,
          "futurePrice": 982.7
        },
        {
          "date": "13/2/2566",
          "OI": -23711.0,
          "futurePrice": 984.5
        },
        {
          "date": "14/2/2566",
          "OI": -23487.0,
          "futurePrice": 984.3
        },
        {
          "date": "15/2/2566",
          "OI": -22853.0,
          "futurePrice": 983.8
        },
        {
          "date": "16/2/2566",
          "OI": -22514.0,
          "futurePrice": 990.9
        },
        {
          "date": "17/2/2566",
          "OI": -21617.0,
          "futurePrice": 988.4
        },
        {
          "date": "20/2/2566",
          "OI": -20298.0,
          "futurePrice": 989.1
        },
        {
          "date": "21/2/2566",
          "OI": -20062.0,
          "futurePrice": 994.7
        },
        {
          "date": "22/2/2566",
          "OI": -17795.0,
          "futurePrice": 986.4
        },
        {
          "date": "23/2/2566",
          "OI": -14325.0,
          "futurePrice": 983.1
        },
        {
          "date": "24/2/2566",
          "OI": -8471.0,
          "futurePrice": 967.7
        },
        {
          "date": "27/2/2566",
          "OI": -7001.0,
          "futurePrice": 967.5
        },
        {
          "date": "28/2/2566",
          "OI": -3912.0,
          "futurePrice": 965.8
        },
        {
          "date": "1/3/2566",
          "OI": -1814.0,
          "futurePrice": 961.4
        },
        {
          "date": "2/3/2566",
          "OI": -1950.0,
          "futurePrice": 962.3
        },
        {
          "date": "3/3/2566",
          "OI": 611.0,
          "futurePrice": 957.8
        },
        {
          "date": "7/3/2566",
          "OI": -660.0,
          "futurePrice": 964.9
        },
        {
          "date": "8/3/2566",
          "OI": 2170.0,
          "futurePrice": 959.6
        },
        {
          "date": "9/3/2566",
          "OI": 3193.0,
          "futurePrice": 960.1
        },
        {
          "date": "10/3/2566",
          "OI": 5271.0,
          "futurePrice": 954.1
        },
        {
          "date": "13/3/2566",
          "OI": 13141.0,
          "futurePrice": 932.4
        },
        {
          "date": "14/3/2566",
          "OI": 32430.0,
          "futurePrice": 905.4
        },
        {
          "date": "15/3/2566",
          "OI": 33860.0,
          "futurePrice": 932.3
        },
        {
          "date": "16/3/2566",
          "OI": 36743.0,
          "futurePrice": 928.7
        },
        {
          "date": "17/3/2566",
          "OI": 36339.0,
          "futurePrice": 939.5
        },
        {
          "date": "20/3/2566",
          "OI": 35378.0,
          "futurePrice": 933.2
        },
        {
          "date": "21/3/2566",
          "OI": 35036.0,
          "futurePrice": 948.4
        },
        {
          "date": "22/3/2566",
          "OI": 33654.0,
          "futurePrice": 953.9
        },
        {
          "date": "23/3/2566",
          "OI": 32026.0,
          "futurePrice": 959.1
        },
        {
          "date": "24/3/2566",
          "OI": 27924.0,
          "futurePrice": 955.8
        },
        {
          "date": "27/3/2566",
          "OI": 27467.0,
          "futurePrice": 959.7
        },
        {
          "date": "28/3/2566",
          "OI": 27130.0,
          "futurePrice": 966.7
        },
        {
          "date": "29/3/2566",
          "OI": 25032.0,
          "futurePrice": 967.5
        },
        {
          "date": "30/3/2566",
          "OI": 25281.0,
          "futurePrice": 963.9
        },
        {
          "date": "31/3/2566",
          "OI": 0.0,
          "futurePrice": 968.3
        },
        {
          "date": "3/4/2566",
          "OI": 0.0,
          "futurePrice": 962.3
        }
      ]
    },
    S50M23: {
      volumeData: [
        {
          "date": "29/12/2565",
          "callPut": -110.0,
          "futurePrice": 1002.4
        },
        {
          "date": "30/12/2565",
          "callPut": 177.0,
          "futurePrice": 1007.3
        },
        {
          "date": "3/1/2566",
          "callPut": 8.0,
          "futurePrice": 1008.1
        },
        {
          "date": "4/1/2566",
          "callPut": -76.0,
          "futurePrice": 1005.1
        },
        {
          "date": "5/1/2566",
          "callPut": 26.0,
          "futurePrice": 999.5
        },
        {
          "date": "6/1/2566",
          "callPut": 455.0,
          "futurePrice": 1003.6
        },
        {
          "date": "9/1/2566",
          "callPut": -1585.0,
          "futurePrice": 1014.6
        },
        {
          "date": "10/1/2566",
          "callPut": 212.0,
          "futurePrice": 1012.5
        },
        {
          "date": "11/1/2566",
          "callPut": -11.0,
          "futurePrice": 1008.5
        },
        {
          "date": "12/1/2566",
          "callPut": 28.0,
          "futurePrice": 1009.8
        },
        {
          "date": "13/1/2566",
          "callPut": -117.0,
          "futurePrice": 1003.6
        },
        {
          "date": "16/1/2566",
          "callPut": -48.0,
          "futurePrice": 1003.5
        },
        {
          "date": "17/1/2566",
          "callPut": 133.0,
          "futurePrice": 1002.5
        },
        {
          "date": "18/1/2566",
          "callPut": 237.0,
          "futurePrice": 1004.6
        },
        {
          "date": "19/1/2566",
          "callPut": -40.0,
          "futurePrice": 1005.8
        },
        {
          "date": "20/1/2566",
          "callPut": -252.0,
          "futurePrice": 1000.2
        },
        {
          "date": "23/1/2566",
          "callPut": -166.0,
          "futurePrice": 1004.4
        },
        {
          "date": "24/1/2566",
          "callPut": 22.0,
          "futurePrice": 1001.6
        },
        {
          "date": "25/1/2566",
          "callPut": -71.0,
          "futurePrice": 1002.1
        },
        {
          "date": "26/1/2566",
          "callPut": -506.0,
          "futurePrice": 995.3
        },
        {
          "date": "27/1/2566",
          "callPut": -80.0,
          "futurePrice": 1000.0
        },
        {
          "date": "30/1/2566",
          "callPut": 1.0,
          "futurePrice": 998.9
        },
        {
          "date": "31/1/2566",
          "callPut": 106.0,
          "futurePrice": 990.3
        },
        {
          "date": "1/2/2566",
          "callPut": 51.0,
          "futurePrice": 997.5
        },
        {
          "date": "2/2/2566",
          "callPut": 167.0,
          "futurePrice": 996.1
        },
        {
          "date": "3/2/2566",
          "callPut": -356.0,
          "futurePrice": 1001.1
        },
        {
          "date": "6/2/2566",
          "callPut": -295.0,
          "futurePrice": 995.0
        },
        {
          "date": "7/2/2566",
          "callPut": -145.0,
          "futurePrice": 995.3
        },
        {
          "date": "8/2/2566",
          "callPut": -84.0,
          "futurePrice": 988.3
        },
        {
          "date": "9/2/2566",
          "callPut": -47.0,
          "futurePrice": 987.0
        },
        {
          "date": "10/2/2566",
          "callPut": 396.0,
          "futurePrice": 982.7
        },
        {
          "date": "13/2/2566",
          "callPut": -538.0,
          "futurePrice": 984.5
        },
        {
          "date": "14/2/2566",
          "callPut": -884.0,
          "futurePrice": 984.3
        },
        {
          "date": "15/2/2566",
          "callPut": 54.0,
          "futurePrice": 983.8
        },
        {
          "date": "16/2/2566",
          "callPut": 429.0,
          "futurePrice": 990.9
        },
        {
          "date": "17/2/2566",
          "callPut": -1267.0,
          "futurePrice": 988.4
        },
        {
          "date": "20/2/2566",
          "callPut": 10.0,
          "futurePrice": 989.1
        },
        {
          "date": "21/2/2566",
          "callPut": 255.0,
          "futurePrice": 994.7
        },
        {
          "date": "22/2/2566",
          "callPut": -1547.0,
          "futurePrice": 986.4
        },
        {
          "date": "23/2/2566",
          "callPut": -251.0,
          "futurePrice": 983.1
        },
        {
          "date": "24/2/2566",
          "callPut": -867.0,
          "futurePrice": 967.7
        },
        {
          "date": "27/2/2566",
          "callPut": 240.0,
          "futurePrice": 967.5
        },
        {
          "date": "28/2/2566",
          "callPut": -259.0,
          "futurePrice": 965.8
        },
        {
          "date": "1/3/2566",
          "callPut": 109.0,
          "futurePrice": 961.4
        },
        {
          "date": "2/3/2566",
          "callPut": 183.0,
          "futurePrice": 962.3
        },
        {
          "date": "3/3/2566",
          "callPut": 26.0,
          "futurePrice": 957.8
        },
        {
          "date": "7/3/2566",
          "callPut": 246.0,
          "futurePrice": 964.9
        },
        {
          "date": "8/3/2566",
          "callPut": 189.0,
          "futurePrice": 959.6
        },
        {
          "date": "9/3/2566",
          "callPut": 633.0,
          "futurePrice": 960.1
        },
        {
          "date": "10/3/2566",
          "callPut": 616.0,
          "futurePrice": 954.1
        },
        {
          "date": "13/3/2566",
          "callPut": -2798.0,
          "futurePrice": 932.4
        },
        {
          "date": "14/3/2566",
          "callPut": -4722.0,
          "futurePrice": 905.4
        },
        {
          "date": "15/3/2566",
          "callPut": -336.0,
          "futurePrice": 932.3
        },
        {
          "date": "16/3/2566",
          "callPut": -322.0,
          "futurePrice": 928.7
        },
        {
          "date": "17/3/2566",
          "callPut": -225.0,
          "futurePrice": 939.5
        },
        {
          "date": "20/3/2566",
          "callPut": -260.0,
          "futurePrice": 933.2
        },
        {
          "date": "21/3/2566",
          "callPut": 82.0,
          "futurePrice": 948.4
        },
        {
          "date": "22/3/2566",
          "callPut": 179.0,
          "futurePrice": 953.9
        },
        {
          "date": "23/3/2566",
          "callPut": 535.0,
          "futurePrice": 959.1
        },
        {
          "date": "24/3/2566",
          "callPut": 517.0,
          "futurePrice": 955.8
        },
        {
          "date": "27/3/2566",
          "callPut": 436.0,
          "futurePrice": 959.7
        },
        {
          "date": "28/3/2566",
          "callPut": 1101.0,
          "futurePrice": 966.7
        },
        {
          "date": "29/3/2566",
          "callPut": 1161.0,
          "futurePrice": 967.5
        },
        {
          "date": "30/3/2566",
          "callPut": 2028.0,
          "futurePrice": 963.9
        },
        {
          "date": "31/3/2566",
          "callPut": -972.0,
          "futurePrice": 968.3
        },
        {
          "date": "3/4/2566",
          "callPut": -71.0,
          "futurePrice": 962.3
        },
        {
          "date": "4/4/2566",
          "callPut": 1149.0,
          "futurePrice": 959.2
        },
        {
          "date": "5/4/2566",
          "callPut": 856.0,
          "futurePrice": 944.1
        },
        {
          "date": "7/4/2566",
          "callPut": -35.0,
          "futurePrice": 946.7
        },
        {
          "date": "10/4/2566",
          "callPut": 1427.0,
          "futurePrice": 961.7
        },
        {
          "date": "11/4/2566",
          "callPut": -407.0,
          "futurePrice": 962.3
        },
        {
          "date": "12/4/2566",
          "callPut": 846.0,
          "futurePrice": 960.5
        },
        {
          "date": "17/4/2566",
          "callPut": 783.0,
          "futurePrice": 964.6
        },
        {
          "date": "18/4/2566",
          "callPut": -506.0,
          "futurePrice": 960.6
        },
        {
          "date": "19/4/2566",
          "callPut": -531.0,
          "futurePrice": 951.7
        },
        {
          "date": "20/4/2566",
          "callPut": 761.0,
          "futurePrice": 943.8
        },
        {
          "date": "21/4/2566",
          "callPut": 516.0,
          "futurePrice": 937.4
        },
        {
          "date": "24/4/2566",
          "callPut": -503.0,
          "futurePrice": 937.8
        },
        {
          "date": "25/4/2566",
          "callPut": -696.0,
          "futurePrice": 930.2
        },
        {
          "date": "26/4/2566",
          "callPut": 1117.0,
          "futurePrice": 932.1
        },
        {
          "date": "27/4/2566",
          "callPut": 1038.0,
          "futurePrice": 924.1
        },
        {
          "date": "28/4/2566",
          "callPut": -141.0,
          "futurePrice": 920.4
        },
        {
          "date": "2/5/2566",
          "callPut": -689.0,
          "futurePrice": 926.9
        },
        {
          "date": "3/5/2566",
          "callPut": 215.0,
          "futurePrice": 928.4
        },
        {
          "date": "8/5/2566",
          "callPut": 3651.0,
          "futurePrice": 945.9
        },
        {
          "date": "9/5/2566",
          "callPut": 1411.0,
          "futurePrice": 947.4
        },
        {
          "date": "10/5/2566",
          "callPut": -2130.0,
          "futurePrice": 947.9
        },
        {
          "date": "11/5/2566",
          "callPut": 960.0,
          "futurePrice": 948.6
        },
        {
          "date": "12/5/2566",
          "callPut": 1860.0,
          "futurePrice": 948.6
        },
        {
          "date": "15/5/2566",
          "callPut": 1605.0,
          "futurePrice": 933.5
        },
        {
          "date": "16/5/2566",
          "callPut": -608.0,
          "futurePrice": 932.8
        },
        {
          "date": "17/5/2566",
          "callPut": -873.0,
          "futurePrice": 920.9
        },
        {
          "date": "18/5/2566",
          "callPut": -928.0,
          "futurePrice": 926.2
        },
        {
          "date": "19/5/2566",
          "callPut": 1595.0,
          "futurePrice": 918.5
        },
        {
          "date": "22/5/2566",
          "callPut": -823.0,
          "futurePrice": 929.8
        },
        {
          "date": "23/5/2566",
          "callPut": 1182.0,
          "futurePrice": 926.2
        },
        {
          "date": "24/5/2566",
          "callPut": -343.0,
          "futurePrice": 926.9
        },
        {
          "date": "25/5/2566",
          "callPut": -282.0,
          "futurePrice": 927.1
        },
        {
          "date": "26/5/2566",
          "callPut": 1358.0,
          "futurePrice": 921.9
        },
        {
          "date": "29/5/2566",
          "callPut": -1528.0,
          "futurePrice": 931.0
        },
        {
          "date": "30/5/2566",
          "callPut": 700.0,
          "futurePrice": 924.7
        },
        {
          "date": "31/5/2566",
          "callPut": 700.0,
          "futurePrice": 921.7
        },
        {
          "date": "1/6/2566",
          "callPut": 1169.0,
          "futurePrice": 914.2
        },
        {
          "date": "2/6/2566",
          "callPut": -203.0,
          "futurePrice": 923.7
        },
        {
          "date": "6/6/2566",
          "callPut": 2054.0,
          "futurePrice": 921.4
        },
        {
          "date": "7/6/2566",
          "callPut": 776.0,
          "futurePrice": 926.6
        },
        {
          "date": "8/6/2566",
          "callPut": 4795.0,
          "futurePrice": 941.3
        },
        {
          "date": "9/6/2566",
          "callPut": 1406.0,
          "futurePrice": 940.1
        },
        {
          "date": "12/6/2566",
          "callPut": -724.0,
          "futurePrice": 939.0
        },
        {
          "date": "13/6/2566",
          "callPut": -858.0,
          "futurePrice": 945.2
        },
        {
          "date": "14/6/2566",
          "callPut": 916.0,
          "futurePrice": 946.0
        },
        {
          "date": "15/6/2566",
          "callPut": 140.0,
          "futurePrice": 947.0
        },
        {
          "date": "16/6/2566",
          "callPut": 3016.0,
          "futurePrice": 947.3
        },
        {
          "date": "19/6/2566",
          "callPut": -414.0,
          "futurePrice": 948.4
        },
        {
          "date": "20/6/2566",
          "callPut": 2899.0,
          "futurePrice": 935.0
        },
        {
          "date": "21/6/2566",
          "callPut": 2303.0,
          "futurePrice": 924.0
        },
        {
          "date": "22/6/2566",
          "callPut": -1073.0,
          "futurePrice": 918.5
        },
        {
          "date": "23/6/2566",
          "callPut": -658.0,
          "futurePrice": 915.4
        },
        {
          "date": "26/6/2566",
          "callPut": 190.0,
          "futurePrice": 908.1
        },
        {
          "date": "27/6/2566",
          "callPut": 225.0,
          "futurePrice": 902.9
        },
        {
          "date": "28/6/2566",
          "callPut": -1117.0,
          "futurePrice": 897.5
        },
        {
          "date": "29/6/2566",
          "callPut": -1112.0,
          "futurePrice": 899.3
        },
        {
          "date": "30/6/2566",
          "callPut": 0.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/7/2566",
          "callPut": 0.0,
          "futurePrice": 916.4
        }
      ],
      openInterestData: [
        {
          "date": "29/12/2565",
          "OI": -104.0,
          "futurePrice": 1002.4
        },
        {
          "date": "30/12/2565",
          "OI": 79.0,
          "futurePrice": 1007.3
        },
        {
          "date": "3/1/2566",
          "OI": 47.0,
          "futurePrice": 1008.1
        },
        {
          "date": "4/1/2566",
          "OI": -27.0,
          "futurePrice": 1005.1
        },
        {
          "date": "5/1/2566",
          "OI": -4.0,
          "futurePrice": 999.5
        },
        {
          "date": "6/1/2566",
          "OI": 450.0,
          "futurePrice": 1003.6
        },
        {
          "date": "9/1/2566",
          "OI": -1150.0,
          "futurePrice": 1014.6
        },
        {
          "date": "10/1/2566",
          "OI": -1000.0,
          "futurePrice": 1012.5
        },
        {
          "date": "11/1/2566",
          "OI": -1005.0,
          "futurePrice": 1008.5
        },
        {
          "date": "12/1/2566",
          "OI": -949.0,
          "futurePrice": 1009.8
        },
        {
          "date": "13/1/2566",
          "OI": -1082.0,
          "futurePrice": 1003.6
        },
        {
          "date": "16/1/2566",
          "OI": -1134.0,
          "futurePrice": 1003.5
        },
        {
          "date": "17/1/2566",
          "OI": -999.0,
          "futurePrice": 1002.5
        },
        {
          "date": "18/1/2566",
          "OI": -922.0,
          "futurePrice": 1004.6
        },
        {
          "date": "19/1/2566",
          "OI": -1003.0,
          "futurePrice": 1005.8
        },
        {
          "date": "20/1/2566",
          "OI": -1280.0,
          "futurePrice": 1000.2
        },
        {
          "date": "23/1/2566",
          "OI": -1373.0,
          "futurePrice": 1004.4
        },
        {
          "date": "24/1/2566",
          "OI": -1337.0,
          "futurePrice": 1001.6
        },
        {
          "date": "25/1/2566",
          "OI": -1388.0,
          "futurePrice": 1002.1
        },
        {
          "date": "26/1/2566",
          "OI": -1821.0,
          "futurePrice": 995.3
        },
        {
          "date": "27/1/2566",
          "OI": -1920.0,
          "futurePrice": 1000.0
        },
        {
          "date": "30/1/2566",
          "OI": -1881.0,
          "futurePrice": 998.9
        },
        {
          "date": "31/1/2566",
          "OI": -1830.0,
          "futurePrice": 990.3
        },
        {
          "date": "1/2/2566",
          "OI": -1791.0,
          "futurePrice": 997.5
        },
        {
          "date": "2/2/2566",
          "OI": -1712.0,
          "futurePrice": 996.1
        },
        {
          "date": "3/2/2566",
          "OI": -2032.0,
          "futurePrice": 1001.1
        },
        {
          "date": "6/2/2566",
          "OI": -2281.0,
          "futurePrice": 995.0
        },
        {
          "date": "7/2/2566",
          "OI": -2429.0,
          "futurePrice": 995.3
        },
        {
          "date": "8/2/2566",
          "OI": -2517.0,
          "futurePrice": 988.3
        },
        {
          "date": "9/2/2566",
          "OI": -2586.0,
          "futurePrice": 987.0
        },
        {
          "date": "10/2/2566",
          "OI": -2176.0,
          "futurePrice": 982.7
        },
        {
          "date": "13/2/2566",
          "OI": -2599.0,
          "futurePrice": 984.5
        },
        {
          "date": "14/2/2566",
          "OI": -3294.0,
          "futurePrice": 984.3
        },
        {
          "date": "15/2/2566",
          "OI": -3289.0,
          "futurePrice": 983.8
        },
        {
          "date": "16/2/2566",
          "OI": -2988.0,
          "futurePrice": 990.9
        },
        {
          "date": "17/2/2566",
          "OI": -3936.0,
          "futurePrice": 988.4
        },
        {
          "date": "20/2/2566",
          "OI": -3915.0,
          "futurePrice": 989.1
        },
        {
          "date": "21/2/2566",
          "OI": -4066.0,
          "futurePrice": 994.7
        },
        {
          "date": "22/2/2566",
          "OI": -5098.0,
          "futurePrice": 986.4
        },
        {
          "date": "23/2/2566",
          "OI": -5254.0,
          "futurePrice": 983.1
        },
        {
          "date": "24/2/2566",
          "OI": -5908.0,
          "futurePrice": 967.7
        },
        {
          "date": "27/2/2566",
          "OI": -5483.0,
          "futurePrice": 967.5
        },
        {
          "date": "28/2/2566",
          "OI": -5226.0,
          "futurePrice": 965.8
        },
        {
          "date": "1/3/2566",
          "OI": -4928.0,
          "futurePrice": 961.4
        },
        {
          "date": "2/3/2566",
          "OI": -5049.0,
          "futurePrice": 962.3
        },
        {
          "date": "3/3/2566",
          "OI": -4881.0,
          "futurePrice": 957.8
        },
        {
          "date": "7/3/2566",
          "OI": -4904.0,
          "futurePrice": 964.9
        },
        {
          "date": "8/3/2566",
          "OI": -4340.0,
          "futurePrice": 959.6
        },
        {
          "date": "9/3/2566",
          "OI": -4066.0,
          "futurePrice": 960.1
        },
        {
          "date": "10/3/2566",
          "OI": -3392.0,
          "futurePrice": 954.1
        },
        {
          "date": "13/3/2566",
          "OI": -2150.0,
          "futurePrice": 932.4
        },
        {
          "date": "14/3/2566",
          "OI": -332.0,
          "futurePrice": 905.4
        },
        {
          "date": "15/3/2566",
          "OI": 1203.0,
          "futurePrice": 932.3
        },
        {
          "date": "16/3/2566",
          "OI": 71.0,
          "futurePrice": 928.7
        },
        {
          "date": "17/3/2566",
          "OI": 255.0,
          "futurePrice": 939.5
        },
        {
          "date": "20/3/2566",
          "OI": 445.0,
          "futurePrice": 933.2
        },
        {
          "date": "21/3/2566",
          "OI": 936.0,
          "futurePrice": 948.4
        },
        {
          "date": "22/3/2566",
          "OI": 829.0,
          "futurePrice": 953.9
        },
        {
          "date": "23/3/2566",
          "OI": 832.0,
          "futurePrice": 959.1
        },
        {
          "date": "24/3/2566",
          "OI": 1086.0,
          "futurePrice": 955.8
        },
        {
          "date": "27/3/2566",
          "OI": 1395.0,
          "futurePrice": 959.7
        },
        {
          "date": "28/3/2566",
          "OI": 2091.0,
          "futurePrice": 966.7
        },
        {
          "date": "29/3/2566",
          "OI": 2180.0,
          "futurePrice": 967.5
        },
        {
          "date": "30/3/2566",
          "OI": 2814.0,
          "futurePrice": 963.9
        },
        {
          "date": "31/3/2566",
          "OI": 1889.0,
          "futurePrice": 968.3
        },
        {
          "date": "3/4/2566",
          "OI": 1294.0,
          "futurePrice": 962.3
        },
        {
          "date": "4/4/2566",
          "OI": 1807.0,
          "futurePrice": 959.2
        },
        {
          "date": "5/4/2566",
          "OI": 2991.0,
          "futurePrice": 944.1
        },
        {
          "date": "7/4/2566",
          "OI": 3123.0,
          "futurePrice": 946.7
        },
        {
          "date": "10/4/2566",
          "OI": 3685.0,
          "futurePrice": 961.7
        },
        {
          "date": "11/4/2566",
          "OI": 3603.0,
          "futurePrice": 962.3
        },
        {
          "date": "12/4/2566",
          "OI": 3671.0,
          "futurePrice": 960.5
        },
        {
          "date": "17/4/2566",
          "OI": 3955.0,
          "futurePrice": 964.6
        },
        {
          "date": "18/4/2566",
          "OI": 3308.0,
          "futurePrice": 960.6
        },
        {
          "date": "19/4/2566",
          "OI": 3991.0,
          "futurePrice": 951.7
        },
        {
          "date": "20/4/2566",
          "OI": 4878.0,
          "futurePrice": 943.8
        },
        {
          "date": "21/4/2566",
          "OI": 6415.0,
          "futurePrice": 937.4
        },
        {
          "date": "24/4/2566",
          "OI": 6236.0,
          "futurePrice": 937.8
        },
        {
          "date": "25/4/2566",
          "OI": 9099.0,
          "futurePrice": 930.2
        },
        {
          "date": "26/4/2566",
          "OI": 9820.0,
          "futurePrice": 932.1
        },
        {
          "date": "27/4/2566",
          "OI": 10859.0,
          "futurePrice": 924.1
        },
        {
          "date": "28/4/2566",
          "OI": 11678.0,
          "futurePrice": 920.4
        },
        {
          "date": "2/5/2566",
          "OI": 11546.0,
          "futurePrice": 926.9
        },
        {
          "date": "3/5/2566",
          "OI": 13065.0,
          "futurePrice": 928.4
        },
        {
          "date": "8/5/2566",
          "OI": 12899.0,
          "futurePrice": 945.9
        },
        {
          "date": "9/5/2566",
          "OI": 12984.0,
          "futurePrice": 947.4
        },
        {
          "date": "10/5/2566",
          "OI": 12662.0,
          "futurePrice": 947.9
        },
        {
          "date": "11/5/2566",
          "OI": 13035.0,
          "futurePrice": 948.6
        },
        {
          "date": "12/5/2566",
          "OI": 14277.0,
          "futurePrice": 948.6
        },
        {
          "date": "15/5/2566",
          "OI": 15160.0,
          "futurePrice": 933.5
        },
        {
          "date": "16/5/2566",
          "OI": 14808.0,
          "futurePrice": 932.8
        },
        {
          "date": "17/5/2566",
          "OI": 15104.0,
          "futurePrice": 920.9
        },
        {
          "date": "18/5/2566",
          "OI": 14353.0,
          "futurePrice": 926.2
        },
        {
          "date": "19/5/2566",
          "OI": 14369.0,
          "futurePrice": 918.5
        },
        {
          "date": "22/5/2566",
          "OI": 14056.0,
          "futurePrice": 929.8
        },
        {
          "date": "23/5/2566",
          "OI": 14566.0,
          "futurePrice": 926.2
        },
        {
          "date": "24/5/2566",
          "OI": 14635.0,
          "futurePrice": 926.9
        },
        {
          "date": "25/5/2566",
          "OI": 16526.0,
          "futurePrice": 927.1
        },
        {
          "date": "26/5/2566",
          "OI": 17029.0,
          "futurePrice": 921.9
        },
        {
          "date": "29/5/2566",
          "OI": 16869.0,
          "futurePrice": 931.0
        },
        {
          "date": "30/5/2566",
          "OI": 19256.0,
          "futurePrice": 924.7
        },
        {
          "date": "31/5/2566",
          "OI": 21690.0,
          "futurePrice": 921.7
        },
        {
          "date": "1/6/2566",
          "OI": 22610.0,
          "futurePrice": 914.2
        },
        {
          "date": "2/6/2566",
          "OI": 23056.0,
          "futurePrice": 923.7
        },
        {
          "date": "6/6/2566",
          "OI": 24345.0,
          "futurePrice": 921.4
        },
        {
          "date": "7/6/2566",
          "OI": 25549.0,
          "futurePrice": 926.6
        },
        {
          "date": "8/6/2566",
          "OI": 26405.0,
          "futurePrice": 941.3
        },
        {
          "date": "9/6/2566",
          "OI": 26048.0,
          "futurePrice": 940.1
        },
        {
          "date": "12/6/2566",
          "OI": 25475.0,
          "futurePrice": 939.0
        },
        {
          "date": "13/6/2566",
          "OI": 24960.0,
          "futurePrice": 945.2
        },
        {
          "date": "14/6/2566",
          "OI": 24172.0,
          "futurePrice": 946.0
        },
        {
          "date": "15/6/2566",
          "OI": 24650.0,
          "futurePrice": 947.0
        },
        {
          "date": "16/6/2566",
          "OI": 24303.0,
          "futurePrice": 947.3
        },
        {
          "date": "19/6/2566",
          "OI": 23437.0,
          "futurePrice": 948.4
        },
        {
          "date": "20/6/2566",
          "OI": 25555.0,
          "futurePrice": 935.0
        },
        {
          "date": "21/6/2566",
          "OI": 26481.0,
          "futurePrice": 924.0
        },
        {
          "date": "22/6/2566",
          "OI": 28823.0,
          "futurePrice": 918.5
        },
        {
          "date": "23/6/2566",
          "OI": 29635.0,
          "futurePrice": 915.4
        },
        {
          "date": "26/6/2566",
          "OI": 29907.0,
          "futurePrice": 908.1
        },
        {
          "date": "27/6/2566",
          "OI": 30905.0,
          "futurePrice": 902.9
        },
        {
          "date": "28/6/2566",
          "OI": 31985.0,
          "futurePrice": 897.5
        },
        {
          "date": "29/6/2566",
          "OI": 31870.0,
          "futurePrice": 899.3
        },
        {
          "date": "30/6/2566",
          "OI": 0.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/7/2566",
          "OI": 0.0,
          "futurePrice": 916.4
        }
      ]
    },
    S50U23: {
      volumeData: [
        {
          "date": "30/3/2566",
          "callPut": -143.0,
          "futurePrice": 963.9
        },
        {
          "date": "31/3/2566",
          "callPut": 39.0,
          "futurePrice": 968.3
        },
        {
          "date": "3/4/2566",
          "callPut": 14.0,
          "futurePrice": 962.3
        },
        {
          "date": "4/4/2566",
          "callPut": 25.0,
          "futurePrice": 959.2
        },
        {
          "date": "5/4/2566",
          "callPut": -151.0,
          "futurePrice": 944.1
        },
        {
          "date": "7/4/2566",
          "callPut": -21.0,
          "futurePrice": 946.7
        },
        {
          "date": "10/4/2566",
          "callPut": 27.0,
          "futurePrice": 961.7
        },
        {
          "date": "11/4/2566",
          "callPut": 7.0,
          "futurePrice": 962.3
        },
        {
          "date": "12/4/2566",
          "callPut": 25.0,
          "futurePrice": 960.5
        },
        {
          "date": "17/4/2566",
          "callPut": -71.0,
          "futurePrice": 964.6
        },
        {
          "date": "18/4/2566",
          "callPut": -165.0,
          "futurePrice": 960.6
        },
        {
          "date": "19/4/2566",
          "callPut": 33.0,
          "futurePrice": 951.7
        },
        {
          "date": "20/4/2566",
          "callPut": -208.0,
          "futurePrice": 943.8
        },
        {
          "date": "21/4/2566",
          "callPut": -70.0,
          "futurePrice": 937.4
        },
        {
          "date": "24/4/2566",
          "callPut": 639.0,
          "futurePrice": 937.8
        },
        {
          "date": "25/4/2566",
          "callPut": 197.0,
          "futurePrice": 930.2
        },
        {
          "date": "26/4/2566",
          "callPut": -208.0,
          "futurePrice": 932.1
        },
        {
          "date": "27/4/2566",
          "callPut": 14.0,
          "futurePrice": 924.1
        },
        {
          "date": "28/4/2566",
          "callPut": -103.0,
          "futurePrice": 920.4
        },
        {
          "date": "2/5/2566",
          "callPut": 37.0,
          "futurePrice": 926.9
        },
        {
          "date": "3/5/2566",
          "callPut": 31.0,
          "futurePrice": 928.4
        },
        {
          "date": "8/5/2566",
          "callPut": -46.0,
          "futurePrice": 945.9
        },
        {
          "date": "9/5/2566",
          "callPut": -55.0,
          "futurePrice": 947.4
        },
        {
          "date": "10/5/2566",
          "callPut": -37.0,
          "futurePrice": 947.9
        },
        {
          "date": "11/5/2566",
          "callPut": 147.0,
          "futurePrice": 948.6
        },
        {
          "date": "12/5/2566",
          "callPut": 194.0,
          "futurePrice": 948.6
        },
        {
          "date": "15/5/2566",
          "callPut": 115.0,
          "futurePrice": 933.5
        },
        {
          "date": "16/5/2566",
          "callPut": 114.0,
          "futurePrice": 932.8
        },
        {
          "date": "17/5/2566",
          "callPut": 58.0,
          "futurePrice": 920.9
        },
        {
          "date": "18/5/2566",
          "callPut": 412.0,
          "futurePrice": 926.2
        },
        {
          "date": "19/5/2566",
          "callPut": 130.0,
          "futurePrice": 918.5
        },
        {
          "date": "22/5/2566",
          "callPut": 358.0,
          "futurePrice": 929.8
        },
        {
          "date": "23/5/2566",
          "callPut": 99.0,
          "futurePrice": 926.2
        },
        {
          "date": "24/5/2566",
          "callPut": 39.0,
          "futurePrice": 926.9
        },
        {
          "date": "25/5/2566",
          "callPut": 66.0,
          "futurePrice": 927.1
        },
        {
          "date": "26/5/2566",
          "callPut": -172.0,
          "futurePrice": 921.9
        },
        {
          "date": "29/5/2566",
          "callPut": 669.0,
          "futurePrice": 931.0
        },
        {
          "date": "30/5/2566",
          "callPut": 418.0,
          "futurePrice": 924.7
        },
        {
          "date": "31/5/2566",
          "callPut": 62.0,
          "futurePrice": 921.7
        },
        {
          "date": "1/6/2566",
          "callPut": 263.0,
          "futurePrice": 914.2
        },
        {
          "date": "2/6/2566",
          "callPut": 346.0,
          "futurePrice": 923.7
        },
        {
          "date": "6/6/2566",
          "callPut": 502.0,
          "futurePrice": 921.4
        },
        {
          "date": "7/6/2566",
          "callPut": 259.0,
          "futurePrice": 926.6
        },
        {
          "date": "8/6/2566",
          "callPut": 497.0,
          "futurePrice": 941.3
        },
        {
          "date": "9/6/2566",
          "callPut": 332.0,
          "futurePrice": 940.1
        },
        {
          "date": "12/6/2566",
          "callPut": -215.0,
          "futurePrice": 939.0
        },
        {
          "date": "13/6/2566",
          "callPut": 133.0,
          "futurePrice": 945.2
        },
        {
          "date": "14/6/2566",
          "callPut": 124.0,
          "futurePrice": 946.0
        },
        {
          "date": "15/6/2566",
          "callPut": 662.0,
          "futurePrice": 947.0
        },
        {
          "date": "16/6/2566",
          "callPut": 230.0,
          "futurePrice": 947.3
        },
        {
          "date": "19/6/2566",
          "callPut": 512.0,
          "futurePrice": 948.4
        },
        {
          "date": "20/6/2566",
          "callPut": 308.0,
          "futurePrice": 935.0
        },
        {
          "date": "21/6/2566",
          "callPut": 722.0,
          "futurePrice": 924.0
        },
        {
          "date": "22/6/2566",
          "callPut": 146.0,
          "futurePrice": 918.5
        },
        {
          "date": "23/6/2566",
          "callPut": 991.0,
          "futurePrice": 915.4
        },
        {
          "date": "26/6/2566",
          "callPut": 987.0,
          "futurePrice": 908.1
        },
        {
          "date": "27/6/2566",
          "callPut": 152.0,
          "futurePrice": 902.9
        },
        {
          "date": "28/6/2566",
          "callPut": 1708.0,
          "futurePrice": 897.5
        },
        {
          "date": "29/6/2566",
          "callPut": 2021.0,
          "futurePrice": 899.3
        },
        {
          "date": "30/6/2566",
          "callPut": 998.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/7/2566",
          "callPut": 1921.0,
          "futurePrice": 916.4
        },
        {
          "date": "4/7/2566",
          "callPut": 1219.0,
          "futurePrice": 921.7
        },
        {
          "date": "5/7/2566",
          "callPut": 313.0,
          "futurePrice": 920.5
        },
        {
          "date": "6/7/2566",
          "callPut": 183.0,
          "futurePrice": 907.8
        },
        {
          "date": "7/7/2566",
          "callPut": -142.0,
          "futurePrice": 907.7
        },
        {
          "date": "10/7/2566",
          "callPut": 3861.0,
          "futurePrice": 915.0
        },
        {
          "date": "11/7/2566",
          "callPut": 2698.0,
          "futurePrice": 916.1
        },
        {
          "date": "12/7/2566",
          "callPut": 4110.0,
          "futurePrice": 913.4
        },
        {
          "date": "13/7/2566",
          "callPut": 1930.0,
          "futurePrice": 916.8
        },
        {
          "date": "14/7/2566",
          "callPut": 475.0,
          "futurePrice": 932.6
        },
        {
          "date": "17/7/2566",
          "callPut": 4290.0,
          "futurePrice": 937.8
        },
        {
          "date": "18/7/2566",
          "callPut": 573.0,
          "futurePrice": 943.4
        },
        {
          "date": "19/7/2566",
          "callPut": 1225.0,
          "futurePrice": 942.4
        },
        {
          "date": "20/7/2566",
          "callPut": 672.0,
          "futurePrice": 934.2
        },
        {
          "date": "21/7/2566",
          "callPut": -161.0,
          "futurePrice": 941.5
        },
        {
          "date": "24/7/2566",
          "callPut": -265.0,
          "futurePrice": 938.0
        },
        {
          "date": "25/7/2566",
          "callPut": -7.0,
          "futurePrice": 943.1
        },
        {
          "date": "26/7/2566",
          "callPut": -695.0,
          "futurePrice": 942.0
        },
        {
          "date": "27/7/2566",
          "callPut": -398.0,
          "futurePrice": 954.6
        },
        {
          "date": "31/7/2566",
          "callPut": -539.0,
          "futurePrice": 963.7
        },
        {
          "date": "2/8/2566",
          "callPut": 1396.0,
          "futurePrice": 958.7
        },
        {
          "date": "3/8/2566",
          "callPut": 61.0,
          "futurePrice": 942.9
        },
        {
          "date": "4/8/2566",
          "callPut": 406.0,
          "futurePrice": 944.8
        },
        {
          "date": "7/8/2566",
          "callPut": -360.0,
          "futurePrice": 951.6
        },
        {
          "date": "8/8/2566",
          "callPut": -290.0,
          "futurePrice": 938.1
        },
        {
          "date": "9/8/2566",
          "callPut": -152.0,
          "futurePrice": 943.5
        },
        {
          "date": "10/8/2566",
          "callPut": 128.0,
          "futurePrice": 947.4
        },
        {
          "date": "11/8/2566",
          "callPut": -838.0,
          "futurePrice": 947.1
        },
        {
          "date": "15/8/2566",
          "callPut": -1074.0,
          "futurePrice": 937.2
        },
        {
          "date": "16/8/2566",
          "callPut": -484.0,
          "futurePrice": 932.2
        },
        {
          "date": "17/8/2566",
          "callPut": -321.0,
          "futurePrice": 938.0
        },
        {
          "date": "18/8/2566",
          "callPut": -1228.0,
          "futurePrice": 931.6
        },
        {
          "date": "21/8/2566",
          "callPut": 230.0,
          "futurePrice": 936.9
        },
        {
          "date": "22/8/2566",
          "callPut": 276.0,
          "futurePrice": 949.5
        },
        {
          "date": "23/8/2566",
          "callPut": -597.0,
          "futurePrice": 949.1
        },
        {
          "date": "24/8/2566",
          "callPut": -1043.0,
          "futurePrice": 954.5
        },
        {
          "date": "25/8/2566",
          "callPut": -929.0,
          "futurePrice": 954.7
        },
        {
          "date": "28/8/2566",
          "callPut": -1177.0,
          "futurePrice": 956.1
        },
        {
          "date": "29/8/2566",
          "callPut": -1485.0,
          "futurePrice": 958.7
        },
        {
          "date": "30/8/2566",
          "callPut": -1012.0,
          "futurePrice": 961.1
        },
        {
          "date": "31/8/2566",
          "callPut": -1270.0,
          "futurePrice": 952.6
        },
        {
          "date": "1/9/2566",
          "callPut": -152.0,
          "futurePrice": 952.8
        },
        {
          "date": "4/9/2566",
          "callPut": -3005.0,
          "futurePrice": 946.5
        },
        {
          "date": "5/9/2566",
          "callPut": 571.0,
          "futurePrice": 943.9
        },
        {
          "date": "6/9/2566",
          "callPut": 918.0,
          "futurePrice": 944.0
        },
        {
          "date": "7/9/2566",
          "callPut": 35.0,
          "futurePrice": 944.8
        },
        {
          "date": "8/9/2566",
          "callPut": 1671.0,
          "futurePrice": 940.2
        },
        {
          "date": "11/9/2566",
          "callPut": 6.0,
          "futurePrice": 937.5
        },
        {
          "date": "12/9/2566",
          "callPut": 1003.0,
          "futurePrice": 941.0
        },
        {
          "date": "13/9/2566",
          "callPut": -378.0,
          "futurePrice": 937.9
        },
        {
          "date": "14/9/2566",
          "callPut": -178.0,
          "futurePrice": 944.7
        },
        {
          "date": "15/9/2566",
          "callPut": 877.0,
          "futurePrice": 946.1
        },
        {
          "date": "18/9/2566",
          "callPut": -619.0,
          "futurePrice": 936.0
        },
        {
          "date": "19/9/2566",
          "callPut": -118.0,
          "futurePrice": 933.1
        },
        {
          "date": "20/9/2566",
          "callPut": -2513.0,
          "futurePrice": 922.7
        },
        {
          "date": "21/9/2566",
          "callPut": -1065.0,
          "futurePrice": 923.8
        },
        {
          "date": "22/9/2566",
          "callPut": -2373.0,
          "futurePrice": 931.8
        },
        {
          "date": "25/9/2566",
          "callPut": -807.0,
          "futurePrice": 923.9
        },
        {
          "date": "26/9/2566",
          "callPut": -2779.0,
          "futurePrice": 917.6
        },
        {
          "date": "27/9/2566",
          "callPut": -2758.0,
          "futurePrice": 917.5
        },
        {
          "date": "28/9/2566",
          "callPut": -342.0,
          "futurePrice": 911.8
        },
        {
          "date": "29/9/2566",
          "callPut": 0.0,
          "futurePrice": 901.6
        },
        {
          "date": "2/10/2566",
          "callPut": 0.0,
          "futurePrice": 901.5
        }
      ],
      openInterestData: [
        {
          "date": "30/3/2566",
          "OI": -139.0,
          "futurePrice": 963.9
        },
        {
          "date": "31/3/2566",
          "OI": -98.0,
          "futurePrice": 968.3
        },
        {
          "date": "3/4/2566",
          "OI": -96.0,
          "futurePrice": 962.3
        },
        {
          "date": "4/4/2566",
          "OI": -72.0,
          "futurePrice": 959.2
        },
        {
          "date": "5/4/2566",
          "OI": -233.0,
          "futurePrice": 944.1
        },
        {
          "date": "7/4/2566",
          "OI": -248.0,
          "futurePrice": 946.7
        },
        {
          "date": "10/4/2566",
          "OI": -209.0,
          "futurePrice": 961.7
        },
        {
          "date": "11/4/2566",
          "OI": -277.0,
          "futurePrice": 962.3
        },
        {
          "date": "12/4/2566",
          "OI": -281.0,
          "futurePrice": 960.5
        },
        {
          "date": "17/4/2566",
          "OI": -329.0,
          "futurePrice": 964.6
        },
        {
          "date": "18/4/2566",
          "OI": -425.0,
          "futurePrice": 960.6
        },
        {
          "date": "19/4/2566",
          "OI": -386.0,
          "futurePrice": 951.7
        },
        {
          "date": "20/4/2566",
          "OI": -508.0,
          "futurePrice": 943.8
        },
        {
          "date": "21/4/2566",
          "OI": -493.0,
          "futurePrice": 937.4
        },
        {
          "date": "24/4/2566",
          "OI": 29.0,
          "futurePrice": 937.8
        },
        {
          "date": "25/4/2566",
          "OI": -54.0,
          "futurePrice": 930.2
        },
        {
          "date": "26/4/2566",
          "OI": -223.0,
          "futurePrice": 932.1
        },
        {
          "date": "27/4/2566",
          "OI": -254.0,
          "futurePrice": 924.1
        },
        {
          "date": "28/4/2566",
          "OI": -384.0,
          "futurePrice": 920.4
        },
        {
          "date": "2/5/2566",
          "OI": -386.0,
          "futurePrice": 926.9
        },
        {
          "date": "3/5/2566",
          "OI": -677.0,
          "futurePrice": 928.4
        },
        {
          "date": "8/5/2566",
          "OI": -718.0,
          "futurePrice": 945.9
        },
        {
          "date": "9/5/2566",
          "OI": -816.0,
          "futurePrice": 947.4
        },
        {
          "date": "10/5/2566",
          "OI": -878.0,
          "futurePrice": 947.9
        },
        {
          "date": "11/5/2566",
          "OI": -808.0,
          "futurePrice": 948.6
        },
        {
          "date": "12/5/2566",
          "OI": -683.0,
          "futurePrice": 948.6
        },
        {
          "date": "15/5/2566",
          "OI": -592.0,
          "futurePrice": 933.5
        },
        {
          "date": "16/5/2566",
          "OI": -508.0,
          "futurePrice": 932.8
        },
        {
          "date": "17/5/2566",
          "OI": -598.0,
          "futurePrice": 920.9
        },
        {
          "date": "18/5/2566",
          "OI": -318.0,
          "futurePrice": 926.2
        },
        {
          "date": "19/5/2566",
          "OI": -329.0,
          "futurePrice": 918.5
        },
        {
          "date": "22/5/2566",
          "OI": -248.0,
          "futurePrice": 929.8
        },
        {
          "date": "23/5/2566",
          "OI": -364.0,
          "futurePrice": 926.2
        },
        {
          "date": "24/5/2566",
          "OI": -340.0,
          "futurePrice": 926.9
        },
        {
          "date": "25/5/2566",
          "OI": -329.0,
          "futurePrice": 927.1
        },
        {
          "date": "26/5/2566",
          "OI": -329.0,
          "futurePrice": 921.9
        },
        {
          "date": "29/5/2566",
          "OI": 358.0,
          "futurePrice": 931.0
        },
        {
          "date": "30/5/2566",
          "OI": 667.0,
          "futurePrice": 924.7
        },
        {
          "date": "31/5/2566",
          "OI": 626.0,
          "futurePrice": 921.7
        },
        {
          "date": "1/6/2566",
          "OI": 842.0,
          "futurePrice": 914.2
        },
        {
          "date": "2/6/2566",
          "OI": 1041.0,
          "futurePrice": 923.7
        },
        {
          "date": "6/6/2566",
          "OI": 1236.0,
          "futurePrice": 921.4
        },
        {
          "date": "7/6/2566",
          "OI": 1284.0,
          "futurePrice": 926.6
        },
        {
          "date": "8/6/2566",
          "OI": 1347.0,
          "futurePrice": 941.3
        },
        {
          "date": "9/6/2566",
          "OI": 1420.0,
          "futurePrice": 940.1
        },
        {
          "date": "12/6/2566",
          "OI": 1242.0,
          "futurePrice": 939.0
        },
        {
          "date": "13/6/2566",
          "OI": 1481.0,
          "futurePrice": 945.2
        },
        {
          "date": "14/6/2566",
          "OI": 1681.0,
          "futurePrice": 946.0
        },
        {
          "date": "15/6/2566",
          "OI": 2195.0,
          "futurePrice": 947.0
        },
        {
          "date": "16/6/2566",
          "OI": 2405.0,
          "futurePrice": 947.3
        },
        {
          "date": "19/6/2566",
          "OI": 2834.0,
          "futurePrice": 948.4
        },
        {
          "date": "20/6/2566",
          "OI": 3159.0,
          "futurePrice": 935.0
        },
        {
          "date": "21/6/2566",
          "OI": 3344.0,
          "futurePrice": 924.0
        },
        {
          "date": "22/6/2566",
          "OI": 2821.0,
          "futurePrice": 918.5
        },
        {
          "date": "23/6/2566",
          "OI": 2988.0,
          "futurePrice": 915.4
        },
        {
          "date": "26/6/2566",
          "OI": 3032.0,
          "futurePrice": 908.1
        },
        {
          "date": "27/6/2566",
          "OI": 3464.0,
          "futurePrice": 902.9
        },
        {
          "date": "28/6/2566",
          "OI": 4035.0,
          "futurePrice": 897.5
        },
        {
          "date": "29/6/2566",
          "OI": 4862.0,
          "futurePrice": 899.3
        },
        {
          "date": "30/6/2566",
          "OI": 4620.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/7/2566",
          "OI": 5484.0,
          "futurePrice": 916.4
        },
        {
          "date": "4/7/2566",
          "OI": 5920.0,
          "futurePrice": 921.7
        },
        {
          "date": "5/7/2566",
          "OI": 6309.0,
          "futurePrice": 920.5
        },
        {
          "date": "6/7/2566",
          "OI": 6966.0,
          "futurePrice": 907.8
        },
        {
          "date": "7/7/2566",
          "OI": 9009.0,
          "futurePrice": 907.7
        },
        {
          "date": "10/7/2566",
          "OI": 14021.0,
          "futurePrice": 915.0
        },
        {
          "date": "11/7/2566",
          "OI": 12769.0,
          "futurePrice": 916.1
        },
        {
          "date": "12/7/2566",
          "OI": 12330.0,
          "futurePrice": 913.4
        },
        {
          "date": "13/7/2566",
          "OI": 12507.0,
          "futurePrice": 916.8
        },
        {
          "date": "14/7/2566",
          "OI": 11888.0,
          "futurePrice": 932.6
        },
        {
          "date": "17/7/2566",
          "OI": 12670.0,
          "futurePrice": 937.8
        },
        {
          "date": "18/7/2566",
          "OI": 12586.0,
          "futurePrice": 943.4
        },
        {
          "date": "19/7/2566",
          "OI": 11837.0,
          "futurePrice": 942.4
        },
        {
          "date": "20/7/2566",
          "OI": 12097.0,
          "futurePrice": 934.2
        },
        {
          "date": "21/7/2566",
          "OI": 12456.0,
          "futurePrice": 941.5
        },
        {
          "date": "24/7/2566",
          "OI": 12138.0,
          "futurePrice": 938.0
        },
        {
          "date": "25/7/2566",
          "OI": 11794.0,
          "futurePrice": 943.1
        },
        {
          "date": "26/7/2566",
          "OI": 11315.0,
          "futurePrice": 942.0
        },
        {
          "date": "27/7/2566",
          "OI": 11034.0,
          "futurePrice": 954.6
        },
        {
          "date": "31/7/2566",
          "OI": 8903.0,
          "futurePrice": 963.7
        },
        {
          "date": "2/8/2566",
          "OI": 8286.0,
          "futurePrice": 958.7
        },
        {
          "date": "3/8/2566",
          "OI": 8949.0,
          "futurePrice": 942.9
        },
        {
          "date": "4/8/2566",
          "OI": 8482.0,
          "futurePrice": 944.8
        },
        {
          "date": "7/8/2566",
          "OI": 8206.0,
          "futurePrice": 951.6
        },
        {
          "date": "8/8/2566",
          "OI": 8707.0,
          "futurePrice": 938.1
        },
        {
          "date": "9/8/2566",
          "OI": 8253.0,
          "futurePrice": 943.5
        },
        {
          "date": "10/8/2566",
          "OI": 8210.0,
          "futurePrice": 947.4
        },
        {
          "date": "11/8/2566",
          "OI": 7517.0,
          "futurePrice": 947.1
        },
        {
          "date": "15/8/2566",
          "OI": 6237.0,
          "futurePrice": 937.2
        },
        {
          "date": "16/8/2566",
          "OI": 6094.0,
          "futurePrice": 932.2
        },
        {
          "date": "17/8/2566",
          "OI": 5618.0,
          "futurePrice": 938.0
        },
        {
          "date": "18/8/2566",
          "OI": 4682.0,
          "futurePrice": 931.6
        },
        {
          "date": "21/8/2566",
          "OI": 5174.0,
          "futurePrice": 936.9
        },
        {
          "date": "22/8/2566",
          "OI": 4654.0,
          "futurePrice": 949.5
        },
        {
          "date": "23/8/2566",
          "OI": 3579.0,
          "futurePrice": 949.1
        },
        {
          "date": "24/8/2566",
          "OI": 2169.0,
          "futurePrice": 954.5
        },
        {
          "date": "25/8/2566",
          "OI": 1935.0,
          "futurePrice": 954.7
        },
        {
          "date": "28/8/2566",
          "OI": 1634.0,
          "futurePrice": 956.1
        },
        {
          "date": "29/8/2566",
          "OI": 1718.0,
          "futurePrice": 958.7
        },
        {
          "date": "30/8/2566",
          "OI": 2150.0,
          "futurePrice": 961.1
        },
        {
          "date": "31/8/2566",
          "OI": 1120.0,
          "futurePrice": 952.6
        },
        {
          "date": "1/9/2566",
          "OI": 802.0,
          "futurePrice": 952.8
        },
        {
          "date": "4/9/2566",
          "OI": 411.0,
          "futurePrice": 946.5
        },
        {
          "date": "5/9/2566",
          "OI": 847.0,
          "futurePrice": 943.9
        },
        {
          "date": "6/9/2566",
          "OI": 1391.0,
          "futurePrice": 944.0
        },
        {
          "date": "7/9/2566",
          "OI": 1851.0,
          "futurePrice": 944.8
        },
        {
          "date": "8/9/2566",
          "OI": 3366.0,
          "futurePrice": 940.2
        },
        {
          "date": "11/9/2566",
          "OI": 3682.0,
          "futurePrice": 937.5
        },
        {
          "date": "12/9/2566",
          "OI": 2827.0,
          "futurePrice": 941.0
        },
        {
          "date": "13/9/2566",
          "OI": 2598.0,
          "futurePrice": 937.9
        },
        {
          "date": "14/9/2566",
          "OI": 2553.0,
          "futurePrice": 944.7
        },
        {
          "date": "15/9/2566",
          "OI": 2334.0,
          "futurePrice": 946.1
        },
        {
          "date": "18/9/2566",
          "OI": 2963.0,
          "futurePrice": 936.0
        },
        {
          "date": "19/9/2566",
          "OI": 3796.0,
          "futurePrice": 933.1
        },
        {
          "date": "20/9/2566",
          "OI": 3556.0,
          "futurePrice": 922.7
        },
        {
          "date": "21/9/2566",
          "OI": 3073.0,
          "futurePrice": 923.8
        },
        {
          "date": "22/9/2566",
          "OI": 1992.0,
          "futurePrice": 931.8
        },
        {
          "date": "25/9/2566",
          "OI": 609.0,
          "futurePrice": 923.9
        },
        {
          "date": "26/9/2566",
          "OI": 1068.0,
          "futurePrice": 917.6
        },
        {
          "date": "27/9/2566",
          "OI": 2698.0,
          "futurePrice": 917.5
        },
        {
          "date": "28/9/2566",
          "OI": 3335.0,
          "futurePrice": 911.8
        },
        {
          "date": "29/9/2566",
          "OI": 0.0,
          "futurePrice": 901.6
        },
        {
          "date": "2/10/2566",
          "OI": 0.0,
          "futurePrice": 901.5
        }
      ]
    },
    S50Z23: {
      volumeData: [
        {
          "date": "29/6/2566",
          "callPut": -321.0,
          "futurePrice": 899.3
        },
        {
          "date": "30/6/2566",
          "callPut": -269.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/7/2566",
          "callPut": -143.0,
          "futurePrice": 916.4
        },
        {
          "date": "4/7/2566",
          "callPut": -174.0,
          "futurePrice": 921.7
        },
        {
          "date": "5/7/2566",
          "callPut": -63.0,
          "futurePrice": 920.5
        },
        {
          "date": "6/7/2566",
          "callPut": 45.0,
          "futurePrice": 907.8
        },
        {
          "date": "7/7/2566",
          "callPut": -44.0,
          "futurePrice": 907.7
        },
        {
          "date": "10/7/2566",
          "callPut": -60.0,
          "futurePrice": 915.0
        },
        {
          "date": "11/7/2566",
          "callPut": -41.0,
          "futurePrice": 916.1
        },
        {
          "date": "12/7/2566",
          "callPut": 36.0,
          "futurePrice": 913.4
        },
        {
          "date": "13/7/2566",
          "callPut": 6.0,
          "futurePrice": 916.8
        },
        {
          "date": "14/7/2566",
          "callPut": 145.0,
          "futurePrice": 932.6
        },
        {
          "date": "17/7/2566",
          "callPut": 85.0,
          "futurePrice": 937.8
        },
        {
          "date": "18/7/2566",
          "callPut": 143.0,
          "futurePrice": 943.4
        },
        {
          "date": "19/7/2566",
          "callPut": 96.0,
          "futurePrice": 942.4
        },
        {
          "date": "20/7/2566",
          "callPut": -76.0,
          "futurePrice": 934.2
        },
        {
          "date": "21/7/2566",
          "callPut": 26.0,
          "futurePrice": 941.5
        },
        {
          "date": "24/7/2566",
          "callPut": -42.0,
          "futurePrice": 938.0
        },
        {
          "date": "25/7/2566",
          "callPut": 14.0,
          "futurePrice": 943.1
        },
        {
          "date": "26/7/2566",
          "callPut": 73.0,
          "futurePrice": 942.0
        },
        {
          "date": "27/7/2566",
          "callPut": 273.0,
          "futurePrice": 954.6
        },
        {
          "date": "31/7/2566",
          "callPut": 107.0,
          "futurePrice": 963.7
        },
        {
          "date": "2/8/2566",
          "callPut": 242.0,
          "futurePrice": 958.7
        },
        {
          "date": "3/8/2566",
          "callPut": -220.0,
          "futurePrice": 942.9
        },
        {
          "date": "4/8/2566",
          "callPut": -37.0,
          "futurePrice": 944.8
        },
        {
          "date": "7/8/2566",
          "callPut": -170.0,
          "futurePrice": 951.6
        },
        {
          "date": "8/8/2566",
          "callPut": 58.0,
          "futurePrice": 938.1
        },
        {
          "date": "9/8/2566",
          "callPut": 222.0,
          "futurePrice": 943.5
        },
        {
          "date": "10/8/2566",
          "callPut": 116.0,
          "futurePrice": 947.4
        },
        {
          "date": "11/8/2566",
          "callPut": -279.0,
          "futurePrice": 947.1
        },
        {
          "date": "15/8/2566",
          "callPut": 488.0,
          "futurePrice": 937.2
        },
        {
          "date": "16/8/2566",
          "callPut": -159.0,
          "futurePrice": 932.2
        },
        {
          "date": "17/8/2566",
          "callPut": 46.0,
          "futurePrice": 938.0
        },
        {
          "date": "18/8/2566",
          "callPut": -75.0,
          "futurePrice": 931.6
        },
        {
          "date": "21/8/2566",
          "callPut": 352.0,
          "futurePrice": 936.9
        },
        {
          "date": "22/8/2566",
          "callPut": 213.0,
          "futurePrice": 949.5
        },
        {
          "date": "23/8/2566",
          "callPut": -127.0,
          "futurePrice": 949.1
        },
        {
          "date": "24/8/2566",
          "callPut": -135.0,
          "futurePrice": 954.5
        },
        {
          "date": "25/8/2566",
          "callPut": 199.0,
          "futurePrice": 954.7
        },
        {
          "date": "28/8/2566",
          "callPut": -385.0,
          "futurePrice": 956.1
        },
        {
          "date": "29/8/2566",
          "callPut": -100.0,
          "futurePrice": 958.7
        },
        {
          "date": "30/8/2566",
          "callPut": 97.0,
          "futurePrice": 961.1
        },
        {
          "date": "31/8/2566",
          "callPut": 238.0,
          "futurePrice": 952.6
        },
        {
          "date": "1/9/2566",
          "callPut": 337.0,
          "futurePrice": 952.8
        },
        {
          "date": "4/9/2566",
          "callPut": -28.0,
          "futurePrice": 946.5
        },
        {
          "date": "5/9/2566",
          "callPut": 92.0,
          "futurePrice": 943.9
        },
        {
          "date": "6/9/2566",
          "callPut": 97.0,
          "futurePrice": 944.0
        },
        {
          "date": "7/9/2566",
          "callPut": -292.0,
          "futurePrice": 944.8
        },
        {
          "date": "8/9/2566",
          "callPut": -87.0,
          "futurePrice": 940.2
        },
        {
          "date": "11/9/2566",
          "callPut": 89.0,
          "futurePrice": 937.5
        },
        {
          "date": "12/9/2566",
          "callPut": 419.0,
          "futurePrice": 941.0
        },
        {
          "date": "13/9/2566",
          "callPut": -84.0,
          "futurePrice": 937.9
        },
        {
          "date": "14/9/2566",
          "callPut": -379.0,
          "futurePrice": 944.7
        },
        {
          "date": "15/9/2566",
          "callPut": 3.0,
          "futurePrice": 946.1
        },
        {
          "date": "18/9/2566",
          "callPut": 579.0,
          "futurePrice": 936.0
        },
        {
          "date": "19/9/2566",
          "callPut": -85.0,
          "futurePrice": 933.1
        },
        {
          "date": "20/9/2566",
          "callPut": -952.0,
          "futurePrice": 922.7
        },
        {
          "date": "21/9/2566",
          "callPut": 73.0,
          "futurePrice": 923.8
        },
        {
          "date": "22/9/2566",
          "callPut": 75.0,
          "futurePrice": 931.8
        },
        {
          "date": "25/9/2566",
          "callPut": 538.0,
          "futurePrice": 923.9
        },
        {
          "date": "26/9/2566",
          "callPut": -851.0,
          "futurePrice": 917.6
        },
        {
          "date": "27/9/2566",
          "callPut": 398.0,
          "futurePrice": 917.5
        },
        {
          "date": "28/9/2566",
          "callPut": -132.0,
          "futurePrice": 911.8
        },
        {
          "date": "29/9/2566",
          "callPut": 2102.0,
          "futurePrice": 901.6
        },
        {
          "date": "2/10/2566",
          "callPut": 2087.0,
          "futurePrice": 901.5
        },
        {
          "date": "3/10/2566",
          "callPut": -193.0,
          "futurePrice": 882.7
        },
        {
          "date": "4/10/2566",
          "callPut": -944.0,
          "futurePrice": 886.8
        },
        {
          "date": "5/10/2566",
          "callPut": -195.0,
          "futurePrice": 887.5
        },
        {
          "date": "6/10/2566",
          "callPut": 2422.0,
          "futurePrice": 876.5
        },
        {
          "date": "9/10/2566",
          "callPut": -1662.0,
          "futurePrice": 875.6
        },
        {
          "date": "10/10/2566",
          "callPut": 1916.0,
          "futurePrice": 879.0
        },
        {
          "date": "11/10/2566",
          "callPut": -2274.0,
          "futurePrice": 895.1
        },
        {
          "date": "12/10/2566",
          "callPut": -3741.0,
          "futurePrice": 892.8
        },
        {
          "date": "16/10/2566",
          "callPut": -2722.0,
          "futurePrice": 878.7
        },
        {
          "date": "17/10/2566",
          "callPut": -2060.0,
          "futurePrice": 881.5
        },
        {
          "date": "18/10/2566",
          "callPut": -1657.0,
          "futurePrice": 890.9
        },
        {
          "date": "19/10/2566",
          "callPut": -2627.0,
          "futurePrice": 879.4
        },
        {
          "date": "20/10/2566",
          "callPut": -3034.0,
          "futurePrice": 861.7
        },
        {
          "date": "24/10/2566",
          "callPut": -659.0,
          "futurePrice": 861.4
        },
        {
          "date": "25/10/2566",
          "callPut": 261.0,
          "futurePrice": 866.7
        },
        {
          "date": "26/10/2566",
          "callPut": -2363.0,
          "futurePrice": 846.9
        },
        {
          "date": "27/10/2566",
          "callPut": -1815.0,
          "futurePrice": 859.1
        },
        {
          "date": "30/10/2566",
          "callPut": 633.0,
          "futurePrice": 868.3
        },
        {
          "date": "31/10/2566",
          "callPut": -494.0,
          "futurePrice": 859.0
        },
        {
          "date": "1/11/2566",
          "callPut": 1833.0,
          "futurePrice": 855.0
        },
        {
          "date": "2/11/2566",
          "callPut": -876.0,
          "futurePrice": 871.0
        },
        {
          "date": "3/11/2566",
          "callPut": 1945.0,
          "futurePrice": 878.0
        },
        {
          "date": "6/11/2566",
          "callPut": 1331.0,
          "futurePrice": 877.5
        },
        {
          "date": "7/11/2566",
          "callPut": 1717.0,
          "futurePrice": 871.5
        },
        {
          "date": "8/11/2566",
          "callPut": -726.0,
          "futurePrice": 875.2
        },
        {
          "date": "9/11/2566",
          "callPut": 2704.0,
          "futurePrice": 872.2
        },
        {
          "date": "10/11/2566",
          "callPut": -432.0,
          "futurePrice": 859.6
        },
        {
          "date": "13/11/2566",
          "callPut": -771.0,
          "futurePrice": 862.8
        },
        {
          "date": "14/11/2566",
          "callPut": -755.0,
          "futurePrice": 862.1
        },
        {
          "date": "15/11/2566",
          "callPut": -1985.0,
          "futurePrice": 881.2
        },
        {
          "date": "16/11/2566",
          "callPut": 2269.0,
          "futurePrice": 880.4
        },
        {
          "date": "17/11/2566",
          "callPut": 1247.0,
          "futurePrice": 877.8
        },
        {
          "date": "20/11/2566",
          "callPut": 287.0,
          "futurePrice": 880.9
        },
        {
          "date": "21/11/2566",
          "callPut": 2233.0,
          "futurePrice": 881.0
        },
        {
          "date": "22/11/2566",
          "callPut": 1117.0,
          "futurePrice": 875.0
        },
        {
          "date": "23/11/2566",
          "callPut": 2090.0,
          "futurePrice": 869.2
        },
        {
          "date": "24/11/2566",
          "callPut": -1245.0,
          "futurePrice": 864.4
        },
        {
          "date": "27/11/2566",
          "callPut": 1927.0,
          "futurePrice": 860.9
        },
        {
          "date": "28/11/2566",
          "callPut": 1510.0,
          "futurePrice": 868.3
        },
        {
          "date": "29/11/2566",
          "callPut": 920.0,
          "futurePrice": 858.4
        },
        {
          "date": "30/11/2566",
          "callPut": 2261.0,
          "futurePrice": 851.6
        },
        {
          "date": "1/12/2566",
          "callPut": 1118.0,
          "futurePrice": 853.7
        },
        {
          "date": "4/12/2566",
          "callPut": 2223.0,
          "futurePrice": 856.3
        },
        {
          "date": "6/12/2566",
          "callPut": 896.0,
          "futurePrice": 856.6
        },
        {
          "date": "7/12/2566",
          "callPut": 1399.0,
          "futurePrice": 849.4
        },
        {
          "date": "8/12/2566",
          "callPut": 3641.0,
          "futurePrice": 853.6
        },
        {
          "date": "12/12/2566",
          "callPut": 1015.0,
          "futurePrice": 850.8
        },
        {
          "date": "13/12/2566",
          "callPut": 303.0,
          "futurePrice": 840.0
        },
        {
          "date": "14/12/2566",
          "callPut": -1600.0,
          "futurePrice": 854.8
        },
        {
          "date": "15/12/2566",
          "callPut": 1614.0,
          "futurePrice": 863.0
        },
        {
          "date": "18/12/2566",
          "callPut": -388.0,
          "futurePrice": 863.6
        },
        {
          "date": "19/12/2566",
          "callPut": -14.0,
          "futurePrice": 865.6
        },
        {
          "date": "20/12/2566",
          "callPut": 577.0,
          "futurePrice": 870.2
        },
        {
          "date": "21/12/2566",
          "callPut": 294.0,
          "futurePrice": 871.0
        },
        {
          "date": "22/12/2566",
          "callPut": 442.0,
          "futurePrice": 872.4
        },
        {
          "date": "25/12/2566",
          "callPut": 916.0,
          "futurePrice": 874.2
        },
        {
          "date": "26/12/2566",
          "callPut": 2205.0,
          "futurePrice": 872.9
        },
        {
          "date": "27/12/2566",
          "callPut": 284.0,
          "futurePrice": 870.2
        },
        {
          "date": "28/12/2566",
          "callPut": 0.0,
          "futurePrice": 873.2
        },
        {
          "date": "29/12/2566",
          "callPut": 0.0,
          "futurePrice": null
        }
      ],
      openInterestData: [
        {
          "date": "29/6/2566",
          "OI": -312.0,
          "futurePrice": 899.3
        },
        {
          "date": "30/6/2566",
          "OI": -572.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/7/2566",
          "OI": -643.0,
          "futurePrice": 916.4
        },
        {
          "date": "4/7/2566",
          "OI": -753.0,
          "futurePrice": 921.7
        },
        {
          "date": "5/7/2566",
          "OI": -773.0,
          "futurePrice": 920.5
        },
        {
          "date": "6/7/2566",
          "OI": -734.0,
          "futurePrice": 907.8
        },
        {
          "date": "7/7/2566",
          "OI": -746.0,
          "futurePrice": 907.7
        },
        {
          "date": "10/7/2566",
          "OI": -795.0,
          "futurePrice": 915.0
        },
        {
          "date": "11/7/2566",
          "OI": -833.0,
          "futurePrice": 916.1
        },
        {
          "date": "12/7/2566",
          "OI": -802.0,
          "futurePrice": 913.4
        },
        {
          "date": "13/7/2566",
          "OI": -841.0,
          "futurePrice": 916.8
        },
        {
          "date": "14/7/2566",
          "OI": -709.0,
          "futurePrice": 932.6
        },
        {
          "date": "17/7/2566",
          "OI": -607.0,
          "futurePrice": 937.8
        },
        {
          "date": "18/7/2566",
          "OI": -472.0,
          "futurePrice": 943.4
        },
        {
          "date": "19/7/2566",
          "OI": -492.0,
          "futurePrice": 942.4
        },
        {
          "date": "20/7/2566",
          "OI": -562.0,
          "futurePrice": 934.2
        },
        {
          "date": "21/7/2566",
          "OI": -526.0,
          "futurePrice": 941.5
        },
        {
          "date": "24/7/2566",
          "OI": -591.0,
          "futurePrice": 938.0
        },
        {
          "date": "25/7/2566",
          "OI": -544.0,
          "futurePrice": 943.1
        },
        {
          "date": "26/7/2566",
          "OI": -491.0,
          "futurePrice": 942.0
        },
        {
          "date": "27/7/2566",
          "OI": -250.0,
          "futurePrice": 954.6
        },
        {
          "date": "31/7/2566",
          "OI": -9.0,
          "futurePrice": 963.7
        },
        {
          "date": "2/8/2566",
          "OI": 9.0,
          "futurePrice": 958.7
        },
        {
          "date": "3/8/2566",
          "OI": -83.0,
          "futurePrice": 942.9
        },
        {
          "date": "4/8/2566",
          "OI": -118.0,
          "futurePrice": 944.8
        },
        {
          "date": "7/8/2566",
          "OI": -228.0,
          "futurePrice": 951.6
        },
        {
          "date": "8/8/2566",
          "OI": -248.0,
          "futurePrice": 938.1
        },
        {
          "date": "9/8/2566",
          "OI": -72.0,
          "futurePrice": 943.5
        },
        {
          "date": "10/8/2566",
          "OI": -52.0,
          "futurePrice": 947.4
        },
        {
          "date": "11/8/2566",
          "OI": -344.0,
          "futurePrice": 947.1
        },
        {
          "date": "15/8/2566",
          "OI": 48.0,
          "futurePrice": 937.2
        },
        {
          "date": "16/8/2566",
          "OI": -172.0,
          "futurePrice": 932.2
        },
        {
          "date": "17/8/2566",
          "OI": -58.0,
          "futurePrice": 938.0
        },
        {
          "date": "18/8/2566",
          "OI": -139.0,
          "futurePrice": 931.6
        },
        {
          "date": "21/8/2566",
          "OI": -292.0,
          "futurePrice": 936.9
        },
        {
          "date": "22/8/2566",
          "OI": -212.0,
          "futurePrice": 949.5
        },
        {
          "date": "23/8/2566",
          "OI": -352.0,
          "futurePrice": 949.1
        },
        {
          "date": "24/8/2566",
          "OI": -475.0,
          "futurePrice": 954.5
        },
        {
          "date": "25/8/2566",
          "OI": -527.0,
          "futurePrice": 954.7
        },
        {
          "date": "28/8/2566",
          "OI": -999.0,
          "futurePrice": 956.1
        },
        {
          "date": "29/8/2566",
          "OI": -1112.0,
          "futurePrice": 958.7
        },
        {
          "date": "30/8/2566",
          "OI": -1007.0,
          "futurePrice": 961.1
        },
        {
          "date": "31/8/2566",
          "OI": -901.0,
          "futurePrice": 952.6
        },
        {
          "date": "1/9/2566",
          "OI": -834.0,
          "futurePrice": 952.8
        },
        {
          "date": "4/9/2566",
          "OI": -608.0,
          "futurePrice": 946.5
        },
        {
          "date": "5/9/2566",
          "OI": -666.0,
          "futurePrice": 943.9
        },
        {
          "date": "6/9/2566",
          "OI": -943.0,
          "futurePrice": 944.0
        },
        {
          "date": "7/9/2566",
          "OI": -1363.0,
          "futurePrice": 944.8
        },
        {
          "date": "8/9/2566",
          "OI": -1522.0,
          "futurePrice": 940.2
        },
        {
          "date": "11/9/2566",
          "OI": -1736.0,
          "futurePrice": 937.5
        },
        {
          "date": "12/9/2566",
          "OI": -1657.0,
          "futurePrice": 941.0
        },
        {
          "date": "13/9/2566",
          "OI": -1725.0,
          "futurePrice": 937.9
        },
        {
          "date": "14/9/2566",
          "OI": -1868.0,
          "futurePrice": 944.7
        },
        {
          "date": "15/9/2566",
          "OI": -1765.0,
          "futurePrice": 946.1
        },
        {
          "date": "18/9/2566",
          "OI": -1465.0,
          "futurePrice": 936.0
        },
        {
          "date": "19/9/2566",
          "OI": -1387.0,
          "futurePrice": 933.1
        },
        {
          "date": "20/9/2566",
          "OI": -2026.0,
          "futurePrice": 922.7
        },
        {
          "date": "21/9/2566",
          "OI": -2310.0,
          "futurePrice": 923.8
        },
        {
          "date": "22/9/2566",
          "OI": -2337.0,
          "futurePrice": 931.8
        },
        {
          "date": "25/9/2566",
          "OI": -2143.0,
          "futurePrice": 923.9
        },
        {
          "date": "26/9/2566",
          "OI": -3247.0,
          "futurePrice": 917.6
        },
        {
          "date": "27/9/2566",
          "OI": -3246.0,
          "futurePrice": 917.5
        },
        {
          "date": "28/9/2566",
          "OI": -3495.0,
          "futurePrice": 911.8
        },
        {
          "date": "29/9/2566",
          "OI": -1610.0,
          "futurePrice": 901.6
        },
        {
          "date": "2/10/2566",
          "OI": 983.0,
          "futurePrice": 901.5
        },
        {
          "date": "3/10/2566",
          "OI": 4500.0,
          "futurePrice": 882.7
        },
        {
          "date": "4/10/2566",
          "OI": 7207.0,
          "futurePrice": 886.8
        },
        {
          "date": "5/10/2566",
          "OI": 8237.0,
          "futurePrice": 887.5
        },
        {
          "date": "6/10/2566",
          "OI": 8865.0,
          "futurePrice": 876.5
        },
        {
          "date": "9/10/2566",
          "OI": 10489.0,
          "futurePrice": 875.6
        },
        {
          "date": "10/10/2566",
          "OI": 10147.0,
          "futurePrice": 879.0
        },
        {
          "date": "11/10/2566",
          "OI": 6582.0,
          "futurePrice": 895.1
        },
        {
          "date": "12/10/2566",
          "OI": 4723.0,
          "futurePrice": 892.8
        },
        {
          "date": "16/10/2566",
          "OI": 5533.0,
          "futurePrice": 878.7
        },
        {
          "date": "17/10/2566",
          "OI": 3674.0,
          "futurePrice": 881.5
        },
        {
          "date": "18/10/2566",
          "OI": 1239.0,
          "futurePrice": 890.9
        },
        {
          "date": "19/10/2566",
          "OI": 1415.0,
          "futurePrice": 879.4
        },
        {
          "date": "20/10/2566",
          "OI": 856.0,
          "futurePrice": 861.7
        },
        {
          "date": "24/10/2566",
          "OI": 819.0,
          "futurePrice": 861.4
        },
        {
          "date": "25/10/2566",
          "OI": 2877.0,
          "futurePrice": 866.7
        },
        {
          "date": "26/10/2566",
          "OI": 5422.0,
          "futurePrice": 846.9
        },
        {
          "date": "27/10/2566",
          "OI": 7870.0,
          "futurePrice": 859.1
        },
        {
          "date": "30/10/2566",
          "OI": 9890.0,
          "futurePrice": 868.3
        },
        {
          "date": "31/10/2566",
          "OI": 11761.0,
          "futurePrice": 859.0
        },
        {
          "date": "1/11/2566",
          "OI": 13434.0,
          "futurePrice": 855.0
        },
        {
          "date": "2/11/2566",
          "OI": 14377.0,
          "futurePrice": 871.0
        },
        {
          "date": "3/11/2566",
          "OI": 14718.0,
          "futurePrice": 878.0
        },
        {
          "date": "6/11/2566",
          "OI": 15211.0,
          "futurePrice": 877.5
        },
        {
          "date": "7/11/2566",
          "OI": 15848.0,
          "futurePrice": 871.5
        },
        {
          "date": "8/11/2566",
          "OI": 15883.0,
          "futurePrice": 875.2
        },
        {
          "date": "9/11/2566",
          "OI": 18501.0,
          "futurePrice": 872.2
        },
        {
          "date": "10/11/2566",
          "OI": 20094.0,
          "futurePrice": 859.6
        },
        {
          "date": "13/11/2566",
          "OI": 19928.0,
          "futurePrice": 862.8
        },
        {
          "date": "14/11/2566",
          "OI": 21106.0,
          "futurePrice": 862.1
        },
        {
          "date": "15/11/2566",
          "OI": 18497.0,
          "futurePrice": 881.2
        },
        {
          "date": "16/11/2566",
          "OI": 18313.0,
          "futurePrice": 880.4
        },
        {
          "date": "17/11/2566",
          "OI": 18188.0,
          "futurePrice": 877.8
        },
        {
          "date": "20/11/2566",
          "OI": 17728.0,
          "futurePrice": 880.9
        },
        {
          "date": "21/11/2566",
          "OI": 18263.0,
          "futurePrice": 881.0
        },
        {
          "date": "22/11/2566",
          "OI": 18886.0,
          "futurePrice": 875.0
        },
        {
          "date": "23/11/2566",
          "OI": 19447.0,
          "futurePrice": 869.2
        },
        {
          "date": "24/11/2566",
          "OI": 21213.0,
          "futurePrice": 864.4
        },
        {
          "date": "27/11/2566",
          "OI": 21635.0,
          "futurePrice": 860.9
        },
        {
          "date": "28/11/2566",
          "OI": 22425.0,
          "futurePrice": 868.3
        },
        {
          "date": "29/11/2566",
          "OI": 22680.0,
          "futurePrice": 858.4
        },
        {
          "date": "30/11/2566",
          "OI": 24556.0,
          "futurePrice": 851.6
        },
        {
          "date": "1/12/2566",
          "OI": 24954.0,
          "futurePrice": 853.7
        },
        {
          "date": "4/12/2566",
          "OI": 24510.0,
          "futurePrice": 856.3
        },
        {
          "date": "6/12/2566",
          "OI": 25105.0,
          "futurePrice": 856.6
        },
        {
          "date": "7/12/2566",
          "OI": 25118.0,
          "futurePrice": 849.4
        },
        {
          "date": "8/12/2566",
          "OI": 26706.0,
          "futurePrice": 853.6
        },
        {
          "date": "12/12/2566",
          "OI": 27143.0,
          "futurePrice": 850.8
        },
        {
          "date": "13/12/2566",
          "OI": 28361.0,
          "futurePrice": 840.0
        },
        {
          "date": "14/12/2566",
          "OI": 29272.0,
          "futurePrice": 854.8
        },
        {
          "date": "15/12/2566",
          "OI": 29807.0,
          "futurePrice": 863.0
        },
        {
          "date": "18/12/2566",
          "OI": 29213.0,
          "futurePrice": 863.6
        },
        {
          "date": "19/12/2566",
          "OI": 29758.0,
          "futurePrice": 865.6
        },
        {
          "date": "20/12/2566",
          "OI": 29889.0,
          "futurePrice": 870.2
        },
        {
          "date": "21/12/2566",
          "OI": 29880.0,
          "futurePrice": 871.0
        },
        {
          "date": "22/12/2566",
          "OI": 29743.0,
          "futurePrice": 872.4
        },
        {
          "date": "25/12/2566",
          "OI": 29848.0,
          "futurePrice": 874.2
        },
        {
          "date": "26/12/2566",
          "OI": 28761.0,
          "futurePrice": 872.9
        },
        {
          "date": "27/12/2566",
          "OI": 27371.0,
          "futurePrice": 870.2
        },
        {
          "date": "28/12/2566",
          "OI": 0.0,
          "futurePrice": 873.2
        },
        {
          "date": "29/12/2566",
          "OI": 0.0,
          "futurePrice": null
        }
      ]
    }
  },
  2024: {
    S50H24: {
      volumeData: [
        {
          "date": "28/9/2566",
          "callPut": -732.0,
          "futurePrice": 911.8
        },
        {
          "date": "29/9/2566",
          "callPut": -801.0,
          "futurePrice": 901.6
        },
        {
          "date": "2/10/2566",
          "callPut": -485.0,
          "futurePrice": 901.5
        },
        {
          "date": "3/10/2566",
          "callPut": -1190.0,
          "futurePrice": 882.7
        },
        {
          "date": "4/10/2566",
          "callPut": -848.0,
          "futurePrice": 886.8
        },
        {
          "date": "5/10/2566",
          "callPut": -215.0,
          "futurePrice": 887.5
        },
        {
          "date": "6/10/2566",
          "callPut": -90.0,
          "futurePrice": 876.5
        },
        {
          "date": "9/10/2566",
          "callPut": -57.0,
          "futurePrice": 875.6
        },
        {
          "date": "10/10/2566",
          "callPut": -205.0,
          "futurePrice": 879.0
        },
        {
          "date": "11/10/2566",
          "callPut": 436.0,
          "futurePrice": 895.1
        },
        {
          "date": "12/10/2566",
          "callPut": -114.0,
          "futurePrice": 892.8
        },
        {
          "date": "16/10/2566",
          "callPut": -586.0,
          "futurePrice": 878.7
        },
        {
          "date": "17/10/2566",
          "callPut": -874.0,
          "futurePrice": 881.5
        },
        {
          "date": "18/10/2566",
          "callPut": 290.0,
          "futurePrice": 890.9
        },
        {
          "date": "19/10/2566",
          "callPut": -306.0,
          "futurePrice": 879.4
        },
        {
          "date": "20/10/2566",
          "callPut": -1062.0,
          "futurePrice": 861.7
        },
        {
          "date": "24/10/2566",
          "callPut": -319.0,
          "futurePrice": 861.4
        },
        {
          "date": "25/10/2566",
          "callPut": -522.0,
          "futurePrice": 866.7
        },
        {
          "date": "26/10/2566",
          "callPut": -1056.0,
          "futurePrice": 846.9
        },
        {
          "date": "27/10/2566",
          "callPut": -862.0,
          "futurePrice": 859.1
        },
        {
          "date": "30/10/2566",
          "callPut": -717.0,
          "futurePrice": 868.3
        },
        {
          "date": "31/10/2566",
          "callPut": -635.0,
          "futurePrice": 859.0
        },
        {
          "date": "1/11/2566",
          "callPut": -907.0,
          "futurePrice": 855.0
        },
        {
          "date": "2/11/2566",
          "callPut": -885.0,
          "futurePrice": 871.0
        },
        {
          "date": "3/11/2566",
          "callPut": -1155.0,
          "futurePrice": 878.0
        },
        {
          "date": "6/11/2566",
          "callPut": -460.0,
          "futurePrice": 877.5
        },
        {
          "date": "7/11/2566",
          "callPut": -623.0,
          "futurePrice": 871.5
        },
        {
          "date": "8/11/2566",
          "callPut": -306.0,
          "futurePrice": 875.2
        },
        {
          "date": "9/11/2566",
          "callPut": -853.0,
          "futurePrice": 872.2
        },
        {
          "date": "10/11/2566",
          "callPut": -809.0,
          "futurePrice": 859.6
        },
        {
          "date": "13/11/2566",
          "callPut": -839.0,
          "futurePrice": 862.8
        },
        {
          "date": "14/11/2566",
          "callPut": -369.0,
          "futurePrice": 862.1
        },
        {
          "date": "15/11/2566",
          "callPut": -1159.0,
          "futurePrice": 881.2
        },
        {
          "date": "16/11/2566",
          "callPut": -158.0,
          "futurePrice": 880.4
        },
        {
          "date": "17/11/2566",
          "callPut": -668.0,
          "futurePrice": 877.8
        },
        {
          "date": "20/11/2566",
          "callPut": -324.0,
          "futurePrice": 880.9
        },
        {
          "date": "21/11/2566",
          "callPut": -437.0,
          "futurePrice": 881.0
        },
        {
          "date": "22/11/2566",
          "callPut": -244.0,
          "futurePrice": 875.0
        },
        {
          "date": "23/11/2566",
          "callPut": -284.0,
          "futurePrice": 869.2
        },
        {
          "date": "24/11/2566",
          "callPut": -393.0,
          "futurePrice": 864.4
        },
        {
          "date": "27/11/2566",
          "callPut": -1015.0,
          "futurePrice": 860.9
        },
        {
          "date": "28/11/2566",
          "callPut": -475.0,
          "futurePrice": 868.3
        },
        {
          "date": "29/11/2566",
          "callPut": -652.0,
          "futurePrice": 858.4
        },
        {
          "date": "30/11/2566",
          "callPut": -1501.0,
          "futurePrice": 851.6
        },
        {
          "date": "1/12/2566",
          "callPut": 88.0,
          "futurePrice": 853.7
        },
        {
          "date": "4/12/2566",
          "callPut": 11.0,
          "futurePrice": 856.3
        },
        {
          "date": "6/12/2566",
          "callPut": -325.0,
          "futurePrice": 856.6
        },
        {
          "date": "7/12/2566",
          "callPut": -510.0,
          "futurePrice": 849.4
        },
        {
          "date": "8/12/2566",
          "callPut": 31.0,
          "futurePrice": 853.6
        },
        {
          "date": "12/12/2566",
          "callPut": -499.0,
          "futurePrice": 850.8
        },
        {
          "date": "13/12/2566",
          "callPut": -1513.0,
          "futurePrice": 840.0
        },
        {
          "date": "14/12/2566",
          "callPut": -161.0,
          "futurePrice": 854.8
        },
        {
          "date": "15/12/2566",
          "callPut": -294.0,
          "futurePrice": 863.0
        },
        {
          "date": "18/12/2566",
          "callPut": -301.0,
          "futurePrice": 863.6
        },
        {
          "date": "19/12/2566",
          "callPut": -199.0,
          "futurePrice": 865.6
        },
        {
          "date": "20/12/2566",
          "callPut": -343.0,
          "futurePrice": 870.2
        },
        {
          "date": "21/12/2566",
          "callPut": -694.0,
          "futurePrice": 871.0
        },
        {
          "date": "22/12/2566",
          "callPut": -997.0,
          "futurePrice": 872.4
        },
        {
          "date": "25/12/2566",
          "callPut": -304.0,
          "futurePrice": 874.2
        },
        {
          "date": "26/12/2566",
          "callPut": -623.0,
          "futurePrice": 872.9
        },
        {
          "date": "27/12/2566",
          "callPut": -1940.0,
          "futurePrice": 870.2
        },
        {
          "date": "28/12/2566",
          "callPut": -703.0,
          "futurePrice": 873.2
        },
        {
          "date": "2/1/2567",
          "callPut": -2755.0,
          "futurePrice": 878.8
        },
        {
          "date": "3/1/2567",
          "callPut": -1504.0,
          "futurePrice": 874.6
        },
        {
          "date": "4/1/2567",
          "callPut": -2480.0,
          "futurePrice": 875.2
        },
        {
          "date": "5/1/2567",
          "callPut": -2312.0,
          "futurePrice": 869.2
        },
        {
          "date": "8/1/2567",
          "callPut": -2407.0,
          "futurePrice": 864.7
        },
        {
          "date": "9/1/2567",
          "callPut": -2410.0,
          "futurePrice": 864.2
        },
        {
          "date": "10/1/2567",
          "callPut": -1662.0,
          "futurePrice": 863.0
        },
        {
          "date": "11/1/2567",
          "callPut": -2187.0,
          "futurePrice": 861.2
        },
        {
          "date": "12/1/2567",
          "callPut": -1255.0,
          "futurePrice": 861.2
        },
        {
          "date": "15/1/2567",
          "callPut": -354.0,
          "futurePrice": 856.3
        },
        {
          "date": "16/1/2567",
          "callPut": -2694.0,
          "futurePrice": 851.9
        },
        {
          "date": "17/1/2567",
          "callPut": -3516.0,
          "futurePrice": 839.0
        },
        {
          "date": "18/1/2567",
          "callPut": -3817.0,
          "futurePrice": 835.9
        },
        {
          "date": "19/1/2567",
          "callPut": -2425.0,
          "futurePrice": 839.4
        },
        {
          "date": "22/1/2567",
          "callPut": -2455.0,
          "futurePrice": 832.2
        },
        {
          "date": "23/1/2567",
          "callPut": -1690.0,
          "futurePrice": 825.8
        },
        {
          "date": "24/1/2567",
          "callPut": -2723.0,
          "futurePrice": 840.7
        },
        {
          "date": "25/1/2567",
          "callPut": -2622.0,
          "futurePrice": 836.8
        },
        {
          "date": "26/1/2567",
          "callPut": -1941.0,
          "futurePrice": 834.3
        },
        {
          "date": "29/1/2567",
          "callPut": -688.0,
          "futurePrice": 836.1
        },
        {
          "date": "30/1/2567",
          "callPut": -1618.0,
          "futurePrice": 835.4
        },
        {
          "date": "31/1/2567",
          "callPut": -1110.0,
          "futurePrice": 829.7
        },
        {
          "date": "1/2/2567",
          "callPut": -2815.0,
          "futurePrice": 830.6
        },
        {
          "date": "2/2/2567",
          "callPut": -3983.0,
          "futurePrice": 843.6
        },
        {
          "date": "5/2/2567",
          "callPut": -1647.0,
          "futurePrice": 839.1
        },
        {
          "date": "6/2/2567",
          "callPut": -353.0,
          "futurePrice": 847.0
        },
        {
          "date": "7/2/2567",
          "callPut": 976.0,
          "futurePrice": 849.3
        },
        {
          "date": "8/2/2567",
          "callPut": -423.0,
          "futurePrice": 843.5
        },
        {
          "date": "9/2/2567",
          "callPut": -530.0,
          "futurePrice": 840.2
        },
        {
          "date": "12/2/2567",
          "callPut": -1059.0,
          "futurePrice": 843.6
        },
        {
          "date": "13/2/2567",
          "callPut": -2544.0,
          "futurePrice": 844.4
        },
        {
          "date": "14/2/2567",
          "callPut": -2190.0,
          "futurePrice": 836.1
        },
        {
          "date": "15/2/2567",
          "callPut": -1098.0,
          "futurePrice": 837.6
        },
        {
          "date": "16/2/2567",
          "callPut": -1839.0,
          "futurePrice": 837.8
        },
        {
          "date": "19/2/2567",
          "callPut": 2027.0,
          "futurePrice": 839.8
        },
        {
          "date": "20/2/2567",
          "callPut": -639.0,
          "futurePrice": 838.0
        },
        {
          "date": "21/2/2567",
          "callPut": -8271.0,
          "futurePrice": 847.7
        },
        {
          "date": "22/2/2567",
          "callPut": -2008.0,
          "futurePrice": 853.9
        },
        {
          "date": "23/2/2567",
          "callPut": -1288.0,
          "futurePrice": 851.5
        },
        {
          "date": "27/2/2567",
          "callPut": -365.0,
          "futurePrice": 846.4
        },
        {
          "date": "28/2/2567",
          "callPut": -2926.0,
          "futurePrice": 836.7
        },
        {
          "date": "29/2/2567",
          "callPut": -2554.0,
          "futurePrice": 833.5
        },
        {
          "date": "1/3/2567",
          "callPut": -288.0,
          "futurePrice": 826.2
        },
        {
          "date": "4/3/2567",
          "callPut": -2118.0,
          "futurePrice": 830.2
        },
        {
          "date": "5/3/2567",
          "callPut": -897.0,
          "futurePrice": 826.1
        },
        {
          "date": "6/3/2567",
          "callPut": -2128.0,
          "futurePrice": 834.7
        },
        {
          "date": "7/3/2567",
          "callPut": -1851.0,
          "futurePrice": 836.3
        },
        {
          "date": "8/3/2567",
          "callPut": -2678.0,
          "futurePrice": 844.1
        },
        {
          "date": "11/3/2567",
          "callPut": -1377.0,
          "futurePrice": 841.1
        },
        {
          "date": "12/3/2567",
          "callPut": -1729.0,
          "futurePrice": 842.7
        },
        {
          "date": "13/3/2567",
          "callPut": -1484.0,
          "futurePrice": 845.4
        },
        {
          "date": "14/3/2567",
          "callPut": -2077.0,
          "futurePrice": 852.8
        },
        {
          "date": "15/3/2567",
          "callPut": -1970.0,
          "futurePrice": 846.3
        },
        {
          "date": "18/3/2567",
          "callPut": -3087.0,
          "futurePrice": 847.3
        },
        {
          "date": "19/3/2567",
          "callPut": -1813.0,
          "futurePrice": 843.4
        },
        {
          "date": "20/3/2567",
          "callPut": -1039.0,
          "futurePrice": 838.7
        },
        {
          "date": "21/3/2567",
          "callPut": -5073.0,
          "futurePrice": 851.1
        },
        {
          "date": "22/3/2567",
          "callPut": -2086.0,
          "futurePrice": 849.0
        },
        {
          "date": "25/3/2567",
          "callPut": -1890.0,
          "futurePrice": 843.4
        },
        {
          "date": "26/3/2567",
          "callPut": 853.0,
          "futurePrice": 845.2
        },
        {
          "date": "27/3/2567",
          "callPut": 357.0,
          "futurePrice": 846.6
        },
        {
          "date": "28/3/2567",
          "callPut": 666.0,
          "futurePrice": 833.3
        },
        {
          "date": "29/3/2567",
          "callPut": 0.0,
          "futurePrice": 837.2
        },
        {
          "date": "1/4/2567",
          "callPut": 0.0,
          "futurePrice": 840.3
        }
      ],
      openInterestData: [
        {
          "date": "28/9/2566",
          "OI": -681.0,
          "futurePrice": 911.8
        },
        {
          "date": "29/9/2566",
          "OI": -1161.0,
          "futurePrice": 901.6
        },
        {
          "date": "2/10/2566",
          "OI": -1270.0,
          "futurePrice": 901.5
        },
        {
          "date": "3/10/2566",
          "OI": -2065.0,
          "futurePrice": 882.7
        },
        {
          "date": "4/10/2566",
          "OI": -2284.0,
          "futurePrice": 886.8
        },
        {
          "date": "5/10/2566",
          "OI": -2330.0,
          "futurePrice": 887.5
        },
        {
          "date": "6/10/2566",
          "OI": -2183.0,
          "futurePrice": 876.5
        },
        {
          "date": "9/10/2566",
          "OI": -2090.0,
          "futurePrice": 875.6
        },
        {
          "date": "10/10/2566",
          "OI": -1953.0,
          "futurePrice": 879.0
        },
        {
          "date": "11/10/2566",
          "OI": -1783.0,
          "futurePrice": 895.1
        },
        {
          "date": "12/10/2566",
          "OI": -2046.0,
          "futurePrice": 892.8
        },
        {
          "date": "16/10/2566",
          "OI": -2503.0,
          "futurePrice": 878.7
        },
        {
          "date": "17/10/2566",
          "OI": -3325.0,
          "futurePrice": 881.5
        },
        {
          "date": "18/10/2566",
          "OI": -2826.0,
          "futurePrice": 890.9
        },
        {
          "date": "19/10/2566",
          "OI": -2882.0,
          "futurePrice": 879.4
        },
        {
          "date": "20/10/2566",
          "OI": -3397.0,
          "futurePrice": 861.7
        },
        {
          "date": "24/10/2566",
          "OI": -3368.0,
          "futurePrice": 861.4
        },
        {
          "date": "25/10/2566",
          "OI": -3533.0,
          "futurePrice": 866.7
        },
        {
          "date": "26/10/2566",
          "OI": -3583.0,
          "futurePrice": 846.9
        },
        {
          "date": "27/10/2566",
          "OI": -3723.0,
          "futurePrice": 859.1
        },
        {
          "date": "30/10/2566",
          "OI": -3829.0,
          "futurePrice": 868.3
        },
        {
          "date": "31/10/2566",
          "OI": -3567.0,
          "futurePrice": 859.0
        },
        {
          "date": "1/11/2566",
          "OI": -3566.0,
          "futurePrice": 855.0
        },
        {
          "date": "2/11/2566",
          "OI": -3327.0,
          "futurePrice": 871.0
        },
        {
          "date": "3/11/2566",
          "OI": -3521.0,
          "futurePrice": 878.0
        },
        {
          "date": "6/11/2566",
          "OI": -3371.0,
          "futurePrice": 877.5
        },
        {
          "date": "7/11/2566",
          "OI": -3525.0,
          "futurePrice": 871.5
        },
        {
          "date": "8/11/2566",
          "OI": -3523.0,
          "futurePrice": 875.2
        },
        {
          "date": "9/11/2566",
          "OI": -3525.0,
          "futurePrice": 872.2
        },
        {
          "date": "10/11/2566",
          "OI": -3653.0,
          "futurePrice": 859.6
        },
        {
          "date": "13/11/2566",
          "OI": -3601.0,
          "futurePrice": 862.8
        },
        {
          "date": "14/11/2566",
          "OI": -3514.0,
          "futurePrice": 862.1
        },
        {
          "date": "15/11/2566",
          "OI": -2575.0,
          "futurePrice": 881.2
        },
        {
          "date": "16/11/2566",
          "OI": -2392.0,
          "futurePrice": 880.4
        },
        {
          "date": "17/11/2566",
          "OI": -2821.0,
          "futurePrice": 877.8
        },
        {
          "date": "20/11/2566",
          "OI": -2809.0,
          "futurePrice": 880.9
        },
        {
          "date": "21/11/2566",
          "OI": -2853.0,
          "futurePrice": 881.0
        },
        {
          "date": "22/11/2566",
          "OI": -2783.0,
          "futurePrice": 875.0
        },
        {
          "date": "23/11/2566",
          "OI": -2656.0,
          "futurePrice": 869.2
        },
        {
          "date": "24/11/2566",
          "OI": -2564.0,
          "futurePrice": 864.4
        },
        {
          "date": "27/11/2566",
          "OI": -2621.0,
          "futurePrice": 860.9
        },
        {
          "date": "28/11/2566",
          "OI": -2721.0,
          "futurePrice": 868.3
        },
        {
          "date": "29/11/2566",
          "OI": -2595.0,
          "futurePrice": 858.4
        },
        {
          "date": "30/11/2566",
          "OI": -2707.0,
          "futurePrice": 851.6
        },
        {
          "date": "1/12/2566",
          "OI": -2325.0,
          "futurePrice": 853.7
        },
        {
          "date": "4/12/2566",
          "OI": -1911.0,
          "futurePrice": 856.3
        },
        {
          "date": "6/12/2566",
          "OI": -1947.0,
          "futurePrice": 856.6
        },
        {
          "date": "7/12/2566",
          "OI": -1689.0,
          "futurePrice": 849.4
        },
        {
          "date": "8/12/2566",
          "OI": -1563.0,
          "futurePrice": 853.6
        },
        {
          "date": "12/12/2566",
          "OI": -1302.0,
          "futurePrice": 850.8
        },
        {
          "date": "13/12/2566",
          "OI": -1071.0,
          "futurePrice": 840.0
        },
        {
          "date": "14/12/2566",
          "OI": -517.0,
          "futurePrice": 854.8
        },
        {
          "date": "15/12/2566",
          "OI": -250.0,
          "futurePrice": 863.0
        },
        {
          "date": "18/12/2566",
          "OI": -175.0,
          "futurePrice": 863.6
        },
        {
          "date": "19/12/2566",
          "OI": -120.0,
          "futurePrice": 865.6
        },
        {
          "date": "20/12/2566",
          "OI": 80.0,
          "futurePrice": 870.2
        },
        {
          "date": "21/12/2566",
          "OI": 267.0,
          "futurePrice": 871.0
        },
        {
          "date": "22/12/2566",
          "OI": 585.0,
          "futurePrice": 872.4
        },
        {
          "date": "25/12/2566",
          "OI": 805.0,
          "futurePrice": 874.2
        },
        {
          "date": "26/12/2566",
          "OI": 1208.0,
          "futurePrice": 872.9
        },
        {
          "date": "27/12/2566",
          "OI": 1169.0,
          "futurePrice": 870.2
        },
        {
          "date": "28/12/2566",
          "OI": 1658.0,
          "futurePrice": 873.2
        },
        {
          "date": "2/1/2567",
          "OI": 1816.0,
          "futurePrice": 878.8
        },
        {
          "date": "3/1/2567",
          "OI": 1961.0,
          "futurePrice": 874.6
        },
        {
          "date": "4/1/2567",
          "OI": 1190.0,
          "futurePrice": 875.2
        },
        {
          "date": "5/1/2567",
          "OI": 780.0,
          "futurePrice": 869.2
        },
        {
          "date": "8/1/2567",
          "OI": 295.0,
          "futurePrice": 864.7
        },
        {
          "date": "9/1/2567",
          "OI": 571.0,
          "futurePrice": 864.2
        },
        {
          "date": "10/1/2567",
          "OI": -13.0,
          "futurePrice": 863.0
        },
        {
          "date": "11/1/2567",
          "OI": -1020.0,
          "futurePrice": 861.2
        },
        {
          "date": "12/1/2567",
          "OI": -1222.0,
          "futurePrice": 861.2
        },
        {
          "date": "15/1/2567",
          "OI": -1024.0,
          "futurePrice": 856.3
        },
        {
          "date": "16/1/2567",
          "OI": -1968.0,
          "futurePrice": 851.9
        },
        {
          "date": "17/1/2567",
          "OI": -221.0,
          "futurePrice": 839.0
        },
        {
          "date": "18/1/2567",
          "OI": 831.0,
          "futurePrice": 835.9
        },
        {
          "date": "19/1/2567",
          "OI": -35.0,
          "futurePrice": 839.4
        },
        {
          "date": "22/1/2567",
          "OI": 1549.0,
          "futurePrice": 832.2
        },
        {
          "date": "23/1/2567",
          "OI": 3718.0,
          "futurePrice": 825.8
        },
        {
          "date": "24/1/2567",
          "OI": 4960.0,
          "futurePrice": 840.7
        },
        {
          "date": "25/1/2567",
          "OI": 4993.0,
          "futurePrice": 836.8
        },
        {
          "date": "26/1/2567",
          "OI": 5472.0,
          "futurePrice": 834.3
        },
        {
          "date": "29/1/2567",
          "OI": 5404.0,
          "futurePrice": 836.1
        },
        {
          "date": "30/1/2567",
          "OI": 5063.0,
          "futurePrice": 835.4
        },
        {
          "date": "31/1/2567",
          "OI": 5845.0,
          "futurePrice": 829.7
        },
        {
          "date": "1/2/2567",
          "OI": 6372.0,
          "futurePrice": 830.6
        },
        {
          "date": "2/2/2567",
          "OI": 4577.0,
          "futurePrice": 843.6
        },
        {
          "date": "5/2/2567",
          "OI": 5305.0,
          "futurePrice": 839.1
        },
        {
          "date": "6/2/2567",
          "OI": 5121.0,
          "futurePrice": 847.0
        },
        {
          "date": "7/2/2567",
          "OI": 5386.0,
          "futurePrice": 849.3
        },
        {
          "date": "8/2/2567",
          "OI": 5966.0,
          "futurePrice": 843.5
        },
        {
          "date": "9/2/2567",
          "OI": 6139.0,
          "futurePrice": 840.2
        },
        {
          "date": "12/2/2567",
          "OI": 5994.0,
          "futurePrice": 843.6
        },
        {
          "date": "13/2/2567",
          "OI": 3898.0,
          "futurePrice": 844.4
        },
        {
          "date": "14/2/2567",
          "OI": 4699.0,
          "futurePrice": 836.1
        },
        {
          "date": "15/2/2567",
          "OI": 4923.0,
          "futurePrice": 837.6
        },
        {
          "date": "16/2/2567",
          "OI": 4307.0,
          "futurePrice": 837.8
        },
        {
          "date": "19/2/2567",
          "OI": 3960.0,
          "futurePrice": 839.8
        },
        {
          "date": "20/2/2567",
          "OI": 4046.0,
          "futurePrice": 838.0
        },
        {
          "date": "21/2/2567",
          "OI": 1333.0,
          "futurePrice": 847.7
        },
        {
          "date": "22/2/2567",
          "OI": 258.0,
          "futurePrice": 853.9
        },
        {
          "date": "23/2/2567",
          "OI": 85.0,
          "futurePrice": 851.5
        },
        {
          "date": "27/2/2567",
          "OI": 1583.0,
          "futurePrice": 846.4
        },
        {
          "date": "28/2/2567",
          "OI": 1725.0,
          "futurePrice": 836.7
        },
        {
          "date": "29/2/2567",
          "OI": 3659.0,
          "futurePrice": 833.5
        },
        {
          "date": "1/3/2567",
          "OI": 5029.0,
          "futurePrice": 826.2
        },
        {
          "date": "4/3/2567",
          "OI": 5136.0,
          "futurePrice": 830.2
        },
        {
          "date": "5/3/2567",
          "OI": 6475.0,
          "futurePrice": 826.1
        },
        {
          "date": "6/3/2567",
          "OI": 6116.0,
          "futurePrice": 834.7
        },
        {
          "date": "7/3/2567",
          "OI": 5062.0,
          "futurePrice": 836.3
        },
        {
          "date": "8/3/2567",
          "OI": 4475.0,
          "futurePrice": 844.1
        },
        {
          "date": "11/3/2567",
          "OI": 4072.0,
          "futurePrice": 841.1
        },
        {
          "date": "12/3/2567",
          "OI": 4147.0,
          "futurePrice": 842.7
        },
        {
          "date": "13/3/2567",
          "OI": 4327.0,
          "futurePrice": 845.4
        },
        {
          "date": "14/3/2567",
          "OI": 3256.0,
          "futurePrice": 852.8
        },
        {
          "date": "15/3/2567",
          "OI": 2445.0,
          "futurePrice": 846.3
        },
        {
          "date": "18/3/2567",
          "OI": 1669.0,
          "futurePrice": 847.3
        },
        {
          "date": "19/3/2567",
          "OI": 2665.0,
          "futurePrice": 843.4
        },
        {
          "date": "20/3/2567",
          "OI": 3504.0,
          "futurePrice": 838.7
        },
        {
          "date": "21/3/2567",
          "OI": 3038.0,
          "futurePrice": 851.1
        },
        {
          "date": "22/3/2567",
          "OI": 3699.0,
          "futurePrice": 849.0
        },
        {
          "date": "25/3/2567",
          "OI": 3873.0,
          "futurePrice": 843.4
        },
        {
          "date": "26/3/2567",
          "OI": 3893.0,
          "futurePrice": 845.2
        },
        {
          "date": "27/3/2567",
          "OI": 4060.0,
          "futurePrice": 846.6
        },
        {
          "date": "28/3/2567",
          "OI": 3523.0,
          "futurePrice": 833.3
        },
        {
          "date": "29/3/2567",
          "OI": 0.0,
          "futurePrice": 837.2
        },
        {
          "date": "1/4/2567",
          "OI": 0.0,
          "futurePrice": 840.3
        }
      ]
    },
    S50M24: {
      volumeData: [
        {
          "date": "27/12/2566",
          "callPut": -152.0,
          "futurePrice": 870.2
        },
        {
          "date": "28/12/2566",
          "callPut": -119.0,
          "futurePrice": 873.2
        },
        {
          "date": "2/1/2567",
          "callPut": -64.0,
          "futurePrice": 878.8
        },
        {
          "date": "3/1/2567",
          "callPut": 1.0,
          "futurePrice": 874.6
        },
        {
          "date": "4/1/2567",
          "callPut": -60.0,
          "futurePrice": 875.2
        },
        {
          "date": "5/1/2567",
          "callPut": -121.0,
          "futurePrice": 869.2
        },
        {
          "date": "8/1/2567",
          "callPut": -91.0,
          "futurePrice": 864.7
        },
        {
          "date": "9/1/2567",
          "callPut": 9.0,
          "futurePrice": 864.2
        },
        {
          "date": "10/1/2567",
          "callPut": -20.0,
          "futurePrice": 863.0
        },
        {
          "date": "11/1/2567",
          "callPut": -26.0,
          "futurePrice": 861.2
        },
        {
          "date": "12/1/2567",
          "callPut": -6.0,
          "futurePrice": 861.2
        },
        {
          "date": "15/1/2567",
          "callPut": -16.0,
          "futurePrice": 856.3
        },
        {
          "date": "16/1/2567",
          "callPut": -221.0,
          "futurePrice": 851.9
        },
        {
          "date": "17/1/2567",
          "callPut": -483.0,
          "futurePrice": 839.0
        },
        {
          "date": "18/1/2567",
          "callPut": -57.0,
          "futurePrice": 835.9
        },
        {
          "date": "19/1/2567",
          "callPut": 12.0,
          "futurePrice": 839.4
        },
        {
          "date": "22/1/2567",
          "callPut": -25.0,
          "futurePrice": 832.2
        },
        {
          "date": "23/1/2567",
          "callPut": -434.0,
          "futurePrice": 825.8
        },
        {
          "date": "24/1/2567",
          "callPut": -247.0,
          "futurePrice": 840.7
        },
        {
          "date": "25/1/2567",
          "callPut": 84.0,
          "futurePrice": 836.8
        },
        {
          "date": "26/1/2567",
          "callPut": 49.0,
          "futurePrice": 834.3
        },
        {
          "date": "29/1/2567",
          "callPut": 54.0,
          "futurePrice": 836.1
        },
        {
          "date": "30/1/2567",
          "callPut": 117.0,
          "futurePrice": 835.4
        },
        {
          "date": "31/1/2567",
          "callPut": 46.0,
          "futurePrice": 829.7
        },
        {
          "date": "1/2/2567",
          "callPut": 9.0,
          "futurePrice": 830.6
        },
        {
          "date": "2/2/2567",
          "callPut": 107.0,
          "futurePrice": 843.6
        },
        {
          "date": "5/2/2567",
          "callPut": 77.0,
          "futurePrice": 839.1
        },
        {
          "date": "6/2/2567",
          "callPut": -21.0,
          "futurePrice": 847.0
        },
        {
          "date": "7/2/2567",
          "callPut": 42.0,
          "futurePrice": 849.3
        },
        {
          "date": "8/2/2567",
          "callPut": -5.0,
          "futurePrice": 843.5
        },
        {
          "date": "9/2/2567",
          "callPut": -196.0,
          "futurePrice": 840.2
        },
        {
          "date": "12/2/2567",
          "callPut": -88.0,
          "futurePrice": 843.6
        },
        {
          "date": "13/2/2567",
          "callPut": 50.0,
          "futurePrice": 844.4
        },
        {
          "date": "14/2/2567",
          "callPut": 120.0,
          "futurePrice": 836.1
        },
        {
          "date": "15/2/2567",
          "callPut": 59.0,
          "futurePrice": 837.6
        },
        {
          "date": "16/2/2567",
          "callPut": -68.0,
          "futurePrice": 837.8
        },
        {
          "date": "19/2/2567",
          "callPut": -28.0,
          "futurePrice": 839.8
        },
        {
          "date": "20/2/2567",
          "callPut": -235.0,
          "futurePrice": 838.0
        },
        {
          "date": "21/2/2567",
          "callPut": -616.0,
          "futurePrice": 847.7
        },
        {
          "date": "22/2/2567",
          "callPut": -1195.0,
          "futurePrice": 853.9
        },
        {
          "date": "23/2/2567",
          "callPut": -325.0,
          "futurePrice": 851.5
        },
        {
          "date": "27/2/2567",
          "callPut": -415.0,
          "futurePrice": 846.4
        },
        {
          "date": "28/2/2567",
          "callPut": 253.0,
          "futurePrice": 836.7
        },
        {
          "date": "29/2/2567",
          "callPut": 353.0,
          "futurePrice": 833.5
        },
        {
          "date": "1/3/2567",
          "callPut": -13.0,
          "futurePrice": 826.2
        },
        {
          "date": "4/3/2567",
          "callPut": 18.0,
          "futurePrice": 830.2
        },
        {
          "date": "5/3/2567",
          "callPut": -5.0,
          "futurePrice": 826.1
        },
        {
          "date": "6/3/2567",
          "callPut": 82.0,
          "futurePrice": 834.7
        },
        {
          "date": "7/3/2567",
          "callPut": -284.0,
          "futurePrice": 836.3
        },
        {
          "date": "8/3/2567",
          "callPut": -265.0,
          "futurePrice": 844.1
        },
        {
          "date": "11/3/2567",
          "callPut": -294.0,
          "futurePrice": 841.1
        },
        {
          "date": "12/3/2567",
          "callPut": 33.0,
          "futurePrice": 842.7
        },
        {
          "date": "13/3/2567",
          "callPut": -342.0,
          "futurePrice": 845.4
        },
        {
          "date": "14/3/2567",
          "callPut": 385.0,
          "futurePrice": 852.8
        },
        {
          "date": "15/3/2567",
          "callPut": 612.0,
          "futurePrice": 846.3
        },
        {
          "date": "18/3/2567",
          "callPut": 181.0,
          "futurePrice": 847.3
        },
        {
          "date": "19/3/2567",
          "callPut": -358.0,
          "futurePrice": 843.4
        },
        {
          "date": "20/3/2567",
          "callPut": 172.0,
          "futurePrice": 838.7
        },
        {
          "date": "21/3/2567",
          "callPut": 667.0,
          "futurePrice": 851.1
        },
        {
          "date": "22/3/2567",
          "callPut": 1008.0,
          "futurePrice": 849.0
        },
        {
          "date": "25/3/2567",
          "callPut": 93.0,
          "futurePrice": 843.4
        },
        {
          "date": "26/3/2567",
          "callPut": -236.0,
          "futurePrice": 845.2
        },
        {
          "date": "27/3/2567",
          "callPut": 44.0,
          "futurePrice": 846.6
        },
        {
          "date": "28/3/2567",
          "callPut": 54.0,
          "futurePrice": 833.3
        },
        {
          "date": "29/3/2567",
          "callPut": -1.0,
          "futurePrice": 837.2
        },
        {
          "date": "1/4/2567",
          "callPut": 1198.0,
          "futurePrice": 840.3
        },
        {
          "date": "2/4/2567",
          "callPut": -505.0,
          "futurePrice": 841.0
        },
        {
          "date": "3/4/2567",
          "callPut": -412.0,
          "futurePrice": 839.0
        },
        {
          "date": "4/4/2567",
          "callPut": -10.0,
          "futurePrice": 840.3
        },
        {
          "date": "5/4/2567",
          "callPut": -1426.0,
          "futurePrice": 839.3
        },
        {
          "date": "9/4/2567",
          "callPut": -194.0,
          "futurePrice": 864.5
        },
        {
          "date": "10/4/2567",
          "callPut": 2507.0,
          "futurePrice": 864.1
        },
        {
          "date": "11/4/2567",
          "callPut": 965.0,
          "futurePrice": 854.3
        },
        {
          "date": "17/4/2567",
          "callPut": -1830.0,
          "futurePrice": 835.8
        },
        {
          "date": "18/4/2567",
          "callPut": -1190.0,
          "futurePrice": 830.2
        },
        {
          "date": "19/4/2567",
          "callPut": -3992.0,
          "futurePrice": 808.1
        },
        {
          "date": "22/4/2567",
          "callPut": -4201.0,
          "futurePrice": 823.0
        },
        {
          "date": "23/4/2567",
          "callPut": -1525.0,
          "futurePrice": 829.4
        },
        {
          "date": "24/4/2567",
          "callPut": -950.0,
          "futurePrice": 832.4
        },
        {
          "date": "25/4/2567",
          "callPut": -1559.0,
          "futurePrice": 833.5
        },
        {
          "date": "26/4/2567",
          "callPut": -913.0,
          "futurePrice": 831.9
        },
        {
          "date": "29/4/2567",
          "callPut": 473.0,
          "futurePrice": 833.2
        },
        {
          "date": "30/4/2567",
          "callPut": 55.0,
          "futurePrice": 835.5
        },
        {
          "date": "2/5/2567",
          "callPut": -4216.0,
          "futurePrice": 832.0
        },
        {
          "date": "3/5/2567",
          "callPut": -3719.0,
          "futurePrice": 836.7
        },
        {
          "date": "7/5/2567",
          "callPut": 860.0,
          "futurePrice": 845.7
        },
        {
          "date": "8/5/2567",
          "callPut": 565.0,
          "futurePrice": 842.0
        },
        {
          "date": "9/5/2567",
          "callPut": -549.0,
          "futurePrice": 838.7
        },
        {
          "date": "10/5/2567",
          "callPut": -182.0,
          "futurePrice": 842.7
        },
        {
          "date": "13/5/2567",
          "callPut": 208.0,
          "futurePrice": 842.5
        },
        {
          "date": "14/5/2567",
          "callPut": -62.0,
          "futurePrice": 846.3
        },
        {
          "date": "15/5/2567",
          "callPut": 62.0,
          "futurePrice": 844.9
        },
        {
          "date": "16/5/2567",
          "callPut": 532.0,
          "futurePrice": 849.3
        },
        {
          "date": "17/5/2567",
          "callPut": -2637.0,
          "futurePrice": 854.4
        },
        {
          "date": "20/5/2567",
          "callPut": -1314.0,
          "futurePrice": 853.0
        },
        {
          "date": "21/5/2567",
          "callPut": -2243.0,
          "futurePrice": 844.8
        },
        {
          "date": "23/5/2567",
          "callPut": 117.0,
          "futurePrice": 842.0
        },
        {
          "date": "24/5/2567",
          "callPut": -807.0,
          "futurePrice": 839.8
        },
        {
          "date": "27/5/2567",
          "callPut": -966.0,
          "futurePrice": 845.2
        },
        {
          "date": "28/5/2567",
          "callPut": -1299.0,
          "futurePrice": 839.7
        },
        {
          "date": "29/5/2567",
          "callPut": -1459.0,
          "futurePrice": 830.5
        },
        {
          "date": "30/5/2567",
          "callPut": -4237.0,
          "futurePrice": 829.2
        },
        {
          "date": "31/5/2567",
          "callPut": -1352.0,
          "futurePrice": 825.5
        },
        {
          "date": "4/6/2567",
          "callPut": -222.0,
          "futurePrice": 822.3
        },
        {
          "date": "5/6/2567",
          "callPut": -54.0,
          "futurePrice": 823.5
        },
        {
          "date": "6/6/2567",
          "callPut": -861.0,
          "futurePrice": 816.5
        },
        {
          "date": "7/6/2567",
          "callPut": 84.0,
          "futurePrice": 818.4
        },
        {
          "date": "10/6/2567",
          "callPut": -3570.0,
          "futurePrice": 807.7
        },
        {
          "date": "11/6/2567",
          "callPut": -797.0,
          "futurePrice": 807.3
        },
        {
          "date": "12/6/2567",
          "callPut": -1453.0,
          "futurePrice": 811.4
        },
        {
          "date": "13/6/2567",
          "callPut": -1414.0,
          "futurePrice": 808.7
        },
        {
          "date": "14/6/2567",
          "callPut": 850.0,
          "futurePrice": 803.8
        },
        {
          "date": "17/6/2567",
          "callPut": -1524.0,
          "futurePrice": 799.4
        },
        {
          "date": "18/6/2567",
          "callPut": -1357.0,
          "futurePrice": 802.4
        },
        {
          "date": "19/6/2567",
          "callPut": -36.0,
          "futurePrice": 810.9
        },
        {
          "date": "20/6/2567",
          "callPut": -740.0,
          "futurePrice": 804.6
        },
        {
          "date": "21/6/2567",
          "callPut": -114.0,
          "futurePrice": 808.8
        },
        {
          "date": "24/6/2567",
          "callPut": -1655.0,
          "futurePrice": 815.6
        },
        {
          "date": "25/6/2567",
          "callPut": -576.0,
          "futurePrice": 818.1
        },
        {
          "date": "26/6/2567",
          "callPut": 1073.0,
          "futurePrice": 817.2
        },
        {
          "date": "27/6/2567",
          "callPut": 548.0,
          "futurePrice": 805.9
        },
        {
          "date": "28/6/2567",
          "callPut": 0.0,
          "futurePrice": 803.1
        },
        {
          "date": "1/7/2567",
          "callPut": 0.0,
          "futurePrice": 802.0
        }
      ],
      openInterestData: [
        {
          "date": "27/12/2566",
          "OI": -142.0,
          "futurePrice": 870.2
        },
        {
          "date": "28/12/2566",
          "OI": -261.0,
          "futurePrice": 873.2
        },
        {
          "date": "2/1/2567",
          "OI": -298.0,
          "futurePrice": 878.8
        },
        {
          "date": "3/1/2567",
          "OI": -236.0,
          "futurePrice": 874.6
        },
        {
          "date": "4/1/2567",
          "OI": -290.0,
          "futurePrice": 875.2
        },
        {
          "date": "5/1/2567",
          "OI": -349.0,
          "futurePrice": 869.2
        },
        {
          "date": "8/1/2567",
          "OI": -380.0,
          "futurePrice": 864.7
        },
        {
          "date": "9/1/2567",
          "OI": -358.0,
          "futurePrice": 864.2
        },
        {
          "date": "10/1/2567",
          "OI": -378.0,
          "futurePrice": 863.0
        },
        {
          "date": "11/1/2567",
          "OI": -394.0,
          "futurePrice": 861.2
        },
        {
          "date": "12/1/2567",
          "OI": -354.0,
          "futurePrice": 861.2
        },
        {
          "date": "15/1/2567",
          "OI": -352.0,
          "futurePrice": 856.3
        },
        {
          "date": "16/1/2567",
          "OI": -581.0,
          "futurePrice": 851.9
        },
        {
          "date": "17/1/2567",
          "OI": -965.0,
          "futurePrice": 839.0
        },
        {
          "date": "18/1/2567",
          "OI": -937.0,
          "futurePrice": 835.9
        },
        {
          "date": "19/1/2567",
          "OI": -932.0,
          "futurePrice": 839.4
        },
        {
          "date": "22/1/2567",
          "OI": -973.0,
          "futurePrice": 832.2
        },
        {
          "date": "23/1/2567",
          "OI": -1377.0,
          "futurePrice": 825.8
        },
        {
          "date": "24/1/2567",
          "OI": -1458.0,
          "futurePrice": 840.7
        },
        {
          "date": "25/1/2567",
          "OI": -1407.0,
          "futurePrice": 836.8
        },
        {
          "date": "26/1/2567",
          "OI": -1432.0,
          "futurePrice": 834.3
        },
        {
          "date": "29/1/2567",
          "OI": -1438.0,
          "futurePrice": 836.1
        },
        {
          "date": "30/1/2567",
          "OI": -1343.0,
          "futurePrice": 835.4
        },
        {
          "date": "31/1/2567",
          "OI": -1314.0,
          "futurePrice": 829.7
        },
        {
          "date": "1/2/2567",
          "OI": -1304.0,
          "futurePrice": 830.6
        },
        {
          "date": "2/2/2567",
          "OI": -1156.0,
          "futurePrice": 843.6
        },
        {
          "date": "5/2/2567",
          "OI": -1075.0,
          "futurePrice": 839.1
        },
        {
          "date": "6/2/2567",
          "OI": -1046.0,
          "futurePrice": 847.0
        },
        {
          "date": "7/2/2567",
          "OI": -970.0,
          "futurePrice": 849.3
        },
        {
          "date": "8/2/2567",
          "OI": -961.0,
          "futurePrice": 843.5
        },
        {
          "date": "9/2/2567",
          "OI": -1048.0,
          "futurePrice": 840.2
        },
        {
          "date": "12/2/2567",
          "OI": -1087.0,
          "futurePrice": 843.6
        },
        {
          "date": "13/2/2567",
          "OI": -975.0,
          "futurePrice": 844.4
        },
        {
          "date": "14/2/2567",
          "OI": -810.0,
          "futurePrice": 836.1
        },
        {
          "date": "15/2/2567",
          "OI": -798.0,
          "futurePrice": 837.6
        },
        {
          "date": "16/2/2567",
          "OI": -915.0,
          "futurePrice": 837.8
        },
        {
          "date": "19/2/2567",
          "OI": -872.0,
          "futurePrice": 839.8
        },
        {
          "date": "20/2/2567",
          "OI": -1200.0,
          "futurePrice": 838.0
        },
        {
          "date": "21/2/2567",
          "OI": -1837.0,
          "futurePrice": 847.7
        },
        {
          "date": "22/2/2567",
          "OI": -3125.0,
          "futurePrice": 853.9
        },
        {
          "date": "23/2/2567",
          "OI": -3402.0,
          "futurePrice": 851.5
        },
        {
          "date": "27/2/2567",
          "OI": -3754.0,
          "futurePrice": 846.4
        },
        {
          "date": "28/2/2567",
          "OI": -3697.0,
          "futurePrice": 836.7
        },
        {
          "date": "29/2/2567",
          "OI": -3561.0,
          "futurePrice": 833.5
        },
        {
          "date": "1/3/2567",
          "OI": -3452.0,
          "futurePrice": 826.2
        },
        {
          "date": "4/3/2567",
          "OI": -3494.0,
          "futurePrice": 830.2
        },
        {
          "date": "5/3/2567",
          "OI": -3500.0,
          "futurePrice": 826.1
        },
        {
          "date": "6/3/2567",
          "OI": -3400.0,
          "futurePrice": 834.7
        },
        {
          "date": "7/3/2567",
          "OI": -3821.0,
          "futurePrice": 836.3
        },
        {
          "date": "8/3/2567",
          "OI": -4312.0,
          "futurePrice": 844.1
        },
        {
          "date": "11/3/2567",
          "OI": -4566.0,
          "futurePrice": 841.1
        },
        {
          "date": "12/3/2567",
          "OI": -4646.0,
          "futurePrice": 842.7
        },
        {
          "date": "13/3/2567",
          "OI": -5166.0,
          "futurePrice": 845.4
        },
        {
          "date": "14/3/2567",
          "OI": -5417.0,
          "futurePrice": 852.8
        },
        {
          "date": "15/3/2567",
          "OI": -5354.0,
          "futurePrice": 846.3
        },
        {
          "date": "18/3/2567",
          "OI": -5639.0,
          "futurePrice": 847.3
        },
        {
          "date": "19/3/2567",
          "OI": -5991.0,
          "futurePrice": 843.4
        },
        {
          "date": "20/3/2567",
          "OI": -6227.0,
          "futurePrice": 838.7
        },
        {
          "date": "21/3/2567",
          "OI": -5670.0,
          "futurePrice": 851.1
        },
        {
          "date": "22/3/2567",
          "OI": -5045.0,
          "futurePrice": 849.0
        },
        {
          "date": "25/3/2567",
          "OI": -5130.0,
          "futurePrice": 843.4
        },
        {
          "date": "26/3/2567",
          "OI": -5448.0,
          "futurePrice": 845.2
        },
        {
          "date": "27/3/2567",
          "OI": -5307.0,
          "futurePrice": 846.6
        },
        {
          "date": "28/3/2567",
          "OI": -5330.0,
          "futurePrice": 833.3
        },
        {
          "date": "29/3/2567",
          "OI": -5957.0,
          "futurePrice": 837.2
        },
        {
          "date": "1/4/2567",
          "OI": -4298.0,
          "futurePrice": 840.3
        },
        {
          "date": "2/4/2567",
          "OI": -4016.0,
          "futurePrice": 841.0
        },
        {
          "date": "3/4/2567",
          "OI": -4438.0,
          "futurePrice": 839.0
        },
        {
          "date": "4/4/2567",
          "OI": -4645.0,
          "futurePrice": 840.3
        },
        {
          "date": "5/4/2567",
          "OI": -5712.0,
          "futurePrice": 839.3
        },
        {
          "date": "9/4/2567",
          "OI": -5998.0,
          "futurePrice": 864.5
        },
        {
          "date": "10/4/2567",
          "OI": -7081.0,
          "futurePrice": 864.1
        },
        {
          "date": "11/4/2567",
          "OI": -7884.0,
          "futurePrice": 854.3
        },
        {
          "date": "17/4/2567",
          "OI": -8509.0,
          "futurePrice": 835.8
        },
        {
          "date": "18/4/2567",
          "OI": -8499.0,
          "futurePrice": 830.2
        },
        {
          "date": "19/4/2567",
          "OI": -6703.0,
          "futurePrice": 808.1
        },
        {
          "date": "22/4/2567",
          "OI": -7572.0,
          "futurePrice": 823.0
        },
        {
          "date": "23/4/2567",
          "OI": -9995.0,
          "futurePrice": 829.4
        },
        {
          "date": "24/4/2567",
          "OI": -10547.0,
          "futurePrice": 832.4
        },
        {
          "date": "25/4/2567",
          "OI": -11493.0,
          "futurePrice": 833.5
        },
        {
          "date": "26/4/2567",
          "OI": -11949.0,
          "futurePrice": 831.9
        },
        {
          "date": "29/4/2567",
          "OI": -11665.0,
          "futurePrice": 833.2
        },
        {
          "date": "30/4/2567",
          "OI": -11874.0,
          "futurePrice": 835.5
        },
        {
          "date": "2/5/2567",
          "OI": -14207.0,
          "futurePrice": 832.0
        },
        {
          "date": "3/5/2567",
          "OI": -15878.0,
          "futurePrice": 836.7
        },
        {
          "date": "7/5/2567",
          "OI": -15924.0,
          "futurePrice": 845.7
        },
        {
          "date": "8/5/2567",
          "OI": -15976.0,
          "futurePrice": 842.0
        },
        {
          "date": "9/5/2567",
          "OI": -16557.0,
          "futurePrice": 838.7
        },
        {
          "date": "10/5/2567",
          "OI": -16644.0,
          "futurePrice": 842.7
        },
        {
          "date": "13/5/2567",
          "OI": -16829.0,
          "futurePrice": 842.5
        },
        {
          "date": "14/5/2567",
          "OI": -16550.0,
          "futurePrice": 846.3
        },
        {
          "date": "15/5/2567",
          "OI": -16852.0,
          "futurePrice": 844.9
        },
        {
          "date": "16/5/2567",
          "OI": -16898.0,
          "futurePrice": 849.3
        },
        {
          "date": "17/5/2567",
          "OI": -18325.0,
          "futurePrice": 854.4
        },
        {
          "date": "20/5/2567",
          "OI": -20493.0,
          "futurePrice": 853.0
        },
        {
          "date": "21/5/2567",
          "OI": -21468.0,
          "futurePrice": 844.8
        },
        {
          "date": "23/5/2567",
          "OI": -21036.0,
          "futurePrice": 842.0
        },
        {
          "date": "24/5/2567",
          "OI": -22072.0,
          "futurePrice": 839.8
        },
        {
          "date": "27/5/2567",
          "OI": -23389.0,
          "futurePrice": 845.2
        },
        {
          "date": "28/5/2567",
          "OI": -23478.0,
          "futurePrice": 839.7
        },
        {
          "date": "29/5/2567",
          "OI": -24061.0,
          "futurePrice": 830.5
        },
        {
          "date": "30/5/2567",
          "OI": -22868.0,
          "futurePrice": 829.2
        },
        {
          "date": "31/5/2567",
          "OI": -22693.0,
          "futurePrice": 825.5
        },
        {
          "date": "4/6/2567",
          "OI": -22198.0,
          "futurePrice": 822.3
        },
        {
          "date": "5/6/2567",
          "OI": -21975.0,
          "futurePrice": 823.5
        },
        {
          "date": "6/6/2567",
          "OI": -18533.0,
          "futurePrice": 816.5
        },
        {
          "date": "7/6/2567",
          "OI": -17278.0,
          "futurePrice": 818.4
        },
        {
          "date": "10/6/2567",
          "OI": -14699.0,
          "futurePrice": 807.7
        },
        {
          "date": "11/6/2567",
          "OI": -14207.0,
          "futurePrice": 807.3
        },
        {
          "date": "12/6/2567",
          "OI": -14001.0,
          "futurePrice": 811.4
        },
        {
          "date": "13/6/2567",
          "OI": -14308.0,
          "futurePrice": 808.7
        },
        {
          "date": "14/6/2567",
          "OI": -12570.0,
          "futurePrice": 803.8
        },
        {
          "date": "17/6/2567",
          "OI": -10076.0,
          "futurePrice": 799.4
        },
        {
          "date": "18/6/2567",
          "OI": -9419.0,
          "futurePrice": 802.4
        },
        {
          "date": "19/6/2567",
          "OI": -9166.0,
          "futurePrice": 810.9
        },
        {
          "date": "20/6/2567",
          "OI": -9388.0,
          "futurePrice": 804.6
        },
        {
          "date": "21/6/2567",
          "OI": -9488.0,
          "futurePrice": 808.8
        },
        {
          "date": "24/6/2567",
          "OI": -9394.0,
          "futurePrice": 815.6
        },
        {
          "date": "25/6/2567",
          "OI": -8962.0,
          "futurePrice": 818.1
        },
        {
          "date": "26/6/2567",
          "OI": -8538.0,
          "futurePrice": 817.2
        },
        {
          "date": "27/6/2567",
          "OI": -8967.0,
          "futurePrice": 805.9
        },
        {
          "date": "28/6/2567",
          "OI": 0.0,
          "futurePrice": 803.1
        },
        {
          "date": "1/7/2567",
          "OI": 0.0,
          "futurePrice": 802.0
        }
      ]
    },
    S50U24: {
      volumeData: [
        {
          "date": "28/3/2567",
          "callPut": -135.0,
          "futurePrice": 833.3
        },
        {
          "date": "29/3/2567",
          "callPut": 44.0,
          "futurePrice": 837.2
        },
        {
          "date": "1/4/2567",
          "callPut": 54.0,
          "futurePrice": 840.3
        },
        {
          "date": "2/4/2567",
          "callPut": -343.0,
          "futurePrice": 841.0
        },
        {
          "date": "3/4/2567",
          "callPut": -21.0,
          "futurePrice": 839.0
        },
        {
          "date": "4/4/2567",
          "callPut": -175.0,
          "futurePrice": 840.3
        },
        {
          "date": "5/4/2567",
          "callPut": -386.0,
          "futurePrice": 839.3
        },
        {
          "date": "9/4/2567",
          "callPut": 228.0,
          "futurePrice": 864.5
        },
        {
          "date": "10/4/2567",
          "callPut": 156.0,
          "futurePrice": 864.1
        },
        {
          "date": "11/4/2567",
          "callPut": -171.0,
          "futurePrice": 854.3
        },
        {
          "date": "17/4/2567",
          "callPut": -295.0,
          "futurePrice": 835.8
        },
        {
          "date": "18/4/2567",
          "callPut": 66.0,
          "futurePrice": 830.2
        },
        {
          "date": "19/4/2567",
          "callPut": -1461.0,
          "futurePrice": 808.1
        },
        {
          "date": "22/4/2567",
          "callPut": -308.0,
          "futurePrice": 823.0
        },
        {
          "date": "23/4/2567",
          "callPut": -144.0,
          "futurePrice": 829.4
        },
        {
          "date": "24/4/2567",
          "callPut": 16.0,
          "futurePrice": 832.4
        },
        {
          "date": "25/4/2567",
          "callPut": -130.0,
          "futurePrice": 833.5
        },
        {
          "date": "26/4/2567",
          "callPut": 12.0,
          "futurePrice": 831.9
        },
        {
          "date": "29/4/2567",
          "callPut": 32.0,
          "futurePrice": 833.2
        },
        {
          "date": "30/4/2567",
          "callPut": -28.0,
          "futurePrice": 835.5
        },
        {
          "date": "2/5/2567",
          "callPut": -70.0,
          "futurePrice": 832.0
        },
        {
          "date": "3/5/2567",
          "callPut": -173.0,
          "futurePrice": 836.7
        },
        {
          "date": "7/5/2567",
          "callPut": -129.0,
          "futurePrice": 845.7
        },
        {
          "date": "8/5/2567",
          "callPut": 208.0,
          "futurePrice": 842.0
        },
        {
          "date": "9/5/2567",
          "callPut": 24.0,
          "futurePrice": 838.7
        },
        {
          "date": "10/5/2567",
          "callPut": 9.0,
          "futurePrice": 842.7
        },
        {
          "date": "13/5/2567",
          "callPut": 93.0,
          "futurePrice": 842.5
        },
        {
          "date": "14/5/2567",
          "callPut": -193.0,
          "futurePrice": 846.3
        },
        {
          "date": "15/5/2567",
          "callPut": 84.0,
          "futurePrice": 844.9
        },
        {
          "date": "16/5/2567",
          "callPut": -198.0,
          "futurePrice": 849.3
        },
        {
          "date": "17/5/2567",
          "callPut": -31.0,
          "futurePrice": 854.4
        },
        {
          "date": "20/5/2567",
          "callPut": -93.0,
          "futurePrice": 853.0
        },
        {
          "date": "21/5/2567",
          "callPut": 0.0,
          "futurePrice": 844.8
        },
        {
          "date": "23/5/2567",
          "callPut": -183.0,
          "futurePrice": 842.0
        },
        {
          "date": "24/5/2567",
          "callPut": -386.0,
          "futurePrice": 839.8
        },
        {
          "date": "27/5/2567",
          "callPut": 72.0,
          "futurePrice": 845.2
        },
        {
          "date": "28/5/2567",
          "callPut": -599.0,
          "futurePrice": 839.7
        },
        {
          "date": "29/5/2567",
          "callPut": -300.0,
          "futurePrice": 830.5
        },
        {
          "date": "30/5/2567",
          "callPut": -603.0,
          "futurePrice": 829.2
        },
        {
          "date": "31/5/2567",
          "callPut": 11.0,
          "futurePrice": 825.5
        },
        {
          "date": "4/6/2567",
          "callPut": 247.0,
          "futurePrice": 822.3
        },
        {
          "date": "5/6/2567",
          "callPut": -10.0,
          "futurePrice": 823.5
        },
        {
          "date": "6/6/2567",
          "callPut": -18.0,
          "futurePrice": 816.5
        },
        {
          "date": "7/6/2567",
          "callPut": 251.0,
          "futurePrice": 818.4
        },
        {
          "date": "10/6/2567",
          "callPut": -715.0,
          "futurePrice": 807.7
        },
        {
          "date": "11/6/2567",
          "callPut": 200.0,
          "futurePrice": 807.3
        },
        {
          "date": "12/6/2567",
          "callPut": -212.0,
          "futurePrice": 811.4
        },
        {
          "date": "13/6/2567",
          "callPut": -779.0,
          "futurePrice": 808.7
        },
        {
          "date": "14/6/2567",
          "callPut": 566.0,
          "futurePrice": 803.8
        },
        {
          "date": "17/6/2567",
          "callPut": -523.0,
          "futurePrice": 799.4
        },
        {
          "date": "18/6/2567",
          "callPut": -341.0,
          "futurePrice": 802.4
        },
        {
          "date": "19/6/2567",
          "callPut": -1324.0,
          "futurePrice": 810.9
        },
        {
          "date": "20/6/2567",
          "callPut": 311.0,
          "futurePrice": 804.6
        },
        {
          "date": "21/6/2567",
          "callPut": -51.0,
          "futurePrice": 808.8
        },
        {
          "date": "24/6/2567",
          "callPut": 18.0,
          "futurePrice": 815.6
        },
        {
          "date": "25/6/2567",
          "callPut": 641.0,
          "futurePrice": 818.1
        },
        {
          "date": "26/6/2567",
          "callPut": 75.0,
          "futurePrice": 817.2
        },
        {
          "date": "27/6/2567",
          "callPut": 819.0,
          "futurePrice": 805.9
        },
        {
          "date": "28/6/2567",
          "callPut": -859.0,
          "futurePrice": 803.1
        },
        {
          "date": "1/7/2567",
          "callPut": -268.0,
          "futurePrice": 802.0
        },
        {
          "date": "2/7/2567",
          "callPut": 258.0,
          "futurePrice": 793.4
        },
        {
          "date": "3/7/2567",
          "callPut": 1160.0,
          "futurePrice": 799.9
        },
        {
          "date": "4/7/2567",
          "callPut": -505.0,
          "futurePrice": 805.1
        },
        {
          "date": "5/7/2567",
          "callPut": -578.0,
          "futurePrice": 809.9
        },
        {
          "date": "8/7/2567",
          "callPut": 881.0,
          "futurePrice": 811.9
        },
        {
          "date": "9/7/2567",
          "callPut": -618.0,
          "futurePrice": 810.8
        },
        {
          "date": "10/7/2567",
          "callPut": 413.0,
          "futurePrice": 813.5
        },
        {
          "date": "11/7/2567",
          "callPut": -573.0,
          "futurePrice": 818.2
        },
        {
          "date": "12/7/2567",
          "callPut": -799.0,
          "futurePrice": 822.2
        },
        {
          "date": "15/7/2567",
          "callPut": 226.0,
          "futurePrice": 821.0
        },
        {
          "date": "16/7/2567",
          "callPut": 54.0,
          "futurePrice": 816.7
        },
        {
          "date": "17/7/2567",
          "callPut": -482.0,
          "futurePrice": 820.5
        },
        {
          "date": "18/7/2567",
          "callPut": -831.0,
          "futurePrice": 824.2
        },
        {
          "date": "19/7/2567",
          "callPut": -123.0,
          "futurePrice": 818.4
        },
        {
          "date": "23/7/2567",
          "callPut": -1146.0,
          "futurePrice": 812.7
        },
        {
          "date": "24/7/2567",
          "callPut": -526.0,
          "futurePrice": 809.2
        },
        {
          "date": "25/7/2567",
          "callPut": -2068.0,
          "futurePrice": 805.2
        },
        {
          "date": "26/7/2567",
          "callPut": -1717.0,
          "futurePrice": 816.3
        },
        {
          "date": "30/7/2567",
          "callPut": -606.0,
          "futurePrice": 817.0
        },
        {
          "date": "31/7/2567",
          "callPut": -150.0,
          "futurePrice": 827.0
        },
        {
          "date": "1/8/2567",
          "callPut": -462.0,
          "futurePrice": 825.8
        },
        {
          "date": "2/8/2567",
          "callPut": 93.0,
          "futurePrice": 816.6
        },
        {
          "date": "5/8/2567",
          "callPut": -8540.0,
          "futurePrice": 797.9
        },
        {
          "date": "6/8/2567",
          "callPut": -3372.0,
          "futurePrice": 796.0
        },
        {
          "date": "7/8/2567",
          "callPut": -2306.0,
          "futurePrice": 811.8
        },
        {
          "date": "8/8/2567",
          "callPut": -2278.0,
          "futurePrice": 807.8
        },
        {
          "date": "9/8/2567",
          "callPut": -742.0,
          "futurePrice": 813.4
        },
        {
          "date": "13/8/2567",
          "callPut": -494.0,
          "futurePrice": 812.4
        },
        {
          "date": "14/8/2567",
          "callPut": -1753.0,
          "futurePrice": 811.6
        },
        {
          "date": "15/8/2567",
          "callPut": -1995.0,
          "futurePrice": 811.6
        },
        {
          "date": "16/8/2567",
          "callPut": -1012.0,
          "futurePrice": 818.7
        },
        {
          "date": "19/8/2567",
          "callPut": 2791.0,
          "futurePrice": 832.1
        },
        {
          "date": "20/8/2567",
          "callPut": -401.0,
          "futurePrice": 834.4
        },
        {
          "date": "21/8/2567",
          "callPut": -1197.0,
          "futurePrice": 840.8
        },
        {
          "date": "22/8/2567",
          "callPut": -678.0,
          "futurePrice": 840.3
        },
        {
          "date": "23/8/2567",
          "callPut": 300.0,
          "futurePrice": 849.7
        },
        {
          "date": "26/8/2567",
          "callPut": -5162.0,
          "futurePrice": 857.6
        },
        {
          "date": "27/8/2567",
          "callPut": -258.0,
          "futurePrice": 856.7
        },
        {
          "date": "28/8/2567",
          "callPut": -1190.0,
          "futurePrice": 861.1
        },
        {
          "date": "29/8/2567",
          "callPut": 310.0,
          "futurePrice": 855.3
        },
        {
          "date": "30/8/2567",
          "callPut": -674.0,
          "futurePrice": 853.5
        },
        {
          "date": "2/9/2567",
          "callPut": -689.0,
          "futurePrice": 849.8
        },
        {
          "date": "3/9/2567",
          "callPut": -700.0,
          "futurePrice": 855.8
        },
        {
          "date": "4/9/2567",
          "callPut": -192.0,
          "futurePrice": 854.8
        },
        {
          "date": "5/9/2567",
          "callPut": 1443.0,
          "futurePrice": 890.3
        },
        {
          "date": "6/9/2567",
          "callPut": 1946.0,
          "futurePrice": 905.4
        },
        {
          "date": "9/9/2567",
          "callPut": 2382.0,
          "futurePrice": 900.0
        },
        {
          "date": "10/9/2567",
          "callPut": 754.0,
          "futurePrice": 898.8
        },
        {
          "date": "11/9/2567",
          "callPut": -1541.0,
          "futurePrice": 892.7
        },
        {
          "date": "12/9/2567",
          "callPut": -410.0,
          "futurePrice": 897.1
        },
        {
          "date": "13/9/2567",
          "callPut": 287.0,
          "futurePrice": 899.7
        },
        {
          "date": "16/9/2567",
          "callPut": 357.0,
          "futurePrice": 903.0
        },
        {
          "date": "17/9/2567",
          "callPut": -1334.0,
          "futurePrice": 904.2
        },
        {
          "date": "18/9/2567",
          "callPut": 122.0,
          "futurePrice": 903.1
        },
        {
          "date": "19/9/2567",
          "callPut": -2360.0,
          "futurePrice": 915.8
        },
        {
          "date": "20/9/2567",
          "callPut": 3682.0,
          "futurePrice": 910.3
        },
        {
          "date": "23/9/2567",
          "callPut": 681.0,
          "futurePrice": 910.4
        },
        {
          "date": "24/9/2567",
          "callPut": 3600.0,
          "futurePrice": 925.4
        },
        {
          "date": "25/9/2567",
          "callPut": 1299.0,
          "futurePrice": 922.8
        },
        {
          "date": "26/9/2567",
          "callPut": 528.0,
          "futurePrice": 921.9
        },
        {
          "date": "27/9/2567",
          "callPut": 2017.0,
          "futurePrice": 916.4
        },
        {
          "date": "30/9/2567",
          "callPut": 0.0,
          "futurePrice": 914.7
        },
        {
          "date": "1/10/2567",
          "callPut": 0.0,
          "futurePrice": 924.8
        }
      ],
      openInterestData: [
        {
          "date": "28/3/2567",
          "OI": -135.0,
          "futurePrice": 833.3
        },
        {
          "date": "29/3/2567",
          "OI": -91.0,
          "futurePrice": 837.2
        },
        {
          "date": "1/4/2567",
          "OI": -47.0,
          "futurePrice": 840.3
        },
        {
          "date": "2/4/2567",
          "OI": -391.0,
          "futurePrice": 841.0
        },
        {
          "date": "3/4/2567",
          "OI": -405.0,
          "futurePrice": 839.0
        },
        {
          "date": "4/4/2567",
          "OI": -604.0,
          "futurePrice": 840.3
        },
        {
          "date": "5/4/2567",
          "OI": -984.0,
          "futurePrice": 839.3
        },
        {
          "date": "9/4/2567",
          "OI": -719.0,
          "futurePrice": 864.5
        },
        {
          "date": "10/4/2567",
          "OI": -559.0,
          "futurePrice": 864.1
        },
        {
          "date": "11/4/2567",
          "OI": -702.0,
          "futurePrice": 854.3
        },
        {
          "date": "17/4/2567",
          "OI": -972.0,
          "futurePrice": 835.8
        },
        {
          "date": "18/4/2567",
          "OI": -1009.0,
          "futurePrice": 830.2
        },
        {
          "date": "19/4/2567",
          "OI": -1743.0,
          "futurePrice": 808.1
        },
        {
          "date": "22/4/2567",
          "OI": -1917.0,
          "futurePrice": 823.0
        },
        {
          "date": "23/4/2567",
          "OI": -2003.0,
          "futurePrice": 829.4
        },
        {
          "date": "24/4/2567",
          "OI": -2009.0,
          "futurePrice": 832.4
        },
        {
          "date": "25/4/2567",
          "OI": -2087.0,
          "futurePrice": 833.5
        },
        {
          "date": "26/4/2567",
          "OI": -2073.0,
          "futurePrice": 831.9
        },
        {
          "date": "29/4/2567",
          "OI": -2091.0,
          "futurePrice": 833.2
        },
        {
          "date": "30/4/2567",
          "OI": -2152.0,
          "futurePrice": 835.5
        },
        {
          "date": "2/5/2567",
          "OI": -2231.0,
          "futurePrice": 832.0
        },
        {
          "date": "3/5/2567",
          "OI": -2399.0,
          "futurePrice": 836.7
        },
        {
          "date": "7/5/2567",
          "OI": -2284.0,
          "futurePrice": 845.7
        },
        {
          "date": "8/5/2567",
          "OI": -2160.0,
          "futurePrice": 842.0
        },
        {
          "date": "9/5/2567",
          "OI": -2059.0,
          "futurePrice": 838.7
        },
        {
          "date": "10/5/2567",
          "OI": -2027.0,
          "futurePrice": 842.7
        },
        {
          "date": "13/5/2567",
          "OI": -1973.0,
          "futurePrice": 842.5
        },
        {
          "date": "14/5/2567",
          "OI": -2145.0,
          "futurePrice": 846.3
        },
        {
          "date": "15/5/2567",
          "OI": -2039.0,
          "futurePrice": 844.9
        },
        {
          "date": "16/5/2567",
          "OI": -2098.0,
          "futurePrice": 849.3
        },
        {
          "date": "17/5/2567",
          "OI": -1968.0,
          "futurePrice": 854.4
        },
        {
          "date": "20/5/2567",
          "OI": -2054.0,
          "futurePrice": 853.0
        },
        {
          "date": "21/5/2567",
          "OI": -2158.0,
          "futurePrice": 844.8
        },
        {
          "date": "23/5/2567",
          "OI": -2241.0,
          "futurePrice": 842.0
        },
        {
          "date": "24/5/2567",
          "OI": -2575.0,
          "futurePrice": 839.8
        },
        {
          "date": "27/5/2567",
          "OI": -2497.0,
          "futurePrice": 845.2
        },
        {
          "date": "28/5/2567",
          "OI": -2693.0,
          "futurePrice": 839.7
        },
        {
          "date": "29/5/2567",
          "OI": -2753.0,
          "futurePrice": 830.5
        },
        {
          "date": "30/5/2567",
          "OI": -3257.0,
          "futurePrice": 829.2
        },
        {
          "date": "31/5/2567",
          "OI": -3204.0,
          "futurePrice": 825.5
        },
        {
          "date": "4/6/2567",
          "OI": -3572.0,
          "futurePrice": 822.3
        },
        {
          "date": "5/6/2567",
          "OI": -3695.0,
          "futurePrice": 823.5
        },
        {
          "date": "6/6/2567",
          "OI": -3618.0,
          "futurePrice": 816.5
        },
        {
          "date": "7/6/2567",
          "OI": -3515.0,
          "futurePrice": 818.4
        },
        {
          "date": "10/6/2567",
          "OI": -2009.0,
          "futurePrice": 807.7
        },
        {
          "date": "11/6/2567",
          "OI": -1885.0,
          "futurePrice": 807.3
        },
        {
          "date": "12/6/2567",
          "OI": -2307.0,
          "futurePrice": 811.4
        },
        {
          "date": "13/6/2567",
          "OI": -2862.0,
          "futurePrice": 808.7
        },
        {
          "date": "14/6/2567",
          "OI": -2965.0,
          "futurePrice": 803.8
        },
        {
          "date": "17/6/2567",
          "OI": -2702.0,
          "futurePrice": 799.4
        },
        {
          "date": "18/6/2567",
          "OI": -2774.0,
          "futurePrice": 802.4
        },
        {
          "date": "19/6/2567",
          "OI": -2533.0,
          "futurePrice": 810.9
        },
        {
          "date": "20/6/2567",
          "OI": -2427.0,
          "futurePrice": 804.6
        },
        {
          "date": "21/6/2567",
          "OI": -2013.0,
          "futurePrice": 808.8
        },
        {
          "date": "24/6/2567",
          "OI": -1983.0,
          "futurePrice": 815.6
        },
        {
          "date": "25/6/2567",
          "OI": -1729.0,
          "futurePrice": 818.1
        },
        {
          "date": "26/6/2567",
          "OI": -1683.0,
          "futurePrice": 817.2
        },
        {
          "date": "27/6/2567",
          "OI": -2046.0,
          "futurePrice": 805.9
        },
        {
          "date": "28/6/2567",
          "OI": -2169.0,
          "futurePrice": 803.1
        },
        {
          "date": "1/7/2567",
          "OI": -1740.0,
          "futurePrice": 802.0
        },
        {
          "date": "2/7/2567",
          "OI": -1830.0,
          "futurePrice": 793.4
        },
        {
          "date": "3/7/2567",
          "OI": -1505.0,
          "futurePrice": 799.9
        },
        {
          "date": "4/7/2567",
          "OI": -1063.0,
          "futurePrice": 805.1
        },
        {
          "date": "5/7/2567",
          "OI": -1229.0,
          "futurePrice": 809.9
        },
        {
          "date": "8/7/2567",
          "OI": -810.0,
          "futurePrice": 811.9
        },
        {
          "date": "9/7/2567",
          "OI": -643.0,
          "futurePrice": 810.8
        },
        {
          "date": "10/7/2567",
          "OI": -904.0,
          "futurePrice": 813.5
        },
        {
          "date": "11/7/2567",
          "OI": -1924.0,
          "futurePrice": 818.2
        },
        {
          "date": "12/7/2567",
          "OI": -2161.0,
          "futurePrice": 822.2
        },
        {
          "date": "15/7/2567",
          "OI": -1755.0,
          "futurePrice": 821.0
        },
        {
          "date": "16/7/2567",
          "OI": -1766.0,
          "futurePrice": 816.7
        },
        {
          "date": "17/7/2567",
          "OI": -1993.0,
          "futurePrice": 820.5
        },
        {
          "date": "18/7/2567",
          "OI": -2790.0,
          "futurePrice": 824.2
        },
        {
          "date": "19/7/2567",
          "OI": -3818.0,
          "futurePrice": 818.4
        },
        {
          "date": "23/7/2567",
          "OI": -4229.0,
          "futurePrice": 812.7
        },
        {
          "date": "24/7/2567",
          "OI": -3802.0,
          "futurePrice": 809.2
        },
        {
          "date": "25/7/2567",
          "OI": -5212.0,
          "futurePrice": 805.2
        },
        {
          "date": "26/7/2567",
          "OI": -6313.0,
          "futurePrice": 816.3
        },
        {
          "date": "30/7/2567",
          "OI": -7061.0,
          "futurePrice": 817.0
        },
        {
          "date": "31/7/2567",
          "OI": -7530.0,
          "futurePrice": 827.0
        },
        {
          "date": "1/8/2567",
          "OI": -7561.0,
          "futurePrice": 825.8
        },
        {
          "date": "2/8/2567",
          "OI": -7295.0,
          "futurePrice": 816.6
        },
        {
          "date": "5/8/2567",
          "OI": -6296.0,
          "futurePrice": 797.9
        },
        {
          "date": "6/8/2567",
          "OI": -6045.0,
          "futurePrice": 796.0
        },
        {
          "date": "7/8/2567",
          "OI": -6479.0,
          "futurePrice": 811.8
        },
        {
          "date": "8/8/2567",
          "OI": -7613.0,
          "futurePrice": 807.8
        },
        {
          "date": "9/8/2567",
          "OI": -7960.0,
          "futurePrice": 813.4
        },
        {
          "date": "13/8/2567",
          "OI": -9448.0,
          "futurePrice": 812.4
        },
        {
          "date": "14/8/2567",
          "OI": -11464.0,
          "futurePrice": 811.6
        },
        {
          "date": "15/8/2567",
          "OI": -10998.0,
          "futurePrice": 811.6
        },
        {
          "date": "16/8/2567",
          "OI": -10836.0,
          "futurePrice": 818.7
        },
        {
          "date": "19/8/2567",
          "OI": -15059.0,
          "futurePrice": 832.1
        },
        {
          "date": "20/8/2567",
          "OI": -19057.0,
          "futurePrice": 834.4
        },
        {
          "date": "21/8/2567",
          "OI": -21169.0,
          "futurePrice": 840.8
        },
        {
          "date": "22/8/2567",
          "OI": -22966.0,
          "futurePrice": 840.3
        },
        {
          "date": "23/8/2567",
          "OI": -24952.0,
          "futurePrice": 849.7
        },
        {
          "date": "26/8/2567",
          "OI": -28547.0,
          "futurePrice": 857.6
        },
        {
          "date": "27/8/2567",
          "OI": -29681.0,
          "futurePrice": 856.7
        },
        {
          "date": "28/8/2567",
          "OI": -31144.0,
          "futurePrice": 861.1
        },
        {
          "date": "29/8/2567",
          "OI": -31441.0,
          "futurePrice": 855.3
        },
        {
          "date": "30/8/2567",
          "OI": -32411.0,
          "futurePrice": 853.5
        },
        {
          "date": "2/9/2567",
          "OI": -33207.0,
          "futurePrice": 849.8
        },
        {
          "date": "3/9/2567",
          "OI": -34211.0,
          "futurePrice": 855.8
        },
        {
          "date": "4/9/2567",
          "OI": -34970.0,
          "futurePrice": 854.8
        },
        {
          "date": "5/9/2567",
          "OI": -40408.0,
          "futurePrice": 890.3
        },
        {
          "date": "6/9/2567",
          "OI": -45688.0,
          "futurePrice": 905.4
        },
        {
          "date": "9/9/2567",
          "OI": -47462.0,
          "futurePrice": 900.0
        },
        {
          "date": "10/9/2567",
          "OI": -49223.0,
          "futurePrice": 898.8
        },
        {
          "date": "11/9/2567",
          "OI": -50040.0,
          "futurePrice": 892.7
        },
        {
          "date": "12/9/2567",
          "OI": -51394.0,
          "futurePrice": 897.1
        },
        {
          "date": "13/9/2567",
          "OI": -51657.0,
          "futurePrice": 899.7
        },
        {
          "date": "16/9/2567",
          "OI": -52716.0,
          "futurePrice": 903.0
        },
        {
          "date": "17/9/2567",
          "OI": -54126.0,
          "futurePrice": 904.2
        },
        {
          "date": "18/9/2567",
          "OI": -55880.0,
          "futurePrice": 903.1
        },
        {
          "date": "19/9/2567",
          "OI": -57163.0,
          "futurePrice": 915.8
        },
        {
          "date": "20/9/2567",
          "OI": -57104.0,
          "futurePrice": 910.3
        },
        {
          "date": "23/9/2567",
          "OI": -57351.0,
          "futurePrice": 910.4
        },
        {
          "date": "24/9/2567",
          "OI": -56260.0,
          "futurePrice": 925.4
        },
        {
          "date": "25/9/2567",
          "OI": -56988.0,
          "futurePrice": 922.8
        },
        {
          "date": "26/9/2567",
          "OI": -58132.0,
          "futurePrice": 921.9
        },
        {
          "date": "27/9/2567",
          "OI": -58155.0,
          "futurePrice": 916.4
        },
        {
          "date": "30/9/2567",
          "OI": 0.0,
          "futurePrice": 914.7
        },
        {
          "date": "1/10/2567",
          "OI": 0.0,
          "futurePrice": 924.8
        }
      ]
    },
    S50Z24: {
      volumeData: [
        {
          "date": "27/6/2567",
          "callPut": -80.0,
          "futurePrice": 805.9
        },
        {
          "date": "28/6/2567",
          "callPut": -105.0,
          "futurePrice": 803.1
        },
        {
          "date": "1/7/2567",
          "callPut": -58.0,
          "futurePrice": 802.0
        },
        {
          "date": "2/7/2567",
          "callPut": -398.0,
          "futurePrice": 793.4
        },
        {
          "date": "3/7/2567",
          "callPut": 166.0,
          "futurePrice": 799.9
        },
        {
          "date": "4/7/2567",
          "callPut": -245.0,
          "futurePrice": 805.1
        },
        {
          "date": "5/7/2567",
          "callPut": 120.0,
          "futurePrice": 809.9
        },
        {
          "date": "8/7/2567",
          "callPut": -117.0,
          "futurePrice": 811.9
        },
        {
          "date": "9/7/2567",
          "callPut": 33.0,
          "futurePrice": 810.8
        },
        {
          "date": "10/7/2567",
          "callPut": 46.0,
          "futurePrice": 813.5
        },
        {
          "date": "11/7/2567",
          "callPut": -39.0,
          "futurePrice": 818.2
        },
        {
          "date": "12/7/2567",
          "callPut": 99.0,
          "futurePrice": 822.2
        },
        {
          "date": "15/7/2567",
          "callPut": -227.0,
          "futurePrice": 821.0
        },
        {
          "date": "16/7/2567",
          "callPut": 43.0,
          "futurePrice": 816.7
        },
        {
          "date": "17/7/2567",
          "callPut": 5.0,
          "futurePrice": 820.5
        },
        {
          "date": "18/7/2567",
          "callPut": -14.0,
          "futurePrice": 824.2
        },
        {
          "date": "19/7/2567",
          "callPut": -11.0,
          "futurePrice": 818.4
        },
        {
          "date": "23/7/2567",
          "callPut": 374.0,
          "futurePrice": 812.7
        },
        {
          "date": "24/7/2567",
          "callPut": -72.0,
          "futurePrice": 809.2
        },
        {
          "date": "25/7/2567",
          "callPut": -216.0,
          "futurePrice": 805.2
        },
        {
          "date": "26/7/2567",
          "callPut": 68.0,
          "futurePrice": 816.3
        },
        {
          "date": "30/7/2567",
          "callPut": 4.0,
          "futurePrice": 817.0
        },
        {
          "date": "31/7/2567",
          "callPut": 42.0,
          "futurePrice": 827.0
        },
        {
          "date": "1/8/2567",
          "callPut": -624.0,
          "futurePrice": 825.8
        },
        {
          "date": "2/8/2567",
          "callPut": -184.0,
          "futurePrice": 816.6
        },
        {
          "date": "5/8/2567",
          "callPut": -1223.0,
          "futurePrice": 797.9
        },
        {
          "date": "6/8/2567",
          "callPut": -647.0,
          "futurePrice": 796.0
        },
        {
          "date": "7/8/2567",
          "callPut": -180.0,
          "futurePrice": 811.8
        },
        {
          "date": "8/8/2567",
          "callPut": -647.0,
          "futurePrice": 807.8
        },
        {
          "date": "9/8/2567",
          "callPut": -79.0,
          "futurePrice": 813.4
        },
        {
          "date": "13/8/2567",
          "callPut": -368.0,
          "futurePrice": 812.4
        },
        {
          "date": "14/8/2567",
          "callPut": 260.0,
          "futurePrice": 811.6
        },
        {
          "date": "15/8/2567",
          "callPut": 358.0,
          "futurePrice": 811.6
        },
        {
          "date": "16/8/2567",
          "callPut": -80.0,
          "futurePrice": 818.7
        },
        {
          "date": "19/8/2567",
          "callPut": 575.0,
          "futurePrice": 832.1
        },
        {
          "date": "20/8/2567",
          "callPut": 247.0,
          "futurePrice": 834.4
        },
        {
          "date": "21/8/2567",
          "callPut": 220.0,
          "futurePrice": 840.8
        },
        {
          "date": "22/8/2567",
          "callPut": -70.0,
          "futurePrice": 840.3
        },
        {
          "date": "23/8/2567",
          "callPut": -1.0,
          "futurePrice": 849.7
        },
        {
          "date": "26/8/2567",
          "callPut": 453.0,
          "futurePrice": 857.6
        },
        {
          "date": "27/8/2567",
          "callPut": -64.0,
          "futurePrice": 856.7
        },
        {
          "date": "28/8/2567",
          "callPut": -88.0,
          "futurePrice": 861.1
        },
        {
          "date": "29/8/2567",
          "callPut": 66.0,
          "futurePrice": 855.3
        },
        {
          "date": "30/8/2567",
          "callPut": 67.0,
          "futurePrice": 853.5
        },
        {
          "date": "2/9/2567",
          "callPut": -43.0,
          "futurePrice": 849.8
        },
        {
          "date": "3/9/2567",
          "callPut": 12.0,
          "futurePrice": 855.8
        },
        {
          "date": "4/9/2567",
          "callPut": -14.0,
          "futurePrice": 854.8
        },
        {
          "date": "5/9/2567",
          "callPut": 942.0,
          "futurePrice": 890.3
        },
        {
          "date": "6/9/2567",
          "callPut": 226.0,
          "futurePrice": 905.4
        },
        {
          "date": "9/9/2567",
          "callPut": 1765.0,
          "futurePrice": 900.0
        },
        {
          "date": "10/9/2567",
          "callPut": 109.0,
          "futurePrice": 898.8
        },
        {
          "date": "11/9/2567",
          "callPut": 908.0,
          "futurePrice": 892.7
        },
        {
          "date": "12/9/2567",
          "callPut": 618.0,
          "futurePrice": 897.1
        },
        {
          "date": "13/9/2567",
          "callPut": 349.0,
          "futurePrice": 899.7
        },
        {
          "date": "16/9/2567",
          "callPut": 431.0,
          "futurePrice": 903.0
        },
        {
          "date": "17/9/2567",
          "callPut": 1464.0,
          "futurePrice": 904.2
        },
        {
          "date": "18/9/2567",
          "callPut": 1462.0,
          "futurePrice": 903.1
        },
        {
          "date": "19/9/2567",
          "callPut": 2270.0,
          "futurePrice": 915.8
        },
        {
          "date": "20/9/2567",
          "callPut": -519.0,
          "futurePrice": 910.3
        },
        {
          "date": "23/9/2567",
          "callPut": 530.0,
          "futurePrice": 910.4
        },
        {
          "date": "24/9/2567",
          "callPut": 1124.0,
          "futurePrice": 925.4
        },
        {
          "date": "25/9/2567",
          "callPut": -1207.0,
          "futurePrice": 922.8
        },
        {
          "date": "26/9/2567",
          "callPut": 911.0,
          "futurePrice": 921.9
        },
        {
          "date": "27/9/2567",
          "callPut": 794.0,
          "futurePrice": 916.4
        },
        {
          "date": "30/9/2567",
          "callPut": -1834.0,
          "futurePrice": 914.7
        },
        {
          "date": "1/10/2567",
          "callPut": 476.0,
          "futurePrice": 924.8
        },
        {
          "date": "2/10/2567",
          "callPut": -4128.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/10/2567",
          "callPut": 486.0,
          "futurePrice": 911.7
        },
        {
          "date": "4/10/2567",
          "callPut": -9726.0,
          "futurePrice": 913.8
        },
        {
          "date": "7/10/2567",
          "callPut": -57.0,
          "futurePrice": 919.5
        },
        {
          "date": "8/10/2567",
          "callPut": -504.0,
          "futurePrice": 919.7
        },
        {
          "date": "9/10/2567",
          "callPut": -823.0,
          "futurePrice": 922.9
        },
        {
          "date": "10/10/2567",
          "callPut": 24.0,
          "futurePrice": 932.7
        },
        {
          "date": "11/10/2567",
          "callPut": -1317.0,
          "futurePrice": 934.3
        },
        {
          "date": "15/10/2567",
          "callPut": -1273.0,
          "futurePrice": 934.8
        },
        {
          "date": "16/10/2567",
          "callPut": -1293.0,
          "futurePrice": 948.9
        },
        {
          "date": "17/10/2567",
          "callPut": 3762.0,
          "futurePrice": 956.8
        },
        {
          "date": "18/10/2567",
          "callPut": -646.0,
          "futurePrice": 956.6
        },
        {
          "date": "21/10/2567",
          "callPut": -1559.0,
          "futurePrice": 951.8
        },
        {
          "date": "22/10/2567",
          "callPut": -2897.0,
          "futurePrice": 940.0
        },
        {
          "date": "24/10/2567",
          "callPut": -199.0,
          "futurePrice": 936.0
        },
        {
          "date": "25/10/2567",
          "callPut": -2799.0,
          "futurePrice": 939.9
        },
        {
          "date": "28/10/2567",
          "callPut": -829.0,
          "futurePrice": 929.5
        },
        {
          "date": "29/10/2567",
          "callPut": -122.0,
          "futurePrice": 929.8
        },
        {
          "date": "30/10/2567",
          "callPut": 992.0,
          "futurePrice": 923.6
        },
        {
          "date": "31/10/2567",
          "callPut": 2196.0,
          "futurePrice": 937.2
        },
        {
          "date": "1/11/2567",
          "callPut": 259.0,
          "futurePrice": 936.2
        },
        {
          "date": "4/11/2567",
          "callPut": -1241.0,
          "futurePrice": 938.8
        },
        {
          "date": "5/11/2567",
          "callPut": 1871.0,
          "futurePrice": 952.5
        },
        {
          "date": "6/11/2567",
          "callPut": -1137.0,
          "futurePrice": 944.6
        },
        {
          "date": "7/11/2567",
          "callPut": -1832.0,
          "futurePrice": 949.6
        },
        {
          "date": "8/11/2567",
          "callPut": -2150.0,
          "futurePrice": 941.1
        },
        {
          "date": "11/11/2567",
          "callPut": -706.0,
          "futurePrice": 937.5
        },
        {
          "date": "12/11/2567",
          "callPut": -1417.0,
          "futurePrice": 932.2
        },
        {
          "date": "13/11/2567",
          "callPut": -1282.0,
          "futurePrice": 936.4
        },
        {
          "date": "14/11/2567",
          "callPut": 799.0,
          "futurePrice": 935.6
        },
        {
          "date": "15/11/2567",
          "callPut": 613.0,
          "futurePrice": 932.7
        },
        {
          "date": "18/11/2567",
          "callPut": -239.0,
          "futurePrice": 940.3
        },
        {
          "date": "19/11/2567",
          "callPut": -1337.0,
          "futurePrice": 945.2
        },
        {
          "date": "20/11/2567",
          "callPut": 207.0,
          "futurePrice": 946.8
        },
        {
          "date": "21/11/2567",
          "callPut": -1161.0,
          "futurePrice": 930.2
        },
        {
          "date": "22/11/2567",
          "callPut": -551.0,
          "futurePrice": 933.8
        },
        {
          "date": "25/11/2567",
          "callPut": 72.0,
          "futurePrice": 934.4
        },
        {
          "date": "26/11/2567",
          "callPut": -447.0,
          "futurePrice": 930.8
        },
        {
          "date": "27/11/2567",
          "callPut": 1504.0,
          "futurePrice": 924.4
        },
        {
          "date": "28/11/2567",
          "callPut": -544.0,
          "futurePrice": 924.5
        },
        {
          "date": "29/11/2567",
          "callPut": 1273.0,
          "futurePrice": 920.5
        },
        {
          "date": "2/12/2567",
          "callPut": 112.0,
          "futurePrice": 929.4
        },
        {
          "date": "3/12/2567",
          "callPut": -485.0,
          "futurePrice": 939.6
        },
        {
          "date": "4/12/2567",
          "callPut": 570.0,
          "futurePrice": 936.7
        },
        {
          "date": "6/12/2567",
          "callPut": 30.0,
          "futurePrice": 938.7
        },
        {
          "date": "9/12/2567",
          "callPut": -488.0,
          "futurePrice": 934.6
        },
        {
          "date": "11/12/2567",
          "callPut": -43.0,
          "futurePrice": 933.4
        },
        {
          "date": "12/12/2567",
          "callPut": -243.0,
          "futurePrice": 931.1
        },
        {
          "date": "13/12/2567",
          "callPut": 1626.0,
          "futurePrice": 923.4
        },
        {
          "date": "16/12/2567",
          "callPut": 1005.0,
          "futurePrice": 921.2
        },
        {
          "date": "17/12/2567",
          "callPut": 1175.0,
          "futurePrice": 902.7
        },
        {
          "date": "18/12/2567",
          "callPut": 2792.0,
          "futurePrice": 906.0
        },
        {
          "date": "19/12/2567",
          "callPut": 88.0,
          "futurePrice": 891.3
        },
        {
          "date": "20/12/2567",
          "callPut": -1307.0,
          "futurePrice": 883.4
        },
        {
          "date": "23/12/2567",
          "callPut": 4418.0,
          "futurePrice": 900.0
        },
        {
          "date": "24/12/2567",
          "callPut": -1500.0,
          "futurePrice": 903.9
        },
        {
          "date": "25/12/2567",
          "callPut": -1141.0,
          "futurePrice": 908.4
        },
        {
          "date": "26/12/2567",
          "callPut": -713.0,
          "futurePrice": 901.9
        },
        {
          "date": "27/12/2567",
          "callPut": 1745.0,
          "futurePrice": 905.4
        },
        {
          "date": "30/12/2567",
          "callPut": 0.0,
          "futurePrice": 903.5
        },
        {
          "date": "31/12/2567",
          "callPut": 0.0,
          "futurePrice": null
        }
      ],
      openInterestData: [
        {
          "date": "27/6/2567",
          "OI": -80.0,
          "futurePrice": 805.9
        },
        {
          "date": "28/6/2567",
          "OI": -170.0,
          "futurePrice": 803.1
        },
        {
          "date": "1/7/2567",
          "OI": -227.0,
          "futurePrice": 802.0
        },
        {
          "date": "2/7/2567",
          "OI": -613.0,
          "futurePrice": 793.4
        },
        {
          "date": "3/7/2567",
          "OI": -414.0,
          "futurePrice": 799.9
        },
        {
          "date": "4/7/2567",
          "OI": -632.0,
          "futurePrice": 805.1
        },
        {
          "date": "5/7/2567",
          "OI": -454.0,
          "futurePrice": 809.9
        },
        {
          "date": "8/7/2567",
          "OI": -504.0,
          "futurePrice": 811.9
        },
        {
          "date": "9/7/2567",
          "OI": -453.0,
          "futurePrice": 810.8
        },
        {
          "date": "10/7/2567",
          "OI": -402.0,
          "futurePrice": 813.5
        },
        {
          "date": "11/7/2567",
          "OI": -429.0,
          "futurePrice": 818.2
        },
        {
          "date": "12/7/2567",
          "OI": -267.0,
          "futurePrice": 822.2
        },
        {
          "date": "15/7/2567",
          "OI": -410.0,
          "futurePrice": 821.0
        },
        {
          "date": "16/7/2567",
          "OI": -319.0,
          "futurePrice": 816.7
        },
        {
          "date": "17/7/2567",
          "OI": -307.0,
          "futurePrice": 820.5
        },
        {
          "date": "18/7/2567",
          "OI": -232.0,
          "futurePrice": 824.2
        },
        {
          "date": "19/7/2567",
          "OI": -281.0,
          "futurePrice": 818.4
        },
        {
          "date": "23/7/2567",
          "OI": 59.0,
          "futurePrice": 812.7
        },
        {
          "date": "24/7/2567",
          "OI": -63.0,
          "futurePrice": 809.2
        },
        {
          "date": "25/7/2567",
          "OI": -235.0,
          "futurePrice": 805.2
        },
        {
          "date": "26/7/2567",
          "OI": -187.0,
          "futurePrice": 816.3
        },
        {
          "date": "30/7/2567",
          "OI": -248.0,
          "futurePrice": 817.0
        },
        {
          "date": "31/7/2567",
          "OI": -26.0,
          "futurePrice": 827.0
        },
        {
          "date": "1/8/2567",
          "OI": -445.0,
          "futurePrice": 825.8
        },
        {
          "date": "2/8/2567",
          "OI": -593.0,
          "futurePrice": 816.6
        },
        {
          "date": "5/8/2567",
          "OI": -1517.0,
          "futurePrice": 797.9
        },
        {
          "date": "6/8/2567",
          "OI": -1602.0,
          "futurePrice": 796.0
        },
        {
          "date": "7/8/2567",
          "OI": -1938.0,
          "futurePrice": 811.8
        },
        {
          "date": "8/8/2567",
          "OI": -2428.0,
          "futurePrice": 807.8
        },
        {
          "date": "9/8/2567",
          "OI": -2458.0,
          "futurePrice": 813.4
        },
        {
          "date": "13/8/2567",
          "OI": -2711.0,
          "futurePrice": 812.4
        },
        {
          "date": "14/8/2567",
          "OI": -2563.0,
          "futurePrice": 811.6
        },
        {
          "date": "15/8/2567",
          "OI": -2457.0,
          "futurePrice": 811.6
        },
        {
          "date": "16/8/2567",
          "OI": -2480.0,
          "futurePrice": 818.7
        },
        {
          "date": "19/8/2567",
          "OI": -1937.0,
          "futurePrice": 832.1
        },
        {
          "date": "20/8/2567",
          "OI": -1957.0,
          "futurePrice": 834.4
        },
        {
          "date": "21/8/2567",
          "OI": -1915.0,
          "futurePrice": 840.8
        },
        {
          "date": "22/8/2567",
          "OI": -2003.0,
          "futurePrice": 840.3
        },
        {
          "date": "23/8/2567",
          "OI": -1871.0,
          "futurePrice": 849.7
        },
        {
          "date": "26/8/2567",
          "OI": -1221.0,
          "futurePrice": 857.6
        },
        {
          "date": "27/8/2567",
          "OI": -1086.0,
          "futurePrice": 856.7
        },
        {
          "date": "28/8/2567",
          "OI": -1485.0,
          "futurePrice": 861.1
        },
        {
          "date": "29/8/2567",
          "OI": -1952.0,
          "futurePrice": 855.3
        },
        {
          "date": "30/8/2567",
          "OI": -2024.0,
          "futurePrice": 853.5
        },
        {
          "date": "2/9/2567",
          "OI": -2250.0,
          "futurePrice": 849.8
        },
        {
          "date": "3/9/2567",
          "OI": -2429.0,
          "futurePrice": 855.8
        },
        {
          "date": "4/9/2567",
          "OI": -2620.0,
          "futurePrice": 854.8
        },
        {
          "date": "5/9/2567",
          "OI": -2792.0,
          "futurePrice": 890.3
        },
        {
          "date": "6/9/2567",
          "OI": -3491.0,
          "futurePrice": 905.4
        },
        {
          "date": "9/9/2567",
          "OI": -3219.0,
          "futurePrice": 900.0
        },
        {
          "date": "10/9/2567",
          "OI": -3490.0,
          "futurePrice": 898.8
        },
        {
          "date": "11/9/2567",
          "OI": -2942.0,
          "futurePrice": 892.7
        },
        {
          "date": "12/9/2567",
          "OI": -2648.0,
          "futurePrice": 897.1
        },
        {
          "date": "13/9/2567",
          "OI": -2526.0,
          "futurePrice": 899.7
        },
        {
          "date": "16/9/2567",
          "OI": -2126.0,
          "futurePrice": 903.0
        },
        {
          "date": "17/9/2567",
          "OI": -1301.0,
          "futurePrice": 904.2
        },
        {
          "date": "18/9/2567",
          "OI": -487.0,
          "futurePrice": 903.1
        },
        {
          "date": "19/9/2567",
          "OI": 1185.0,
          "futurePrice": 915.8
        },
        {
          "date": "20/9/2567",
          "OI": 175.0,
          "futurePrice": 910.3
        },
        {
          "date": "23/9/2567",
          "OI": 759.0,
          "futurePrice": 910.4
        },
        {
          "date": "24/9/2567",
          "OI": 958.0,
          "futurePrice": 925.4
        },
        {
          "date": "25/9/2567",
          "OI": 319.0,
          "futurePrice": 922.8
        },
        {
          "date": "26/9/2567",
          "OI": 565.0,
          "futurePrice": 921.9
        },
        {
          "date": "27/9/2567",
          "OI": 700.0,
          "futurePrice": 916.4
        },
        {
          "date": "30/9/2567",
          "OI": 219.0,
          "futurePrice": 914.7
        },
        {
          "date": "1/10/2567",
          "OI": 1156.0,
          "futurePrice": 924.8
        },
        {
          "date": "2/10/2567",
          "OI": -3366.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/10/2567",
          "OI": -3365.0,
          "futurePrice": 911.7
        },
        {
          "date": "4/10/2567",
          "OI": -12282.0,
          "futurePrice": 913.8
        },
        {
          "date": "7/10/2567",
          "OI": -13189.0,
          "futurePrice": 919.5
        },
        {
          "date": "8/10/2567",
          "OI": -14203.0,
          "futurePrice": 919.7
        },
        {
          "date": "9/10/2567",
          "OI": -15109.0,
          "futurePrice": 922.9
        },
        {
          "date": "10/10/2567",
          "OI": -16494.0,
          "futurePrice": 932.7
        },
        {
          "date": "11/10/2567",
          "OI": -18576.0,
          "futurePrice": 934.3
        },
        {
          "date": "15/10/2567",
          "OI": -20159.0,
          "futurePrice": 934.8
        },
        {
          "date": "16/10/2567",
          "OI": -21564.0,
          "futurePrice": 948.9
        },
        {
          "date": "17/10/2567",
          "OI": -22451.0,
          "futurePrice": 956.8
        },
        {
          "date": "18/10/2567",
          "OI": -24724.0,
          "futurePrice": 956.6
        },
        {
          "date": "21/10/2567",
          "OI": -24809.0,
          "futurePrice": 951.8
        },
        {
          "date": "22/10/2567",
          "OI": -25543.0,
          "futurePrice": 940.0
        },
        {
          "date": "24/10/2567",
          "OI": -25501.0,
          "futurePrice": 936.0
        },
        {
          "date": "25/10/2567",
          "OI": -25610.0,
          "futurePrice": 939.9
        },
        {
          "date": "28/10/2567",
          "OI": -24563.0,
          "futurePrice": 929.5
        },
        {
          "date": "29/10/2567",
          "OI": -24728.0,
          "futurePrice": 929.8
        },
        {
          "date": "30/10/2567",
          "OI": -24143.0,
          "futurePrice": 923.6
        },
        {
          "date": "31/10/2567",
          "OI": -23313.0,
          "futurePrice": 937.2
        },
        {
          "date": "1/11/2567",
          "OI": -23964.0,
          "futurePrice": 936.2
        },
        {
          "date": "4/11/2567",
          "OI": -24113.0,
          "futurePrice": 938.8
        },
        {
          "date": "5/11/2567",
          "OI": -24816.0,
          "futurePrice": 952.5
        },
        {
          "date": "6/11/2567",
          "OI": -26578.0,
          "futurePrice": 944.6
        },
        {
          "date": "7/11/2567",
          "OI": -27889.0,
          "futurePrice": 949.6
        },
        {
          "date": "8/11/2567",
          "OI": -27471.0,
          "futurePrice": 941.1
        },
        {
          "date": "11/11/2567",
          "OI": -27138.0,
          "futurePrice": 937.5
        },
        {
          "date": "12/11/2567",
          "OI": -26670.0,
          "futurePrice": 932.2
        },
        {
          "date": "13/11/2567",
          "OI": -26696.0,
          "futurePrice": 936.4
        },
        {
          "date": "14/11/2567",
          "OI": -25841.0,
          "futurePrice": 935.6
        },
        {
          "date": "15/11/2567",
          "OI": -25351.0,
          "futurePrice": 932.7
        },
        {
          "date": "18/11/2567",
          "OI": -25613.0,
          "futurePrice": 940.3
        },
        {
          "date": "19/11/2567",
          "OI": -27429.0,
          "futurePrice": 945.2
        },
        {
          "date": "20/11/2567",
          "OI": -25798.0,
          "futurePrice": 946.8
        },
        {
          "date": "21/11/2567",
          "OI": -25285.0,
          "futurePrice": 930.2
        },
        {
          "date": "22/11/2567",
          "OI": -25767.0,
          "futurePrice": 933.8
        },
        {
          "date": "25/11/2567",
          "OI": -24807.0,
          "futurePrice": 934.4
        },
        {
          "date": "26/11/2567",
          "OI": -25783.0,
          "futurePrice": 930.8
        },
        {
          "date": "27/11/2567",
          "OI": -24869.0,
          "futurePrice": 924.4
        },
        {
          "date": "28/11/2567",
          "OI": -23995.0,
          "futurePrice": 924.5
        },
        {
          "date": "29/11/2567",
          "OI": -22961.0,
          "futurePrice": 920.5
        },
        {
          "date": "2/12/2567",
          "OI": -22025.0,
          "futurePrice": 929.4
        },
        {
          "date": "3/12/2567",
          "OI": -23666.0,
          "futurePrice": 939.6
        },
        {
          "date": "4/12/2567",
          "OI": -24147.0,
          "futurePrice": 936.7
        },
        {
          "date": "6/12/2567",
          "OI": -23693.0,
          "futurePrice": 938.7
        },
        {
          "date": "9/12/2567",
          "OI": -23907.0,
          "futurePrice": 934.6
        },
        {
          "date": "11/12/2567",
          "OI": -23193.0,
          "futurePrice": 933.4
        },
        {
          "date": "12/12/2567",
          "OI": -21875.0,
          "futurePrice": 931.1
        },
        {
          "date": "13/12/2567",
          "OI": -20715.0,
          "futurePrice": 923.4
        },
        {
          "date": "16/12/2567",
          "OI": -18019.0,
          "futurePrice": 921.2
        },
        {
          "date": "17/12/2567",
          "OI": -14005.0,
          "futurePrice": 902.7
        },
        {
          "date": "18/12/2567",
          "OI": -10633.0,
          "futurePrice": 906.0
        },
        {
          "date": "19/12/2567",
          "OI": -8586.0,
          "futurePrice": 891.3
        },
        {
          "date": "20/12/2567",
          "OI": -6040.0,
          "futurePrice": 883.4
        },
        {
          "date": "23/12/2567",
          "OI": -3594.0,
          "futurePrice": 900.0
        },
        {
          "date": "24/12/2567",
          "OI": -4561.0,
          "futurePrice": 903.9
        },
        {
          "date": "25/12/2567",
          "OI": -5053.0,
          "futurePrice": 908.4
        },
        {
          "date": "26/12/2567",
          "OI": -5704.0,
          "futurePrice": 901.9
        },
        {
          "date": "27/12/2567",
          "OI": -6861.0,
          "futurePrice": 905.4
        },
        {
          "date": "30/12/2567",
          "OI": 0.0,
          "futurePrice": 903.5
        },
        {
          "date": "31/12/2567",
          "OI": 0.0,
          "futurePrice": null
        }
      ]
    }
  },
  2025: {
    S50H25: {
      volumeData: [
        {
          "date": "27/9/2567",
          "callPut": -125.0,
          "futurePrice": 916.4
        },
        {
          "date": "30/9/2567",
          "callPut": -341.0,
          "futurePrice": 914.7
        },
        {
          "date": "1/10/2567",
          "callPut": -107.0,
          "futurePrice": 924.8
        },
        {
          "date": "2/10/2567",
          "callPut": 407.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/10/2567",
          "callPut": -486.0,
          "futurePrice": 911.7
        },
        {
          "date": "4/10/2567",
          "callPut": -38.0,
          "futurePrice": 913.8
        },
        {
          "date": "7/10/2567",
          "callPut": 14.0,
          "futurePrice": 919.5
        },
        {
          "date": "8/10/2567",
          "callPut": -10.0,
          "futurePrice": 919.7
        },
        {
          "date": "9/10/2567",
          "callPut": -22.0,
          "futurePrice": 922.9
        },
        {
          "date": "10/10/2567",
          "callPut": -100.0,
          "futurePrice": 932.7
        },
        {
          "date": "11/10/2567",
          "callPut": -34.0,
          "futurePrice": 934.3
        },
        {
          "date": "15/10/2567",
          "callPut": -222.0,
          "futurePrice": 934.8
        },
        {
          "date": "16/10/2567",
          "callPut": 167.0,
          "futurePrice": 948.9
        },
        {
          "date": "17/10/2567",
          "callPut": -5.0,
          "futurePrice": 956.8
        },
        {
          "date": "18/10/2567",
          "callPut": 53.0,
          "futurePrice": 956.6
        },
        {
          "date": "21/10/2567",
          "callPut": 127.0,
          "futurePrice": 951.8
        },
        {
          "date": "22/10/2567",
          "callPut": -173.0,
          "futurePrice": 940.0
        },
        {
          "date": "24/10/2567",
          "callPut": 399.0,
          "futurePrice": 936.0
        },
        {
          "date": "25/10/2567",
          "callPut": 31.0,
          "futurePrice": 939.9
        },
        {
          "date": "28/10/2567",
          "callPut": 35.0,
          "futurePrice": 929.5
        },
        {
          "date": "29/10/2567",
          "callPut": 54.0,
          "futurePrice": 929.8
        },
        {
          "date": "30/10/2567",
          "callPut": 14.0,
          "futurePrice": 923.6
        },
        {
          "date": "31/10/2567",
          "callPut": 114.0,
          "futurePrice": 937.2
        },
        {
          "date": "1/11/2567",
          "callPut": 82.0,
          "futurePrice": 936.2
        },
        {
          "date": "4/11/2567",
          "callPut": -51.0,
          "futurePrice": 938.8
        },
        {
          "date": "5/11/2567",
          "callPut": 166.0,
          "futurePrice": 952.5
        },
        {
          "date": "6/11/2567",
          "callPut": -47.0,
          "futurePrice": 944.6
        },
        {
          "date": "7/11/2567",
          "callPut": 10.0,
          "futurePrice": 949.6
        },
        {
          "date": "8/11/2567",
          "callPut": 5.0,
          "futurePrice": 941.1
        },
        {
          "date": "11/11/2567",
          "callPut": 145.0,
          "futurePrice": 937.5
        },
        {
          "date": "12/11/2567",
          "callPut": 34.0,
          "futurePrice": 932.2
        },
        {
          "date": "13/11/2567",
          "callPut": -47.0,
          "futurePrice": 936.4
        },
        {
          "date": "14/11/2567",
          "callPut": 121.0,
          "futurePrice": 935.6
        },
        {
          "date": "15/11/2567",
          "callPut": 157.0,
          "futurePrice": 932.7
        },
        {
          "date": "18/11/2567",
          "callPut": 116.0,
          "futurePrice": 940.3
        },
        {
          "date": "19/11/2567",
          "callPut": -141.0,
          "futurePrice": 945.2
        },
        {
          "date": "20/11/2567",
          "callPut": -63.0,
          "futurePrice": 946.8
        },
        {
          "date": "21/11/2567",
          "callPut": -133.0,
          "futurePrice": 930.2
        },
        {
          "date": "22/11/2567",
          "callPut": -12.0,
          "futurePrice": 933.8
        },
        {
          "date": "25/11/2567",
          "callPut": -83.0,
          "futurePrice": 934.4
        },
        {
          "date": "26/11/2567",
          "callPut": 193.0,
          "futurePrice": 930.8
        },
        {
          "date": "27/11/2567",
          "callPut": 8.0,
          "futurePrice": 924.4
        },
        {
          "date": "28/11/2567",
          "callPut": -82.0,
          "futurePrice": 924.5
        },
        {
          "date": "29/11/2567",
          "callPut": 212.0,
          "futurePrice": 920.5
        },
        {
          "date": "2/12/2567",
          "callPut": 249.0,
          "futurePrice": 929.4
        },
        {
          "date": "3/12/2567",
          "callPut": 168.0,
          "futurePrice": 939.6
        },
        {
          "date": "4/12/2567",
          "callPut": 169.0,
          "futurePrice": 936.7
        },
        {
          "date": "6/12/2567",
          "callPut": 111.0,
          "futurePrice": 938.7
        },
        {
          "date": "9/12/2567",
          "callPut": 191.0,
          "futurePrice": 934.6
        },
        {
          "date": "11/12/2567",
          "callPut": 353.0,
          "futurePrice": 933.4
        },
        {
          "date": "12/12/2567",
          "callPut": 68.0,
          "futurePrice": 931.1
        },
        {
          "date": "13/12/2567",
          "callPut": 565.0,
          "futurePrice": 923.4
        },
        {
          "date": "16/12/2567",
          "callPut": 354.0,
          "futurePrice": 921.2
        },
        {
          "date": "17/12/2567",
          "callPut": 1497.0,
          "futurePrice": 902.7
        },
        {
          "date": "18/12/2567",
          "callPut": 889.0,
          "futurePrice": 906.0
        },
        {
          "date": "19/12/2567",
          "callPut": 1292.0,
          "futurePrice": 891.3
        },
        {
          "date": "20/12/2567",
          "callPut": 1656.0,
          "futurePrice": 883.4
        },
        {
          "date": "23/12/2567",
          "callPut": 2654.0,
          "futurePrice": 900.0
        },
        {
          "date": "24/12/2567",
          "callPut": 361.0,
          "futurePrice": 903.9
        },
        {
          "date": "25/12/2567",
          "callPut": 1078.0,
          "futurePrice": 908.4
        },
        {
          "date": "26/12/2567",
          "callPut": 406.0,
          "futurePrice": 901.9
        },
        {
          "date": "27/12/2567",
          "callPut": 1968.0,
          "futurePrice": 905.4
        },
        {
          "date": "30/12/2567",
          "callPut": 1002.0,
          "futurePrice": 903.5
        },
        {
          "date": "2/1/2568",
          "callPut": 1162.0,
          "futurePrice": 887.1
        },
        {
          "date": "3/1/2568",
          "callPut": 1936.0,
          "futurePrice": 892.8
        },
        {
          "date": "6/1/2568",
          "callPut": 1070.0,
          "futurePrice": 886.1
        },
        {
          "date": "7/1/2568",
          "callPut": -288.0,
          "futurePrice": 901.6
        },
        {
          "date": "8/1/2568",
          "callPut": 901.0,
          "futurePrice": 896.8
        },
        {
          "date": "9/1/2568",
          "callPut": 2432.0,
          "futurePrice": 881.8
        },
        {
          "date": "10/1/2568",
          "callPut": 875.0,
          "futurePrice": 888.3
        },
        {
          "date": "13/1/2568",
          "callPut": 1936.0,
          "futurePrice": 878.6
        },
        {
          "date": "14/1/2568",
          "callPut": 3644.0,
          "futurePrice": 868.9
        },
        {
          "date": "15/1/2568",
          "callPut": 1313.0,
          "futurePrice": 880.6
        },
        {
          "date": "16/1/2568",
          "callPut": 1761.0,
          "futurePrice": 879.6
        },
        {
          "date": "17/1/2568",
          "callPut": 1297.0,
          "futurePrice": 872.8
        },
        {
          "date": "20/1/2568",
          "callPut": -1220.0,
          "futurePrice": 873.3
        },
        {
          "date": "21/1/2568",
          "callPut": -47.0,
          "futurePrice": 881.2
        },
        {
          "date": "22/1/2568",
          "callPut": -86.0,
          "futurePrice": 886.5
        },
        {
          "date": "23/1/2568",
          "callPut": 714.0,
          "futurePrice": 873.8
        },
        {
          "date": "24/1/2568",
          "callPut": 898.0,
          "futurePrice": 881.5
        },
        {
          "date": "27/1/2568",
          "callPut": 773.0,
          "futurePrice": 871.3
        },
        {
          "date": "28/1/2568",
          "callPut": 1175.0,
          "futurePrice": 874.1
        },
        {
          "date": "29/1/2568",
          "callPut": 1366.0,
          "futurePrice": 871.1
        },
        {
          "date": "30/1/2568",
          "callPut": 1427.0,
          "futurePrice": 867.0
        },
        {
          "date": "31/1/2568",
          "callPut": 2411.0,
          "futurePrice": 854.1
        },
        {
          "date": "3/2/2568",
          "callPut": 3820.0,
          "futurePrice": 843.0
        },
        {
          "date": "4/2/2568",
          "callPut": 3945.0,
          "futurePrice": 844.9
        },
        {
          "date": "5/2/2568",
          "callPut": 878.0,
          "futurePrice": 832.5
        },
        {
          "date": "6/2/2568",
          "callPut": 85.0,
          "futurePrice": 817.0
        },
        {
          "date": "7/2/2568",
          "callPut": -2804.0,
          "futurePrice": 831.1
        },
        {
          "date": "10/2/2568",
          "callPut": -4228.0,
          "futurePrice": 823.4
        },
        {
          "date": "11/2/2568",
          "callPut": -2345.0,
          "futurePrice": 830.5
        },
        {
          "date": "13/2/2568",
          "callPut": -182.0,
          "futurePrice": 830.9
        },
        {
          "date": "14/2/2568",
          "callPut": -27.0,
          "futurePrice": 824.3
        },
        {
          "date": "17/2/2568",
          "callPut": 2121.0,
          "futurePrice": 805.1
        },
        {
          "date": "18/2/2568",
          "callPut": 2190.0,
          "futurePrice": 803.6
        },
        {
          "date": "19/2/2568",
          "callPut": 2903.0,
          "futurePrice": 804.7
        },
        {
          "date": "20/2/2568",
          "callPut": -1537.0,
          "futurePrice": 789.1
        },
        {
          "date": "21/2/2568",
          "callPut": 948.0,
          "futurePrice": 791.0
        },
        {
          "date": "24/2/2568",
          "callPut": 1410.0,
          "futurePrice": 784.0
        },
        {
          "date": "25/2/2568",
          "callPut": -4435.0,
          "futurePrice": 762.2
        },
        {
          "date": "26/2/2568",
          "callPut": 3757.0,
          "futurePrice": 780.0
        },
        {
          "date": "27/2/2568",
          "callPut": 1312.0,
          "futurePrice": 771.1
        },
        {
          "date": "28/2/2568",
          "callPut": -3368.0,
          "futurePrice": 760.6
        },
        {
          "date": "3/3/2568",
          "callPut": -1211.0,
          "futurePrice": 753.9
        },
        {
          "date": "4/3/2568",
          "callPut": -1433.0,
          "futurePrice": 742.7
        },
        {
          "date": "5/3/2568",
          "callPut": 3203.0,
          "futurePrice": 763.6
        },
        {
          "date": "6/3/2568",
          "callPut": 923.0,
          "futurePrice": 750.2
        },
        {
          "date": "7/3/2568",
          "callPut": -5534.0,
          "futurePrice": 761.7
        },
        {
          "date": "10/3/2568",
          "callPut": -1463.0,
          "futurePrice": 743.1
        },
        {
          "date": "11/3/2568",
          "callPut": 4502.0,
          "futurePrice": 748.9
        },
        {
          "date": "12/3/2568",
          "callPut": 515.0,
          "futurePrice": 730.9
        },
        {
          "date": "13/3/2568",
          "callPut": -3975.0,
          "futurePrice": 732.4
        },
        {
          "date": "14/3/2568",
          "callPut": 1000.0,
          "futurePrice": 739.9
        },
        {
          "date": "17/3/2568",
          "callPut": -828.0,
          "futurePrice": 740.1
        },
        {
          "date": "18/3/2568",
          "callPut": 216.0,
          "futurePrice": 745.1
        },
        {
          "date": "19/3/2568",
          "callPut": 1919.0,
          "futurePrice": 751.2
        },
        {
          "date": "20/3/2568",
          "callPut": 1028.0,
          "futurePrice": 745.4
        },
        {
          "date": "21/3/2568",
          "callPut": -1333.0,
          "futurePrice": 753.9
        },
        {
          "date": "24/3/2568",
          "callPut": -1184.0,
          "futurePrice": 756.6
        },
        {
          "date": "25/3/2568",
          "callPut": 1460.0,
          "futurePrice": 755.7
        },
        {
          "date": "26/3/2568",
          "callPut": 0.0,
          "futurePrice": null
        },
        {
          "date": "27/3/2568",
          "callPut": 0.0,
          "futurePrice": null
        }
      ],
      openInterestData: [
        {
          "date": "27/9/2567",
          "OI": -110.0,
          "futurePrice": 916.4
        },
        {
          "date": "30/9/2567",
          "OI": -422.0,
          "futurePrice": 914.7
        },
        {
          "date": "1/10/2567",
          "OI": -533.0,
          "futurePrice": 924.8
        },
        {
          "date": "2/10/2567",
          "OI": -21.0,
          "futurePrice": 914.6
        },
        {
          "date": "3/10/2567",
          "OI": -516.0,
          "futurePrice": 911.7
        },
        {
          "date": "4/10/2567",
          "OI": -560.0,
          "futurePrice": 913.8
        },
        {
          "date": "7/10/2567",
          "OI": -462.0,
          "futurePrice": 919.5
        },
        {
          "date": "8/10/2567",
          "OI": -448.0,
          "futurePrice": 919.7
        },
        {
          "date": "9/10/2567",
          "OI": -436.0,
          "futurePrice": 922.9
        },
        {
          "date": "10/10/2567",
          "OI": -499.0,
          "futurePrice": 932.7
        },
        {
          "date": "11/10/2567",
          "OI": -524.0,
          "futurePrice": 934.3
        },
        {
          "date": "15/10/2567",
          "OI": -714.0,
          "futurePrice": 934.8
        },
        {
          "date": "16/10/2567",
          "OI": -497.0,
          "futurePrice": 948.9
        },
        {
          "date": "17/10/2567",
          "OI": -537.0,
          "futurePrice": 956.8
        },
        {
          "date": "18/10/2567",
          "OI": -509.0,
          "futurePrice": 956.6
        },
        {
          "date": "21/10/2567",
          "OI": -610.0,
          "futurePrice": 951.8
        },
        {
          "date": "22/10/2567",
          "OI": -781.0,
          "futurePrice": 940.0
        },
        {
          "date": "24/10/2567",
          "OI": -342.0,
          "futurePrice": 936.0
        },
        {
          "date": "25/10/2567",
          "OI": -348.0,
          "futurePrice": 939.9
        },
        {
          "date": "28/10/2567",
          "OI": -250.0,
          "futurePrice": 929.5
        },
        {
          "date": "29/10/2567",
          "OI": -163.0,
          "futurePrice": 929.8
        },
        {
          "date": "30/10/2567",
          "OI": -183.0,
          "futurePrice": 923.6
        },
        {
          "date": "31/10/2567",
          "OI": -60.0,
          "futurePrice": 937.2
        },
        {
          "date": "1/11/2567",
          "OI": 0.0,
          "futurePrice": 936.2
        },
        {
          "date": "4/11/2567",
          "OI": -46.0,
          "futurePrice": 938.8
        },
        {
          "date": "5/11/2567",
          "OI": -193.0,
          "futurePrice": 952.5
        },
        {
          "date": "6/11/2567",
          "OI": -315.0,
          "futurePrice": 944.6
        },
        {
          "date": "7/11/2567",
          "OI": -298.0,
          "futurePrice": 949.6
        },
        {
          "date": "8/11/2567",
          "OI": -227.0,
          "futurePrice": 941.1
        },
        {
          "date": "11/11/2567",
          "OI": -149.0,
          "futurePrice": 937.5
        },
        {
          "date": "12/11/2567",
          "OI": -204.0,
          "futurePrice": 932.2
        },
        {
          "date": "13/11/2567",
          "OI": -95.0,
          "futurePrice": 936.4
        },
        {
          "date": "14/11/2567",
          "OI": -97.0,
          "futurePrice": 935.6
        },
        {
          "date": "15/11/2567",
          "OI": 24.0,
          "futurePrice": 932.7
        },
        {
          "date": "18/11/2567",
          "OI": 92.0,
          "futurePrice": 940.3
        },
        {
          "date": "19/11/2567",
          "OI": 146.0,
          "futurePrice": 945.2
        },
        {
          "date": "20/11/2567",
          "OI": 154.0,
          "futurePrice": 946.8
        },
        {
          "date": "21/11/2567",
          "OI": 122.0,
          "futurePrice": 930.2
        },
        {
          "date": "22/11/2567",
          "OI": -25.0,
          "futurePrice": 933.8
        },
        {
          "date": "25/11/2567",
          "OI": -2.0,
          "futurePrice": 934.4
        },
        {
          "date": "26/11/2567",
          "OI": 257.0,
          "futurePrice": 930.8
        },
        {
          "date": "27/11/2567",
          "OI": 183.0,
          "futurePrice": 924.4
        },
        {
          "date": "28/11/2567",
          "OI": 383.0,
          "futurePrice": 924.5
        },
        {
          "date": "29/11/2567",
          "OI": 695.0,
          "futurePrice": 920.5
        },
        {
          "date": "2/12/2567",
          "OI": 847.0,
          "futurePrice": 929.4
        },
        {
          "date": "3/12/2567",
          "OI": 1005.0,
          "futurePrice": 939.6
        },
        {
          "date": "4/12/2567",
          "OI": 1144.0,
          "futurePrice": 936.7
        },
        {
          "date": "6/12/2567",
          "OI": 1176.0,
          "futurePrice": 938.7
        },
        {
          "date": "9/12/2567",
          "OI": 1304.0,
          "futurePrice": 934.6
        },
        {
          "date": "11/12/2567",
          "OI": 1596.0,
          "futurePrice": 933.4
        },
        {
          "date": "12/12/2567",
          "OI": 1583.0,
          "futurePrice": 931.1
        },
        {
          "date": "13/12/2567",
          "OI": 2023.0,
          "futurePrice": 923.4
        },
        {
          "date": "16/12/2567",
          "OI": 2225.0,
          "futurePrice": 921.2
        },
        {
          "date": "17/12/2567",
          "OI": 3210.0,
          "futurePrice": 902.7
        },
        {
          "date": "18/12/2567",
          "OI": 3891.0,
          "futurePrice": 906.0
        },
        {
          "date": "19/12/2567",
          "OI": 4897.0,
          "futurePrice": 891.3
        },
        {
          "date": "20/12/2567",
          "OI": 6509.0,
          "futurePrice": 883.4
        },
        {
          "date": "23/12/2567",
          "OI": 8502.0,
          "futurePrice": 900.0
        },
        {
          "date": "24/12/2567",
          "OI": 7517.0,
          "futurePrice": 903.9
        },
        {
          "date": "25/12/2567",
          "OI": 9374.0,
          "futurePrice": 908.4
        },
        {
          "date": "26/12/2567",
          "OI": 8969.0,
          "futurePrice": 901.9
        },
        {
          "date": "27/12/2567",
          "OI": 9435.0,
          "futurePrice": 905.4
        },
        {
          "date": "30/12/2567",
          "OI": 7534.0,
          "futurePrice": 903.5
        },
        {
          "date": "2/1/2568",
          "OI": 8560.0,
          "futurePrice": 887.1
        },
        {
          "date": "3/1/2568",
          "OI": 9061.0,
          "futurePrice": 892.8
        },
        {
          "date": "6/1/2568",
          "OI": 8868.0,
          "futurePrice": 886.1
        },
        {
          "date": "7/1/2568",
          "OI": 8277.0,
          "futurePrice": 901.6
        },
        {
          "date": "8/1/2568",
          "OI": 8957.0,
          "futurePrice": 896.8
        },
        {
          "date": "9/1/2568",
          "OI": 12411.0,
          "futurePrice": 881.8
        },
        {
          "date": "10/1/2568",
          "OI": 11182.0,
          "futurePrice": 888.3
        },
        {
          "date": "13/1/2568",
          "OI": 12008.0,
          "futurePrice": 878.6
        },
        {
          "date": "14/1/2568",
          "OI": 14144.0,
          "futurePrice": 868.9
        },
        {
          "date": "15/1/2568",
          "OI": 14104.0,
          "futurePrice": 880.6
        },
        {
          "date": "16/1/2568",
          "OI": 14084.0,
          "futurePrice": 879.6
        },
        {
          "date": "17/1/2568",
          "OI": 14760.0,
          "futurePrice": 872.8
        },
        {
          "date": "20/1/2568",
          "OI": 16702.0,
          "futurePrice": 873.3
        },
        {
          "date": "21/1/2568",
          "OI": 16000.0,
          "futurePrice": 881.2
        },
        {
          "date": "22/1/2568",
          "OI": 15077.0,
          "futurePrice": 886.5
        },
        {
          "date": "23/1/2568",
          "OI": 15368.0,
          "futurePrice": 873.8
        },
        {
          "date": "24/1/2568",
          "OI": 15073.0,
          "futurePrice": 881.5
        },
        {
          "date": "27/1/2568",
          "OI": 16252.0,
          "futurePrice": 871.3
        },
        {
          "date": "28/1/2568",
          "OI": 17569.0,
          "futurePrice": 874.1
        },
        {
          "date": "29/1/2568",
          "OI": 19206.0,
          "futurePrice": 871.1
        },
        {
          "date": "30/1/2568",
          "OI": 20870.0,
          "futurePrice": 867.0
        },
        {
          "date": "31/1/2568",
          "OI": 24422.0,
          "futurePrice": 854.1
        },
        {
          "date": "3/2/2568",
          "OI": 25902.0,
          "futurePrice": 843.0
        },
        {
          "date": "4/2/2568",
          "OI": 25843.0,
          "futurePrice": 844.9
        },
        {
          "date": "5/2/2568",
          "OI": 26695.0,
          "futurePrice": 832.5
        },
        {
          "date": "6/2/2568",
          "OI": 28841.0,
          "futurePrice": 817.0
        },
        {
          "date": "7/2/2568",
          "OI": 30177.0,
          "futurePrice": 831.1
        },
        {
          "date": "10/2/2568",
          "OI": 28812.0,
          "futurePrice": 823.4
        },
        {
          "date": "11/2/2568",
          "OI": 25980.0,
          "futurePrice": 830.5
        },
        {
          "date": "13/2/2568",
          "OI": 25838.0,
          "futurePrice": 830.9
        },
        {
          "date": "14/2/2568",
          "OI": 27319.0,
          "futurePrice": 824.3
        },
        {
          "date": "17/2/2568",
          "OI": 28917.0,
          "futurePrice": 805.1
        },
        {
          "date": "18/2/2568",
          "OI": 28734.0,
          "futurePrice": 803.6
        },
        {
          "date": "19/2/2568",
          "OI": 29910.0,
          "futurePrice": 804.7
        },
        {
          "date": "20/2/2568",
          "OI": 29818.0,
          "futurePrice": 789.1
        },
        {
          "date": "21/2/2568",
          "OI": 33069.0,
          "futurePrice": 791.0
        },
        {
          "date": "24/2/2568",
          "OI": 33596.0,
          "futurePrice": 784.0
        },
        {
          "date": "25/2/2568",
          "OI": 39264.0,
          "futurePrice": 762.2
        },
        {
          "date": "26/2/2568",
          "OI": 43655.0,
          "futurePrice": 780.0
        },
        {
          "date": "27/2/2568",
          "OI": 44256.0,
          "futurePrice": 771.1
        },
        {
          "date": "28/2/2568",
          "OI": 44503.0,
          "futurePrice": 760.6
        },
        {
          "date": "3/3/2568",
          "OI": 45649.0,
          "futurePrice": 753.9
        },
        {
          "date": "4/3/2568",
          "OI": 47820.0,
          "futurePrice": 742.7
        },
        {
          "date": "5/3/2568",
          "OI": 46570.0,
          "futurePrice": 763.6
        },
        {
          "date": "6/3/2568",
          "OI": 46394.0,
          "futurePrice": 750.2
        },
        {
          "date": "7/3/2568",
          "OI": 48949.0,
          "futurePrice": 761.7
        },
        {
          "date": "10/3/2568",
          "OI": 50275.0,
          "futurePrice": 743.1
        },
        {
          "date": "11/3/2568",
          "OI": 51289.0,
          "futurePrice": 748.9
        },
        {
          "date": "12/3/2568",
          "OI": 51770.0,
          "futurePrice": 730.9
        },
        {
          "date": "13/3/2568",
          "OI": 53996.0,
          "futurePrice": 732.4
        },
        {
          "date": "14/3/2568",
          "OI": 54132.0,
          "futurePrice": 739.9
        },
        {
          "date": "17/3/2568",
          "OI": 54219.0,
          "futurePrice": 740.1
        },
        {
          "date": "18/3/2568",
          "OI": 54311.0,
          "futurePrice": 745.1
        },
        {
          "date": "19/3/2568",
          "OI": 54381.0,
          "futurePrice": 751.2
        },
        {
          "date": "20/3/2568",
          "OI": 54295.0,
          "futurePrice": 745.4
        },
        {
          "date": "21/3/2568",
          "OI": 53029.0,
          "futurePrice": 753.9
        },
        {
          "date": "24/3/2568",
          "OI": 53168.0,
          "futurePrice": 756.6
        },
        {
          "date": "25/3/2568",
          "OI": 52941.0,
          "futurePrice": 755.7
        },
        {
          "date": "26/3/2568",
          "OI": 0.0,
          "futurePrice": null
        },
        {
          "date": "27/3/2568",
          "OI": 0.0,
          "futurePrice": null
        }
      ]
    },
    S50M25: {
      volumeData: [
        {
          "date": "27/12/2567",
          "callPut": -50.0,
          "futurePrice": 905.4
        },
        {
          "date": "30/12/2567",
          "callPut": 6.0,
          "futurePrice": 903.5
        },
        {
          "date": "2/1/2568",
          "callPut": -31.0,
          "futurePrice": 887.1
        },
        {
          "date": "3/1/2568",
          "callPut": 1.0,
          "futurePrice": 892.8
        },
        {
          "date": "6/1/2568",
          "callPut": -12.0,
          "futurePrice": 886.1
        },
        {
          "date": "7/1/2568",
          "callPut": -147.0,
          "futurePrice": 901.6
        },
        {
          "date": "8/1/2568",
          "callPut": -53.0,
          "futurePrice": 896.8
        },
        {
          "date": "9/1/2568",
          "callPut": 40.0,
          "futurePrice": 881.8
        },
        {
          "date": "10/1/2568",
          "callPut": 69.0,
          "futurePrice": 888.3
        },
        {
          "date": "13/1/2568",
          "callPut": 33.0,
          "futurePrice": 878.6
        },
        {
          "date": "14/1/2568",
          "callPut": -80.0,
          "futurePrice": 868.9
        },
        {
          "date": "15/1/2568",
          "callPut": 312.0,
          "futurePrice": 880.6
        },
        {
          "date": "16/1/2568",
          "callPut": 220.0,
          "futurePrice": 879.6
        },
        {
          "date": "17/1/2568",
          "callPut": 127.0,
          "futurePrice": 872.8
        },
        {
          "date": "20/1/2568",
          "callPut": -73.0,
          "futurePrice": 873.3
        },
        {
          "date": "21/1/2568",
          "callPut": -57.0,
          "futurePrice": 881.2
        },
        {
          "date": "22/1/2568",
          "callPut": 133.0,
          "futurePrice": 886.5
        },
        {
          "date": "23/1/2568",
          "callPut": 39.0,
          "futurePrice": 873.8
        },
        {
          "date": "24/1/2568",
          "callPut": -7.0,
          "futurePrice": 881.5
        },
        {
          "date": "27/1/2568",
          "callPut": -13.0,
          "futurePrice": 871.3
        },
        {
          "date": "28/1/2568",
          "callPut": 46.0,
          "futurePrice": 874.1
        },
        {
          "date": "29/1/2568",
          "callPut": 56.0,
          "futurePrice": 871.1
        },
        {
          "date": "30/1/2568",
          "callPut": 47.0,
          "futurePrice": 867.0
        },
        {
          "date": "31/1/2568",
          "callPut": -458.0,
          "futurePrice": 854.1
        },
        {
          "date": "3/2/2568",
          "callPut": -78.0,
          "futurePrice": 843.0
        },
        {
          "date": "4/2/2568",
          "callPut": 102.0,
          "futurePrice": 844.9
        },
        {
          "date": "5/2/2568",
          "callPut": -156.0,
          "futurePrice": 832.5
        },
        {
          "date": "6/2/2568",
          "callPut": -409.0,
          "futurePrice": 817.0
        },
        {
          "date": "7/2/2568",
          "callPut": -288.0,
          "futurePrice": 831.1
        },
        {
          "date": "10/2/2568",
          "callPut": -291.0,
          "futurePrice": 823.4
        },
        {
          "date": "11/2/2568",
          "callPut": 135.0,
          "futurePrice": 830.5
        },
        {
          "date": "13/2/2568",
          "callPut": -288.0,
          "futurePrice": 830.9
        },
        {
          "date": "14/2/2568",
          "callPut": 3263.0,
          "futurePrice": 824.3
        },
        {
          "date": "17/2/2568",
          "callPut": 431.0,
          "futurePrice": 805.1
        },
        {
          "date": "18/2/2568",
          "callPut": -600.0,
          "futurePrice": 803.6
        },
        {
          "date": "19/2/2568",
          "callPut": 327.0,
          "futurePrice": 804.7
        },
        {
          "date": "20/2/2568",
          "callPut": 285.0,
          "futurePrice": 789.1
        },
        {
          "date": "21/2/2568",
          "callPut": 362.0,
          "futurePrice": 791.0
        },
        {
          "date": "24/2/2568",
          "callPut": -136.0,
          "futurePrice": 784.0
        },
        {
          "date": "25/2/2568",
          "callPut": 389.0,
          "futurePrice": 762.2
        },
        {
          "date": "26/2/2568",
          "callPut": 361.0,
          "futurePrice": 780.0
        },
        {
          "date": "27/2/2568",
          "callPut": 75.0,
          "futurePrice": 771.1
        },
        {
          "date": "28/2/2568",
          "callPut": -406.0,
          "futurePrice": 760.6
        },
        {
          "date": "3/3/2568",
          "callPut": 604.0,
          "futurePrice": 753.9
        },
        {
          "date": "4/3/2568",
          "callPut": -2321.0,
          "futurePrice": 742.7
        },
        {
          "date": "5/3/2568",
          "callPut": -1568.0,
          "futurePrice": 763.6
        },
        {
          "date": "6/3/2568",
          "callPut": 292.0,
          "futurePrice": 750.2
        },
        {
          "date": "7/3/2568",
          "callPut": -3155.0,
          "futurePrice": 761.7
        },
        {
          "date": "10/3/2568",
          "callPut": -3239.0,
          "futurePrice": 743.1
        },
        {
          "date": "11/3/2568",
          "callPut": -23.0,
          "futurePrice": 748.9
        },
        {
          "date": "12/3/2568",
          "callPut": -433.0,
          "futurePrice": 730.9
        },
        {
          "date": "13/3/2568",
          "callPut": -2817.0,
          "futurePrice": 732.4
        },
        {
          "date": "14/3/2568",
          "callPut": -2604.0,
          "futurePrice": 739.9
        },
        {
          "date": "17/3/2568",
          "callPut": -1670.0,
          "futurePrice": 740.1
        },
        {
          "date": "18/3/2568",
          "callPut": 92.0,
          "futurePrice": 745.1
        },
        {
          "date": "19/3/2568",
          "callPut": -52.0,
          "futurePrice": 751.2
        },
        {
          "date": "20/3/2568",
          "callPut": -86.0,
          "futurePrice": 745.4
        },
        {
          "date": "21/3/2568",
          "callPut": 393.0,
          "futurePrice": 753.9
        },
        {
          "date": "24/3/2568",
          "callPut": -92.0,
          "futurePrice": 756.6
        },
        {
          "date": "25/3/2568",
          "callPut": 466.0,
          "futurePrice": 755.7
        },
        {
          "date": "26/3/2568",
          "callPut": 0.0,
          "futurePrice": null
        },
        {
          "date": "27/3/2568",
          "callPut": 0.0,
          "futurePrice": null
        }
      ],
      openInterestData: [
        {
          "date": "27/12/2567",
          "OI": -49.0,
          "futurePrice": 905.4
        },
        {
          "date": "30/12/2567",
          "OI": -45.0,
          "futurePrice": 903.5
        },
        {
          "date": "2/1/2568",
          "OI": -92.0,
          "futurePrice": 887.1
        },
        {
          "date": "3/1/2568",
          "OI": -65.0,
          "futurePrice": 892.8
        },
        {
          "date": "6/1/2568",
          "OI": -59.0,
          "futurePrice": 886.1
        },
        {
          "date": "7/1/2568",
          "OI": -234.0,
          "futurePrice": 901.6
        },
        {
          "date": "8/1/2568",
          "OI": -296.0,
          "futurePrice": 896.8
        },
        {
          "date": "9/1/2568",
          "OI": -148.0,
          "futurePrice": 881.8
        },
        {
          "date": "10/1/2568",
          "OI": -20.0,
          "futurePrice": 888.3
        },
        {
          "date": "13/1/2568",
          "OI": 12.0,
          "futurePrice": 878.6
        },
        {
          "date": "14/1/2568",
          "OI": -5.0,
          "futurePrice": 868.9
        },
        {
          "date": "15/1/2568",
          "OI": 259.0,
          "futurePrice": 880.6
        },
        {
          "date": "16/1/2568",
          "OI": 441.0,
          "futurePrice": 879.6
        },
        {
          "date": "17/1/2568",
          "OI": 420.0,
          "futurePrice": 872.8
        },
        {
          "date": "20/1/2568",
          "OI": 383.0,
          "futurePrice": 873.3
        },
        {
          "date": "21/1/2568",
          "OI": 316.0,
          "futurePrice": 881.2
        },
        {
          "date": "22/1/2568",
          "OI": 281.0,
          "futurePrice": 886.5
        },
        {
          "date": "23/1/2568",
          "OI": 322.0,
          "futurePrice": 873.8
        },
        {
          "date": "24/1/2568",
          "OI": 353.0,
          "futurePrice": 881.5
        },
        {
          "date": "27/1/2568",
          "OI": 334.0,
          "futurePrice": 871.3
        },
        {
          "date": "28/1/2568",
          "OI": 380.0,
          "futurePrice": 874.1
        },
        {
          "date": "29/1/2568",
          "OI": 440.0,
          "futurePrice": 871.1
        },
        {
          "date": "30/1/2568",
          "OI": 465.0,
          "futurePrice": 867.0
        },
        {
          "date": "31/1/2568",
          "OI": 56.0,
          "futurePrice": 854.1
        },
        {
          "date": "3/2/2568",
          "OI": -233.0,
          "futurePrice": 843.0
        },
        {
          "date": "4/2/2568",
          "OI": -165.0,
          "futurePrice": 844.9
        },
        {
          "date": "5/2/2568",
          "OI": -121.0,
          "futurePrice": 832.5
        },
        {
          "date": "6/2/2568",
          "OI": -325.0,
          "futurePrice": 817.0
        },
        {
          "date": "7/2/2568",
          "OI": -571.0,
          "futurePrice": 831.1
        },
        {
          "date": "10/2/2568",
          "OI": -926.0,
          "futurePrice": 823.4
        },
        {
          "date": "11/2/2568",
          "OI": -756.0,
          "futurePrice": 830.5
        },
        {
          "date": "13/2/2568",
          "OI": -935.0,
          "futurePrice": 830.9
        },
        {
          "date": "14/2/2568",
          "OI": 1812.0,
          "futurePrice": 824.3
        },
        {
          "date": "17/2/2568",
          "OI": 2311.0,
          "futurePrice": 805.1
        },
        {
          "date": "18/2/2568",
          "OI": 2297.0,
          "futurePrice": 803.6
        },
        {
          "date": "19/2/2568",
          "OI": 2599.0,
          "futurePrice": 804.7
        },
        {
          "date": "20/2/2568",
          "OI": 2796.0,
          "futurePrice": 789.1
        },
        {
          "date": "21/2/2568",
          "OI": 3034.0,
          "futurePrice": 791.0
        },
        {
          "date": "24/2/2568",
          "OI": 3054.0,
          "futurePrice": 784.0
        },
        {
          "date": "25/2/2568",
          "OI": 3532.0,
          "futurePrice": 762.2
        },
        {
          "date": "26/2/2568",
          "OI": 3783.0,
          "futurePrice": 780.0
        },
        {
          "date": "27/2/2568",
          "OI": 3801.0,
          "futurePrice": 771.1
        },
        {
          "date": "28/2/2568",
          "OI": 4173.0,
          "futurePrice": 760.6
        },
        {
          "date": "3/3/2568",
          "OI": 4486.0,
          "futurePrice": 753.9
        },
        {
          "date": "4/3/2568",
          "OI": 3066.0,
          "futurePrice": 742.7
        },
        {
          "date": "5/3/2568",
          "OI": 3592.0,
          "futurePrice": 763.6
        },
        {
          "date": "6/3/2568",
          "OI": 3451.0,
          "futurePrice": 750.2
        },
        {
          "date": "7/3/2568",
          "OI": 3505.0,
          "futurePrice": 761.7
        },
        {
          "date": "10/3/2568",
          "OI": 1057.0,
          "futurePrice": 743.1
        },
        {
          "date": "11/3/2568",
          "OI": 1704.0,
          "futurePrice": 748.9
        },
        {
          "date": "12/3/2568",
          "OI": 1577.0,
          "futurePrice": 730.9
        },
        {
          "date": "13/3/2568",
          "OI": 331.0,
          "futurePrice": 732.4
        },
        {
          "date": "14/3/2568",
          "OI": -2013.0,
          "futurePrice": 739.9
        },
        {
          "date": "17/3/2568",
          "OI": -3067.0,
          "futurePrice": 740.1
        },
        {
          "date": "18/3/2568",
          "OI": -3411.0,
          "futurePrice": 745.1
        },
        {
          "date": "19/3/2568",
          "OI": -3696.0,
          "futurePrice": 751.2
        },
        {
          "date": "20/3/2568",
          "OI": -4075.0,
          "futurePrice": 745.4
        },
        {
          "date": "21/3/2568",
          "OI": -3900.0,
          "futurePrice": 753.9
        },
        {
          "date": "24/3/2568",
          "OI": -4570.0,
          "futurePrice": 756.6
        },
        {
          "date": "25/3/2568",
          "OI": -4549.0,
          "futurePrice": 755.7
        },
        {
          "date": "26/3/2568",
          "OI": 0.0,
          "futurePrice": null
        },
        {
          "date": "27/3/2568",
          "OI": 0.0,
          "futurePrice": null
        }
      ]
    }
  }
};


// ------------------- Crosshair Synchronization ----------------------
function syncCrosshair(sourceChart, sourceSeries, targetChart, targetSeries) {
  if (!sourceChart || !sourceSeries || !targetChart || !targetSeries) {
    console.error("syncCrosshair called with missing chart or series references.");
    return;
  }
  sourceChart.subscribeCrosshairMove((param) => {
    const dataPoint = param.seriesData.get(sourceSeries);
    if (dataPoint && typeof dataPoint.value === "number") {
      targetChart.setCrosshairPosition(dataPoint.value, dataPoint.time, targetSeries);
    } else {
      targetChart.clearCrosshairPosition();
    }
  });
}

// ------------------- Visible Range Synchronization ----------------------
function syncVisibleRange(chart1, chart2) {
  chart1.timeScale().subscribeVisibleLogicalRangeChange((range) => {
    if (range) {
      chart2.timeScale().setVisibleLogicalRange(range);
    }
  });
  chart2.timeScale().subscribeVisibleLogicalRangeChange((range) => {
    if (range) {
      chart1.timeScale().setVisibleLogicalRange(range);
    }
  });
}

// ------------------- DualAxisChart Component (Volume Chart) ----------------------
// Here we accumulate the callPut values.
const DualAxisChart = ({
  data,
  chartTitle,
  primaryLineColor = "green",
  secondaryLineColor = '#ffdf4f',
  onChartReady,
  selectedSeries
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartContainerRef = useRef(null);
  const tooltipRef = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!chartContainerRef.current) return;

    // Create a tooltip element.
    const tooltipDiv = document.createElement("div");
    tooltipDiv.style.position = "absolute";
    tooltipDiv.style.display = "none";
    tooltipDiv.style.padding = "8px";
    tooltipDiv.style.backgroundColor = "rgba(211, 211, 211, 0.8)";
    tooltipDiv.style.color = "black";
    tooltipDiv.style.borderRadius = "20px";
    tooltipDiv.style.fontSize = "13px";
    tooltipDiv.style.zIndex = "1000";
    tooltipDiv.style.pointerEvents = "none";
    tooltipDiv.style.fontWeight = "1000";
    chartContainerRef.current.appendChild(tooltipDiv);
    tooltipRef.current = tooltipDiv;

    // Create the chart.
    const newChart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      rightPriceScale: { visible: true, borderColor: "#D1D4DC", textColor: theme.palette.text.secondary, fontSize: 30 },
      leftPriceScale: { visible: true, borderColor: "#D1D4DC", textColor: theme.palette.text.secondary, fontSize: 30 },
      layout: {
        background: { color: theme.palette.table.odd },
        textColor: theme.palette.text.secondary,
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
      },
      // grid: { vertLines: { color: "#f0f0f0" }, horzLines: { color: "#f0f0f0" } },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderColor: "#D1D4DC",
        rightOffset: 5,
        barSpacing: 30,
        minBarSpacing: 10,
        tickMarkFormatter: (time) => {
          const date = new Date(time * 1000);
          return date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" });
        },
      },
      crosshair: { mode: 1, vertLine: { width: 1, color: "#758696", style: 3 }, horzLine: { visible: false } },
      handleScale: { mouseWheel: true, pinch: true, axisPressedMouseMove: true },
      handleScroll: { mouseWheel: true, pressedMouseMove: true, horzTouchDrag: true, vertTouchDrag: true },
      watermark: {
        visible: true,
        fontSize: 28,
        horzAlign: 'center',
        vertAlign: 'top',
        color: theme.palette.chart.watermark,
        text: selectedSeries,
      },
    });

    chartContainerRef.current.style.borderRadius = '10px';
    chartContainerRef.current.style.overflow = 'hidden';

    // Filter out rows with invalid futurePrice.
    const filteredData = data.filter(
      (d) => typeof d.futurePrice === "number" && d.futurePrice !== 0
    );
    // Accumulate callPut values.
    let cumulativeCallPut = 0;
    const xData = filteredData.map((d) => {
      const [day, month, year] = d.date.split("/").map(Number);
      const adjustedYear = year > 2400 ? year - 543 : year;
      const timestamp = new Date(adjustedYear, month - 1, day, 12).getTime() / 1000;
      cumulativeCallPut += d.callPut;
      return {
        time: timestamp,
        primaryValue: cumulativeCallPut,
        futurePrice: d.futurePrice,
        originalDate: `${day}/${month}/${adjustedYear}`,
      };
    });
    xData.sort((a, b) => a.time - b.time);

    // Add the primary series (accumulated Call-Put).
    const primarySeries = newChart.addLineSeries({
      color: primaryLineColor,
      priceLineVisible: false,
      // lineWidth: 2,
      priceScaleId: "right",
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      priceFormat: { type: "custom", formatter: (price) => Math.round(price).toLocaleString("en-US") },
    });
    primarySeries.setData(xData.map((p) => ({ time: p.time, value: p.primaryValue })));

    // Add the secondary series (Futures Price).
    const secondarySeries = newChart.addLineSeries({
      color: secondaryLineColor,
      priceLineVisible: false,
      // lineWidth: 2,
      priceScaleId: "left",
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      priceFormat: {
        type: "custom",
        formatter: (price) =>
          price.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      },
    });
    secondarySeries.setData(xData.map((p) => ({ time: p.time, value: p.futurePrice })));

    // Subscribe to crosshair moves to show tooltip.
    newChart.subscribeCrosshairMove((param) => {
      if (!param.point || param.time === undefined || param.point.x < 0 || param.point.y < 0) {
        tooltipRef.current.style.display = "none";
        return;
      }
      const coordinate = param.point;
      const dataPoint = xData.find((p) => p.time === param.time);
      if (dataPoint) {
        tooltipRef.current.style.display = "block";
        tooltipRef.current.style.left = `${coordinate.x + 15}px`;
        tooltipRef.current.style.top = `${coordinate.y + 15}px`;
        tooltipRef.current.style.backgroundColor = 'rgba(0,0,0, 0.4)';
        tooltipRef.current.innerHTML = `
          <div style="margin-bottom: 4px; color: ${theme.palette.text.primary}; ">วันที่ : ${dataPoint.originalDate}</div>
          <div style="color: ${primaryLineColor}">Call-Put (Accumulated): ${dataPoint.primaryValue.toLocaleString()}</div>
          <div style="color: ${secondaryLineColor}">Futures Price: ${dataPoint.futurePrice.toLocaleString()}</div>
        `;
      }
    });

    newChart.timeScale().fitContent();
    // const handleResize = () => {
    //   if (chartContainerRef.current && newChart) {
    //     newChart.resize(chartContainerRef.current.clientWidth, 400);
    //   }
    // };
    // window.addEventListener("resize", handleResize);
    // if (onChartReady) {
    //   onChartReady({ chart: newChart, primarySeries: primarySeries });
    // }
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    //   if (tooltipRef.current) tooltipRef.current.remove();
    //   newChart.remove();
    // };
    const resizeObserver = new ResizeObserver(() => {
      if (chartContainerRef.current && newChart) {
        newChart.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight);
      }
    });

    resizeObserver.observe(chartContainerRef.current); // เริ่มสังเกตการเปลี่ยนขนาด

    newChart.timeScale().fitContent();

    if (onChartReady) {
      onChartReady({ chart: newChart });
    }

    return () => {
      resizeObserver.disconnect(); // ล้าง observer ตอน component unmount
      if (tooltipRef.current) tooltipRef.current.remove();
      newChart.remove();
    };
  }, [data, primaryLineColor, secondaryLineColor, onChartReady, chartContainerRef]);

  return (
    <div style={{
      marginBottom: "10px",
      backgroundColor: theme.palette.background.sidebar, borderRadius: '10px', padding: '10px'
    }}>
      {/* <h2 style={{ display: "flex", alignItems: "center", gap: "12px" }}> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: isMobile ? '4px' : "8px",
          padding: isMobile ? '6px' : "12px",
          background: colors.surface[20],
          borderRadius: "8px",
          marginBottom: "16px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {/* {chartTitle} : */}
        <Typography sx={{minWidth: isMobile ? '55px' : 'unset'}} variant={isMobile ? 'h6' :'h5'}>{chartTitle} :</Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: isMobile ? '14px' : "18px" }}>
          <div
            style={{
              width: "30px",
              height: "4px",
              backgroundColor: primaryLineColor,
              // marginRight: "8px"
            }}
          />
          {/* <span>Call-Put</span> */}
          <Typography variant={isMobile ? 'h6' :'h5'}>Call-Put (Accumulated)</Typography>

        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px", fontSize: isMobile ? '14px' : "18px" }}>
          <div
            style={{
              width: "30px",
              height: "4px",
              backgroundColor: secondaryLineColor,
              // marginRight: "8px"
            }}
          />
          {/* <span>Futures Price</span> */}
          <Typography variant={isMobile ? 'h6' :'h5'}>Futures Price</Typography>
        </div>
      </div>
      {/* </h2> */}
      <div
        ref={chartContainerRef}
        style={{ height: "100%", width: "100%", maxHeight: '300px', position: "relative" }}
      />
    </div>
  );
};

// ------------------- DualAxisChart1 Component (OI Chart) ----------------------
// Here we accumulate the OI values.
const DualAxisChart1 = ({
  data,
  chartTitle,
  primaryLineColor = "blue",
  secondaryLineColor = '#ffdf4f',
  onChartReady,
  selectedSeries
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const chartContainerRef = useRef(null);
  const tooltipRef = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!chartContainerRef.current) return;
    const tooltipDiv = document.createElement("div");
    tooltipDiv.style.position = "absolute";
    tooltipDiv.style.display = "none";
    tooltipDiv.style.padding = "8px";
    tooltipDiv.style.backgroundColor = "rgba(211, 211, 211, 0.8)";
    tooltipDiv.style.color = "black";
    tooltipDiv.style.borderRadius = "20px";
    tooltipDiv.style.fontSize = "13px";
    tooltipDiv.style.zIndex = "1000";
    tooltipDiv.style.pointerEvents = "none";
    tooltipDiv.style.fontWeight = "1000";
    chartContainerRef.current.appendChild(tooltipDiv);
    tooltipRef.current = tooltipDiv;

    const newChart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      rightPriceScale: { visible: true, borderColor: "#D1D4DC", textColor: theme.palette.text.secondary, fontSize: 30 },
      leftPriceScale: { visible: true, borderColor: "#D1D4DC", textColor: theme.palette.text.secondary, fontSize: 30 },
      layout: {
        background: { color: theme.palette.table.odd },
        textColor: theme.palette.text.secondary,
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
      },
      // grid: { vertLines: { color: "#f0f0f0" }, horzLines: { color: "#f0f0f0" } },
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        borderColor: "#D1D4DC",
        rightOffset: 5,
        barSpacing: 30,
        minBarSpacing: 10,
        tickMarkFormatter: (time) => {
          const date = new Date(time * 1000);
          return date.toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "numeric" });
        },
      },
      crosshair: { mode: 1, vertLine: { width: 1, color: "#758696", style: 3 }, horzLine: { visible: false } },
      handleScale: { mouseWheel: true, pinch: true, axisPressedMouseMove: true },
      handleScroll: { mouseWheel: true, pressedMouseMove: true, horzTouchDrag: true, vertTouchDrag: true },
      watermark: {
        visible: true,
        fontSize: 28,
        horzAlign: 'center',
        vertAlign: 'top',
        color: theme.palette.chart.watermark,
        text: selectedSeries,
      },
    });



    chartContainerRef.current.style.borderRadius = '10px';
    chartContainerRef.current.style.overflow = 'hidden';

    const filteredData = data.filter(
      (d) => typeof d.futurePrice === "number" && d.futurePrice !== 0
    );
    let cumulativeOI = 0;
    const xData = filteredData.map((d) => {
      const [day, month, year] = d.date.split("/").map(Number);
      const adjustedYear = year > 2400 ? year - 543 : year;
      const timestamp = new Date(adjustedYear, month - 1, day, 12).getTime() / 1000;
      cumulativeOI += d.OI;
      return {
        time: timestamp,
        primaryValue: cumulativeOI,
        futurePrice: d.futurePrice,
        originalDate: `${day}/${month}/${adjustedYear}`,
      };
    });
    xData.sort((a, b) => a.time - b.time);

    const primarySeries = newChart.addLineSeries({
      color: primaryLineColor,
      priceLineVisible: false,
      // lineWidth: 2,
      priceScaleId: "right",
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      priceFormat: { type: "custom", formatter: (price) => Math.round(price).toLocaleString("en-US") },
    });
    primarySeries.setData(xData.map((p) => ({ time: p.time, value: p.primaryValue })));

    const secondarySeries = newChart.addLineSeries({
      color: secondaryLineColor,
      priceLineVisible: false,
      // lineWidth: 2,
      priceScaleId: "left",
      crosshairMarkerVisible: true,
      crosshairMarkerRadius: 4,
      priceFormat: {
        type: "custom",
        formatter: (price) =>
          price.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      },
    });
    secondarySeries.setData(xData.map((p) => ({ time: p.time, value: p.futurePrice })));

    newChart.subscribeCrosshairMove((param) => {
      if (!param.point || param.time === undefined || param.point.x < 0 || param.point.y < 0) {
        tooltipRef.current.style.display = "none";
        return;
      }
      const coordinate = param.point;
      const dataPoint = xData.find((p) => p.time === param.time);
      if (dataPoint) {
        tooltipRef.current.style.display = "block";
        tooltipRef.current.style.left = `${coordinate.x + 15}px`;
        tooltipRef.current.style.top = `${coordinate.y + 15}px`;
        tooltipRef.current.style.backgroundColor = 'rgba(0,0,0, 0.4)';
        tooltipRef.current.innerHTML = `
          <div style="margin-bottom: 4px; color: ${theme.palette.text.primary}; ">วันที่ : ${dataPoint.originalDate}</div>
          <div style="color: ${primaryLineColor}">Open Interest (Accumulated): ${dataPoint.primaryValue.toLocaleString()}</div>
          <div style="color: ${secondaryLineColor}">Futures Price: ${dataPoint.futurePrice.toLocaleString()}</div>
        `;
      }
    });

    newChart.timeScale().fitContent();
    // const handleResize = () => {
    //   if (chartContainerRef.current && newChart) {
    //     newChart.resize(chartContainerRef.current.clientWidth, 400);
    //   }
    // };
    // window.addEventListener("resize", handleResize);
    // if (onChartReady) {
    //   onChartReady({ chart: newChart, primarySeries: primarySeries });
    // }
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    //   if (tooltipRef.current) tooltipRef.current.remove();
    //   newChart.remove();
    // };
    const resizeObserver = new ResizeObserver(() => {
      if (chartContainerRef.current && newChart) {
        newChart.resize(chartContainerRef.current.clientWidth, chartContainerRef.current.clientHeight);
      }
    });

    resizeObserver.observe(chartContainerRef.current); // เริ่มสังเกตการเปลี่ยนขนาด

    newChart.timeScale().fitContent();

    if (onChartReady) {
      onChartReady({ chart: newChart });
    }

    return () => {
      resizeObserver.disconnect(); // ล้าง observer ตอน component unmount
      if (tooltipRef.current) tooltipRef.current.remove();
      newChart.remove();
    };

  }, [data, primaryLineColor, secondaryLineColor, onChartReady, chartContainerRef]);

  return (
    <div style={{ backgroundColor: theme.palette.background.sidebar, borderRadius: '5px', padding: '10px' }}>
      {/* <h2 style={{ display: "flex", alignItems: "center", gap: "12px" }}> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: isMobile ? '4px' : "8px",
          padding: isMobile ? '6px' : "12px",
          background: colors.surface[20],
          borderRadius: "8px",
          marginBottom: "16px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <Typography sx={{minWidth: isMobile ? '55px' : 'unset'}} variant={isMobile ? 'h6' :'h5'}>{chartTitle} :</Typography>
        <div style={{ display: "flex", alignItems: "center", gap: "8px", fontSize: isMobile ? '14px' : "18px" }}>
          <div
            style={{
              width: "30px",
              height: "4px",
              backgroundColor: primaryLineColor,
              // marginRight: "8px",
            }}
          />
          {/* <span>Open Interest</span> */}
          <Typography variant={isMobile ? 'h6' :'h5'}>Open Interest (Accumulated)</Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px", fontSize: isMobile ? '14px' : "18px" }}>
          <div
            style={{
              width: "30px",
              height: "4px",
              backgroundColor: secondaryLineColor,
              // marginRight: "8px",
            }}
          />
          {/* <span>Futures Price</span> */}
          <Typography variant={isMobile ? 'h6' :'h5'}>Futures Price</Typography>
        </div>
      </div>
      {/* </h2> */}
      <div
        ref={chartContainerRef}
        style={{ height: "100%", width: "100%", maxHeight: '300px', position: "relative" }}
      />
    </div>
  );
};

// ------------------- Main App ----------------------
const App = () => {
  const [selectedYear, setSelectedYear] = useState(2020);
  const [selectedSeries, setSelectedSeries] = useState(Object.keys(symbolData[2020])[0]);

  // We'll store our chart instances (each as an object { chart, primarySeries }).
  const chart1Ref = useRef(null); // For Volume chart
  const chart2Ref = useRef(null); // For OI chart

  const isMobile = useIsMobile();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Function to update synchronization of crosshair and visible range.
  const updateSync = () => {
    if (chart1Ref.current && chart2Ref.current) {
      const { chart: volumeChart, primarySeries: volumePrimary } = chart1Ref.current;
      const { chart: oiChart, primarySeries: oiPrimary } = chart2Ref.current;
      // Sync crosshair in both directions.
      syncCrosshair(volumeChart, volumePrimary, oiChart, oiPrimary);
      syncCrosshair(oiChart, oiPrimary, volumeChart, volumePrimary);
      // Sync visible time range.
      syncVisibleRange(volumeChart, oiChart);
    }
  };

  // Call updateSync whenever selectedYear or selectedSeries changes.
  useEffect(() => {
    updateSync();
  }, [selectedYear, selectedSeries]);

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setSelectedSeries(Object.keys(symbolData[year])[0]);
  };

  const handleSeriesChange = (series) => {
    setSelectedSeries(series);
  };

  return (
    <div>
      <Box sx={{ width: "90%", height: '100%', margin: "auto", backgroundColor: theme.palette.background.sidebar, boxShadow: theme.palette.shadow.primary, padding: 1, borderRadius: '10px' }}>
        <div
          style={{
            display: "flex",
            justifyContent: 'center',
            margin: '10px',
            padding: "15px",
            backgroundColor: colors.surface[20],
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
        >
          <div>
            <FormControl sx={{
              minWidth: 120,
              ".MuiFormLabel-root": {
                backgroundColor: colors.surface[20],
                padding: '0px 5px 0px 5px',
              },
            }}>
              <InputLabel id="yearSelect-label">เลือกปี</InputLabel>
              <Select
                labelId="yearSelect-label"
                // label="เลือกปี"
                id="yearSelect"
                size='small'
                value={selectedYear}
                onChange={(e) => handleYearChange(Number(e.target.value))}
                sx={{ borderRadius: "5px" }}
              >
                {Object.keys(symbolData).map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <FormControl sx={{
              minWidth: 120, marginLeft: 2,
              ".MuiFormLabel-root": {
                backgroundColor: colors.surface[20],
                padding: '0px 5px 0px 5px',
              },

            }}>
              <InputLabel id="seriesSelect-label">Series</InputLabel>
              <Select
                labelId="seriesSelect-label"
                // label="Series"
                size='small'
                id="seriesSelect"
                value={selectedSeries}
                onChange={(e) => handleSeriesChange(e.target.value)}
                sx={{ borderRadius: "5px" }}
              >
                {Object.keys(symbolData[selectedYear] || {}).map((series) => (
                  <MenuItem key={series} value={series}>
                    {series}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        {/* Remount the chart components when selectedYear or selectedSeries change */}
        <div key={`${selectedYear}-${selectedSeries}`}>
          <DualAxisChart
            data={symbolData[selectedYear][selectedSeries].volumeData}
            chartTitle="Volume"
            primaryLineColor={colors.greenChart[100]}
            secondaryLineColor={colors.redChart[300]}
            onChartReady={({ chart, primarySeries }) => {
              chart1Ref.current = { chart, primarySeries };
              updateSync();
            }}
            selectedSeries={selectedSeries}
          />
        </div>

        <div key={`${selectedYear}-${selectedSeries}-oi`}>
          <DualAxisChart1
            data={symbolData[selectedYear][selectedSeries].openInterestData}
            chartTitle="OI"
            primaryLineColor={colors.yellowChart[100]}
            secondaryLineColor={colors.redChart[300]}
            onChartReady={({ chart, primarySeries }) => {
              chart2Ref.current = { chart, primarySeries };
              updateSync();
            }}
            selectedSeries={selectedSeries}
          />
        </div>
      </Box>
    </div>
  );
};

export default App;