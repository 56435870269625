import { useEffect } from "react"

import { __fetching_data__ } from '../../components/api.js';
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, Paper, ButtonGroup, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import Preloading from "../../components/Preloading.jsx";
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// import './index.css'
import BidAskComponent2 from "./bidask.jsx";
import { ColorType, createChart, Rectangle } from 'lightweight-charts';
import { tokens } from "../../theme";

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

let globalList = []

export default function HisDayTrade() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const csrfToken = useSelector((state) => state.csrfToken);
    const [idxSelect, setIdxSelect] = useState(-1)
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [detailData, setDetailData] = useState([])
    const [selectDate, setSelectDate] = useState()

    const [selectTime, setSelectTime] = useState()
    const [strDate, setStrDate] = useState('')
    const [strTime, setStrTime] = useState('10:00')
    const [onClickTable, setOnClickTable] = useState(false)
    const [selectSymbol, setSelectSymbol] = useState('')
    const [loading, setLoading] = useState(false);

    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isPTablet = useIsPortraitTablet();


    let [excList, setExcList] = useState([])
    let localExcList = []
    // let excList = []

    const [stHour, stMinute] = [10, 0];
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const formatted_now = now.format('YYYY-MM-DD HH:mm:ss');
    const today_value = dayjs(today).subtract(0, 'day');
    const current_time = dayjs().set('hour', stHour).set('minute', stMinute);
    const MAX_ROW = 20;
    let counter = 0;
    let allData = [];
    let [excData, setExecData] = useState([]);
    let [uiExcBtn, setUIExcBtn] = useState([]);
    const [showChart, setShowChart] = useState(false);

    const [selectDate2, setSelectDate2] = useState(today_value);
    // let excData = [];

    const formatTime = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };

    const handlePlotGraph = () => {
        setShowChart(true); // เมื่อกดแสดง Chart
        onClick(); // เรียกฟังก์ชันเดิมของคุณ
    };

    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };

    function sleep(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    const displayData2 = async () => {
        const start = 0
        const end = allData.length
        let counter = 0;
        for (let i = start; i < end; i++) {
            let symbol = allData[i][0]
            let indexList = excData.filter(e => e[0] == symbol)
            let isDisplay = true
            if (indexList.length > 0) {
                let gIndex = globalList.filter(e => e.indexOf(indexList[0][1]) != -1)
                // if (globalList.indexOf(indexList[0]) != -1) {
                if (gIndex.length > 0) {
                    isDisplay = false
                    // counterDisplay++;
                    continue;
                }
            }
            if (counter < (MAX_ROW / 2)) {
                setData1(e => {
                    e[counter] = allData[i]
                    return ([...e])
                })
            } else {
                setData2(e => {
                    let row2 = counter % 10
                    e[row2] = allData[i]
                    return ([...e])
                })
            }
            setIdxSelect(counter);
            await sleep(1000);
            if (counter == (MAX_ROW) - 1) {
                counter = 0
            } else {
                counter++;
            }
        }
    }


    // const fetchData = async () => {
    //     const url = `${base_api_url}/his_day_trade?date=${strDate}&time=${strTime}`
    //     // console.log(url)
    //     const response = await __fetching_data__({ url: url, method: "GET", headers: { "xsrf-token": csrfToken } });
    //     // console.log('response', response)
    //     // setAllData(response.data)
    //     allData = response.data.data
    //     // excData = response.data.exc
    //     // allData[0] = ['AAI', 'BUY', 175, '927.1000', '2024.12.03 09:45.001']
    //     if (response.status) {
    //         // display3()
    //         displayData2()
    //         // const d1 = []
    //         // const d2 = []
    //         // for (let i = 0; i < response.data.length; i++) {
    //         // let counterDisplay = 0;
    //         // for (let i = 0; i < MAX_ROW; i++) {
    //         //     let symbol = allData[i][0]
    //         //     let indexList = excData.filter(e => e[0] == symbol)
    //         //     let isDisplay = true
    //         //     if (indexList.length > 0) {
    //         //         if (excList.indexOf(indexList[0][1]) != -1) {
    //         //             isDisplay = false
    //         //             counterDisplay++;
    //         //             continue;
    //         //         }
    //         //     }
    //         //     if (i < MAX_ROW / 2) {
    //         //         // d1.push(response.data[i])
    //         //         setData1(e => {
    //         //             return ([...e, allData[i]])
    //         //         })
    //         //     } else {
    //         //         // d2.push(response.data[i])
    //         //         setData2(e => {
    //         //             return ([...e, allData[i]])
    //         //         })
    //         //     }
    //         //     setIdxSelect(i-counterDisplay);
    //         //     await sleep(1000);
    //         // }
    //         // setTimeout(displayData2, 300)
    //     }
    // }

    const fetchData = async () => {
        setLoading(true); // เริ่มโหลด
        try {
            const url = `${base_api_url}/his_day_trade?date=${strDate}&time=${strTime}`;
            const response = await __fetching_data__({ url: url, method: "GET", headers: { "xsrf-token": csrfToken } });

            allData = response.data.data;
            if (response.status) {
                displayData2();
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); // โหลดเสร็จแล้ว
        }
    };


    useEffect(() => {
        setSelectDate(today_value)
        // console.log('start_date =',start_date)
        // setSelectDate2(selectDate2)
        setSelectTime(current_time)
        const fetchData = async () => {
            const url = `${base_api_url}/his_day_trade_exc`
            // console.log(url)
            const response = await __fetching_data__({ url: url, method: "GET", headers: { "xsrf-token": csrfToken } });
            console.log(response.data)
            setExecData(response.data)
            // excData = response.data

        }
        fetchData()
    }, []);




    const onClick = async (symbol, start_date) => {
        console.log('on click', symbol)
        console.log('select date', start_date)
        setSelectSymbol(symbol)
        setSelectDate(start_date)
        clearDiv()
        const response = await __fetching_data__({ url: `${base_api_url}/his_day_trade_detail?symbol=${symbol}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        setDetailData(response.data)

        const formatDateToYYYYMMDD = (date) => {
            if (!date) return ''; // ตรวจสอบว่ามีค่า date หรือไม่

            console.log("Raw date input:", date);

            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0'); // เติม 0 ด้านหน้า ถ้าเป็นเลขหลักเดียว
            const day = String(d.getDate()).padStart(2, '0'); // เติม 0 ด้านหน้า
            // console.log(`${year}-${month}-${day}`);
            return `${year}-${month}-${day}`;
        };


        console.log('date to show =', start_date)





        const formattedDate = formatDateToYYYYMMDD(start_date);
        console.log('Formatted Date:', formattedDate);

        var value = {
            // user_input: 'AOT'
            user_input: symbol
        };
        const response_chatbot = await __fetching_data__({ url: `${base_api_url}/chat_bot_report`, method: "POST", body: JSON.stringify(value) });
        console.log(response_chatbot.data)
        const symbolName = symbol;
        // console.log('name',symbolName);

        setOnClickTable(true)

        // const [chartShort, lineShort1, lineShort2] = generateChartShort(response_chatbot.data.plot_data)

        const [chartLast, lineSeriesLast] = createChartTrade('last', response_chatbot.data.plot_data.price, 'Last', true, symbolName)
        const [chartPredictedTrend, lineSeriesPredictedTrend] = createChartTrade('predicted_trend', response_chatbot.data.plot_data.flow_accum, 'Predicted Trend', false, symbolName)
        const [chartShort, [lineShort1, lineShort2], containerShort] = generateChartShort(response_chatbot.data.plot_data, symbolName)
        // const [chartShort, lineShort1, lineShort2] = createChartShort(response_chatbot.data.plot_data.short, response_chatbot.data.plot_data.outstanding_short)
        // const [chartShort, lineShort1, lineShort2] = generateChart59(response_chatbot.data.plot_data)

        // const response_money_input = await __fetching_data__({ url: `${base_api_url}/his_money_inout?symbol=24CS&startdate=2024-12-02`, method: "GET", headers: { "xsrf-token": csrfToken } });
        const response_money_input = await __fetching_data__({ url: `${base_api_url}/his_money_inout?symbol=${symbol}&startdate=${formattedDate}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log('money input data', response_money_input.data)
        const [chartMoneyInout, lineSeriesMoneyInout] = createChartMoneyInout(response_money_input.data, symbolName)

        chartLast.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
            chartLast.timeScale().setVisibleLogicalRange(timeRange);
            chartPredictedTrend.timeScale().setVisibleLogicalRange(timeRange);
            chartShort.timeScale().setVisibleLogicalRange(timeRange);
            chartMoneyInout.timeScale().setVisibleLogicalRange(timeRange);
        });
        chartPredictedTrend.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
            chartLast.timeScale().setVisibleLogicalRange(timeRange);
            chartPredictedTrend.timeScale().setVisibleLogicalRange(timeRange);
            chartShort.timeScale().setVisibleLogicalRange(timeRange);
            chartMoneyInout.timeScale().setVisibleLogicalRange(timeRange);
        });
        chartShort.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
            chartLast.timeScale().setVisibleLogicalRange(timeRange);
            chartPredictedTrend.timeScale().setVisibleLogicalRange(timeRange);
            chartShort.timeScale().setVisibleLogicalRange(timeRange);
            chartMoneyInout.timeScale().setVisibleLogicalRange(timeRange);
        });
        chartMoneyInout.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
            chartLast.timeScale().setVisibleLogicalRange(timeRange);
            chartPredictedTrend.timeScale().setVisibleLogicalRange(timeRange);
            chartShort.timeScale().setVisibleLogicalRange(timeRange);
            chartMoneyInout.timeScale().setVisibleLogicalRange(timeRange);
        });

        function getCrosshairDataPoint(series, param) {
            if (!param.time) {
                return null;
            }
            const dataPoint = param.seriesData.get(series);
            return dataPoint || null;
        }

        function syncCrosshair(chart, series, dataPoint) {
            try {
                if (dataPoint && dataPoint.value != null && dataPoint.time != null) {
                    // Set crosshair position only if dataPoint has valid value and time
                    chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
                } else {
                    // Clear the crosshair if dataPoint is invalid or missing
                    chart.clearCrosshairPosition();
                }
            } catch (error) {
                // Suppress any unexpected errors
                console.error("Error in syncCrosshair: ", error);
            }
        }


        chartLast.subscribeCrosshairMove(param => {
            const dataPoint = getCrosshairDataPoint(lineSeriesLast, param);
            if (dataPoint) {
                syncCrosshair(chartPredictedTrend, lineSeriesPredictedTrend, dataPoint);
                syncCrosshair(chartShort, lineShort1, dataPoint);
                syncCrosshair(chartMoneyInout, lineSeriesMoneyInout, dataPoint);
            }
        });
        chartPredictedTrend.subscribeCrosshairMove(param => {
            const dataPoint = getCrosshairDataPoint(lineSeriesPredictedTrend, param);
            if (dataPoint) {
                syncCrosshair(chartLast, lineSeriesLast, dataPoint);
                syncCrosshair(chartShort, lineShort1, dataPoint);
                syncCrosshair(chartMoneyInout, lineSeriesMoneyInout, dataPoint);
            }
        });
        chartMoneyInout.subscribeCrosshairMove(param => {
            const dataPoint = getCrosshairDataPoint(lineSeriesMoneyInout, param);
            if (dataPoint) {
                syncCrosshair(chartLast, lineSeriesLast, dataPoint);
                syncCrosshair(chartShort, lineShort1, dataPoint);
                syncCrosshair(chartPredictedTrend, lineSeriesPredictedTrend, dataPoint);
            }
        });

    }

    function createChartTrade(id, data, txt, isLast, symbolName) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', id)
        filterContainer.style.position = 'relative';
        filterContainer.classList.add('chart-containerhis');
        filterContainer.style.width = isMobile || isTablet ? '350px' : "24%"
        // isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"




        filterContainer.style.borderRadius = "10px";
        filterContainer.style.overflow = "hidden";
        chartDiv.append(filterContainer)

        const label = document.createElement('div');
        label.innerText = txt;
        label.style.position = "absolute";
        label.style.top = "5px";
        label.style.left = "5px";
        label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
        label.style.color = "white";
        label.style.zIndex = '1111';
        label.style.padding = "5px 10px";
        label.style.borderRadius = "10px";
        label.style.pointerEvents = "none"; // ✅ ป้องกันไม่ให้ Label บังกราฟ
        // filterContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไปก่อนสร้างกราฟ
        label.style.transition = "opacity 0.3s ease"; // ✅ ทำให้เปลี่ยน opacity ลื่นไหล

        filterContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไป

        // ✅ เมื่อ hover บน filterContainer ทำให้ Label จางลง
        filterContainer.addEventListener("mouseenter", () => {
            label.style.opacity = "0.1";
        });

        // ✅ เมื่อออกจาก hover ให้ Label กลับมาชัดเจน
        filterContainer.addEventListener("mouseleave", () => {
            label.style.opacity = "1";
        });
        const chart = createChart(document.getElementById(id),
            {
                width: isLast ? filterContainer.clientWidth : filterContainer.clientWidth,
                height: 270,
                timeScale: {
                    rightOffset: 5
                }
            }
        );
        chart.applyOptions({

            layout: {
                background: { color: theme.palette.table.odd }, // #434C5E
                textColor: theme.palette.text.secondary, //#AAB1C4
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง #5C667D
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน #5C667D
            },
        });
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 28,
                horzAlign: 'center',
                vertAlign: 'top',
                color: theme.palette.chart.watermark,
                // text: txt,
                text: symbolName.toUpperCase(),
            },
        })
        // chartAnnotation(chart, txt)
        let lineSeries;
        if (isLast) {
            lineSeries = chart.addLineSeries({
                title: '', color: colors.greenChart[100],
                lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false
            })
        } else {
            chart.applyOptions({
                crosshair: {
                    // mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                }
            })
            chart.priceScale('right').applyOptions({
                visible: false,
                textColor: '#F5F5F5',
            });
            chart.priceScale('left').applyOptions({
                visible: false,
                textColor: '#F5F5F5'

            });
            lineSeries = chart.addLineSeries({ lineStyle: 0, lineWidth: 3, lastValueVisible: false, priceLineVisible: false, });
        }
        lineSeries.setData(data);
        const resizeObserver = new ResizeObserver(() => {
            const newWidth = filterContainer.clientWidth;
            const newHeight = filterContainer.clientHeight;
            chart.resize(newWidth, newHeight);
        });

        resizeObserver.observe(filterContainer);
        return [chart, lineSeries]
    }

    function generateChartShort(plotData, symbolName) {
        // const chartContainer = document.createElement('div');
        // chartContainer.classList.add('chart-container');
        // const chart = createChart(chartContainer, { ...chartOption, leftPriceScale: { visible: true }, });
        // chartAnnotation(chart, '%short');

        const chartContainer = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', 'short')
        filterContainer.style.position = 'relative';
        // filterContainer.style.marginLeft = "20px"
        filterContainer.classList.add('chart-containerhis');
        // isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"

        filterContainer.style.borderRadius = "10px";
        filterContainer.style.overflow = "hidden";

        filterContainer.style.width = isMobile || isTablet ? '350px' : "24%";
        chartContainer.append(filterContainer)

        const label = document.createElement('div');
        label.innerText = '%short';
        label.style.position = "absolute";
        label.style.top = "5px";
        label.style.left = "5px";
        label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
        label.style.color = "white";
        label.style.zIndex = '1111';
        label.style.padding = "5px 10px";
        label.style.borderRadius = "10px";
        label.style.pointerEvents = "none"; // ✅ ป้องกันไม่ให้ Label บังกราฟ
        // filterContainer.appendChild(label);
        label.style.transition = "opacity 0.3s ease"; // ✅ ทำให้เปลี่ยน opacity ลื่นไหล

        filterContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไป

        // ✅ เมื่อ hover บน filterContainer ทำให้ Label จางลง
        filterContainer.addEventListener("mouseenter", () => {
            label.style.opacity = "0.1";
        });

        // ✅ เมื่อออกจาก hover ให้ Label กลับมาชัดเจน
        filterContainer.addEventListener("mouseleave", () => {
            label.style.opacity = "1";
        });
        const chart = createChart(document.getElementById('short'),
            {
                // width: 600,
                // height: 400,
                width: filterContainer.clientWidth,
                height: 270,
                timeScale: {
                    rightOffset: 5
                },
                leftPriceScale: {
                    visible: true,
                    // borderVisible: false,
                    // scaleMargins: {
                    //     top: 0.1,    // ✅ ลดช่องว่างด้านบนของราคา (ค่าต่ำสุดคือ 0)
                    //     bottom: 0.2   // ✅ เพิ่มช่องว่างด้านล่างให้ label ไม่ถูกบัง
                    // }
                },
                // rightPriceScale: {
                //     visible: true,
                //     borderVisible: false,
                //     scaleMargins: {
                //         top: 0.1,
                //         bottom: 0.1
                //     }
                // }
            }
        );

        console.log("plotdata", plotData)
        const sumVal = plotData['short'].reduce((acc, dict) => {
            return 'value' in dict ? acc + dict['value'] : acc;
        }, 0);


        let short_series = [];
        let short_series1 = [];
        let markers = []

        // const series5 = chart.addLineSeries({
        //     priceScaleId: 'left',
        //     // upColor: '#26a69a', downColor: '#ef5350', borderVisible: false,
        //     // wickUpColor: '#26a69a', wickDownColor: '#ef5350',
        //     color: 'blude', lineStyle: 0, lineWidth: 3, title: '1111', ...transacOptions
        // });

        if (sumVal === 0 || plotData['short'].length <= 1) {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    horzAlign: 'center',
                    vertAlign: 'center',
                    color: 'grey',
                    text: "%Short: None",
                },
                layout: {
                    background: { color: theme.palette.table.odd }, // #434C5E
                    textColor: theme.palette.text.secondary, //#AAB1C4
                },
                grid: {
                    vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง #5C667D
                    horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน #5C667D
                },

            });
        } else {
            const dataForSeries = generateData(plotData['short'], 'time', 'value');
            short_series = chart.addLineSeries({
                color: colors.redChart[100],
                lastValueVisible: true, title: '% daily short', priceLineVisible: false
            });
            short_series.setData(dataForSeries);

            const dataForSeries1 = generateData(plotData['outstanding_short'], 'time', 'value');
            short_series1 = chart.addLineSeries({
                color: colors.greenChart[100],
                lastValueVisible: true, title: 'outstandingshort', priceLineVisible: false, priceScaleId: 'left',
            });
            short_series1.setData(dataForSeries1);
            short_series1.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })

            chart.applyOptions({

                layout: {
                    background: { color: theme.palette.table.odd }, // #434C5E
                    textColor: theme.palette.text.secondary, //#AAB1C4
                },
                grid: {
                    vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง #5C667D
                    horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน #5C667D
                },
            });
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    color: theme.palette.chart.watermark,
                    // text: `%short`,  // Dynamic text based on inputValue
                    text: symbolName.toUpperCase(),  // Dynamic text based on inputValue
                    horzAlign: 'center',
                    vertAlign: 'top',
                },
            })

            chart.priceScale('left').applyOptions({
                autoScale: true,
                scaleMargins: { top: 0.1, bottom: 0.1 }
            })
        }
        const series = [short_series1, short_series]
        let titlesVisible = false;

        // Create button to toggle visibility of specific series titles
        const toggleButton = document.createElement('button');
        // toggleButton.setAttribute('class', 'toggleButton');
        toggleButton.textContent = 'Show/Hide All';

        const headerText = document.createElement('div');
        headerText.textContent = '%short';
        // chartContainer.appendChild(headerText);

        const addDivHeader = document.createElement('div')
        addDivHeader.setAttribute('class', 'divHeader');
        // addDivHeader.appendChild(headerText)
        // addDivHeader.appendChild(toggleButton)
        // Append button to chart container
        // chartContainer.appendChild(addDivHeader);

        // Add event listener to toggle visibility of titles
        toggleButton.addEventListener('click', () => {
            titlesVisible = !titlesVisible;
            const buttonText = titlesVisible ? 'Hide/Show All' : 'Show/Hide All';
            toggleButton.textContent = buttonText;

            // Iterate through each series
            series.forEach(s => {
                if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
                    // Toggle visibility of 'Series 1' and 'Series 2' titles
                    s.applyOptions({
                        title: titlesVisible ? s.options().title : '',
                    });
                } else if (s.options().title === '') {
                    // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                    if (s === short_series) {
                        s.applyOptions({

                            title: titlesVisible ? '% daily short' : '',
                        });
                    } else if (s === short_series1) {
                        s.applyOptions({
                            title: titlesVisible ? 'outstandingshort' : '',
                        });
                    }
                }
            });
        });

        series.forEach(s => {
            if (s.length == 0) {
                return;
            }
            if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
                // Toggle visibility of 'Series 1' and 'Series 2' titles
                s.applyOptions({
                    title: titlesVisible ? s.options().title : '',
                });
            } else if (s.options().title === '') {
                // If the title is empty, set it to 'Series 1' or 'Series 2' based on its index
                if (s === short_series) {
                    s.applyOptions({
                        title: titlesVisible ? 'outstandingshort' : '',
                    });
                } else if (s === short_series1) {
                    s.applyOptions({
                        title: titlesVisible ? '% daily short' : '',
                    });
                }
            }
        });

        // var point_data = plotData['point_data'].filter(e => e[8] == 'short')
        // if (point_data) {
        //     for (var i = 0; i < point_data.length; i++) {
        //         var point = point_data[i]
        //         let colorCode = ''
        //         let charCode = ''
        //         let barPosition = ''
        //         if (point[9] == 'A') {
        //             colorCode = '#008000'
        //             charCode = 'U'
        //             barPosition = 'aboveBar'
        //         } else if (point[9] == 'V') {
        //             colorCode = '#FF0000'
        //             charCode = 'D'
        //             barPosition = 'belowBar'
        //         }
        //         markers.push({
        //             // 'time': date.getFullYear()+'-'+month+'-'+numDay,
        //             'time': parseInt(point[12]),
        //             // position: 'aboveBar',
        //             position: barPosition,
        //             color: colorCode,
        //             shape: 'circle',
        //             text: charCode
        //         })
        //     }
        // }
        // function compare(a, b) {
        //     if (a.time < b.time) {
        //         return -1;
        //     }
        //     if (a.time > b.time) {
        //         return 1;
        //     }
        //     return 0;
        // }
        // markers.sort(compare)
        // if(short_series1.length > 0){
        //     short_series1.setMarkers(markers)
        // }

        // let max_data = plotData['max_peaks']
        // const flow_value = plotData['flow_accum'].map(items => items.value)

        // let isControl = false
        // window.addEventListener('keydown', (event) => {
        //     if (event.ctrlKey) {
        //         isControl = true
        //         if(noti_group_id == ''){
        //             noti_group_id = generateGuid()
        //         }
        //     }
        // });
        // window.addEventListener('keyup', (event) => {
        //     isControl = false
        //     noti_group_id = ''
        // });
        // const handleKeyDown = (event) => {
        //     event.preventDefault();
        //     const code = event.which || event.keyCode;

        //     let charCode = String.fromCharCode(code).toLowerCase();

        //     if (event.ctrlKey) {
        //         isControl = !isControl
        //     }
        // };

        // window.addEventListener('keydown', handleKeyDown);

        const resizeObserver = new ResizeObserver(() => {
            const newWidth = filterContainer.clientWidth;
            const newHeight = filterContainer.clientHeight;
            chart.resize(newWidth, newHeight);
        });

        resizeObserver.observe(filterContainer);

        return [chart, [short_series, short_series1], chartContainer];
        // return [chart, short_series1, chartContainer];
    }

    function createChartShort(data1, data2) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', 'short')
        filterContainer.style.marginLeft = "10px"
        // isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"

        filterContainer.style.borderRadius = "10px";
        filterContainer.style.overflow = "hidden";
        chartDiv.append(filterContainer)
        const chart = createChart(document.getElementById('short'),
            {
                width: 350,
                height: 270,
                timeScale: {
                    rightOffset: 5
                }
            }
        );
        chart.priceScale('left').applyOptions({
            autoScale: true,
            scaleMargins: { top: 0.1, bottom: 0.1 }
        })
        const sumVal = data1.reduce((acc, dict) => {
            return 'value' in dict ? acc + dict['value'] : acc;
        }, 0);

        if (sumVal === 0 || data1.length <= 1) {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 14,
                    horzAlign: 'center',
                    vertAlign: 'top',
                    color: 'grey',
                    text: "%Short: None",
                },
            });
        } else {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    color: theme.palette.chart.watermark,
                    text: '%Short',  // Dynamic text based on inputValue
                    horzAlign: 'center',
                    vertAlign: 'top',
                },
            });

            chart.priceScale('left').applyOptions({
                autoScale: true,
                scaleMargins: { top: 0.1, bottom: 0.1 }
            })
        }
        // chartAnnotation(chart, 'Last')
        const lineSeries1 = chart.addLineSeries({ color: 'red', lastValueVisible: true, title: '% daily short', priceLineVisible: false });
        lineSeries1.setData(data1);
        for (let i = 0; i < data2.length; i++) {
            data2[i]['value'] = Number(data2[i]['value'])
        }

        const lineSeries2 = chart.addLineSeries({ color: 'green', lastValueVisible: true, title: 'outstandingshort', priceLineVisible: false, priceScaleId: 'left', });
        lineSeries2.setData(data2);
        lineSeries2.applyOptions({
            priceFormat: {
                type: 'volume',
            }
        })
        return [chart, lineSeries1, lineSeries2]
    }

    function createChartMoneyInout(data, symbolName) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', 'money_inout')
        filterContainer.style.position = 'relative';
        filterContainer.classList.add('chart-containerhis');
        // isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"

        filterContainer.style.borderRadius = "10px";
        filterContainer.style.overflow = "hidden";
        filterContainer.style.width = isMobile || isTablet ? '350px' : "24%";


        chartDiv.append(filterContainer)

        const label = document.createElement('div');
        label.innerText = 'Flow';
        label.style.position = "absolute";
        label.style.top = "5px";
        label.style.left = "5px";
        label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
        label.style.color = "white";
        label.style.zIndex = '1111';
        label.style.padding = "5px 10px";
        label.style.borderRadius = "10px";
        label.style.pointerEvents = "none"; // ✅ ป้องกันไม่ให้ Label บังกราฟ
        // filterContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไปก่อนสร้างกราฟ

        label.style.transition = "opacity 0.3s ease"; // ✅ ทำให้เปลี่ยน opacity ลื่นไหล

        filterContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไป

        // ✅ เมื่อ hover บน filterContainer ทำให้ Label จางลง
        filterContainer.addEventListener("mouseenter", () => {
            label.style.opacity = "0.1";
        });

        // ✅ เมื่อออกจาก hover ให้ Label กลับมาชัดเจน
        filterContainer.addEventListener("mouseleave", () => {
            label.style.opacity = "1";
        });



        // data = data.map(e => ({ 'time': Date.parse(e['Date'] + ' ' + e['Time']), 'value': e['Money In/out'] }))
        data = data.map(e => ({
            'time': Math.floor(new Date(e['Date'] + ' ' + e['Time']).getTime() / 1000), // แปลงเป็นวินาที
            'value': e['Money In/out']
        }));
        const chart = createChart(document.getElementById('money_inout'),
            {
                width: filterContainer.clientWidth,
                height: 270,
                timeScale: {
                    rightOffset: 5
                }
            }
        );
        chart.applyOptions({

            layout: {
                background: { color: theme.palette.table.odd }, // #434C5E
                textColor: theme.palette.text.secondary, //#AAB1C4
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง #5C667D
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน #5C667D
            },
        });
        // chartAnnotation(chart, 'Flow')
        chart.applyOptions({
            crosshair: {
                // mode: CrosshairMode.Normal,
                // Horizontal crosshair line (showing Price in Label)
                horzLine: {
                    labelVisible: false
                },
            }
        });
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 28,
                horzAlign: 'center',
                vertAlign: 'top',
                color: theme.palette.chart.watermark,
                text: symbolName.toUpperCase(),
            },
        })
        chart.priceScale('right').applyOptions({
            visible: false,
            textColor: '#F5F5F5',
        });
        chart.priceScale('left').applyOptions({
            visible: false,
            textColor: '#F5F5F5'

        });
        function compare(a, b) {
            if (a.time < b.time) {
                return -1;
            }
            if (a.time > b.time) {
                return 1;
            }
            return 0;
        }
        let lineSeries = chart.addLineSeries({ lineStyle: 0, lineWidth: 3, lastValueVisible: false, priceLineVisible: false, });
        lineSeries.setData(data);
        const resizeObserver = new ResizeObserver(() => {
            const newWidth = filterContainer.clientWidth;
            const newHeight = filterContainer.clientHeight;
            chart.resize(newWidth, newHeight);
        });

        resizeObserver.observe(filterContainer);
        return [chart, lineSeries]
    }

    function chartAnnotation(chart, text) {
        chart.applyOptions({
            watermark: {
                visible: true,
                fontSize: 28,
                horzAlign: 'left',
                vertAlign: 'top',
                color: 'rgb(0,0,0)',
                text: text,
            },
            rightPriceScale: {
                visible: true,
            },
            leftPriceScale: {
                visible: true,
                //mode: PriceScaleMode.Normal,
                autoScale: false,  // Disable automatic scaling
                invertScale: false,
                alignLabels: false,
                minValue: -1,      // Set the minimum value
                maxValue: 1,       // Set the maximum value
            },
        });

    }

    // const mockData = [
    //     ["AAPL", "BUY", 500, 175.25, "2024-02-05 09:30:00"],
    //     ["GOOG", "SELL", 300, 2800.5, "2024-02-05 09:35:00"],
    //     ["TSLA", "BUY", 120, 900.75, "2024-02-05 09:40:00"],
    //     ["AMZN", "SELL", 200, 3400.0, "2024-02-05 09:45:00"],
    //     ["MSFT", "BUY", 150, 310.2, "2024-02-05 09:50:00"],
    //     ["AAPL", "BUY", 500, 175.25, "2024-02-05 09:30:00"],
    //     ["GOOG", "SELL", 300, 2800.5, "2024-02-05 09:35:00"],
    //     ["TSLA", "BUY", 120, 900.75, "2024-02-05 09:40:00"],
    //     ["AMZN", "SELL", 200, 3400.0, "2024-02-05 09:45:00"],
    //     ["MSFT", "BUY", 150, 310.2, "2024-02-05 09:50:00"],
    //     ["AAPL", "BUY", 500, 175.25, "2024-02-05 09:30:00"],
    //     ["GOOG", "SELL", 300, 2800.5, "2024-02-05 09:35:00"],
    //     ["TSLA", "BUY", 120, 900.75, "2024-02-05 09:40:00"],
    //     ["AMZN", "SELL", 200, 3400.0, "2024-02-05 09:45:00"],
    //     ["MSFT", "BUY", 150, 310.2, "2024-02-05 09:50:00"],
    //     ["AAPL", "BUY", 500, 175.25, "2024-02-05 09:30:00"],
    //     ["GOOG", "SELL", 300, 2800.5, "2024-02-05 09:35:00"],
    //     ["TSLA", "BUY", 120, 900.75, "2024-02-05 09:40:00"],
    //     ["AMZN", "SELL", 200, 3400.0, "2024-02-05 09:45:00"],
    //     ["MSFT", "BUY", 150, 310.2, "2024-02-05 09:50:00"],
    // ];

    // ใช้ค่า idxSelect จำลองแถวที่ถูกเลือก



    const genTable = (dataTable, isCol2) => {

        return (
            <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: theme.palette.table.thead, borderBottomStyle: 'hidden', }}>
                            {["SYMBOL", "T", "VOL", "PRICE", "TIME"].map((header) => (
                                <TableCell
                                    key={header}
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        padding: '10px 0px'
                                    }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataTable.map((e, idx) => {
                            var row_data = e;
                            let colCheck = isCol2 ? idx + 10 : idx;
                            let cssDisplay = {};
                            if (colCheck == idxSelect) {
                                cssDisplay["backgroundColor"] =
                                    // row_data[1] == "BUY" ? "lightgreen" : "lightcoral";
                                    row_data[1] == "BUY" ? '#3CB371' : '#D32F2F';
                            }

                            return (
                                <TableRow
                                    key={idx}
                                    onClick={() => console.log("Click:", row_data[0])} // แทน onClick(row_data[0])
                                    sx={{
                                        cursor: "pointer",
                                        borderBottomStyle: 'hidden',
                                        backgroundColor: cssDisplay["backgroundColor"] ||
                                            (idx % 2 === 0 ? theme.palette.table.even : theme.palette.table.odd), // ใช้ค่าเดิมก่อน ถ้าไม่มีให้ใช้สีสลับ
                                        "&:hover": { backgroundColor: "#444b5f" },
                                    }}
                                >
                                    <TableCell sx={{ color: "white", textAlign: "center", fontSize: '0.8rem', padding: isTablet ? '10px 0px' : '10px' }}>
                                        {row_data[0]}
                                    </TableCell>
                                    <TableCell sx={{ color: "white", textAlign: "center", fontSize: '0.8rem', padding: isTablet ? '10px 0px' : '10px' }}>
                                        {row_data[1] === "BUY" ? "B" : "S"}
                                    </TableCell>
                                    <TableCell sx={{ color: "white", textAlign: "center", fontSize: '0.8rem', padding: isTablet ? '10px 0px' : '10px' }}>
                                        {Number(row_data[2]).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </TableCell>
                                    <TableCell sx={{ color: "white", textAlign: "center", fontSize: '0.8rem', padding: isTablet ? '10px 0px' : '10px' }}>
                                        {Number(row_data[3])
                                            .toFixed(2)
                                            .toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                    </TableCell>
                                    <TableCell sx={{ color: "white", textAlign: "center", fontSize: '0.8rem', padding: isTablet ? '10px 0px' : '10px' }}>
                                        {row_data[4].split(" ")[1]}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const gen20Table = (dataTable, isCol2) => {
        return (
            <TableContainer component={Paper} sx={{ backgroundColor: "#2e3343" }}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#3a3f51" }}>
                            {["SYMBOL", "T", "VOL", "PRICE", "TIME"].map((header) => (
                                <TableCell
                                    key={header}
                                    sx={{
                                        color: "white",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataTable.slice(0, 20).map((e, idx) => {
                            var row_data = e
                            let colCheck = isCol2 ? idx + 20 : idx
                            let cssDisplay = { cursor: 'pointer' }
                            if (colCheck === idxSelect) {
                                // cssDisplay['backgroundColor'] = row_data[1] == 'BUY' ? 'lightgreen' : 'lightcoral'
                                cssDisplay['backgroundColor'] = row_data[1] == 'BUY' ? '#3CB371' : '#D32F2F'
                            }
                            return (
                                <TableRow
                                    key={idx}
                                    onClick={() => console.log("Click:", row_data[0])} // แทน onClick(row_data[0])
                                    sx={{
                                        cursor: "pointer",
                                        backgroundColor: cssDisplay["backgroundColor"] || "inherit",
                                        "&:hover": { backgroundColor: "#444b5f" },
                                    }}
                                >
                                    <TableCell sx={{ color: "white", textAlign: "center", padding: '10px' }}>
                                        {row_data[0]}
                                    </TableCell>
                                    <TableCell sx={{ color: "white", textAlign: "center", padding: '10px' }}>
                                        {row_data[1] === "BUY" ? "B" : "S"}
                                    </TableCell>
                                    <TableCell sx={{ color: "white", textAlign: "center", padding: '10px' }}>
                                        {Number(row_data[2]).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                        })}
                                    </TableCell>
                                    <TableCell sx={{ color: "white", textAlign: "center", padding: '10px' }}>
                                        {Number(row_data[3])
                                            .toFixed(2)
                                            .toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                    </TableCell>
                                    <TableCell sx={{ color: "white", textAlign: "center", padding: '10px' }}>
                                        {row_data[4].split(" ")[1]}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
        // < div >
        //     <table className="customers">
        //         <thead>
        //             <tr>
        //                 <th>SYMBOL</th>
        //                 <th>T</th>
        //                 <th>VOL</th>
        //                 <th>PRICE</th>
        //                 <th>TIME</th>
        //             </tr>
        //         </thead>
        //         <tbody>
        //             {dataTable.slice(0, 20).map((e, idx) => {
        //                 var row_data = e
        //                 let colCheck = isCol2 ? idx + 20 : idx
        //                 let cssDisplay = { cursor: 'pointer' }
        //                 if (colCheck === idxSelect) {
        //                     cssDisplay['backgroundColor'] = row_data[1] == 'BUY' ? 'lightgreen' : 'lightcoral'
        //                 }

        //                 return <tr
        //                     key={idx}
        //                     onClick={() =>
        //                         onClick(row_data[0])
        //                     } style={cssDisplay}>
        //                     <td>
        //                         {row_data[0]}
        //                     </td>
        //                     <td>
        //                         {row_data[1] === 'BUY' ? 'B' : 'S'}
        //                     </td>
        //                     <td>
        //                         {Number(row_data[2]).toLocaleString(undefined, { maximumFractionDigits: 2 })}
        //                     </td>
        //                     <td>
        //                         {Number(row_data[3]).toFixed(2).toLocaleString(undefined, { maximumFractionDigits: 2 })}
        //                     </td>
        //                     <td>
        //                         {row_data[4].split(' ')[1]}
        //                     </td>
        //                 </tr>
        //             })
        //             }
        //         </tbody>
        //     </table>
        // </div >
    }

    const onClickExc = (name) => {
        const idx = excList.indexOf(name)
        if (idx != -1) {
            setExcList(excList.filter(e => e != name))
            excList = excList.filter(e => e != name)
        } else {
            setExcList(e => ([...e, name]))
            excList = [...excList, name]
        }
        globalList = excList
        console.log(globalList)
    }

    const clearDiv = () => {
        document.getElementById('last').remove()
        document.getElementById('predicted_trend').remove()
        document.getElementById('short').remove()
        document.getElementById('money_inout').remove()
    }

    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    function generateData(data, date_columm = 'date', field = 'value') {
        const timeZoneOffset = 7 * 60 * 60 * 1000;
        const cdata = data.map(d => {
            // let time = timeToTz(d[date_columm], selectedTimezone)
            let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000

            return { time: time2, value: parseFloat(d[field]) || 0 }
        });
        return cdata;
    }

    function generateChart59(plotData) {
        const chartContainer = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', 'short')
        // filterContainer.style.width = "100%"
        // isMobile ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"
        chartContainer.append(filterContainer)
        const chart = createChart(document.getElementById('short'),
            {
                width: 325,
                height: 270,
                timeScale: {
                    rightOffset: 5
                }
            }
        );

        // const chartContainer = document.createElement('div');
        // chartContainer.classList.add('chart-container');
        // chartContainer.setAttribute('id', 'short')
        // // const chart = createChart(chartContainer, chartOption);
        // const chart = createChart(document.getElementById('short'),
        //     {
        //         width: 325,
        //         height: 270,
        //         timeScale: {
        //             rightOffset: 5
        //         }
        //     }
        // );

        chartAnnotation(chart, 'ผู้บริหาร');
        const seriesArray = []; // Array to store series arrays
        const seriesTitles = []; // Array to store series titles


        if (plotData['all_59'].length === 0) {
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 14,
                    horzAlign: 'center',
                    vertAlign: 'top',
                    color: 'grey',
                    text: "59-2:None",
                },
            });
        } else {
            const data_59 = plotData['all_59'];
            const name_59 = plotData['name_59'].slice(1);
            for (let i = 0; i < name_59.length; i++) {
                const data = data_59[i];
                const name = name_59[i];
                const color = getRandomColor();
                const seriesData = generateData(data, 'time', 'value');

                // Version 1: Line series without title
                const series_59_without_title = chart.addLineSeries({ color: color });
                series_59_without_title.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                    visible: false, // Initially hide this series
                });
                series_59_without_title.setData(seriesData);

                // Version 2: Line series with title
                const series_59_with_title = chart.addLineSeries({ title: `${name}`, color: color });
                series_59_with_title.applyOptions({
                    priceFormat: {
                        type: 'volume',
                    },
                });
                series_59_with_title.setData(seriesData);

                // Store both versions in an array
                seriesArray.push([series_59_without_title, series_59_with_title]);
                seriesTitles.push(name);
            }

            // Show the series with title initially
            seriesArray.forEach(seriesPair => {
                seriesPair[1].applyOptions({ visible: true });
            });

            // Top left Symbol font size in chart
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    color: theme.palette.chart.watermark,
                    // text: `${inputValue}`,  // Dynamic text based on inputValue
                    text: '%Short',
                    horzAlign: 'center',
                    vertAlign: 'top',
                },
            });

            // Function to toggle visibility of all series
            let titleVisibilityState = false; // Initial state of title visibility

            function toggleAllSeriesTitleVisibility() {
                seriesArray.forEach(seriesPair => {
                    const [seriesWithoutTitle, seriesWithTitle] = seriesPair;
                    const currentTitleVisibility = seriesWithTitle.options().title;

                    // Toggle title visibility
                    if (titleVisibilityState) {
                        seriesWithoutTitle.applyOptions({ visible: true });
                        seriesWithTitle.applyOptions({ visible: false });
                    } else {
                        seriesWithoutTitle.applyOptions({ visible: false });
                        seriesWithTitle.applyOptions({ visible: true });
                    }
                });

                // Update title visibility state
                titleVisibilityState = !titleVisibilityState;
            }


            // Button to control visibility of all series
            const toggleButton = document.createElement('button');
            // toggleButton.setAttribute('class', 'toggleButton'); // Add id attribute
            toggleButton.textContent = 'Hide/Show All';
            toggleButton.addEventListener('click', toggleAllSeriesTitleVisibility);
            // toggleAllSeriesTitleVisibility()
            // chartContainer.appendChild(toggleButton);

            const headerText = document.createElement('div');
            headerText.textContent = 'ผู้บริหาร';
            // chartContainer.appendChild(headerText);

            const addDivHeader = document.createElement('div')
            addDivHeader.setAttribute('class', 'divHeader');
            addDivHeader.appendChild(headerText)
            addDivHeader.appendChild(toggleButton)
            // Append button to chart container
            chartContainer.appendChild(addDivHeader);
            // toggleAllSeriesTitleVisibility()

            // Update title visibility state
            // titleVisibilityState = !titleVisibilityState;
            // addHeader('ผู้บริหาร', chartContainer);

            // Click event handler for individual series
            chart.subscribeClick(mouseParams => {
                console.log('Click event triggered');
                console.log('mouseParams:', mouseParams);

                if (!mouseParams || !mouseParams.point || !mouseParams.hoveredSeries) {
                    console.log('No point or hovered series data found');
                    return;
                }

                const clickedSeries = mouseParams.hoveredSeries;
                const seriesIndex = seriesArray.findIndex(s => s.includes(clickedSeries));

                if (seriesIndex === -1) {
                    console.log('Clicked series not found');
                    return;
                }

                // Toggle visibility between the two versions of the series
                const [seriesWithoutTitle, seriesWithTitle] = seriesArray[seriesIndex];

                // Check if series with title is currently visible
                if (seriesWithTitle.options().visible) {
                    seriesWithoutTitle.applyOptions({ visible: true });
                    seriesWithTitle.applyOptions({ visible: false });
                } else {
                    seriesWithoutTitle.applyOptions({ visible: false });
                    seriesWithTitle.applyOptions({ visible: true });
                }

                console.log('Toggled visibility for series:', seriesTitles[seriesIndex]);
            });

        }

        seriesArray.forEach(seriesPair => {
            const [seriesWithoutTitle, seriesWithTitle] = seriesPair;
            const currentTitleVisibility = seriesWithTitle.options().title;
            seriesWithoutTitle.applyOptions({ visible: true });
            seriesWithTitle.applyOptions({ visible: false });
        });

        return [chart, seriesArray[0], chartContainer];
    }
    const symbolTag = () => {
        return (
            <div>
                {/* <div style={{ marginRight: (isMobile || isPTablet) ? '0' : '250px' }}>
                    <div >Exc</div>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <button onClick={() => onClickExc('S50')}
                                className={excList.indexOf('S50') == -1 ? 'btn_exc' : 'btn_exc_click'}>S50</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('USD')}
                                className={excList.indexOf('USD') == -1 ? 'btn_exc' : 'btn_exc_click'}>USD </button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button
                                onClick={() => onClickExc('GO,GF,GF10')}
                                className={excList.indexOf('GO,GF,GF10') == -1 ? 'btn_exc' : 'btn_exc_click'}>GO, GF10, GF</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('SVF')}
                                className={excList.indexOf('SVF') == -1 ? 'btn_exc' : 'btn_exc_click'}>SVF</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('DW')}
                                className={excList.indexOf('DW') == -1 ? 'btn_exc' : 'btn_exc_click'}>DW</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('DR')}
                                className={excList.indexOf('DR') == -1 ? 'btn_exc' : 'btn_exc_click'}>DR</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('SET')}
                                className={excList.indexOf('SET') == -1 ? 'btn_exc' : 'btn_exc_click'}>SET</button>
                        </div>
                        <div style={{ marginLeft: '8px' }}>
                            <button onClick={() => onClickExc('MAI')}
                                className={excList.indexOf('MAI') == -1 ? 'btn_exc' : 'btn_exc_click'}>MAI</button>
                        </div>
                    </div>
                </div> */}
                <Stack direction="column" spacing={1} sx={{ marginRight: (isMobile || isPTablet) ? 0 : 10 }}>
                    <div>Exc</div>
                    <ButtonGroup variant="contained" aria-label="exc selection">
                        {["S50", "USD", "GO,GF,GF10", "SVF", "DW", "DR", "SET", "MAI"].map((item) => (
                            <Button
                                key={item}
                                onClick={() => onClickExc(item)}
                                sx={{
                                    backgroundColor: excList.includes(item) ? "primary.dark" : "grey.500",
                                    "&:hover": { backgroundColor: excList.includes(item) ? "primary.main" : "grey.600" },
                                }}
                            >
                                {item}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Stack>;
            </div>
        )
    }
    const excOptions = ["S50", "USD", "GO,GF,GF10", "SVF", "DW", "DR", "SET", "MAI"];
    return (<div>

        <Box style={{ display: 'flex', flexDirection: (isMobile || isTablet) ? 'column' : 'normal', justifyContent: 'center', marginLeft: '20px', marginRight: '20px' }}>




        </Box>
        <Preloading open={loading} />

        {/* ส่วนของ Desktop ครับ ถ้าจะปรับปรับตรงนี้นะครับ */}
        {(!isMobile && !isTablet) && (
            <>

                <div style={{
                    display: 'flex',
                    marginLeft: '50px',
                    marginRight: '50px',
                    // justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '1vw', justifyContent: 'center' }}>
                        <Box sx={{
                            marginTop: '10px', width: '100%',
                            //  maxWidth: '700px', 
                            maxWidth: '40vw',
                            backgroundColor: theme.palette.background.sidebar, padding: 1, borderRadius: '5px'
                        }}>

                            <Grid container xl={12} lg={12} md={12} sx={{ gap: '1vw', justifyContent: 'center' }}>
                                <span style={{ fontSize: '2rem', }}>His Day Trade</span>
                                <Grid item xs={4} md={3} lg={12} >
                                    <Box sx={{ marginRight: (isMobile || isPTablet) ? 0 : 10 }}>
                                        <Stack direction="row" gap="5px" sx={{ display: 'flex', justifyContent: 'center' }}>Exc:
                                            {excOptions.map((item) => {
                                                const isSelected = excList.includes(item);
                                                return (
                                                    <Button
                                                        key={item}
                                                        onClick={() => onClickExc(item)}
                                                        variant={isSelected ? "contained" : "outlined"}
                                                        sx={{
                                                            minWidth: 40,
                                                            maxHeight: '22px',
                                                            backgroundColor: isSelected ? 'normal' : "#43cc80",
                                                            color: isSelected ? "white" : "white",
                                                            borderColor: isSelected ? "black" : "green",
                                                            "&:hover": {
                                                                backgroundColor: isSelected ? "darkgreen" : "lightgray",
                                                                color: isSelected ? "white" : "black",
                                                            },
                                                            boxShadow: theme.palette.shadow.primary
                                                        }}
                                                    >
                                                        {item}
                                                    </Button>
                                                );
                                            })}
                                        </Stack>
                                    </Box>

                                </Grid>
                                <Grid item xs={4} md={3} lg={3} sx={{ flexGrow: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DatePicker
                                            id="select_date"
                                            name="select_date"
                                            label="Select Date"
                                            value={selectDate}
                                            size="small"
                                            onChange={(e) => {
                                                setSelectDate(e)
                                                setStrDate(formatDate(e))
                                            }}
                                            sx={{
                                                // width: '40%',
                                                // marginBottom: '15px',
                                                // marginLeft: '-20px',
                                                '& .MuiInputBase-root': {
                                                    // backgroundColor: '#f9f9f9', // สีพื้นหลังของ input
                                                    borderRadius: '8px', // มุมโค้งของกรอบ
                                                    height: '40px',
                                                    // border: '1px solid #ddd', // กรอบเส้น
                                                    // padding: '-20px', // ระยะห่างภายใน input
                                                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // เงาของกรอบ
                                                },

                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#04AA6D', // สีของกรอบเมื่อไม่เลือก
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#03a55d', // สีของกรอบเมื่อ hover
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#028c4c', // สีของกรอบเมื่อเลือก
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={4} md={3} lg={3}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <TimePicker
                                            id="select_time"
                                            name="select_time"
                                            label="Select Time"
                                            size="small"
                                            value={selectTime}
                                            onChange={(e) => {
                                                setSelectTime(e)
                                                setStrTime(formatTime(e))
                                            }}
                                            sx={{
                                                // width: '40%',
                                                // marginBottom: '15px',
                                                // marginLeft: '10px',
                                                '& .MuiInputBase-root': {
                                                    height: '40px',
                                                    // backgroundColor: '#f9f9f9', // สีพื้นหลังของ input
                                                    borderRadius: '8px', // มุมโค้งของกรอบ
                                                    // border: '1px solid #ddd', // กรอบเส้น
                                                    // padding: '-20px', // ระยะห่างภายใน input
                                                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // เงาของกรอบ

                                                },

                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#04AA6D', // สีของกรอบเมื่อไม่เลือก
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#03a55d', // สีของกรอบเมื่อ hover
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#028c4c', // สีของกรอบเมื่อเลือก
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                {/* <Button variant="contained" onClick={() => fetchData()} style={{ height: '55px', marginLeft: '10px' }}>
    Search
</Button> */}
                                <Grid item xs={4} md={3} lg={2}>
                                    <Button variant="contained" onClick={() => fetchData()} style={{
                                        height: '40px',
                                        // marginLeft: '10px'
                                    }} disabled={loading}>
                                        {loading ? "Loading..." : "Search"}
                                    </Button>
                                </Grid>
                            </Grid>
                            <div style={{
                                display: 'flex', marginTop: '15px',
                                // marginLeft: '-20px',
                                gap: '5px'
                            }}>
                                {genTable(data1, false)}
                                {genTable(data2, true)}
                                {/* {genTable(mockData, true)} */}
                                {/* {genTable(mockData, false)} */}


                            </div>
                        </Box>
                        <Box sx={{
                            marginTop: '10px',
                            //  maxWidth: '700px',
                            height: '100%',
                            maxWidth: '40vw',

                        }}>
                            <BidAskComponent2 start_date={selectDate}
                                start_time={selectTime}
                                onClickSearch={onClickTable}
                                symbol={selectSymbol}
                                onPlotGraph={onClick}
                            // onPlotGraph={handlePlotGraph}
                            />
                        </Box>

                    </Box >
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div id="chart-div" style={{
                            justifyContent: 'center',
                            backgroundColor: theme.palette.background.sidebar,
                            padding: '15px',
                            borderRadius: '10px',
                            width: '100%',
                            height: '300px',
                            display: 'flex',
                            marginTop: '10px',
                            // marginLeft: '10px',
                            gap: '1vw'
                        }}>


                            <div id='last'>

                            </div>
                            <div
                                // style={{ marginLeft: '10px' }}
                                id='predicted_trend'>

                            </div>
                            <div
                                // style={{ marginLeft: '10px' }} 
                                id='short'>

                            </div>
                            <div
                                // style={{ marginLeft: '10px' }}
                                id="money_inout">

                            </div>
                        </div>


                    </Box>

                </div>


            </>
        )
        }


        {/* ส่วนของ Tablet ครับ */}
        {
            (isTablet && !isPTablet) && (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                        <Box style={{ marginTop: '10px', width: '50%' }}>
                            <div>
                                <Grid item xs={4} md={3} lg={12} >
                                    <Box sx={{ marginRight: (isMobile || isPTablet) ? 0 : 10, mb: 1 }}>
                                        <Stack direction="row" gap="5px" sx={{ display: 'flex', justifyContent: 'center' }}>Exc:
                                            {excOptions.map((item) => {
                                                const isSelected = excList.includes(item);
                                                return (
                                                    <Button
                                                        key={item}
                                                        onClick={() => onClickExc(item)}
                                                        variant={isSelected ? "contained" : "outlined"}
                                                        sx={{
                                                            minWidth: 40,
                                                            width: 'fit-content',
                                                            maxHeight: '22px',
                                                            backgroundColor: isSelected ? 'normal' : "#43cc80",
                                                            color: isSelected ? "white" : "white",
                                                            borderColor: isSelected ? "black" : "green",
                                                            "&:hover": {
                                                                backgroundColor: isSelected ? "darkgreen" : "lightgray",
                                                                color: isSelected ? "white" : "black",
                                                            },
                                                            boxShadow: theme.palette.shadow.primary
                                                        }}
                                                    >
                                                        {item}
                                                    </Button>
                                                );
                                            })}
                                        </Stack>
                                    </Box>

                                </Grid>
                                <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Grid item xs={4} sm={4} md={4}><LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                        <DatePicker
                                            id="select_date"
                                            name="select_date"
                                            label="Select Date"
                                            value={selectDate}
                                            onChange={(e) => {
                                                setSelectDate(e)
                                                setStrDate(formatDate(e))
                                            }}
                                            sx={{
                                                // width: '40%',
                                                marginLeft: '-20px',
                                                '& .MuiInputBase-root': {
                                                    // backgroundColor: '#f9f9f9', // สีพื้นหลังของ input
                                                    borderRadius: '8px', // มุมโค้งของกรอบ
                                                    // border: '1px solid #ddd', // กรอบเส้น
                                                    // padding: '-20px', // ระยะห่างภายใน input
                                                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // เงาของกรอบ
                                                },
                                                '& .MuiInputLabel-root': {
                                                    // color: '#555', // สีข้อความ label
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#04AA6D', // สีของกรอบเมื่อไม่เลือก
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#03a55d', // สีของกรอบเมื่อ hover
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#028c4c', // สีของกรอบเมื่อเลือก
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <TimePicker
                                                id="select_time"
                                                name="select_time"
                                                label="Select Time"
                                                value={selectTime}
                                                onChange={(e) => {
                                                    setSelectTime(e)
                                                    setStrTime(formatTime(e))
                                                }}
                                                sx={{
                                                    // width: '40%',
                                                    // marginLeft: '10px',
                                                    '& .MuiInputBase-root': {
                                                        // backgroundColor: '#f9f9f9', // สีพื้นหลังของ input
                                                        borderRadius: '8px', // มุมโค้งของกรอบ
                                                        // border: '1px solid #ddd', // กรอบเส้น
                                                        // padding: '-20px', // ระยะห่างภายใน input
                                                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // เงาของกรอบ

                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        // color: '#555', // สีข้อความ label
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#04AA6D', // สีของกรอบเมื่อไม่เลือก
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#03a55d', // สีของกรอบเมื่อ hover
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#028c4c', // สีของกรอบเมื่อเลือก
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>


                                    {/* <Button variant="contained" onClick={() => fetchData()} style={{ height: '55px', marginLeft: '10px' }}>
                            Search
                        </Button> */}
                                    <Grid item xs={2} sm={2}>
                                        <Button variant="contained" onClick={() => fetchData()} sx={{ height: '55px' }} disabled={loading}>
                                            {loading ? "Loading..." : "Search"}
                                        </Button>

                                    </Grid>


                                </Grid>
                            </div>
                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '15px', gap: '5px', justifyContent: 'center', alignItems: 'center' }}>
                                {/* {gen20Table([...data1, ...data2], false)} */}

                                {genTable(data1, false)}
                                {genTable(data2, true)}
                                {/* {genTable(data2, true)} */}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <BidAskComponent2 start_date={selectDate}
                                start_time={selectTime}
                                onClickSearch={onClickTable}
                                symbol={selectSymbol}
                                onPlotGraph={onClick}
                            />


                        </Box>
                    </Box >
                    {/* <Box sx={{
                        maxWidth: '480px', overflowX: 'auto',
                        '::-webkit-scrollbar': {
                            width: '5px',
                            height: '5px'
                        },
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555',
                        },
                    }}>

                        <div id="chart-div" style={{
                            display: 'flex',
                            marginTop: '10px',
                            width: '1200px',
                            // marginLeft: '10px',
                            gap: '10px'
                        }}>

                            <div id='last'></div>
                            <div style={{ marginLeft: '10px' }} id='predicted_trend'></div>
                            <div style={{ marginLeft: '10px' }} id='short'></div>
                            <div style={{ marginLeft: '10px' }} id="money_inout"></div>
                        </div>
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <div id="chart-div" style={{
                            justifyContent: 'center',
                            backgroundColor: theme.palette.background.sidebar,
                            padding: '15px',
                            borderRadius: '10px',
                            width: '80vw',
                            maxWidth: '80vw',
                            height: '220px',
                            display: 'flex',
                            marginTop: '10px',
                            // marginLeft: '10px',
                            gap: '1vw'
                        }}>


                            <div id='last'>

                            </div>
                            <div
                                // style={{ marginLeft: '10px' }}
                                id='predicted_trend'>

                            </div>
                            <div
                                // style={{ marginLeft: '10px' }} 
                                id='short'>

                            </div>
                            <div
                                // style={{ marginLeft: '10px' }}
                                id="money_inout">

                            </div>
                        </div>


                    </Box>
                </>
            )
        }

        {/* ส่วนของ Mobile ครับ */}
        {
            (isMobile || isPTablet) && (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                        <Swiper
                            style={{ width: '100%', maxWidth: '600px' }}
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            pagination={{
                                clickable: true, dynamicBullets: true,
                            }}
                        //   onSwiper={(swiper) => {
                        //     window.mySwiper = swiper;
                        //   }}
                        >
                            <SwiperSlide>
                                <Box style={{ marginTop: '10px', width: '100%' }}>
                                    <div>
                                        <Grid item xs={4} md={3} lg={12} >
                                            <Box sx={{ marginRight: (isMobile || isPTablet) ? 0 : 10, mb: 2 }}>
                                                <Stack direction="row" gap="5px" sx={{ display: 'flex', justifyContent: 'center' }}>Exc:
                                                    {excOptions.map((item) => {
                                                        const isSelected = excList.includes(item);
                                                        return (
                                                            <Button
                                                                key={item}
                                                                onClick={() => onClickExc(item)}
                                                                variant={isSelected ? "contained" : "outlined"}
                                                                sx={{
                                                                    minWidth: 40,
                                                                    width: 'fit-content',
                                                                    maxHeight: '22px',
                                                                    backgroundColor: isSelected ? 'normal' : "#43cc80",
                                                                    color: isSelected ? "white" : "white",
                                                                    borderColor: isSelected ? "black" : "green",
                                                                    "&:hover": {
                                                                        backgroundColor: isSelected ? "darkgreen" : "lightgray",
                                                                        color: isSelected ? "white" : "black",
                                                                    },
                                                                    boxShadow: theme.palette.shadow.primary
                                                                }}
                                                            >
                                                                {item}
                                                            </Button>
                                                        );
                                                    })}
                                                </Stack>
                                            </Box>

                                        </Grid>
                                        <Grid container spacing={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Grid item xs={4} sm={4}><LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <DatePicker
                                                    id="select_date"
                                                    name="select_date"
                                                    label="Select Date"
                                                    value={selectDate}
                                                    onChange={(e) => {
                                                        setSelectDate(e)
                                                        setStrDate(formatDate(e))
                                                    }}
                                                    sx={{
                                                        // width: '40%',
                                                        marginLeft: '-20px',
                                                        '& .MuiInputBase-root': {
                                                            // backgroundColor: '#f9f9f9', // สีพื้นหลังของ input
                                                            borderRadius: '8px', // มุมโค้งของกรอบ
                                                            // border: '1px solid #ddd', // กรอบเส้น
                                                            padding: '-20px', // ระยะห่างภายใน input
                                                            boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // เงาของกรอบ
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            // color: '#555', // สีข้อความ label
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#04AA6D', // สีของกรอบเมื่อไม่เลือก
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#03a55d', // สีของกรอบเมื่อ hover
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#028c4c', // สีของกรอบเมื่อเลือก
                                                        },
                                                    }}
                                                />
                                            </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} sm={4}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <TimePicker
                                                        id="select_time"
                                                        name="select_time"
                                                        label="Select Time"
                                                        value={selectTime}
                                                        onChange={(e) => {
                                                            setSelectTime(e)
                                                            setStrTime(formatTime(e))
                                                        }}
                                                        sx={{
                                                            // width: '40%',
                                                            // marginLeft: '10px',
                                                            '& .MuiInputBase-root': {
                                                                // backgroundColor: '#f9f9f9', // สีพื้นหลังของ input
                                                                borderRadius: '8px', // มุมโค้งของกรอบ
                                                                // border: '1px solid #ddd', // กรอบเส้น
                                                                // padding: '-20px', // ระยะห่างภายใน input
                                                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)', // เงาของกรอบ

                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                // color: '#555', // สีข้อความ label
                                                            },
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#04AA6D', // สีของกรอบเมื่อไม่เลือก
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#03a55d', // สีของกรอบเมื่อ hover
                                                            },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#028c4c', // สีของกรอบเมื่อเลือก
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>


                                            {/* <Button variant="contained" onClick={() => fetchData()} style={{ height: '55px', marginLeft: '10px' }}>
                            Search
                        </Button> */}
                                            <Grid item xs={2} sm={2}>
                                                <Button variant="contained" onClick={() => fetchData()} sx={{ height: '55px' }} disabled={loading}>
                                                    {loading ? "Loading..." : "Search"}
                                                </Button>

                                            </Grid>


                                        </Grid>
                                    </div>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '15px', justifyContent: 'center', alignItems: 'center' }}>
                                        {gen20Table([...data1, ...data2], false)}
                                        {/* {genTable(data2, true)} */}
                                    </Box>
                                </Box>

                            </SwiperSlide>
                            <SwiperSlide>
                                <BidAskComponent2 start_date={selectDate}
                                    start_time={selectTime}
                                    onClickSearch={onClickTable}
                                    symbol={selectSymbol}
                                    onPlotGraph={onClick}
                                />
                                <Box sx={{ maxWidth: '600px', overflowX: 'auto', padding: '0px 10px' }}
                                    onWheel={(event) => event.stopPropagation()}
                                    onTouchMove={(event) => event.stopPropagation()}
                                >

                                    <div id="chart-div" style={{
                                        display: 'flex',
                                        marginTop: '10px',
                                        // marginLeft: '10px',
                                        width: '1200px',
                                        overflowY: 'auto',
                                        gap: '10px'
                                        // marginRight: '10px',
                                    }}
                                        onWheel={(event) => event.stopPropagation()}
                                        onTouchMove={(event) => event.stopPropagation()}
                                    >

                                        <div id='last'></div>
                                        <div style={{ marginLeft: '10px' }} id='predicted_trend'></div>
                                        <div style={{ marginLeft: '10px' }} id='short'></div>
                                        <div style={{ marginLeft: '10px' }} id="money_inout"></div>
                                    </div>
                                </Box>



                            </SwiperSlide>
                        </Swiper>


                    </Box >

                </>
            )
        }




    </div >)
}