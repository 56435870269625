import { useState, useEffect, useRef } from 'react'
import { createChart } from 'lightweight-charts';
import { useTheme, Box, Autocomplete, TextField, Typography, IconButton, SvgIcon, Container, Paper } from '@mui/material';
import { GridView, GridOn, Apps } from '@mui/icons-material';
import { tokens } from "../../theme";
import R4c3 from './LayoutsChart/43';
import R3c3 from './LayoutsChart/33'
import R2c2 from './LayoutsChart/22'
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
//import './App.css'

//const symbolsToSelect = ['segs','rgwesge','rsgsegs','esgaewsg','esfgsg']




function Realtime() {

    const [selectedLayout, setSelectedLayout] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useIsMobile();
    const isPTablet = useIsPortraitTablet();
    const isTablet = useIsTablet();

    const layoutOptions = [
        { label: '4x3', value: 'R4c3', component: <R4c3 />, icon: <GridOn /> },
        { label: '3x3', value: 'R3c3', component: <R3c3 />, icon: <Apps /> },
        { label: '2x2', value: 'R2c2', component: <R2c2 />, icon: <GridView /> },
    ];


    useEffect(() => {
        const fetchLayout = async () => {
            try {
                if (selectedValue === 'R4c3') {
                    setSelectedLayout(<R4c3 />);
                } else if (selectedValue === 'R3c3') {
                    setSelectedLayout(<R3c3 />);
                } else if (selectedValue === 'R2c2') {
                    setSelectedLayout(<R2c2 />);
                } else {
                    setSelectedLayout(null); // Reset layout if selectedValue is null or invalid
                }
            } catch (error) {
                console.error('Error fetching layout:', error);
            }
        };

        fetchLayout();
    }, [selectedValue]);

    return (
        <>
            {/* <Box sx={{
      position: 'absolute',
      width: '100vw',
      height: '10vh',
      top: '0vh',
      left: '0vw',
      background: 'linear-gradient(to right, #140F3D, #000,#040444)',
      zIndex: 9000000

    }}> */}

            {/* <Box sx={{
        position: 'absolute',
        width: '20%',
        height: '100%',
        left: '5%',
        overflow: 'hidden',
        
      }}>
        <a href="/"><img src="https://i.postimg.cc/TP1gJwyz/logo-white-02.png" alt="" width='40%' height='auto' /></a>
      
      </Box> */}

            {/* <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={layoutOptions}
        size='small'
        className='sl-symbol'
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => setSelectedValue(newValue?.value)}
        sx={{
          margin: '1%',
          width: '25%',
          right: '5%',
          zIndex: 10,
          position: 'absolute',
          backgroundColor: 'white'
        }}
        renderInput={(params) => <TextField {...params} label="Select Layout" />}
      /> */}

            {/* </Box> */}

            {/* bar Select  */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: isMobile ? 'center' : 'unset' }}>

                <div style={{
                    backgroundColor: '#FFCD77',
                    width: 'fit-content',
                    height: '50px',
                    color: 'black',
                    fontSize: '18px',
                    fontFamily: 'nunito',
                    marginTop: '10px',
                    borderRadius: '0px 30px 30px 0px', // มุมบนและล่างขวาโค้ง 30px
                    display: 'flex',
                    alignItems: 'center', // จัดให้อยู่ตรงกลางแนวตั้ง
                    paddingLeft: '10px', // เว้นระยะจากขอบซ้ายเล็กน้อย
                    paddingRight: '10px',
                    boxShadow: '4px 4px 10px #3636FF', // เพิ่มเงาสีน้ำเงิน
                    gap: '10px'
                }}> {isMobile ? 'Layout:' : 'Select Layout :'}

                    <Box style={{ display: 'flex', gap: '15px' }}>
                        {layoutOptions.map((option) => (
                            <IconButton
                                key={option.value}
                                onClick={() => setSelectedValue(option.value)}
                                sx={{
                                    backgroundColor: selectedValue === option.value ? '#202431' : 'black', // เปลี่ยนสีพื้นหลังเมื่อเลือก
                                    color: selectedValue === option.value ? 'black' : 'black', // ทำให้ไอคอนเป็นสีดำทั้งตอนเลือกและไม่ได้เลือก
                                    borderRadius: '10px',
                                    padding: '7px',
                                    '&:hover': { backgroundColor: '#3636FF', color: 'black' },
                                    boxShadow: selectedValue === option.value ? 'inset 0 0 10px 10px #9747FF' : 'none', // เพิ่ม inner shadow ตอนเลือก
                                }}
                            >
                                {option.icon}
                            </IconButton>
                        ))}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', borderLeft: '2px solid black', paddingLeft: '10px', gap: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            Price
                            <Box sx={{ display: 'flex', width: isMobile ? '35px' : '60px', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                                <Box sx={{ width: isMobile? '30px' : '50px', height: '3px', backgroundColor: colors.whiteChart[200], borderRadius: '10px' }} />
                            </Box>

                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            Value
                            <Box sx={{ display: 'flex', width: isMobile ? '35px' : '60px', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>


                                <Box sx={{ width: isMobile? '30px' : '50px', height: '3px', backgroundColor: colors.greenChart[100], borderRadius: '10px' }} />
                                <Box sx={{ width: isMobile? '30px' : '50px', height: '3px', backgroundColor: colors.redChart[100], borderRadius: '10px' }} />

                            </Box>

                        </Box>

                    </Box>
                </div>


                {selectedLayout && (
                    <Container maxWidth='xl' sx={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 0, maxWidth: '95%', overflowX: 'auto' }}>
                        <Paper
                            sx={{
                                flex: 1,
                                overflowY: 'auto',
                                width: isMobile ? '200vw' : '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                p: 1,
                                backgroundColor: theme.palette.background.container,
                                maxHeight: {
                                    xs: '88vh',
                                    sm: '90vh',
                                    md: '90vh',
                                    lg: '90vh',
                                    xl: '90vh',
                                },
                            }}
                        >
                            <Box sx={{ height: '100%' }}>
                                {selectedLayout}
                            </Box>
                        </Paper>
                    </Container>
                )}

            </Box>



        </>

    );
}

export default Realtime
