import { useState, useEffect, useRef, useCallback } from 'react'

import { createChart } from 'lightweight-charts';
import { Box, Autocomplete, TextField, useTheme, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../../theme";
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import LoopIcon from '@mui/icons-material/Loop';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../../components/hooks';


//const symbolsToSelect = ['segs','rgwesge','rsgsegs','esgaewsg','esfgsg']

const FavoriteListPopup = ({
  selectedFavorite,
  setSelectedFavorite,
  symbolSelected,  // Make sure to pass this from the parent component
  applyFavoriteSymbols,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [favorites, setFavorites] = useState([]);
  const [openSettings, setOpenSettings] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const [editedFavoriteName, setEditedFavoriteName] = useState('');
  const [editedSymbols, setEditedSymbols] = useState([]);
  const [symbolArray, setSymbolArray] = useState([]); // Track selected symbols

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();


  // Load favorites from localStorage on mount
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favoriteLists33')) || [];
    setFavorites(storedFavorites);
  }, []);

  // Save favorites to localStorage whenever the favorites list changes
  useEffect(() => {
    localStorage.setItem('favoriteLists33', JSON.stringify(favorites));
  }, [favorites]);

  // Update symbolArray whenever symbolSelected changes
  useEffect(() => {
    const selectedSymbols = symbolSelected;
    console.log('symbol in fav is', symbolSelected);
    setSymbolArray(selectedSymbols);  // Update symbolArray
  }, [symbolSelected]);
  console.log('array is', symbolArray);

  const handleFavoriteSelect = (event, value) => {
    if (value) {
      setSelectedFavorite(value);
      setSymbolArray(value.symbols);  // Set the symbols for the selected favorite
      applyFavoriteSymbols(value.symbols);  // Apply the selected symbols
    }
  };

  const addToFavorites = () => {
    if (!symbolArray.length) return; // Ensure we have symbols to add

    const updatedFavorites = selectedFavorite
      ? favorites.map((fav) =>
        fav.name === selectedFavorite.name
          ? { ...fav, symbols: [...symbolArray] } // Update symbols of existing favorite
          : fav
      )
      : [...favorites, { name: `Favorite ${favorites.length + 1}`, symbols: [...symbolArray] }]; // Add new favorite

    setFavorites(updatedFavorites);
    setSelectedFavorite(null);
    setSymbolArray([]); // Clear symbolArray after adding to favorites
  };

  const handleOpenSettings = (option) => {
    setEditedFavoriteName(option.name);
    setEditedSymbols(option.symbols);
    setSelectedFavorite(option);
    setOpenSettings(true);
  };

  const handleSaveSettings = () => {
    const updatedFavorites = favorites.map((fav) =>
      fav.name === selectedFavorite.name
        ? { ...fav, name: editedFavoriteName, symbols: editedSymbols } // Save edited symbols and name
        : fav
    );
    setFavorites(updatedFavorites);

    const updatedSelected = updatedFavorites.find(fav => fav.name === editedFavoriteName);
    setSelectedFavorite(updatedSelected || null);

    setOpenSettings(false);
  };

  const handleOpenDeleteConfirm = (option) => {
    setOptionToDelete(option);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteFavorite = () => {
    const updatedFavorites = favorites.filter((fav) => fav.name !== optionToDelete.name);
    setFavorites(updatedFavorites);
    if (selectedFavorite?.name === optionToDelete.name) {
      setSelectedFavorite(null);
    }
    setOpenDeleteConfirm(false);
  };

  useEffect(() => {
    if (selectedFavorite) {
      const updatedFav = favorites.find(fav => fav.name === selectedFavorite.name);
      if (updatedFav) {
        setSelectedFavorite(updatedFav); // อัปเดตค่าใหม่
      }
    }
  }, [favorites]);

  return (
    <div style={{ marginBottom: '10px', position: 'absolute', right: isMobile ? '15%' :  '10%', top: '10px', display: 'flex', flexDirection: 'row', gap: '1vw' }}>
      <Autocomplete
        disablePortal
        id='favoriteList'
        size='small'
        options={favorites}
        value={selectedFavorite}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          if (!value) {
            // กดล้างหรือเลือก 'null' -> ล้าง symbolArray
            setSelectedFavorite(null);
            setSymbolArray([]);
          } else {
            handleFavoriteSelect(event, value);
          }
        }}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{option.name}</span>
            <div>
              <IconButton size='small' onClick={() => handleOpenSettings(option)}>
                <EditIcon />
              </IconButton>
              <IconButton size='small' onClick={() => handleOpenDeleteConfirm(option)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </li>
        )}
        renderInput={(params) => <TextField {...params} label='Favorite List' />}
        sx={{ width: '150px' }}
      />
      <Button
        variant='contained'
        startIcon={selectedFavorite ? <SaveIcon /> : <FavoriteIcon />}
        onClick={addToFavorites}
        sx={{ backgroundColor: colors.orangeChart[200] }}
      >
        {selectedFavorite ? `Save` : 'Add to favorite'}
      </Button>

      <Dialog open={openSettings} onClose={() => setOpenSettings(false)}>
        <DialogTitle>Edit Favorite</DialogTitle>
        <DialogContent>
          <TextField
            label='Favorite Name'
            fullWidth
            value={editedFavoriteName}
            onChange={(e) => setEditedFavoriteName(e.target.value)}
            sx={{ mt: '1em' }}
          />
          {/* <Grid container spacing={2}>
                      {editedSymbols.map((symbol, index) => (
                          <Grid item xs={6} key={index}>
                              <TextField
                                  label={`Symbol ${index + 1}`}
                                  fullWidth
                                  value={symbol}
                                  onChange={(e) => handleSymbolChangeInSettings(index, e.target.value)}
                              />
                          </Grid>
                      ))}
                  </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSettings(false)} variant='text' sx={{ backgroundColor: 'transparent' }}>Cancel</Button>
          <Button onClick={handleSaveSettings} variant='contained' sx={{ backgroundColor: 'green' }} >Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {optionToDelete?.name} from your favorites?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(false)} variant='text' sx={{ backgroundColor: 'transparent' }}>Cancel</Button>
          <Button onClick={handleDeleteFavorite} variant='contained' sx={{ backgroundColor: 'red' }}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

import { useFetchingData } from '../../../components/api.js';



function App() {
  const { __fetching_data__ } = useFetchingData();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();

  const [selectedFavorite, setSelectedFavorite] = useState(null);

  const [symbolsToSelect, setSymbolsToSelect] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedSymbol2, setSelectedSymbol2] = useState(null);
  const [selectedSymbol3, setSelectedSymbol3] = useState(null);
  const [selectedSymbol4, setSelectedSymbol4] = useState(null);
  const [selectedSymbol5, setSelectedSymbol5] = useState(null);
  const [selectedSymbol6, setSelectedSymbol6] = useState(null);
  const [selectedSymbol7, setSelectedSymbol7] = useState(null);
  const [selectedSymbol8, setSelectedSymbol8] = useState(null);
  const [selectedSymbol9, setSelectedSymbol9] = useState(null);
  const [showAutocomplete, setShowAutocomplete] = useState(true);
  const [showAutocomplete2, setShowAutocomplete2] = useState(true);
  const [showAutocomplete3, setShowAutocomplete3] = useState(true);
  const [showAutocomplete4, setShowAutocomplete4] = useState(true);
  const [showAutocomplete5, setShowAutocomplete5] = useState(true);
  const [showAutocomplete6, setShowAutocomplete6] = useState(true);
  const [showAutocomplete7, setShowAutocomplete7] = useState(true);
  const [showAutocomplete8, setShowAutocomplete8] = useState(true);
  const [showAutocomplete9, setShowAutocomplete9] = useState(true);

  const [layout, SetLayout] = useState(null);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [data4, setData4] = useState(null);
  const [data5, setData5] = useState(null);
  const [data6, setData6] = useState(null);
  const [data7, setData7] = useState(null);
  const [data8, setData8] = useState(null);
  const [data9, setData9] = useState(null);

  const [flow_acc, setFlow_acc] = useState(null);
  const [flow_acc2, setFlow_acc2] = useState(null);
  const [flow_acc3, setFlow_acc3] = useState(null);
  const [flow_acc4, setFlow_acc4] = useState(null);
  const [flow_acc5, setFlow_acc5] = useState(null);
  const [flow_acc6, setFlow_acc6] = useState(null);
  const [flow_acc7, setFlow_acc7] = useState(null);
  const [flow_acc8, setFlow_acc8] = useState(null);
  const [flow_acc9, setFlow_acc9] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [isLoading5, setIsLoading5] = useState(false);
  const [isLoading6, setIsLoading6] = useState(false);
  const [isLoading7, setIsLoading7] = useState(false);
  const [isLoading8, setIsLoading8] = useState(false);
  const [isLoading9, setIsLoading9] = useState(false);

  const chartContainerRef = useRef(null);
  const chartContainerRef2 = useRef(null);
  const chartContainerRef3 = useRef(null);
  const chartContainerRef4 = useRef(null);
  const chartContainerRef5 = useRef(null);
  const chartContainerRef6 = useRef(null);
  const chartContainerRef7 = useRef(null);
  const chartContainerRef8 = useRef(null);
  const chartContainerRef9 = useRef(null);

  const chartInitializedRef = useRef(false);
  const chartInitializedRef2 = useRef(false);
  const chartInitializedRef3 = useRef(false);
  const chartInitializedRef4 = useRef(false);
  const chartInitializedRef5 = useRef(false);
  const chartInitializedRef6 = useRef(false);
  const chartInitializedRef7 = useRef(false);
  const chartInitializedRef8 = useRef(false);
  const chartInitializedRef9 = useRef(false);

  const [isZoomed, setIsZoomed] = useState(false);
  const [isZoomed2, setIsZoomed2] = useState(false);
  const [isZoomed3, setIsZoomed3] = useState(false);
  const [isZoomed4, setIsZoomed4] = useState(false);
  const [isZoomed5, setIsZoomed5] = useState(false);
  const [isZoomed6, setIsZoomed6] = useState(false);
  const [isZoomed7, setIsZoomed7] = useState(false);
  const [isZoomed8, setIsZoomed8] = useState(false);
  const [isZoomed9, setIsZoomed9] = useState(false);



  let previousLogicalRange = useRef(null);
  let previousLogicalRange2 = useRef(null);
  let previousLogicalRange3 = useRef(null);
  let previousLogicalRange4 = useRef(null);
  let previousLogicalRange5 = useRef(null);
  let previousLogicalRange6 = useRef(null);
  let previousLogicalRange7 = useRef(null);
  let previousLogicalRange8 = useRef(null);
  let previousLogicalRange9 = useRef(null);


  const updateInterval = useRef(null);
  const chartRef = useRef(null);

  const base_api_url =
    process.env.NODE_ENV === "production"
      ? window.location.protocol === "https:"
        ? process.env.REACT_APP_API_HTTPS_URL
        : process.env.REACT_APP_API_HTTP_URL
      : "/application";

  const handleResetZoom = (chartId, setZoomState) => {
    setZoomState(false);
    if (chartId.current?.chart) {

      chartId.current.chart.timeScale().fitContent();
    }
  }

  const handleResetAllZoom = () => {
    if (chartContainerRef.current?.chart) {
      setIsZoomed(false);
      chartContainerRef.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef2.current?.chart) {
      setIsZoomed2(false);
      chartContainerRef2.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef3.current?.chart) {
      setIsZoomed3(false);
      chartContainerRef3.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef4.current?.chart) {
      setIsZoomed4(false);
      chartContainerRef4.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef5.current?.chart) {
      setIsZoomed5(false);
      chartContainerRef5.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef6.current?.chart) {
      setIsZoomed6(false);
      chartContainerRef6.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef7.current?.chart) {
      setIsZoomed7(false);
      chartContainerRef7.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef8.current?.chart) {
      setIsZoomed8(false);
      chartContainerRef8.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef9.current?.chart) {
      setIsZoomed9(false);
      chartContainerRef9.current.chart.timeScale().fitContent();
    }
  }

  //function press Home to timeScale().fitContent()
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Home') {
        if (chartContainerRef.current?.chart) {
          setIsZoomed(false);
          chartContainerRef.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef2.current?.chart) {
          setIsZoomed2(false);
          chartContainerRef2.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef3.current?.chart) {
          setIsZoomed3(false);
          chartContainerRef3.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef4.current?.chart) {
          setIsZoomed4(false);
          chartContainerRef4.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef5.current?.chart) {
          setIsZoomed5(false);
          chartContainerRef5.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef6.current?.chart) {
          setIsZoomed6(false);
          chartContainerRef6.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef7.current?.chart) {
          setIsZoomed7(false);
          chartContainerRef7.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef8.current?.chart) {
          setIsZoomed8(false);
          chartContainerRef8.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef9.current?.chart) {
          setIsZoomed9(false);
          chartContainerRef9.current.chart.timeScale().fitContent();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useEffect(() => {
    const fetchSymbol = async () => {
      try {
        const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_symbols` });
        // const jsonData = await response.json();
        const symbolsData = jsonData.data.symbols

        setSymbolsToSelect(symbolsData)
        //console.log(symbolsToSelect)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSymbol();
  }, []);

  //fetch data 1
  useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol || selectedSymbol == null || selectedSymbol == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }

            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); // API เก่าใช้ item.time API ใหม่ใช้ item.time
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7

                    return {
                        time: date.getTime() / 1000,
                        // value: item.close, //API ตัวเก่าใช้ item.close
                        value: item.last, //API ตัวใหม่ใช้ item.last
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData(null);
            }
            else {
                setData(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); // API เก่าใช้ item.time API ใหม่ใช้ item.time
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        // value: item.flow_accum, // API เก่าใช้ item.flow_accum
                        value: item.flow, // API ใหม่ใช้ item.flow
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc(null);
            }
            else {
                setFlow_acc(extractedAcc);
            }

        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol]);

//fetch data 2
useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol2 || selectedSymbol2 == null || selectedSymbol2 == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol2)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol2.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }
            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.last,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData2(null);
            }
            else {
                setData2(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.flow,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc2(null);
            }
            else {
                setFlow_acc2(extractedAcc);
            }
        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol2]);

//fetch data 3
useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol3 || selectedSymbol3 == null || selectedSymbol3 == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol3)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol3.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }


            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.last,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData3(null);
            }
            else {
                setData3(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.flow,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc3(null);
            }
            else {
                setFlow_acc3(extractedAcc);
            }
        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol3]);

//fetch data 4
useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol4 || selectedSymbol4 == null || selectedSymbol4 == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol4)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol4.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }

            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.last,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData4(null);
            }
            else {
                setData4(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.flow,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc4(null);
            }
            else {
                setFlow_acc4(extractedAcc);
            }
        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol4]);

//fetch data 5
useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol5 || selectedSymbol5 == null || selectedSymbol5 == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol5)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol5.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }

            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.last,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData5(null);
            }
            else {
                setData5(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.flow,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc5(null);
            }
            else {
                setFlow_acc5(extractedAcc);
            }
        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol5]);

//fetch data 6
useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol6 || selectedSymbol6 == null || selectedSymbol6 == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol6)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol6.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }
            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.last,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData6(null);
            }
            else {
                setData6(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.flow,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc6(null);
            }
            else {
                setFlow_acc6(extractedAcc);
            }
        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol6]);

//fetch data 7
useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol7 || selectedSymbol7 == null || selectedSymbol7 == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol7)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol7.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }

            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.last,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData7(null);
            }
            else {
                setData7(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.flow,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc7(null);
            }
            else {
                setFlow_acc7(extractedAcc);
            }
        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol7]);

//fetch data 8
useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol8 || selectedSymbol8 == null || selectedSymbol8 == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol8)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol8.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }

            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.last,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData8(null);
            }
            else {
                setData8(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.flow,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc8(null);
            }
            else {
                setFlow_acc8(extractedAcc);
            }
        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol8]);

//fetch data 9
useEffect(() => {
    const fetchData = async () => {
        if (!selectedSymbol9 || selectedSymbol9 == null || selectedSymbol9 == undefined) return;
        try {
            // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol9)}` });
            const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol9.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
            // const jsonData = await response.json();

            if (!Array.isArray(jsonData.data)) {
                throw new Error('Response data is not an array');
            }
            const now = new Date();
            const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
            const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

            const extractedData = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.last,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });

            if (extractedData.length === 0) {
                setData9(null);
            }
            else {
                setData9(extractedData);
            }

            const extractedAcc = jsonData.data
                .map(item => {
                    const date = new Date(item.time); //
                    // Adjust the time to Thailand timezone
                    date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                    return {
                        time: date.getTime() / 1000,
                        value: item.flow,
                    };
                })
                .filter(item => {
                    const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                    return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                });
            if (extractedAcc.length === 0) {
                setFlow_acc9(null);
            }
            else {
                setFlow_acc9(extractedAcc);
            }
        } catch (error) {
            console.error('Error fetching or processing data:', error);
        }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
}, [selectedSymbol9]);




  //chart1
  useEffect(() => {
    if (data && chartContainerRef.current) {
      let chart = chartContainerRef.current.chart;

      // console.log('data Chart 1 is', data)

      if (!chart) {

        chart = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,

          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data.length = 1 ? 1 : data.length < 20 ? 0 : data.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });
        // Define layout options for two price scales
        const layoutOptions = {

        };
        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'

          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },
        });

        chartContainerRef.current.style.borderRadius = '10px';
        chartContainerRef.current.style.overflow = 'hidden';
        chartContainerRef.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange) {
            previousLogicalRange = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange = newLogicalRange;
        });


        // ✅ Cleanup เมื่อ component ถูก unmount
        return () => {
          // chart.unsubscribeCrosshairMove();
          chart.timeScale().unsubscribeVisibleLogicalRangeChange();
        };


      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data);
      const filteredFlowAcc = filterAndSortData(flow_acc);

      if (chartContainerRef.current.lineSeries) {
        chart.removeSeries(chartContainerRef.current.lineSeries);
        chartContainerRef.current.lineSeries = null;
      }
      if (chartContainerRef.current.baselineSeries) {
        chart.removeSeries(chartContainerRef.current.baselineSeries);
        chartContainerRef.current.baselineSeries = null;
      }

      let lineSeries = chartContainerRef.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef.current) {
        chart.timeScale().fitContent();
        chartInitializedRef.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading(false);
    }
  }, [data, flow_acc]);

  //chart2
  useEffect(() => {
    if (data2 && chartContainerRef2.current) {
      let chart = chartContainerRef2.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef2.current, {
          width: chartContainerRef2.current.clientWidth,
          height: chartContainerRef2.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data2.length < 20 ? 0 : data2.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef2.current.style.borderRadius = '10px';
        chartContainerRef2.current.style.overflow = 'hidden';
        chartContainerRef2.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange2) {
            previousLogicalRange2 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange2.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange2.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed2(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed2(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange2 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol2.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data2);
      const filteredFlowAcc = filterAndSortData(flow_acc2);

      if (chartContainerRef2.current.lineSeries) {
        chart.removeSeries(chartContainerRef2.current.lineSeries);
        chartContainerRef2.current.lineSeries = null;
      }
      if (chartContainerRef2.current.baselineSeries) {
        chart.removeSeries(chartContainerRef2.current.baselineSeries);
        chartContainerRef2.current.baselineSeries = null;
      }

      let lineSeries = chartContainerRef2.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef2.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data2);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef2.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef2.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc2);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef2.current) {
        chart.timeScale().fitContent();
        chartInitializedRef2.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed2) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading2(false);
    }
  }, [data2, flow_acc2]);

  //chart3
  useEffect(() => {
    if (data3 && chartContainerRef3.current) {
      let chart = chartContainerRef3.current.chart;
      if (!chart) {
        chart = createChart(chartContainerRef3.current, {
          width: chartContainerRef3.current.clientWidth,
          height: chartContainerRef3.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data3.length < 20 ? 0 : data3.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef3.current.style.borderRadius = '10px';
        chartContainerRef3.current.style.overflow = 'hidden';
        chartContainerRef3.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange3) {
            previousLogicalRange3 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange3.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange3.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed3(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed3(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange3 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol3.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data3);
      const filteredFlowAcc = filterAndSortData(flow_acc3);


      if (chartContainerRef3.current.lineSeries) {
        chart.removeSeries(chartContainerRef3.current.lineSeries);
        chartContainerRef3.current.lineSeries = null;
      }
      if (chartContainerRef3.current.baselineSeries) {
        chart.removeSeries(chartContainerRef3.current.baselineSeries);
        chartContainerRef3.current.baselineSeries = null;
      }

      let lineSeries = chartContainerRef3.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef3.current.lineSeries = lineSeries;
      }

      lineSeries.setData(filteredData);
      // lineSeries.setData(data3);

      // Add a second line series
      let baselineSeries = chartContainerRef3.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef3.current.baselineSeries = baselineSeries;
      }
      baselineSeries.setData(filteredFlowAcc);
      // baselineSeries.setData(flow_acc3);
      if (!chartInitializedRef3.current) {
        chart.timeScale().fitContent();
        chartInitializedRef3.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed3) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading3(false);
    }
  }, [data3, flow_acc3]);

  //chart4
  useEffect(() => {
    if (data4 && chartContainerRef4.current) {
      let chart = chartContainerRef4.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef4.current, {
          width: chartContainerRef4.current.clientWidth,
          height: chartContainerRef4.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data4.length < 20 ? 0 : data4.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef4.current.style.borderRadius = '10px';
        chartContainerRef4.current.style.overflow = 'hidden';
        chartContainerRef4.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange4) {
            previousLogicalRange4 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange4.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange4.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed4(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed4(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange4 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol4.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data4);
      const filteredFlowAcc = filterAndSortData(flow_acc4);

      if (chartContainerRef4.current.lineSeries) {
        chart.removeSeries(chartContainerRef4.current.lineSeries);
        chartContainerRef4.current.lineSeries = null;
      }
      if (chartContainerRef4.current.baselineSeries) {
        chart.removeSeries(chartContainerRef4.current.baselineSeries);
        chartContainerRef4.current.baselineSeries = null;
      }


      let lineSeries = chartContainerRef4.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef4.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data4);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef4.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef4.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc4);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef4.current) {
        chart.timeScale().fitContent();
        chartInitializedRef4.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed4) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading4(false);
    }
  }, [data4, flow_acc4]);


  //chart5
  useEffect(() => {
    if (data5 && chartContainerRef5.current) {
      let chart = chartContainerRef5.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef5.current, {
          width: chartContainerRef5.current.clientWidth,
          height: chartContainerRef5.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data5.length < 20 ? 0 : data5.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef5.current.style.borderRadius = '10px';
        chartContainerRef5.current.style.overflow = 'hidden';
        chartContainerRef5.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange5) {
            previousLogicalRange5 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange5.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange5.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed5(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed5(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange5 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol5.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data5);
      const filteredFlowAcc = filterAndSortData(flow_acc5);

      if (chartContainerRef5.current.lineSeries) {
        chart.removeSeries(chartContainerRef5.current.lineSeries);
        chartContainerRef5.current.lineSeries = null;
      }
      if (chartContainerRef5.current.baselineSeries) {
        chart.removeSeries(chartContainerRef5.current.baselineSeries);
        chartContainerRef5.current.baselineSeries = null;
      }

      let lineSeries = chartContainerRef5.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef5.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data5);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef5.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef5.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc5);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef5.current) {
        chart.timeScale().fitContent();
        chartInitializedRef5.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed5) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading5(false);
    }
  }, [data5, flow_acc5]);


  //chart6
  useEffect(() => {
    if (data6 && chartContainerRef6.current) {
      let chart = chartContainerRef6.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef6.current, {
          width: chartContainerRef6.current.clientWidth,
          height: chartContainerRef6.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data6.length < 20 ? 0 : data6.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef6.current.style.borderRadius = '10px';
        chartContainerRef6.current.style.overflow = 'hidden';
        chartContainerRef6.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange6) {
            previousLogicalRange6 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange6.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange6.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed6(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed6(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange6 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol6.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data6);
      const filteredFlowAcc = filterAndSortData(flow_acc6);

      if (chartContainerRef6.current.lineSeries) {
        chart.removeSeries(chartContainerRef6.current.lineSeries);
        chartContainerRef6.current.lineSeries = null;
      }
      if (chartContainerRef6.current.baselineSeries) {
        chart.removeSeries(chartContainerRef6.current.baselineSeries);
        chartContainerRef6.current.baselineSeries = null;
      }

      let lineSeries = chartContainerRef6.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef6.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data6);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef6.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef6.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc6);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef6.current) {
        chart.timeScale().fitContent();
        chartInitializedRef6.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed6) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading6(false);
    }
  }, [data6, flow_acc6]);

  //chart7
  useEffect(() => {
    if (data7 && chartContainerRef7.current) {
      console.log(data7)
      let chart = chartContainerRef7.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef7.current, {
          width: chartContainerRef7.current.clientWidth,
          height: chartContainerRef7.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data7.length < 20 ? 0 : data7.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef7.current.style.borderRadius = '10px';
        chartContainerRef7.current.style.overflow = 'hidden';
        chartContainerRef7.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange7) {
            previousLogicalRange7 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange7.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange7.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed7(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed7(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange7 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol7.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data7);
      const filteredFlowAcc = filterAndSortData(flow_acc7);

      if (chartContainerRef7.current.lineSeries) {
        chart.removeSeries(chartContainerRef7.current.lineSeries);
        chartContainerRef7.current.lineSeries = null;
      }
      if (chartContainerRef7.current.baselineSeries) {
        chart.removeSeries(chartContainerRef7.current.baselineSeries);
        chartContainerRef7.current.baselineSeries = null;
      }


      let lineSeries = chartContainerRef7.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef7.current.lineSeries = lineSeries;
      }

      lineSeries.setData(filteredData);
      // lineSeries.setData(data7);

      // Add a second line series
      let baselineSeries = chartContainerRef7.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef7.current.baselineSeries = baselineSeries;
      }
      baselineSeries.setData(filteredFlowAcc);
      // baselineSeries.setData(flow_acc7);
      if (!chartInitializedRef7.current) {
        chart.timeScale().fitContent();
        chartInitializedRef7.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed7) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading7(false);
    }
  }, [data7, flow_acc7]);


  //chart8
  useEffect(() => {
    if (data8 && chartContainerRef8.current) {
      let chart = chartContainerRef8.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef8.current, {
          width: chartContainerRef8.current.clientWidth,
          height: chartContainerRef8.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data8.length < 20 ? 0 : data8.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef8.current.style.borderRadius = '10px';
        chartContainerRef8.current.style.overflow = 'hidden';
        chartContainerRef8.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange8) {
            previousLogicalRange8 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange8.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange8.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed8(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed8(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange8 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol8.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data8);
      const filteredFlowAcc = filterAndSortData(flow_acc8);

      if (chartContainerRef8.current.lineSeries) {
        chart.removeSeries(chartContainerRef8.current.lineSeries);
        chartContainerRef8.current.lineSeries = null;
      }
      if (chartContainerRef8.current.baselineSeries) {
        chart.removeSeries(chartContainerRef8.current.baselineSeries);
        chartContainerRef8.current.baselineSeries = null;
      }


      let lineSeries = chartContainerRef8.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef8.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data8);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef8.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef8.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc8);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef8.current) {
        chart.timeScale().fitContent();
        chartInitializedRef8.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed8) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading8(false);
    }
  }, [data8, flow_acc8]);


  //chart9
  useEffect(() => {
    if (data9 && chartContainerRef9.current) {
      let chart = chartContainerRef9.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef9.current, {
          width: chartContainerRef9.current.clientWidth,
          height: chartContainerRef9.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data9.length < 20 ? 0 : data9.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef9.current.style.borderRadius = '10px';
        chartContainerRef9.current.style.overflow = 'hidden';
        chartContainerRef9.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange9) {
            previousLogicalRange9 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange9.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange9.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed9(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed9(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange9 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol9.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data9);
      const filteredFlowAcc = filterAndSortData(flow_acc9);

      if (chartContainerRef9.current.lineSeries) {
        chart.removeSeries(chartContainerRef9.current.lineSeries);
        chartContainerRef9.current.lineSeries = null;
      }
      if (chartContainerRef9.current.baselineSeries) {
        chart.removeSeries(chartContainerRef9.current.baselineSeries);
        chartContainerRef9.current.baselineSeries = null;
      }


      let lineSeries = chartContainerRef9.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef9.current.lineSeries = lineSeries;
      }

      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef9.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef9.current.baselineSeries = baselineSeries;
      }
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef9.current) {
        chart.timeScale().fitContent();
        chartInitializedRef9.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed9) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading9(false);
    }
  }, [data9, flow_acc9]);


  console.log("This is data : ", data)

  const symbolSelected = [
    selectedSymbol,
    selectedSymbol2,
    selectedSymbol3,
    selectedSymbol4,
    selectedSymbol5,
    selectedSymbol6,
    selectedSymbol7,
    selectedSymbol8,
    selectedSymbol9,

  ];

  const applyFavoriteSymbols = (symbols) => {
    if (symbols && symbols.length) {
      // อัปเดต selectedSymbol แต่ละตัวจาก array ของ symbols
      setSelectedSymbol(symbols[0] || null);
      setSelectedSymbol2(symbols[1] || null);
      setSelectedSymbol3(symbols[2] || null);
      setSelectedSymbol4(symbols[3] || null);
      setSelectedSymbol5(symbols[4] || null);
      setSelectedSymbol6(symbols[5] || null);
      setSelectedSymbol7(symbols[6] || null);
      setSelectedSymbol8(symbols[7] || null);
      setSelectedSymbol9(symbols[8] || null);


      // ตรวจสอบค่าว่ามีค่าอยู่หรือไม่ ถ้ามีให้ปิด Autocomplete สำหรับแต่ละตัว
      if (symbols[0]) setShowAutocomplete(false);
      if (symbols[1]) setShowAutocomplete2(false);
      if (symbols[2]) setShowAutocomplete3(false);
      if (symbols[3]) setShowAutocomplete4(false);
      if (symbols[4]) setShowAutocomplete5(false);
      if (symbols[5]) setShowAutocomplete6(false);
      if (symbols[6]) setShowAutocomplete7(false);
      if (symbols[7]) setShowAutocomplete8(false);
      if (symbols[8]) setShowAutocomplete9(false);

    }
  };

  return (
    <>

      <style>
        {`
      
      .chartBox {
        position: relative;
        /*
        width: calc(25vw - 5px);  For 4 columns on desktop 
        height: calc(33.3vh - 5px);  Adjust height as needed */
      
        width: calc(25vw - 5px);  
        height: calc(30vh - 5px) !important;
        
        margin: 2px;
        
        background-color: #575b69; /* Or any color you want */
      }
          
      
    `}
      </style>
      <Box id='main' className='container' sx={{
        // position: 'relative',
        // width: '100vw',
        maxWidth: 'xl',
        height: '90vh',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'flex-start',
        flexDirection: 'column'
        /* Adjust line spacing */
      }}>

        <FavoriteListPopup
          selectedFavorite={selectedFavorite}
          setSelectedFavorite={setSelectedFavorite}
          symbolSelected={symbolSelected}   // ส่งฟังก์ชันไปยัง FavoriteListPopup
          applyFavoriteSymbols={applyFavoriteSymbols}  // ส่งฟังก์ชันไปยัง FavoriteListPopup
        />

        <IconButton children={<LoopIcon />} onClick={() => handleResetAllZoom()}
          sx={{
            position: 'absolute',
            right: '5%',
            top: '10px',
            zIndex: 10,
            backgroundColor: "#575b69",
            // padding: '4px',
            // margin: "1%",
            "&:hover": { backgroundColor: 'transparent' }
          }} />

        {/* chart 1*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol(newValue);
                setIsLoading(true);
                setShowAutocomplete(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol(typedValue);
                  setIsLoading(true);
                  setShowAutocomplete(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol ? `/realtime_fullscreen?symbol=${selectedSymbol.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />

          )}

          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef, setIsZoomed)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol != null ? selectedSymbol.toUpperCase() : null}
              </Typography>
            </Box>
          )}
          <div ref={chartContainerRef} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '0', zIndex: 0 }} />

        </Box>
        {/* chart 2*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete2 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol2(newValue);
                setIsLoading2(true);
                setShowAutocomplete2(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol2(typedValue);
                  setIsLoading2(true);
                  setShowAutocomplete2(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete2(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol2 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol2 ? `/realtime_fullscreen?symbol=${selectedSymbol2.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />

          )}

          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef2, setIsZoomed2)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />

          {isLoading2 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol2 != null ? selectedSymbol2.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef2} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 3*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete3 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol3(newValue);
                setIsLoading3(true);
                setShowAutocomplete3(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol3(typedValue);
                  setIsLoading3(true);
                  setShowAutocomplete3(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete3(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol3 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol3 ? `/realtime_fullscreen?symbol=${selectedSymbol3.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}

              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef3, setIsZoomed3)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading3 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol3 != null ? selectedSymbol3.toUpperCase() : null}
              </Typography>
            </Box>
          )}
          <div ref={chartContainerRef3} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 4*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete4 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol4(newValue);
                setIsLoading4(true);
                setShowAutocomplete4(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol4(typedValue);
                  setIsLoading4(true);
                  setShowAutocomplete4(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete4(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol4 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol4 ? `/realtime_fullscreen?symbol=${selectedSymbol4.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef4, setIsZoomed4)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading4 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol4 != null ? selectedSymbol4.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef4} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 5*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete5 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol5(newValue);
                etIsLoading5(true);
                setShowAutocomplete5(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol5(typedValue);
                  setIsLoading5(true);
                  setShowAutocomplete5(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete5(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol5 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol5 ? `/realtime_fullscreen?symbol=${selectedSymbol5.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef5, setIsZoomed5)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading5 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol5 != null ? selectedSymbol5.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef5} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 6*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete6 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol6(newValue);
                setIsLoading6(true);
                setShowAutocomplete6(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol6(typedValue);
                  setIsLoading6(true);
                  setShowAutocomplete6(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete6(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol6 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol6 ? `/realtime_fullscreen?symbol=${selectedSymbol6.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef6, setIsZoomed6)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading6 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol6 != null ? selectedSymbol6.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef6} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 7*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete7 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol7(newValue);
                setIsLoading7(true);
                setShowAutocomplete7(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol7(typedValue);
                  setIsLoading7(true);
                  setShowAutocomplete7(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete7(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol7 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol7 ? `/realtime_fullscreen?symbol=${selectedSymbol7.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef7, setIsZoomed7)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading7 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol7 != null ? selectedSymbol7.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef7} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 8*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete8 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol8(newValue);
                setIsLoading8(true);
                setShowAutocomplete8(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol8(typedValue);
                  setIsLoading8(true);
                  setShowAutocomplete8(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete8(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol8 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol8 ? `/realtime_fullscreen?symbol=${selectedSymbol8.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef8, setIsZoomed8)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading8 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol8 != null ? selectedSymbol8.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef8} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 9*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '33% !important' : 'unset' }}>
          {showAutocomplete9 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol9(newValue);
                setIsLoading9(true);
                setShowAutocomplete9(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol9(typedValue);
                  setIsLoading9(true);
                  setShowAutocomplete9(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete9(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol9 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol9 ? `/realtime_fullscreen?symbol=${selectedSymbol9.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef9, setIsZoomed9)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading9 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol9 != null ? selectedSymbol9.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef9} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>

      </Box>

    </>
  );
}

export default App
