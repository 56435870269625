/**
 * FullscreenPageFlip.js
 * This page renders a fullscreen chart for a given flip market.
 */
import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Button } from '@mui/material';
import FontLoader from '../../components/FontLoader';

// --------------------- Utility Functions ---------------------
function datetimeToTimestamp(datetimeStr) {
  if (!datetimeStr || typeof datetimeStr !== 'string') return null;
  const dt = new Date(datetimeStr.replace(' ', 'T') + 'Z');
  if (isNaN(dt.getTime())) return null;
  return Math.floor(dt.getTime() / 1000);
}

function convertApiDataToSeries(data) {
  const sortedData = [...data]
    .filter(d => d.datetime)
    .sort((a, b) => {
      const ta = datetimeToTimestamp(a.datetime);
      const tb = datetimeToTimestamp(b.datetime);
      return (ta ?? 0) - (tb ?? 0);
    });

  let accumulatedValue = 0;
  let currentDay = null;
  const resultSeries = [];
  const seenTimestamps = new Set();

  for (const d of sortedData) {
    const timestamp = datetimeToTimestamp(d.datetime);
    if (timestamp === null) continue;

    const dateObj = new Date(d.datetime.replace(' ', 'T') + 'Z');
    const day = dateObj.toISOString().split('T')[0];
    if (day !== currentDay) {
      accumulatedValue = 0;
      currentDay = day;
    }

    const val = (d.negative_to_positive || 0) - (d.positive_to_negative || 0);
    accumulatedValue += val;

    if (!seenTimestamps.has(timestamp)) {
      resultSeries.push({ time: timestamp, value: accumulatedValue });
      seenTimestamps.add(timestamp);
    }
  }

  return resultSeries;
}

function validateSeriesData(series) {
  for (let i = 1; i < series.length; i++) {
    if (series[i].time <= series[i - 1].time) {
      console.error("Data not strictly ascending:", series[i], series[i - 1]);
      return false;
    }
  }
  return true;
}

// --------------------- Data Fetching Helpers ---------------------
async function fetchHistoryData(market, csrfToken) {
  const baseApiUrl = window.config.base_api_url;
  const t1HistoryUrl = `${baseApiUrl}/t1/history/${market}`;
  const intradayHistoryUrl = `${baseApiUrl}/intraday/history/${market}`;

  const [t1Res, intradayRes] = await Promise.all([
    fetch(t1HistoryUrl, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
    }),
    fetch(intradayHistoryUrl, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
    }),
  ]);
  if (!t1Res.ok || !intradayRes.ok) {
    throw new Error(`Failed to fetch history data for market=${market}`);
  }

  const [t1Json, intradayJson] = await Promise.all([t1Res.json(), intradayRes.json()]);
  return {
    t1History: t1Json.data || [],
    intradayHistory: intradayJson.data || [],
  };
}

async function fetchRealtimeData(market, csrfToken) {
  const baseApiUrl = window.config.base_api_url;
  const t1RealtimeUrl = `${baseApiUrl}/t1/realtime/${market}`;
  const intradayRealtimeUrl = `${baseApiUrl}/intraday/realtime/${market}`;

  const [t1Res, intradayRes] = await Promise.all([
    fetch(t1RealtimeUrl, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
    }),
    fetch(intradayRealtimeUrl, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
    }),
  ]);
  if (!t1Res.ok || !intradayRes.ok) {
    throw new Error(`Failed to fetch realtime data for market=${market}`);
  }
  const [t1Json, intradayJson] = await Promise.all([t1Res.json(), intradayRes.json()]);
  return {
    t1Realtime: t1Json.data || [],
    intradayRealtime: intradayJson.data || [],
  };
}

// --------------------- FullscreenPageFlip Component ---------------------
const FullscreenPageFlip = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Read query params (market and title)
  const params = new URLSearchParams(location.search);
  const market = params.get('market');
  const title = params.get('title');

  const csrfToken = useSelector(state => state.csrfToken);
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const t1SeriesRef = useRef(null);
  const intradaySeriesRef = useRef(null);
  const baselineSeriesRef = useRef(null);

  const [t1HistoryData, setT1HistoryData] = useState([]);
  const [intradayHistoryData, setIntradayHistoryData] = useState([]);
  const [t1RealtimeData, setT1RealtimeData] = useState([]);
  const [intradayRealtimeData, setIntradayRealtimeData] = useState([]);

  // New toggle states for T1 and Intraday lines
  const [showT1, setShowT1] = useState(true);
  const [showIntraday, setShowIntraday] = useState(true);

  // Set up the chart on mount
  useEffect(() => {
    if (!chartContainerRef.current) return;

    chartContainerRef.current.style.outline = "2px solid #fff";
    chartContainerRef.current.style.boxSizing = "border-box";
    chartContainerRef.current.style.borderRadius = "10px";
    chartContainerRef.current.style.overflow = "hidden";

    const chart = createChart(chartContainerRef.current, {
      layout: { textColor: 'white', background: { color: '#575b69' } },
      timeScale: { timeVisible: true, rightOffset: 20 },
      height: chartContainerRef.current.offsetHeight,
      width: chartContainerRef.current.offsetWidth,
      localization: {
        priceFormatter: (price) => price.toLocaleString('en-US', { maximumFractionDigits: 0 }),
      },
      priceScale: { mode: 0 },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
    });
    chartRef.current = chart;

    // Add a baseline (0 line), T1 and Intraday series
    baselineSeriesRef.current = chart.addLineSeries({
      color: '#df82ff',
      lineWidth: 1,
      lastValueVisible: false,
      priceLineVisible: false,
    });
    t1SeriesRef.current = chart.addLineSeries({
      color: '#5efee8',
      lineWidth: 2,
      title: 'Flip T-1 -> T',
    });
    intradaySeriesRef.current = chart.addLineSeries({
      color: '#ffdf4f',
      lineWidth: 2,
      title: 'Intraday',
    });

    // Observe resizing
    const ro = new ResizeObserver(() => {
      if (chartRef.current && chartContainerRef.current) {
        chartRef.current.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
        });
      }
    });
    ro.observe(chartContainerRef.current);

    async function initializeData() {
      try {
        const { t1History, intradayHistory } = await fetchHistoryData(market, csrfToken);
        setT1HistoryData(t1History);
        setIntradayHistoryData(intradayHistory);

        const { t1Realtime, intradayRealtime } = await fetchRealtimeData(market, csrfToken);
        setT1RealtimeData(t1Realtime);
        setIntradayRealtimeData(intradayRealtime);
      } catch (err) {
        console.error("Initialization error:", err);
      }
    }
    initializeData();

    // Poll realtime data every 10 seconds
    const intervalId = setInterval(async () => {
      try {
        const { t1Realtime, intradayRealtime } = await fetchRealtimeData(market, csrfToken);
        setT1RealtimeData(t1Realtime);
        setIntradayRealtimeData(intradayRealtime);
      } catch (err) {
        console.error("Realtime polling error:", err);
      }
    }, 10000);

    return () => {
      clearInterval(intervalId);
      ro.disconnect();
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
  }, [market, csrfToken]);

  // Update chart series when data changes
  useEffect(() => {
    if (!chartRef.current || !t1SeriesRef.current || !intradaySeriesRef.current) return;

    const t1Data = convertApiDataToSeries([...t1HistoryData, ...t1RealtimeData]);
    const intradayData = convertApiDataToSeries([...intradayHistoryData, ...intradayRealtimeData]);

    t1Data.sort((a, b) => a.time - b.time);
    intradayData.sort((a, b) => a.time - b.time);

    if (!validateSeriesData(t1Data) || !validateSeriesData(intradayData)) {
      console.warn('Invalid data, skipping update');
      return;
    }

    t1SeriesRef.current.setData(t1Data);
    intradaySeriesRef.current.setData(intradayData);

    if (intradayData.length > 0) {
      baselineSeriesRef.current.setData([
        { time: intradayData[0].time, value: 0 },
        { time: intradayData[intradayData.length - 1].time, value: 0 },
      ]);
    }
  }, [t1HistoryData, t1RealtimeData, intradayHistoryData, intradayRealtimeData]);

  // Toggle the visibility for T1 series when showT1 changes
  useEffect(() => {
    if (t1SeriesRef.current) {
      t1SeriesRef.current.applyOptions({ visible: showT1 });
    }
  }, [showT1]);

  // Toggle the visibility for Intraday series when showIntraday changes
  useEffect(() => {
    if (intradaySeriesRef.current) {
      intradaySeriesRef.current.applyOptions({ visible: showIntraday });
    }
  }, [showIntraday]);

  return (
    <div style={{ fontFamily: 'Lato, Sans-serif', padding: '20px' }}>
      <FontLoader/>
      
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
        <h3>{title}</h3>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          {/* Toggle T1 Line Button */}
          <Button
            onClick={() => setShowT1(prev => !prev)}
            variant="contained"
            sx={{
              textTransform: 'none',
              borderRadius: '10px',
              backgroundColor: showT1 ? '#1976d2' : '#e0e0e0',
              color: showT1 ? 'white' : 'black',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: 'bold',
              transition: 'background-color 0.3s ease, transform 0.2s',
              '&:hover': { transform: 'scale(1.05)' },
            }}
          >
            {showT1 ? 'T1 Line' : 'T1 Line'}
          </Button>

          {/* Toggle Intraday Line Button */}
          <Button
            onClick={() => setShowIntraday(prev => !prev)}
            variant="contained"
            sx={{
              textTransform: 'none',
              borderRadius: '10px',
              backgroundColor: showIntraday ? '#1976d2' : '#e0e0e0',
              color: showIntraday ? 'white' : 'black',
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: 'bold',
              transition: 'background-color 0.3s ease, transform 0.2s',
              '&:hover': { transform: 'scale(1.05)' },
            }}
          >
            {showIntraday ? 'Intraday Line' : 'Intraday Line'}
          </Button>
        </Box>
      </div>
      
      <div style={{ width: '100%', height: '85vh' }} ref={chartContainerRef} />
    </div>
  );
};

export default FullscreenPageFlip;
