import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Tooltip, Dialog } from '@mui/material';
import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { createChart } from 'lightweight-charts';

import CardContent from '@mui/material/CardContent';

// import { Bar } from "react-chartjs-2";
// import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Legend, Tooltip as ChartTooltip } from "chart.js";

import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, ChartTooltip, zoomPlugin);

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import StatBox from "../../components/StatBox";
import EmailIcon from "@mui/icons-material/Email";

import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import { __fetching_data__ } from '../../components/api.js';

import dayjs from 'dayjs';

import { Formik } from 'formik'
import * as yup from "yup";


import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import TableContainer from '@mui/material/TableContainer';
import Slider from '@mui/material/Slider';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';
import { Link, LinkOff } from "@mui/icons-material";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';

import SyncIcon from '@mui/icons-material/Sync';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import FontLoader from '../../components/FontLoader';




const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

const DejavuTickmatch = () => {

    const isMobile = useIsMobile();
    const isPTablet = useIsPortraitTablet();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const csrfToken = useSelector((state) => state.csrfToken);

    const isSyncing = useRef(false);
    const [isSyncEnabled, setIsSyncEnabled] = useState(false);
    const syncScrollRef = useRef([]);

    const [chartData, setChartData] = useState(null);
    const [chartData2, setChartData2] = useState(null);

    const [flipCount, setFlipCount] = useState(0); // จำนวนครั้งที่เกิดการพลิก
    const [flipCount2, setFlipCount2] = useState(0); // จำนวนครั้งที่เกิดการพลิก
    const [flipPoints, setFlipPoints] = useState([]); // รายการเวลาที่เกิดการพลิก
    const [flipPoints2, setFlipPoints2] = useState([]); // รายการเวลาที่เกิดการพลิก

    const [flipPointsChart1, setFlipPointsChart1] = useState([]);
    const [flipPointsChart2, setFlipPointsChart2] = useState([]);

    const [isSubmitted1, setIsSubmitted1] = useState(false);
    const [isSubmitted2, setIsSubmitted2] = useState(false);

    const [isTableVisible, setIsTableVisible] = useState(true);
    const [isTableVisible2, setIsTableVisible2] = useState(true);
    // const [rowRefs, setRowRefs] = useState([]);
    const rowRefs = useRef([]);
    const rowRefs2 = useRef([]);
    const tableRef = useRef(null);

    const [highlightedIndex, setHighlightedIndex] = useState(null);
    const [highlightedIndex2, setHighlightedIndex2] = useState(null);

    const toggleSync = () => {
        setIsSyncEnabled((prev) => {
            isSyncing.current = !prev; // อัปเดตค่า isSyncing
            return !prev;
        });
    };

    const toggleTableVisibility = () => {
        setIsTableVisible((prev) => !prev);
    };

    const toggleTableVisibility2 = () => {
        setIsTableVisible2((prev) => !prev);
    };

    const handleScroll = (e, sourceRef) => {
        if (isSyncing.current) {
            syncScrollRef.current.forEach((ref) => {
                if (ref && ref !== sourceRef) {
                    ref.scrollTop = e.target.scrollTop;
                }
            });
        }
    };

    const handleRowClick = (index) => {
        setHighlightedIndex(index); // 🔥 เปลี่ยนสีแถว
        rowRefs.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });

        // ลบ Highlight หลังจาก 2 วินาที
        setTimeout(() => {
            setHighlightedIndex(null);
        }, 2000);
    };

    const handleRowClick2 = (index) => {
        setHighlightedIndex2(index); // 🔥 เปลี่ยนสีแถว
        rowRefs2.current[index]?.scrollIntoView({ behavior: "smooth", block: "center" });

        // ลบ Highlight หลังจาก 2 วินาที
        setTimeout(() => {
            setHighlightedIndex2(null);
        }, 2000);
    };

    useEffect(() => {
        if (!isSyncing && syncScrollRef.current) {
            // Clear scroll listeners for other refs when syncing is disabled
            syncScrollRef.current.forEach((ref) => {
                if (ref) {
                    ref.removeEventListener("scroll", handleScroll);
                }
            });
        }
    }, [isSyncing]);

    function createData(time, last, vol, type, acc_cum_vol) {
        return { time, last, vol, type, acc_cum_vol };
    }
    const [rows, setRows] = useState(
        [
            // createData('', '', '', ''),
            // createData('', '', '', ''),
        ]
    );

    const [rows2, setRows2] = useState(
        [
            // createData('', '', '', ''),
            // createData('', '', '', ''),
        ]
    );

    const [rowsFilltered, setRowsFilltered] = useState([]);
    const [rowsFilltered2, setRowsFilltered2] = useState([]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            // backgroundColor: colors.blueAccent[500],//"#B6DBFB",//theme.palette.common.black,
            backgroundColor:
                '#283593',
            // "#B6DBFB",//
            // theme.palette.common.black,
            color: theme.palette.common.white,//"#1B7FD6",//theme.palette.common.white,
            fontWeight: 'bold',
            paddingTop: 8, // Set top padding to 0
            paddingBottom: 8, // Set bottom padding to 0
            borderBottom: 0
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],
            color: theme.palette.common.white,
            fontWeight: 'bold',
            borderBottom: 0
        },
    }));

    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const today_value = dayjs(today);
    const [initialValues, setInitialValues] = useState({
        symbol1: "",
        symbol2: "",
        date1: today_value.subtract(1, 'day').format('YYYY-MM-DD'),
        date2: today_value.subtract(1, 'day').format('YYYY-MM-DD'),
    });
    const disableWeekends = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        // console.log(name, value)
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleDateChange = (name, value) => {
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const [sumBuy, setSumBuy] = useState([0, 0]);
    const [sumSell, setSumSell] = useState([0, 0]);
    const [netVolume, setNetVolume] = useState([0, 0]);

    useEffect(() => {



    }, []);

    // useEffect(() => {
    //     setRowRefs(rowRefs);
    // }, []);
    // const rowRefs = [];

    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };

    const objectToQueryString = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }

    const [isFetch, setIsFetch] = useState({
        'form1': false,
        'form2': false,
    });

    const endProcess = () => {
        setIsFetch({
            'form1': false,
            'form2': false,
        });
    }

    const processStackedBarData = (tickmatchData) => {
        let labels = [];
        let buyVolumes = [];
        let sellVolumes = [];

        tickmatchData?.forEach((value) => {
            let price = parseFloat(value.Last).toFixed(2); // ราคา (แกน X)
            let volume = parseFloat(value.Vol); // ปริมาณ (Volume)
            let type = value.Type; // "BUY" หรือ "SELL"

            // เพิ่ม Labels (ราคาหุ้น)
            if (!labels.includes(price)) {
                labels.push(price);
                buyVolumes.push(0);
                sellVolumes.push(0);
            }

            let index = labels.indexOf(price);

            // เพิ่ม Volume ใน Buy หรือ Sell
            if (type === "BUY") {
                buyVolumes[index] += volume;
            } else if (type === "SELL") {
                sellVolumes[index] += volume;
            }
        });

        return {
            labels: labels,
            datasets: [
                {
                    label: "Buy Volume",
                    data: buyVolumes,
                    backgroundColor: "rgba(67, 160, 71, 0.7)", // เขียว
                    borderColor: "rgba(67, 160, 71, 1)",
                    borderWidth: 1,
                },
                {
                    label: "Sell Volume",
                    data: sellVolumes,
                    backgroundColor: "rgba(211, 47, 47, 0.7)", // ชมพู
                    borderColor: "rgba(211, 47, 47, 1)",
                    borderWidth: 1,
                },
            ],
        };
    };

    const processStackedBarData2 = (tickmatchData) => {
        let labels = [];
        let buyVolumes = [];
        let sellVolumes = [];

        tickmatchData?.forEach((value) => {
            let price = parseFloat(value.Last).toFixed(2); // ราคา (แกน X)
            let volume = parseFloat(value.Vol); // ปริมาณ (Volume)
            let type = value.Type; // "BUY" หรือ "SELL"

            // เพิ่ม Labels (ราคาหุ้น)
            if (!labels.includes(price)) {
                labels.push(price);
                buyVolumes.push(0);
                sellVolumes.push(0);
            }

            let index = labels.indexOf(price);

            // เพิ่ม Volume ใน Buy หรือ Sell
            if (type === "BUY") {
                buyVolumes[index] += volume;
            } else if (type === "SELL") {
                sellVolumes[index] += volume;
            }
        });

        return {
            labels: labels,
            datasets: [
                {
                    label: "Buy Volume",
                    data: buyVolumes,
                    backgroundColor: "rgba(67, 160, 71, 0.7)", // เขียว
                    borderColor: "rgba(67, 160, 71, 1)",
                    borderWidth: 1,
                },
                {
                    label: "Sell Volume",
                    data: sellVolumes,
                    backgroundColor: "rgba(211, 47, 47, 0.7)", // ชมพู
                    borderColor: "rgba(211, 47, 47, 1)",
                    borderWidth: 1,
                },
            ],
        };
    };

    // const FlipPointsChart = ({ data }) => {
    //     const chartContainerRef = useRef(null);

    //     useEffect(() => {
    //         if (!data || data.length === 0) return;

    //         const chart = createChart(chartContainerRef.current, {
    //             width: chartContainerRef.current.clientWidth,
    //             height: 150,
    //             timeScale: {
    //                 timeVisible: true,
    //                 secondsVisible: true,
    //             },
    //             rightPriceScale: {
    //                 visible: false,
    //             }
    //         });

    //         const series = chart.addLineSeries({
    //             color: colors.greenChart[100],
    //             priceFormat: {
    //                 type: 'price',
    //                 precision: 2,
    //                 minMove: 0.01,
    //             },
    //         });
    //         chart.applyOptions({
    //             layout: {
    //                 background: { color: theme.palette.table.odd },
    //                 textColor: theme.palette.text.secondary,
    //             },
    //             grid: {
    //                 vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
    //                 horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
    //             },
    //         });

    //         //  เก็บค่าล่าสุดของแต่ละวินาที (Map)
    //         let groupedData = new Map();
    //         let flipCount = 1;
    //         let flipMarkersByTime = {}; // เก็บ flip ตามเวลาเดียวกัน

    //         data.forEach((item, index) => {
    //             const time = Math.floor(new Date(item.DateTime).getTime() / 1000);
    //             const value = item.acc_cum_vol;
    //             const color = value > 0 ? colors.greenChart[100] : colors.redChart[100];

    //             // เก็บค่าล่าสุดของวินาทีนั้น
    //             groupedData.set(time, { time, value,color });

    //             // ตรวจ Flip Point
    //             if (index > 0) {
    //                 const prevValue = parseFloat(data[index - 1].acc_cum_vol);
    //                 if ((prevValue > 0 && value < 0) || (prevValue < 0 && value > 0)) {
    //                     // ถ้าเคยมี Flip ที่เวลานี้แล้ว ให้ต่อข้อความ
    //                     if (!flipMarkersByTime[time]) {
    //                         flipMarkersByTime[time] = [];
    //                     }
    //                     flipMarkersByTime[time].push(flipCount++);
    //                 }
    //             }
    //         });

    //         // สร้าง Markers และรวม Flip ในวินาทีเดียวกัน
    //         const flipMarkers = Object.entries(flipMarkersByTime).map(([time, flips]) => ({
    //             time: parseInt(time),
    //             position: 'aboveBar',
    //             color: theme.palette.text.primary,
    //             shape: 'arrowDown',
    //             text: `ครั้งที่ ${flips.join(', ')}`,
    //         }));

    //         // แปลงข้อมูลเพื่อใช้กับกราฟ
    //         const formattedData = Array.from(groupedData.values()).sort((a, b) => a.time - b.time);

    //         series.setData(formattedData);
    //         chart.timeScale().fitContent();
    //         series.setMarkers(flipMarkers);


    //         return () => chart.remove();
    //     }, [data]);

    //     return <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }} />;
    // };


    const FlipTimelineChart = ({ data, flipPoints, handleRowClick, rows }) => {
        const chartContainerRef = useRef(null);

        useEffect(() => {
            if (!data || data.length === 0) return;

            const chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                height: 40,
                timeScale: {
                    timeVisible: true,
                    secondsVisible: true,
                },
                rightPriceScale: { visible: false },
                leftPriceScale: { visible: false },

            });

            const series = chart.addLineSeries({
                lineWidth: 10,
                priceLineVisible: false,
            });
            chart.applyOptions({
                layout: {
                    background: { color: theme.palette.background.container },
                    textColor: theme.palette.text.secondary,
                },
                crosshair: {
                    mode: 0, // 🔥 ปิด Crosshair (CrosshairMode.Disabled)
                    vertLines: { visible: false, color: 'transparent' }, // ❌ ซ่อนเส้นแนวตั้ง
                    horzLines: { visible: false, color: 'transparent' }, // ❌ ซ่อนเส้นแนวนอน
                },
                grid: {
                    vertLines: { color: 'transparent' }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                    // vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                    horzLines: { color: 'transparent' }, // เปลี่ยนสีเส้น Grid แนวนอน
                    // horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
                },
            });


            data.sort((a, b) => new Date(a.DateTime).getTime() - new Date(b.DateTime).getTime());

            let uniqueData = new Map();
            data.forEach((value) => {
                const time = Math.floor(new Date(value.DateTime).getTime() / 1000);
                const accVol = parseFloat(value.acc_cum_vol);
                uniqueData.set(time, { time, value: 50, accVol });
            });

            let sortedData = Array.from(uniqueData.values()).sort((a, b) => a.time - b.time);

            let formattedData = sortedData.map((point) => ({
                time: point.time,
                value: 50,
                color: point.accVol > 0 ? '#00AA00' : '#AA0000',
            }));

            // console.log('Formatted Data:', formattedData);

            series.setData(formattedData);

            chart.timeScale().fitContent();

            // chart.subscribeClick((param) => {
            //     if (!param || !param.time) return;

            //     console.log('row is', rows)
            //     // const rowsData = rows.map()

            //     const clickTime = param.time; // Unix timestamp
            //     console.log('clickTime:', clickTime);

            //     // หา flip ที่เกิดขึ้นในช่วงนาทีเดียวกัน
            //     const matchedFlip = flipPoints.find((flip) => {
            //         console.log('flip', flip.time);

            //         const timeParts = flip.time.split(':'); // แยก hh:mm.sss
            //         const hours = parseInt(timeParts[0], 10);
            //         const minutes = parseInt(timeParts[1], 10);
            //         const seconds = timeParts[2] ? Math.floor(parseFloat(timeParts[2])) : 0; // ถ้าไม่มีวินาที ให้ใช้ 0

            //         const flipTime = (hours * 3600) + (minutes * 60) + seconds; // คำนวณเป็นวินาทีของวัน
            //         console.log('flipTime', flipTime);

            //         return Math.floor(clickTime % 86400 / 60) === Math.floor(flipTime / 60); // เปรียบเทียบเฉพาะระดับ "นาที"
            //     });

            //     if (matchedFlip) {
            //         handleRowClick(matchedFlip.index);
            //         console.log('match', matchedFlip);
            //     } else {
            //         // แปลง clickTime เป็น HH:MM:SS
            //         const clickDate = new Date(clickTime * 1000);
            //         const clickHour = clickDate.getUTCHours();
            //         const clickMinute = clickDate.getUTCMinutes();
            //         const clickSecond = clickDate.getUTCSeconds();

            //         const clickTimeInSeconds = (clickHour * 3600) + (clickMinute * 60) + clickSecond;

            //         let closestRow = null;
            //         let minDiff = Infinity;

            //         for (const row of rows) {
            //             let rowTime = row.time.split(" ")[1] || row.time; // ตัดวันที่ออกถ้ามี
            //             console.log('row Time', rowTime);

            //             // ถ้ามี `.` เช่น `"14:10.000"` ให้แปลงเป็น `"14:10:00"`
            //             if (rowTime.includes(".")) {
            //                 rowTime = rowTime.split(".")[0] + ":00";
            //             }

            //             const [rowHour, rowMinute, rowSecond] = rowTime.split(':').map(Number);
            //             if (isNaN(rowHour) || isNaN(rowMinute) || isNaN(rowSecond)) {
            //                 console.warn('Invalid row time format:', rowTime);
            //                 continue;
            //             }

            //             const rowTimeInSeconds = (rowHour * 3600) + (rowMinute * 60) + rowSecond;
            //             console.log('rtinsec', rowTimeInSeconds);

            //             // คำนวณค่าต่างระหว่าง `clickTime` กับ `rowTime`
            //             const diff = Math.abs(rowTimeInSeconds - clickTimeInSeconds);

            //             if (diff < minDiff) {
            //                 minDiff = diff;
            //                 closestRow = row;
            //             }
            //         }

            //         if (closestRow) {
            //             const rowIndex = closestRow.index ?? rows.findIndex(r => r === closestRow); // ถ้าไม่มี index ใช้ findIndex
            //             if (rowIndex !== -1) {
            //                 handleRowClick(rowIndex);
            //                 console.log('jump to closest row:', closestRow);
            //             } else {
            //                 console.warn('Row found but no valid index:', closestRow);
            //             }
            //         } else {
            //             console.log('no row found for minute');
            //         }

            //     }
            // });
            chart.subscribeClick((param) => {
                if (!param || !param.time) return;

                // console.log('row is', rows);

                const clickTime = param.time; // Unix timestamp
                // console.log('clickTime:', clickTime);

                // หา flip ที่เกิดขึ้นในช่วงนาทีเดียวกัน
                const matchedFlip = flipPoints.find((flip) => {
                    // console.log('flip', flip.time);

                    const timeParts = flip.time.split(':'); // แยก hh:mm.sss
                    const hours = parseInt(timeParts[0], 10);
                    const minutes = parseInt(timeParts[1], 10);
                    const seconds = timeParts[2] ? Math.floor(parseFloat(timeParts[2])) : 0; // ถ้าไม่มีวินาที ให้ใช้ 0

                    const flipTime = (hours * 3600) + (minutes * 60) + seconds; // คำนวณเป็นวินาทีของวัน
                    // console.log('flipTime', flipTime);

                    return Math.floor(clickTime % 86400 / 60) === Math.floor(flipTime / 60); // เปรียบเทียบเฉพาะระดับ "นาที"
                });

                let closestFlip = null;
                let minDiff = Infinity;

                for (const flip of flipPoints) {
                    const timeParts = flip.time.split(':');
                    const hours = parseInt(timeParts[0], 10);
                    const minutes = parseInt(timeParts[1], 10);
                    const seconds = timeParts[2] ? Math.floor(parseFloat(timeParts[2])) : 0;

                    const flipTime = (hours * 3600) + (minutes * 60) + seconds;

                    // ตรวจสอบว่า flipTime <= clickTime และเลือกอันที่ใกล้ที่สุด
                    if (flipTime <= (clickTime % 86400)) {
                        const diff = (clickTime % 86400) - flipTime;
                        if (diff < minDiff) {
                            minDiff = diff;
                            closestFlip = flip;
                        }
                    }
                }

                if (matchedFlip) {
                    handleRowClick(matchedFlip.index);
                    // console.log('match', matchedFlip);
                } else if (closestFlip) {
                    handleRowClick(closestFlip.index);
                    // console.log('closet', closestFlip);
                } else {
                    handleRowClick(0);
                    // console.log('not found');
                }
            });



            return () => chart.remove();
        }, [data, flipPoints]);

        return <div ref={chartContainerRef} style={{ width: '100%', height: '100%' }} />;
    };






    const detectPriceFlips = (data) => {
        let flips = [];
        let prevAccVol = null;

        data.forEach((value, index) => {
            const currentAccVol = parseFloat(value.acc_cum_vol); // แปลงเป็นตัวเลข

            if (
                prevAccVol !== null &&
                (prevAccVol > 0 && currentAccVol < 0 || prevAccVol < 0 && currentAccVol > 0)
            ) {
                flips.push({
                    index,
                    time: value.Time.split(" ")[1],
                    toVolume: value.Vol.toLocaleString(),
                    toType: value.Type,
                    toPrice: parseFloat(value.Last).toFixed(2),
                    fromAccVol: prevAccVol.toLocaleString(), // acc_cum_vol ก่อนหน้า
                    toAccVol: currentAccVol.toLocaleString(), // acc_cum_vol หลังเปลี่ยน
                });
            }

            prevAccVol = currentAccVol;
        });

        setFlipCount(flips.length);
        setFlipPoints(flips);
        // console.log("flip data", flips)
    };

    const detectPriceFlips2 = (data) => {
        let flips = [];
        let prevAccVol = null;

        data.forEach((value, index) => {
            const currentAccVol = parseFloat(value.acc_cum_vol); // แปลงเป็นตัวเลข

            if (
                prevAccVol !== null &&
                (prevAccVol > 0 && currentAccVol < 0 || prevAccVol < 0 && currentAccVol > 0)
            ) {
                flips.push({
                    index,
                    time: value.Time.split(" ")[1],
                    toVolume: value.Vol.toLocaleString(),
                    toType: value.Type,
                    toPrice: parseFloat(value.Last).toFixed(2),
                    fromAccVol: prevAccVol.toLocaleString(), // acc_cum_vol ก่อนหน้า
                    toAccVol: currentAccVol.toLocaleString(), // acc_cum_vol หลังเปลี่ยน
                });
            }

            prevAccVol = currentAccVol;
        });

        setFlipCount2(flips.length);
        setFlipPoints2(flips);
    };



    const handleSubmitForm1 = async () => {
        setRows([]);
        setIsSubmitted1(false);
        setIsFetch(prevState => ({
            ...prevState,
            'form1': true,
        }));
        const values = {
            symbol: initialValues.symbol1,
            start_date: formatDate(initialValues.date1),
            end_date: formatDate(initialValues.date1)
        };
        const response = await __fetching_data__({ url: `${base_api_url}/tickmatch_dejavu_report1?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        // console.log(response)
        if (response.status) {
            let newData = [];
            response.data.tickmatch?.map((value, index) => {
                newData.push(createData(
                    // value.Time.split(" ")[1],
                    value.Time,
                    parseFloat(value.Last).toFixed(2),
                    value.Vol.toLocaleString(),
                    value.Type,
                    value.acc_cum_vol.toLocaleString()))
            });
            // console.log(newData);
            // setSumBuy(prevState => [
            //     response.data.tickmatch_sum?.[0]?.sum_buy?.toLocaleString() || 0,
            //     ...prevState.slice(1)
            // ]);
            // setSumSell(prevState => [
            //     response.data.tickmatch_sum?.[0]?.sum_sell?.toLocaleString() || 0,
            //     ...prevState.slice(1)
            // ]);

            const sumBuyValue = response.data.tickmatch_sum?.[0]?.sum_buy || 0;
            const sumSellValue = response.data.tickmatch_sum?.[0]?.sum_sell || 0;
            const netVolumeValue = sumBuyValue - sumSellValue;


            setSumBuy(prevState => prevState.map((item, index) => index === 0 ? response.data.tickmatch_sum?.[0]?.sum_buy.toLocaleString() || 0 : item));
            setSumSell(prevState => prevState.map((item, index) => index === 0 ? response.data.tickmatch_sum?.[0]?.sum_sell.toLocaleString() || 0 : item));
            setNetVolume(prevState => prevState.map((item, index) =>
                index === 0 ? netVolumeValue : item
            ));



            setRows(newData);
            setIsSubmitted1(true);

            const stackedBarData = processStackedBarData(response.data.tickmatch);
            detectPriceFlips(response.data.tickmatch);
            setChartData(stackedBarData);
            setFlipPointsChart1(response.data.tickmatch);
        }
        endProcess();
    }
    const handleSubmitForm2 = async () => {
        setRows2([]);
        setIsSubmitted2(false);
        setIsFetch(prevState => ({
            ...prevState,
            'form2': true,
        }));
        const values = {
            symbol: initialValues.symbol2,
            start_date: formatDate(initialValues.date2),
            end_date: formatDate(initialValues.date2)
        };
        const response = await __fetching_data__({ url: `${base_api_url}/tickmatch_dejavu_report1?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        if (response.status) {
            let newData = [];
            response.data.tickmatch.map((value, index) => {
                newData.push(createData(
                    value.Time,
                    // value.Last,
                    // value.Time.split(" ")[1],
                    parseFloat(value.Last).toFixed(2),
                    value.Vol.toLocaleString(),
                    value.Type,
                    value.acc_cum_vol.toLocaleString()))
            });

            const sumBuyValue = response.data.tickmatch_sum?.[0]?.sum_buy || 0;
            const sumSellValue = response.data.tickmatch_sum?.[0]?.sum_sell || 0;
            const netVolumeValue = sumBuyValue - sumSellValue;

            setSumBuy(prevState => prevState.map((item, index) => index === 1 ? response.data.tickmatch_sum?.[0]?.sum_buy.toLocaleString() || 0 : item));
            setSumSell(prevState => prevState.map((item, index) => index === 1 ? response.data.tickmatch_sum?.[0]?.sum_sell.toLocaleString() || 0 : item));
            setNetVolume(prevState => prevState.map((item, index) => index === 1 ? netVolumeValue : item));

            detectPriceFlips2(response.data.tickmatch);

            setRows2(newData);
            setIsSubmitted2(true);

            const stackedBarData = processStackedBarData2(response.data.tickmatch);
            setChartData2(stackedBarData);
            setFlipPointsChart2(response.data.tickmatch);
        }
        endProcess();
    }

    const getValidDate = (date) => {
        let validDate = dayjs(date);
        while (disableWeekends(validDate)) {
            validDate = validDate.subtract(1, 'day');
        }
        return validDate;
    };


    const DataTable = ({ rows, isFetching, syncScrollRef }) => {
        const tableRef = useRef(null);

        return (
            <Grid item md={12} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ flex: 1 }}>
                <TableContainer
                    component={Paper}
                    ref={(ref) => {
                        tableRef.current = ref;
                        if (syncScrollRef && !syncScrollRef.current.includes(ref)) {
                            syncScrollRef.current.push(ref);
                        }
                    }}
                    onScroll={handleScroll}
                    sx={{
                        width: '100%',
                        height: '100%',
                        '@media (min-width: 600px)': {
                            width: '100%',
                            height: '100%',
                        },
                        // Table ***************
                        maxHeight: { xs: '32vh', sm: '30vh', md: '45vh', lg: '50vh', xl: '50vh' },
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888',
                            borderRadius: '4px',
                        },
                        '::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555',
                        },
                    }}
                >
                    <Table aria-label="simple table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                {['Time', 'Last', 'Vol', 'Type', 'Sum'].map((header) => (
                                    <StyledTableCell
                                        key={header}
                                        align="center"
                                        sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                    >
                                        {header}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isFetching ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <CircularProgress sx={{ color: theme.palette.text.primary }} />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                rows.map((row, index) => (
                                    <TableRow key={index}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even,
                                            "&:hover": { backgroundColor: theme.palette.background.default },
                                            td: {
                                                padding: '4px', fontSize: {
                                                    xs: 12,
                                                    sm: 12,
                                                    md: 10,
                                                    lg: 12,
                                                    xl: 12
                                                }, fontFamily: 'Source Sans Pro, sans-serif'
                                            }
                                        }}>
                                        <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.time}</TableCell>
                                        <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.last}</TableCell>
                                        <TableCell align="center" style={{ textAlign: 'right', padding: '4px', borderBottom: 0 }}>
                                            {row.vol}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ padding: '4px', color: row.type === 'BUY' ? colors.greenChart[100] : colors.redChart[100], borderBottom: 0 }}
                                        >
                                            {row.type}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{
                                                padding: '4px',
                                                borderBottom: 0,
                                                color: parseFloat(row.acc_cum_vol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                            }}
                                        >
                                            {row.acc_cum_vol}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    }

    const SyncButtonWithTooltip = ({ isSyncEnabled, onClick }) => {
        return (
            <Tooltip title={isSyncEnabled ? 'Click to disable scroll sync' : 'Click to enable scroll sync'}>
                <Button
                    onClick={onClick}
                    // variant={isSyncEnabled ? 'contained' : 'outlined'}
                    variant='contained'
                    startIcon={
                        isSyncEnabled ? (
                            <SyncIcon />
                        ) : (
                            <SyncDisabledIcon
                            // sx={{ transform: 'scaleY(-1)' }}
                            />
                        )
                    }
                    sx={{
                        backgroundColor: isSyncEnabled ? colors.greenChart[100] : '#283593',
                        // backgroundImage: 'linear-gradient(#6a11cb, #2575fc)',
                        color: 'white',
                        borderColor: isSyncEnabled ? colors.greenChart[100] : 'gray',
                        '&:hover': {
                            backgroundColor: isSyncEnabled ? colors.greenChart[100] : 'rgba(0, 0, 0, 0.04)', // Adjust hover state
                            color: isSyncEnabled ? 'white' : 'black'
                        },
                    }}
                >
                    {/* {isSyncEnabled ? 'Disable Sync' : 'Enable Sync'} */} Sync
                </Button>
            </Tooltip>
        );
    };

    const StackedBarChart = ({ chartData, isLoading }) => {

        const [open, setOpen] = useState(false);

        // ป้องกันการสร้าง chartData ใหม่ตลอดเวลา
        const memoizedChartData = useMemo(() => chartData, [chartData]);

        // ป้องกันการสร้างฟังก์ชันใหม่ทุกครั้งที่รีเรนเดอร์
        const handleOpen = useCallback(() => setOpen(true), []);
        const handleClose = useCallback(() => setOpen(false), []);


        if (isLoading) {
            return (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "0px" }}>
                    {/* <CircularProgress /> */}
                </Box>
            );
        }

        if (!chartData) return null;
        if (!memoizedChartData) return null;

        return (
            <Box sx={{
                marginTop: '5px', borderRadius: '10px', padding: 1, backgroundColor: theme.palette.background.container1,
                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
            }}>

                <Box sx={{ position: 'relative', borderRadius: '10px', backgroundColor: theme.palette.background.container }}>
                    <IconButton sx={{ position: 'absolute', top: 1, right: 1, }} onClick={handleOpen}>
                        <ZoomInIcon />
                    </IconButton>

                    <div style={{ width: "100%", height: "20vh", }}>

                        <Bar
                            data={memoizedChartData}
                            options={{
                                color: theme.palette.text.primary,
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        position: "top",
                                        labels: {
                                            color: theme.palette.text.primary, // สีฟอนต์ของ legend
                                            font: { size: 14 },
                                        },
                                    },
                                    zoom: {
                                        pan: { enabled: true, mode: "x" },
                                        zoom: {
                                            wheel: { enabled: true },
                                            pinch: { enabled: true },
                                            mode: "x",
                                        },
                                    },
                                },
                                scales: {
                                    x: {
                                        stacked: true,
                                        color: theme.palette.text.primary
                                    },
                                    y: {
                                        stacked: true,
                                        color: theme.palette.text.primary
                                    },

                                },
                            }}
                        />
                    </div>
                </Box>

                <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                    <Box sx={{ position: "relative", padding: 2 }}>
                        {/* ปุ่มปิด Popup */}
                        <IconButton sx={{ position: "absolute", top: 8, right: 8 }} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>

                        {/* กราฟขนาดใหญ่ */}
                        <div style={{ width: "100%", height: "70vh" }}>
                            <Bar
                                data={memoizedChartData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: { position: "top" },
                                        zoom: {
                                            pan: { enabled: true, mode: "x" },
                                            zoom: {
                                                wheel: { enabled: true },
                                                pinch: { enabled: true },
                                                mode: "x",
                                            },
                                        },
                                    },

                                    scales: {
                                        x: { stacked: true },
                                        y: { stacked: true },
                                    },
                                }}
                            />
                        </div>
                    </Box>
                </Dialog>
            </Box>

        );
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: isMobile ? 0 : '20px', fontFamily: 'Lato, Sans-serif', }}>
            <Box display="flex" justifyContent="center" alignItems="center" p="0">
                {/* <Header title="TickMatch" subtitle="" /> */}
                <Typography variant="h2" sx={{ fontWeight: 'bold', marginBottom: '1vh' }}>
                    TickMatch
                </Typography>
            </Box>

            <Box p="0" display="Box"
                sx={{
                    height: {
                        xs: '90vh', // height for extra-small screens
                        sm: '90vh', // height for small screens
                        md: '82vh', // height for medium screens
                        lg: '90vh', // height for large screens
                        xl: '90vh', // height for extra-large screens
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    // marginTop: '-2.3rem'
                }}>
                <Container maxWidth="xl" sx={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <Paper
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: isMobile ? 2 : 2,
                            // backgroundColor: theme.palette.background.container1,
                            maxHeight: {
                                xs: '90vh', // height for extra-small screens
                                sm: '90vh', // height for small screens
                                md: '90vh', // height for medium screens
                                lg: '90vh', // height for large screens
                                xl: '90vh', // height for extra-large screens
                            },
                        }}
                    >

                        {!isMobile && (
                            <Grid className="Container-tm" container
                                xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    // flex: '1',
                                    flexDirection: (isMobile || isPTablet) ? 'column' : 'row',
                                    // marginBottom: "1rem",
                                    flexWrap: 'nowrap',
                                    gap: 1
                                }}>
                                <Grid className="grid-tm1-nM" item xs={12} md={6}

                                    sx={{
                                        padding: 1,
                                        borderRadius: '10px',
                                        backgroundColor: theme.palette.background.container,
                                    }}
                                >
                                    <SyncButtonWithTooltip isSyncEnabled={isSyncEnabled} onClick={toggleSync} />



                                    {/* Tickmatch Table 1 */}

                                    <Box sx={{
                                        backgroundColor: theme.palette.background.container1,
                                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);',
                                        padding: 1,
                                        borderRadius: '10px',
                                        marginTop: 0.5,
                                    }}>
                                        <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0} sx={{ marginTop: -2 }}>
                                            <Grid container spacing={2} sx={{
                                                marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                                paddingRight: "0.5rem",
                                                // backgroundColor: colors.blueAccent[500],
                                                display: "flex",
                                                flexDirection: "row", // Ensure horizontal alignment
                                                justifyContent: "space-between", // Space items across the row
                                                alignItems: "center", // Align items vertically
                                                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                                gap: "0.5rem", // Optional: Spacing between items
                                            }}>

                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        id="symbol1"
                                                        name="symbol1"
                                                        label="Symbol"
                                                        variant="outlined"
                                                        required
                                                        size='small'
                                                        value={initialValues.symbol1}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                        <DatePicker
                                                            // fullWidth
                                                            id="date1"
                                                            name="date1"
                                                            label="Date"
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            // defaultValue={initialValues.date1}
                                                            defaultValue={getValidDate(initialValues.date1)}
                                                            maxDate={today_value.subtract(1, 'day')}
                                                            onChange={(value) => handleDateChange('date1', value)}
                                                            shouldDisableDate={disableWeekends}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={4} md={4}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '12px'
                                                        }
                                                    }}>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        sx={{ backgroundColor: theme.palette.table.thead1 }}
                                                        onClick={handleSubmitForm1}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} sx={{
                                                // marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                                paddingRight: "0.5rem",
                                                // backgroundColor: colors.blueAccent[500],
                                                display: "flex",
                                                flexDirection: "row", // Ensure horizontal alignment
                                                justifyContent: "end", // Space items across the row
                                                alignItems: "center", // Align items vertically
                                                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                                // gap: "0.5rem", // Optional: Spacing between items
                                            }}>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Buy"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumBuy[0]}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: colors.greenChart[100],
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: colors.greenChart[100],
                                                            },
                                                            // '& .MuiInputBase-root': {
                                                            //     backgroundColor: 'rgba(144, 238, 144, 0.3)',
                                                            // },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: colors.greenChart[100],
                                                                // minWidth: `${sumbuy.toString().length * 12}px`,
                                                                // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                                                // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                                                // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: colors.greenChart[100],
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: colors.greenChart[100],
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: colors.greenChart[100],
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Sell"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumSell[0]}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: colors.redChart[100],
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: colors.redChart[100],
                                                            },
                                                            // '& .MuiInputBase-root': {
                                                            //     backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                            // },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: colors.redChart[100],
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: colors.redChart[100],
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: colors.redChart[100],
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: colors.redChart[100],
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Net Acc. Vol"
                                                        variant="outlined"
                                                        size="small"
                                                        value={netVolume[0].toLocaleString()}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            // '& .MuiInputBase-root': {
                                                            //     backgroundColor: netVolume[0] === 0 ? 'transparent' : netVolume[0] < 0 ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.1)',
                                                            // },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item md={12} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ flex: 1 }}>
                                            <TableContainer
                                                component={Paper}
                                                ref={(ref) => {
                                                    tableRef.current = ref;
                                                    if (syncScrollRef && !syncScrollRef.current.includes(ref)) {
                                                        syncScrollRef.current.push(ref);
                                                    }
                                                }}
                                                onScroll={handleScroll}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    '@media (min-width: 600px)': {
                                                        width: '100%',
                                                        height: '100%',
                                                    },
                                                    // Table ***************
                                                    maxHeight: { xs: '32vh', sm: '30vh', md: '45vh', lg: '30vh', xl: '33vh' },
                                                    overflowY: 'auto',
                                                    '::-webkit-scrollbar': {
                                                        width: '8px',
                                                    },
                                                    '::-webkit-scrollbar-thumb': {
                                                        backgroundColor: '#888',
                                                        borderRadius: '4px',
                                                    },
                                                    '::-webkit-scrollbar-thumb:hover': {
                                                        backgroundColor: '#555',
                                                    },
                                                    backgroundColor: 'transparent'
                                                }}
                                            >
                                                <Table aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            {['Time', 'Last', 'Vol', 'Type', 'Sum'].map((header) => (
                                                                <StyledTableCell
                                                                    key={header}
                                                                    align="center"
                                                                    sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                                                >
                                                                    {header}
                                                                </StyledTableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {isFetch.form1 ? (
                                                            <TableRow>
                                                                <TableCell colSpan={5} align="center">
                                                                    <CircularProgress sx={{ color: theme.palette.text.primary }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            rows.map((row, index) => (
                                                                <TableRow key={index}
                                                                    ref={el => rowRefs.current[index] = el}
                                                                    sx={{
                                                                        backgroundColor: highlightedIndex === index
                                                                            ? "#ffeb3b80" // สีไฮไลต์ ถ้าถูกเลือก
                                                                            : index % 2 === 0
                                                                                ? theme.palette.table.odd  // สีพื้นหลังสลับปกติ
                                                                                : theme.palette.table.even,
                                                                        transition: "background-color 0.5s ease-in-out",
                                                                        "&:hover": { backgroundColor: theme.palette.background.default },
                                                                        td: {
                                                                            padding: '4px', fontSize: {
                                                                                xs: 12,
                                                                                sm: 12,
                                                                                md: 10,
                                                                                lg: 12,
                                                                                xl: 12
                                                                            }, fontFamily: 'Source Sans Pro, sans-serif'
                                                                        }
                                                                    }}>
                                                                    <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.time.split(" ")[1]}</TableCell>
                                                                    <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.last}</TableCell>
                                                                    <TableCell align="center" style={{ textAlign: 'right', padding: '4px', borderBottom: 0 }}>
                                                                        {row.vol}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        style={{ padding: '4px', color: row.type === 'BUY' ? colors.greenChart[100] : colors.redChart[100], borderBottom: 0 }}
                                                                    >
                                                                        {row.type}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        style={{
                                                                            padding: '4px',
                                                                            borderBottom: 0,
                                                                            color: parseFloat(row.acc_cum_vol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                        }}
                                                                    >
                                                                        {row.acc_cum_vol}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Box>




                                    {isSubmitted1 && (<>

                                        <Box sx={{
                                            padding: 1,
                                            backgroundColor: theme.palette.background.container1,
                                            marginTop: 1,
                                            borderRadius: '10px',
                                            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
                                        }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '5px' }}>
                                                <Typography variant="h6">Total Flip Count: {flipCount}</Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                                    <Box sx={{ padding: '5px 10px', backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                                        <Box sx={{ width: '20px', height: '10px', backgroundColor: colors.redChart[100], borderRadius: '2px' }} />
                                                        <Typography variant="h6" sx={{ mr: '10px' }}>{'Net Vol < 0'}</Typography>
                                                        <Box sx={{ width: '20px', height: '10px', backgroundColor: colors.greenChart[100], borderRadius: '2px' }} />
                                                        <Typography variant="h6">{'Net Vol > 0'}</Typography>
                                                    </Box>
                                                    <IconButton onClick={toggleTableVisibility}>
                                                        {isTableVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                </Box>
                                            </Box>

                                            {isTableVisible && (
                                                <>
                                                    <Box sx={{
                                                        padding: '10px 10px 0px 10px',
                                                        borderRadius: '10px',
                                                        backgroundColor: theme.palette.background.container,
                                                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
                                                    }}>
                                                        <FlipTimelineChart
                                                            data={flipPointsChart1}
                                                            flipPoints={flipPoints}
                                                            handleRowClick={handleRowClick}
                                                            rows={rows}
                                                        />
                                                    </Box>

                                                    <TableContainer component={Paper} sx={{ marginTop: 1, backgroundColor: 'transparent' }}>
                                                        <Table>
                                                            <TableHead >
                                                                <TableRow >
                                                                    {[
                                                                        'ครั้งที่',
                                                                        'Time',
                                                                        //   'Last',
                                                                        //    'Vol',
                                                                        // 'Type', 
                                                                        'From Acc. Vol',
                                                                        'To Acc. Vol'
                                                                    ].map((header) => (
                                                                        <StyledTableCell
                                                                            key={header}
                                                                            align="center"
                                                                            sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                                                        >
                                                                            {header}
                                                                        </StyledTableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {flipPoints.length === 0 ? (
                                                                    <TableRow>
                                                                        <TableCell colSpan={7} align="center" sx={{ padding: '16px', fontSize: '14px', color: theme.palette.text.primary }}>
                                                                            No Flip Data
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : (
                                                                    flipPoints.map((flip, index) => (
                                                                        <TableRow key={index}
                                                                            onClick={() => handleRowClick(flip.index)}
                                                                            sx={{ backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even, cursor: 'pointer' }}>
                                                                            <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{index + 1}</TableCell>
                                                                            <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{flip.time}</TableCell>
                                                                            {/* <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{flip.toPrice}</TableCell> */}
                                                                            {/* <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{flip.toVolume}</TableCell> */}
                                                                            {/* <TableCell align="center" style={{ padding: '4px', color: flip.toType === 'BUY' ? colors.greenChart[100] : colors.redChart[100], borderBottom: 0 }}>{flip.toType}</TableCell> */}
                                                                            <TableCell align="center" style={{
                                                                                padding: '4px', borderBottom: 0, color: parseFloat(flip.fromAccVol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                            }}>{flip.fromAccVol}</TableCell>
                                                                            <TableCell align="center" style={{
                                                                                padding: '4px',
                                                                                borderBottom: 0,
                                                                                color: parseFloat(flip.toAccVol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                            }}>{flip.toAccVol}</TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </>
                                            )}
                                        </Box></>)}


                                    <StackedBarChart chartData={chartData} isLoading={isFetch.form1} />



                                </Grid>
                                {/* Tickmatch Table 2 */}
                                <Grid className="grid-tm2-nM" item xs={12} md={6}
                                    sx={{
                                        padding: 1,
                                        borderRadius: '10px',
                                        backgroundColor: theme.palette.background.container,
                                    }}
                                >
                                    {!isPTablet &&
                                        (<>
                                            <SyncButtonWithTooltip isSyncEnabled={isSyncEnabled} onClick={toggleSync} />
                                        </>)}

                                    <Box sx={{
                                        backgroundColor: theme.palette.background.container1,
                                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);',
                                        padding: 1,
                                        borderRadius: '10px',
                                        marginTop: 0.5
                                    }}>
                                        <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0} sx={{ marginTop: -2 }}>
                                            <Grid container spacing={2} sx={{
                                                marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                                paddingRight: "0.5rem",
                                                // backgroundColor: colors.blueAccent[500],
                                                display: "flex",
                                                flexDirection: "row", // Ensure horizontal alignment
                                                justifyContent: "space-between", // Space items across the row
                                                alignItems: "center", // Align items vertically
                                                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                                gap: "0.5rem", // Optional: Spacing between items
                                            }}>

                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        id="symbol2"
                                                        name="symbol2"
                                                        label="Symbol"
                                                        variant="outlined"
                                                        required
                                                        size='small'
                                                        value={initialValues.symbol2}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                        <DatePicker
                                                            // fullWidth
                                                            id="date2"
                                                            name="date2"
                                                            label="Date"
                                                            slotProps={{ textField: { size: 'small' } }}
                                                            // defaultValue={initialValues.date1}
                                                            defaultValue={getValidDate(initialValues.date2)}
                                                            maxDate={today_value.subtract(1, 'day')}
                                                            onChange={(value) => handleDateChange('date2', value)}
                                                            shouldDisableDate={disableWeekends}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                                <Grid item xs={4} md={4}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '12px'
                                                        }
                                                    }}>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        sx={{ backgroundColor: theme.palette.table.thead1 }}
                                                        onClick={handleSubmitForm2}
                                                    >
                                                        Search
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} sx={{
                                                // marginTop: "0.5rem",
                                                marginBottom: "0.5rem",
                                                paddingRight: "0.5rem",
                                                // backgroundColor: colors.blueAccent[500],
                                                display: "flex",
                                                flexDirection: "row", // Ensure horizontal alignment
                                                justifyContent: "end", // Space items across the row
                                                alignItems: "center", // Align items vertically
                                                flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                                // gap: "0.5rem", // Optional: Spacing between items
                                            }}>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Buy"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumBuy[1]}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: colors.greenChart[100],
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: colors.greenChart[100],
                                                            },
                                                            // '& .MuiInputBase-root': {
                                                            //     backgroundColor: 'rgba(144, 238, 144, 0.3)',
                                                            // },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: colors.greenChart[100],
                                                                // minWidth: `${sumbuy.toString().length * 12}px`,
                                                                // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                                                // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                                                // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: colors.greenChart[100],
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: colors.greenChart[100],
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: colors.greenChart[100],
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Sum Sell"
                                                        variant="outlined"
                                                        size="small"
                                                        value={sumSell[1]}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: colors.redChart[100],
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: colors.redChart[100],
                                                            },
                                                            // '& .MuiInputBase-root': {
                                                            //     backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                            // },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: colors.redChart[100],
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: colors.redChart[100],
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: colors.redChart[100],
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: colors.redChart[100],
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} md={4} sx={{
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Net Acc. Vol"
                                                        variant="outlined"
                                                        size="small"
                                                        value={netVolume[1].toLocaleString()}
                                                        InputProps={{
                                                            readOnly: true,

                                                        }}
                                                        sx={{
                                                            '& .MuiInputLabel-root': {
                                                                color: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            '& .MuiInputLabel-root.Mui-focused': {
                                                                color: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            // '& .MuiInputBase-root': {
                                                            //     backgroundColor: netVolume[1] === 0 ? 'transparent' : netVolume[1] < 0 ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.1)',
                                                            // },
                                                            '& .MuiInputBase-input': {
                                                                textAlign: 'right',
                                                                fontSize: 'calc(1rem - 1px)',
                                                                fontWeight: 'bold',
                                                                color: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            '& .MuiOutlinedInput-root': {
                                                                '& fieldset': {
                                                                    borderColor: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item md={12} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ flex: 1 }}>
                                            <TableContainer
                                                component={Paper}
                                                ref={(ref) => {
                                                    tableRef.current = ref;
                                                    if (syncScrollRef && !syncScrollRef.current.includes(ref)) {
                                                        syncScrollRef.current.push(ref);
                                                    }
                                                }}
                                                onScroll={handleScroll}
                                                sx={{
                                                    width: '100%',
                                                    height: '100%',
                                                    '@media (min-width: 600px)': {
                                                        width: '100%',
                                                        height: '100%',
                                                    },
                                                    // Table ***************
                                                    maxHeight: { xs: '32vh', sm: '30vh', md: '45vh', lg: '30vh', xl: '33vh' },
                                                    overflowY: 'auto',
                                                    '::-webkit-scrollbar': {
                                                        width: '8px',
                                                    },
                                                    '::-webkit-scrollbar-thumb': {
                                                        backgroundColor: '#888',
                                                        borderRadius: '4px',
                                                    },
                                                    '::-webkit-scrollbar-thumb:hover': {
                                                        backgroundColor: '#555',
                                                    },
                                                    backgroundColor: 'transparent'
                                                }}
                                            >
                                                <Table aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            {['Time', 'Last', 'Vol', 'Type', 'Sum'].map((header) => (
                                                                <StyledTableCell
                                                                    key={header}
                                                                    align="center"
                                                                    sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                                                >
                                                                    {header}
                                                                </StyledTableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {isFetch.form2 ? (
                                                            <TableRow>
                                                                <TableCell colSpan={5} align="center">
                                                                    <CircularProgress sx={{ color: theme.palette.text.primary }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            rows2.map((row, index) => (
                                                                <TableRow key={index}
                                                                    ref={el => rowRefs2.current[index] = el}
                                                                    sx={{
                                                                        backgroundColor: highlightedIndex2 === index
                                                                            ? "#ffeb3b80" // สีไฮไลต์ ถ้าถูกเลือก
                                                                            : index % 2 === 0
                                                                                ? theme.palette.table.odd  // สีพื้นหลังสลับปกติ
                                                                                : theme.palette.table.even,
                                                                        transition: "background-color 0.5s ease-in-out",
                                                                        "&:hover": { backgroundColor: theme.palette.background.default },
                                                                        td: {
                                                                            padding: '4px', fontSize: {
                                                                                xs: 12,
                                                                                sm: 12,
                                                                                md: 10,
                                                                                lg: 12,
                                                                                xl: 12
                                                                            }, fontFamily: 'Source Sans Pro, sans-serif'
                                                                        }
                                                                    }}>
                                                                    <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.time.split(" ")[1]}</TableCell>
                                                                    <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.last}</TableCell>
                                                                    <TableCell align="center" style={{ textAlign: 'right', padding: '4px', borderBottom: 0 }}>
                                                                        {row.vol}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        style={{ padding: '4px', color: row.type === 'BUY' ? colors.greenChart[100] : colors.redChart[100], borderBottom: 0 }}
                                                                    >
                                                                        {row.type}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="center"
                                                                        style={{
                                                                            padding: '4px',
                                                                            borderBottom: 0,
                                                                            color: parseFloat(row.acc_cum_vol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                        }}
                                                                    >
                                                                        {row.acc_cum_vol}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Box>

                                    {isSubmitted2 && (<>
                                        <Box sx={{
                                            padding: 1,
                                            backgroundColor: theme.palette.background.container1,
                                            marginTop: 1,
                                            borderRadius: '10px',
                                            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
                                        }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '5px' }}>
                                                <Typography variant="h6">Total Flip Count: {flipCount2}</Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                                    <Box sx={{ padding: '5px 10px', backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                                        <Box sx={{ width: '20px', height: '10px', backgroundColor: colors.redChart[100], borderRadius: '2px' }} />
                                                        <Typography variant="h6" sx={{ mr: '10px' }}>{'Net Vol < 0'}</Typography>
                                                        <Box sx={{ width: '20px', height: '10px', backgroundColor: colors.greenChart[100], borderRadius: '2px' }} />
                                                        <Typography variant="h6">{'Net Vol > 0'}</Typography>
                                                    </Box>
                                                    <IconButton onClick={toggleTableVisibility}>
                                                        {isTableVisible2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                </Box>

                                            </Box>

                                            {isTableVisible2 && (<>
                                                <Box sx={{
                                                    padding: '10px 10px 0px 10px',
                                                    borderRadius: '10px',
                                                    backgroundColor: theme.palette.background.container,
                                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
                                                }}>
                                                    <FlipTimelineChart
                                                        data={flipPointsChart2}
                                                        flipPoints={flipPoints2}
                                                        handleRowClick={handleRowClick2}
                                                        rows={rows2}
                                                    />
                                                </Box>
                                                <TableContainer component={Paper} sx={{ marginTop: 1, backgroundColor: 'transparent' }}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                {[
                                                                    'ครั้งที่',
                                                                    'Time',
                                                                    //   'Last',
                                                                    //    'Vol',
                                                                    // 'Type', 
                                                                    'From Acc. Vol',
                                                                    'To Acc. Vol'
                                                                ].map((header) => (
                                                                    <StyledTableCell
                                                                        key={header}
                                                                        align="center"
                                                                        sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                                                    >
                                                                        {header}
                                                                    </StyledTableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {flipPoints2.length === 0 ? (
                                                                <TableRow>
                                                                    <TableCell colSpan={7} align="center" sx={{ padding: '16px', fontSize: '14px', color: theme.palette.text.primary }}>
                                                                        No Flip Data
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : (
                                                                flipPoints2.map((flip, index) => (
                                                                    <TableRow key={index}
                                                                        onClick={() => handleRowClick2(flip.index)}
                                                                        sx={{ backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even, cursor: 'pointer' }}>
                                                                        <TableCell align="center" style={{ padding: '4px', borderBottom: 0, }}>{index + 1}</TableCell>
                                                                        <TableCell align="center" style={{ padding: '4px', borderBottom: 0, }}>{flip.time}</TableCell>
                                                                        {/* <TableCell align="center" style={{ textAlign: 'right', borderBottom: 0, padding: '4px' }}>{flip.toPrice}</TableCell> */}
                                                                        {/* <TableCell align="center" style={{ padding: '4px', borderBottom: 0, }}>{flip.toVolume}</TableCell> */}
                                                                        {/* <TableCell align="center" style={{ padding: '4px', borderBottom: 0, color: flip.toType === 'BUY' ? colors.greenChart[100] : colors.redChart[100] }}>{flip.toType}</TableCell> */}
                                                                        <TableCell align="center" style={{
                                                                            padding: '4px', borderBottom: 0, color: parseFloat(flip.fromAccVol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                        }}>{flip.fromAccVol}</TableCell>
                                                                        <TableCell align="center" style={{
                                                                            padding: '4px',
                                                                            borderBottom: 0,
                                                                            color: parseFloat(flip.toAccVol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                        }}>{flip.toAccVol}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                            )}
                                        </Box></>)}

                                    <StackedBarChart chartData={chartData2} isLoading={isFetch.form2} />

                                </Grid>
                            </Grid>)}
                        {isMobile && <>
                            <Grid item xs={12} md={6} sx={{
                                // height: {
                                //     xs: '82vh',
                                //     sm: '82vh',
                                //     md: '82vh',
                                //     lg: '90vh',
                                //     xl: '95vh',
                                // },
                                padding: 0.5,
                                borderRadius: '10px',
                                backgroundColor: theme.palette.background.container,
                            }}>
                                <SyncButtonWithTooltip isSyncEnabled={isSyncEnabled} onClick={toggleSync} />

                                {/* Table 1 */}
                                <Box sx={{
                                    backgroundColor: theme.palette.background.container1,
                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);',
                                    padding: 1,
                                    borderRadius: '10px',
                                    marginTop: 0.5,
                                }}>
                                    <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0} sx={{ marginTop: -2 }}>
                                        <Grid container spacing={2} sx={{
                                            marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            paddingRight: "0.5rem",
                                            // backgroundColor: colors.blueAccent[500],
                                            display: "flex",
                                            flexDirection: "row", // Ensure horizontal alignment
                                            justifyContent: "space-between", // Space items across the row
                                            alignItems: "center", // Align items vertically
                                            flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                            gap: "0.5rem", // Optional: Spacing between items
                                        }}>

                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    id="symbol1"
                                                    name="symbol1"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues.symbol1}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        // fullWidth
                                                        id="date1"
                                                        name="date1"
                                                        label="Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        // defaultValue={initialValues.date1}
                                                        defaultValue={getValidDate(initialValues.date1)}
                                                        maxDate={today_value.subtract(1, 'day')}
                                                        onChange={(value) => handleDateChange('date1', value)}
                                                        shouldDisableDate={disableWeekends}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={4}
                                                sx={{
                                                    fontSize: {
                                                        xs: '10px',
                                                        sm: '12px'
                                                    }
                                                }}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ backgroundColor: theme.palette.table.thead1 }}
                                                    onClick={handleSubmitForm1}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2} sx={{
                                            // marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            paddingRight: "0.5rem",
                                            // backgroundColor: colors.blueAccent[500],
                                            display: "flex",
                                            flexDirection: "row", // Ensure horizontal alignment
                                            justifyContent: "end", // Space items across the row
                                            alignItems: "center", // Align items vertically
                                            flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                            // gap: "0.5rem", // Optional: Spacing between items
                                        }}>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Sum Buy"
                                                    variant="outlined"
                                                    size="small"
                                                    value={sumBuy[0]}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: colors.greenChart[100],
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: colors.greenChart[100],
                                                        },
                                                        // '& .MuiInputBase-root': {
                                                        //     backgroundColor: 'rgba(144, 238, 144, 0.3)',
                                                        // },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: colors.greenChart[100],
                                                            // minWidth: `${sumbuy.toString().length * 12}px`,
                                                            // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                                            // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                                            // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: colors.greenChart[100],
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: colors.greenChart[100],
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: colors.greenChart[100],
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Sum Sell"
                                                    variant="outlined"
                                                    size="small"
                                                    value={sumSell[0]}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: colors.redChart[100],
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: colors.redChart[100],
                                                        },
                                                        // '& .MuiInputBase-root': {
                                                        //     backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                        // },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: colors.redChart[100],
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: colors.redChart[100],
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: colors.redChart[100],
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: colors.redChart[100],
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Net Acc. Vol"
                                                    variant="outlined"
                                                    size="small"
                                                    value={netVolume[0].toLocaleString()}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                        },
                                                        // '& .MuiInputBase-root': {
                                                        //     backgroundColor: netVolume[0] === 0 ? 'transparent' : netVolume[0] < 0 ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.1)',
                                                        // },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: netVolume[0] === 0 ? 'normal' : netVolume[0] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={12} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ flex: 1 }}>
                                        <TableContainer
                                            component={Paper}
                                            ref={(ref) => {
                                                tableRef.current = ref;
                                                if (syncScrollRef && !syncScrollRef.current.includes(ref)) {
                                                    syncScrollRef.current.push(ref);
                                                }
                                            }}
                                            onScroll={handleScroll}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                '@media (min-width: 600px)': {
                                                    width: '100%',
                                                    height: '100%',
                                                },
                                                // Table ***************
                                                maxHeight: { xs: '20vh', sm: '30vh', md: '45vh', lg: '30vh', xl: '33vh' },
                                                overflowY: 'auto',
                                                '::-webkit-scrollbar': {
                                                    width: '8px',
                                                },
                                                '::-webkit-scrollbar-thumb': {
                                                    backgroundColor: '#888',
                                                    borderRadius: '4px',
                                                },
                                                '::-webkit-scrollbar-thumb:hover': {
                                                    backgroundColor: '#555',
                                                },
                                                backgroundColor: 'transparent'
                                            }}
                                        >
                                            <Table aria-label="simple table" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {['Time', 'Last', 'Vol', 'Type', 'Sum'].map((header) => (
                                                            <StyledTableCell
                                                                key={header}
                                                                align="center"
                                                                sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                                            >
                                                                {header}
                                                            </StyledTableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {isFetch.form1 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={5} align="center">
                                                                <CircularProgress sx={{ color: theme.palette.text.primary }} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        rows.map((row, index) => (
                                                            <TableRow key={index}
                                                                ref={el => rowRefs.current[index] = el}
                                                                sx={{
                                                                    backgroundColor: highlightedIndex === index
                                                                        ? "#ffeb3b80" // สีไฮไลต์ ถ้าถูกเลือก
                                                                        : index % 2 === 0
                                                                            ? theme.palette.table.odd  // สีพื้นหลังสลับปกติ
                                                                            : theme.palette.table.even,
                                                                    transition: "background-color 0.5s ease-in-out",
                                                                    "&:hover": { backgroundColor: theme.palette.background.default },
                                                                    td: {
                                                                        padding: '4px', fontSize: {
                                                                            xs: 12,
                                                                            sm: 12,
                                                                            md: 10,
                                                                            lg: 12,
                                                                            xl: 12
                                                                        }, fontFamily: 'Source Sans Pro, sans-serif'
                                                                    }
                                                                }}>
                                                                <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.time.split(" ")[1]}</TableCell>
                                                                <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.last}</TableCell>
                                                                <TableCell align="center" style={{ textAlign: 'right', padding: '4px', borderBottom: 0 }}>
                                                                    {row.vol}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    style={{ padding: '4px', color: row.type === 'BUY' ? colors.greenChart[100] : colors.redChart[100], borderBottom: 0 }}
                                                                >
                                                                    {row.type}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    style={{
                                                                        padding: '4px',
                                                                        borderBottom: 0,
                                                                        color: parseFloat(row.acc_cum_vol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                    }}
                                                                >
                                                                    {row.acc_cum_vol}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Box>

                                {isSubmitted1 && (<>

                                    <Box sx={{
                                        padding: 1,
                                        backgroundColor: theme.palette.background.container1,
                                        marginTop: 1,
                                        borderRadius: '10px',
                                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '5px' }}>
                                            <Typography variant="h6">Total Flip Count: {flipCount}</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                                <Box sx={{ padding: '5px 10px', backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                                    <Box sx={{ width: '20px', height: '10px', backgroundColor: colors.redChart[100], borderRadius: '2px' }} />
                                                    <Typography variant="h6" sx={{ mr: '10px' }}>{'Net Vol < 0'}</Typography>
                                                    <Box sx={{ width: '20px', height: '10px', backgroundColor: colors.greenChart[100], borderRadius: '2px' }} />
                                                    <Typography variant="h6">{'Net Vol > 0'}</Typography>
                                                </Box>
                                                <IconButton onClick={toggleTableVisibility}>
                                                    {isTableVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Box>
                                        </Box>

                                        {isTableVisible && (
                                            <>
                                                <Box sx={{
                                                    padding: '10px 10px 0px 10px',
                                                    borderRadius: '10px',
                                                    backgroundColor: theme.palette.background.container,
                                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
                                                }}>
                                                    <FlipTimelineChart
                                                        data={flipPointsChart1}
                                                        flipPoints={flipPoints}
                                                        handleRowClick={handleRowClick}
                                                        rows={rows}
                                                    />
                                                </Box>

                                                <TableContainer component={Paper} sx={{ marginTop: 1, backgroundColor: 'transparent' }}>
                                                    <Table>
                                                        <TableHead >
                                                            <TableRow >
                                                                {[
                                                                    'ครั้งที่',
                                                                    'Time',
                                                                    //   'Last',
                                                                    //    'Vol',
                                                                    // 'Type', 
                                                                    'From Acc. Vol',
                                                                    'To Acc. Vol'
                                                                ].map((header) => (
                                                                    <StyledTableCell
                                                                        key={header}
                                                                        align="center"
                                                                        sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                                                    >
                                                                        {header}
                                                                    </StyledTableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {flipPoints.length === 0 ? (
                                                                <TableRow>
                                                                    <TableCell colSpan={7} align="center" sx={{ padding: '16px', fontSize: '14px', color: theme.palette.text.primary }}>
                                                                        No Flip Data
                                                                    </TableCell>
                                                                </TableRow>
                                                            ) : (
                                                                flipPoints.map((flip, index) => (
                                                                    <TableRow key={index}
                                                                        onClick={() => handleRowClick(flip.index)}
                                                                        sx={{ backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even, cursor: 'pointer' }}>
                                                                        <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{index + 1}</TableCell>
                                                                        <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{flip.time}</TableCell>
                                                                        {/* <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{flip.toPrice}</TableCell> */}
                                                                        {/* <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{flip.toVolume}</TableCell> */}
                                                                        {/* <TableCell align="center" style={{ padding: '4px', color: flip.toType === 'BUY' ? colors.greenChart[100] : colors.redChart[100], borderBottom: 0 }}>{flip.toType}</TableCell> */}
                                                                        <TableCell align="center" style={{
                                                                            padding: '4px', borderBottom: 0, color: parseFloat(flip.fromAccVol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                        }}>{flip.fromAccVol}</TableCell>
                                                                        <TableCell align="center" style={{
                                                                            padding: '4px',
                                                                            borderBottom: 0,
                                                                            color: parseFloat(flip.toAccVol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                        }}>{flip.toAccVol}</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        )}
                                    </Box></>)}
                                <StackedBarChart chartData={chartData} isLoading={isFetch.form1} />
                            </Grid>
                            <Grid item xs={12} md={6}
                                sx={{
                                    padding: 0.5,
                                    borderRadius: '10px',
                                    backgroundColor: theme.palette.background.container1,
                                }}>


                                {/* Table 2 */}
                                <Box sx={{
                                    backgroundColor: theme.palette.background.container1,
                                    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);',
                                    padding: 1,
                                    borderRadius: '10px',
                                    marginTop: 0.5
                                }}>
                                    <Grid container xs={12} sm={12} md={12} lg={12} p={0} m={0} sx={{ marginTop: -2 }}>
                                        <Grid container spacing={2} sx={{
                                            marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            paddingRight: "0.5rem",
                                            // backgroundColor: colors.blueAccent[500],
                                            display: "flex",
                                            flexDirection: "row", // Ensure horizontal alignment
                                            justifyContent: "space-between", // Space items across the row
                                            alignItems: "center", // Align items vertically
                                            flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                            gap: "0.5rem", // Optional: Spacing between items
                                        }}>

                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    id="symbol2"
                                                    name="symbol2"
                                                    label="Symbol"
                                                    variant="outlined"
                                                    required
                                                    size='small'
                                                    value={initialValues.symbol2}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                    <DatePicker
                                                        // fullWidth
                                                        id="date2"
                                                        name="date2"
                                                        label="Date"
                                                        slotProps={{ textField: { size: 'small' } }}
                                                        // defaultValue={initialValues.date1}
                                                        defaultValue={getValidDate(initialValues.date2)}
                                                        maxDate={today_value.subtract(1, 'day')}
                                                        onChange={(value) => handleDateChange('date2', value)}
                                                        shouldDisableDate={disableWeekends}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={4} md={4}
                                                sx={{
                                                    fontSize: {
                                                        xs: '10px',
                                                        sm: '12px'
                                                    }
                                                }}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{ backgroundColor: theme.palette.table.thead1 }}
                                                    onClick={handleSubmitForm2}
                                                >
                                                    Search
                                                </Button>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2} sx={{
                                            // marginTop: "0.5rem",
                                            marginBottom: "0.5rem",
                                            paddingRight: "0.5rem",
                                            // backgroundColor: colors.blueAccent[500],
                                            display: "flex",
                                            flexDirection: "row", // Ensure horizontal alignment
                                            justifyContent: "end", // Space items across the row
                                            alignItems: "center", // Align items vertically
                                            flexWrap: "nowrap", // Prevent items from wrapping to the next line
                                            // gap: "0.5rem", // Optional: Spacing between items
                                        }}>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Sum Buy"
                                                    variant="outlined"
                                                    size="small"
                                                    value={sumBuy[1]}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: colors.greenChart[100],
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: colors.greenChart[100],
                                                        },
                                                        // '& .MuiInputBase-root': {
                                                        //     backgroundColor: 'rgba(144, 238, 144, 0.3)',
                                                        // },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: colors.greenChart[100],
                                                            // minWidth: `${sumbuy.toString().length * 12}px`,
                                                            // overflow: 'hidden', // ป้องกันไม่ให้ข้อความล้น
                                                            // textOverflow: 'ellipsis', // ตัดข้อความด้วย ... หากยาวเกิน
                                                            // whiteSpace: 'nowrap', // บังคับให้ข้อความอยู่ในบรรทัดเดียว
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: colors.greenChart[100],
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: colors.greenChart[100],
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: colors.greenChart[100],
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Sum Sell"
                                                    variant="outlined"
                                                    size="small"
                                                    value={sumSell[1]}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: colors.redChart[100],
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: colors.redChart[100],
                                                        },
                                                        // '& .MuiInputBase-root': {
                                                        //     backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                        // },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: colors.redChart[100],
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: colors.redChart[100],
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: colors.redChart[100],
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: colors.redChart[100],
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} sx={{
                                            }}>
                                                <TextField
                                                    fullWidth
                                                    label="Net Acc. Vol"
                                                    variant="outlined"
                                                    size="small"
                                                    value={netVolume[1].toLocaleString()}
                                                    InputProps={{
                                                        readOnly: true,

                                                    }}
                                                    sx={{
                                                        '& .MuiInputLabel-root': {
                                                            color: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                        },
                                                        '& .MuiInputLabel-root.Mui-focused': {
                                                            color: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                        },
                                                        // '& .MuiInputBase-root': {
                                                        //     backgroundColor: netVolume[1] === 0 ? 'transparent' : netVolume[1] < 0 ? 'rgba(255, 0, 0, 0.1)' : 'rgba(0, 255, 0, 0.1)',
                                                        // },
                                                        '& .MuiInputBase-input': {
                                                            textAlign: 'right',
                                                            fontSize: 'calc(1rem - 1px)',
                                                            fontWeight: 'bold',
                                                            color: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                        },
                                                        '& .MuiOutlinedInput-root': {
                                                            '& fieldset': {
                                                                borderColor: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            '&:hover fieldset': {
                                                                borderColor: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: netVolume[1] === 0 ? 'normal' : netVolume[1] < 0 ? colors.redChart[100] : colors.greenChart[100],
                                                            },
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ flex: 1 }}>
                                        <TableContainer
                                            component={Paper}
                                            ref={(ref) => {
                                                tableRef.current = ref;
                                                if (syncScrollRef && !syncScrollRef.current.includes(ref)) {
                                                    syncScrollRef.current.push(ref);
                                                }
                                            }}
                                            onScroll={handleScroll}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                '@media (min-width: 600px)': {
                                                    width: '100%',
                                                    height: '100%',
                                                },
                                                // Table ***************
                                                maxHeight: { xs: '20vh', sm: '30vh', md: '45vh', lg: '30vh', xl: '33vh' },
                                                overflowY: 'auto',
                                                '::-webkit-scrollbar': {
                                                    width: '8px',
                                                },
                                                '::-webkit-scrollbar-thumb': {
                                                    backgroundColor: '#888',
                                                    borderRadius: '4px',
                                                },
                                                '::-webkit-scrollbar-thumb:hover': {
                                                    backgroundColor: '#555',
                                                },
                                                backgroundColor: 'transparent'
                                            }}
                                        >
                                            <Table aria-label="simple table" stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {['Time', 'Last', 'Vol', 'Type', 'Sum'].map((header) => (
                                                            <StyledTableCell
                                                                key={header}
                                                                align="center"
                                                                sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                                            >
                                                                {header}
                                                            </StyledTableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {isFetch.form2 ? (
                                                        <TableRow>
                                                            <TableCell colSpan={5} align="center">
                                                                <CircularProgress sx={{ color: theme.palette.text.primary }} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (
                                                        rows2.map((row, index) => (
                                                            <TableRow key={index}
                                                                ref={el => rowRefs2.current[index] = el}
                                                                sx={{
                                                                    backgroundColor: highlightedIndex2 === index
                                                                        ? "#ffeb3b80" // สีไฮไลต์ ถ้าถูกเลือก
                                                                        : index % 2 === 0
                                                                            ? theme.palette.table.odd  // สีพื้นหลังสลับปกติ
                                                                            : theme.palette.table.even,
                                                                    transition: "background-color 0.5s ease-in-out",
                                                                    "&:hover": { backgroundColor: theme.palette.background.default },
                                                                    td: {
                                                                        padding: '4px', fontSize: {
                                                                            xs: 12,
                                                                            sm: 12,
                                                                            md: 10,
                                                                            lg: 12,
                                                                            xl: 12
                                                                        }, fontFamily: 'Source Sans Pro, sans-serif'
                                                                    }
                                                                }}>
                                                                <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.time.split(" ")[1]}</TableCell>
                                                                <TableCell align="center" style={{ padding: '4px', borderBottom: 0 }}>{row.last}</TableCell>
                                                                <TableCell align="center" style={{ textAlign: 'right', padding: '4px', borderBottom: 0 }}>
                                                                    {row.vol}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    style={{ padding: '4px', color: row.type === 'BUY' ? colors.greenChart[100] : colors.redChart[100], borderBottom: 0 }}
                                                                >
                                                                    {row.type}
                                                                </TableCell>
                                                                <TableCell
                                                                    align="center"
                                                                    style={{
                                                                        padding: '4px',
                                                                        borderBottom: 0,
                                                                        color: parseFloat(row.acc_cum_vol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                    }}
                                                                >
                                                                    {row.acc_cum_vol}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Box>
                                {isSubmitted2 && (<>
                                    <Box sx={{
                                        padding: 1,
                                        backgroundColor: theme.palette.background.container1,
                                        marginTop: 1,
                                        borderRadius: '10px',
                                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
                                    }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', mb: '5px' }}>
                                            <Typography variant="h6">Total Flip Count: {flipCount2}</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
                                                <Box sx={{ padding: '5px 10px', backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                                                    <Box sx={{ width: '20px', height: '10px', backgroundColor: colors.redChart[100], borderRadius: '2px' }} />
                                                    <Typography variant="h6" sx={{ mr: '10px' }}>{'Net Vol < 0'}</Typography>
                                                    <Box sx={{ width: '20px', height: '10px', backgroundColor: colors.greenChart[100], borderRadius: '2px' }} />
                                                    <Typography variant="h6">{'Net Vol > 0'}</Typography>
                                                </Box>
                                                <IconButton onClick={toggleTableVisibility}>
                                                    {isTableVisible2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </IconButton>
                                            </Box>
                                        </Box>

                                        {isTableVisible2 && (<>
                                            <Box sx={{
                                                padding: '10px 10px 0px 10px',
                                                borderRadius: '10px',
                                                backgroundColor: theme.palette.background.container,
                                                boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
                                            }}>
                                                <FlipTimelineChart
                                                    data={flipPointsChart2}
                                                    flipPoints={flipPoints2}
                                                    handleRowClick={handleRowClick2}
                                                    rows={rows2}
                                                />
                                            </Box>
                                            <TableContainer component={Paper} sx={{ marginTop: 1, backgroundColor: 'transparent' }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {[
                                                                'ครั้งที่',
                                                                'Time',
                                                                //   'Last',
                                                                //    'Vol',
                                                                // 'Type', 
                                                                'From Acc. Vol',
                                                                'To Acc. Vol'
                                                            ].map((header) => (
                                                                <StyledTableCell
                                                                    key={header}
                                                                    align="center"
                                                                    sx={{ position: 'sticky', top: 0, backgroundColor: 'transparent', zIndex: 1 }}
                                                                >
                                                                    {header}
                                                                </StyledTableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {flipPoints2.length === 0 ? (
                                                            <TableRow>
                                                                <TableCell colSpan={7} align="center" sx={{ padding: '16px', fontSize: '14px', color: theme.palette.text.primary }}>
                                                                    No Flip Data
                                                                </TableCell>
                                                            </TableRow>
                                                        ) : (
                                                            flipPoints2.map((flip, index) => (
                                                                <TableRow key={index}
                                                                    onClick={() => handleRowClick2(flip.index)}
                                                                    sx={{ backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even, cursor: 'pointer' }}>
                                                                    <TableCell align="center" style={{ padding: '4px', borderBottom: 0, }}>{index + 1}</TableCell>
                                                                    <TableCell align="center" style={{ padding: '4px', borderBottom: 0, }}>{flip.time}</TableCell>
                                                                    {/* <TableCell align="center" style={{ textAlign: 'right', borderBottom: 0, padding: '4px' }}>{flip.toPrice}</TableCell> */}
                                                                    {/* <TableCell align="center" style={{ padding: '4px', borderBottom: 0, }}>{flip.toVolume}</TableCell> */}
                                                                    {/* <TableCell align="center" style={{ padding: '4px', borderBottom: 0, color: flip.toType === 'BUY' ? colors.greenChart[100] : colors.redChart[100] }}>{flip.toType}</TableCell> */}
                                                                    <TableCell align="center" style={{
                                                                        padding: '4px', borderBottom: 0, color: parseFloat(flip.fromAccVol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                    }}>{flip.fromAccVol}</TableCell>
                                                                    <TableCell align="center" style={{
                                                                        padding: '4px',
                                                                        borderBottom: 0,
                                                                        color: parseFloat(flip.toAccVol.replace(/,/g, '')) > 0 ? colors.greenChart[100] : colors.redChart[100]
                                                                    }}>{flip.toAccVol}</TableCell>
                                                                </TableRow>
                                                            ))
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                        )}
                                    </Box></>)}

                                <StackedBarChart chartData={chartData2} isLoading={isFetch.form2} />
                            </Grid>
                        </>
                        }
                    </Paper>
                </Container>
            </Box>
        </Box >

    );
};

export default DejavuTickmatch;
