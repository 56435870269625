import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
// import axios from 'axios';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';
import { Box, Typography } from '@mui/material';
import FontLoader from '../../components/FontLoader';

const API_BASE_URL = "http://172.18.1.81:3100/money_in_out";

let colorIndex = 0;
const softDarkColors = [
  "#ffcc00", // เหลืองสดใส (Vivid Yellow)
  "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
  "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
  "#ff9966", // ส้มสด (Bright Orange)
  "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
  "#ffffff", // ขาว (White)
  "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
  "#ff66b3", // ชมพูสดใส (Hot Pink)
  "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
  "#3399ff", // ฟ้าสดใส (Sky Blue)
  "#ff9933", // ส้มอ่อนสด (Bright Peach)
  "#ff3399", // ชมพูบานเย็น (Magenta Pink)
  "#33ff66", // เขียวสด (Vivid Green)
  "#ff9966", // พีชสดใส (Peach Orange)
  "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
  "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
  "#66ccff", // ฟ้าสดกลาง (Electric Blue)
  "#33ccff", // ฟ้าสด 
  "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
  "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
];

const getRandomColor = () => {
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
  return color;
};

const earthToneColor = 'rgba(181, 101, 29, 0.5)';
const getOppositeColor = () => '#ffffb3';

const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};




const FullscreenPageMoneyinout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Read query params
  const params = new URLSearchParams(location.search);
  const market = params.get('market');   // e.g. "SET100"
  const type = params.get('type');       // e.g. "in" or "out"
  const title = params.get('title');     // e.g. "SET100 Down (-)"

  // We'll define a flag whether to invert & negate the chart if type === "out"
  const shouldInvert = (type === "out");

  const fullScreenChartContainerRef = useRef(null);
  const fullScreenChartRef = useRef(null);

  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());
  const [selectedSymbol, setSelectedSymbol] = useState(null);


  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();

  const [tableData, setTableData] = useState([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const [lastRankChange, setLastRankChange] = useState({});
  const [selectedModalSymbol, setSelectedModalSymbol] = useState(null);
  const chartRef = useRef(null);  // ตัว ref สำหรับ chart

  const csrfToken = useSelector((state) => state.csrfToken);

  // Keep references updated on re-render
  useEffect(() => {
    // each time previousRanks changes, store in a ref
    // so we can read them inside async calls
    lineSeriesRef.current = lineSeriesRef.current || new Map();
  }, []);

  useEffect(() => {
    // ตรวจสอบว่า chartRef.current ถูกตั้งค่าแล้ว
    if (chartRef.current) {
      console.log("chartRef is defined:", chartRef.current);
      // ตั้งค่า chart หรือเชื่อมต่อกับไลบรารีที่ใช้ในการสร้างกราฟ
    } else {
      console.log("chartRef is not defined yet");
    }
  }, [chartRef]); // คอยฟังการเปลี่ยนแปลงของ chartRef

  // -- Utility for fetching historical data (one-time)
  const fetchHistoricalData = async () => {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });

      const response = await fetch_response.json();
      console.log("Fullscreen historical data:", response);

      const data = response.data[type]; // 'in' or 'out'
      const historicalData = {};
      const todayDateString = getTodayDateString();

      const times = Object.keys(data.rank);
      times.sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build historicalData
      times.forEach((time) => {
        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const dateTimeString = `${todayDateString}T${time}`;
          const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

          // Possibly negate
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: finalValue,
          });
        });
      });

      // We'll store the final "previousRanks" from the last snapshot
      const newPreviousRanks = {};

      let lastChangeTime = -Infinity;
      const rankChanges = [];

      // Populate the chart
      const latestSymbolsSet = new Set(latestSymbols);

      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null;
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);
        const latestRank = symbolData[symbolData.length - 1].rank;
        newPreviousRanks[symbol] = latestRank;

        // Track rank changes
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            const changeTime = symbolData[i].time;
            rankChanges.push({
              symbol,
              time: changeTime,
              oldRank: symbolData[i - 1].rank,
              newRank: symbolData[i].rank,
            });
            if (changeTime > lastChangeTime) {
              lastChangeTime = changeTime;
            }
          }
        }

        // Create or update the series
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = fullScreenChartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        // Convert to {time, value} for the chart
        const seriesData = symbolData.map((dp) => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      setPreviousRanks(newPreviousRanks);

      // Remove any symbols not in latestSymbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbolsSet.has(sym));
      symbolsToRemove.forEach((symbol) => {
        const s = lineSeriesRef.current.get(symbol);
        if (s) {
          fullScreenChartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });

      // If user had selected a symbol that is no longer in the data, unselect
      if (selectedModalSymbol && !latestSymbolsSet.has(selectedModalSymbol)) {
        setSelectedModalSymbol(null);
      }

      // Determine highlight colors
      const lastChanges = rankChanges.filter((rc) => rc.time === lastChangeTime);
      const symbolColors = new Map();
      if (lastChangeTime !== -Infinity && lastChanges.length > 0) {
        lastChanges.forEach((change) => {
          if (change.newRank < change.oldRank) {
            symbolColors.set(change.symbol, '#4d8b43');
          } else if (change.newRank > change.oldRank) {
            symbolColors.set(change.symbol, '#ff6343');
          } else {
            symbolColors.set(change.symbol, 'transparent');
          }
        });
      }

      // Build table data
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';

        return {
          rank: latestData.rank,
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);

    } catch (error) {
      console.error("Error fetching historical data:", error);
    }
  };

  // -- Utility for fetching current data (polling)
  // const fetchCurrentData = async () => {
  //   try {
  //     // if (!Object.keys(previousRanks).length) {
  //     //   console.warn("previousRanks not init, skipping fetchCurrentData");
  //     //   return;
  //     // }

  //     const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${market}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'xsrf-token': csrfToken
  //       },
  //     });
  //     const response = await fetch_response.json();

  //     const data = response.data[type]; // 'in' or 'out'
  //     const timeKey = Object.keys(data.rank)[0];
  //     const todayDateString = getTodayDateString();
  //     const dateTimeString = `${todayDateString}T${timeKey}`;
  //     const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

  //     const latestSymbols = Object.keys(data.rank[timeKey]) || [];
  //     const newPreviousRanks = {};

  //     const rankChanges = [];
  //     let lastChangeTime = timestamp;

  //     // We'll gather updated table data
  //     const updatedTableData = [];

  //     latestSymbols.forEach((symbol) => {
  //       const currentRank = data.rank[timeKey][symbol];
  //       const oldRank = previousRanks[symbol] ?? currentRank;

  //       newPreviousRanks[symbol] = currentRank;

  //       if (currentRank !== oldRank) {
  //         rankChanges.push({
  //           symbol,
  //           time: adjustToLocalTime(timestamp),
  //           oldRank,
  //           newRank: currentRank,
  //         });
  //       }

  //       // Possibly invert
  //       const rawValue = data.value[timeKey][symbol];
  //       const finalValue = shouldInvert ? -1 * rawValue : rawValue;

  //       updatedTableData.push({
  //         rank: currentRank,
  //         symbol,
  //         value: finalValue,
  //         color: 'transparent', // default, we can update highlight next
  //       });
  //     });

  //     setPreviousRanks(newPreviousRanks);

  //     // Remove any symbol not in the latest
  //     const allSymbols = Array.from(lineSeriesRef.current.keys());
  //     const missingSymbols = allSymbols.filter(sym => !latestSymbols.includes(sym));
  //     missingSymbols.forEach((symbol) => {
  //       const s = lineSeriesRef.current.get(symbol);
  //       if (s) {
  //         fullScreenChartRef.current.removeSeries(s);
  //       }
  //       lineSeriesRef.current.delete(symbol);
  //       seriesDataRef.current.delete(symbol);
  //       originalColorsRef.current.delete(symbol);
  //     });

  //     if (selectedModalSymbol && !latestSymbols.includes(selectedModalSymbol)) {
  //       setSelectedModalSymbol(null);
  //     }

  //     // Update the chart lines for each symbol
  //     latestSymbols.forEach((symbol) => {
  //       // Possibly invert
  //       const rawValue = data.value[timeKey][symbol];
  //       const finalValue = shouldInvert ? -1 * rawValue : rawValue;

  //       const updatePoint = {
  //         time: adjustToLocalTime(timestamp),
  //         value: finalValue,
  //       };

  //       let series = lineSeriesRef.current.get(symbol);
  //       if (series) {
  //         const oldSeriesData = seriesDataRef.current.get(symbol) || [];
  //         const lastIdx = oldSeriesData.length - 1;
  //         if (lastIdx >= 0 && oldSeriesData[lastIdx].time === updatePoint.time) {
  //           oldSeriesData[lastIdx] = updatePoint;
  //         } else {
  //           oldSeriesData.push(updatePoint);
  //         }
  //         series.setData(oldSeriesData);
  //         seriesDataRef.current.set(symbol, oldSeriesData);
  //       } else {
  //         const color = getRandomColor();
  //         series = fullScreenChartRef.current.addLineSeries({
  //           color,
  //           lineWidth: 2,
  //           lastValueVisible: true,
  //           priceLineVisible: false,
  //           title: symbol,
  //         });
  //         series.setData([updatePoint]);
  //         lineSeriesRef.current.set(symbol, series);
  //         originalColorsRef.current.set(symbol, color);
  //         seriesDataRef.current.set(symbol, [updatePoint]);
  //       }
  //     });
      

  //     // highlight any changed ranks
  //     if (rankChanges.length > 0) {
  //       rankChanges.forEach(change => {
  //         if (change.newRank < change.oldRank) {
  //           const idx = updatedTableData.findIndex((r) => r.symbol === change.symbol);
  //           if (idx >= 0) updatedTableData[idx].color = '#ccffcc';
  //         } else if (change.newRank > change.oldRank) {
  //           const idx = updatedTableData.findIndex((r) => r.symbol === change.symbol);
  //           if (idx >= 0) updatedTableData[idx].color = '#ffcccc';
  //         }
  //       });
  //     }

  //     setTableData(updatedTableData);

  //   } catch (error) {
  //     console.error("Error fetching current data:", error);
  //   }
  // };


  const fetchCurrentData = async () => {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      const data = response.data[type]; // 'in' or 'out'
      const timeKey = Object.keys(data.rank)[0];
      const todayDateString = getTodayDateString();
      const dateTimeString = `${todayDateString}T${timeKey}`;
      const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);
  
      const latestSymbols = Object.keys(data.rank[timeKey]) || [];
  
      // ✅ คัดกรอง Top 5 สำหรับกราฟ
      const sortedSymbols = latestSymbols.sort((a, b) => data.rank[timeKey][a] - data.rank[timeKey][b]);
      const top5Symbols = sortedSymbols.slice(0, 5);
  
      const updatedTableData = [];
      const rankChanges = [];
      const newPreviousRanks = {};
  
      latestSymbols.forEach((symbol) => { // ใช้ทั้งหมดในการอัปเดตข้อมูลในตาราง
        const currentRank = data.rank[timeKey][symbol];
        const oldRank = previousRanks[symbol] ?? currentRank;
  
        newPreviousRanks[symbol] = currentRank;
  
        if (currentRank !== oldRank) {
          rankChanges.push({
            symbol,
            time: adjustToLocalTime(timestamp),
            oldRank,
            newRank: currentRank,
          });
        }
  
        // Possibly invert
        const rawValue = data.value[timeKey][symbol];
        const finalValue = shouldInvert ? -1 * rawValue : rawValue;
  
        updatedTableData.push({
          rank: currentRank,
          symbol,
          value: finalValue,
          color: 'transparent', // default, we can update highlight next
        });
      });
  
      setPreviousRanks(newPreviousRanks);
  
      // ✅ ลบสัญลักษณ์ที่ไม่อยู่ใน Top 5 หรือที่ถูกเลือกออกจากกราฟ
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const missingSymbols = allSymbols.filter(sym => !top5Symbols.includes(sym) && !lineSeriesRef.current.has(sym));
      missingSymbols.forEach((symbol) => {
        const s = lineSeriesRef.current.get(symbol);
        if (s) {
          fullScreenChartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });
  
      // ✅ อัปเดตกราฟสำหรับ Top 5 หรือสัญลักษณ์ที่ถูกเลือก
      top5Symbols.forEach((symbol) => {
        const rawValue = data.value[timeKey][symbol];
        const finalValue = shouldInvert ? -1 * rawValue : rawValue;
  
        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: finalValue,
        };
  
        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          const oldSeriesData = seriesDataRef.current.get(symbol) || [];
          const lastIdx = oldSeriesData.length - 1;
          if (lastIdx >= 0 && oldSeriesData[lastIdx].time === updatePoint.time) {
            oldSeriesData[lastIdx] = updatePoint;
          } else {
            oldSeriesData.push(updatePoint);
          }
          series.setData(oldSeriesData);
          seriesDataRef.current.set(symbol, oldSeriesData);
        } else {
          // ตรวจสอบว่า fullScreenChartRef.current พร้อมใช้งาน
          if (fullScreenChartRef.current) {
            const color = getRandomColor();
            series = fullScreenChartRef.current.addLineSeries({
              color,
              lineWidth: 2,
              lastValueVisible: true,
              priceLineVisible: false,
              title: symbol,
            });
            series.setData([updatePoint]);
            lineSeriesRef.current.set(symbol, series);
            originalColorsRef.current.set(symbol, color);
            seriesDataRef.current.set(symbol, [updatePoint]);
          } else {
            console.error("fullScreenChartRef.current is still not defined!");
          }
        }
      });
  
      // ✅ Highlight การเปลี่ยนแปลงอันดับ
      if (rankChanges.length > 0) {
        rankChanges.forEach(change => {
          if (change.newRank < change.oldRank) {
            const idx = updatedTableData.findIndex((r) => r.symbol === change.symbol);
            if (idx >= 0) updatedTableData[idx].color = '#ccffcc';
          } else if (change.newRank > change.oldRank) {
            const idx = updatedTableData.findIndex((r) => r.symbol === change.symbol);
            if (idx >= 0) updatedTableData[idx].color = '#ffcccc';
          }
        });
      }
  
      setTableData(updatedTableData);
    } catch (error) {
      console.error("Error fetching current data:", error);
    }
};

  

  // -- Set up the chart once
  useEffect(() => {
    if (!fullScreenChartContainerRef.current) return;
  
    fullScreenChartContainerRef.current.style.outline = "2px solid #fff"; 
    fullScreenChartContainerRef.current.style.boxSizing = "border-box"; 
    fullScreenChartContainerRef.current.style.borderRadius = "10px"; 
    fullScreenChartContainerRef.current.style.overflow = "hidden"; 
  
    const chart = createChart(fullScreenChartContainerRef.current, {
      layout: { textColor: 'white', background: { type: 'solid', color: '#575b69' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: fullScreenChartContainerRef.current.offsetHeight,
      width: fullScreenChartContainerRef.current.offsetWidth,
  
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
  
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: { mode: 0 },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
    });
  
    fullScreenChartRef.current = chart;
  
    chart.priceScale('right').applyOptions({
      textColor: '#575b69',
    });
  
    if (shouldInvert) {
      chart.applyOptions({
        invertScale: true,
      });
    }
  
    const initializeData = async () => {
      await fetchHistoricalData();
      await fetchCurrentData();
    };
  
    initializeData();
  
    const intervalID = setInterval(() => {
      fetchCurrentData();
    }, 10000);
  
    const resizeObserver = new ResizeObserver(() => {
      if (chart && fullScreenChartContainerRef.current) {
        chart.resize(
          fullScreenChartContainerRef.current.offsetWidth,
          fullScreenChartContainerRef.current.offsetHeight
        );
      }
    });
  
    resizeObserver.observe(fullScreenChartContainerRef.current);
  
    return () => {
      clearInterval(intervalID);
      resizeObserver.disconnect();
      if (chart) {
        chart.remove();
      }
    };
  }, [market, type]);
  

  // -- Chart Navigation
  const handleStartButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: earliestTime,
        to: earliestTime + rangeDuration,
      });
    }
  };

  const handleHalfDayButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      const middleTime = (earliestTime + latestTime) / 2;
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;

      timeScale.setVisibleRange({
        from: middleTime - rangeDuration / 2,
        to: middleTime + rangeDuration / 2,
      });
    }
  };

  const handleEndDayButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (latestTime !== -Infinity) {
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: latestTime - rangeDuration,
        to: latestTime,
      });
    }
  };

  const handleShowAllButtonClick = () => {
    if (!fullScreenChartRef.current) return;

    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;

    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });

    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      timeScale.setVisibleRange({ from: earliestTime, to: latestTime });

      timeScale.applyOptions({ rightOffset: 16 });
    }
  };

  // -- Table row click logic
  // const handleRowClickModal = (symbol) => {
  //   if (selectedModalSymbol === symbol) {
  //     // Deselect
  //     setSelectedModalSymbol(null);
  //     // Reset all series to original colors and styles
  //     lineSeriesRef.current.forEach((series, sym) => {
  //       const originalColor = originalColorsRef.current.get(sym);
  //       series.applyOptions({
  //         color: originalColor,
  //         lineWidth: 2,
  //       });
  //     });
  //   } else {
  //     // Highlight the clicked series
  //     setSelectedModalSymbol(symbol);
  //     const selectedSeries = lineSeriesRef.current.get(symbol);
  //     const data = seriesDataRef.current.get(symbol);

  //     if (selectedSeries && fullScreenChartRef.current) {
  //       // remove & re-add to bring on top
  //       fullScreenChartRef.current.removeSeries(selectedSeries);
  //       const newSeries = fullScreenChartRef.current.addLineSeries({
  //         color: selectedSeries.options().color,
  //         lineWidth: selectedSeries.options().lineWidth,
  //         lastValueVisible: true,
  //         priceLineVisible: false,
  //         title: symbol,
  //       });
  //       newSeries.setData(data);
  //       lineSeriesRef.current.set(symbol, newSeries);

  //       const originalColor = originalColorsRef.current.get(symbol);
  //       const oppositeColor = getOppositeColor();
  //       newSeries.applyOptions({
  //         color: oppositeColor,
  //         lineWidth: 4,
         
  //       });


  //       // fade other series
  //       lineSeriesRef.current.forEach((s, sym) => {
  //         if (sym !== symbol) {
  //           s.applyOptions({
  //             color: earthToneColor,
  //             lineWidth: 2,
  //           });
  //         }

  //       });
  //     }
  //   }
  // };

  const handleRowClickModal = (symbol) => {
    const row = tableData.find(r => r.symbol === symbol);
    if (!row) return;
  
    if (selectedSymbol === symbol) {
      // ✅ ถ้าคลิกซ้ำ → กลับไปแสดง Top 5
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const row = tableData.find(r => r.symbol === sym);
        if (row) {
          if (row.rank <= 5) {
            series.applyOptions({
              visible: true,
              color: originalColorsRef.current.get(sym),
              lineWidth: 2,
            });
          } else {
            series.applyOptions({ visible: false });
          }
        }
      });
    } else {
      // ✅ แสดงเฉพาะ Symbol ที่เลือก (รวมถึงอันดับ 6-20)
      setSelectedSymbol(symbol);
      lineSeriesRef.current.forEach((series, sym) => {
        if (sym === symbol) {
          series.applyOptions({
            visible: true,
            color: getOppositeColor(),
            lineWidth: 4,
          });
        } else {
          series.applyOptions({ visible: false });
        }
      });
  
      // ✅ ถ้า Symbol อันดับ 6-20 ถูกเลือก → ต้องเพิ่มเข้าไปถ้ายังไม่มี Series
      if (!lineSeriesRef.current.has(symbol)) {
        const color = getRandomColor();
        const newSeries = chartRef.current.addLineSeries({
          color,
          lineWidth: 2,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
  
        const seriesData = seriesDataRef.current.get(symbol) || [];
        newSeries.setData(seriesData);
        lineSeriesRef.current.set(symbol, newSeries);
        seriesDataRef.current.set(symbol, seriesData);
        originalColorsRef.current.set(symbol, color);
      }
    }
  };
  

 


  function renderTable(startIndex, endIndex) {
    // 1. Sort the table data by rank.
    const sortedData = [...tableData].sort((a, b) => a.rank - b.rank);
  
    // 2. Build an array of exactly (endIndex - startIndex) rows.
    // For example, if startIndex = 0 and endIndex = 10, then we need rows for ranks 1 through 10.
    const rowCount = endIndex - startIndex;
    const tableRows = Array.from({ length: rowCount }, (_, i) => {
      const rankWanted = startIndex + i + 1; // ranks: startIndex+1 to endIndex
      // Check if there is an existing row with this rank.
      const existingRow = sortedData.find(row => row.rank === rankWanted);
      if (existingRow) {
        return existingRow;
      } else {
        // Create a placeholder row if no data exists for this rank.
        return {
          rank: rankWanted,
          symbol: '',
          value: 0,
          color: 'transparent',
          placeholder: true, // mark this as a mock row
        };
      }
    });
  
    return (
      <table
        className="rank-table"
        style={{
          fontSize: '11.5px',
          fontFamily: 'Lato, Sans-serif',
          border: '1px solid #ddd',
          borderCollapse: 'collapse',
        }}
      >

        <thead>
          <tr style={{ zIndex: isMobile ? 1 : 0 }}>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Rank</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Symbol</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Value</th>
          </tr>
        </thead>
        <tbody>
  {tableRows.map((row, idx) => (
    <tr
      key={idx}
      style={{
        backgroundColor: row.symbol === selectedModalSymbol ? "highlightColor" : row.color,
        cursor: row.placeholder ? 'default' : 'pointer',
        fontWeight: row.symbol === selectedModalSymbol ? 'bold' : 'normal',
        borderBottom: '1px solid #ddd',
      }}
      onClick={() => {
        // ถ้าเป็น placeholder ให้ข้าม
        if (row.placeholder) return;
        handleRowClickModal(row.symbol);
      }}
    >
      <td style={{ padding: '3px', textAlign: 'center', backgroundColor: '#636a7e' }}>{row.rank}</td>
      <td style={{ padding: '3px', textAlign: 'center', backgroundColor: '#636a7e' }}>
        {row.symbol || ''}
      </td>
      <td style={{ padding: '3px', textAlign: 'center', backgroundColor: '#636a7e' }}>
        {row.value
          ? (row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })
          : ''}
      </td>
    </tr>
  ))}
</tbody>
      </table>
    );
  }
  
  
  return (
    <div style={{ fontFamily: 'Lato, Sans-serif', padding: '20px' }}>
      <FontLoader/>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h3' sx={{ fontWeight: 'bold', fontFamily: 'Lato, Sans-serif', }}> Ideatradepoint Realtime</Typography>
      </Box>

      {/* Back button */}

      <h3>{title}</h3>

      {/* Buttons to shift time range */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button onClick={handleStartButtonClick} className="nav-button" style={{borderRadius:'10px',backgroundColor:'#FFCD77'}}>Start</button>
          <button onClick={handleHalfDayButtonClick} className="nav-button" style={{borderRadius:'10px',backgroundColor:'#FFCD77'}}>Half-Day</button>
          <button onClick={handleEndDayButtonClick} className="nav-button" style={{borderRadius:'10px',backgroundColor:'#FFCD77'}}>End-Day</button>
          <button onClick={handleShowAllButtonClick} className="nav-button" style={{borderRadius:'10px',backgroundColor:'#FFCD77'}}>Show All</button>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: (isMobile || isPTablet) ? 'column' : 'row', gap: '20px' }}>
        {/* Chart Container */}
        <div
          ref={fullScreenChartContainerRef}
          className="chart-container"
          style={{
            width: (isMobile || isPTablet) ? '100%' : '100%',
            height: (isMobile || isPTablet) ? '40vh' : '85vh',
            position: 'relative',
            fontFamily: 'Lato, Sans-serif',
          }}
        />

        {/* Right side table */}

        {(!isMobile && !isPTablet) && (
          <>
            <div
              className="table-container"
              style={{
                width: '20%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                maxHeight: '100vh',
                fontFamily: 'Lato, Sans-serif',
              }}
            >
              <table className="rank-table" style={{
                fontSize: '14.px',
                fontFamily: 'Lato, Sans-serif',
                border: '1px solid #ddd',
                borderCollapse: 'collapse'
              }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center', padding: '5px', backgroundColor: '#283593' }}>Rank</th>
                    <th style={{ textAlign: 'center', padding: '5px', backgroundColor: '#283593' }}>Symbol</th>
                    <th style={{ textAlign: 'center', padding: '5px', backgroundColor: '#283593' }}>Value</th>
                  </tr>
                </thead>
                <tbody>
  {tableData
    .sort((a, b) => a.rank - b.rank)
    .map((row, index) => {
      const isSelected = row.symbol === selectedModalSymbol;
      return (
        <tr
          key={index}
          style={{
            backgroundColor: isSelected ? "#B8860B" : "#636a7e", // เปลี่ยนสีเมื่อถูกเลือก
            cursor: "pointer",
            fontWeight: isSelected ? "bold" : "normal",
            borderBottom: "1px solid #ddd",
          }}
          onClick={() => handleRowClickModal(row.symbol)}
        >
          <td style={{ padding: "5px", textAlign: "center" }}>{row.rank}</td>
          <td style={{ padding: "5px", textAlign: "center" }}>{row.symbol}</td>
          <td style={{ padding: "5px", textAlign: "center" }}>
            {(row.value / 1_000_000).toLocaleString("en-US", { maximumFractionDigits: 2 })}
          </td>
        </tr>
      );
    })}
</tbody>

              </table>
            </div>
          </>
        )}
        {(isMobile || isPTablet) && (
          <>
            <div
              className="table-container"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'center',
                // overflowY: 'auto',
                // maxHeight: '80vh',
                fontFamily: 'Lato, Sans-serif',
              }}
            >
              <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {renderTable(0, 10)}
              </div>
              <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {renderTable(10, 20)}
              </div>
            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default FullscreenPageMoneyinout;
