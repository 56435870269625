import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import './index.css';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, useMediaQuery, useTheme, IconButton } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FaSearch } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยาย
import LoopIcon from '@mui/icons-material/Loop';
import FontLoader from '../../components/FontLoader';

// ---------------------------------------------
// Utilities
// ---------------------------------------------
let colorIndex = 0;
const softDarkColors = [
  "#ffcc00", // เหลืองสดใส (Vivid Yellow)
  "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
  "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
  "#ff9966", // ส้มสด (Bright Orange)
  "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
  "#ffffff", // ขาว (White)
  "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
  "#ff66b3", // ชมพูสดใส (Hot Pink)
  "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
  "#3399ff", // ฟ้าสดใส (Sky Blue)
  "#ff9933", // ส้มอ่อนสด (Bright Peach)
  "#ff3399", // ชมพูบานเย็น (Magenta Pink)
  "#33ff66", // เขียวสด (Vivid Green)
  "#ff9966", // พีชสดใส (Peach Orange)
  "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
  "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
  "#66ccff", // ฟ้าสดกลาง (Electric Blue)
  "#33ccff", // ฟ้าสด 
  "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
  "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
];

const getRandomColor = () => {
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
  return color;
};


const earthToneColor = 'rgba(181, 101, 29, 0.5)';
const getOppositeColor = () => '#ffffb3';

const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

// ---------------------------------------------
// Reusable child: ChartSectionWithMultipleLines
// ---------------------------------------------
const ChartSectionWithMultipleLines = ({ title, market, type }) => {
  const chartContainerRef = useRef(null);
  const chartTableContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const [tableData, setTableData] = useState([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef({});
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const csrfToken = useSelector((state) => state.csrfToken);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const shouldInvert = (type === "out");
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();


  useEffect(() => {
    previousRanksRef.current = previousRanks;
  }, [previousRanks]);

  // ---------------------------------------------
  // sendTelegramMessage is NOT called here directly.
  // We simply push changes into window.__globalRankChanges__ for the parent to handle.
  // ---------------------------------------------
  const applyFullTimeRange = (chart, historicalData, latestSymbols) => {
    if (!chart || !latestSymbols || latestSymbols.length === 0) return;

    // คำนวณช่วงเวลาทั้งหมดของข้อมูล
    const allTimes = [];
    latestSymbols.forEach((symbol) => {
      const symbolData = historicalData[symbol];
      if (symbolData && symbolData.length > 0) {
        symbolData.forEach((dataPoint) => {
          allTimes.push(dataPoint.time);
        });
      }
    });

    if (allTimes.length > 0) {
      const minTime = Math.min(...allTimes);
      const maxTime = Math.max(...allTimes);

      // ✅ ขยายกราฟให้ครอบคลุมข้อมูลทั้งหมด
      // chart.timeScale().setVisibleRange({ from: minTime, to: maxTime });
      chartRef.current.timeScale().fitContent();

      // ✅ ป้องกันไม่ให้เส้นกราฟติดขอบขวา
      chart.timeScale().applyOptions({ rightOffset: 60 });

      // ✅ ให้กราฟพอดีกับช่วงข้อมูลทั้งหมด
      chart.timeScale().fitContent();
    }
  };

  async function fetchHistoricalData() {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();

      const data = response.data[type]; // 'in' หรือ 'out'
      const times = Object.keys(data.rank).sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      const historicalData = {};
      const todayDateString = getTodayDateString();
      times.forEach((time) => {
        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const dateTimeString = `${todayDateString}T${time}`;
          const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: finalValue,
          });
        });
      });

      // ✅ คัดกรอง 20 อันดับแรกสำหรับตาราง
      const sortedSymbols = latestSymbols.sort((a, b) => data.rank[latestTime][a] - data.rank[latestTime][b]);
      const top20Symbols = sortedSymbols.slice(0, 20); // ตารางแสดง 20 อันดับแรก
      const top5Symbols = sortedSymbols.slice(0, 5);  // กราฟแสดง 5 อันดับแรก

      // ✅ อัปเดตตารางข้อมูล
      const updatedTable = top20Symbols.map((symbol) => {
        const sData = historicalData[symbol];
        const lastItem = sData[sData.length - 1];
        const prevItem = sData.length > 1 ? sData[sData.length - 2] : null;

        // คำนวณเปอร์เซ็นต์การเปลี่ยนแปลง
        const pctChange = prevItem ? ((lastItem.value - prevItem.value) / prevItem.value) * 100 : 0;
        return {
          rank: lastItem.rank,
          symbol,
          value: lastItem.value,
          pct_change: pctChange.toFixed(2), // ปัดเป็นทศนิยม 2 ตำแหน่ง
        };
      });
      setTableData(updatedTable);

      // ✅ สร้างเส้นกราฟสำหรับ **ทุก Symbol** (แต่แสดงเฉพาะ Top 5)
      top20Symbols.forEach((symbol) => {
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }
        const seriesData = historicalData[symbol].map(dp => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      // ✅ ซ่อน Symbol ที่ไม่ใช่ Top 5 ตั้งแต่เริ่มต้น
      lineSeriesRef.current.forEach((series, sym) => {
        series.applyOptions({ visible: top5Symbols.includes(sym) });
      });

      applyFullTimeRange(chartRef.current, historicalData, latestSymbols);

    } catch (err) {
      console.error('Error fetchHistoricalData for', market, err);
    }
  }

  async function fetchCurrentData() {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      const data = response.data[type];
      const timeKey = Object.keys(data.rank)[0];
      const dateTimeString = `${getTodayDateString()}T${timeKey}`;
      const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);
      const latestSymbols = Object.keys(data.rank[timeKey]);

      // ✅ คัดกรอง 5 อันดับแรก
      const sortedSymbols = latestSymbols.sort((a, b) => data.rank[timeKey][a] - data.rank[timeKey][b]);
      const top5Symbols = sortedSymbols.slice(0, 5);

      top5Symbols.forEach((symbol) => {
        const rawValue = data.value[timeKey][symbol];
        const finalValue = shouldInvert ? -1 * rawValue : rawValue;
        const point = {
          time: adjustToLocalTime(timestamp),
          value: finalValue,
        };
        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          const existingData = seriesDataRef.current.get(symbol) || [];
          existingData.push(point);
          series.setData(existingData);
          seriesDataRef.current.set(symbol, existingData);
        } else {
          const c = getRandomColor();
          series = chartRef.current.addLineSeries({
            color: c,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          series.setData([point]);
          lineSeriesRef.current.set(symbol, series);
          seriesDataRef.current.set(symbol, [point]);
          originalColorsRef.current.set(symbol, c);
        }
      });

      // ✅ ซ่อน Symbol ที่ไม่ใช่ Top 5
      lineSeriesRef.current.forEach((series, sym) => {
        series.applyOptions({ visible: top5Symbols.includes(sym) });
      });

    } catch (err) {
      console.error("fetchCurrentData error for", market, type, err);
    }
  }

  const handleRowClick = (symbol) => {
    const row = tableData.find(r => r.symbol === symbol);
    if (!row) return;

    if (selectedSymbol === symbol) {
      // ✅ ถ้าคลิกซ้ำ → กลับไปแสดง Top 5
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const row = tableData.find(r => r.symbol === sym);
        if (row) {
          if (row.rank <= 5) {
            series.applyOptions({ visible: true, color: originalColorsRef.current.get(sym), lineWidth: 2 });
          } else {
            series.applyOptions({ visible: false });
          }
        }
      });
    } else {
      // ✅ แสดงเฉพาะ Symbol ที่เลือก (รวมถึงอันดับ 6-20)
      setSelectedSymbol(symbol);
      lineSeriesRef.current.forEach((series, sym) => {
        if (sym === symbol) {
          series.applyOptions({ visible: true, color: getOppositeColor(), lineWidth: 4 });
        } else {
          series.applyOptions({ visible: false });
        }
      });

      // ✅ ถ้า Symbol อันดับ 6-20 ถูกเลือก → ต้องเพิ่มเข้าไปถ้ายังไม่มี Series
      if (!lineSeriesRef.current.has(symbol)) {
        const color = getRandomColor();
        const newSeries = chartRef.current.addLineSeries({
          color,
          lineWidth: 2,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });

        const seriesData = seriesDataRef.current.get(symbol) || [];
        newSeries.setData(seriesData);
        lineSeriesRef.current.set(symbol, newSeries);
        seriesDataRef.current.set(symbol, seriesData);
        originalColorsRef.current.set(symbol, color);
      }
    }
  };

  const handleRezoom = () => {
    if (!chartRef.current || !chartRef.current.timeScale) return;

    const minTime = 1610000000000;
    const maxTime = 1620000000000;

    chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
    chartRef.current.timeScale().fitContent();
    chartRef.current.timeScale().applyOptions({ rightOffset: 50 });
  };



  // Initialize chart on mount
  useEffect(() => {
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'white', background: { type: 'solid', color: '#575b69', } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      //   width: isCollapsed
      // ? chartContainerRef.current ? chartContainerRef.current.offsetWidth : '100%'
      // : chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
    });


    chartRef.current.priceScale('right').applyOptions({
      textColor: '#575b69',
    });
    if (shouldInvert) {
      chartRef.current.applyOptions({ invertScale: true });
    }
    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: { mode: 0 },

      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
    });

    // เพิ่ม outline ให้กับ chartContainerRef
    chartContainerRef.current.style.outline = "2px solid #fff"; // เปลี่ยนสีตามต้องการ
    chartContainerRef.current.style.boxSizing = "border-box"; // ป้องกัน outline เล็กกว่า chart
    chartContainerRef.current.style.borderRadius = "10px";
    chartContainerRef.current.style.overflow = "hidden";
    const init = async () => {
      await fetchHistoricalData();
      await fetchCurrentData();
    };
    init();

    const intervalID = setInterval(() => {
      fetchCurrentData();
    }, 10_000);

    return () => {
      clearInterval(intervalID);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [market, type]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleSeriesVisibility = (symbol) => {
    const series = lineSeriesRef.current.get(symbol);
    if (series) {
      const currentVisible = series.options().visible ?? true;
      series.applyOptions({ visible: !currentVisible });
    }
  };


  function renderTable(startIndex, endIndex) {
    // 1. Sort the data by rank.
    const sortedData = [...tableData].sort((a, b) => a.rank - b.rank);

    // 2. Build an array of exactly (endIndex - startIndex) rows.
    // For example, if startIndex = 0 and endIndex = 10, then we need rows for ranks 1 through 10.
    const rowCount = endIndex - startIndex;
    const tableRows = Array.from({ length: rowCount }, (_, i) => {
      const rankWanted = startIndex + i + 1; // ranks: startIndex+1 to endIndex
      // Find if we already have data for this rank.
      const existingRow = sortedData.find(row => row.rank === rankWanted);
      if (existingRow) {
        return existingRow;
      } else {
        // Create a placeholder row for the missing rank.
        return {
          rank: rankWanted,
          symbol: '',
          value: 0,
          color: 'transparent',
          placeholder: true, // flag to mark that this is a mock row
        };
      }
    });

    return (
      <table
        className="rank-table"
        style={{
          fontSize: '11.5px',
          fontFamily: 'Lato, Sans-serif',
          border: '1px solid #ddd',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr style={{ zIndex: isMobile ? 1 : 0 }}>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Rank</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Symbol</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Value</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>%Change</th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row, idx) => {
            const isHighlighted = row.symbol === selectedSymbol; // ตรวจสอบว่าแถวนี้ถูกเลือก

            return (
              <tr
                key={idx}
                style={{
                  backgroundColor: isHighlighted ? "#B8860B" : "#636a7e", // เปลี่ยนสีเฉพาะเมื่อไฮไลต์
                  cursor: row.placeholder ? "default" : "pointer",
                  fontWeight: isHighlighted ? "bold" : "normal",
                  borderBottom: "1px solid #ddd",
                }}
                onClick={() => {
                  if (!row.placeholder) {
                    handleRowClick(row.symbol);
                  }
                }}
              >
                <td
                  style={{
                    padding: "3px 0 5px",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    backgroundColor: isHighlighted ? row.color : "#636a7e",
                    color: "#fff",
                  }}
                >
                  {row.rank}
                </td>
                <td
                  style={{
                    padding: "3px 0 5px",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    backgroundColor: isHighlighted ? row.color : "#636a7e",
                    color: "#fff",
                  }}
                >
                  {row.symbol || ""}
                </td>
                <td
                  style={{
                    padding: "3px 0 5px",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    backgroundColor: isHighlighted ? row.color : "#636a7e",
                    color: "#fff",
                  }}
                >
                  {row.value
                    ? (row.value / 1_000_000).toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })
                    : ""}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    padding: "3px 0 5px",
                    backgroundColor:
                    row.pct_change > 0
                      ? '#228B22'
                      : row.pct_change < 0
                      ? '#C8342A'
                      : '#575b69', // สีเขียวถ้าบวก แดงถ้าลบ
                  }}
                >
                  {row.pct_change ? `${row.pct_change}` : "-"}
                </td>
              </tr>
            );
          })}
        </tbody>

      </table>
    );
  }




  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  };

  // Dynamic Chart Width

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isCollapsed]);

  //


  const onChartTouch = (event, allowTouchMove) => {
    if (chartTableContainerRef.current && chartTableContainerRef.current.contains(event.target)) {
      window.mySwiper.allowTouchMove = allowTouchMove;
    }
  };


  return (
    <div className="chart-section" style={{ fontFamily: 'Lato, Sans-serif', }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {/* <h3 style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: '1',
          // paddingRight: isMobile ? '15vw' : '0'
        }}> */}
        {/** chart Title */}
        {/* {title}
          <FaSearch
            onClick={() => {
              const url = `/fullscreenmoneyinout?market=${market}&type=${type}&title=${encodeURIComponent(title)}`;
              window.open(url, '_blank');
            }}
            className="expand-button"
          />


        </h3> */}

        <h3 style={{
          position: 'relative', // ให้ FaSearch อิงตำแหน่งจาก h3
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'start', // ให้ title อยู่ตรงกลาง
          flex: '1',
        }}>
          {title}

          <FaSearch
            onClick={() => {
              const url = `/fullscreenmoneyinout?market=${market}&type=${type}&title=${encodeURIComponent(title)}`;
              window.open(url, '_blank');
            }}
            style={{
              position: 'absolute', // ป้องกันไม่ให้ขยับตามเนื้อหา
              top: (isMobile || isTablet) ? 2 : '50%', // จัดให้อยู่กึ่งกลางแนวตั้ง
              right: (isMobile || isTablet) ? '50%' : '390px', // ชิดขวา
              transform: (isMobile || isTablet) ? 'unset'  : 'translateY(-50%)', // ปรับให้อยู่ตรงกลางพอดี
              cursor: 'pointer',
              fontSize: '20px',
              zIndex: '10', // ให้ปุ่มอยู่เหนือเนื้อหา

            }}
          />

          <LoopIcon
            onClick={handleRezoom}
            style={{
              position: 'absolute', // ป้องกันไม่ให้ขยับตามเนื้อหา
              top: (isMobile || isTablet) ? 0 : '50%', // จัดให้อยู่กึ่งกลางแนวตั้ง
              right: (isMobile || isTablet) ? '40%' :  '355px', // ให้ห่างจาก FaSearch ไปทางซ้าย
              transform: (isMobile || isTablet) ? 'unset'  :  'translateY(-50%)', // ปรับให้อยู่ตรงกลางพอดี
              cursor: 'pointer',
              fontSize: '25px',
              color: '#febc69', // สามารถเปลี่ยนสีตามต้องการ
              zIndex: '10', // ให้ปุ่มอยู่เหนือเนื้อหา
            }}
          />
        </h3>

        {/* Icon พับเก็บ Table สำหรับ Mobile และ Tablet */}
        {(isMobile || isTablet) && (<IconButton
          variant="contained"
          onClick={toggleCollapse}
        // sx={{ marginBottom: '10px' }}
        >
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>)}
        {/* จบ Icon พับเก็บ */}
      </Box>

      <div className="chart-table-container" ref={chartTableContainerRef} style={{ display: 'flex', flexDirection: 'row', gap: isMobile ? '2vw' : '5px', justifyContent: 'center' }}>
        <div ref={chartContainerRef} style={{ width: isCollapsed ? '100%' : '60%', position: 'relative' }}
          onTouchStart={(event) => onChartTouch(event, false)}
          onTouchEnd={(event) => onChartTouch(event, true)}
        >

        </div>
        {/* โค้ดเก่าอยู่นี่ครับ */}
        {/* <div style={{ width: '20%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {renderTable(0, 10)}
        </div>
        <div style={{ width: '20%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {renderTable(10, 20)}
        </div> */}

        {/* โค้ดใหม่  */}

        {(!isMobile && !isTablet) && (
          <>
            {/* หากเข้าสู่โหมด Desktop */}
            <div style={{ width: '30%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {renderTable(0, 10)}
            </div>
            <div style={{ width: '30%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {renderTable(10, 20)}
            </div>
          </>
        )}
        {(isMobile || isTablet) && (
          <>
            {/* หากเข้าสู่โหมด Mobile */}
            {/* <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '250px', overflowY: 'auto' }}>
              {renderTable(0, 20)}
            </div> */}

            {!isCollapsed && (<Box sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight:  '290px',
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}>
              {renderTable(0, 20)}
            </Box>)}

          </>
        )
        }

      </div>
    </div>
  );
};


// ---------------------------------------------
// Parent: collects the rank improvements from all ChartSections
// ---------------------------------------------
const MoneyInOut = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  // 1) global array
  if (!window.__globalRankChanges__) {
    window.__globalRankChanges__ = [];
  }

  // 2) define sendTelegramMessage
  async function sendTelegramMessage(msg) {
    try {
      const botToken = '7686389843:AAGQTUjCcBTq3xQZh0hS3_B9SUnVv4u8g40';
      const chatId = '-1002304211612';
      const url = `https://api.telegram.org/bot7686389843:AAGQTUjCcBTq3xQZh0hS3_B9SUnVv4u8g40/sendMessage`;
      const resp = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chat_id: chatId, text: msg }),
      });
      const data = await resp.json();
      console.log('Telegram response:', data);
    } catch (err) {
      console.error('Failed to send Telegram message:', err);
    }
  }

  // 3) Periodically merge & send all improvements
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window.__globalRankChanges__.length > 0) {
        // build final message
        let lines = [];
        lines.push("Moneyinout Rank Change");
        lines.push("------------------------------------");

        // group by chartTitle
        const byChart = {};
        window.__globalRankChanges__.forEach((chg) => {
          if (!byChart[chg.chartTitle]) {
            byChart[chg.chartTitle] = [];
          }
          byChart[chg.chartTitle].push(chg);
        });

        Object.keys(byChart).forEach((chartTitle) => {
          lines.push(chartTitle);
          byChart[chartTitle].forEach((chg) => {
            lines.push(`${chg.symbol} ${chg.oldRank} to ${chg.newRank} value ${chg.value.toLocaleString('en-US', { maximumFractionDigits: 2 })}`);
          });
          lines.push("------------------------------------");
        });

        const finalMsg = lines.join("\n");
        sendTelegramMessage(finalMsg);

        // clear
        window.__globalRankChanges__ = [];
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!window.mySwiper) {
      window.mySwiper = null;
    }
  }, []);



  return (
    <div
      className="dashboard"
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: (isMobile || isTablet) ? '10px' : '20px',
        fontFamily: 'Lato, Sans-serif',
      }}
    >
      <FontLoader/>
      <div style={{ maxWidth: '1800px', width: '100%' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>
          Ideatradepoint Realtime
        </h1>
        <div
          className="sections"
          style={{
            display: 'grid',
            gridTemplateColumns: (isMobile || isTablet) ? '1fr' : '1fr 1fr',
            gap: '20px'
          }}
        >
          {/* โค้ดเก่า */}
          {/* SET100 and NON-SET100 Money In (+) */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
            <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
          </div> */}

          {/* SET100 and NON-SET100 Money Out (-) */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
            <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
          </div> */}

          {/* MAI and WARRANT Money In (+) */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
            <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
          </div> */}

          {/* MAI and WARRANT Money Out (-) */}
          {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
            <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
          </div> */}

          {/* โค้ดใหม่ */}

          {(!isMobile && !isTablet) && (
            <>
              {/* หากเข้าสู่ Desktop mode */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
              </div>
            </>
          )}
          {(isTablet && !isPTablet) && (
            <Swiper
              style={{ width: '100%', maxWidth: '1200px' }}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={1}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              onSwiper={(swiper) => {
                window.mySwiper = swiper;
              }}
            >
              {/* Slide 1 */}
              <SwiperSlide>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                    gap: '10px', // ระยะห่างระหว่าง Grid
                  }}
                >
                  <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                  <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
                  <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                  <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
                </div>
              </SwiperSlide>

              {/* Slide 2 */}
              <SwiperSlide>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                    gap: '10px',
                  }}
                >
                  <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                  <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
                  <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                  <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
                </div>
              </SwiperSlide>
            </Swiper>
          )}
          {(isMobile || isPTablet) && (
            <>
              {/* หากเข้าสู่ Mobile mode */}
              <Swiper
                style={{ width: '100%', maxWidth: '600px' }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{
                  clickable: true, dynamicBullets: true,
                }}
                onSwiper={(swiper) => {
                  window.mySwiper = swiper;
                }}
              >
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                    <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
                    <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                    <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
                    <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
                  </div>
                </SwiperSlide>

              </Swiper>

              {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
              </div> */}
              {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
        <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
    </div> */}
            </>
          )
          }
        </div>
      </div>
    </div>
  );
};

export default MoneyInOut;
