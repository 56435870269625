import React from 'react';
import LookerEmbed from './LookerEmbed';

function Fund() {
  return (
    <div>
      <LookerEmbed />
    </div>
  );
}

export default Fund;
