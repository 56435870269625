import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { useDispatch, useSelector } from "react-redux";
import Preloading from '../../components/Preloading';
import { Box, Button, TextField, Typography, useTheme, Container, Autocomplete } from '@mui/material';
import { tokens } from '../../theme';
import FontLoader from '../../components/FontLoader';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';
// import Papa from 'papaparse';
// import './DW.css';
// import config from '../config/config.json';

var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

const parseCSV = (text) => {
  const lines = text.trim().split('\n');
  const headers = lines[0].split(',').map(header => header.trim());
  const result = [];

  for (let i = 1; i < lines.length; i++) {
    if (lines[i].trim() === '') continue; // Skip empty lines
    const obj = {};
    const currentLine = lines[i].split(',').map(value => value.trim());
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j];
    }
    result.push(obj);
  }
  return result;
};

const DW = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [symbols, setSymbols] = useState([]); // State to store symbols
  const [symbol, setSymbol] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(new Date(Date.now() - tzoffset).toISOString().slice(0, 16));
  const [chartDataPrice, setChartDataPrice] = useState([]);
  const [chartDataC, setChartDataC] = useState([]);
  const [chartDataP, setChartDataP] = useState([]);
  const [chartDataDif, setChartDataDif] = useState([]);
  const [peaksPrice, setPeaksPrice] = useState([]);
  const [valleysPrice, setValleysPrice] = useState([]);
  const [peaksC, setPeaksC] = useState([]);
  const [valleysC, setValleysC] = useState([]);
  const [peaksP, setPeaksP] = useState([]);
  const [valleysP, setValleysP] = useState([]);
  const [peaksDif, setPeaksDif] = useState([]);
  const [valleysDif, setValleysDif] = useState([]);
  const [foldersPrice, setFoldersPrice] = useState([]);
  const [foldersC, setFoldersC] = useState([]);
  const [foldersP, setFoldersP] = useState([]);
  const [symbolConfirmed, setSymbolConfirmed] = useState(false);
  const [timeFrame, setTimeFrame] = useState('Intraday');
  const [loading, setLoading] = useState(false);

  const priceChartRef = useRef();
  const cChartRef = useRef();
  const pChartRef = useRef();
  const difChartRef = useRef(); // New ref for difChart
  const csrfToken = useSelector((state) => state.csrfToken);
  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();


  useEffect(() => { // Load symbols from Symbol.csv (initial fetch)
    const loadSymbols = async () => {
      try {
        const fetch_response = await fetch(`${window.config.base_api_url}/api/data/Symbol`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        });
        const data = await fetch_response.text();
        const parsedData = parseCSV(data);
        const symbolList = parsedData.map(row => row.Symbol);
        setSymbols(symbolList);
      } catch (err) {
        console.error("Error loading symbols:", err);
      }
    };

    loadSymbols();
  }, []);

  const fetchData = async () => {
    if (symbol && startDate && endDate) {
      setLoading(true); // Start loading

      try {
        const dataPrice = await fetch(`${window.config.base_api_url}/api/data/${symbol}/Price`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        }).then(res => res.text());

        const dataC = await fetch(`${window.config.base_api_url}/api/data/${symbol}/C`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        }).then(res => res.text()).catch(() => '');

        const dataP = await fetch(`${window.config.base_api_url}/api/data/${symbol}/P`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        }).then(res => res.text()).catch(() => '');

        const dataDif = await fetch(`${window.config.base_api_url}/api/data/${symbol}/dif`, {
          headers: {
            'Content-Type': 'text/csv',
            'xsrf-token': csrfToken,
          },
        }).then(res => res.text()).catch(() => '');

        // Determine which peak/valley columns to use based on the selected time frame
        let peakColumn = '';
        let valleyColumn = '';

        switch (timeFrame) {
          case 'Intraday':
            peakColumn = 'peak_Intraday';
            valleyColumn = 'valley_Intraday';
            break;
          case '30 Min':
            peakColumn = 'peak_30';
            valleyColumn = 'valley_30';
            break;
          case '60 Min':
            peakColumn = 'peak_60';
            valleyColumn = 'valley_60';
            break;
          case 'Day':
            peakColumn = 'peak_Day';
            valleyColumn = 'valley_Day';
            break;
          default:
            peakColumn = 'peak_Intraday';
            valleyColumn = 'valley_Intraday';
        }

        const folderSetPrice = new Set();
        const folderSetC = new Set();
        const folderSetP = new Set();

        const filterAndMapData = (data, folderSet, isPriceData = false) => {
          const folderArray = [];
          const filteredData = [];
          const peakData = [];
          const valleyData = [];
          const lastDataPointOfDay = {}; // Object to store the last data point and folder for each day

          const parsedData = parseCSV(data);

          parsedData.forEach(d => {
            const date = new Date(d.Timestamp);
            const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); // Convert UTC to local time
            const timestamp = localDate.getTime() / 1000; // Convert to seconds

            if (date >= new Date(startDate) && date <= new Date(endDate)) {
              let shouldAddData = false;

              if (timeFrame === 'Intraday') {
                shouldAddData = true;
              } else if (timeFrame === '30 Min' && date.getMinutes() % 30 === 0) {
                shouldAddData = true;
              } else if (timeFrame === '60 Min' && date.getMinutes() === 0) {
                shouldAddData = true;
              } else if (timeFrame === 'Day') {
                const dayKey = localDate.toISOString().split('T')[0]; // YYYY-MM-DD format
                // Store the latest data point for each day along with peak and valley
                lastDataPointOfDay[dayKey] = {
                  time: timestamp,
                  value: parseFloat(d.Value),
                  folders: d.Folder,
                  peak: parseFloat(d[peakColumn]), // Capture peak for the day
                  valley: parseFloat(d[valleyColumn]) // Capture valley for the day
                };
              }

              if (shouldAddData) {
                // Normalize folder string
                let folders = d.Folder.split(',')
                  .map(folder => folder.trim())
                  .filter(folder => folder && folder.toLowerCase() !== 'nan'); // Filter out empty and 'NaN' folders

                folders = [...new Set(folders)]; // Remove duplicates
                folders.sort(); // Sort folders alphabetically

                // Add normalized folders to the folderArray
                folders.forEach(folder => folderArray.push(folder));

                filteredData.push({
                  time: timestamp,
                  value: parseFloat(d.Value),
                });

                // Process peaks and valleys based on the selected time frame
                if (isPriceData) {
                  if (d[peakColumn] && !isNaN(d[peakColumn])) {
                    peakData.push({ time: timestamp, value: parseFloat(d[peakColumn]) });
                  }
                  if (d[valleyColumn] && !isNaN(d[valleyColumn])) {
                    valleyData.push({ time: timestamp, value: parseFloat(d[valleyColumn]) });
                  }
                }
              }
            }
          });

          // If the timeFrame is 'Day', add the last data point of each day including peaks and valleys
          if (timeFrame === 'Day') {
            Object.keys(lastDataPointOfDay).forEach(day => {
              const dataPoint = lastDataPointOfDay[day];
              filteredData.push({
                time: dataPoint.time,
                value: dataPoint.value,
              });

              // Process and add folder information
              let folders = dataPoint.folders.split(',')
                .map(folder => folder.trim())
                .filter(folder => folder && folder.toLowerCase() !== 'nan'); // Filter out empty and 'NaN' folders

              folders = [...new Set(folders)]; // Remove duplicates
              folders.sort(); // Sort folders alphabetically

              // Add folders to folderArray
              folders.forEach(folder => folderArray.push(folder));

              // Add peak and valley data for the day
              if (!isNaN(dataPoint.peak)) {
                peakData.push({ time: dataPoint.time, value: dataPoint.peak });
              }
              if (!isNaN(dataPoint.valley)) {
                valleyData.push({ time: dataPoint.time, value: dataPoint.valley });
              }
            });
          }

          // Sort and store the folder data
          folderArray.sort((a, b) => a.localeCompare(b)); // Sort the array
          folderArray.forEach(folder => folderSet.add(folder)); // Add sorted folders to the set

          // Return the filtered data and peaks/valleys
          return {
            filteredData: filteredData, // Ensure sorted by time
            peakData,
            valleyData,
          };
        };

        // Process data for all charts
        const priceData = filterAndMapData(dataPrice, folderSetPrice, true);
        const cData = filterAndMapData(dataC, folderSetC, true);
        const pData = filterAndMapData(dataP, folderSetP, true);
        const difData = filterAndMapData(dataDif, new Set(), true);

        // Set chart data and peaks/valleys for each chart
        setChartDataPrice(priceData.filteredData);
        setChartDataC(cData.filteredData);
        setChartDataP(pData.filteredData);
        setChartDataDif(difData.filteredData);

        setPeaksPrice(priceData.peakData);
        setValleysPrice(priceData.valleyData);

        setPeaksC(cData.peakData);
        setValleysC(cData.valleyData);

        setPeaksP(pData.peakData);
        setValleysP(pData.valleyData);

        setPeaksDif(difData.peakData);
        setValleysDif(difData.valleyData);

        // Set folder data for display
        setFoldersPrice(Array.from(new Set(folderSetPrice)).join(', '));
        setFoldersC(Array.from(new Set(folderSetC)).join(', '));
        setFoldersP(Array.from(new Set(folderSetP)).join(', '));
        console.log("folder P is", foldersP)

        setLoading(false); // End loading
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (symbolConfirmed) { // Only fetch data if symbol is confirmed
      fetchData(); // Fetch new data when symbol, startDate, endDate, or timeFrame changes
    }
  }, [symbolConfirmed, startDate, endDate, timeFrame]); // Added symbolConfirmed here

  useEffect(() => { // Create charts and synchronize them

    const resizeChart = (chart, chartRef) => {
      if (chart && chartRef.current) {
        const width = chartRef.current.clientWidth;
        const height = chartRef.current.clientHeight;
        chart.resize(width, 300); // Fixed height at 300px
      }

    };

    const priceChart = createChart(priceChartRef.current, {
      width: priceChartRef.current.clientWidth,
      height: priceChartRef.current.clientHeight,
      crosshair: { mode: 1 },
      timeScale: { timeVisible: true, rightOffset: chartDataPrice.length <= 20 ? 0 : chartDataPrice.length <= 50 ? 1 : 5 },
      rightPriceScale: { visible: true }, // Hide price scale
    });

    // Apply watermark with dynamic symbol
    priceChart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'top',
        color: theme.palette.chart.watermark, // Adjust color and opacity as needed
        text: `${symbol.toUpperCase()}`,
      },
      layout: {
        background: { color: theme.palette.table.odd }, // #434C5E
        textColor: theme.palette.text.secondary, //#AAB1C4
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง #5C667D
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน #5C667D
      },
    });

    priceChartRef.current.style.borderRadius = '10px';
    priceChartRef.current.style.overflow = 'hidden';

    const priceSeries = priceChart.addLineSeries({ color: '#a096ff', priceLineVisible: false });
    priceSeries.setData(chartDataPrice);

    let cSeries, pSeries, difSeries;

    // Create cChart (C data)
    const cChart = createChart(cChartRef.current, {
      // height: 400, 
      height: 300,
      crosshair: { mode: 1 },
      timeScale: { timeVisible: true, rightOffset: chartDataC.length <= 20 ? 0 : chartDataC.length <= 50 ? 1 : 5 },
      rightPriceScale: { visible: true }, // Hide price scale
    });

    cChart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'top',
        color: theme.palette.chart.watermark,
        text: `CALL`,
        fontFamily: 'Lato, Sans-serif',
      },
      layout: {
        background: { color: theme.palette.table.odd }, // #434C5E
        textColor: theme.palette.text.secondary, //#AAB1C4
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง #5C667D
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน #5C667D
      },
    });

    cChartRef.current.style.borderRadius = '10px';
    cChartRef.current.style.overflow = 'hidden';

    cSeries = cChart.addLineSeries({
      color: '#f5db66', priceLineVisible: false,
      priceFormat: {
        type: 'custom',
        minMove: 0.00000001,
        formatter: price => {
          const absPrice = Math.abs(price);
          if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
          if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
          if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
          if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
          if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
          return price.toFixed(0);
        },
      },
    });
    cSeries.setData(chartDataC);
    // cChart.timeScale().fitContent();

    // Create pChart (P data)
    const pChart = createChart(pChartRef.current, {
      // height: 400, 
      height: 300,
      crosshair: { mode: 1 },
      timeScale: { timeVisible: true, rightOffset: chartDataP.length <= 20 ? 0 : chartDataP.length <= 50 ? 1 : 5 },
      rightPriceScale: { visible: true },
    });

    pChart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'top',
        color: theme.palette.chart.watermark,
        text: `PUT`,
        fontFamily: 'Lato, Sans-serif',
      },
      layout: {
        background: { color: theme.palette.table.odd }, // #434C5E
        textColor: theme.palette.text.secondary, //#AAB1C4
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง #5C667D
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน #5C667D
      },
    });

    pChartRef.current.style.borderRadius = '10px';
    pChartRef.current.style.overflow = 'hidden';

    pSeries = pChart.addLineSeries({
      color: '#ff7571', priceLineVisible: false,
      priceFormat: {
        type: 'custom',
        minMove: 0.00000001,
        formatter: price => {
          const absPrice = Math.abs(price);
          if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
          if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
          if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
          if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
          if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
          return price.toFixed(0);
        },
      },
    });
    pSeries.setData(chartDataP);
    // pChart.timeScale().fitContent();

    // Create difChart (Dif data)
    const difChart = createChart(difChartRef.current, {
      height: 300,
      // height: 400, 
      crosshair: { mode: 1 },
      timeScale: { timeVisible: true, rightOffset: chartDataDif.length <= 20 ? 0 : chartDataDif.length <= 50 ? 1 : 5 },
      rightPriceScale: { visible: true },
    });

    difChart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: 'center',
        vertAlign: 'top',
        color: theme.palette.chart.watermark,
        text: `NET`,
        fontFamily: 'Lato, Sans-serif',
      },
      layout: {
        background: { color: theme.palette.table.odd }, // #434C5E
        textColor: theme.palette.text.secondary, //#AAB1C4
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง #5C667D
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน #5C667D
      },
    });

    difChartRef.current.style.borderRadius = '10px';
    difChartRef.current.style.overflow = 'hidden';

    difSeries = difChart.addLineSeries({
      color: '#92f467',
      priceLineVisible: false,
      priceFormat: {
        type: 'custom',
        minMove: 0.00000001,
        formatter: price => {
          const absPrice = Math.abs(price);
          if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
          if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
          if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
          if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
          if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
          return price.toFixed(0);
        },
      },
    });
    difSeries.setData(chartDataDif);
    // difChart.timeScale().fitContent();

    // const handleResize = () => {
    //   resizeChart(priceChart, priceChartRef);
    //   resizeChart(cChart, cChartRef);
    //   resizeChart(pChart, pChartRef);
    //   resizeChart(difChart, difChartRef);
    // };

    // window.addEventListener('resize', handleResize);

    // // Initial resize
    // handleResize();

    requestAnimationFrame(() => {
      if (timeFrame !== 'Intraday') { // ถ้าไม่ใช่ Intraday ให้ fitContent()
        priceChart.timeScale().fitContent();
        cChart.timeScale().fitContent();
        pChart.timeScale().fitContent();
        difChart.timeScale().fitContent();
      }
    });

    const resizeObserver = new ResizeObserver(() => {
      resizeChart(priceChart, priceChartRef);
      resizeChart(cChart, cChartRef);
      resizeChart(pChart, pChartRef);
      resizeChart(difChart, difChartRef);
    });

    resizeObserver.observe(priceChartRef.current);
    resizeObserver.observe(cChartRef.current);
    resizeObserver.observe(pChartRef.current);
    resizeObserver.observe(difChartRef.current);

    const addMarkersToChart = (chartSeries, peaks, valleys) => {
      const markers = [];

      peaks.forEach(peak => {
        markers.push({
          time: peak.time, // Ensure time is in ascending order
          position: 'aboveBar',
          color: '#83D143',
          shape: 'arrowUp',
          text: `U`,
        });
      });

      valleys.forEach(valley => {
        markers.push({
          time: valley.time, // Ensure time is in ascending order
          position: 'belowBar', // Triangle below the data point
          color: '#F5564F', // Color for valleys
          shape: 'arrowDown',
          text: `D`,
        });
      });

      // Sort markers by time (ascending)
      markers.sort((a, b) => a.time - b.time);

      // Set markers on the series
      chartSeries.setMarkers(markers);
    };

    // Example: Add markers to a specific chart series
    addMarkersToChart(priceSeries, peaksPrice, valleysPrice);
    addMarkersToChart(cSeries, peaksC, valleysC);
    addMarkersToChart(pSeries, peaksP, valleysP);
    addMarkersToChart(difSeries, peaksDif, valleysDif);

    // Synchronize visible range across charts
    const syncVisibleRange = (sourceChart, targetChart) => {
      if (sourceChart && targetChart) {
        sourceChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
          targetChart.timeScale().setVisibleLogicalRange(timeRange);
        });
      }
    };

    // Synchronize crosshair movement across charts
    const syncCrosshair = (sourceChart, sourceSeries, targetChart, targetSeries) => {
      if (sourceChart && targetChart) {
        sourceChart.subscribeCrosshairMove(param => {
          const dataPoint = param.seriesData.get(sourceSeries);
          if (dataPoint) {
            targetChart.setCrosshairPosition(dataPoint.value, dataPoint.time, targetSeries);
          } else {
            targetChart.clearCrosshairPosition();
          }
        });
      }
    };

    // Synchronize charts
    if (cChart) {
      syncVisibleRange(priceChart, cChart);
      syncVisibleRange(cChart, priceChart);
      syncCrosshair(priceChart, priceSeries, cChart, cSeries);
      syncCrosshair(cChart, cSeries, priceChart, priceSeries);
    }

    if (pChart) {
      syncVisibleRange(priceChart, pChart);
      syncVisibleRange(pChart, priceChart);
      syncCrosshair(priceChart, priceSeries, pChart, pSeries);
      syncCrosshair(pChart, pSeries, priceChart, priceSeries);
    }

    if (difChart) { // Sync difChart with other charts
      syncVisibleRange(priceChart, difChart);
      syncVisibleRange(difChart, priceChart);
      syncCrosshair(priceChart, priceSeries, difChart, difSeries);
      syncCrosshair(difChart, difSeries, priceChart, priceSeries);

      if (cChart) {
        syncVisibleRange(cChart, difChart);
        syncVisibleRange(difChart, cChart);
        syncCrosshair(cChart, cSeries, difChart, difSeries);
        syncCrosshair(difChart, difSeries, cChart, cSeries);
      }

      if (pChart) {
        syncVisibleRange(pChart, difChart);
        syncVisibleRange(difChart, pChart);
        syncCrosshair(pChart, pSeries, difChart, difSeries);
        syncCrosshair(difChart, difSeries, pChart, pSeries);
      }
    }

    return () => {
      // window.removeEventListener('resize', handleResize);
      resizeObserver.disconnect();

      priceChart.remove();
      cChart && cChart.remove();
      pChart && pChart.remove();
      difChart && difChart.remove(); // Remove difChart on cleanup
    };
  }, [chartDataPrice, chartDataC, chartDataP, chartDataDif, peaksPrice, valleysPrice, peaksC, valleysC, peaksP, valleysP, peaksDif, valleysDif, symbol]); // Added all peak/valley data to dependency array

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ marginBottom: '20px' }}>DW View Charts</h1>
      <Box sx={{ backgroundColor: theme.palette.background.sidebar, maxWidth: '95%', width: '100%', padding: 1, borderRadius: '10px', boxShadow: theme.palette.shadow.primary }}>

        <Box style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>

          {!isMobile && <>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {/* <TextField
              label="Symbol"
              type='text'
              size="small"
              variant="outlined"
              width='50px'
              value={symbol}
              onChange={(e) => {
                setSymbol(e.target.value);
                setSymbolConfirmed(false); // Reset confirmation when symbol changes
              }}
            // placeholder='Search or select a symbol'
            /> */}

              <Autocomplete
                freeSolo
                size='small'
                options={symbols} // รายการตัวเลือก
                value={symbol} // ค่าปัจจุบัน
                onChange={(event, newValue) => {
                  setSymbol(newValue || ""); // อัปเดตค่าเมื่อเลือก
                  setSymbolConfirmed(false); // Reset confirmation
                }}
                onInputChange={(event, newInputValue) => {
                  setSymbol(newInputValue); // อัปเดตค่าเมื่อพิมพ์
                  setSymbolConfirmed(false);
                }}
                sx={{
                  width: '150px'
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Symbol"
                    placeholder="Search or select a symbol"
                    variant="outlined"
                  />
                )}
              />


              <TextField
                label="Start Datetime"
                type='datetime-local'
                size='small'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }} // label always on top
              />



              <TextField
                label="End Datetime"
                type='datetime-local'
                size='small'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />

              <Button
                onClick={() => setSymbolConfirmed(true)}
                variant="contained"
                disabled={!symbol || !startDate || !endDate}
                sx={{ backgroundColor: colors.greenChart[200] }}
              >
                Enter
              </Button>
            </Box></>}

          {isMobile && <>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flexDirection: 'column' }}>
              {/* <TextField
              label="Symbol"
              type='text'
              size="small"
              variant="outlined"
              width='50px'
              value={symbol}
              onChange={(e) => {
                setSymbol(e.target.value);
                setSymbolConfirmed(false); // Reset confirmation when symbol changes
              }}
            // placeholder='Search or select a symbol'
            /> */}

              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                <Autocomplete
                  freeSolo
                  size='small'
                  options={symbols} // รายการตัวเลือก
                  value={symbol} // ค่าปัจจุบัน
                  onChange={(event, newValue) => {
                    setSymbol(newValue || ""); // อัปเดตค่าเมื่อเลือก
                    setSymbolConfirmed(false); // Reset confirmation
                  }}
                  onInputChange={(event, newInputValue) => {
                    setSymbol(newInputValue); // อัปเดตค่าเมื่อพิมพ์
                    setSymbolConfirmed(false);
                  }}
                  sx={{
                    width: '200px'
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Symbol"
                      placeholder="Search or select a symbol"
                      variant="outlined"
                    />
                  )}
                />
                <Button
                  onClick={() => setSymbolConfirmed(true)}
                  variant="contained"
                  disabled={!symbol || !startDate || !endDate}
                  sx={{ backgroundColor: colors.greenChart[200], width: '100px' }}
                >
                  Enter
                </Button>
              </Box>


              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                <TextField
                  label="Start Datetime"
                  type='datetime-local'
                  size='small'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }} // label always on top
                />



                <TextField
                  label="End Datetime"
                  type='datetime-local'
                  size='small'
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />

              </Box>



            </Box></>}

          {/* Time Frame Selector */}
          <Box sx={{ marginBottom: '10px', gap: '5px', display: 'flex', alignItems: 'center' }}>
            {/* <label >Time frame:</label> */}

            <Typography variant='subtitle1'>Time frame:</Typography>

            <Button
              size='small'
              onClick={() => setTimeFrame('Intraday')}
              sx={{
                cursor: 'pointer',
                backgroundColor: timeFrame === 'Intraday' ? theme.palette.table.thead : 'normal',
                fontWeight: timeFrame === 'Intraday' ? 'bold' : 'normal',
                borderRadius: '4px'
              }}
            >
              Intraday
            </Button>



            <Button
              size='small'
              onClick={() => setTimeFrame('30 Min')}
              sx={{
                cursor: 'pointer',
                backgroundColor: timeFrame === '30 Min' ? theme.palette.table.thead : 'normal',
                fontWeight: timeFrame === '30 Min' ? 'bold' : 'normal',
                fontFamily: 'Lato, Sans-serif',
                fontFamily: 'Lato, Sans-serif',
                borderRadius: '4px'
              }}
            >
              30 Min
            </Button>


            <Button
              size='small'
              onClick={() => setTimeFrame('60 Min')}
              sx={{
                cursor: 'pointer',
                backgroundColor: timeFrame === '60 Min' ? theme.palette.table.thead : 'normal',
                fontWeight: timeFrame === '60 Min' ? 'bold' : 'normal',
                fontFamily: 'Lato, Sans-serif',
                borderRadius: '4px'
              }}
            >
              60 Min
            </Button>



            <Button
              size='small'
              onClick={() => setTimeFrame('Day')}
              sx={{
                cursor: 'pointer',
                backgroundColor: timeFrame === 'Day' ? theme.palette.table.thead : 'normal',
                fontWeight: timeFrame === 'Day' ? 'bold' : 'normal',
                borderRadius: '4px'
              }}
            >
              Day
            </Button>
          </Box>
        </Box>
        <Preloading open={loading} />
        {loading ? (
          <div style={{
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#000000',
            textAlign: 'center',
            marginTop: '20px',
            fontFamily: 'Lato, Sans-serif',
          }}></div>
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            gap: '10px'
            // maxWidth: '1800px',
          }}>
            <div style={{
              // flex: 1,
              width: '50%',
              //  margin: '0 10px' 
            }}>
              <div ref={priceChartRef}></div>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                // justifyContent: 'center', // จัดให้อยู่ตรงกลาง
                gap: '10px', // ระยะห่างเท่ากัน
                marginTop: '10px',
                marginBottom: '10px'
              }}>
                {(typeof foldersPrice === 'string' ? foldersPrice.split(',') : []).map((folder, index) => (
                  <span key={index} style={{
                    // display: 'inline-block',
                    // color: theme.palette.text.primary,
                    // minWidth: '130px',
                    // backgroundColor: colors.tonal[10]

                    display: 'inline-block',
                    color: theme.palette.text.primary,
                    backgroundColor: colors.tonal[10],
                    padding: '6px 12px',
                    borderRadius: '8px', // ขอบโค้ง
                    textAlign: 'center',
                    width: 'fit-content', // ให้ขนาดพอดีกับข้อความ
                    minWidth: '130px'
                  }}>{folder.trim()}</span>
                ))
                }
              </div>

              <div ref={cChartRef}></div>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                // justifyContent: 'center', // จัดให้อยู่ตรงกลาง
                gap: '10px', // ระยะห่างเท่ากัน
                marginTop: '10px',
                marginBottom: '10px'
              }}>
                {symbolConfirmed ? (typeof foldersC === 'string' ? foldersC.split(',').map(f => f.trim()).filter(f => f) : []).length > 0
                  ? (typeof foldersC === 'string' ? foldersC.split(',') : []).map((folder, index) => (
                    folder.trim() && (
                      <span key={index} style={{
                        display: 'inline-block',
                        color: theme.palette.text.primary,
                        backgroundColor: colors.tonal[10],
                        padding: '6px 12px',
                        borderRadius: '8px',
                        textAlign: 'center',
                        width: 'fit-content',
                        minWidth: '130px'
                      }}>
                        {folder.trim()}
                      </span>
                    )
                  ))
                  : <span style={{
                    color: theme.palette.text.primary,
                    fontStyle: 'italic'
                  }}>
                    No folders to display
                  </span>
                  : null}
              </div>
            </div>
            <div style={{
              //  flex: 1,
              width: '50%',
              //  margin: '0 10px'
            }}>
              <div ref={difChartRef}></div>

              <div style={{
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
                marginTop: '10px',
                marginBottom: symbolConfirmed ? '10px' : '0px',
                fontSize: '14px',
                // color: '#414141',
                lineHeight: '1.5',
                padding: '6px 12px',
              }}>
                {symbolConfirmed ?
                  <span style={{
                    fontStyle: 'italic'
                  }}>
                    No folders to display
                  </span>

                  : null}
              </div>

              <div ref={pChartRef}></div>
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                // justifyContent: 'center', // จัดให้อยู่ตรงกลาง
                gap: '10px', // ระยะห่างเท่ากัน
                marginTop: '10px',
              }}>
                {/* {(typeof foldersP === 'string' ? foldersP.split(',') : []).map((folder, index) => (
                  <span key={index} style={{
                    // display: 'inline-block',
                    // color: theme.palette.text.primary,
                    // minWidth: '130px',
                    // backgroundColor: colors.tonal[10]

                    display: 'inline-block',
                    color: theme.palette.text.primary,
                    backgroundColor: colors.tonal[10],
                    padding: '6px 12px',
                    borderRadius: '8px', // ขอบโค้ง
                    textAlign: 'center',
                    width: 'fit-content', // ให้ขนาดพอดีกับข้อความ
                    minWidth: '130px'
                  }}>{folder.trim()}</span>
                ))} */}
                {symbolConfirmed ? (typeof foldersP === 'string' ? foldersP.split(',').map(f => f.trim()).filter(f => f) : []).length > 0
                  ? (typeof foldersP === 'string' ? foldersP.split(',') : []).map((folder, index) => (
                    folder.trim() && (
                      <span key={index} style={{
                        display: 'inline-block',
                        color: theme.palette.text.primary,
                        backgroundColor: colors.tonal[10],
                        padding: '6px 12px',
                        borderRadius: '8px',
                        textAlign: 'center',
                        width: 'fit-content',
                        minWidth: '130px'
                      }}>
                        {folder.trim()}
                      </span>
                    )
                  ))
                  : <span style={{
                    color: theme.palette.text.primary,
                    fontStyle: 'italic'
                  }}>
                    No folders to display
                  </span>
                  : null}
              </div>
            </div>
          </div>
        )}

      </Box>


    </div>
  );
};

export default DW;

