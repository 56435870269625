import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import './index.css';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, useMediaQuery, useTheme, IconButton } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import LoopIcon from '@mui/icons-material/Loop';
import FontLoader from '../../components/FontLoader';

// const API_BASE_URL = "http://172.18.1.81:3101/flow";

let colorIndex = 0;
const softDarkColors = [
  "#ffcc00", // เหลืองสดใส (Vivid Yellow)
  "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
  "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
  "#ff9966", // ส้มสด (Bright Orange)
  "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
  "#ffffff", // ขาว (White)
  "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
  "#ff66b3", // ชมพูสดใส (Hot Pink)
  "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
  "#3399ff", // ฟ้าสดใส (Sky Blue)
  "#ff9933", // ส้มอ่อนสด (Bright Peach)
  "#ff3399", // ชมพูบานเย็น (Magenta Pink)
  "#33ff66", // เขียวสด (Vivid Green)
  "#ff9966", // พีชสดใส (Peach Orange)
  "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
  "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
  "#66ccff", // ฟ้าสดกลาง (Electric Blue)
  "#33ccff", // ฟ้าสด 
  "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
  "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
];

const getRandomColor = () => {
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};
const ChartSectionWithMultipleLines = ({ title, market, type }) => {
  const chartContainerRef = useRef(null);
  const chartTableContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const theme = useTheme();

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef(previousRanks);
  const [lastRankChange, setLastRankChange] = useState({});
  const lastRankChangeRef = useRef(lastRankChange);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const csrfToken = useSelector((state) => state.csrfToken);

  const highlightColorsRef = useRef({});
  


  // Decide whether to invert + negate values if "Down (-)" or type === "out"
  const shouldInvert = (type === "out");

  const [allTimeRange, setAllTimeRange] = useState({ from: null, to: null });


  
  

  const fetchHistoricalData = async () => {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/flow/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      console.log(response);

      const data = response.data[type]; // 'in' or 'out'
      const historicalData = {};

      const times = Object.keys(data.rank);
      times.sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build historical data
      times.forEach((time) => {
        const timestamp = Math.floor(new Date(time).getTime() / 1000);

        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: finalValue,
          });
        });
      });

      const newPreviousRanks = {};

      // Track rank changes
      let lastChangeTime = -Infinity;
      const rankChanges = []; // {symbol, time, oldRank, newRank}

      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null; 
              

            
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);

        const latestRank = symbolData[symbolData.length - 1].rank;
        newPreviousRanks[symbol] = latestRank;

        // Detect rank changes
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            const changeTime = symbolData[i].time;
            rankChanges.push({
              symbol: symbol,
              time: changeTime, 
              oldRank: symbolData[i - 1].rank,
              newRank: symbolData[i].rank
            });
            if (changeTime > lastChangeTime) {
              lastChangeTime = changeTime;
            }
          }
        }

        // Create or update series
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color: color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        const seriesData = symbolData.map((dataPoint) => ({
          time: dataPoint.time,
          value: dataPoint.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      // คำนวณช่วงเวลาทั้งหมดของข้อมูล
      const allTimes = [];
      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (symbolData && symbolData.length > 0) {
          symbolData.forEach((dataPoint) => {
            allTimes.push(dataPoint.time);
          });
        }
      });

      if (allTimes.length > 0) {
        const minTime = Math.min(...allTimes);
        const maxTime = Math.max(...allTimes);

        setAllTimeRange({ from: minTime, to: maxTime });
          //config graph scale
        if (chartRef.current) {
          chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
          chartRef.current.timeScale().fitContent();
          chartRef.current.timeScale().applyOptions({ rightOffset: 50 });
        }
      }

      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // Remove obsolete symbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      symbolsToRemove.forEach((symbol) => {
        const s = lineSeriesRef.current.get(symbol);
        if (s) {
          chartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });

      if (selectedSymbol && !latestSymbols.includes(selectedSymbol)) {
        setSelectedSymbol(null);
      }

      // Determine highlight colors based on the lastChangeTime
      const lastChanges = rankChanges.filter((rc) => rc.time === lastChangeTime);
      const symbolColors = new Map();
      if (lastChangeTime !== -Infinity && lastChanges.length > 0) {
        lastChanges.forEach((change) => {
          if (change.newRank < change.oldRank) {
            symbolColors.set(change.symbol, '#43a047'); // Green
          } else if (change.newRank > change.oldRank) {
            symbolColors.set(change.symbol, '#D32F2F'); // Red
          } else {
            symbolColors.set(change.symbol, 'transparent');
          }
        });
      }

      // Update table data
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';
        return {
          rank: latestData.rank,
          symbol,
          value: latestData.value,
          color,
          pct_change: data.pct_change ? data.pct_change[symbol] : null, // add pct_change here
        };
      });

      setTableData(updatedTableData);
      tableDataRef.current = updatedTableData;
      updateSeriesVisibility();
    } catch (error) {
      console.error(`Error fetching historical data for ${market}:`, error);
    }
  };
  
  const fetchCurrentData = async () => {
    try {
      if (Object.keys(previousRanksRef.current).length === 0) {
        return;
      }
      const fetch_response = await fetch(`${window.config.base_api_url}/flow/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      console.log("API response status:", response.status);
      console.log("API response code:", response.code);
      console.log("Rank keys count:", Object.keys(response.data[type].rank).length);
      Object.keys(response.data[type].rank).slice(0, 5).forEach(time => {
        console.log(`Time ${time} has ${Object.keys(response.data[type].rank[time]).length} symbols`);
      });

      const data = response.data[type]; // "in" or "out"
      const timeKey = Object.keys(data.rank)[0];
      const timestamp = Math.floor(new Date(timeKey).getTime() / 1000);

      const latestSymbols = Object.keys(data.rank[timeKey]);

      // Compare ranks for changes
      const newPreviousRanks = {};
      latestSymbols.forEach((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const oldRank = previousRanksRef.current[symbol];

        // If oldRank is undefined => NEW symbol => treat as green
        if (oldRank === undefined) {
          highlightColorsRef.current[symbol] = '#43a047'; // new symbol => green
        } else if (currentRank < oldRank) {
          highlightColorsRef.current[symbol] = '#43a047'; // green
        } else if (currentRank > oldRank) {
          highlightColorsRef.current[symbol] = '#D32F2F'; // red
        }
        // if currentRank === oldRank => do NOTHING
        // that preserves any previous color

        newPreviousRanks[symbol] = currentRank;
      });

      // Store table
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = seriesDataRef.current.get(symbol) || [];
        const latestData = symbolData[symbolData.length - 1];
        const color = highlightColorsRef.current[symbol] || 'transparent';

        return {
          rank: newPreviousRanks[symbol],
          symbol,
          value: latestData.value,
          color,
          pct_change: data.pct_change ? data.pct_change[symbol] : null, // add pct_change here       
        };
      });

      setTableData(updatedTableData);
      tableDataRef.current = updatedTableData;
      updateSeriesVisibility();

      // Update previousRanks
      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // Remove missing symbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          chartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);

        // Also remove from highlightColorsRef if symbol is truly gone
        delete highlightColorsRef.current[symbol];
      });

      if (selectedSymbol && !latestSymbols.includes(selectedSymbol)) {
        setSelectedSymbol(null);
      }

      // Update the chart series with new data points
      latestSymbols.forEach((symbol) => {
        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: (shouldInvert ? -1 : 1) * data.value[timeKey][symbol],
        };

        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          const existingData = seriesDataRef.current.get(symbol) || [];
          const lastPoint = existingData[existingData.length - 1];

          if (lastPoint && lastPoint.time === updatePoint.time) {
            existingData[existingData.length - 1] = updatePoint;
          } else {
            existingData.push(updatePoint);
          }
          series.setData(existingData);
          seriesDataRef.current.set(symbol, existingData);
        } else {
          // new symbol => create a line series
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          series.setData([updatePoint]);
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
          seriesDataRef.current.set(symbol, [updatePoint]);
        }
      });

      // Highlight changes
      const symbolColors = new Map();
      if (Object.keys(highlightColorsRef.current).length > 0) {
        latestSymbols.forEach((symbol) => {
          const color = highlightColorsRef.current[symbol];
          if (color) {
            symbolColors.set(symbol, color);
          }
        });
      }

      // Update table data
      const finalTableData = latestSymbols.map((symbol) => {
        const dataArr = seriesDataRef.current.get(symbol) || [];
        const latestData = dataArr[dataArr.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';
        return {
          rank: newPreviousRanks[symbol],
          symbol,
          value: latestData.value,
          color,
          pct_change: data.pct_change ? data.pct_change[symbol] : null,
        };
      });
      setTableData(finalTableData);
      tableDataRef.current = finalTableData;
      updateSeriesVisibility();
      

    } catch (error) {
      console.error(`Error fetching current data for ${market}:`, error);
    }
  };

  const handleRowClick = (symbol, rank) => {
    if (selectedSymbol === symbol) {
      // Deselect: Reset styles for all series and then update visibility.
      setSelectedSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const originalColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: originalColor,
          lineWidth: 2,
        });
      });
      updateSeriesVisibility();
    } else {
      setSelectedSymbol(symbol);
      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);

      if (selectedSeries && chartRef.current) {
        // Remove and re-add to bring the series to the front.
        chartRef.current.removeSeries(selectedSeries);
        const newSeries = chartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        const originalColor = originalColorsRef.current.get(symbol);
        const oppositeColor = getOppositeColor();
        newSeries.applyOptions({
          color: '#66ffff',
          lineWidth: 4,
        });
      }

      if (rank > 5) {
        // For a series with rank 11–20, hide all others so only the selected one is visible.
        lineSeriesRef.current.forEach((series, sym) => {
          series.applyOptions({ visible: sym === symbol });
        });
      } else {
        // For a top-10 series, show all top-10 series and fade the unselected ones.
        lineSeriesRef.current.forEach((series, sym) => {
          const row = tableDataRef.current.find(r => r.symbol === sym);
          if (row && row.rank <= 5) {
            if (sym === symbol) {
              series.applyOptions({ visible: true });
            } else {
              series.applyOptions({
                color: earthToneColor,
                lineWidth: 2,
                visible: true,
              });
            }
          } else {
            // Hide any series not in the top 10.
            series.applyOptions({ visible: false });
          }
        });
      }
    }
  };

  const updateSeriesVisibility = () => {
    // If a series is selected and its rank is greater than 10, show only that series.
    if (selectedSymbol) {
      const selRow = tableDataRef.current.find(row => row.symbol === selectedSymbol);
      if (selRow && selRow.rank > 10) {
        lineSeriesRef.current.forEach((series, sym) => {
          series.applyOptions({ visible: sym === selectedSymbol });
        });
        return;
      }
    }
    // Otherwise, make sure that only series with rank 1–10 are visible.
    lineSeriesRef.current.forEach((series, sym) => {
      const row = tableDataRef.current.find(r => r.symbol === sym);
      series.applyOptions({ visible: row && row.rank <= 5 });
    });
  };

  useEffect(() => {
    chartRef.current = createChart(chartContainerRef.current, {
      // layout: {
      //   background: { type: 'solid', color: 'white' }
      // },

      layout: {
        background: { color: theme.palette.table.odd },
        textColor: theme.palette.text.secondary,
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
      },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 270,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: {
          visible: false,
        },
        verticalLine: {
          visible: true,
        },
      },
    });

    chartRef.current.priceScale('right').applyOptions({
      textColor: 'white', // Set price scale text color to white

    });

    // If we want to invert the scale for type === "out"
    if (shouldInvert) {
      chartRef.current.applyOptions({
        invertScale: true,
      });
    }

    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) =>
          (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
      },
    });
    chartContainerRef.current.style.outline = "2px solid #fff"; // เปลี่ยนสีตามต้องการ
    chartContainerRef.current.style.boxSizing = "border-box"; // ป้องกัน outline เล็กกว่า chart
    chartContainerRef.current.style.borderRadius = "10px";
    chartContainerRef.current.style.overflow = "hidden";

    const initializeData = async () => {
      await fetchHistoricalData();
      await fetchCurrentData();
    };

    initializeData();


    const intervalID = setInterval(() => {
      fetchCurrentData();
    }, 10000);

    return () => {
      clearInterval(intervalID);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [market, type]);

  const toggleSeriesVisibility = (symbol) => {
    const series = lineSeriesRef.current.get(symbol);
    if (series) {
      const currentVisible = series.options().visible ?? true;
      series.applyOptions({ visible: !currentVisible });
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  };

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isCollapsed]);

  // ฟังก์ชัน Rezoom (คืนค่าช่วงเวลาเริ่มต้น)
  const handleRezoom = () => {
    // ตรวจสอบว่ามี chartRef หรือไม่
    if (chartRef.current) {
      // สมมติว่าคุณมีข้อมูลที่ใช้เพื่อกำหนดช่วงเวลา (minTime, maxTime)
      const minTime = 1610000000000; // เวลาเริ่มต้น
      const maxTime = 1620000000000; // เวลาสิ้นสุด

      // การตั้งค่าช่วงเวลาบนกราฟ
      chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
      chartRef.current.timeScale().fitContent();
      chartRef.current.timeScale().applyOptions({ rightOffset: 50 });
    }
  };

  const onChartTouch = (event, allowTouchMove) => {
    if (chartTableContainerRef.current && chartTableContainerRef.current.contains(event.target)) {
      window.mySwiper.allowTouchMove = allowTouchMove;
    }
  };
  const renderTable = (startIndex, endIndex, onRowClick, selectedSym) => {
    // 1) Sort the data by rank
    const sortedData = [...tableData].sort((a, b) => a.rank - b.rank);

    // 2) Build an array of exactly (endIndex - startIndex) rows (with placeholders if needed)
    const rowCount = endIndex - startIndex;
    const tableRows = Array.from({ length: rowCount }, (_, i) => {
      const rankWanted = startIndex + i + 1;
      const existingRow = sortedData.find(r => r.rank === rankWanted);
      if (existingRow) {
        return existingRow;
      } else {
        return {
          rank: rankWanted,
          symbol: '',
          value: 0,
          color: 'transparent',
          pct_change: null,
          placeholder: true,
        };
      }
    });

    return (
      <table
        className="rank-tablee"
        style={{
          fontSize: '11.5px',
          fontFamily: 'Lato, Sans-serif',
          border: '1px solid #ddd',
          borderCollapse: 'collapse',
        }}
      >
        <thead style={{ backgroundColor: theme.palette.table.thead1 }}>
          <tr>
            <th style={{ textAlign: 'center', padding: '3px' }}>Rank</th>
            <th style={{ textAlign: 'center', padding: '3px' }}>Symbol</th>
            <th style={{ textAlign: 'center', padding: '3px' }}>Value</th>
            <th style={{ textAlign: 'center', padding: '3px' }}>%Change</th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: row.color,
                cursor: row.placeholder ? 'default' : 'pointer',
                fontWeight: row.symbol === selectedSym ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease',
                borderBottom: '1px solid #ddd'
              }}
              onClick={() => {
                if (row.placeholder) return;
                onRowClick(row.symbol, row.rank);
              }}
            >
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.rank}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.symbol || ''}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>
                {row.value
                  ? (row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })
                  : ''}
              </td>
              <td
                style={{
                  padding: '3px',
                  textAlign: 'center',
                  backgroundColor:
                    row.pct_change > 0
                      ? '#228B22'
                      : row.pct_change < 0
                      ? '#C8342A'
                      : '#363D4F'
                }}
              >
                {row.pct_change != null
                  ? (row.pct_change * 100).toFixed(2)
                  : ''}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    );
  };


  return (
    <div className="chart-section" style={{ fontFamily: 'Lato, Sans-serif' }}>
      <FontLoader/>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3
          style={{
            position: 'relative', // ใช้ตำแหน่งแบบเดียวกับโค้ดที่ 1
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            flex: '1'
          }}
        >
          {title}

          {/* ปุ่มแว่นขยาย */}
          <FaSearch
            onClick={() => {
              const url = `/fullscreenrealflow?market=${market}&type=${type}&title=${encodeURIComponent(title)}`;
              window.open(url, '_blank'); // Open in new tab
            }}
            // className="expand-button"
            style={{
              position: 'absolute', // ป้องกันไม่ให้ขยับตามเนื้อหา
              top: (isMobile || isTablet) ? 2 : '50%', // จัดให้อยู่กึ่งกลางแนวตั้ง
              right: (isMobile || isTablet) ? '50%' : '390px', // ชิดขวา
              transform: (isMobile || isTablet) ? 'unset'  : 'translateY(-50%)', // ปรับให้อยู่ตรงกลางพอดี
              cursor: 'pointer',
              fontSize: '20px',
              zIndex: '10', // ให้ปุ่มอยู่เหนือเนื้อหา

            }}
          />

          {/* ปุ่ม Rezoom (ถ้าต้องการเพิ่ม) */}
          <LoopIcon
            onClick={handleRezoom} // เชื่อมโยงกับฟังก์ชัน Rezoom
            style={{
              position: 'absolute', // ป้องกันไม่ให้ขยับตามเนื้อหา
              top: (isMobile || isTablet) ? 0 : '50%', // จัดให้อยู่กึ่งกลางแนวตั้ง
              right: (isMobile || isTablet) ? '40%' :  '355px', // ให้ห่างจาก FaSearch ไปทางซ้าย
              transform: (isMobile || isTablet) ? 'unset'  :  'translateY(-50%)', // ปรับให้อยู่ตรงกลางพอดี
              cursor: 'pointer',
              fontSize: '25px',
              color: '#febc69', // สามารถเปลี่ยนสีตามต้องการ
              zIndex: '10', // ให้ปุ่มอยู่เหนือเนื้อหา
            }}
          />
        </h3>

       

         

        {
          (isMobile || isTablet) && (
            <IconButton
              variant="contained"
              onClick={toggleCollapse}
            // sx={{ marginBottom: '10px' }}
            >
              {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          )
        }
        {/* จบ Icon พับเก็บ */}
      </Box>

      <div
        className="chart-table-container"
        ref={chartTableContainerRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: isMobile ? '2vw' : '3px',
          fontFamily: 'Arial, sans-serif',
          justifyContent: 'center'
        }}
      >
        {/* Chart Container */}
        <div
          ref={chartContainerRef}
          className="chart-container"
          style={{
            width: isCollapsed ? '100%' : (isTablet) ? '58%' : '50%',
            height: '265px',
            position: 'relative',
            fontFamily: 'Arial, sans-serif',
          }}
          onTouchStart={(event) => onChartTouch(event, false)} //ป้องกันการเลื่อนเปลี่ยนหน้าเมื่อ focus ที่ chart
          onTouchEnd={(event) => onChartTouch(event, true)}
        >
        </div>
        {/* Tables */}
        {(!isMobile && !isTablet) && (
          <>
            {!isCollapsed && (
              <>
                <Box className="table-container" sx={{
                  width: '33%',
                  maxWidth: '200px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  overflowY: 'auto',
                  maxHeight: '90vh',
                  fontFamily: 'Lato, Sans-serif'
                }}>
                  {renderTable(0, 10, handleRowClick, selectedSymbol)}
                </Box>
                {/* <div
              className="table-container"
              style={{
                width: '20%',
                maxWidth: '150px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                maxHeight: '80vh',
                fontFamily: 'Arial, sans-serif',
              }}
            >

            </div> */}
                {/* <div
              className="table-container"
              style={{
                width: '20%',
                maxWidth: '150px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                maxHeight: '80vh',
                fontFamily: 'Arial, sans-serif',
              }}
            >
              {renderTable(10, 20, handleRowClick, selectedSymbol)}
            </div> */}

                <Box className="table-container" sx={{
                  width: '35%',
                  maxWidth: '210px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                  overflowY: 'auto',
                  maxHeight: '80vh',
                  fontFamily: 'Lato, Sans-serif'
                 
                }}>
                  {renderTable(10, 20, handleRowClick, selectedSymbol)}
                </Box>
              </>
            )}
          </>
        )}

        {(isMobile || isTablet) && (
          <>

            {!isCollapsed && (
              <Box className="table-container" sx={{
                maxWidth: '200px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                maxHeight: isPTablet ? '265px' : isTablet ? '30vh'  : '265px',
                fontFamily: 'Arial, sans-serif',
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                  width: '5px',
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: '#888',
                  borderRadius: '4px',
                },
                '::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#555',
                },
              }}>
                {renderTable(0, 20, handleRowClick, selectedSymbol)}
              </Box>
            )}
          </>
        )}
      </div>
    </div>
  );
};
const Realflow = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  return (
    <div
      className="dashboard"
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: (isMobile || isTablet) ? '10px' : '20px',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <div style={{ maxWidth: '1800px', width: '100%' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '-1px', fontFamily: 'Arial, sans-serif' }}>
          Real flow
        </h1>
        <div
          className="sections"
          style={{
            display: 'grid',
            gridTemplateColumns: (isMobile || isTablet) ? '1fr' : '1fr 1fr',
            gap: '10px',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          {/* Desktop , laptop mode => 1200px */}
          {(!isMobile && !isTablet) && (
            <>
              {/* SET100 and NON-SET100 Money In (+) */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
              </div>

              {/* SET100 and NON-SET100 Money Out (-) */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
              </div>

              {/* MAI and WARRANT Money In (+) */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
              </div>

              {/* MAI and WARRANT Money Out (-) */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
              </div>
            </>
          )}

          {/* Lanscape Tablet Mode 
          900px -> 1200px 
          */}

          {(isTablet && !isPTablet) && (
            <Swiper
              style={{ width: '100%', maxWidth: '1200px' }}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={20}
              slidesPerView={1}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              onSwiper={(swiper) => {
                window.mySwiper = swiper;
              }}
            >
              {/* Slide 1 */}
              <SwiperSlide>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                    gap: '10px', // ระยะห่างระหว่าง Grid
                  }}
                >
                  <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                  <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
                  <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                  <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
                </div>
              </SwiperSlide>

              {/* Slide 2 */}
              <SwiperSlide>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                    gap: '10px',
                  }}
                >
                  <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                  <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
                  <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                  <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
                </div>
              </SwiperSlide>
            </Swiper>
          )}


          {/* Mobile Mode , Portrait Tablet Mode <= 900px */}
          {(isMobile || isPTablet) && (
            <>
              {/* หากเข้าสู่ Mobile mode */}
              <Swiper
                style={{ width: '100%', maxWidth: '600px' }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{
                  clickable: true, dynamicBullets: true,
                }}
                onSwiper={(swiper) => {
                  window.mySwiper = swiper;
                }}
              >
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                    <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
                    <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="MAI Up (+)" market="MAI" type="in" />
                    <ChartSectionWithMultipleLines title="MAI Down (-)" market="MAI" type="out" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <ChartSectionWithMultipleLines title="WARRANT Up (+)" market="WARRANT" type="in" />
                    <ChartSectionWithMultipleLines title="WARRANT Down (-)" market="WARRANT" type="out" />
                  </div>
                </SwiperSlide>

              </Swiper>

              {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <ChartSectionWithMultipleLines title="SET100 Up (+)" market="SET100" type="in" />
                <ChartSectionWithMultipleLines title="SET100 Down (-)" market="SET100" type="out" />
              </div> */}
              {/* <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <ChartSectionWithMultipleLines title="NON-SET100 Up (+)" market="NON-SET100" type="in" />
        <ChartSectionWithMultipleLines title="NON-SET100 Down (-)" market="NON-SET100" type="out" />
    </div> */}
            </>
          )
          }
        </div>
      </div>
    </div>
  );

};

export default Realflow;
