import React, { useState, useEffect, forwardRef, createRef, useContext, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import {
  Box, Typography, useTheme, Button, Grid, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Paper,
  Checkbox,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControlLabel, FormGroup,
  Alert
} from "@mui/material";
import { Visibility, VisibilityOff, AdminPanelSettingsOutlinedIcon, LockOpenOutlinedIcon, SecurityOutlinedIcon } from '@mui/icons-material';

import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import Header from "../../components/Header";
import { __fetching_data__ } from '../../components/api.js';
import DataGridComponent from "../../components/DataGrid";
import DialogComponent from "../../components/Dialog";

const initialValueRole = [
  { _id: 1, key: 'chatBot8Chanel', name: 'ChatBot 8Chanel', read: false, create: false, update: false, delete: false },
  { _id: 2, key: 'chatBot9Chanel', name: 'ChatBot 9Chanel', read: false, create: false, update: false, delete: false },
  { _id: 3, key: 'petroleumChart', name: 'PetroleumChart', read: false, create: false, update: false, delete: false },
  { _id: 4, key: 'rubberThaiChart', name: 'Rubber Thai Chart', read: false, create: false, update: false, delete: false },
  { _id: 5, key: 'realTimeChart', name: 'Realtime Chart', read: false, create: false, update: false, delete: false },
  { _id: 6, key: 'goldChart', name: 'Gold', read: false, create: false, update: false, delete: false },
  { _id: 7, key: 'bidaskDejavu', name: 'BidAsk', read: false, create: false, update: false, delete: false },
  { _id: 8, key: 'tickmatchDejavu', name: 'TickMatch', read: false, create: false, update: false, delete: false },

  { _id: 105, key: 'manageUser', name: 'Manage Users', read: false, create: false, update: false, delete: false },
  { _id: 106, key: 'manageRole', name: 'Manage Roles', read: false, create: false, update: false, delete: false },

];

const initialValueCreate = {
  _id: null,
  name: null,
  role_id: null,
  package_price: {
    monthly: 0,
    annually: 0
  }
}

const MyDialog = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const csrfToken = useSelector((state) => state.csrfToken);


  const [roles, setRoles] = useState(null);
  const getRoles = async () => {
    const roleResponse = await __fetching_data__({ url: `${window.config.base_api_url}/roles`, method: "GET", headers: { "xsrf-token": csrfToken } });
    setRoles(roleResponse.data)
  }
  useEffect(() => {
    getRoles();
  }, []);

  const columns = [
    // {
    //   field: "role_id",
    //   headerName: "ID",
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          {params.value}
        </Box>
      ),
    },
    {
      field: "package_price",
      headerName: "Package Price",
      flex: 1,
      renderCell: (params) => (
        <Box component="ul" sx={{ padding: 0, margin: 0, listStyle: 'none' }}>
          {params.value && Object.entries(params.value).map(([key, value]) => (
            <Box component="li" key={key}>
              {key}={' '} {value.toLocaleString()}
            </Box>
          ))}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      // width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          // onClick={() => handleDelete(params.id)}
          onClick={(event) => {
            event.stopPropagation();
            handleSetPageType("delete");
            setSelectedRow((prevData) => ({
              ...prevData,
              '_id': params.id
            }));
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const [selectedRow, setSelectedRow] = useState(initialValueCreate);
  const [returnedData, setReturnedData] = useState([]);

  const handleSaveClick = async () => {
    const value = {
      role: selectedRow,
      role_detail: returnedData
    }

    const { url, method } = {
      create: { url: `${window.config.base_api_url}/roles`, method: "POST" },
      edit: { url: `${window.config.base_api_url}/roles`, method: "PUT" },
      delete: { url: `${window.config.base_api_url}/roles`, method: "DELETE" }
    }[pageType];
    const response = await __fetching_data__({ url, method, headers: { "xsrf-token": csrfToken }, body: JSON.stringify(value) });

    if (response && response.status) {
      getRoles();
      handleCloseDialog();
    }

  };


  const handleDataReturned = (data) => {
    setReturnedData(data);
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    const setNestedState = (state, keys, value) => {
      if (keys.length === 1) {
        state[keys[0]] = value;
        return state;
      }
      state[keys[0]] = setNestedState(state[keys[0]] || {}, keys.slice(1), value);
      return state;
    };

    const keys = name.split('.');
    setSelectedRow((prevData) => ({
      ...prevData,
      ...setNestedState({ ...prevData }, keys, value)
    }));
  };

  const [pageType, setPageType] = useState("");
  const isCreate = pageType === "create";
  const isEdit = pageType === "edit";
  const isDelete = pageType === "delete";

  const handleEdit = (rowData) => {
    setSelectedRow(rowData);
    // setOpenFormEdit(true);
    handleSetPageType("edit")
  };

  const handleSetPageType = (type) => {
    setPageType(type);
  }

  const handleCloseDialog = () => {
    handleSetPageType("")
    setSelectedRow(initialValueCreate)
  }

  const handleOpenDialog = (type, data = {}) => {

  }

  const getDialogContent = () => {

  }

  const getDialogAction = () => {

  }

  return (
    <Box m="20px">
      <Grid container justifyContent="space-between" alignItems="center">
        <Header title="" subtitle="" />
        <Box>
          <Button type="submit" variant="contained"
            // onClick={handleCreateOpen}
            onClick={() => { handleSetPageType("create") }}
            sx={{
              // backgroundColor: colors.blueAccent[400]
            }} >
            Create New Role
          </Button>
        </Box>
      </Grid>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            color: "black",
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "transparent",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#686868",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: colors.blueAccent[800],
            color: "black",
          },
        }}
      >

        {roles && (
          <DataGridComponent rows={roles} columns={columns} getRowId={(row) => row._id}
            components={{
              Toolbar: GridToolbarContainer,
              ToolbarExport: GridToolbarExport,
            }}
            disableRowSelectionOnClick
            onRowClick={(rowData) => handleEdit(rowData.row)}
          />
        )}

        <DialogComponent open={isCreate} onClose={handleCloseDialog} title="สร้างสิทธิ์"
          content={
            <Box sx={{ flexGrow: 1 }}>
              <Grid container justifyContent="center" spacing={2}>
                <Box
                  m="0 0 0 0"
                  height="100%"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      // backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                    '& .MuiDataGrid-cell': {
                      borderRight: '1px solid #e0e0e0', // Border for each cell
                    },
                    '& .MuiDataGrid-columnsContainer': {
                      borderBottom: '1px solid #e0e0e0', // Border at the bottom of header row
                    },
                    '& .MuiDataGrid-colCellTitle': {
                      textAlign: 'center', // Center align text in header cells
                    },
                    "& .MuiButtonBase-root.MuiCheckbox-root:hover": {
                      color: `black !important`, // เปลี่ยนสีตอน hover
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: `${colors.tonal[20]} !important`,
                      // color: "black",

                    },


                  }}
                  width="100%"
                  padding="0 10px 0 10px"
                >
                  <Grid container justifyContent="" spacing={2} marginTop={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Name"
                        name="name"
                        value={selectedRow.name || ""}
                        onChange={handleChangeData}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="ราคารายเดือน"
                        name="package_price.monthly"
                        value={selectedRow.package_price?.monthly ? selectedRow.package_price.monthly.toLocaleString() : ''}
                        onChange={handleChangeData}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="off"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="ราคารายปี"
                        name="package_price.annually"
                        value={selectedRow.package_price?.annually ? selectedRow.package_price.annually.toLocaleString() : ""}
                        onChange={handleChangeData}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="off"
                        type="text"
                      />
                    </Grid>

                  </Grid>
                  <TableRole roles={roles} onDataReturned={handleDataReturned} />
                </Box>
              </Grid>
            </Box>
          }
          action={
            <>
              <Button
                onClick={handleCloseDialog}
                sx={{
                  backgroundColor: colors.redAccent[500]
                }}
              >Cancel</Button>
              <Button
                onClick={handleSaveClick}
                sx={{
                  backgroundColor: colors.blueAccent[500]
                }}>Save
              </Button>
            </>
          }
        />

        <ProptoDialog open={isEdit} onClose={handleCloseDialog} title="แก้ไขสิทธิ์"
          content={
            <Box sx={{ flexGrow: 1 }}>
              <Grid container justifyContent="center" spacing={2}>
                <Box
                  m="0 0 0 0"
                  height="100%"
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "none",
                    },
                    "& .name-column--cell": {
                      color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: colors.blueAccent[700],
                      borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      // backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                      color: `${colors.greenAccent[200]} !important`,
                    },
                    '& .MuiDataGrid-cell': {
                      borderRight: '1px solid #e0e0e0', // Border for each cell
                    },
                    '& .MuiDataGrid-columnsContainer': {
                      borderBottom: '1px solid #e0e0e0', // Border at the bottom of header row
                    },
                    '& .MuiDataGrid-colCellTitle': {
                      textAlign: 'center', // Center align text in header cells
                    },
                    "& .MuiDataGrid-row:hover": {
                      backgroundColor: `${colors.tonal[20]} !important`,
                      // color: "black",
                    },

                  }}
                  width="100%"
                  padding="0 10px 0 10px"
                >
                  <Grid container justifyContent="" spacing={2} marginTop={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Name"
                        name="name"
                        value={selectedRow.name || ""}
                        onChange={handleChangeData}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="ราคารายเดือน"
                        name="package_price.monthly"
                        value={selectedRow.package_price?.monthly ? selectedRow.package_price.monthly.toLocaleString() : ''}
                        onChange={handleChangeData}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="off"
                        type="text"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="ราคารายปี"
                        name="package_price.annually"
                        value={selectedRow.package_price?.annually ? selectedRow.package_price.annually.toLocaleString() : ""}
                        onChange={handleChangeData}
                        fullWidth
                        margin="normal"
                        size="small"
                        autoComplete="off"
                        type="text"
                      />
                    </Grid>

                  </Grid>
                  <TableRole rowData={selectedRow} roles={roles} onDataReturned={handleDataReturned} />
                </Box>
              </Grid>
            </Box>
          }
          action={
            <>
              <Button
                onClick={handleCloseDialog}
                sx={{
                  backgroundColor: colors.redAccent[500],

                }}
              >Cancel</Button>
              <Button
                onClick={handleSaveClick}
                sx={{
                  backgroundColor: colors.blueAccent[500]
                }}>Save
              </Button>
            </>
          }
        />

        <ProptoDialog open={isDelete} onClose={handleCloseDialog} title="" maxWidth="xs"
          content={
            <Box display="flex" justifyContent="center" >
              <Typography variant="h3" gutterBottom color="error" sx={{ fontWeight: 700 }}>
                ลบสิทธิ์
              </Typography>
            </Box>
          }
          action={
            <>
              <Button
                onClick={handleCloseDialog}
                sx={{
                  backgroundColor: colors.redAccent[500],
                  // color: colors.primary[100]
                }}
              >Cancel</Button>
              <Button
                onClick={handleSaveClick}
                sx={{
                  backgroundColor: colors.blueAccent[500]
                }}>Confirm
              </Button>
            </>
          }
        />

      </Box>



    </Box>
  );
};


const ProptoDialog = ({ open, onClose, title, content, action, maxWidth = "lg" }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{action}</DialogActions>
    </Dialog>
  )
}



const TableRole = ({ onClose, rowData, roles, onSave, onDataReturned }) => {

  const columns = [
    { field: "name", headerName: "Name", flex: 1, headerAlign: 'center', },

    {
      field: "read",
      headerName: "Read",
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(e) => handleCheckboxChange(e, params.row._id, 'read')}
        />),
    },
    {
      field: "create",
      headerName: "Create",
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(e) => handleCheckboxChange(e, params.row._id, 'create')}
        />),
    },
    {
      field: "update",
      headerName: "Update",
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(e) => handleCheckboxChange(e, params.row._id, 'update')}
        />),
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(e) => handleCheckboxChange(e, params.row._id, 'delete')}
        />),
    },


  ];
  const [rows, setRow] = useState(initialValueRole);
  const [data, setData] = useState(rows);

  useEffect(() => {
    if (!rowData || !rowData.role_id) {
      setData(initialValueRole);
      onDataReturned(initialValueRole);
      return;
    }

    const foundObject = roles.find(item => item.role_id === rowData.role_id);
    const updateRolePrototype = (permissions) => {
      return initialValueRole.map(role => {
        const permission = permissions && permissions[role.key] ? permissions[role.key] : { read: false, create: false, update: false, delete: false };
        return {
          ...role,
          read: permission.read || false,
          create: permission.create || false,
          update: permission.update || false,
          delete: permission.delete || false
        };
      });
    };
    if (foundObject) {
      const updatedRoles = updateRolePrototype(foundObject.permissions);
      setData(updatedRoles)
    }
  }, []);

  const handleCheckboxChange = (event, id, field) => {
    const newData = data.map((row) => {
      if (row._id === id) {
        return { ...row, [field]: event.target.checked };
      }
      return row;
    });
    setData(newData);
    onDataReturned(newData);
  };

  return (
    <div style={{ height: 450, width: '100%' }}>
      <DataGridComponent
        rows={data}
        columns={columns}
        getRowId={(row) => row._id}
        components={{
          Toolbar: GridToolbarContainer,
          ToolbarExport: GridToolbarExport,
        }}
        disableRowSelectionOnClick
        disableColumnMenu
        disableSelectionOnClick
        hideFooterPagination
        hideFooterSelectedRowCount
      />
    </div>
  );
};

export default MyDialog;
