import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from 'react-redux'; // for xsrf-token if needed
import { Box, Autocomplete, TextField, useTheme, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress, Chip, Divider } from '@mui/material';
import { tokens } from "../../theme";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

// ============ Utility Functions ============
function adjustToLocalTime(timestamp) {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
}

function safeConvertToTimestamp(dateStr, timeStr = '00:00:00') {
  const dateTimeStr = `${dateStr}T${timeStr}`;
  const d = new Date(dateTimeStr);
  if (isNaN(d.getTime())) {
    console.warn("Skipping invalid date/time:", dateStr, timeStr);
    return null;
  }
  return Math.floor(d.getTime() / 1000);
}

// ============ API Fetchers (Right Chart - Money In/Out Data) ============

async function fetchDailyData(symbol, startDate, endDate, csrfToken) {
  const url = `${window.config.base_api_url}/api/money/stock/daily?symbol=${encodeURIComponent(symbol)}&start_date=${startDate}&end_date=${endDate}`;
  const resp = await fetch(url, {
    headers: { 'Content-Type': 'application/json', 'xsrf-token': csrfToken },
  });
  if (!resp.ok) {
    throw new Error(`Failed to fetch daily for ${symbol}`);
  }
  const json = await resp.json();
  const dailyDataMap = new Map();
  for (const item of json) {
    const dateStr = item.Date;
    const ts = safeConvertToTimestamp(item.Date, item.Time);
    if (ts === null) continue;
    if (!dailyDataMap.has(dateStr) || dailyDataMap.get(dateStr).time < ts) {
      dailyDataMap.set(dateStr, {
        time: adjustToLocalTime(ts),
        value: item["accum"] ?? 0,
      });
    }
  }
  return Array.from(dailyDataMap.values());
}

async function fetchHistoryData(symbol, startDate, endDate, csrfToken) {
  const url = `${window.config.base_api_url}/api/money/stock/history?symbol=${encodeURIComponent(symbol)}&start_date=${startDate}&end_date=${endDate}`;
  const resp = await fetch(url, {
    headers: { 'Content-Type': 'application/json', 'xsrf-token': csrfToken },
  });
  if (!resp.ok) {
    throw new Error(`Failed to fetch history for ${symbol}`);
  }
  const json = await resp.json();
  const parsed = [];
  for (const item of json) {
    const ts = safeConvertToTimestamp(item.Date, item.Time);
    if (ts === null) continue;
    parsed.push({
      time: adjustToLocalTime(ts),
      value: item["accum"] ?? 0,
    });
  }
  return parsed;
}

async function fetchRealtimeData(symbol, csrfToken) {
  const url = `${window.config.base_api_url}/api/money/stock/realtime?symbol=${encodeURIComponent(symbol)}`;
  const resp = await fetch(url, {
    headers: { 'Content-Type': 'application/json', 'xsrf-token': csrfToken },
  });
  if (!resp.ok) {
    throw new Error(`Failed to fetch realtime for ${symbol}`);
  }
  const json = await resp.json();
  const todayStr = new Date().toISOString().split('T')[0];
  const parsed = [];
  for (const item of json) {
    const ts = safeConvertToTimestamp(todayStr, item.Time);
    if (ts === null) continue;
    parsed.push({
      time: adjustToLocalTime(ts),
      value: item["accum"] ?? 0,
    });
  }
  return parsed;
}

// ============ Left Chart (Price) Fetchers ============

async function fetchDayPriceData(symbol, startDate, endDate, csrfToken) {
  const url = `${window.config.base_api_url}/api/price/day?symbol=${encodeURIComponent(symbol)}&start_date=${startDate}&end_date=${endDate}`;
  const resp = await fetch(url, {
    headers: { 'Content-Type': 'application/json', 'xsrf-token': csrfToken },
  });
  if (!resp.ok) {
    throw new Error(`Failed to fetch day price data for ${symbol} from Z drive`);
  }
  const json = await resp.json();
  return json.map(point => ({
    ...point,
    time: adjustToLocalTime(point.time)
  }));
}

async function fetchIntradayPriceData(symbol, startDate, endDate, csrfToken) {
  const url = `${window.config.base_api_url}/api/price/intraday?symbol=${encodeURIComponent(symbol)}&start_date=${startDate}&end_date=${endDate}`;
  const resp = await fetch(url, {
    headers: { 'Content-Type': 'application/json', 'xsrf-token': csrfToken },
  });
  const json = await resp.json();
  return json.map(point => ({
    ...point,
    time: adjustToLocalTime(point.time)
  }));
}

// Merge & sort helper
function mergeAndSortData(...dataArrays) {
  const combined = [].concat(...dataArrays);
  const uniqueSortedData = Array.from(
    new Map(combined.map((item) => [item.time, item])).values()
  ).sort((a, b) => a.time - b.time);
  return uniqueSortedData;
}

function getColorForSymbol(symbol) {
  let hash = 0;
  for (let i = 0; i < symbol.length; i++) {
    hash = symbol.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colors = [
    '#2196f3', '#4caf50', '#f44336', '#9c27b0',
    '#ff9800', '#009688', '#e91e63', '#ffc107'
  ];
  return colors[Math.abs(hash) % colors.length];
}

// ============ Forward Filling Helpers ============
function getUnionTimestampsFromMaps(...maps) {
  const timestampsSet = new Set();
  maps.forEach(map => {
    map.forEach(dataArr => {
      dataArr.forEach(point => timestampsSet.add(point.time));
    });
  });
  return Array.from(timestampsSet).sort((a, b) => a - b);
}

function forwardFillSeries(data, unionTimestamps) {
  let result = [];
  let i = 0;
  let lastValue = null;
  for (const t of unionTimestamps) {
    while (i < data.length && data[i].time <= t) {
      lastValue = data[i].value;
      i++;
    }
    if (lastValue !== null) {
      result.push({ time: t, value: lastValue });
    }
  }
  return result;
}


// ============ Main Component ============
const MoneyInOutStock = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const csrfToken = useSelector((state) => state.csrfToken);
  const leftChartContainerRef = useRef(null);
  const rightChartContainerRef = useRef(null);
  const leftChartRef = useRef(null);
  const rightChartRef = useRef(null);
  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();


  // Left chart data (price data)
  const [leftBelow50Map, setLeftBelow50Map] = useState(new Map()); // price ≤ 50
  const [rightAbove50Map, setRightAbove50Map] = useState(new Map()); // price > 50

  // Right chart data (money in/out)
  const [moneyInOutMap, setMoneyInOutMap] = useState(new Map());

  // Series refs
  const leftBelow50SeriesRef = useRef(new Map());
  const rightAbove50SeriesRef = useRef(new Map());
  const moneyInOutSeriesRef = useRef(new Map());

  // List of symbols
  const [symbols, setSymbols] = useState([]);
  // Chart mode: "intraday" or "day"
  const [chartMode, setChartMode] = useState('intraday');

  // Input fields
  const [inputSymbol, setInputSymbol] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });

  // 1) Initialize Left Chart (Price Chart)
  useEffect(() => {
    if (!leftChartContainerRef.current) return;
    const chart = createChart(leftChartContainerRef.current, {
      layout: {
        background: { color: theme.palette.table.odd },
        textColor: theme.palette.text.secondary,
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
      },
      height: leftChartContainerRef.current.clientHeight || 400,
      width: leftChartContainerRef.current.clientWidth || 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
      localization: {
        priceFormatter: (price) =>
          price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      timeScale: {
        timeVisible: true,
        rightOffset: 20,
        minBarSpacing: 0.00001,
      },
      leftPriceScale: { visible: true },
      rightPriceScale: { visible: true },
    });
    leftChartRef.current = chart;
    const resizeObserver = new ResizeObserver(() => {
      if (leftChartContainerRef.current && leftChartRef.current) {
        leftChartRef.current.applyOptions({
          width: leftChartContainerRef.current.clientWidth,
          height: leftChartContainerRef.current.clientHeight,
        });
      }
    });
    resizeObserver.observe(leftChartContainerRef.current);
    return () => {
      resizeObserver.disconnect();
      if (leftChartRef.current) {
        leftChartRef.current.remove();
      }
    };
  }, []);

  // 2) Initialize Right Chart (Money In/Out Chart)
  useEffect(() => {
    if (!rightChartContainerRef.current) return;
    const chart = createChart(rightChartContainerRef.current, {
      layout: {
        background: { color: theme.palette.table.odd },
        textColor: theme.palette.text.secondary,
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
      },
      height: rightChartContainerRef.current.clientHeight || 400,
      width: rightChartContainerRef.current.clientWidth || 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
      localization: {
        priceFormatter: (price) =>
          price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      timeScale: {
        timeVisible: true,
        rightOffset: 650,
        minBarSpacing: 0.00001,
      },
      rightPriceScale: { visible: true },
    });
    rightChartRef.current = chart;
    const resizeObserver = new ResizeObserver(() => {
      if (rightChartContainerRef.current && rightChartRef.current) {
        rightChartRef.current.applyOptions({
          width: rightChartContainerRef.current.clientWidth,
          height: rightChartContainerRef.current.clientHeight,
        });
      }
    });
    resizeObserver.observe(rightChartContainerRef.current);
    return () => {
      resizeObserver.disconnect();
      if (rightChartRef.current) {
        rightChartRef.current.remove();
      }
    };
  }, []);

  // 3) Render Left Chart (Price Data)
  useEffect(() => {
    if (!leftChartRef.current) return;
    const chart = leftChartRef.current;
    // Remove old series
    leftBelow50SeriesRef.current.forEach((series) => chart.removeSeries(series));
    leftBelow50SeriesRef.current.clear();
    rightAbove50SeriesRef.current.forEach((series) => chart.removeSeries(series));
    rightAbove50SeriesRef.current.clear();

    // Compute union timestamps for both price series maps
    const unionTimestamps = getUnionTimestampsFromMaps(leftBelow50Map, rightAbove50Map);
    leftBelow50Map.forEach((dataArr, sym) => {
      const color = getColorForSymbol(sym);
      const series = chart.addLineSeries({
        priceScaleId: 'left',
        color,
        lineWidth: 2,
        title: `${sym} Price`,
      });
      const sortedData = dataArr.slice().sort((a, b) => a.time - b.time);
      const filledData = forwardFillSeries(sortedData, unionTimestamps);
      series.setData(filledData);
      leftBelow50SeriesRef.current.set(sym, series);
    });
    rightAbove50Map.forEach((dataArr, sym) => {
      const color = getColorForSymbol(sym);
      const series = chart.addLineSeries({
        priceScaleId: 'right',
        color,
        lineWidth: 2,
        title: `${sym} Price`,
      });
      const sortedData = dataArr.slice().sort((a, b) => a.time - b.time);
      const filledData = forwardFillSeries(sortedData, unionTimestamps);
      series.setData(filledData);
      rightAbove50SeriesRef.current.set(sym, series);
    });
    if (leftBelow50Map.size + rightAbove50Map.size > 0) {
      chart.timeScale().fitContent();
    }
  }, [leftBelow50Map, rightAbove50Map]);

  // 4) Render Right Chart (Money In/Out Data)
  useEffect(() => {
    if (!rightChartRef.current) return;
    const chart = rightChartRef.current;
    moneyInOutSeriesRef.current.forEach((s) => chart.removeSeries(s));
    moneyInOutSeriesRef.current.clear();
    // Compute union timestamps for money data
    const unionTimestampsMoney = getUnionTimestampsFromMaps(moneyInOutMap);
    moneyInOutMap.forEach((dataArr, sym) => {
      const color = getColorForSymbol(sym);
      const series = chart.addLineSeries({
        color,
        lineWidth: 2,
        title: sym,
      });
      const sortedData = dataArr.slice().sort((a, b) => a.time - b.time);
      const filledData = forwardFillSeries(sortedData, unionTimestampsMoney);
      series.setData(filledData);
      moneyInOutSeriesRef.current.set(sym, series);
    });
    if (moneyInOutMap.size > 0) {
      chart.timeScale().fitContent();
    }
  }, [moneyInOutMap]);

  // 5) Synchronize Crosshair between Left and Right Charts
  useEffect(() => {
    if (!leftChartRef.current || !rightChartRef.current) return;
    let isSyncing = false;
    const leftTimeScale = leftChartRef.current.timeScale();
    const rightTimeScale = rightChartRef.current.timeScale();

    leftChartRef.current.subscribeCrosshairMove((param) => {
      if (isSyncing) return;
      isSyncing = true;
      if (param.time != null) {
        const currentRange = rightTimeScale.getVisibleTimeRange();
        if (currentRange && currentRange.from != null && currentRange.to != null) {
          const center = (currentRange.from + currentRange.to) / 2;
          const shift = param.time - center;
          rightTimeScale.setVisibleLogicalRange({
            from: currentRange.from + shift,
            to: currentRange.to + shift,
          });
        }
      }
      isSyncing = false;
    });

    rightChartRef.current.subscribeCrosshairMove((param) => {
      if (isSyncing) return;
      isSyncing = true;
      if (param.time != null) {
        const currentRange = leftTimeScale.getVisibleTimeRange();
        if (currentRange && currentRange.from != null && currentRange.to != null) {
          const center = (currentRange.from + currentRange.to) / 2;
          const shift = param.time - center;
          leftTimeScale.setVisibleLogicalRange({
            from: currentRange.from + shift,
            to: currentRange.to + shift,
          });
        }
      }
      isSyncing = false;
    });
  }, []);

  // 6) Poll real-time for Money In/Out in intraday
  useEffect(() => {
    if (chartMode !== 'intraday') return;
    if (symbols.length === 0) return;
    const intervalID = setInterval(async () => {
      for (const sym of symbols) {
        let realData = [];
        try {
          realData = await fetchRealtimeData(sym, csrfToken);
        } catch (err) {
          console.warn("Realtime poll error for", sym, err);
          continue;
        }
        if (realData.length === 0) continue;
        setMoneyInOutMap((prev) => {
          const oldData = prev.get(sym) || [];
          const merged = mergeAndSortData(oldData, realData);
          const copy = new Map(prev);
          copy.set(sym, merged);
          return copy;
        });
      }
    }, 10000);
    return () => clearInterval(intervalID);
  }, [symbols, chartMode, csrfToken]);

  // 7) Poll realtime Money In/Out data (for intraday mode)
  useEffect(() => {
    if (chartMode !== 'intraday') return;
    if (symbols.length === 0) return;
    const intervalID = setInterval(async () => {
      for (const sym of symbols) {
        let realData = [];
        try {
          realData = await fetchRealtimeData(sym, csrfToken);
        } catch (err) {
          console.warn("Realtime poll error for", sym, err);
          continue;
        }
        if (realData.length === 0) continue;
        setMoneyInOutMap((prev) => {
          const oldData = prev.get(sym) || [];
          const merged = mergeAndSortData(oldData, realData);
          const copy = new Map(prev);
          copy.set(sym, merged);
          return copy;
        });
      }
    }, 10000);
    return () => clearInterval(intervalID);
  }, [symbols, chartMode, csrfToken]);

  // 8) Add Symbol (fetch price and money data)
  const addSymbolLine = async () => {
    if (!inputSymbol || !startDate) return;
    const sym = inputSymbol.toUpperCase();

    // RIGHT CHART (Money In/Out)
    if (chartMode === 'day') {
      try {
        const dailyData = await fetchDailyData(sym, startDate, endDate, csrfToken);
        setMoneyInOutMap((prev) => {
          const copy = new Map(prev);
          copy.set(sym, dailyData);
          return copy;
        });
      } catch (err) {
        console.error('Daily fetch error for', sym, err);
      }
    } else {
      let histData = [];
      let realData = [];
      try {
        histData = await fetchHistoryData(sym, startDate, endDate, csrfToken);
      } catch (err) {
        console.error(`History fetch error for ${sym}:`, err);
      }
      try {
        realData = await fetchRealtimeData(sym, csrfToken);
      } catch (err) {
        console.warn(`Realtime fetch error for ${sym}, skip merging.`, err);
      }
      const finalMoneyInOut = mergeAndSortData(histData, realData);
      setMoneyInOutMap((prev) => {
        const copy = new Map(prev);
        copy.set(sym, finalMoneyInOut);
        return copy;
      });
    }

    // LEFT CHART (Price)
    try {
      let priceData = [];
      if (chartMode === 'day') {
        priceData = await fetchDayPriceData(sym, startDate, endDate, csrfToken);
      } else {
        priceData = await fetchIntradayPriceData(sym, startDate, endDate, csrfToken);
      }
      const maxVal = priceData.reduce((max, d) => (d.value > max ? d.value : max), -Infinity);
      if (maxVal > 50) {
        setRightAbove50Map((prev) => {
          const copy = new Map(prev);
          copy.set(sym, priceData);
          return copy;
        });
      } else {
        setLeftBelow50Map((prev) => {
          const copy = new Map(prev);
          copy.set(sym, priceData);
          return copy;
        });
      }
    } catch (apiErr) {
      console.error(`Failed to fetch price data for ${sym}:`, apiErr);
    }

    setSymbols((prev) => {
      if (!prev.includes(sym)) return [...prev, sym];
      return prev;
    });
    setInputSymbol('');
  };

  // 9) Remove Symbol
  const removeSymbol = (sym) => {
    setLeftBelow50Map((prev) => {
      const copy = new Map(prev);
      copy.delete(sym);
      return copy;
    });
    setRightAbove50Map((prev) => {
      const copy = new Map(prev);
      copy.delete(sym);
      return copy;
    });
    setMoneyInOutMap((prev) => {
      const copy = new Map(prev);
      copy.delete(sym);
      return copy;
    });
    setSymbols((prev) => prev.filter((s) => s !== sym));
  };

  // 10) Switch Chart Mode (intraday vs day)
  const handleModeChange = (mode) => {
    setChartMode(mode);
    symbols.forEach(async (sym) => {
      let priceData = [];
      try {
        if (mode === 'day') {
          priceData = await fetchDayPriceData(sym, startDate, endDate, csrfToken);
        } else {
          priceData = await fetchIntradayPriceData(sym, startDate, endDate, csrfToken);
        }
      } catch (err) {
        console.error(`Price fetch error for ${sym}:`, err);
      }
      const maxVal = priceData.reduce((max, d) => (d.value > max ? d.value : max), -Infinity);
      // Remove current price data
      setLeftBelow50Map((prev) => {
        const copy = new Map(prev);
        copy.delete(sym);
        return copy;
      });
      setRightAbove50Map((prev) => {
        const copy = new Map(prev);
        copy.delete(sym);
        return copy;
      });
      if (maxVal > 50) {
        setRightAbove50Map((prev) => {
          const copy = new Map(prev);
          copy.set(sym, priceData);
          return copy;
        });
      } else {
        setLeftBelow50Map((prev) => {
          const copy = new Map(prev);
          copy.set(sym, priceData);
          return copy;
        });
      }
      let moneyData = [];
      if (mode === 'day') {
        try {
          moneyData = await fetchDailyData(sym, startDate, endDate, csrfToken);
        } catch (err) {
          console.error(`Daily fetch error for ${sym}:`, err);
        }
      } else {
        let histData = [];
        let realData = [];
        try {
          histData = await fetchHistoryData(sym, startDate, endDate, csrfToken);
        } catch { }
        try {
          realData = await fetchRealtimeData(sym, csrfToken);
        } catch { }
        moneyData = mergeAndSortData(histData, realData);
      }
      setMoneyInOutMap((prev) => {
        const copy = new Map(prev);
        copy.delete(sym);
        copy.set(sym, moneyData);
        return copy;
      });
    });
    if (rightChartRef.current) {
      rightChartRef.current.applyOptions({
        timeScale: { rightOffset: mode === 'day' ? 20 : 650 },
      });
    }
  };

  const isAddDisabled = !inputSymbol || !startDate;
  console.log('start Date', startDate);
  console.log('end Date', endDate);
  console.log(symbols)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        fontFamily: 'Arial, sans-serif',
        padding: '10px 10px',
      }}
    >
      <h1 style={{ marginBottom: '10px' }}>Moneyinout Stock</h1>
      {(!isMobile && !isTablet) && (
        <>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', flexDirection: 'row', gap: 2,
            mb: 1
            //  border: '1px solid #fff'
          }}>
            <Box sx={{ display: 'flex', gap: '20px', width: 600, justifyContent: symbols.length > 0 ? 'flex-end' : 'center' }}>
              <div>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1, justifyContent: 'center' }}>
                  <TextField
                    label='Symbol'
                    variant="outlined"
                    placeholder="Enter symbol (e.g. KBANK)"
                    value={inputSymbol}
                    onChange={(e) => setInputSymbol(e.target.value)}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: 200 }}
                  />

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <Box sx={{ marginBottom: 1, gap: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}> */}
                    <DatePicker
                      label="Start Date"
                      value={startDate ? dayjs(startDate) : null}
                      onChange={(newValue) => setStartDate(newValue ? newValue.format("YYYY-MM-DD") : null)}
                      format="DD/MM/YYYY"
                      sx={{ width: 140 }}
                      disabled={symbols.length > 0}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{
                        textField: {
                          size: "small",
                          InputLabelProps: { shrink: true }, // ทำให้ label ลอยอยู่ตลอด
                        },
                      }}
                    />

                    <DatePicker
                      label="End Date"
                      value={endDate ? dayjs(endDate) : null}
                      onChange={(newValue) => setEndDate(newValue ? newValue.format("YYYY-MM-DD") : null)}
                      format="DD/MM/YYYY"
                      sx={{ width: 140 }}
                      disabled={symbols.length > 0}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                    {/* </Box> */}
                  </LocalizationProvider>


                  <Button
                    variant="contained"
                    onClick={addSymbolLine}
                    disabled={isAddDisabled}
                    sx={{ textTransform: "none", backgroundColor: !isAddDisabled ? 'green' : 'unset' }}
                  >
                    Add Symbol
                  </Button>
                </Box>



                <Box sx={{ marginBottom: 1, display: 'flex', justifyContent: 'center' }}>
                  <label>
                    <input
                      type="radio"
                      name="chartMode"
                      value="intraday"
                      checked={chartMode === 'intraday'}
                      onChange={() => handleModeChange('intraday')}
                    />
                    Intraday
                  </label>
                  <label style={{ marginLeft: '10px' }}>
                    <input
                      type="radio"
                      name="chartMode"
                      value="day"
                      checked={chartMode === 'day'}
                      onChange={() => handleModeChange('day')}
                    />
                    Day
                  </label>
                </Box>

              </div>
            </Box>
            {symbols.length > 0 &&
              (<>
                <Divider orientation="vertical" color='white' />
                <Box sx={{ width: 600 }}>
                  <Box sx={{ display: "flex", gap: 1, mb: 1, flexWrap: "wrap", width: '100%' }}>
                    {symbols.map((sym) => (
                      <Chip
                        key={sym}
                        label={sym}
                        onDelete={() => removeSymbol(sym)}
                        sx={{ backgroundColor: colors.tonal[30] }}
                      />
                    ))}
                  </Box>
                </Box>
              </>)}
          </Box>
        </>
      )}
      {(isTablet || isPTablet) && (
        <>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', flexDirection: isPTablet ? 'column' : 'row', gap: 2,
            mb: 1,
            width: '100%'
            //  border: '1px solid #fff'
          }}>
            <Box sx={{ display: 'flex', width: isPTablet ? '100%' : symbols.length > 0 ? '50%' : '100%', gap: '20px', justifyContent: isPTablet ? 'center' : symbols.length > 0 ? 'flex-end' : 'center' }}>
              <div>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1, justifyContent: 'center' }}>
                  <TextField
                    label='Symbol'
                    variant="outlined"
                    placeholder="Enter symbol (e.g. KBANK)"
                    value={inputSymbol}
                    onChange={(e) => setInputSymbol(e.target.value)}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: 200 }}
                  />
                  <Button
                    variant="contained"
                    onClick={addSymbolLine}
                    disabled={isAddDisabled}
                    sx={{ textTransform: "none", backgroundColor: !isAddDisabled ? 'green' : 'unset' }}
                  >
                    Add Symbol
                  </Button>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ marginBottom: 1, gap: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <DatePicker
                      label="Start Date"
                      value={startDate ? dayjs(startDate) : null}
                      onChange={(newValue) => setStartDate(newValue ? newValue.format("YYYY-MM-DD") : null)}
                      format="DD/MM/YYYY"
                      sx={{ width: 140 }}
                      disabled={symbols.length > 0}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{
                        textField: {
                          size: "small",
                          InputLabelProps: { shrink: true }, // ทำให้ label ลอยอยู่ตลอด
                        },
                      }}
                    />

                    <DatePicker
                      label="End Date"
                      value={endDate ? dayjs(endDate) : null}
                      onChange={(newValue) => setEndDate(newValue ? newValue.format("YYYY-MM-DD") : null)}
                      format="DD/MM/YYYY"
                      sx={{ width: 140 }}
                      disabled={symbols.length > 0}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </Box>
                </LocalizationProvider>



                <Box sx={{ marginBottom: 1, display: 'flex', justifyContent: 'center' }}>
                  <label>
                    <input
                      type="radio"
                      name="chartMode"
                      value="intraday"
                      checked={chartMode === 'intraday'}
                      onChange={() => handleModeChange('intraday')}
                    />
                    Intraday
                  </label>
                  <label style={{ marginLeft: '10px' }}>
                    <input
                      type="radio"
                      name="chartMode"
                      value="day"
                      checked={chartMode === 'day'}
                      onChange={() => handleModeChange('day')}
                    />
                    Day
                  </label>
                </Box>

              </div>
            </Box>
            {symbols.length > 0 &&
              (<>
                {!isPTablet && <Divider orientation="vertical" color='white' />}

                <Box sx={{ width: isPTablet ? '100%' : '50%' }}>
                  <Box sx={{ display: "flex", gap: 1, mb: 1, flexWrap: "wrap", width: '100%', justifyContent: isPTablet ? 'center' : 'unset' }}>
                    {symbols.map((sym) => (
                      <Chip
                        key={sym}
                        label={sym}
                        onDelete={() => removeSymbol(sym)}
                        sx={{ backgroundColor: colors.tonal[30] }}
                      />
                    ))}
                  </Box>
                </Box>
              </>)}
          </Box>
        </>
      )}
      {(isMobile && !isTablet) && (
        <>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between', flexDirection: 'column',
            mb: 1,
            width: '100%'
            //  border: '1px solid #fff'
          }}>
            <Box sx={{ display: 'flex', width: '100%', gap: '20px', justifyContent: 'center' }}>
              <div>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1, justifyContent: 'center' }}>
                  <TextField
                    label='Symbol'
                    variant="outlined"
                    placeholder="Enter symbol (e.g. KBANK)"
                    value={inputSymbol}
                    onChange={(e) => setInputSymbol(e.target.value)}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: 200 }}
                  />
                  <Button
                    variant="contained"
                    onClick={addSymbolLine}
                    disabled={isAddDisabled}
                    sx={{ textTransform: "none", backgroundColor: !isAddDisabled ? 'green' : 'unset' }}
                  >
                    Add Symbol
                  </Button>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ marginBottom: 1, gap: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <DatePicker
                      label="Start Date"
                      value={startDate ? dayjs(startDate) : null}
                      onChange={(newValue) => setStartDate(newValue ? newValue.format("YYYY-MM-DD") : null)}
                      format="DD/MM/YYYY"
                      sx={{ width: 140 }}
                      disabled={symbols.length > 0}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{
                        textField: {
                          size: "small",
                          InputLabelProps: { shrink: true }, // ทำให้ label ลอยอยู่ตลอด
                        },
                      }}
                    />

                    <DatePicker
                      label="End Date"
                      value={endDate ? dayjs(endDate) : null}
                      onChange={(newValue) => setEndDate(newValue ? newValue.format("YYYY-MM-DD") : null)}
                      format="DD/MM/YYYY"
                      sx={{ width: 140 }}
                      disabled={symbols.length > 0}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </Box>
                </LocalizationProvider>



                <Box sx={{ marginBottom: 1, display: 'flex', justifyContent: 'center' }}>
                  <label>
                    <input
                      type="radio"
                      name="chartMode"
                      value="intraday"
                      checked={chartMode === 'intraday'}
                      onChange={() => handleModeChange('intraday')}
                    />
                    Intraday
                  </label>
                  <label style={{ marginLeft: '10px' }}>
                    <input
                      type="radio"
                      name="chartMode"
                      value="day"
                      checked={chartMode === 'day'}
                      onChange={() => handleModeChange('day')}
                    />
                    Day
                  </label>
                </Box>

              </div>
            </Box>
            {symbols.length > 0 &&
              (<>
                {/* <Divider orientation="vertical" color='white' /> */}
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ display: "flex", gap: 1, mb: 1, flexWrap: "wrap", width: '100%' }}>
                    {symbols.map((sym) => (
                      <Chip
                        key={sym}
                        label={sym}
                        onDelete={() => removeSymbol(sym)}
                        sx={{ backgroundColor: colors.tonal[30] }}
                      />
                    ))}
                  </Box>
                </Box>
              </>)}
          </Box>
        </>
      )}

      <Box sx={{
        display: 'flex', flexDirection:
        {
          md: 'row', // ถ้าความกว้างของจอ > 1200px ให้แสดงเป็น row
          sm: 'column', // ถ้าความกว้างน้อยกว่า < 1200px ให้แสดงเป็น column
          xs: 'column'
        },
        gap: '20px', width: '100%', height: {
          sm: '75%',
          xs: '65%'
        }
      }}>
        <div ref={leftChartContainerRef} style={{ flex: 1, border: '1px solid #ccc', borderRadius: '10px', overflow: 'hidden', position: 'relative' }}>
          <Box sx={{
            position: 'absolute', zIndex: 111, left: '10px', top: '10px', padding: '5px 10px', backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: '10px',
            transition: "background-color 0.3s",
            "&:hover": {
              color: 'rgba(255,255,255,0.1)',
              backgroundColor: "rgba(0,0,0,0.1)",
            },
          }}>
            <Typography variant='body1'>Price</Typography>
          </Box>
        </div>
        <div ref={rightChartContainerRef} style={{ flex: 1, border: '1px solid #ccc', borderRadius: '10px', overflow: 'hidden', position: 'relative' }}>
          <Box sx={{
            position: 'absolute', zIndex: 111, left: '10px', top: '10px', padding: '5px 10px', backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: '10px',
            transition: "background-color 0.3s",
            "&:hover": {
              color: 'rgba(255,255,255,0.1)',
              backgroundColor: "rgba(0,0,0,0.1)",
            },
          }}>
            <Typography variant='body1'>Money In/Out</Typography>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default MoneyInOutStock;
