import React, { useRef, useEffect } from 'react';
import { Box, Drawer, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Menu, MenuItem } from "react-pro-sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import TimelineIcon from '@mui/icons-material/Timeline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Tooltip from '@mui/material/Tooltip';
import { fontSize, height } from '@mui/system';
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
import { styled } from '@mui/system';
import { useState } from 'react';
import { tokens } from '../../theme';
import { useTheme } from '@mui/material/styles';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MenuList from "@mui/material/MenuList";
import Divider from '@mui/material/Divider';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from "react-redux";



const Item = ({ title, to, icon, user, permissionKey, selected, setSelected }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);



    const textStyle = {
        fontSize: '1rem', // Set the desired font size
        // padding: '10px', // Set the desired padding
        // color: setSelected ? colors.primary[400] : 'red',
        fontFamily: 'Sora, sans-serif',
        fontWeight: '300',
        fontStyle: 'normal',
        //color: '#3083FF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',

    };
    const handleItemClick = (event) => {
        setSelected(to);
        // isMobile ? collapseSidebar(true) : collapseSidebar(false);
    };

    let isSubscriber = false;
    // let isSubscriber = false;
    if (
        !user ||
        !user.permissions ||
        !user.permissions.permission ||
        !user.permissions.permission[permissionKey] ||
        !user.permissions.permission[permissionKey].read
    ) {
        // return <Navigate to="/401" replace />;
        // setIsSubscriber(true);
        isSubscriber = true;
        // to = 'upgrade';
    }

    const Ribbon = styled('div')(() => ({
        right: '1vw',
        width: '50px', // Default size
        height: '50px',
        backgroundColor: 'red',
        color: 'white',
        position: 'absolute',
        top: '0rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.6rem',
        fontWeight: '300',
        zIndex: 1,
        width: '60px',
        height: '20px',
        fontSize: '0.45rem',
    }));

    const NavLinkWithRibbon = styled(NavLink)(() => ({
        position: 'relative',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        '&.active': {
            borderRadius: '15px',
            color: 'white',
        },
        marginTop: '3px'
    }));

    const StyledBox = styled(Box)(({ theme, selected, title, colors }) => ({
        // backgroundColor: isSubscriber ? '#E5E5E5' : 'white',
        backgroundColor: selected == to ? theme.palette.background.default : 'transparent',
        // backgroundImage: selected == to ? 'linear-gradient(90deg, #3083FF, #1D4E99)' : 'white',
        borderRadius: '15px 0 0 15px',
        color: selected == to ? theme.palette.text.sidebar : "white",
        position: 'relative',
        width: '95%',
        margin: '0 10px 0 10px',
        padding: '1.5vh 4rem 1.5vh 3vw',


        position: 'relative',

        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        ':hover': {
            borderRadius: '15px 0 0 15px',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.sidebar,
        },
        ':after': {
            // backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99)',
            backgroundColor: theme.palette.background.default,
        },
    }));

    return (
        <NavLinkWithRibbon to={isSubscriber ? 'upgrade' : to} style={{
            position: 'relative',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            left: '2vw'
        }}>
            <StyledBox selected={selected} title={title} colors={colors} onClick={handleItemClick} icon={icon}>
                <Typography style={textStyle}>{icon}
                    {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
                    {title}</Typography>
                {isSubscriber && <Ribbon>Subscription</Ribbon>}
            </StyledBox>
        </NavLinkWithRibbon >
    );

}

const ListItemLink = (props) => {
    const { icon, primary, to, itemStyle } = props;

    return (
        <li>
            <ListItem button component={Link} to={to}>
                {icon ? icon : null}
                <ListItemText primary={primary} style={{ ...itemStyle, marginLeft: '8px' }} />
            </ListItem>
        </li>
    );
}

const MSideBar = ({ open, toggleSidebar }) => {
    const location = useLocation();
    const [selected, setSelected] = useState(location.pathname);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const userDetail = debugUser;

    const userDetail = useSelector((state) => state.userDetail);
    const iconButtonRef = useRef();

    const [showDiv, setShowDiv] = useState(false);

    const toggleDiv = () => {
        setShowDiv(!showDiv);
    };

    const [divPosition, setDivPosition] = useState({ left: 0, top: 0 });

    useEffect(() => {
        if (showDiv && iconButtonRef.current) {
            const rect = iconButtonRef.current.getBoundingClientRect();
            setDivPosition({ top: rect.bottom + 5 });
        }
    }, [showDiv]);

    useEffect(() => { }, []);


    return (
        <>
            <IconButton onClick={toggleSidebar} color={theme.palette.text.primary}>
                <MenuIcon />
            </IconButton>
            <Drawer

                anchor="left"
                open={open}
                onClose={toggleSidebar}
                variant="temporary"
                sx={{ width: 240, flexShrink: 0, }}
            >

                <Box sx={{
                    // width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.background.sidebar,
                }}>
                    <IconButton className="closeButton" onClick={toggleSidebar} sx={{ alignSelf: 'flex-end', mt: 2, mr: 2, color: 'white' }}>
                        <CloseIcon />
                    </IconButton>
                    {/* <Box sx={{ height: 55 }}></Box> */}

                    <Menu>
                        <MenuItem
                            style={{
                                margin: "0 0 10px 0",
                                color: 'black',
                                cursor: "default",
                                padding: "0",
                            }}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="center"
                            >
                                <Box
                                    // bgcolor="red"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexGrow={1}
                                    flexBasis={0}
                                ></Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    flexGrow={1}
                                    flexBasis={0}
                                    padding={0}
                                >
                                    <React.Fragment>
                                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                            <Tooltip title="Account">


                                                <IconButton
                                                    ref={iconButtonRef}
                                                    onClick={toggleDiv}
                                                    // size="small"
                                                    sx={{ ml: 2 }}
                                                >
                                                    {/* <Avatar sx={{ width: 64, height: 64 }}> */}
                                                    <img
                                                        src={userDetail.img_src ? userDetail.img_src : "data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220,0,20,20%22%20width=%2239%22%20height=%2239%22%3E%3Crect%20height=%2220%22%20width=%2220%22%20fill=%22hsl%28153,25%25,50%25%29%22/%3E%3Ctext%20fill=%22white%22%20x=%2210%22%20y=%2214.8%22%20font-size=%2214%22%20font-family=%22-apple-system,BlinkMacSystemFont,Trebuchet%20MS,Roboto,Ubuntu,sans-serif%22%20text-anchor=%22middle%22%3EI%3C/text%3E%3C/svg%3E"}
                                                        alt="Your Image"
                                                        style={{ borderRadius: "50%" }}
                                                    // onClick={handleMenuClick} 
                                                    />
                                                    {/* </Avatar> */}
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </React.Fragment>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="end"
                                    alignItems="center"
                                    flexGrow={1}
                                    flexBasis={0}

                                >
                                </Box>
                            </Box>
                        </MenuItem>
                    </Menu>

                    {/* LoGO */}
                    <Box sx={{
                        width: '50vw',
                    }}>
                        <Box variant="h2" color='black' overflow='visible'
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                top: '0vh',
                                left: '1vw'
                                //top: '20vh'
                            }}>
                            <img src="https://i.postimg.cc/TP1gJwyz/logo-white-02.png" alt="" width='70%' height='auto' />
                        </Box>
                    </Box>
                    {/* <Box sx={{ height: 240 }}>

                    </Box> */}
                    <Typography
                        variant="h5"
                        color={colors.grey[300]}
                        sx={{
                            fontSize: '1.1rem',
                            m: "15px 0 5px 20px",
                            marginBottom: '1.5rem',
                            fontFamily: '"Sora", sans-serif',
                            fontWeight: '400',
                            fontStyle: 'normal',
                            //color: '#3083FF',
                            color: '#FFCD77',
                        }}
                    >
                        Data
                    </Typography>
                    <Item
                        title='หมอดูหุ้น'
                        to="/chatbot"
                        icon={<ChatIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        // user={user}
                        user={userDetail}
                        permissionKey={'chatBot9Chanel'}
                    />
                    <Item
                        title='Petroleum'
                        to="/petroleum"
                        icon={<OilBarrelIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        // user={user}
                        user={userDetail}
                        permissionKey={'petroleumChart'}
                    />

                    <Item
                        title="Rubber Thai"
                        to="/rubber"
                        icon={<SsidChartIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        // user={user}
                        user={userDetail}
                        permissionKey={'rubberThaiChart'}
                    />


                    <Item
                        title="Flow Intraday"
                        to="/realtime"
                        icon={<BarChartIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        // user={user}
                        user={userDetail}
                        permissionKey={'realTimeChart'}
                    // permissionKey={'rubberThaiChart'}
                    />

                    <Item
                        title='S50'
                        to="/s50"
                        icon={<ChatIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        user={userDetail}
                        permissionKey={'chatBot9Chanel'}
                    />

                    <Item
                        title="Gold"
                        to="/gold"
                        icon={<InsightsIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        // user={user}
                        user={userDetail}
                        permissionKey={'goldChart'}
                    />

                    < Item
                        title="BidAsk"
                        to="/dejavu/bidask"
                        icon={<TimelineIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        // user={user}
                        user={userDetail}
                        permissionKey={'bidaskDejavu'}
                    />

                    < Item
                        title="TickMatch"
                        to="/dejavu/tickmatch"
                        icon={<FormatListBulletedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        // user={user}
                        user={userDetail}
                        permissionKey={'tickmatchDejavu'}
                    />

                    < Item
                        title="DR"
                        to="/dr"
                        icon={<FormatListBulletedIcon />}
                        selected={selected}
                        setSelected={setSelected}
                        // user={user}
                        user={userDetail}
                        permissionKey={'tickmatchDejavu'}
                    />

                    {userDetail.user_type == 'employee' && (
                        <Typography
                            variant="h5"
                            color={colors.grey[300]}
                            sx={{
                                m: "15px 0 5px 20px",
                                marginBottom: '1.5rem',
                                fontSize: '1rem',
                                fontFamily: '"Sora", sans-serif',
                                fontWeight: '400',
                                fontStyle: 'normal',
                                //color: '#3083FF',
                                color: '#FFCD77',
                            }}
                        >
                            Administrator
                        </Typography>
                    )}
                    {userDetail.user_type === 'employee' && (
                        < Item
                            title="Manage User"
                            to="/admin/users"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            // isMobile={isMobile}
                            user={userDetail}
                            permissionKey={'manageUser'}
                        />
                    )}
                    {userDetail.user_type === 'employee' && (
                        < Item
                            title="Manage Role"
                            to="/admin/roles"
                            icon={<GroupsIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            // isMobile={isMobile}
                            user={userDetail}
                            permissionKey={'manageUser'}
                        />
                    )}
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 99999
                        }}
                    >
                        {
                            showDiv && (
                                <Box
                                    className="sideDiv"
                                    sx={{
                                        position: 'absolute',
                                        // backgroundColor: '#ffffff',
                                        // border: '1px solid #000000',
                                        // padding: '10px',
                                        width: '200px',
                                        height: '150px',
                                        top: divPosition.top,
                                        left: divPosition.left,
                                        fontSize: '10px'
                                    }}
                                >

                                    <Card sx={{ minWidth: sx => sx.width }}>
                                        <CardContent
                                            style={{
                                                // backgroundColor: 'red',
                                                padding: 0
                                            }}>
                                            {/* Close Button */}
                                            <IconButton
                                                aria-label="close"
                                                onClick={toggleDiv}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    zIndex: 1,
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>

                                            <MenuList
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                // onKeyDown={handleListKeyDown}
                                                style={{
                                                    padding: 0
                                                }}
                                            >

                                                <li>
                                                    <ListItem >
                                                        <ListItemText
                                                            primary={<Typography style={{ marginLeft: '8px', fontSize: '1rem' }} variant="h3" gutterBottom>IDEATRADE</Typography>}
                                                        />
                                                    </ListItem>
                                                </li>
                                                <Divider variant="middle" component="li" />
                                                <ListItemLink
                                                    to="/profile"
                                                    primary={
                                                        <Typography>Account</Typography>
                                                    }
                                                    icon={<PersonOutlinedIcon />}
                                                />
                                                <ListItemLink
                                                    to="/logout"
                                                    primary={
                                                        <Typography>Logout</Typography>
                                                    }
                                                    icon={<LogoutIcon style={{ color: 'red' }} />}
                                                    itemStyle={{ color: 'red' }}
                                                />
                                            </MenuList>
                                        </CardContent>

                                    </Card>
                                </Box>
                            )

                        }
                    </div>
                </Box>
            </Drawer>

        </>
    )

};

export default MSideBar;