import React, { useEffect, useRef, useState } from "react";
import { createChart } from "lightweight-charts";
import { useLocation } from "react-router-dom";
import {
    Box, Button, Container, Paper, Grid, Typography,
    List, ListItem, ListItemText, ListItemAvatar, Avatar, Divider, IconButton
} from "@mui/material";


import Header from "../../components/Header";
import LightweightChartv1 from "../../components/LightweightChart v1";

const generateData = (data, date_columm = 'datetime', field, haveTime = false) => {
    const timeZoneOffset = 7 * 60 * 60 * 1000;
    const cdata = data.map(d => {
        let time = timeToTz(d[date_columm], 'Asia/Bangkok')

        const dd = new Date(d[date_columm])
        const year = dd.getFullYear();
        const month = String(dd.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
        const day = String(dd.getDate()).padStart(2, '0');
        const hour = String(dd.getHours()).padStart(2, '0');
        const minute = String(dd.getMinutes()).padStart(2, '0');
        const second = String(dd.getSeconds()).padStart(2, '0');

        // let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000
        let time2 = `${year}-${month}-${day}`;
        if (haveTime) {
            time2 = `${time2} ${hour}:${minute}:${second}`;
        }

        return { time: time2, value: parseFloat(d[field]) || 0 }
    });
    return cdata;
}
function timeToTz(originalTime, timeZone) {
    const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
    // const options = {
    //     timeZone: timeZone,
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     second: 'numeric',
    //     hour12: false,
    // };
    // const formatter = new Intl.DateTimeFormat('en-US', options);
    return zonedDate.getTime() / 1000;
}

const FullChart = () => {
    const chartContainerRef = useRef(null);
    const location = useLocation();
    const [storedSymbols, setStoredSymbols] = useState([]);

    // ดึงข้อมูล Base64 จาก query parameters
    const queryParams = new URLSearchParams(location.search);
    const base64Data = queryParams.get("data");

    // แปลงจาก Base64 กลับเป็น JSON
    const allData = base64Data ? JSON.parse(atob(base64Data)) : [];


    const [chartData, setChartData] = useState([]);
    const [chartDataPrice, setChartDataPrice] = useState([]);
    const [chartTitle, setChartTitle] = useState([]);
    const [isIntraday, setIsIntraday] = useState([]);

    const [selectedOption, setSelectedOption] = useState('SET');

    useEffect(() => {
        const storedData = localStorage.getItem("chartData");
        const storedDataPrice = localStorage.getItem("chartDataPrice");
        setStoredSymbols(JSON.parse((localStorage.getItem("chartDataSymbols"))));
        setIsIntraday(localStorage.getItem("chartType"));

        // console.log(storedData)
        if (storedData) {
            const allData = JSON.parse(storedData);
            const title = localStorage.getItem("chartTitle");
            const storedMarket = localStorage.getItem("chartMarket");

            setChartData(allData);
            setChartTitle(title);
            setSelectedOption(storedMarket);
        }
        if (storedDataPrice) {
            const allDataPrice = JSON.parse(storedDataPrice);
            // if (allDataPrice?.length > 0) {
            setChartDataPrice(allDataPrice);
            // }
        }

    }, []);

    const commonOptions = isIntraday == 'intraday' ? {
        crosshair: {
            mode: 0
        },
        timeScale: {
            visible: true,
            timeVisible: true,
            secondsVisible: true,
        },
        localization: {
            locale: "en-US"
        },
        layout: {
            background: {
                type: 'solid',
                color: '#575b69',
            },
            textColor: '#ffffff',
        },
        grid: {
            vertLines: { color: '#323856' },
            horzLines: { color: '#323856' },
        },
        title: {
            color: 'white',
        },
        rightPriceScale: {
            visible: false,
        },
        leftPriceScale: {
            visible: false,
        },
        localization: {
            locale: 'th-TH',
        },

    } : {
        crosshair: {
            mode: 0
        },
        timeScale: {
            visible: true,
        },
        localization: {
            locale: "en-US"
        },
        layout: {
            background: {
                type: 'solid',
                color: '#575b69',
            },
            textColor: '#ffffff',

        },
        title: {
            text: chartTitle, // ชื่อกราฟ
            color: '#ffffff', // สีตัวอักษรเป็นขาว
            font: {
                size: 16, // ขนาดตัวอักษร
                weight: 'bold', // ขนาดตัวหนา
            },
        },
        grid: {
            vertLines: { color: '#323856' },
            horzLines: { color: '#323856' },
        },
        rightPriceScale: {
            visible: false,
        },
        leftPriceScale: {
            visible: false,
        },
    };

    const chartRefs = useRef([]);
    const chartContainerRefs = useRef([]);
    const [isReady, setIsReady] = useState(false);
    const [seriesOptions, setSeriesOptions] = useState([]);

    const content = document.getElementsByClassName('content')[0];
    const resizeHandler = () => {
        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef) {
                const chart = chartRef.getChartRef();
                try {
                    const container = chartContainerRefs.current[index]
                    if (container) {
                        chart.resize(container.clientWidth, container.clientHeight);
                    }
                } catch (error) {
                    console.error('Error resizing chart:', error);
                }
            }
        });
    };

    useEffect(() => {
        if (content) {
            const resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver.observe(content);
            return () => {
                if (resizeObserver) {
                    resizeObserver.disconnect();
                }
            };
        }
    }, [content]);

    useEffect(() => {

        chartRefs.current.forEach((chartRef, index) => {
            if (chartRef && chartRef.getChartRef) {
                // console.log(`Chart ${index + 1} instance:`, chartRef);
                const chart = chartRef.getChartRef();
                const series = chartRef.getSeriesRefs();

                const container = chartContainerRefs.current[0];
                if (container) {
                    // เพิ่มการตกแต่ง container
                    container.style.outline = "2px solid #9b9b9b"; // ขอบสีขาว
                    container.style.borderRadius = "10px"; // ขอบมน
                    container.style.overflow = "hidden"; // ซ่อนส่วนเกิน
                    container.style.boxSizing = "border-box"; // ป้องกันการล้นออกมา
                    container.style.height = "780px"; // กำหนดความสูง

                }
                const width = container.clientWidth;
                const height = container.clientHeight;
                chart.resize(width, height);

                if (series) {
                    const seriesData = series[0]?.data();
                    if (seriesData?.length > 0) {
                        const lastTime = new Date(seriesData[seriesData.length - 1].time).getTime();
                        const firstTime = new Date(seriesData[0].time).getTime();

                        const visibleRangeSize = 50//1 * 24 * 60 * 60 * 1000;
                        const from = Math.max(lastTime - visibleRangeSize, firstTime);
                        const to = lastTime;

                        chart.timeScale().setVisibleLogicalRange({ from, to });
                        chart.timeScale().scrollToPosition(2);
                    }
                }


            }

            return () => {


            }
        });
        setIsReady(true)
    }, [isReady, selectedOption, chartData, chartDataPrice, seriesOptions]); // chartContainerRefs.current, chartRefs.current,





    useEffect(() => {
        const fetchDataRealtime = async () => {
            console.log("Ready RealTime...")
            try {
                for (const [index, chartRef] of chartRefs.current.entries()) {
                    if (chartRef && chartRef.getChartRef) {
                        let paramsQuery = {
                            market: selectedOption,
                            sector: chartTitle,
                        };
                        let url = `${window.config.base_api_url}/sector_rotation/realtime?${new URLSearchParams(paramsQuery)}`
                        const response = await fetch(url);
                        const data = await response.json();
                        // if (initialData.length === 0 && data.data.length > 0) {
                        //     setInitialData(data.data); // เก็บข้อมูลที่ดึงมาไว้ใน state
                        // }

                        const chart = chartRef.getChartRef();
                        const series = chartRef.getSeriesRefs();
                        series.forEach((s, seriesIndex) => {
                            if (data?.data?.length > 0) {

                                let x = generateData(data.data, 'datetime', 'cumsum', true);

                                // let x = [{ 'time': Math.floor(Date.now() / 1000) + 7 * 60 * 60, 'value': Math.random() * 100 }]
                                // s.update({
                                //     time: x[0]?.time,
                                //     value: x[0]?.value
                                // })

                                const newDataPoints = x.map(item => {
                                    if (!item.time) {
                                        console.error("Invalid time value:", item.time);
                                        return null;
                                    }

                                    const time = new Date(item.time);
                                    // console.log((time.getTime() / 1000) + 7 * 60 * 60)

                                    if (isNaN(time.getTime())) {
                                        console.error("Invalid date format:", item.time);
                                        return null;
                                    }

                                    return {
                                        time: (time.getTime() / 1000) + 0 * 60 * 60,
                                        value: item.value,
                                    };
                                }).filter(item => item !== null)

                                newDataPoints.forEach(item => {
                                    try {
                                        s.update(item);
                                    } catch (error) {
                                        // console.error("Error updating series:", error);
                                    }
                                });
                            }
                        });

                    }
                }
            } catch (error) {
                console.log(error)
            } finally {

            }
        };

        if (isReady) {
            if (isIntraday != 'intraday') {
                const interval = setInterval(fetchDataRealtime, 1000);
                return () => clearInterval(interval);
            }

        }
    }, [isReady, selectedOption]);


    useEffect(() => {
        let seriesOptions2 = [
            {
                options: {
                    color: '#82f755',
                    lineWidth: 2,
                    priceLineVisible: false,
                    priceScaleId: "right",
                    title: "flow"
                },
                data: chartData,
            }
        ];

        if (chartDataPrice?.length > 0) {
            seriesOptions2.push({
                options: {
                    color: '#f8c953',
                    lineWidth: 2,
                    priceLineVisible: false,
                    priceScaleId: "left",
                    title: "price"
                },
                data: chartDataPrice,
            });
        }

        setSeriesOptions(seriesOptions2);
    }, [chartData, chartDataPrice]);


    return (
        <Box m="20px">
            {/* <Preloader open={loading} /> */}
            {/* <Box display="flex" justifyContent={{ md: 'center', lg: 'center' }} alignItems="center" p="0" > */}
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box flex="1" display="flex" justifyContent="center"></Box>
                <Box flex="1" display="flex" justifyContent="center">
                    <Header title=" " subtitle="" />
                </Box>
                {/* <Box flex="1" display="flex" justifyContent="end">
                    <Box mb="30px">
                        <Button
                            // onClick={moveToFirstData}
                            sx={{
                                variant: "h2",
                                backgroundColor: colors.greenAccent[700],
                                color: colors.grey[100],
                                // fontSize: "14px",
                                fontWeight: "bold",
                                // padding: "10px 20px",
                                m: "0 0 5px 0"
                            }}
                        >
                            First Data
                        </Button>
                    </Box>
                    <Box mb="30px" ml="3px">
                        <Button
                            onClick={moveToLastData}
                            sx={{
                                variant: "h2",
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                // fontSize: "14px",
                                fontWeight: "bold",
                                // padding: "10px 20px",
                                m: "0 0 5px 0"
                            }}
                        >
                            Last Data
                        </Button>
                    </Box>
                </Box> */}
            </Box>
            <Box p="0" display="Box"
                sx={{
                    height: {
                        xs: '50vh', // height for extra-small screens
                        sm: '60vh', // height for small screens
                        md: '90vh', // height for medium screens
                        lg: '90vh', // height for large screens
                        xl: '100vh', // height for extra-large screens
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '-3.3rem',
                    // width: '100%'
                }}>
                <Container maxWidth={false} sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: "85vw", mx: "auto" }}>
                    <Paper
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 2,


                            maxHeight: {

                                // xs: '50vh', // height for extra-small screens
                                // sm: '60vh', // height for small screens
                                // xs: '90vh',
                                // sm: '90vh',
                                // md: '90vh', // height for medium screens
                                // lg: '90vh', // height for large screens
                                // xl: '100vh', // height for extra-large screens
                            },

                        }}
                    >
                        <Grid container
                            direction="row"
                            spacing={0}
                            // key={"chart_row" + row} 
                            wrap="wrap"
                            sx={{
                                width: '100%',
                                margin: 0,
                                padding: 0,
                                marginTop: '5px',
                                height: '100%',

                            }}
                            columnSpacing={1}

                        >
                            <Grid
                                item
                                md={10}
                                xs={10}
                                sx={{
                                    // width: '100%',
                                    height: '100%',
                                    // height: 'calc(100% * 9 / 16)',
                                    padding: 0,
                                    margin: 0,
                                    // backgroundColor: row === 0 ? 'red' : 'blue',
                                    // borderLeft: '0.5px solid #9b9b9b',
                                    // borderBottom: '0.5px solid #9b9b9b',
                                    // borderRight: '0.5px solid #9b9b9b',
                                    // borderTop: '0.5px solid #9b9b9b',
                                    borderRadius: '4px',
                                    // paddingX: '1px',
                                    // backgroundColor: 'red'
                                }}


                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        padding: 0,
                                        marginTop: '10px',
                                        // maxHeight: 'calc(100% * 9 / 16)',

                                        // backgroundColor: 'red'
                                    }}
                                    ref={(el) => (chartContainerRefs.current[0] = el)}
                                >


                                    <LightweightChartv1
                                        ref={(el) => (chartRefs.current[0] = el)}
                                        options={{ ...commonOptions, fontSize: '50px' }}
                                        seriesOptions={seriesOptions}
                                        title={chartTitle}
                                    />





                                </Box>

                            </Grid>

                            <Grid
                                item
                                md={1.8}
                                xs={2}
                                sx={{
                                    maxHeight: "100%",
                                    p: 0,
                                    m: 0,

                                }}
                            >
                                <p style={{ marginLeft: '10px', marginBottom: "10px", fontSize: "18px", fontWeight: "bold", color: '#f5cd3d' }}>Symbol In Sector</p>
                                <Box
                                    sx={{
                                        overflowY: 'auto',
                                        '&::-webkit-scrollbar': {
                                            width: 8,
                                        },
                                        '&::-webkit-scrollbar-thumb': {
                                            backgroundColor: '#8b8b8b',
                                            borderRadius: '4px',
                                            marginLeft: '5px'
                                        },
                                        '&::-webkit-scrollbar-thumb:hover': {
                                            backgroundColor: '#555',
                                        },
                                        '&::-webkit-scrollbar-track': {
                                            backgroundColor: '#f1f1f1',
                                        },
                                        maxHeight: {
                                            xs: '50vh', // height for extra-small screens
                                            sm: '60vh', // height for small screens
                                            md: '80vh', // height for medium screens
                                            lg: '80vh', // height for large screens
                                            xl: '90vh', // height for extra-large screens
                                        },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center', // จัดให้อยู่กลางแนวแกน X
                                    }}
                                >
                                    {storedSymbols?.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                border: '1px solid #000', // เพิ่มความหนาขอบเป็น 1px
                                                width: '100%',
                                                textAlign: 'center', // จัดข้อความให้อยู่กลาง
                                                padding: 1.3, // เพิ่ม padding ให้ข้อความดูสมดุล
                                                backgroundColor: '#636a7e', // พื้นหลังเทาอ่อน
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <Typography
                                                variant="h5" // ปรับขนาดฟอนต์ใหญ่ขึ้น
                                                sx={{
                                                    fontWeight: 'bold', // ทำให้ตัวอักษรหนา
                                                    color: '#fff', // ใช้สีเทาเข้มให้ดูอ่านง่าย
                                                }}
                                            >
                                                {item.Symbol}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>


                            </Grid>

                        </Grid>


                    </Paper>
                </Container>

            </Box>

        </Box>


    );
};

export default FullChart;
