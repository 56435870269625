import React from "react";
import AreaSection from "./components/AreaSection";
import HotStockSection from "./components/HotStockSection";

function App() {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Real Time Hot Stock</h1>
      <hr style={{ margin: "20px 0" }} />
      {/* Area Section */}
      <AreaSection />
      <hr style={{ margin: "20px 0" }} />
      {/* Hot Stock Section */}
      <HotStockSection />
    </div>
  );
}

export default App;
