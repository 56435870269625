import { useEffect } from "react";

const FontLoader = () => {
  useEffect(() => {
    const link = document.createElement("link");
    link.href = "https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return null; // Component นี้ใช้โหลดฟอนต์อย่างเดียว ไม่มี UI
};

export default FontLoader;
