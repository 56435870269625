import { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { Box } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import "./tip.css"
import InfoIcon from '@mui/icons-material/Info'; // import ไอคอน Information
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FontLoader from '../../components/FontLoader';


function FiveChart() {
  const chartContainerRef = useRef(null);
  const [data, setData] = useState([]);

  const chartContainerRef2 = useRef(null);
  const [data2, setData2] = useState([]);

  const chartContainerRef3 = useRef(null);
  const [data3, setData3] = useState([]);

  const chartContainerRef4 = useRef(null);
  const [data4, setData4] = useState([]);

  const chartContainerRef5 = useRef(null);
  const [data5, setData5] = useState([]);

  // Fetch XAUUSD

  const uri = `${window.config.base_api_url}`;
  useEffect(() => {
    fetch(uri + '/fivechart?broker_name=XAUUSD')
      .then(response => response.json())
      .then(data => setData(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Fetch SPDR
  useEffect(() => {
    fetch(uri + '/fivechart?broker_name=SPDR')
      .then(response => response.json())
      .then(data => setData2(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Fetch VIX
  useEffect(() => {
    fetch(uri + '/fivechart?broker_name=VIX')
      .then(response => response.json())
      .then(data => setData3(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Fetch DXY
  useEffect(() => {
    fetch(uri + '/fivechart?broker_name=DXY')
      .then(response => response.json())
      .then(data => setData4(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  // Fetch US10YY
  useEffect(() => {
    fetch(uri + '/fivechart?broker_name=US10YY')
      .then(response => response.json())
      .then(data => setData5(data.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const charts = [];
  const seriesList = [];

  // Utility functions
  function syncCrosshair(chart, series, dataPoint) {
    if (dataPoint) {
      chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
    } else {
      chart.clearCrosshairPosition();
    }
  }

  function getCrosshairDataPoint(series, param) {
    if (!param.time) return null;
    const dataPoint = param.seriesData.get(series);
    return dataPoint || null;
  }

  function createChartWithOptions(containerRef, data, watermarkText, color, rightPriceScale, rightOffset) {
    if (!data) {
      return;
    };
    // rightPriceScale: {
    //   borderVisible: false,
    // },
    const commonOptions = {
      width: containerRef.current.clientWidth,
      height: containerRef.current.clientHeight,
      layout: {
        background: {
          //color: '#191D41',
          color: '#575b69',


        },
        textColor: 'white',

      },
      timeScale: {  rightOffset: 10 },

      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },

    }

    const chart = createChart(containerRef.current, commonOptions);


    chart.priceScale('right').applyOptions({
      visible: !rightPriceScale ? false : true,
      // textColor: '#F5F5F5',
      
    });
    chart.priceScale('left').applyOptions({
      visible: false,
      // textColor: '#F5F5F5',
      
    });

    chart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 30,
        horzAlign: 'center',
        vertAlign: 'top',
        //color: '#C7D0FF',
        color: 'rgba(255, 255, 255, 0.6)', // สีขาวที่โปร่งใส 30%
        text: watermarkText,
        fontFamily: 'Lato, Sans-serif',


      },


    });

    const lineSeries = chart.addLineSeries({ color, lineWidth: 2, priceLineVisible: false, });
    const formattedData = data.map(item => ({
      time: item.time,
      // value: item.value, //อันเดิมอยู่นี่
      value: typeof item.value === "string" ? parseFloat(item.value) || 0 : item.value, // บังคับแปลงจาก string เป็น ตัวเลข
    }));

    lineSeries.applyOptions({
      priceFormat: {
        type: 'custom',
        formatter: (price) => {
          return price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
      }
      // priceFormat: {
      //   type: 'custom',
      //   formatter: (price) => {
      //     let shortenedPrice = price / 1_000_000_000;
      //     return shortenedPrice.toLocaleString('en-US', {
      //       minimumFractionDigits: 1,
      //       maximumFractionDigits: 1,
      //     }) + 'B';

      //   },
      // },
    });

    // console.log("data is", formattedData)

    lineSeries.setData(formattedData);

    // ฟังก์ชันการปรับขนาด Chart อัตโนมัติ
    const resizeObserver = new ResizeObserver(() => {
      chart.applyOptions({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      });
    });

    resizeObserver.observe(containerRef.current);

    return { chart, lineSeries };
  }

  // Create charts
  useEffect(() => {
    

    const chart1 = createChartWithOptions(chartContainerRef, data, 'Gold (COMEX)', '#95fe51', true);
    const chart2 = createChartWithOptions(chartContainerRef2, data2, 'Trends', '#5efee8');
    const chart3 = createChartWithOptions(chartContainerRef3, data3, 'VIX', '#ffdf4f');
    const chart4 = createChartWithOptions(chartContainerRef4, data4, 'DXY', '#ffc8f8');
    const chart5 = createChartWithOptions(chartContainerRef5, data5, 'US10YY', '#FFD1D1');

    if (chart1) charts.push(chart1.chart);
    if (chart2) charts.push(chart2.chart);
    if (chart3) charts.push(chart3.chart);
    if (chart4) charts.push(chart4.chart);
    if (chart5) charts.push(chart5.chart);

    if (chart1) seriesList.push(chart1.lineSeries);
    if (chart2) seriesList.push(chart2.lineSeries);
    if (chart3) seriesList.push(chart3.lineSeries);
    if (chart4) seriesList.push(chart4.lineSeries);
    if (chart5) seriesList.push(chart5.lineSeries);

    // Synchronize crosshair and visible logical range
    charts.forEach((chart, index) => {
      
      chart.subscribeCrosshairMove(param => {
        const dataPoint = getCrosshairDataPoint(seriesList[index], param);
        charts.forEach((otherChart, otherIndex) => {
          if (otherChart !== chart) {
            syncCrosshair(otherChart, seriesList[otherIndex], dataPoint);
          }
        });
      });

      chart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        charts.forEach(otherChart => {
          if (otherChart !== chart) {
            otherChart.timeScale().setVisibleLogicalRange(timeRange);
          }
        });
      });
    });

    

    return () => {
      charts.forEach(chart => chart.remove());
    };
  }, [data, data2, data3, data4, data5]);




  return (

    <Box sx={{ padding: 2,fontFamily: 'Lato, Sans-serif', }}>
      {/* กราฟแรกเต็มแถว */}
      <Box
        ref={chartContainerRef}
        sx={{
          width: "100%",
          height: 280,
          borderRadius: 3,
          overflow: "hidden",
          marginBottom: 1,
          boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.5)",
          border: "2px solid black",
          position: "relative", // เพิ่ม position relative
        }}
      >
        {/* Tooltip */}
        {/* <div className="item-hints" style={{ position: "absolute", top: "85px", right: "1161px" }}>
          <div className="hint" data-position="4">
            <span className="hint-radius"></span>
            <span className="hint-dot">
              <InfoIcon style={{ fontSize: 30, color: "#000" }} /> {/* แสดงไอคอน Information */}
        {/* </span>
            <div className="hint-content do--split-children">
              <p>Is Price</p>
            </div>
          </div> */}

      </Box>

      {/* แถวที่สอง: กราฟ 2 และ 3 */}
      <Box sx={{ display: "flex", gap: 1, marginBottom: 1 }}>
        <Box
          ref={chartContainerRef2}
          sx={{
            flex: 1,
            height: 280,
            borderRadius: 3,
            overflow: "hidden",
            boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.5)",
            border: "2px solid black",
            position: "relative", // เพิ่ม position relative
          }}
        >
          {/* Tooltip */}
          {/* <div className="item-hints" style={{ position: "absolute", top: "85px", right: "470px" }}>
            <div className="hint" data-position="4">
              <span className="hint-radius"></span>
              <span className="hint-dot">
                <ShowChartIcon style={{ fontSize: 30, color: "#000" }} />
              </span>
              <div className="hint-content do--split-children">
                <p>
                  Up Is <span style={{ color: "green" }}>Good</span> <br />
                  Down Is <span style={{ color: "red" }}>Lose</span>
                </p>
              </div>
            </div>
          </div> */}
        </Box>

        <Box
          ref={chartContainerRef3}
          sx={{
            flex: 1,
            height: 280,
            borderRadius: 3,
            overflow: "hidden",
            boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.5)",
            border: "2px solid black",
            position: "relative", // เพิ่ม position relative
          }}
        >
          {/* Tooltip */}
          {/* <div className="item-hints" style={{ position: "absolute", top: "85px", right: "470px" }}>
            <div className="hint" data-position="4">
              <span className="hint-radius"></span>
              <span className="hint-dot">
                <ShowChartIcon style={{ fontSize: 30, color: "#000" }} />
              </span>
              <div className="hint-content do--split-children">
                <p>
                  Up Is <span style={{ color: "green" }}>Good</span> <br />
                  Down Is <span style={{ color: "red" }}>Lose</span>
                </p>
              </div>
            </div>
          </div> */}
        </Box>
      </Box>

      {/* แถวที่สาม: กราฟ 4 และ 5 */}
      <Box sx={{ display: "flex", gap: 1 }}>
        <Box
          ref={chartContainerRef4}
          sx={{
            flex: 1,
            height: 280,
            borderRadius: 3,
            overflow: "hidden",
            boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.5)",
            border: "2px solid black",
            position: "relative", // เพิ่ม position relative
          }}
        >
          {/* Tooltip */}
          {/* <div className="item-hints" style={{ position: "absolute", top: "85px", right: "470px" }}>
            <div className="hint" data-position="4">
              <span className="hint-radius"></span>
              <span className="hint-dot">
                <ShowChartIcon style={{ fontSize: 30, color: "#000" }} />
              </span>
              <div className="hint-content do--split-children">
                <p>
                  Up Is <span style={{ color: "red" }}>Lose</span> <br />
                  Down Is <span style={{ color: "green" }}>Good</span>
                </p>
              </div>
            </div>
          </div> */}
        </Box>

        <Box
          ref={chartContainerRef5}
          sx={{
            flex: 1,
            height: 280,
            borderRadius: 3,
            overflow: "hidden",
            boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.5)",
            border: "2px solid black",
            position: "relative", // เพิ่ม position relative
          }}
        >
          {/* Tooltip */}
          {/* <div className="item-hints" style={{ position: "absolute", top: "85px", right: "470px" }}>
            <div className="hint" data-position="4">
              <span className="hint-radius"></span>
              <span className="hint-dot">
                <ShowChartIcon style={{ fontSize: 30, color: "#000" }} />
              </span>
              <div className="hint-content do--split-children">
                <p>
                  Up Is <span style={{ color: "red" }}>Lose</span> <br />
                  Down Is <span style={{ color: "green" }}>Good</span>
                </p>
              </div>
            </div>
          </div> */}
        </Box>
      </Box>
    </Box>



  );
}

export default FiveChart;
