import React, { useState, useEffect } from 'react';
import Chart from './Chart';
import Form from './Form';
import DataTable from './DataTable';
import LoadingSpinner from './LoadingSpinner';
import NoDataAnimation from './NoDataAnimation';
import { useSelector } from 'react-redux';
import { useIsMobile, useIsPortraitTablet, useIsTablet} from "../../components/hooks";

import { Box, Typography, Container, Paper } from '@mui/material';
import FontLoader from '../../components/FontLoader';

const PageFirst = () => {
    const [symbol, setSymbol] = useState(null);
    const [startDate, setStartDate] = useState("2024-12-25");
    const [endDate, setEndDate] = useState("2024-12-25");
    const [nStd, setNStd] = useState(3);
    const [windowState, setWindowState] = useState(0);
    const [data, setData] = useState(null);
    const [upperExceedData, setUpperExceedData] = useState([]);
    const [lowerExceedData, setLowerExceedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const csrfToken = useSelector((state) => state.csrfToken);

    const isMobile = useIsMobile();
    const isPTablet = useIsPortraitTablet();
    const isTablet = useIsTablet();

    async function fetchData() {
        console.log("Parameters:", { symbol, startDate, endDate, nStd, windowState });
        // const url = `${window.config.base_api_url}/bidask_volume/${symbol}?startDate=${startDate}&endDate=${endDate}&nStd=${nStd}&windowState=${windowState}`;
        const url = `${window.config.base_api_url}/bidask_volume/${symbol}&${startDate}&${endDate}&${nStd}&${windowState}`;
        console.log("Requesting URL:", url);
        // console.log(window.config.base_api_url)

        setLoading(true); // เปิด LoadingSpinner ก่อนเริ่มโหลดข้อมูล
        try {
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch data. Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Fetched data:", data);
            setData(data.data);
        } catch (error) {
            console.error("Error fetching data:", error.message);
        } finally {
            setLoading(false); // ปิด LoadingSpinner หลังโหลดข้อมูลเสร็จ (ไม่ว่าจะสำเร็จหรือไม่)
        }
    }

    useEffect(() => {
        if (!data) return;

        const upperExceed = [];
        const lowerExceed = [];

        data.forEach((item) => {
            if (item.spread_vol > item.spread_upper_bound) {
                upperExceed.push(item);
            }
            if (item.spread_vol < item.spread_lower_bound) {
                lowerExceed.push(item);
            }
        });

        setUpperExceedData(upperExceed);
        setLowerExceedData(lowerExceed);
    }, [data]);

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Lato, Sans-serif',
                textAlign: 'center',
            }}>
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <Box sx={{
            padding: (isPTablet || isMobile) ? '0px' : '20px', display: 'flex', justifyContent: 'center', flexDirection: 'column',

            // marginTop: '-40px'
        }}>
            <FontLoader/>
            <Typography variant="h2" sx={{
                textAlign: 'center',
                fontFamily: 'Lato, Sans-serif',
                fontSize: '2rem',
                fontWeight: 'bold',
                marginBottom: '10px'
            }}>
                Bid/Ask Volume visualization
            </Typography>

            <Container maxWidth="xl">
                <Paper sx={{ padding: 2 }}>
                    <div style={{ marginBottom: '20px' }}>
                        {/* <h1 style={{
                    //  marginLeft: '450px', 
                    fontFamily: ' sans-serif',
                    fontSize: '2.5rem'
                }}>Bid/Ask Volume Visualization</h1> */}
                        <Form
                            symbol={symbol}
                            startDate={startDate}
                            endDate={endDate}
                            nStd={nStd}
                            window={windowState}
                            setSymbol={setSymbol}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            setNStd={setNStd}
                            setWindow={setWindowState}
                            fetchData={fetchData}
                        />
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'space-between',
                        justifyContent: 'space-between',
                        // marginTop: '-30px',
                        height: data ? 'unset' : '70vh',
                        fontFamily: 'Lato, Sans-serif',
                    }}>
                        {data ? (
                            <>
                                <Chart data={data} upperExceedData={upperExceedData} lowerExceedData={lowerExceedData} />
                                {/* <DataTable upperExceedData={upperExceedData} lowerExceedData={lowerExceedData} /> */}
                            </>
                        ) : (
                            <Box sx={{marginTop: '100px'}}>
                                <NoDataAnimation />
                            </Box>

                        )}
                    </div>

                </Paper>
            </Container>



        </Box>
    );
};

export default PageFirst;
