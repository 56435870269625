import { useEffect, useState, useRef } from 'react';
import { useTheme, Box, Container, Grid, Paper, Button,
  Typography, FormControl, InputLabel, Select, MenuItem,
  IconButton, useMediaQuery } from '@mui/material';

import Header from "../../components/Header";
import LightweightChart from "../../components/LightweightChart";
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';

import Preloading from '../../components/Preloading';
import FontLoader from '../../components/FontLoader';

const generateData = (data, dateCol = 'datetime', field = 'cumsum', haveTime = false) => {
  return data.map(d => {
    const dd = new Date(d[dateCol]);
    const year = dd.getFullYear();
    const month = String(dd.getMonth() + 1).padStart(2, '0');
    const day = String(dd.getDate()).padStart(2, '0');
    const hour = String(dd.getHours()).padStart(2, '0');
    const minute = String(dd.getMinutes()).padStart(2, '0');
    const second = String(dd.getSeconds()).padStart(2, '0');

    let timeStr = `${year}-${month}-${day}`;
    if (haveTime) {
      timeStr += ` ${hour}:${minute}:${second}`;
    }
    return {
      time: timeStr,
      value: parseFloat(d[field]) || 0
    };
  });
};

function syncCrosshair(chart, series, dataPoint) {
  if (dataPoint) {
    chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
  } else {
    chart.clearCrosshairPosition();
  }
}

function getCrosshairDataPoint(series, param) {
  if (!param.time) return null;
  return param.seriesData.get(series) || null;
}

const SectorRotation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  // Market sets
  const sectors_set = [
    'AGRI','AUTO','BANK','COMM','CONMAT','CONS','ENERG','ETRON','FASHION','FIN',
    'FOOD','HELTH','HOME','ICT','IMM','INSUR','MEDIA','MINE','PAPER','PERSON',
    'PETRO','PF&REIT','PKG','PROF','PROP','REHABCO','STEEL','TOURISM','TRANS'
  ];
  const sectors_mai = [
    'AGRO','CONSUMP','FINCIAL','INDUS','PROPCON','RESOURC','SERVICE','TECH'
  ];
  const sectors_both = ['SET','MAI']; // "SET&MAI"

  const [selectedOption, setSelectedOption] = useState('SET');
  // The actual sectors we'll render
  const [sectors, setSectors] = useState(sectors_set);

  // Each sector gets data placeholders
  const [chartData, setChartData] = useState(sectors.map(() => []));
  const [chartDataPrice, setChartDataPrice] = useState(sectors.map(() => []));
  const [chartSymbolList, setChartSymbolList] = useState(sectors.map(() => []));

  // chunking
  const [sectorsChunks, setSectorsChunks] = useState([]);
  const [itemsPerRow, setItemsPerRow] = useState(2);

  // references
  const chartRefs = useRef([]);
  const chartContainerRefs = useRef([]);

  const [loading, setLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);

  // Common chart options
  const commonOptions = {
    crosshair: { mode: 0 },
    timeScale: { visible: true },
    localization: { locale: "en-US" },
    layout: {
      background: { type: 'solid', color: '#575b69' },
      textColor: '#ffffff',
      fontFamily: 'Lato, Sans-serif',
    },
    grid: {
      vertLines: { color: '#323856' },
      horzLines: { color: '#323856' },
    },
    rightPriceScale: { visible: false },
    leftPriceScale: { visible: false },
  };

  // Decide columns per row just like old code
  const getItemsPerRow = (chartCount) => {
    if (chartCount >= 10) {
      return window.innerWidth > 1366 ? 7 : window.innerWidth > 900 ? 5 : 2;
    } else if (chartCount === 8) {
      return 2; // 2 columns (MAI)
    } else if (chartCount === 2) {
      return 2; // 2 columns (SET&MAI)
    } else {
      return window.innerWidth > 1366 ? 7 : window.innerWidth > 900 ? 5 : 2;
    }
  };

  // Handle user dropdown
  const handleChange = (event) => {
    setLoading(true);
    chartRefs.current = [];
    chartContainerRefs.current = [];

    const val = event.target.value;
    let newSectors = [];
    if (val === 'SET') {
      newSectors = sectors_set;
    } else if (val === 'MAI') {
      newSectors = sectors_mai;
    } else { // 'SET_MAI'
      newSectors = sectors_both;
    }

    setSelectedOption(val);
    setSectors(newSectors);

    // reset placeholders
    setChartData(newSectors.map(() => []));
    setChartDataPrice(newSectors.map(() => []));
    setChartSymbolList(newSectors.map(() => []));
  };

  // chunk whenever sectors changes
  useEffect(() => {
    if (!sectors || sectors.length === 0) return;
    const newItems = getItemsPerRow(sectors.length);
    setItemsPerRow(newItems);

    const chunked = [];
    for (let i = 0; i < sectors.length; i += newItems) {
      chunked.push(sectors.slice(i, i + newItems));
    }
    setSectorsChunks(chunked);
  }, [sectors]);

  // fetch data for each sector
  useEffect(() => {
    if (!sectors || sectors.length === 0) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const responses = await Promise.all(
          sectors.map(sector => {
            const url = `${window.config.base_api_url}/sector_rotation2?market=${selectedOption}&sector=${encodeURIComponent(sector)}`;
            return fetch(url)
              .then(res => {
                if (!res.ok) return [];
                return res.json().then(d => d?.data ? d : []);
              })
              .catch(() => []);
          })
        );

        const updatedData = responses.map(r => r?.data?.data    || []);
        const updatedPrice= responses.map(r => r?.data?.price   || []);
        const updatedSymb = responses.map(r => r?.data?.symbols || []);

        setChartData(updatedData);
        setChartDataPrice(updatedPrice);
        setChartSymbolList(updatedSymb);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedOption, sectors]);

  // crosshair/time-scale sync, unsub to avoid "Object is disposed"
  useEffect(() => {
    const crosshairHandlers = [];
    const rangeHandlers = [];

    chartRefs.current.forEach((chartRef, index) => {
      if (!chartRef || !chartRef.getChartRef) return;
      const chart = chartRef.getChartRef();
      const series = chartRef.getSeriesRefs();
      const container = chartContainerRefs.current[index];
      if (!container) return;

      // We'll do dynamic sizing below
      // but let's do an initial resize
      chart.resize(container.clientWidth, container.clientHeight);

      // CROSSHAIR
      const crosshairHandler = (param) => {
        const dataPoint = getCrosshairDataPoint(series[0], param);
        chartRefs.current.forEach((otherRef, otherIndex) => {
          if (otherIndex !== index && otherRef && otherRef.getChartRef) {
            const otherChart = otherRef.getChartRef();
            const otherSeries = otherRef.getSeriesRefs();
            otherSeries.forEach(os => syncCrosshair(otherChart, os, dataPoint));
          }
        });
      };
      chart.subscribeCrosshairMove(crosshairHandler);
      crosshairHandlers[index] = crosshairHandler;

      // TIME-SCALE
      const rangeHandler = (timeRange) => {
        chartRefs.current.forEach((otherRef, otherIndex) => {
          if (otherIndex !== index && otherRef && otherRef.getChartRef) {
            otherRef.getChartRef().timeScale().setVisibleLogicalRange(timeRange);
          }
        });
      };
      chart.timeScale().subscribeVisibleLogicalRangeChange(rangeHandler);
      rangeHandlers[index] = rangeHandler;

      // scroll near the end
      chart.timeScale().scrollToPosition(4, false);
    });

    return () => {
      // cleanup
      chartRefs.current.forEach((chartRef, index) => {
        if (!chartRef || !chartRef.getChartRef) return;
        const chart = chartRef.getChartRef();
        try {
          if (crosshairHandlers[index]) {
            chart.unsubscribeCrosshairMove(crosshairHandlers[index]);
          }
        } catch (err) { /* ignore */ }

        try {
          if (rangeHandlers[index]) {
            chart.timeScale().unsubscribeVisibleLogicalRangeChange(rangeHandlers[index]);
          }
        } catch (err) { /* ignore */ }
      });
    };
  }, [chartData]);

  // final styling & container resizing once data is loaded
  useEffect(() => {
    // short delay
    const tId = setTimeout(() => {
      // figure out how many rows we have
      // we already know itemsPerRow, so number of rows = ceil(chartData.length / itemsPerRow)
      const totalCharts = chartData.length;
      const rowCount = Math.ceil(totalCharts / itemsPerRow);

      // get the full window height
      const containerHeight = window.innerHeight;

      // we reduce it slightly (80%) so there's some margin
      const itemHeight = (containerHeight / rowCount) * 0.8;

      chartRefs.current.forEach((chartRef, index) => {
        if (!chartRef || !chartRef.getChartRef) return;
        const container = chartContainerRefs.current[index];
        if (!container) return;

        // style the container
        container.style.outline = "2px solid #9b9b9b";
        container.style.borderRadius = "10px";
        container.style.overflow = "hidden";
        container.style.boxSizing = "border-box";

        // apply dynamic height
        container.style.height = `${itemHeight}px`;

        // now resize chart
        const chart = chartRef.getChartRef();
        chart.resize(container.clientWidth, container.clientHeight);
      });

      setIsReady(true);
    }, 200);

    return () => clearTimeout(tId);
  }, [chartData, itemsPerRow]);

  // Real-time updates
  useEffect(() => {
    if (!isReady) return;
    const interval = setInterval(async () => {
      try {
        const responses = await Promise.all(
          chartRefs.current.map((_, i) => {
            if (!sectors[i]) return null;
            const url = `${window.config.base_api_url}/sector_rotation/realtime?market=${selectedOption}&sector=${encodeURIComponent(sectors[i])}`;
            return fetch(url).then(r => r.json()).catch(() => null);
          })
        );
        chartRefs.current.forEach((chartRef, i) => {
          if (!chartRef || !chartRef.getChartRef || !responses[i] || !responses[i].data) return;
          const data = responses[i].data;
          const chart = chartRef.getChartRef();
          const series = chartRef.getSeriesRefs();
          // main line is cumsum
          const newX = generateData(data, 'datetime', 'cumsum', true);
          if (newX.length === 0) return;

          const newPoints = newX.map(item => {
            const d = new Date(item.time);
            if (isNaN(d.getTime())) return null;
            return { time: d.getTime() / 1000, value: item.value };
          }).filter(x => x !== null);

          if (series.length > 0 && newPoints.length > 0) {
            series[0].update(newPoints[newPoints.length - 1]);
          }
        });
      } catch (err) {
        console.error(err);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isReady, sectors, selectedOption]);

  // Move to last data
  const moveToLastData = () => {
    chartRefs.current.forEach(chartRef => {
      if (!chartRef || !chartRef.getChartRef) return;
      const chart = chartRef.getChartRef();
      const series = chartRef.getSeriesRefs();
      if (!series[0]) return;

      const sData = series[0].data();
      if (sData.length > 0) {
        const lastTime = new Date(sData[sData.length - 1].time).getTime();
        const firstTime = new Date(sData[0].time).getTime();
        const rangeSize = 50; 
        const from = Math.max(lastTime - rangeSize, firstTime);
        chart.timeScale().setVisibleLogicalRange({ from, to: lastTime });
        chart.timeScale().scrollToPosition(2);
      }
    });
  };

  // Move to first data
  const moveToFirstData = () => {
    chartRefs.current.forEach(chartRef => {
      if (!chartRef || !chartRef.getChartRef) return;
      const chart = chartRef.getChartRef();
      const series = chartRef.getSeriesRefs();
      if (!series[0]) return;

      const sData = series[0].data();
      if (sData.length > 0) {
        const firstTime = new Date(sData[0].time).getTime();
        const rangeSize = 7 * 24 * 60 * 60 * 1000;
        chart.timeScale().setVisibleLogicalRange({ from: firstTime, to: firstTime + rangeSize });
        chart.timeScale().scrollToPosition(-Number.MAX_SAFE_INTEGER, false);
      }
    });
  };

  // Render
  return (
    <Box m="20px">
      <FontLoader />
      <Preloading open={loading} />

      {/* Top bar: desktop */}
      {(!isMobile && !isTablet) && (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Box sx={{ display: "flex", flex: 1, justifyContent: "center", ml: "1rem" }}>
            <Box mb="30px" width="100%">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="dropdown-label">Select Market</InputLabel>
                <Select
                  labelId="dropdown-label"
                  value={selectedOption}
                  onChange={handleChange}
                  label="Select Market"
                >
                  <MenuItem value="SET">SET</MenuItem>
                  <MenuItem value="MAI">MAI</MenuItem>
                  <MenuItem value="SET_MAI">SET&MAI</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box flex="1" display="flex" justifyContent="center">
            <Header title="Sector rotation" subtitle="" />
          </Box>
          <Box flex="1" display="flex" justifyContent="end" sx={{ mr: "1rem" }}>
            <Box mb="30px">
              <Button
                onClick={moveToFirstData}
                sx={{
                  variant: "h2",
                  backgroundColor: "#72db6f",
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontFamily: 'Lato, Sans-serif',
                  m: "0 0 5px 0"
                }}
              >
                First Data
              </Button>
            </Box>
            <Box mb="30px" ml="3px">
              <Button
                onClick={moveToLastData}
                sx={{
                  variant: "h2",
                  backgroundColor: "#4C8CD3",
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontFamily: 'Lato, Sans-serif',
                  m: "0 0 5px 0"
                }}
              >
                Last Data
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {/* Top bar: mobile/tablet */}
      {(isMobile || isTablet) && (
        <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" width="100%">
          <Box flex="1" display="flex" justifyContent="center">
            <Typography variant="h2" sx={{ fontWeight: 'bold', fontFamily: 'Lato, Sans-serif' }}>
              Sector rotation
            </Typography>
          </Box>
          <Box flex="1" display="flex" justifyContent="center" sx={{ alignItems: 'center' }}>
            <Box width="100%">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="dropdown-label">Select Market</InputLabel>
                <Select
                  labelId="dropdown-label"
                  value={selectedOption}
                  onChange={handleChange}
                  label="Select Market"
                >
                  <MenuItem value="SET">SET</MenuItem>
                  <MenuItem value="MAI">MAI</MenuItem>
                  <MenuItem value="SET_MAI">SET&MAI</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Button
                onClick={moveToFirstData}
                sx={{
                  variant: "h2",
                  backgroundColor: "#72db6f",
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontFamily: 'Lato, Sans-serif',
                  m: "0 0 5px 0",
                  width: '80px'
                }}
              >
                First
              </Button>
            </Box>
            <Box ml="3px">
              <Button
                onClick={moveToLastData}
                sx={{
                  variant: "h2",
                  backgroundColor: "#4C8CD3",
                  color: "#ffffff",
                  fontWeight: "bold",
                  fontFamily: 'Lato, Sans-serif',
                  m: "0 0 5px 0",
                  width: '80px'
                }}
              >
                Last
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        p="0"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '-1.9rem',
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Paper
            sx={{
              flex: 1,
              overflowY: 'auto',
              width: '100%',
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              p: 2,
            }}
          >
            {sectorsChunks.map((sectorRow, rowIndex) => {
              // where we start in the overall chart arrays
              const rowStartIndex = rowIndex * itemsPerRow;
              return (
                <Grid
                  container
                  direction="row"
                  spacing={0}
                  wrap="nowrap"
                  key={`row_${rowIndex}`}
                  sx={{ width: '100%', margin: 0, padding: 0, marginTop: '-5px' }}
                >
                  {sectorRow.map((sectorName, colIndex) => {
                    // dataIndex in the flattened arrays
                    const dataIndex = rowStartIndex + colIndex;
                    const dataForSector = chartData[dataIndex] || [];
                    const priceForSector= chartDataPrice[dataIndex] || [];
                    const symbolsForSector = chartSymbolList[dataIndex] || [];

                    // color by column (0 => green, 1 => yellow, 2 => green, etc.)
                    const isEvenColumn = (colIndex % 2) === 0;
                    const mainColor = isEvenColumn ? "#82f755" : "#f8c953";

                    // If we are in SET&MAI mode, label the main line "flow"; otherwise no label
                    const mainLineTitle = (selectedOption === 'SET_MAI') ? 'flow' : '';
                    // Similarly, second line is "price" only if SET&MAI, else no label
                    const secondLineTitle = (selectedOption === 'SET_MAI') ? 'price' : '';

                    // Prepare line series
                    const lineOptions = [
                      {
                        options: {
                          color: mainColor,
                          lineWidth: 3,
                          priceLineVisible: false,
                          priceScaleId: "right",
                          title: mainLineTitle,
                        },
                        data: generateData(dataForSector, 'datetime', 'cumsum')
                      }
                    ];
                    if (priceForSector.length > 0) {
                      lineOptions.push({
                        options: {
                          color: "#5efee8",
                          lineWidth: 3,
                          priceLineVisible: false,
                          priceScaleId: "left",
                          title: secondLineTitle
                        },
                        data: generateData(priceForSector, 'datetime', 'value')
                      });
                    }

                    return (
                      <Grid
                        item
                        key={`chart_${dataIndex}`}
                        xs={12}
                        md={12 / itemsPerRow}
                        sx={{
                          padding: '3px',
                          margin: '5px',
                          boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                          borderRadius: '4px',
                          position: 'relative',
                          marginLeft: '-1px',
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            // dynamic height is set in the effect
                            position: 'relative',
                          }}
                          ref={el => (chartContainerRefs.current[dataIndex] = el)}
                        >
                          <LightweightChart
                            ref={el => (chartRefs.current[dataIndex] = el)}
                            options={{ ...commonOptions }}
                            seriesOptions={lineOptions}
                            title={sectorName}
                          />

                          {/* Zoom in top-right */}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 2,
                              right: 2,
                              zIndex: 10,
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                const allData = generateData(dataForSector, 'datetime', 'cumsum');
                                const allPrice= priceForSector.length > 0
                                  ? generateData(priceForSector, 'datetime', 'value')
                                  : null;
                                localStorage.setItem("chartMarket", selectedOption);
                                localStorage.setItem("chartData", JSON.stringify(allData));
                                localStorage.setItem("chartDataPrice", JSON.stringify(allPrice));
                                localStorage.setItem("chartTitle", sectorName);
                                localStorage.setItem("chartDataSymbols", JSON.stringify(symbolsForSector));
                                window.open(`/full-chart`, "_blank");
                              }}
                              sx={{
                                width: 25,
                                height: 25,
                                padding: 0,
                                backgroundColor: 'transparent',
                                border: 'none',
                                boxShadow: 'none',
                                '&:hover': { backgroundColor: 'transparent' },
                              }}
                            >
                              <ZoomInIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default SectorRotation;
