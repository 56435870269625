import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, IconButton, Typography, useTheme, TextField, Container, InputAdornment, CircularProgress, Grid, Paper } from "@mui/material";
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';
// import CircularProgress from '@mui/material/CircularProgress';
import { __fetching_data__ } from '../../components/api';
import ReactDOM from 'react-dom';
import { tokens } from "../../theme";
var selectedTimezone = 'Asia/Bangkok';


const s50Page = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useIsMobile();
    const isPTablet = useIsPortraitTablet();
    const isTablet = useIsTablet();
    const userDetail = useSelector((state) => state.userDetail);
    const [inputValue, setInputValue] = useState('S50');
    const base_api_url =
        process.env.NODE_ENV === "production"
            ? window.location.protocol === "https:"
                ? process.env.REACT_APP_API_HTTPS_URL
                : process.env.REACT_APP_API_HTTP_URL
            : "/application";

    const resChatBoxRef = useRef(null);

    const showLoader = () => {
        const chatbox = document.getElementById('chatbox');

        // ลบ Loader เก่าถ้ามี
        const existingLoader = document.getElementById('loading-spinner');
        if (existingLoader) {
            existingLoader.remove();
        }

        // สร้าง Container ของ Loader
        const loaderContainer = document.createElement('div');
        loaderContainer.setAttribute('id', 'loading-spinner');
        loaderContainer.style.position = 'absolute';
        loaderContainer.style.top = '0';
        loaderContainer.style.left = '0';
        loaderContainer.style.width = '100%';
        loaderContainer.style.height = '100%';
        loaderContainer.style.display = 'flex';
        loaderContainer.style.justifyContent = 'center';
        loaderContainer.style.alignItems = 'center';
        loaderContainer.style.background = 'rgba(0, 0, 0, 0.2)'; // ✅ พื้นหลังรอโหลด

        // ✅ ใช้ `CircularProgress` ของ MUI
        const loader = document.createElement('div');
        ReactDOM.render(<CircularProgress size={50} style={{ color: 'white' }} />, loader);

        loaderContainer.appendChild(loader);
        chatbox.appendChild(loaderContainer);
    };

    const hideLoader = () => {
        const loader = document.getElementById('loading-spinner');
        if (loader) {
            loader.remove();
        }
    };

    const fetchingData = async () => {
        showLoader(); // ⬅️ แสดง MUI CircularProgress

        try {
            console.log('start send message...');
            if (!inputValue.trim()) return;

            const chatbox = document.getElementById('chatbox');
            var value = { user_input: inputValue };

            const response = await __fetching_data__({
                url: `${base_api_url}/chat_bot_report`,
                method: "POST",
                body: JSON.stringify(value)
            });

            console.log('response', response);

            if (response.status) {
                window.data = response.data;
                window.bdata = response.bdata;

                if (response.data.index_data) {
                    setChartplot(response.data);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            hideLoader(); // ⬅️ ซ่อน MUI CircularProgress
        }
    };

    // fetchingData();
    useEffect(() => {
        fetchingData();
    }, []);

    function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint) {
            chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            return;
        }
        chart.clearCrosshairPosition();
    }

    function getCrosshairDataPoint(series, param) {
        if (!param.time) {
            return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
    }

    // ปรับปรุง syncCrosshair
    // function syncCrosshair(chart, series, dataPoint) {
    //     if (!dataPoint || !dataPoint.time) {
    //         chart.clearCrosshairPosition();
    //         return;
    //     }

    //     if (!series.data || !Array.isArray(series.data) || series.data.length === 0) return;

    //     chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
    // }

    // // ปรับปรุง getCrosshairDataPoint
    // function getCrosshairDataPoint(series, param) {
    //     if (!param.time || !series || !series.data) return null;

    //     let dataPoint = param.seriesData.get(series);
    //     if (!dataPoint) {
    //         console.warn("⚠️ Data point not found, searching nearest...");
    //         dataPoint = findNearestDataPoint(series, param.time);
    //     }

    //     return dataPoint || null;
    // }

    // function findNearestDataPoint(series, targetTime) {
    //     if (!series || !Array.isArray(series.data)) return null;
    //     let closestPoint = null;
    //     let minDiff = Infinity;

    //     series.data.forEach(point => {
    //         const diff = Math.abs(point.time - targetTime);
    //         if (diff < minDiff) {
    //             minDiff = diff;
    //             closestPoint = point;
    //         }
    //     });

    //     return closestPoint;
    // }

    function generateData(data, date_columm = 'date', field = 'value') {
        const timeZoneOffset = 7 * 60 * 60 * 1000;
        const cdata = data.map(d => {
            let time = timeToTz(d[date_columm], selectedTimezone)
            let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000

            return { time: time2, value: parseFloat(d[field]) || 0 }
        });
        return cdata;
    }
    function timeToTz(originalTime, timeZone) {
        const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
        const options = {
            timeZone: timeZone,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        return zonedDate.getTime() / 1000;
    }



    const setChartplot = (response) => {
        const chatbox = document.getElementById('chatbox');
        const plotData = response.index_data;
        window.plotData = plotData;
        const symbol = plotData['index'];

        // const containerWidth = '750px';
        // const containerHeight = '100%';
        const containerWidth = 'auto';
        const containerHeight = 'auto';

        // const responseContainer = document.createElement('div');


        let responseContainer = document.querySelector('.response-container');

        function syncAndFilterData(dataSets, dateKeys) {
            // 1. ตรวจสอบว่าแต่ละชุดข้อมูลมีวันที่เป็น Date Object และกำจัดค่าที่ผิดพลาด
            const processedDataSets = dataSets.map((dataset, index) =>
                dataset
                    .map(item => ({
                        ...item,
                        __parsedDate: item[dateKeys[index]] ? new Date(item[dateKeys[index]]) : null // เก็บค่า date ไว้ชั่วคราว
                    }))
                    .filter(item => item.__parsedDate instanceof Date && !isNaN(item.__parsedDate))
            );

            // 2. ดึง Set ของวันที่จากทุก dataset
            const allDates = processedDataSets.map(dataset =>
                new Set(dataset.map(item => item.__parsedDate.toISOString().split('T')[0]))
            );

            // 3. คำนวณวันที่ที่มีอยู่ในทุก dataset
            const commonDates = [...allDates.reduce((a, b) => new Set([...a].filter(date => b.has(date))))];

            // 4. กรองเฉพาะข้อมูลที่มีวันที่ตรงกัน และลบ `__parsedDate`
            const filteredDataSets = processedDataSets.map(dataset =>
                dataset
                    .filter(item => commonDates.includes(item.__parsedDate.toISOString().split('T')[0]))
                    .map(({ __parsedDate, ...rest }) => rest) // เอาคืนเฉพาะค่าเดิม
            );

            return filteredDataSets;
        }

        // 📌 ใช้งานฟังก์ชัน โดยกำหนด key ที่ถูกต้องของแต่ละชุดข้อมูล
        const data1 = plotData['s50_ind']['flow_s50'];  // ใช้ key 'time'
        const data2 = plotData['s50_ind']['mid_trend']; // ใช้ key 'date'
        const data3 = plotData['s50_ind']['mksum_s50'][0]; // ใช้ key 'time'
        const data4 = plotData['price'][0]; // ใช้ key 'time'
        const data5 = plotData['s50_scoring']; // ใช้ key 'time'
        const data6 = plotData['s50_ind']['mksum_s50'][3];

        // 🔄 ทำให้ข้อมูลทั้งหมดมี length เท่ากัน
        const [filteredData1, filteredData2, filteredData3, filteredData4, filteredData5, filteredData6] = syncAndFilterData(
            [data1, data2, data3, data4, data5, data6],
            ['time', 'date', 'time', 'time', 'time', 'time'] // กำหนดชื่อคีย์ของแต่ละชุด
        );

        // 🆕 ตัวแปรใหม่ที่สามารถใช้งานได้
        // const syncedCFUD = generateData(filteredData1, 'time', 'value');
        // const syncedMidTrend = generateData(filteredData2, 'date', 'close'); // ใช้ 'date' เหมือนเดิม
        // const syncedTrend = generateData(filteredData3, 'time', 'value');
        // const syncedPrice = generateData(filteredData4, 'time', 'value');
        // const syncedScoring = generateData(filteredData5, 'time', 'value');

        // console.log('new CFUD', syncedCFUD);
        // console.log('new MidTrend', syncedMidTrend);
        // console.log('new Trend', syncedTrend);
        // console.log('new Price', syncedPrice);
        // console.log('new soring', syncedScoring);


        // ถ้ามีแล้ว ให้ล้างข้อมูลเดิมก่อน (ไม่ต้องสร้างใหม่)
        if (responseContainer) {
            // console.log('🛠 พบกราฟอยู่แล้ว ไม่โหลดซ้ำ');
            responseContainer.innerHTML = ''; // ล้างข้อมูลเก่า
        } else {
            // ถ้ายังไม่มี ให้สร้างใหม่
            responseContainer = document.createElement('div');
        }
        if (symbol === 'SET50') {
            responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-3 gap-2 p-4');
            // responseContainer.setAttribute('id', 'sortable-grid');
            responseContainer.classList.add('response-container');
            //             responseContainer.style.display = 'grid';
            //             // responseContainer.style.gridTemplateColumns = `repeat(2, ${containerWidth})`;
            //             responseContainer.style.gridTemplateColumns = isMobile ? 'repeat(2, 1fr)'  : 'repeat(2, 1fr)';
            //             // responseContainer.style.gridTemplateColumns = `repeat(3, ${containerWidth})`;
            //             // responseContainer.style.gridTemplateRows = `repeat(3, ${containerHeight})`;
            //             responseContainer.style.gridTemplateRows = `auto auto auto`;
            //             responseContainer.style.gridTemplateAreas = `
            //      "last confirm"
            //      "trend mid"
            //      "scoring scoring"
            // `;
            //             responseContainer.style.gap = '1vh';
            responseContainer.style.display = (isMobile || isPTablet) ? 'flex' : 'grid';
            if (isMobile || isPTablet) {
                // 🟢 Mobile Mode → ใช้ Flexbox เรียงลงล่าง
                responseContainer.style.flexDirection = 'column';
                responseContainer.style.gap = '1vh';  // ระยะห่างระหว่าง items
            } else {
                // 🟢 Desktop Mode → ใช้ Grid Layout
                responseContainer.style.gridTemplateColumns = 'repeat(2, 1fr)';
                responseContainer.style.gridTemplateRows = 'auto auto auto';
                responseContainer.style.gridTemplateAreas = `
                    "last confirm"
                    "trend mid"
                `;
                responseContainer.style.gap = '1vh';
            }
        } else {
            responseContainer.setAttribute('class', 'grid grid-cols-3 grid-rows-2 gap-2 p-4');
            responseContainer.classList.add('response-container');
            responseContainer.style.display = 'grid';
            responseContainer.style.gridTemplateColumns = `repeat(2, ${containerWidth})`;
            // responseContainer.style.gridTemplateColumns = `repeat(3, 1fr)`;
            // responseContainer.style.gridTemplateColumns = `repeat(2, ${containerWidth})`;
            responseContainer.style.gridTemplateRows = `repeat(2, ${containerHeight})`;
            // responseContainer.style.gridTemplateRows = `auto auto auto`;
            //             responseContainer.style.gridTemplateAreas = `
            //     "last confirm"
            //     "trend mid"
            //     "scoring scoring"
            // `;
            responseContainer.style.gap = '1vh';
        }
        // const chartWidth = 750;
        // const chartHeight = 250;
        // const chartWidth = chatbox.offsetWidth / 3.2;
        const chartWidth = chatbox.offsetWidth / 2.1;
        const chartHeight = chatbox.offsetHeight / 3;
        const chartOption = {
            // width: chartWidth, height: chartHeight, 
            crosshair: { mode: 1, },
            timeScale: {
                visible: true,
                // rightOffset: 10
            },
            layout: {
                background: {
                    type: 'solid',
                    color: theme.palette.table.odd,
                },
                textColor: theme.palette.text.secondary,
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
            },
        }

        function chartAnnotation(chart, text) {


            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    horzAlign: 'left',
                    vertAlign: 'top',
                    color: 'rgb(1,216,149)',
                    text: text,
                },
                rightPriceScale: {
                    visible: true,
                },
                leftPriceScale: {
                    visible: true,
                    mode: PriceScaleMode.Normal,
                    autoScale: false,  // Disable automatic scaling
                    invertScale: false,
                    alignLabels: true,
                    minValue: -1,      // Set the minimum value
                    maxValue: 1,       // Set the maximum value
                },
            });



        }
        const createScoringLabel = () => {
            // สร้างกล่อง Label หลัก
            const label = document.createElement('div');
            label.innerText = 'S50 scoring';
            label.style.position = "absolute";
            label.style.top = "10px";
            label.style.right = "70px";
            label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
            label.style.color = "white";
            label.style.zIndex = '1111';
            label.style.padding = "5px 10px";
            label.style.borderRadius = "10px";
            label.style.pointerEvents = "none"; // ป้องกันการบังการคลิกบนกราฟ
            label.style.display = "flex";
            label.style.alignItems = "center";
            // label.style.gap = "8px";
            label.style.transition = "opacity 0.3s ease";

            // ข้อมูลคะแนนและสี
            const scoringData = [
                { color: '#00AA00', ml: '10px', br: '3px 0px 0px 3px' },   // เขียวเข้ม
                { color: '#88FF88', ml: '0', br: '0px' },   // เขียวอ่อน
                { color: '#FF8888', ml: '0', br: '0px' },  // แดงอ่อน
                { color: '#FF0000', ml: '0', br: '0px 3px 3px 0px' }   // แดงเข้ม
            ];

            // สร้างกล่องแต่ละคะแนน
            scoringData.forEach(({ color, ml, br }) => {
                const scoreContainer = document.createElement('div');
                scoreContainer.style.display = "flex";
                scoreContainer.style.alignItems = "center";
                // scoreContainer.style.gap = "4px";

                // สร้างกล่องสี
                const colorBox = document.createElement('div');
                colorBox.style.width = "25px";
                colorBox.style.height = "12px";
                colorBox.style.backgroundColor = color;
                colorBox.style.borderRadius = br;
                colorBox.style.marginLeft = ml;

                // สร้างข้อความแสดงค่า
                const scoreText = document.createElement('span');
                // scoreText.innerText = value;
                scoreText.style.color = "white";
                scoreText.style.fontSize = "14px";

                // นำกล่องสีและข้อความมาใส่ในกล่องรวม
                scoreContainer.appendChild(colorBox);
                scoreContainer.appendChild(scoreText);

                // เพิ่มเข้าไปใน Label หลัก
                label.appendChild(scoreContainer);
            });

            return label;
        };


        // Last & scoring Chart
        function generateIndexLast() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');

            chartIndexContainer.style.width = '100%';
            chartIndexContainer.style.height = isMobile ? '200px' : isPTablet ? '270px' : isTablet ? '320px' : '420px';
            chartIndexContainer.style.overflow = 'hidden';
            chartIndexContainer.style.borderRadius = '20px';
            chartIndexContainer.style.gridArea = "last";




            chartIndexContainer.setAttribute('id', `chart-1`);
            chartIndexContainer.style.position = 'relative';

            const label = document.createElement('div');
            label.innerText = 'Last';
            label.style.position = "absolute";
            label.style.top = "10px";
            label.style.left = "10px";
            label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
            label.style.color = "white";
            label.style.zIndex = '1111';
            label.style.padding = "5px 10px";
            label.style.borderRadius = "10px";
            label.style.pointerEvents = "none"; // ✅ ป้องกันไม่ให้ Label บังกราฟ
            // chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไปก่อนสร้างกราฟ

            label.style.transition = "opacity 0.3s ease"; // ✅ ทำให้เปลี่ยน opacity ลื่นไหล

            chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไป

            // // ✅ เมื่อ hover บน filterContainer ทำให้ Label จางลง
            // chartIndexContainer.addEventListener("mouseenter", () => {
            //     label.style.opacity = "0.1";
            // });

            // // ✅ เมื่อออกจาก hover ให้ Label กลับมาชัดเจน
            // chartIndexContainer.addEventListener("mouseleave", () => {
            //     label.style.opacity = "1";
            // });



            // เพิ่ม Label เข้าไปใน chartIndexContainer
            // const chartIndexContainer = document.getElementById('chartIndexContainer');
            const scoringLabel = createScoringLabel();
            chartIndexContainer.appendChild(scoringLabel);


            const chart = createChart(chartIndexContainer, chartOption,
                {
                    width: chartIndexContainer.offsetWidth,
                    height: chartIndexContainer.offsetHeight,
                }
            );
            // chartAnnotation(chart, 'Last')



            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    horzAlign: 'center',
                    vertAlign: 'top',
                    color: theme.palette.chart.watermark,
                    text: `${inputValue.toUpperCase()}`,
                },
            });



            const resizeObserver = new ResizeObserver(() => {
                chart.applyOptions({
                    width: chartIndexContainer.offsetWidth,
                    height: chartIndexContainer.offsetHeight
                });
            });
            resizeObserver.observe(chartIndexContainer);

            chart.applyOptions({
                rightPriceScale: {
                    visible: true,
                    // color: '#f5f5f5'
                },
                leftPriceScale: {
                    visible: true,
                    // drawTicks: false,
                    // ticksVisible: 'false',
                    scaleMargins: {
                        top: 0.9,  // ✅ ดันลงล่างสุด
                        bottom: 0,
                    },
                    overlay: true,
                    textColor: 'transparent',
                },
            });



            // let series = chart.addLineSeries({ title: '', color: '#32D74B', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            // const dataForSeries = generateData(plotData['price'][0], 'time', 'value');
            // series.setData(dataForSeries);
            // console.log(dataForSeries, 'last data')
            // let s50_series = [];

            // const scoringSeries = chart.addLineSeries({
            //     lineWidth: 5,
            //     priceScaleId: 'left',
            //     lastValueVisible: false, // ❌ ซ่อนราคาล่าสุดฝั่งซ้าย
            //     priceLineVisible: false, // ❌ ซ่อนเส้นราคาฝั่งซ้าย
            //     crossHairMarkerVisible: false,
            // });

            // const dataForScoringSeries = generateData(plotData['s50_scoring'], 'time', 'value').map((point) => ({
            //     time: point.time,
            //     value: 0, // 📌 แสดงใกล้กับ Time Scale (เลื่อนลงด้านล่าง)
            //     color:
            //         point.value === 3 ? '#00AA00' : // เขียวชัด
            //             point.value === 1 ? '#88FF88' : // เขียวจาง
            //                 point.value === -1 ? '#FF8888' : // แดงจาง
            //                     point.value === -3 ? '#FF0000' : // แดงชัด
            //                         '#FFFFFF',
            // }));

            // scoringSeries.setData(dataForScoringSeries);
            // console.log('scoring data', dataForScoringSeries)

            // scoringSeries.applyOptions({
            //     lastValueVisible: false,
            //     priceLineVisible: false,
            //     priceScaleId: 'left',
            //     crossHairMarkerVisible: false,
            //     crossHairPriceLineVisible: false,
            //     customPriceFormatter: () => '',
            // });

            let series = chart.addLineSeries({
                title: '',
                color: '#32D74B',
                lastValueVisible: true,
                priceLineVisible: false,
                priceScaleId: 'right',
                CrosshairMarkerVisible: false
            });
            // const dataForSeries = generateData(plotData['price'][0], 'time', 'value');
            const dataForSeries = generateData(filteredData4, 'time', 'value');



            const scoringSeries = chart.addLineSeries({
                lineWidth: 10,
                priceScaleId: 'left',
                lastValueVisible: false,
                priceLineVisible: false,
                crossHairMarkerVisible: false,
                crossHairPriceLineVisible: false,
                // customPriceFormatter: () => '',
            });

            const dataForScoringSeries = generateData(plotData['s50_scoring'], 'time', 'value').map((point) => ({ // เส้น S50 Scoring 
                time: point.time,
                value: 0,
                color:
                    point.value === 3 ? '#00AA00' :
                        point.value === 1 ? '#88FF88' :
                            point.value === -1 ? '#FF8888' :
                                point.value === -3 ? '#FF0000' :
                                    '#FFFFFF',
            }));

            // 🔹 กรองข้อมูลให้มี time ตรงกัน 🔹

            const getDate = (unix) => new Date(unix * 1000).toISOString().split('T')[0]; // แปลง Unix เป็น YYYY-MM-DD
            const scoringDates = new Set(dataForScoringSeries.map(d => getDate(d.time)));

            // 🔹 กรอง dataForSeries ให้เหลือแค่วันที่ตรงกัน
            const filteredPriceData = dataForSeries.filter(d => scoringDates.has(getDate(d.time)));

            // 🔹 กรอง dataForScoringSeries ให้เหลือแค่วันที่ตรงกับ filteredPriceData
            const priceDates = new Set(filteredPriceData.map(d => getDate(d.time)));
            const filteredScoringData = dataForScoringSeries.filter(d => priceDates.has(getDate(d.time)));

            // 🔍 ตรวจสอบผลลัพธ์
            // console.log('✅ commonDates:', [...priceDates]);
            // console.log('✅ filteredPriceData:', filteredPriceData);
            // console.log('✅ filteredScoringData:', filteredScoringData);

            // 🔹 อัปเดตกราฟ
            // series.setData(filteredPriceData);
            series.setData(dataForSeries);

            // console.log('last', dataForSeries)
            scoringSeries.setData(filteredScoringData);



            // chart.timeScale().fitContent();
            setTimeout(() => {
                chart.timeScale().applyOptions({ rightOffset: 5 });
            }, 100);
            console.log('Last Chart Options', chart.options());


            return [chart, [
                series,
                // scoringSeries
            ], chartIndexContainer]
        }

        // Trend Chart
        function generateIndexChartS50Last() {

            const eqColor = '#FFA23A';
            const lineColor = '#FC6238';
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');

            chartIndexContainer.style.width = '100%';
            chartIndexContainer.style.height = isMobile ? '200px' : isPTablet ? '270px' : isTablet ? '320px' : '420px';
            chartIndexContainer.style.overflow = 'hidden';
            chartIndexContainer.style.borderRadius = '20px';
            chartIndexContainer.style.gridArea = "trend";

            chartIndexContainer.setAttribute('id', `chart-3`);
            chartIndexContainer.style.position = 'relative';

            const label = document.createElement('div');
            label.innerText = 'Trend';
            label.style.position = "absolute";
            label.style.top = "10px";
            label.style.left = "10px";
            label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
            label.style.color = "white";
            label.style.zIndex = '1111';
            label.style.padding = "5px 10px";
            label.style.borderRadius = "10px";
            label.style.pointerEvents = "none"; // ✅ ป้องกันไม่ให้ Label บังกราฟ
            // chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไปก่อนสร้างกราฟ

            label.style.transition = "opacity 0.3s ease"; // ✅ ทำให้เปลี่ยน opacity ลื่นไหล

            chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไป

            // // ✅ เมื่อ hover บน filterContainer ทำให้ Label จางลง
            // chartIndexContainer.addEventListener("mouseenter", () => {
            //     label.style.opacity = "0.1";
            // });

            // // ✅ เมื่อออกจาก hover ให้ Label กลับมาชัดเจน
            // chartIndexContainer.addEventListener("mouseleave", () => {
            //     label.style.opacity = "1";
            // });

            const filterContainer = document.createElement('div');
            filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
            chartIndexContainer.appendChild(filterContainer);
            const chart = createChart(chartIndexContainer, chartOption,
                {
                    width: chartIndexContainer.offsetWidth,
                    height: chartIndexContainer.offsetHeight,
                }
            );
            chartAnnotation(chart, 'Trend') //Foreign & Individual

            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    horzAlign: 'center',
                    vertAlign: 'top',
                    color: theme.palette.chart.watermark,
                    text: `${inputValue.toUpperCase()}`,
                },
            });



            const resizeObserver = new ResizeObserver(() => {
                chart.applyOptions({
                    width: chartIndexContainer.offsetWidth,
                    height: chartIndexContainer.offsetHeight
                });
            });
            resizeObserver.observe(chartIndexContainer);

            let series = [];
            let turnoverSeries = [];
            if (plotData['s50_ind']['mksum_s50']) {
                chart.applyOptions({
                    rightPriceScale: {
                        visible: true,
                    },
                    leftPriceScale: {
                        visible: true,
                    },
                });

                const seriesForeign = chart.addLineSeries({
                    // title: 'foreign',
                    priceScaleId: 'right',
                    color: lineColor
                });
                // const seriesIndividual = chart.addLineSeries({
                //     title: 'individual',
                //     priceScaleId: 'right',
                //     color: 'red'
                // });

                // const seriesInstitution = chart.addLineSeries({ 
                //     title: 'institution',
                //     priceScaleId: 'left',
                //     color: 'purple'
                // });   

                // const foreignData = generateData(plotData['s50_ind']['mksum_s50'][0], 'time', 'value');
                const foreignData = generateData(filteredData3, 'time', 'value');
                console.log('fdata', foreignData);
                seriesForeign.setData(foreignData);

                // const individualData = generateData(plotData['s50_ind']['mksum_s50'][1], 'time', 'value');
                // seriesIndividual.setData(individualData);

                // const institutionData = generateData(plotData['s50_ind']['mksum_s50'][2], 'time', 'value');
                // seriesInstitution.setData(institutionData);

                const mksumSeries = [seriesForeign];

                mksumSeries.forEach(serie => {
                    serie.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        }
                    });
                    series.push(serie);
                });

                const turnoverForeign = chart.addLineSeries({
                    title: 'eq_foreign',
                    priceScaleId: 'right',
                    color: eqColor
                });
                // const turnoverIndividual = chart.addLineSeries({
                //     title: 'eq_individual',
                //     priceScaleId: 'right',
                //     color: 'red'
                // });

                // const turnoverInstitution = chart.addLineSeries({ 
                //     title: 'turnover_institution',
                //     priceScaleId: 'left',
                //     color: 'purple'
                // });  

                // const turnoverForeignData = generateData(plotData['s50_ind']['mksum_s50'][3], 'time', 'value'); //trend
                const turnoverForeignData = generateData(filteredData6, 'time', 'value');
                console.log('eq_data', turnoverForeignData);

                turnoverForeign.setData(turnoverForeignData); 

                // const turnoverIndividualData = generateData(plotData['s50_ind']['mksum_s50'][4], 'time', 'value');
                // turnoverIndividual.setData(turnoverIndividualData);

                // const turnoverInstitutionData = generateData(plotData['s50_ind']['mksum_s50'][5], 'time', 'value');
                // turnoverInstitution.setData(turnoverInstitutionData);

                const turnoverMksumSeries = [turnoverForeign];
                turnoverMksumSeries.forEach(serie => {
                    serie.applyOptions({
                        priceFormat: {
                            type: 'volume',
                        },
                        visible: false
                    });
                    turnoverSeries.push(serie);
                });

                const buttonContainer = document.createElement('div');
                buttonContainer.setAttribute('style', 'padding-left: 70%; position: absolute; z-index: 1;');

                const eq = hideSeriesButton(turnoverMksumSeries, 'Eq', eqColor);
                const s50 = hideSeriesButton(mksumSeries, 'S50', lineColor);

                // console.log('Trend data', foreignData)

                buttonContainer.appendChild(eq);
                buttonContainer.appendChild(s50);

                filterContainer.appendChild(buttonContainer);
            } else {

            }
            // chart.timeScale().fitContent();
            setTimeout(() => {
                chart.timeScale().applyOptions({ rightOffset: 5 });
            }, 100);
            console.log('Trend Chart Options', chart.options());

            return [chart, [...series, ...turnoverSeries], chartIndexContainer];
        }
        // Mid Trend
        function generateIndexoutstanding() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');

            chartIndexContainer.style.width = '100%';
            chartIndexContainer.style.height = isMobile ? '200px' : isPTablet ? '270px' : isTablet ? '320px' : '420px';
            chartIndexContainer.style.overflow = 'hidden';
            chartIndexContainer.style.borderRadius = '20px';
            chartIndexContainer.style.gridArea = "mid";

            chartIndexContainer.setAttribute('id', `chart-4`)
            chartIndexContainer.style.position = 'relative';

            const label = document.createElement('div');
            label.innerText = 'Mid Trend';
            label.style.position = "absolute";
            label.style.top = "10px";
            label.style.left = "10px";
            label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
            label.style.color = "white";
            label.style.zIndex = '1111';
            label.style.padding = "5px 10px";
            label.style.borderRadius = "10px";
            label.style.pointerEvents = "none"; // ✅ ป้องกันไม่ให้ Label บังกราฟ
            // chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไปก่อนสร้างกราฟ

            label.style.transition = "opacity 0.3s ease"; // ✅ ทำให้เปลี่ยน opacity ลื่นไหล

            chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไป

            // // ✅ เมื่อ hover บน filterContainer ทำให้ Label จางลง
            // chartIndexContainer.addEventListener("mouseenter", () => {
            //     label.style.opacity = "0.1";
            // });

            // // ✅ เมื่อออกจาก hover ให้ Label กลับมาชัดเจน
            // chartIndexContainer.addEventListener("mouseleave", () => {
            //     label.style.opacity = "1";
            // });

            const chart = createChart(chartIndexContainer, chartOption,
                {
                    width: chartIndexContainer.offsetWidth,
                    height: chartIndexContainer.offsetHeight,
                }
            );
            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                },

            })
            chart.priceScale('right').applyOptions({
                visible: false,
                // textColor: '#F5F5F5',

            });
            chart.priceScale('left').applyOptions({
                visible: false,
                // textColor: '#F5F5F5',


            });

            chartAnnotation(chart, 'Mid Trend')

            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    horzAlign: 'center',
                    vertAlign: 'top',
                    color: theme.palette.chart.watermark,
                    text: `${inputValue.toUpperCase()}`,
                },
            });

            const resizeObserver = new ResizeObserver(() => {
                chart.applyOptions({
                    width: chartIndexContainer.offsetWidth,
                    height: chartIndexContainer.offsetHeight
                });
            });
            resizeObserver.observe(chartIndexContainer);

            const all_series = [];
            const series = chart.addLineSeries({ title: '', color: '#FF6FFF', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
            // const dataForSeries = generateData(plotData['s50_ind']['mid_trend'], 'date', 'close');
            const dataForSeries = generateData(filteredData2, 'date', 'close');
            series.setData(dataForSeries);
            console.log('Mid Trend data', dataForSeries);
            series.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })
            all_series.push(series);
            // chart.timeScale().fitContent();
            setTimeout(() => {
                chart.timeScale().applyOptions({ rightOffset: 5 });
            }, 100);
            console.log('Mid Trends Option', chart.options());

            return [chart, all_series, chartIndexContainer]





        }

        // CF Up/down
        function generateIndexFlowS50() {
            const chartIndexContainer = document.createElement('div');
            chartIndexContainer.classList.add('chart-container');

            chartIndexContainer.style.width = '100%';
            chartIndexContainer.style.height = isMobile ? '200px' : isPTablet ? '270px' : isTablet ? '320px' : '420px';
            chartIndexContainer.style.overflow = 'hidden';
            chartIndexContainer.style.borderRadius = '20px';

            chartIndexContainer.setAttribute('id', `chart-3`)
            chartIndexContainer.style.position = 'relative';

            const label = document.createElement('div');
            label.innerText = 'Confirm Up/Down S50';
            label.style.position = "absolute";
            label.style.top = "10px";
            label.style.left = "10px";
            label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
            label.style.color = "white";
            label.style.zIndex = '1111';
            label.style.padding = "5px 10px";
            label.style.borderRadius = "10px";
            label.style.pointerEvents = "none"; // ✅ ป้องกันไม่ให้ Label บังกราฟ
            // chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไปก่อนสร้างกราฟ

            label.style.transition = "opacity 0.3s ease"; // ✅ ทำให้เปลี่ยน opacity ลื่นไหล

            chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไป

            // // ✅ เมื่อ hover บน filterContainer ทำให้ Label จางลง
            // chartIndexContainer.addEventListener("mouseenter", () => {
            //     label.style.opacity = "0.1";
            // });

            // // ✅ เมื่อออกจาก hover ให้ Label กลับมาชัดเจน
            // chartIndexContainer.addEventListener("mouseleave", () => {
            //     label.style.opacity = "1";
            // });

            const chart = createChart(chartIndexContainer, chartOption,
                {
                    width: chartIndexContainer.offsetWidth,
                    height: chartIndexContainer.offsetHeight,
                }
            );
            chart.applyOptions({
                crosshair: {
                    mode: CrosshairMode.Normal,
                    // Horizontal crosshair line (showing Price in Label)
                    horzLine: {
                        labelVisible: false
                    },
                },

            })
            chart.priceScale('right').applyOptions({
                visible: false,
                // textColor: '#F5F5F5',

            });
            chart.priceScale('left').applyOptions({
                visible: false,
                // textColor: '#F5F5F5',


            });
            chartAnnotation(chart, 'Confirm Up/Down S50')

            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 28,
                    horzAlign: 'center',
                    vertAlign: 'top',
                    color: theme.palette.chart.watermark,
                    text: `${inputValue.toUpperCase()}`,
                },
            });



            const resizeObserver = new ResizeObserver(() => {
                chart.applyOptions({
                    width: chartIndexContainer.offsetWidth,
                    height: chartIndexContainer.offsetHeight
                });
            });
            resizeObserver.observe(chartIndexContainer);

            console.log('Confirm UD S50 Options', chart.options());

            const all_series = [];
            const series = chart.addLineSeries({ title: '', color: '#FFCC00', lastValueVisible: false, priceScaleId: 'right', priceLineVisible: false, CrosshairMarkerVisible: false })
            // const dataForSeries = generateData(plotData['s50_ind']['flow_s50'], 'time', 'value');
            const dataForSeries = generateData(filteredData1, 'time', 'value');
            series.setData(dataForSeries);
            // console.log('CF U/D data', dataForSeries)
            series.applyOptions({
                priceFormat: {
                    type: 'volume',
                }
            })
            all_series.push(series);
            // chart.timeScale().fitContent();
            setTimeout(() => {
                chart.timeScale().applyOptions({ rightOffset: 5 });
            }, 100);
            return [chart, all_series, chartIndexContainer]

        }



        function hideSeriesButton(seriesList, text, color) {
            function createColorIndicator(color, isVisible) {
                const colorIndicator = document.createElement('span');
                colorIndicator.style.display = 'inline-block';
                colorIndicator.style.width = '10px';
                colorIndicator.style.height = '10px';
                // colorIndicator.style.color = theme.palette.text.primary;

                colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
                colorIndicator.style.marginRight = '5px';
                colorIndicator.style.marginLeft = '5px';

                return colorIndicator;
            }

            const button = document.createElement('button');

            // button.style.color = theme.palette.text.primary;
            button.setAttribute('class', 'btn');
            button.textContent = text;
            // background-color: ${theme.palette.background.sidebar}; 

            button.setAttribute('style', `z-index: 2; color: ${theme.palette.text.primary}; 
            background-color: rgba(0,0,0,0.3);
            padding: 4px; 
            border-radius: 5px; 
            border: none;
            box-shadow: none;
            margin-right: 5px;
            margin-top: 10px;
            `);
            button.appendChild(createColorIndicator(color, seriesList[0].options().visible));

            button.addEventListener('click', () => {
                // Toggle visibility for each series in the list
                const isVisible = !seriesList[0].options().visible;
                seriesList.forEach(series => {
                    series.applyOptions({
                        visible: isVisible,
                    });
                });
                // Update color indicator
                const colorIndicator = button.querySelector('span');
                colorIndicator.style.backgroundColor = isVisible ? color : 'transparent';
            });

            return button;
        }

        // chart 6
        // function generateS50Scoring() {
        //     const chartIndexContainer = document.createElement('div');
        //     chartIndexContainer.classList.add('chart-container');
        //     chartIndexContainer.style.width = '100%';
        //     chartIndexContainer.style.height = isMobile ? '200px' : isPTablet ? '270px' : isTablet ? '320px' : '420px';
        //     chartIndexContainer.style.overflow = 'hidden';
        //     chartIndexContainer.style.borderRadius = '20px';
        //     chartIndexContainer.style.gridArea = "scoring";

        //     chartIndexContainer.setAttribute('id', `chart-3`)
        //     chartIndexContainer.style.position = 'relative';

        //     const label = document.createElement('div');
        //     label.innerText = 'S50 Scoring';
        //     label.style.position = "absolute";
        //     label.style.top = "10px";
        //     label.style.left = "10px";
        //     label.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
        //     label.style.color = "white";
        //     label.style.zIndex = '1111';
        //     label.style.padding = "5px 10px";
        //     label.style.borderRadius = "10px";
        //     label.style.pointerEvents = "none"; // ✅ ป้องกันไม่ให้ Label บังกราฟ
        //     // chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไปก่อนสร้างกราฟ

        //     label.style.transition = "opacity 0.3s ease"; // ✅ ทำให้เปลี่ยน opacity ลื่นไหล

        //     chartIndexContainer.appendChild(label); // 📌 เพิ่ม Label เข้าไป

        //     // ✅ เมื่อ hover บน filterContainer ทำให้ Label จางลง
        //     chartIndexContainer.addEventListener("mouseenter", () => {
        //         label.style.opacity = "0.1";
        //     });

        //     // ✅ เมื่อออกจาก hover ให้ Label กลับมาชัดเจน
        //     chartIndexContainer.addEventListener("mouseleave", () => {
        //         label.style.opacity = "1";
        //     });
        //     const chart = createChart(chartIndexContainer,
        //         {
        //             width: chartIndexContainer.offsetWidth,
        //             height: chartIndexContainer.offsetHeight,
        //             crosshair: { mode: 1, },
        //             timeScale: {
        //                 visible: true,
        //                 // rightOffset: 10 
        //             },
        //             layout: {
        //                 background: { type: 'solid', color: theme.palette.table.odd },
        //                 textColor: theme.palette.text.secondary
        //             },
        //             grid: {
        //                 vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
        //                 horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
        //             },
        //         });
        //     chart.applyOptions({
        //         crosshair: {
        //             mode: CrosshairMode.Normal,
        //             // Horizontal crosshair line (showing Price in Label)
        //             horzLine: {
        //                 labelVisible: false
        //             },
        //         },

        //     })
        //     chart.applyOptions({
        //         // rightPriceScale: {
        //         //     visible: true,
        //         // },
        //         leftPriceScale: {
        //             visible: true,
        //         },
        //     });
        //     // chart.priceScale('right').applyOptions({
        //     //     visible: false,
        //     //     textColor: '#F5F5F5',

        //     // });
        //     // chart.priceScale('left').applyOptions({
        //     //     visible: false,
        //     //     textColor: '#F5F5F5',


        //     // });
        //     chartAnnotation(chart, 'S50 Scoring')

        //     chart.applyOptions({
        //         watermark: {
        //             visible: true,
        //             fontSize: 28,
        //             horzAlign: 'center',
        //             vertAlign: 'top',
        //             color: theme.palette.chart.watermark,
        //             text: `${inputValue.toUpperCase()}`,
        //         },
        //     });

        //     const resizeObserver = new ResizeObserver(() => {
        //         chart.applyOptions({
        //             width: chartIndexContainer.offsetWidth,
        //             height: chartIndexContainer.offsetHeight
        //         });
        //     });
        //     resizeObserver.observe(chartIndexContainer);

        //     const all_series = [];
        //     const series = chart.addLineSeries({ title: '', color: '#00FFFF', lastValueVisible: false, priceScaleId: 'right', priceLineVisible: false, CrosshairMarkerVisible: false })
        //     // console.log('s50 scoring', plotData['s50_scoring'])
        //     const dataForSeries = generateData(plotData['s50_scoring'], 'time', 'value');
        //     series.setData(dataForSeries);
        //     series.applyOptions({
        //         priceFormat: {
        //             type: 'volume',
        //         }
        //     })
        //     all_series.push(series);

        //     // chart.timeScale().fitContent();
        //     chart.timeScale().applyOptions({
        //         rightOffset: 10
        //     });
        //     console.log(chart.options());

        //     return [chart, all_series, chartIndexContainer]
        // }

        // function generateS50Scoring() {
        //     const chartIndexContainer = document.createElement('div');
        //     chartIndexContainer.classList.add('chart-container');
        //     chartIndexContainer.style.width = '100%';
        //     chartIndexContainer.style.height = '40px';
        //     chartIndexContainer.style.overflow = 'hidden';
        //     chartIndexContainer.style.borderRadius = '10px';
        //     chartIndexContainer.style.gridArea = "last";
        //     chartIndexContainer.setAttribute('id', `chart-3`);
        //     // chartIndexContainer.style.position = 'absolute';
        //     chartIndexContainer.style.zIndex = '111';
        //     chartIndexContainer.style.marginTop = '350px';
        //     // chartIndexContainer.style.marginRight = '30px';

        //     // chartIndexContainer.style.bottom = '0'
        //     // chartIndexContainer.style.left = '0';

        //     chartIndexContainer.style.position = 'relative';

        //     const chart = createChart(chartIndexContainer, {
        //         width: chartIndexContainer.offsetWidth,
        //         height: chartIndexContainer.offsetHeight,
        //         // crosshair: { mode: CrosshairMode.Normal },
        //         timeScale: { visible: false },
        //         layout: {
        //             background: { type: 'solid', color: 'transparent' },
        //             // textColor: theme.palette.text.secondary
        //             textColor: 'transparent'
        //         },
        //         grid: {
        //             // vertLines: { color: theme.palette.neutral.dark },
        //             vertLines: { color: 'transparent' },
        //             // horzLines: { color: theme.palette.neutral.dark },
        //             horzLines: { color: 'transparent' },
        //         },
        //     });

        //     chart.applyOptions({
        //         crosshair: {
        //             mode: CrosshairMode.Normal,
        //             horzLine: { labelVisible: false },
        //             vertLine: { labelVisible: false },
        //         },
        //         leftPriceScale: { visible: true },
        //         rightPriceScale: {
        //             visible: true,
        //             textColor: 'transparent', // ทำให้ข้อความราคามองไม่เห็น
        //             borderVisible: false, // ซ่อนเส้นขอบของ Price Scale
        //         },
        //         watermark: { visible: false } // 🔥 ลบ watermark ออก
        //     });

        //     // chartAnnotation(chart, 'S50 Scoring');

        //     const resizeObserver = new ResizeObserver(() => {
        //         chart.applyOptions({
        //             width: chartIndexContainer.offsetWidth,
        //             height: chartIndexContainer.offsetHeight
        //         });
        //     });
        //     resizeObserver.observe(chartIndexContainer);

        //     const all_series = [];
        //     const series = chart.addLineSeries({
        //         lineWidth: 10,
        //         priceLineVisible: false, // ปิดการแสดงราคาของเส้น series
        //         lastValueVisible: false, // ปิดการแสดงราคาสุดท้ายของ series
        //         CrosshairMarkerVisible: false,
        //     });

        //     // ✅ เตรียมข้อมูลและกำหนดสีตามเงื่อนไข
        //     // const dataForSeries = generateData(plotData['s50_scoring'], 'time', 'value').map((point) => ({
        //     //     time: point.time,
        //     //     value: 0,
        //     //     color:
        //     //         point.value === 3 ? '#00AA00' : // เขียวชัด
        //     //             point.value === 1 ? '#88FF88' : // เขียวจาง
        //     //                 point.value === -1 ? '#FF8888' : // แดงจาง
        //     //                     point.value === -3 ? '#AA0000' : // แดงชัด
        //     //                         '#FFFFFF', // ค่าอื่น ๆ (ถ้ามี)
        //     // }));

        //     // series.setData(dataForSeries);

        //     const dataLastSeries = generateData(filteredData4, 'time', 'value');

        //     // เตรียมข้อมูลและกำหนดสีตามเงื่อนไข
        //     const dataForScoringSeries = generateData(plotData['s50_scoring'], 'time', 'value').map((point) => ({
        //         time: point.time,
        //         value: 0,
        //         color:
        //             point.value === 3 ? '#00AA00' : // เขียวชัด
        //                 point.value === 1 ? '#88FF88' : // เขียวจาง
        //                     point.value === -1 ? '#FF8888' : // แดงจาง
        //                         point.value === -3 ? '#AA0000' : // แดงชัด
        //                             '#FFFFFF', // ค่าอื่น ๆ (ถ้ามี)
        //     }));

        //     // ฟังก์ชันแปลง Unix เป็น YYYY-MM-DD
        //     const getDate = (unix) => new Date(unix * 1000).toISOString().split('T')[0];

        //     // สร้าง Set ของวันที่จาก dataForScoringSeries
        //     const scoringDates = new Set(dataForScoringSeries.map(d => getDate(d.time)));

        //     // 🔹 กรอง dataLastSeries ให้เหลือแค่วันที่ตรงกับ scoringDates
        //     const filteredPriceData = dataLastSeries.filter(d => scoringDates.has(getDate(d.time)));

        //     // 🔹 กรอง dataForScoringSeries ให้เหลือแค่วันที่ตรงกับ filteredPriceData
        //     const priceDates = new Set(filteredPriceData.map(d => getDate(d.time)));
        //     const filteredScoringData = dataForScoringSeries.filter(d => priceDates.has(getDate(d.time)));

        //     series.setData(filteredScoringData);

        //     series.applyOptions({
        //         priceFormat: {
        //             type: 'custom',
        //             formatter: value => {
        //                 const absoluteValue = Math.abs(value).toFixed(2); // แปลงค่าลบให้เป็นบวกและจัดรูปแบบทศนิยมให้เป็น 2 หลัก
        //                 return `${absoluteValue.padStart(6, '0')}`; // ใส่เลข 0 ด้านหน้าให้มี 6 หลัก
        //             },
        //         },
        //         // crossHairMarkerVisible: true,
        //         // crossHairMarkerBorderColor: 'black', // สีขอบของ marker
        //         // crossHairMarkerRadius: 6,
        //         // crossHairMarkerBackgroundColor: 'white',
        //     });

        //     all_series.push(series);

        //     setTimeout(() => {
        //         chart.timeScale().applyOptions({ rightOffset: 5 });
        //     }, 100);

        //     console.log('S50 scoring', chart.options());

        //     return [chart, all_series, chartIndexContainer];
        // }



        //BidAsk


        // function generateIndexPeak() {
        //     const chartIndexContainer = document.createElement('div');
        //     chartIndexContainer.classList.add('chart-container');
        //     chartIndexContainer.setAttribute('id', `chart-7`)

        //     const filterContainer = document.createElement('div');
        //     filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index: 3;');
        //     chartIndexContainer.appendChild(filterContainer);

        //     const chart = createChart(chartIndexContainer, chartOption);
        //     chartAnnotation(chart, 'Peak');

        //     const series_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 2, title: 'eq_bid', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' });
        //     const series_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 2, title: 'eq_ask', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' })
        //     const series_3 = chart.addLineSeries({ color: 'black', lineStyle: 0, lineWidth: 2, title: 'eq_sum', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'right' })

        //     const series = [series_1, series_2, series_3];
        //     series.forEach((seri, index) => {
        //         const dataForSeries = generateData(plotData['swap'][index], 'time', 'value');
        //         seri.setData(dataForSeries);

        //     })
        //     let s50_series = [];
        //     if (plotData['s50_ind']['swap_s50']) {
        //         series.forEach(seri => {
        //             seri.applyOptions({
        //                 visible: false
        //             })
        //         })

        //         const s50_series_1 = chart.addLineSeries({ color: 'red', lineStyle: 0, lineWidth: 2, title: 's50_bid', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });
        //         const s50_series_2 = chart.addLineSeries({ color: 'green', lineStyle: 0, lineWidth: 2, title: 's50_ask', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });
        //         const s50_series_3 = chart.addLineSeries({ color: 'black', lineStyle: 0, lineWidth: 2, title: 's50_sum', lastValueVisible: false, priceLineVisible: false, priceScaleId: 'left' });

        //         const swap_series = [s50_series_1, s50_series_2, s50_series_3];
        //         swap_series.forEach((seri, index) => {
        //             const dataForSeries = generateData(plotData['s50_ind']['swap_s50'][index], 'time', 'value');
        //             seri.setData(dataForSeries);
        //             s50_series.push(seri);
        //         });
        //         const buttonContainer = document.createElement('div');
        //         buttonContainer.setAttribute('style', 'padding-left: 80%; position: absolute; z-index: 1;');

        //         const eq = hideSeriesButton(series, 'Eq', '#FF4D02')
        //         const s50 = hideSeriesButton(s50_series, 'S50', '#FF4D02')

        //         buttonContainer.appendChild(eq);
        //         buttonContainer.appendChild(s50);

        //         filterContainer.appendChild(buttonContainer);
        //     } else {

        //     }
        //     return [chart, [...series, ...s50_series], chartIndexContainer];
        // }

        const [chart0, series0, chartIndexContainer0] = generateIndexLast(); //Last 
        const [chart4, series4, chartIndexContainer4] = generateIndexoutstanding(); //Mid Trend
        // const [chart8, series8, charIndexContainer8] = generateS50Scoring(); //s50 scoring

        // console.log('series Last', series0)


        // console.log('Last Chart:', { chart0, series0, chartIndexContainer0 });
        // console.log('Mid Trend:', { chart4, series4, chartIndexContainer4 });


        let allSeries, charts, allIndexChartContainers, allIndexGenerators;

        if (symbol === 'SET50') {
            const [chart2, series2, chartIndexContainer2] = generateIndexFlowS50(); // Confrim Up/Down S50 
            const [chart3, series3, chartIndexContainer3] = generateIndexChartS50Last(); // Trend


            // console.log('CF U/D:', { chart2, series2, chartIndexContainer2 });
            // console.log('Trend:', { chart3, series3, chartIndexContainer3 });


            // allSeries = [series0, series2, series3, series4, series8];
            allSeries = [series0, series2, series3, series4];
            console.log(series2, 'series 2')
            // charts = [chart0, chart2, chart3, chart4, chart8];
            charts = [chart0, chart2, chart3, chart4];
            // allIndexChartContainers = [chartIndexContainer0, chartIndexContainer2, chartIndexContainer3, chartIndexContainer4, charIndexContainer8];
            allIndexChartContainers = [chartIndexContainer0, chartIndexContainer2, chartIndexContainer3, chartIndexContainer4];
            // allIndexGenerators = [generateIndexLast, generateIndexFlowS50, generateIndexChartS50Last, generateIndexoutstanding, generateS50Scoring];
            allIndexGenerators = [generateIndexLast, generateIndexFlowS50, generateIndexChartS50Last, generateIndexoutstanding];

        }


        // let isSyncingZoom = false; // ป้องกัน loop ในการซูม
        // let isSyncingCrosshair = false; // ป้องกัน loop ในการ sync crosshair

        // // 🔄 Sync Zoom ระหว่างทุกกราฟ
        // charts.forEach((currentChart, currentIndex) => {
        //     currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        //         if (!timeRange || isSyncingZoom) return;
        //         isSyncingZoom = true; // ป้องกัน loop

        //         charts.forEach((otherChart, otherIndex) => {
        //             if (currentIndex !== otherIndex) {
        //                 otherChart.timeScale().setVisibleLogicalRange(timeRange);
        //             }
        //         });

        //         isSyncingZoom = false;
        //     });
        // });

        // // 🔄 Sync Crosshair ระหว่างทุกกราฟ
        // charts.forEach((currentChart, currentIndex) => {
        //     currentChart.subscribeCrosshairMove(param => {
        //         if (isSyncingCrosshair || !param || !param.time) return;
        //         isSyncingCrosshair = true; // ป้องกัน loop

        //         const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);
        //         if (!dataPoint) return; // ตรวจสอบว่า dataPoint มีค่าจริงก่อน sync

        //         charts.forEach((otherChart, otherIndex) => {
        //             if (currentIndex !== otherIndex) {
        //                 allSeries[otherIndex].forEach(otherSeries => {
        //                     syncCrosshair(otherChart, otherSeries, dataPoint);
        //                 });
        //             }
        //         });

        //         isSyncingCrosshair = false;
        //     });
        // });

        let isSyncingZoom = false;
        let isSyncingCrosshair = false;
        let sharedPixelOffset = 0;

        // 🔄 Sync Zoom ระหว่างทุกกราฟ
        charts.forEach((currentChart, currentIndex) => {
            const timeScale = currentChart.timeScale();

            timeScale.subscribeVisibleLogicalRangeChange(timeRange => {
                if (!timeRange || isSyncingZoom) return;
                isSyncingZoom = true;

                // ✅ ใช้ getVisibleLogicalRange() แทน logicalRange()
                const logicalRange = timeScale.getVisibleLogicalRange();
                if (!logicalRange) {
                    isSyncingZoom = false;
                    return;
                }

                const logicalWidth = logicalRange.to - logicalRange.from;
                const pixelOffset = (timeScale.options().rightOffset || 0) * timeScale.width() / logicalWidth;
                sharedPixelOffset = Math.max(sharedPixelOffset, pixelOffset); // หา offset สูงสุด

                charts.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        const otherLogicalRange = otherChart.timeScale().getVisibleLogicalRange();
                        if (!otherLogicalRange) return;

                        const otherLogicalWidth = otherLogicalRange.to - otherLogicalRange.from;
                        const newRightOffset = sharedPixelOffset * otherLogicalWidth / otherChart.timeScale().width();

                        otherChart.timeScale().applyOptions({ rightOffset: newRightOffset });
                        otherChart.timeScale().setVisibleLogicalRange(timeRange);
                    }
                });

                isSyncingZoom = false;
            });
        });


        // 🔄 Sync Crosshair ระหว่างทุกกราฟ
        charts.forEach((currentChart, currentIndex) => {
            currentChart.subscribeCrosshairMove(param => {
                if (isSyncingCrosshair || !param || !param.time) return;
                isSyncingCrosshair = true;

                const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);
                if (!dataPoint) return;

                charts.forEach((otherChart, otherIndex) => {
                    if (currentIndex !== otherIndex) {
                        allSeries[otherIndex].forEach(otherSeries => {
                            syncCrosshair(otherChart, otherSeries, dataPoint);
                        });
                    }
                });

                isSyncingCrosshair = false;
            });
        });





        let isTimeScaleSyncing = false;
        let isCrosshairSyncing = false;

        // let sharedRightOffset = 5; // ค่าเริ่มต้นที่ใช้ร่วมกัน

        // charts.forEach((currentChart, currentIndex) => {
        //     currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
        //         if (isTimeScaleSyncing) return;
        //         isTimeScaleSyncing = true;

        //         // sharedRightOffset = currentChart.timeScale().options().rightOffset || sharedRightOffset;

        //         charts.forEach((otherChart, otherIndex) => {
        //             if (currentIndex !== otherIndex) {
        //                 // otherChart.timeScale().applyOptions({ rightOffset: sharedRightOffset });
        //                 setRightOffsetInPixels(otherChart, 50);
        //                 otherChart.timeScale().setVisibleLogicalRange(timeRange);
        //             }
        //         });

        //         isTimeScaleSyncing = false;
        //     });

        //     currentChart.timeScale().subscribeVisibleTimeRangeChange(timeRange => {
        //         if (isTimeScaleSyncing) return;
        //         isTimeScaleSyncing = true;

        //         charts.forEach((otherChart, otherIndex) => {
        //             if (currentIndex !== otherIndex) {
        //                 otherChart.timeScale().setVisibleLogicalRange(timeRange);
        //             }
        //         });

        //         isTimeScaleSyncing = false;
        //     });
        // });



        // charts.forEach((currentChart, currentIndex) => {
        //     currentChart.subscribeCrosshairMove(param => {
        //         if (isCrosshairSyncing) return;
        //         isCrosshairSyncing = true;

        //         const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);

        //         charts.forEach((otherChart, otherIndex) => {
        //             if (currentIndex !== otherIndex) {
        //                 allSeries[otherIndex].forEach(otherSeries => {
        //                     syncCrosshair(otherChart, otherSeries, dataPoint);
        //                 });
        //             }
        //         });

        //         isCrosshairSyncing = false;
        //     });
        // });

        // let isTimeScaleSyncing = false;
        // let isCrosshairSyncing = false;

        // ซิงค์ TimeScale ทุกกราฟ


        // charts.forEach((currentChart, currentIndex) => {
        //     currentChart.timeScale().subscribeVisibleTimeRangeChange(timeRange => {
        //         if (isTimeScaleSyncing) return;
        //         isTimeScaleSyncing = true;

        //         charts.forEach((otherChart, otherIndex) => {
        //             if (currentIndex !== otherIndex) {
        //                 const timeScale = otherChart.timeScale();
        //                 if (timeScale) {
        //                     // ใช้ logical range เพื่อให้ sync กันเป๊ะ
        //                     const logicalRange = timeScale.getVisibleLogicalRange();
        //                     if (logicalRange) {
        //                         timeScale.setVisibleLogicalRange(logicalRange);
        //                     }
        //                 }
        //             }
        //         });

        //         isTimeScaleSyncing = false;
        //     });
        // });

        // ซิงค์ Crosshair ทุกกราฟ
        // charts.forEach((currentChart, currentIndex) => {
        //     currentChart.subscribeCrosshairMove(param => {
        //         if (isCrosshairSyncing) return;
        //         isCrosshairSyncing = true;

        //         let dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);
        //         if (!dataPoint) {
        //             console.warn(`⚠️ Crosshair data not found, searching nearest point for chart ${currentIndex}`);
        //             dataPoint = findNearestDataPoint(allSeries[currentIndex][0], param.time);
        //         }

        //         if (dataPoint) {
        //             charts.forEach((otherChart, otherIndex) => {
        //                 if (currentIndex !== otherIndex) {
        //                     allSeries[otherIndex].forEach(otherSeries => {
        //                         syncCrosshair(otherChart, otherSeries, dataPoint);
        //                     });
        //                 }
        //             });
        //         }

        //         isCrosshairSyncing = false;
        //     });
        // });

        // charts.forEach((currentChart, currentIndex) => {
        //     currentChart.subscribeCrosshairMove(param => {
        //         if (!param || !param.time) return; // ป้องกัน error ถ้า param ว่าง

        //         console.log(`📌 Crosshair Time:`, param.time);
        //         console.log(`📌 Series Data:`, param.seriesData);

        //         const series = allSeries[currentIndex][0]; // ดึง series ที่ต้องใช้
        //         const dataPoint = param.seriesData.get(series);

        //         console.log(`📌 Data Point:`, dataPoint); // ดูว่ามีค่าหรือไม่

        //         if (!dataPoint) {
        //             console.warn(`⚠️ Crosshair data not found, searching nearest point for chart ${currentIndex}`);
        //         }
        //     });
        // });








        function renderChartInNewWindow(chartIndexContainer, generateIndexChart) {
            // Specify window dimensions and position
            const windowWidth = 800;
            const windowHeight = 600;
            // const windowWidth = 900;
            // const windowHeight = 700;
            const windowLeft = window.screen.width / 2 - windowWidth / 2;
            const windowTop = window.screen.height / 2 - windowHeight / 2;

            // Open a new browser window
            const newWindow = window.open('', 'Chart Window', `width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`);

            // Write the basic HTML structure
            newWindow.document.write('<html><head><title>Chart Popup</title></head><body></body></html>');

            // Create a div container
            const container = newWindow.document.createElement('div');
            container.classList.add('grid', 'grid-cols-[1fr,3fr]', 'grid-rows-1', 'gap-2');
            newWindow.document.body.appendChild(container);

            // Add Tailwind CSS
            const tailwindLink = newWindow.document.createElement('link');
            tailwindLink.href = 'https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css';
            tailwindLink.rel = 'stylesheet';
            newWindow.document.head.appendChild(tailwindLink);

            // Append the chart container to the new window
            const nameContainer = newWindow.document.createElement('div');
            const symbolName = newWindow.document.createElement('h2');
            symbolName.textContent = symbol; // Ensure 'symbol' is defined
            nameContainer.appendChild(symbolName);
            container.appendChild(nameContainer);
            container.appendChild(chartIndexContainer);
            ;

            // Ensure that the chart and series are interactive after scripts are loaded
            newWindow.addEventListener('DOMContentLoaded', () => {
                // Reinitialize or modify the chart/series in the new window
                const [newChart, newSeries, newChartContainer] = generateIndexChart();
                container.innerHTML = ''; // Clear previous content
                container.appendChild(newChartContainer);
            });
        }
        function addRenderButton(generateIndexChart, chartIndexContainer) {
            const [chart, series, container] = generateIndexChart();
            document.querySelectorAll('.newtabButton').forEach(button => {
                button.removeEventListener('click', () => renderChartInNewWindow(container, generateIndexChart));
            });
            const renderButton = document.createElement('button');
            renderButton.setAttribute('class', 'btn');
            renderButton.setAttribute('style', 'z-index:50; position:absolute;');

            renderButton.classList.add("newtabButton");
            renderButton.textContent = '🔎';
            // renderButton.addEventListener('click', () => renderChartInNewWindow(container, generateIndexChart));

            // const renderButtonContainer = document.createElement('div');
            // renderButtonContainer.setAttribute('style','z-index:999; position:relative;')
            // renderButtonContainer.appendChild(renderButton);
            // chartIndexContainer.insertBefore(renderButtonContainer, chartIndexContainer.firstChild);

            renderButton.addEventListener('click', clickHandler);

            const renderButtonContainer = document.createElement('div');
            renderButtonContainer.setAttribute('style', 'z-index:999; position:relative;')
            renderButtonContainer.appendChild(renderButton);
            chartIndexContainer.insertBefore(renderButtonContainer, chartIndexContainer.firstChild);

            function clickHandler() {
                renderChartInNewWindow(container, generateIndexChart, symbol);
            }
        }



        allIndexChartContainers.forEach((container, index) => {
            // addRenderButton(allIndexGenerators[index], container);
            responseContainer.appendChild(container);
        });

        chatbox.appendChild(responseContainer);
    }
    return (

        <>
            <style>
                {`
      .toggleButton {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -90%;
        right: -45%;
        width: 30%;
        z-index: 90000000 !important;
    }

    .header-text {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -93%;
        right: -16%;
        width: 30%;
        z-index: 9000000000 !important;
    }

    .divHeader {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -100%; 
        // top: -90%;
        left: 11%;
        // left: 16%;
        width: 80%;
        display: flex;
        justify-content: space-between;
        z-index: 90000000 !important;
    }

    .toggleButton2 {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -90%;
        right: -45%;
        width: 30%;
        z-index: 90000000 !important;
    }

    .toggleButton246 {
        position: relative; /* Make sure it's the desired position      transform: translate(-50%, -500%);*/
        top: -90%;
        right: -52%;
        width: 28%;
        z-index: 90000000 !important;
    }   
    `}
            </style>
            <Container
                style={{
                    marginTop: -5,
                    width: '100%',
                    height: 'calc(100vh - var(--top-bar-height))',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 0,
                    maxWidth: '25000px'
                }}>
                <Paper
                    elevation={3}
                    style={{
                        padding: 10,
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        maxWidth: '25000px',
                        flexDirection: 'column', // จัดเรียงข้อความในแนวตั้ง
                        justifyContent: 'center',  // จัดให้อยู่ตรงกลางแนวตั้ง
                        alignItems: 'center',      // จัดให้อยู่ตรงกลางแนวนอน
                        textAlign: 'center',       // ทำให้ข้อความอยู่กึ่งกลาง
                        backgroundColor: 'rgba(32, 36, 49, 1)',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                    }}>
                    <div
                        id="resChatBox"
                        ref={resChatBoxRef}
                        style={{
                            // position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            overflowY: 'auto',
                            padding: '20px',
                            // backgroundColor: 'red',
                            maxWidth: '25000px',

                        }}>
                        <div
                            id="chatbox"
                            style={{
                                width: '100%',
                                height: '100%',
                                maxHeight: '883px',
                            }}>
                            {/* {messages.map((message, index) => (
                                <div key={index} style={{ textAlign: message.isBot ? 'left' : 'right' }}> */}

                        </div>
                    </div>


                </Paper>
            </Container>
        </>
    );
};


export default s50Page;