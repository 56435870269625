import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { createChart, CrosshairMode, PriceScaleMode } from 'lightweight-charts';

// const LightweightChart = ({ options, seriesOptions, title }) => {
const LightweightChartv1 = forwardRef(({ options, seriesOptions, title }, ref) => {
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);
    const seriesRefs = useRef([]);

    useEffect(() => {
        const chart = createChart(chartContainerRef.current, options);
        chartRef.current = chart;

        // chartAnnotation(chart, title);
        seriesOptions.forEach((seriesOption, index) => {
            const lineSeries = chart.addLineSeries(seriesOption.options);
            lineSeries.setData(seriesOption.data);
            seriesRefs.current[index] = lineSeries;

            lineSeries.applyOptions({
                priceFormat: {
                    type: 'custom',
                    formatter: (price) => {
                        let shortenedPrice = price / 1_000_000_000;
                        return shortenedPrice.toLocaleString('en-US', {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                        }) + 'B';

                    },
                },

            });

            // if (seriesOption.data.length <= 1) {
            //     chart.applyOptions({
            //         watermark: {
            //             visible: true,
            //             fontSize: 14,
            //             horzAlign: 'center',
            //             vertAlign: 'center',
            //             color: 'grey',
            //             text: `${title}:None`,
            //         },
            //     });
            // } else {
            //     chart.applyOptions({
            //         watermark: {
            //             visible: true,
            //             fontSize: options.fontSize || 32,
            //             horzAlign: 'center',
            //             vertAlign: 'center',
            //             color: 'rgba(0, 0, 0, 0.1)',
            //             text: `${title}`,
            //         },
            //     });
            // }
            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize:  40,
                    horzAlign: 'center',
                    vertAlign: 'top',
                    color: 'rgba(255, 255, 255, 0.5)',
                    text: `${title}`,
                },
            });

        });

        // chart.subscribeCrosshairMove((param) => {
        //     if (param) {
        //         const series = seriesRefs.current[0];  // หรือเลือก series ที่คุณต้องการ
        //         const dataPoint = getCrosshairDataPoint(series, param);
        //         if (dataPoint) {
        //             onCrosshairMove(dataPoint);
        //         }
        //     }
        // });

        // chart.applyOptions({
        //     timeScale: {
        //         tickMarkFormatter: (time, tickMarkType) => {
        //             let date;
        //             if (typeof time === 'string') {
        //                 date = new Date(time); // ถ้าเป็น ISO string เช่น '2024-07-10T14:30:00Z'
        //             } else if (typeof time === 'number') {
        //                 date = new Date(time * 1000); // ถ้าเป็น Unix timestamp (หน่วยวินาที)
        //             } else {
        //                 return 'Invalid Time';
        //             }
        //             const now = new Date();

        //             const showYear = date.getFullYear() !== now.getFullYear();
        //             const year2Digit = String(date.getFullYear()).slice(-2);

        //             return showYear
        //                 ? `${date.getDate()} ${date.toLocaleString('en-GB', { month: 'short' })} '${year2Digit}`
        //                 : `${date.getDate()} ${date.toLocaleString('en-GB', { month: 'short' })}`;

        //             // const options = {
        //             //     // day: '2-digit',
        //             //     month: '2-digit',  // 'Jan', 'Feb', ...
        //             //     year: '2-digit', // '25' แทน 2025
        //             //     // hour: '2-digit',
        //             //     // minute: '2-digit',
        //             //     // second: '2-digit'
        //             // };
        //             // return date.toLocaleString('en-GB', options).replace(',', '');
        //         }
        //     }
        // });

        return () => {
            chart.remove();
        };
    }, [options, seriesOptions]);

    // useEffect(() => {
    //     if (ref) {
    //         ref.current = seriesRefs.current;
    //         
    //     }
    // }, [ref]);
    // useImperativeHandle(ref, () => ({
    //     getSeriesRefs: () => seriesRefs.current,
    // }));
    useImperativeHandle(ref, () => ({
        getChartRef: () => chartRef.current, // ส่ง ref ของ chart
        getSeriesRefs: () => seriesRefs.current, // ส่ง ref ของ series
    }));



    return <div ref={chartContainerRef} style={{ width: '100%', height: '300px' }} />;
    // };
});

function chartAnnotation(chart, text) {
    chart.applyOptions({
        watermark: {
            visible: true,
            fontSize: 12,
            horzAlign: 'left',
            vertAlign: 'top',
            color: 'rgb(1,216,149)',
            text: text,
        },
        rightPriceScale: {
            visible: true,
        },
        // leftPriceScale: {
        //     visible: true,
        //     mode: PriceScaleMode.Normal,
        //     autoScale: false,  // Disable automatic scaling
        //     invertScale: false,
        //     alignLabels: true,
        //     minValue: -1,      // Set the minimum value
        //     maxValue: 1,       // Set the maximum value
        // },
    });



}

export default LightweightChartv1;
