import React, { useEffect, useState, useRef } from "react";
import ChatbotChartCell from "./ChatbotChartCell";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Tooltip, Dialog, TableContainer, IconButton } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';

// A very simple CSV parser that splits on newlines and commas.
function parseCSV(csvText) {
  const lines = csvText.split("\n");
  return lines
    .map(line => line.split(",").map(cell => cell.trim()))
    .filter(row => row.some(cell => cell !== ""));
}

// Parse a date string "YYYY-MM-DD" into a Date object.
function parseYYYYMMDD(dateStr) {
  const parts = dateStr.split("-");
  if (parts.length !== 3) {
    // console.log("parseYYYYMMDD: invalid format =>", dateStr);
    return null;
  }
  const [yyyy, mm, dd] = parts;
  const year = parseInt(yyyy, 10);
  const month = parseInt(mm, 10) - 1;
  const day = parseInt(dd, 10);
  const dateObj = new Date(year, month, day);
  if (isNaN(dateObj.getTime())) {
    // console.log("parseYYYYMMDD: can't parse =>", dateStr);
    return null;
  }
  return dateObj;
}

// Format a Date object as "YYYY-MM-DD"
function formatAsYYYYMMDD(dateObj) {
  if (!(dateObj instanceof Date)) return "";
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function App() {
  // Unfiltered data
  const [originalChunkData, setOriginalChunkData] = useState([]);
  const [originalDateLabels, setOriginalDateLabels] = useState([]);

  const [tableVisibility, setTableVisibility] = useState({});

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();

  // Filtered data (what we actually show)
  const [chunkData, setChunkData] = useState([]);
  const [dateLabels, setDateLabels] = useState([]);

  // Inputs
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    return formatAsYYYYMMDD(today);
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Flag to hold table/chart creation until filter is applied
  const [displayTable, setDisplayTable] = useState(false);

  // Create a cache for filter results
  const filterCache = useRef({});

  const csrfToken = useSelector((state) => state.csrfToken);

  // CSV fetching encapsulated in a function
  async function fetchCSVData() {
    try {
      // const resp = await fetch("http://172.17.1.85:3001/sheet");
      const resp = await fetch(`${window.config.base_api_url}/api/outshort/sheet`, {
        headers: {
          'Content-Type': 'text/csv',
          'xsrf-token': csrfToken,
        },
      });

      const csvText = await resp.text();
      // console.log("[DEBUG] CSV fetch success, length =", csvText.length);
      const rawData = parseCSV(csvText);
      // console.log("[DEBUG] Parsed CSV rows:", rawData.length);
      if (!rawData.length) {
        // console.log("[DEBUG] No CSV rows found, returning");
        return;
      }
      const csvHeader = rawData[0];
      const csvBody = rawData.slice(1);
      const pairCount = Math.floor(csvHeader.length / 2);
      // console.log("[DEBUG] CSV header length =", csvHeader.length, "=> pairCount =", pairCount);

      const newChunkData = Array.from({ length: pairCount }, () => []);
      csvBody.forEach(rowArr => {
        for (let p = 0; p < pairCount; p++) {
          const colSymbol = 2 * p;
          const colDiff = 2 * p + 1;
          const symbolVal = rowArr[colSymbol] || "";
          const diffVal = rowArr[colDiff] || "";
          if (symbolVal) {
            newChunkData[p].push({ symbol: symbolVal, diff: diffVal });
          }
        }
      });

      const newDateLabels = [];
      for (let p = 0; p < pairCount; p++) {
        const dateVal = csvHeader[2 * p + 1] || `Date${p + 1}`;
        newDateLabels.push(dateVal);
      }
      // console.log("[DEBUG] dateLabels =>", newDateLabels);
      newDateLabels.forEach(dl => {
        const dObj = parseYYYYMMDD(dl);
        // console.log("[DEBUG] label:", dl, "=> parse =>", dObj);
      });

      // Save the original (unfiltered) data
      setOriginalChunkData(newChunkData);
      setOriginalDateLabels(newDateLabels);
    } catch (err) {
      // console.error("[DEBUG] Error fetching/parsing CSV =>", err);
    }
  }

  useEffect(() => {
    fetchCSVData();
  }, []);

  const handleApplyFilter = () => {
    // console.log("[DEBUG] applyFilter clicked => startDate =", startDate, "endDate =", endDate);
    // Create a key based on startDate and endDate.
    const filterKey = `${startDate}_${endDate}`;
    if (!startDate || !endDate) {
      // console.log("[DEBUG] no start/end => show all data");
      setChunkData(originalChunkData);
      setDateLabels(originalDateLabels);
      setDisplayTable(true);
      return;
    }
    const start = parseYYYYMMDD(startDate);
    const end = parseYYYYMMDD(endDate);
    if (!start || !end) {
      // console.log("[DEBUG] invalid parse => showing all");
      setChunkData(originalChunkData);
      setDateLabels(originalDateLabels);
      setDisplayTable(true);
      return;
    }
    // console.log("[DEBUG] filtering from", start.toISOString().slice(0, 10), "to", end.toISOString().slice(0, 10));

    // Check cache first.
    if (filterCache.current[filterKey]) {
      const { chunks, labels } = filterCache.current[filterKey];
      // console.log("[DEBUG] Using cached filter data.");
      setChunkData(chunks);
      setDateLabels(labels);
      setDisplayTable(true);
      return;
    }

    const filteredChunks = [];
    const filteredLabels = [];
    for (let p = 0; p < originalDateLabels.length; p++) {
      const dateStr = originalDateLabels[p];
      const dateObj = parseYYYYMMDD(dateStr);
      if (!dateObj) {
        // console.log("[DEBUG] skip chunk p=", p, "=> can't parse dateStr=", dateStr);
        continue;
      }
      if (dateObj >= start && dateObj <= end) {
        filteredChunks.push(originalChunkData[p]);
        filteredLabels.push(dateStr);
      }
    }
    // console.log("[DEBUG] filtered => found", filteredChunks.length, "chunks in range");

    // Cache the filtered result.
    filterCache.current[filterKey] = { chunks: filteredChunks, labels: filteredLabels };

    setChunkData(filteredChunks);
    setDateLabels(filteredLabels);
    setDisplayTable(true);
  };

  // Styling for filter area (flush left)
  const filterAreaStyle = {
    marginTop: '1rem',
    marginLeft: isMobile ? '1rem' : "5rem",
    display: "flex",
    alignItems: "center",
    gap: '10px',
  };
  const filterLabelStyle = { marginRight: "1rem" };
  const filterButtonStyle = { marginRight: "1rem", padding: "4px 8px" };

  // Big scrollable box style
  const bigBoxStyle = {
    width: "90vw",
    height: "90vh",
    margin: "1rem auto",
    border: "1px solid #444",
    borderRadius: "4px",
    overflow: "auto",
    padding: "10px",
  };

  // Use whiteSpace: "nowrap" so that each date's table stays inline (side-by-side)
  const innerWrapStyle = {
    whiteSpace: "nowrap",
  };

  const tableContainerStyle = {
    display: "inline-block",
    verticalAlign: "top",
    margin: "0 20px 20px 0",
    backgroundColor: theme.palette.background.container1,
    padding: '10px',
    borderRadius: '10px',
  };

  const tableStyle = {
    borderCollapse: "collapse",
    tableLayout: "auto",
  };

  const thStyle = {
    border: `1px solid #ccc`,
    background: theme.palette.table.thead,
    padding: "6px",
  };

  const thStyle2 = {
    border: `1px solid #ccc`,
    background: theme.palette.table.thead,
    padding: "6px",
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center'
  };

  const tdStyle = {
    border: "1px solid #ccc",
    padding: "6px",
    fontSize: '16px',
    color: theme.palette.text.primary,

  };

  const tdStyle2 = {
    border: "1px solid #ccc",
    // padding: "6px",
  };

  // Define a container style for chart cells (300px wide)
  const chartContainerStyle = {
    width: "300px",
    height: "120px",
    border: "1px solid transparent",
    borderRadius: '10px',
    overflow: 'hidden'
  };

  const toggleTable = (idx) => {
    setTableVisibility((prev) => ({
      ...prev,
      [idx]: !prev[idx] // สลับค่าเปิด/ปิดของแต่ละ index
    }));
  };

  return (
    <div>
      {/* Filter area */}
      <div style={filterAreaStyle}>
        <h1 style={{ marginRight: isMobile ? 'unset' : "2rem" }}>Outshort</h1>
        {/* <Typography variant='h3' sx={{ marginRight: "2rem" }}>Outshort</Typography> */}
        {/* <label style={filterLabelStyle}>
          Start Date:{" "}
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label style={filterLabelStyle}>
          End Date:{" "}
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <button onClick={handleApplyFilter} style={filterButtonStyle}>
          Apply Filter
        </button> */}
        {/* Start Date */}
        <TextField
          label="Start Date"
          type="date"
          size="small"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        // sx={{ minWidth: 180 }}
        />

        {/* End Date */}
        <TextField
          label="End Date"
          type="date"
          size="small"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        // sx={{ minWidth: 180 }}
        />

        {/* Apply Filter Button */}
        <Button variant="contained" sx={{
          backgroundColor: colors.greenChart[50],
          boxShadow: theme.palette.shadow.primary,
        }} onClick={handleApplyFilter}>
          Apply Filter
        </Button>
      </div>

      {/* Display table only after filter is applied */}
      {displayTable ? (
        <Box
          //  sx={bigBoxStyle}
          sx={{
            width: '100%',
            maxWidth: isTablet ? '80vw' : "84vw",
            height: isTablet ? '88vh' : "90vh",
            margin: "1rem auto",
            border: "1px solid #444",
            borderRadius: "4px",
            overflow: "auto",
            padding: "10px",
            overflowY: 'auto',
            '::-webkit-scrollbar': {
              width: '8px',
              height: '8px'
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
              borderRadius: '4px',
            },
            '::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        >
          <div style={innerWrapStyle}>
            {chunkData.map((arr, idx) => {
              const dateLabel = dateLabels[idx];
              console.log('dateLabel =', dateLabel)

              const [year, month, day] = dateLabel.split('-');
              const dateLabel2 = { monthDay: `${day}-${month}-`, year };
              const dateLabel3 = `${day}-${month}-${year}`;

              return (<>
                {/* <h3 style={{ margin: 0 }}>{dateLabel}</h3> */}

                <div key={idx} style={tableContainerStyle}>
                  <Box sx={{ mb: '10px', display: 'flex', width: 'fit-content', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant='h5'>{dateLabel3}</Typography>
                    {/* <IconButton sx={{ padding: 0 }} onClick={() => toggleTable(idx)}>
                      {tableVisibility[idx] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton> */}
                  </Box>

                  {!tableVisibility[idx] && (
                    <Box>
                      <TableContainer component={Paper}>
                        <Table stickyHeader sx={tableStyle}>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={thStyle2}>Symbol</TableCell>
                              <TableCell sx={thStyle2}>Date</TableCell>
                              <TableCell sx={thStyle2}><span style={{ fontSize: '1em' }}>Diff.</span><br /><span style={{ fontSize: '0.8em' }}>Out Short</span></TableCell>
                              <TableCell sx={thStyle2}>Chart Out Short</TableCell>
                              <TableCell sx={thStyle2}>Chart Price</TableCell>
                              <TableCell sx={thStyle2}>Chart Predict Trends</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {arr.map((item, rowIndex) => (
                              <TableRow key={rowIndex}>
                                <TableCell sx={tdStyle}>{item.symbol}</TableCell>
                                {/* <TableCell sx={tdStyle}>{dateLabel}</TableCell> */}
                                <TableCell sx={tdStyle}>
                                  <span style={{ fontSize: '1em', }}>{dateLabel2.monthDay}</span>
                                  <span style={{ fontSize: '0.8em' }}>{dateLabel2.year}</span>
                                </TableCell>
                                <TableCell sx={{ ...tdStyle, textAlign: 'center' }}>{item.diff}</TableCell>
                                <TableCell sx={tdStyle}>
                                  <ChatbotChartCell
                                    type="outshort"
                                    symbol={item.symbol}
                                    containerStyle={chartContainerStyle}
                                    endDate={dateLabel}
                                  />
                                </TableCell>
                                <TableCell sx={tdStyle}>
                                  <ChatbotChartCell
                                    type="price"
                                    symbol={item.symbol}
                                    containerStyle={chartContainerStyle}
                                    endDate={dateLabel}
                                  />
                                </TableCell>
                                <TableCell sx={tdStyle}>
                                  <ChatbotChartCell
                                    type="predict"
                                    symbol={item.symbol}
                                    containerStyle={chartContainerStyle}
                                    endDate={dateLabel}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  )}



                  {/* <table style={tableStyle}>
                    <thead>
                      <tr>
                        <th style={thStyle}>Symbol</th>
                        <th style={thStyle}>Date</th>
                        <th style={thStyle}>Different Out Short</th>
                        <th style={thStyle}>Chart Out Short</th>
                        <th style={thStyle}>Chart Price</th>
                        <th style={thStyle}>Chart Predict Trends</th>
                      </tr>
                    </thead>
                    <tbody>
                      {arr.map((item, rowIndex) => (
                        <tr key={rowIndex}>
                          <td style={tdStyle}>{item.symbol}</td>
                          <td style={tdStyle}>{dateLabel}</td>
                          <td style={tdStyle}>{item.diff}</td>
                          <td style={tdStyle}>
                            <ChatbotChartCell
                              type="outshort"
                              symbol={item.symbol}
                              containerStyle={chartContainerStyle}
                              endDate={dateLabel}
                            />
                          </td>
                          <td style={tdStyle}>
                            <ChatbotChartCell
                              type="price"
                              symbol={item.symbol}
                              containerStyle={chartContainerStyle}
                              endDate={dateLabel}
                            />
                          </td>
                          <td style={tdStyle}>
                            <ChatbotChartCell
                              type="predict"
                              symbol={item.symbol}
                              containerStyle={chartContainerStyle}
                              endDate={dateLabel}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
              </>
              );
            })}
          </div>
        </Box>
      ) : (
        <div style={{ marginLeft: isMobile ? '1rem' : "5rem", marginTop: "1rem" }}>
          <p>Please select a start date and click "Apply Filter" to display data.</p>
        </div>
      )}
    </div>
  );
}

export default App;
