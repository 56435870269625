import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
// import './Flow2.css';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { CircularProgress, Box, Grid, useMediaQuery, Container, useTheme, Dialog, DialogContent, DialogActions, Button, IconButton, Typography, TextField, InputAdornment, Tooltip, Autocomplete, DialogTitle, } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close"
import ZoomInIcon from "@mui/icons-material/ZoomIn"
import { tokens } from '../../theme';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const initialSymbols = ["PTT", "OR", "M", "TRUE", "ADVANC", "ICN", "CPF", "CPALL", "TBEV", "TTB", "KBANK", "SCB"];

function convertData(data, date_column = 'time', field = 'value', timezone = true) {
    const timeZoneOffset = timezone ? 7 * 60 * 60 * 1000 : 0;
    return data.map(d => ({
        time: (new Date(d[date_column]).getTime() + timeZoneOffset) / 1000,
        value: parseFloat(d[field]) || 0,
    }));
}

const FavoriteStocks = ({ containers, setContainers, handleSymbolChange }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isPTablet = useIsPortraitTablet();

    const [isEditing, setIsEditing] = useState(false);  // เก็บสถานะการแก้ไข
    const [tempFavoriteName, setTempFavoriteName] = useState('');  // เก็บชื่อที่กำลังแก้ไข

    // เริ่มต้น symbolArray เป็นค่าว่าง เพื่อป้องกันปัญหารีเซ็ตข้อมูล
    const [symbolArray, setSymbolArray] = useState([]);
    const [favorites, setFavorites] = useState([]);
    const [selectedFavorite, setSelectedFavorite] = useState(null);
    const [openSettings, setOpenSettings] = useState(false);
    const [editedFavoriteName, setEditedFavoriteName] = useState('');
    const [editedSymbols, setEditedSymbols] = useState([]);
    const isUpdatingContainers = useRef(false);
    const isUpdatingSymbols = useRef(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [optionToDelete, setOptionToDelete] = useState(null); // ใช้เพื่อเก็บรายการที่จะลบชั่วคราว

    console.log('array is', symbolArray);

    // โหลด favorites จาก localStorage ครั้งแรก
    useEffect(() => {
        const cachedFavorites = localStorage.getItem('favoriteStocks');
        if (cachedFavorites) {
            setFavorites(JSON.parse(cachedFavorites));
        }
    }, []);

    // เก็บ favorites ลง localStorage ทุกครั้งที่ favorites เปลี่ยน
    useEffect(() => {
        localStorage.setItem('favoriteStocks', JSON.stringify(favorites));
    }, [favorites]);

    // อัปเดต symbolArray เมื่อ containers มีการเปลี่ยนแปลง
    // useEffect(() => {
    //     if (containers && containers.length > 0) {
    //         const symbols = containers.map(cont => cont.symbol || '');
    //         if (JSON.stringify(symbols) !== JSON.stringify(symbolArray)) {
    //             setSymbolArray(symbols);
    //         }
    //     }
    // }, [containers]);

    // useEffect(() => {
    //     if (symbolArray.length > 0) {
    //         setContainers(prev =>
    //             prev.map((c, idx) => ({
    //                 ...c,
    //                 symbol: symbolArray[idx] || "",  // ✅ อัปเดต symbol ของ containers
    //             }))
    //         );
    //     }
    // }, [symbolArray]);



    // useEffect(() => {
    //     if (containers.length > 0 && !isUpdatingSymbols.current) {
    //         const symbols = containers.map(cont => cont.symbol);

    //         if (JSON.stringify(symbols) !== JSON.stringify(symbolArray)) {
    //             isUpdatingContainers.current = true; // ✅ ป้องกัน loop
    //             setSymbolArray(symbols);
    //         }
    //     }
    //     isUpdatingSymbols.current = false;
    // }, [containers]);



    useEffect(() => {
        if (containers.length > 0 && !isUpdatingSymbols.current) {
            const symbols = containers.map(cont => cont.symbol || "");

            // ✅ ป้องกันการตั้งค่า symbolArray ซ้ำซ้อน
            if (JSON.stringify(symbols) !== JSON.stringify(symbolArray) && symbols.some(s => s !== "")) {
                isUpdatingContainers.current = true;
                setSymbolArray(symbols);
            }
        }
        isUpdatingSymbols.current = false;
    }, [containers]);

    useEffect(() => {
        if (symbolArray.length > 0 && !isUpdatingContainers.current) {
            setContainers(prev => {
                const updatedContainers = prev.map((c, idx) => ({
                    ...c,
                    symbol: symbolArray[idx] || "",
                }));

                if (JSON.stringify(updatedContainers) !== JSON.stringify(prev)) {
                    isUpdatingSymbols.current = true; // ✅ ป้องกัน loop
                    return updatedContainers;
                }
                return prev;
            });
        }
        isUpdatingContainers.current = false;
    }, [symbolArray]);

    const applySymbolsToContainers = useCallback(() => {
        setContainers(prev => {
            const updatedContainers = prev.map((c, idx) => ({
                ...c,
                symbol: symbolArray[idx] || '',
            }));
            console.log('Applied symbols to containers:', updatedContainers);
            return updatedContainers;
        });
    }, [symbolArray]);

    // ฟังก์ชัน Apply Symbols
    // const applySymbolsToContainers = () => {
    //     setContainers(prev =>
    //         prev.map((c, idx) => ({
    //             ...c,
    //             symbol: symbolArray[idx] || '', // ✅ อัปเดต symbol จาก symbolArray
    //         }))
    //     );
    //     console.log('Applied symbols to containers:', containers);
    // };

    // เพิ่ม Favorite ใหม่
    // const addToFavorites = () => {
    //     const symbols = [...symbolArray];
    //     const newFavorite = {
    //         name: `Favorite ${favorites.length + 1}`,
    //         symbols,
    //     };
    //     setFavorites([...favorites, newFavorite]);
    // };


    const addToFavorites = () => {
        const symbols = [...symbolArray];

        if (selectedFavorite) {
            // หากมีการเลือก Favorite อยู่แล้ว ให้ทำการบันทึกทับ
            const updatedFavorites = favorites.map((fav) =>
                fav.name === selectedFavorite.name
                    ? { ...fav, symbols }  // บันทึกสัญลักษณ์ใหม่ทับรายการที่เลือก
                    : fav
            );
            setFavorites(updatedFavorites);
        } else {
            // หากยังไม่ได้เลือก Favorite ใด ให้สร้างรายการใหม่
            const newFavorite = {
                name: `Favorite ${favorites.length + 1}`,  // ชื่อใหม่สำหรับรายการ
                symbols,
            };
            setFavorites([...favorites, newFavorite]);
        }

        // รีเซ็ตการเลือกหลังจากบันทึกเสร็จ
        setSelectedFavorite(null);
    };

    // เลือก Favorite ที่ต้องการใช้
    // const handleFavoriteSelect = (event, value) => {
    //     if (value) {
    //         setSelectedFavorite(value);
    //         setSymbolArray(value.symbols);
    //         applySymbolsToContainers();
    //     }
    // };

    const handleFavoriteSelect = (event, value) => {
        if (value) {
            setSelectedFavorite(value);
            setSymbolArray(value.symbols); // ✅ อัปเดต symbolArray ก่อน
        }
    };

    useEffect(() => {
        if (selectedFavorite) {
            applySymbolsToContainers();
        }
    }, [symbolArray]);

    // เปิดหน้าต่าง Settings
    // const handleOpenSettings = () => {
    //     setEditedFavoriteName(selectedFavorite?.name || '');
    //     setEditedSymbols(selectedFavorite?.symbols || []);
    //     setOpenSettings(true);
    // };

    const handleOpenSettings = (selectedOption) => {
        setSelectedFavorite(selectedOption);
        setEditedFavoriteName(selectedOption.name);
        setEditedSymbols(selectedOption.symbols);
        setOpenSettings(true);
    };

    // เปลี่ยนแปลง Symbol ใน Settings
    const handleSymbolChangeInSettings = (index, newSymbol) => {
        const updatedSymbols = [...editedSymbols];
        updatedSymbols[index] = newSymbol;
        setEditedSymbols(updatedSymbols);
    };

    // บันทึก Settings
    const handleSaveSettings = () => {
        if (selectedFavorite) {
            const updatedFavorites = favorites.map((fav) =>
                fav.name === selectedFavorite.name
                    ? { ...fav, name: editedFavoriteName, symbols: editedSymbols }
                    : fav
            );
            setFavorites(updatedFavorites);
        }
        setOpenSettings(false);
    };

    // ลบ Favorite ที่เลือก
    // const handleDeleteFavorite = () => {
    //     if (selectedFavorite) {
    //         const updatedFavorites = favorites.filter((fav) => fav.name !== selectedFavorite.name);
    //         setFavorites(updatedFavorites);
    //         setSelectedFavorite(null);
    //     }
    // };
    // const handleDeleteFavorite = (selectedOption) => {
    //     const updatedFavorites = favorites.filter((fav) => fav.name !== selectedOption.name);
    //     setFavorites(updatedFavorites);
    //     if (selectedFavorite?.name === selectedOption.name) {
    //         setSelectedFavorite(null);
    //     }
    // };

    const handleDeleteFavorite = () => {
        const updatedFavorites = favorites.filter((fav) => fav.name !== optionToDelete.name);
        setFavorites(updatedFavorites);
        if (selectedFavorite?.name === optionToDelete.name) {
            setSelectedFavorite(null);
        }
        setOpenDeleteConfirm(false); // ปิด dialog หลังจากลบเสร็จ
    };

    const handleOpenDeleteConfirm = (option) => {
        setOptionToDelete(option); // ตั้งค่ารายการที่จะลบใน optionToDelete
        setOpenDeleteConfirm(true); // เปิด dialog เพื่อยืนยันการลบ
    };





    return (
        <div style={{ marginBottom: '10px', position: 'absolute', right: isMobile ? '5%' : '10%', top: isMobile ? '5px' : '10px', display: 'flex', flexDirection: 'row', gap: '1vw' }}>
            <Autocomplete
                disablePortal
                id='favoriteList'
                size='small'
                options={favorites}
                getOptionLabel={(option) => option.name}
                onChange={handleFavoriteSelect}
                renderOption={(props, option) => (
                    <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{option.name}</span>
                        <div>
                            <IconButton size="small" onClick={() => handleOpenSettings(option)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleOpenDeleteConfirm(option)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </li>
                )}
                renderInput={(params) => <TextField {...params} label="Favorite Lists" />}
                sx={{ width: '150px' }}
            />
            <Button
                variant='contained'
                startIcon={<FavoriteIcon />}
                onClick={addToFavorites}
                sx={{ backgroundColor: colors.orangeChart[200] }}
            >
                {selectedFavorite ? `Save to ${selectedFavorite.name} ` : 'Add to favorite'}
            </Button>
            {/* <Tooltip title="Settings">
                <IconButton color="primary" onClick={handleOpenSettings}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip> */}
            {/* <Tooltip title="Delete Favorite">
                <IconButton color="error" onClick={handleDeleteFavorite}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip> */}
            {/* <Tooltip title="reset">
                <IconButton color='primary' onClick={setSymbolArray([])}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip> */}

            <Dialog open={openSettings} onClose={() => setOpenSettings(false)}>
                <DialogTitle>Edit Favorite</DialogTitle>
                <DialogContent>
                    <TextField
                        label='Favorite Name'
                        fullWidth
                        value={editedFavoriteName}
                        onChange={(e) => setEditedFavoriteName(e.target.value)}
                        sx={{ mt: '1em' }}
                    />
                    {/* <Grid container spacing={2}>
                        {editedSymbols.map((symbol, index) => (
                            <Grid item xs={6} key={index}>
                                <TextField
                                    label={`Symbol ${index + 1}`}
                                    fullWidth
                                    value={symbol}
                                    onChange={(e) => handleSymbolChangeInSettings(index, e.target.value)}
                                />
                            </Grid>
                        ))}
                    </Grid> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSettings(false)} variant='text' sx={{ backgroundColor: 'transparent' }}>Cancel</Button>
                    <Button onClick={handleSaveSettings} variant='contained' sx={{ backgroundColor: 'green' }} >Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete {optionToDelete?.name} from your favorites?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteConfirm(false)} variant='text' sx={{ backgroundColor: 'transparent' }}>Cancel</Button>
                    <Button onClick={handleDeleteFavorite} variant='contained' sx={{ backgroundColor: 'red' }}>Delete</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


function TradingViewChart({ lineSeriesData, symbol, date, onClick, isOpen }) {
    const chartContainerRef = useRef();
    const chartRef = useRef();
    const baselineSeriesRef = useRef(null);
    const flowLineSeriesRef = useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (!chartContainerRef.current) return;
        console.log(lineSeriesData.price.length);

        const chart = createChart(chartContainerRef.current, {


            // grid: { vertLines: { color: 'rgba(197, 203, 206, 0.7)' }, horzLines: { color: 'rgba(197, 203, 206, 0.7)' } },
            rightPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 } },
            leftPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 }, visible: false },
            timeScale: {
                rightOffset: lineSeriesData.price.length < 100 ? 1 : 5,
                barSpacing: 3,
                lockVisibleTimeRangeOnResize: true,
                rightBarStaysOnScroll: true,
                borderVisible: true,
                visible: true,
                timeVisible: true,
                secondsVisible: true,
                handleScroll: false,
            },
            watermark: {
                color: theme.palette.chart.watermark,
                visible: true,
                text: (symbol || '').toUpperCase(),
                fontSize: 24,
                horzAlign: 'center',
                vertAlign: 'top',
            },
        });

        chart.applyOptions({
            layout: {
                background: { color: theme.palette.table.odd },
                textColor: theme.palette.text.secondary,
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
            },
        })

        const updateChartSize = () => {
            if (!chartContainerRef.current) return;
            chart.applyOptions({ width: chartContainerRef.current.clientWidth, height: chartContainerRef.current.clientHeight });
        };
        const resizeObserver = new ResizeObserver(() => requestAnimationFrame(updateChartSize));
        resizeObserver.observe(chartContainerRef.current);
        updateChartSize();

        // const priceLineSeries = chart.addLineSeries({ color: colors.blueChart[50], lineWidth: 2, priceScaleId: 'right', priceLineVisible: false, });
        // priceLineSeries.setData(lineSeriesData.price);



        const flowLineSeries = chart.addLineSeries({ color: 'rgba(255,255,255,0.9)', lineWidth: 2, priceScaleId: 'right', priceLineVisible: false, });
        flowLineSeries.setData(lineSeriesData.price);
        // console.log(lineSeriesData);

        let baseValue = lineSeriesData.flow[0]?.value;
        const baselineSeries = chart.addBaselineSeries({
            baseValue: { type: 'price', baseValue },
            priceScaleId: 'left',
            priceLineVisible: false,
            // priceFormat: { type: 'volume' }
        });
        baselineSeries.applyOptions({
            topLineColor: colors.greenChart[100] ?? "#00FF00",
            bottomLineColor: colors.redChart[100] ?? "#FF0000",
            topFillColor1: "rgba(0, 0, 0, 0.0)",
            topFillColor2: "rgba(0, 0, 0, 0.0)",
            bottomFillColor1: "rgba(0, 0, 0, 0.0)",
            bottomFillColor2: "rgba(0, 0, 0, 0.0)",
        });
        baselineSeries.setData(lineSeriesData.flow);
        console.log('data is', lineSeriesData)
        console.log('baseValue', lineSeriesData.price[0]?.value)

        chart.timeScale().fitContent();


        return () => {
            resizeObserver.disconnect();
            chart.remove();
        };
    }, [lineSeriesData, symbol, date]);
    // useEffect(() => {
    //     if (!chartContainerRef.current) return;

    //     // ตรวจสอบว่ามี Chart อยู่แล้วหรือไม่
    //     if (!chartRef.current) {
    //         chartRef.current = createChart(chartContainerRef.current, {
    //             rightPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 } },
    //             leftPriceScale: { scaleMargins: { top: 0.3, bottom: 0.25 }, visible: false },
    //             timeScale: {
    //                 rightOffset: 12,
    //                 barSpacing: 3,
    //                 lockVisibleTimeRangeOnResize: true,
    //                 rightBarStaysOnScroll: true,
    //                 borderVisible: true,
    //                 visible: true,
    //                 timeVisible: true,
    //                 secondsVisible: true,
    //                 handleScroll: false,
    //             },
    //             watermark: {
    //                 color: theme.palette.chart.watermark,
    //                 visible: true,
    //                 text: (symbol || '').toUpperCase(),
    //                 fontSize: 24,
    //                 horzAlign: 'center',
    //                 vertAlign: 'top',
    //             },
    //         });

    //         chartRef.current.applyOptions({
    //             layout: {
    //                 background: { color: theme.palette.table.odd },
    //                 textColor: theme.palette.text.secondary,
    //             },
    //             grid: {
    //                 vertLines: { color: theme.palette.neutral.dark },
    //                 horzLines: { color: theme.palette.neutral.dark },
    //             },
    //         });

    //         const updateChartSize = () => {
    //             if (!chartContainerRef.current) return;
    //             chartRef.current.applyOptions({
    //                 width: chartContainerRef.current.clientWidth,
    //                 height: chartContainerRef.current.clientHeight,
    //             });
    //         };

    //         const resizeObserver = new ResizeObserver(() => requestAnimationFrame(updateChartSize));
    //         resizeObserver.observe(chartContainerRef.current);
    //         updateChartSize();
    //     }

    //     const chart = chartRef.current;

    //     // ลบ Series เก่าก่อนเพิ่มใหม่
    //     if (baselineSeriesRef.current) {
    //         chart.removeSeries(baselineSeriesRef.current);
    //     }

    //     if (flowLineSeriesRef.current) {
    //         chart.removeSeries(flowLineSeriesRef.current);
    //     }

    //     // ตรวจสอบว่ามีข้อมูลก่อนสร้าง Baseline Series
    //     const priceData = lineSeriesData.price || [];
    //     const baseValue = priceData.length > 0 ? priceData[0].value : 0;

    //     baselineSeriesRef.current = chart.addBaselineSeries({
    //         baseValue: baseValue,
    //         priceScaleId: 'right',
    //     });

    //     baselineSeriesRef.current.applyOptions({
    //         topLineColor: colors.greenChart[100] ?? "#00FF00",
    //         bottomLineColor: colors.redChart[100] ?? "#FF0000",
    //         topFillColor1: colors.greenChart[50] ?? "rgba(0, 255, 0, 0.5)",
    //         topFillColor2: colors.greenChart[100] ?? "rgba(0, 255, 0, 1)",
    //         bottomFillColor1: colors.redChart[50] ?? "rgba(255, 0, 0, 0.5)",
    //         bottomFillColor2: colors.redChart[100] ?? "rgba(255, 0, 0, 1)",
    //     });

    //     if (priceData.length > 0) {
    //         baselineSeriesRef.current.setData(priceData);
    //     }

    //     // ตรวจสอบว่ามีข้อมูลก่อนสร้าง Flow Line Series
    //     const flowData = lineSeriesData.flow || [];
    //     if (flowData.length > 0) {
    //         flowLineSeriesRef.current = chart.addLineSeries({
    //             color: colors.whiteChart[100],
    //             lineWidth: 2,
    //             priceScaleId: 'left',
    //             priceLineVisible: false,
    //             priceFormat: { type: 'volume' }
    //         });
    //         flowLineSeriesRef.current.setData(flowData);
    //     }

    //     return () => {
    //         if (chartRef.current) {
    //             chart.remove();
    //             chartRef.current = null;
    //             baselineSeriesRef.current = null;
    //             flowLineSeriesRef.current = null;
    //         }
    //     };
    // }, [lineSeriesData, symbol, date]);
    return <div ref={chartContainerRef} style={{ position: 'relative', marginTop: '3%', height: isOpen ? '150px' : '100%', width: '100%' }} onClick={onClick} />;
}

function ChartContainer({ chartId, containerData, onDateChange, onSymbolChange, onSubmit, onDateReplicate, onSymbolReplicate }) {
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const theme = useTheme();
    const isLaptop = useMediaQuery(theme.breakpoints.down('1400'));
    const { selectedDate, symbol, lineSeriesData } = containerData;



    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);



    const handleChartClick = (data) => {
        setPopupData(data); // ตั้งค่าข้อมูลที่จะส่งไปยัง popup
        setIsPopupOpen(true); // เปิด popup
    };

    const handleClose = () => {
        setIsPopupOpen(false);
        setPopupData(null);
    };

    const handleDateChange = (e) => {
        onDateChange(chartId, e.target.value);
    };

    const handleSymbolChange = (e) => {
        onSymbolChange(chartId, e.target.value);
    };

    // const handleLocalSubmit = () => {
    //     onSubmit(chartId, selectedDate, symbol);
    // };

    const [isSearching, setIsSearching] = useState(false);
    const [debouncedSymbol, setDebouncedSymbol] = useState(symbol);
    const [debouncedDate, setDebouncedDate] = useState(selectedDate);

    const previousSymbol = useRef('');
    const previousDate = useRef('');

    // Delay การอัพเดตค่า
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSymbol(symbol?.trim() || '');
            setDebouncedDate(selectedDate?.trim() || '');
        }, 1000); // Delay 1 วินาที

        return () => clearTimeout(handler);
    }, [symbol, selectedDate]);

    // ตรวจสอบการเปลี่ยนแปลงค่าที่แท้จริงก่อนเรียก onSubmit
    useEffect(() => {
        if (
            debouncedSymbol &&
            debouncedDate &&
            (previousSymbol.current !== debouncedSymbol || previousDate.current !== debouncedDate)
        ) {
            console.log("start searching");
            setIsSearching(true); // เริ่มต้นแสดง Indicator
            onSubmit(chartId, debouncedDate, debouncedSymbol).finally(() => {
                setIsSearching(false); // ปิด Indicator เมื่อการค้นหาเสร็จสิ้น
            });
            previousSymbol.current = debouncedSymbol;
            previousDate.current = debouncedDate;
        }
    }, [debouncedSymbol, debouncedDate, chartId, onSubmit]);

    return (
        <Box id={chartId} sx={{
            display: 'flex',
            flexDirection: 'column',
            width: isMobile ? '200px' : '100%',
            maxWidth: {
                xs: '50%',
                sm: '45%',
                md: '45%',
                lg: '30%',
                xl: '30%',
            },
            minWidth: {
                xs: '200px',
                sm: '200px',
                md: '330px',
            },
            // margin: '0.2vw', 

        }}>
            <div style={{
                display: 'flex', justifyContent: 'center',
                height: isMobile ? 'fit-content' : 'normal',
                alignItems: 'center',
                // marginTop: '1%'
            }}>
                {/* Date input + button */}
                {(!isMobile && !isTablet) && (<>
                    <Box sx={{
                        // display: isLaptop ? 'column' : 'flex',
                        display: 'flex',
                        // marginBottom: '5px',
                        width: isLaptop ? '280px' : '100%',
                        justifyContent: 'space-between',
                    }}>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* <input
                                type="date"
                                value={selectedDate || ''}
                                onChange={handleDateChange}
                                className="form-control"
                                style={{ width: '120px', margin: '0.1vw 5px' }}
                            />
                            <button onClick={() => onDateReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px', padding: '0px',  }}>+</button> */}
                            <TextField
                                type="date"
                                value={selectedDate || ""}
                                onChange={handleDateChange}
                                variant="outlined"
                                size="small"
                                sx={{
                                    width: "140px", margin: "0.1vw 5px",
                                    "& input": {
                                        color: "white", // เปลี่ยนสีข้อความ
                                    },
                                    "& input::-webkit-calendar-picker-indicator": {
                                        filter: "invert(1)", // ทำให้ไอคอนปฏิทินเป็นสีขาว
                                    },
                                }}
                            />
                            <IconButton
                                onClick={() => onDateReplicate(chartId)}
                                size="small"
                                sx={{
                                    // width: "30px", height: "30px",
                                    // marginLeft: "5px"
                                }}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </div>
                        {/* Symbol input + button */}
                        <div style={{ display: 'flex', alignItems: 'center', }} >
                            {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Symbol here"
                                value={symbol || ''}
                                onChange={handleSymbolChange}
                                style={{ width: '120px', margin: '0.1vw 5px' }}
                            />
                            <button onClick={() => onSymbolReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px', padding: '0px', }}>+</button> */}
                            <TextField
                                type="text"
                                variant="outlined"
                                size="small"
                                placeholder="Symbol here"
                                value={symbol || ""}
                                onChange={handleSymbolChange}
                                sx={{ width: "140px", margin: "0.1vw 5px" }}
                            />
                            <IconButton
                                onClick={() => onSymbolReplicate(chartId)}
                                size="small"
                                sx={{
                                    // width: "30px", height: "30px",
                                    //  marginLeft: "5px"
                                }}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </div>



                    </Box>

                </>)}

                {(isMobile || isTablet) && (<>
                    <Box sx={{
                        display: 'flex',
                        marginBottom: '10px',
                        // maxWidth: '500px',
                        width: {
                            xs: '40vw',
                            sm: '25vw',
                            md: '30vw',

                        },
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'row'
                        },
                        justifyItems: 'center'

                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <input
                                type="date"
                                value={selectedDate || ''}
                                onChange={handleDateChange}
                                className="form-control"
                                style={{ width: '120px', margin: '0.1vw 5px' }} />
                            <button onClick={() => onDateReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px', padding: '0px',  }}>+</button> */}
                            <TextField
                                type="date"
                                value={selectedDate || ""}
                                onChange={handleDateChange}
                                variant="outlined"
                                size="small"
                                sx={{
                                    width: "120px", margin: "0.1vw 5px",
                                    "& input": {
                                        color: "white", // เปลี่ยนสีข้อความ
                                    },
                                    "& input::-webkit-calendar-picker-indicator": {
                                        filter: "invert(1)", // ทำให้ไอคอนปฏิทินเป็นสีขาว
                                    },
                                }}
                            />
                            <IconButton
                                onClick={() => onDateReplicate(chartId)}
                                size="small"
                                sx={{
                                    width: "30px", height: "20px",
                                    //  marginLeft: "5px",
                                }}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <input
                                type="text"
                                className="form-control"
                                placeholder="Symbol here"
                                value={symbol || ''}
                                onChange={handleSymbolChange}
                                style={{ width: '120px', margin: '0.1vw 5px' }} />
                            <button onClick={() => onSymbolReplicate(chartId)} style={{ width: '20px', height: '20px', fontSize: '10px', marginLeft: '5px', padding: '0px', }}>+</button> */}
                            <TextField
                                type="text"
                                variant="outlined"
                                size="small"
                                placeholder="Symbol here"
                                value={symbol || ""}
                                onChange={handleSymbolChange}
                                sx={{ width: "120px", margin: "0.1vw 5px" }}
                            />
                            <IconButton
                                onClick={() => onSymbolReplicate(chartId)}
                                size="small"
                                sx={{
                                    width: "30px", height: "20px",
                                    // marginLeft: "5px"
                                }}
                            >
                                <AddIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </Box>
                </>)}


                {isSearching && (
                    <div
                        style={{
                            position: 'absolute',
                            // top: '25%',
                            // left: '25%',
                            // transform: 'translate(-50%, -50%)',
                            zIndex: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            // backgroundColor: 'rgba(255, 255, 255, 0.8)', // ทำให้ background โปร่งใส
                            width: '100%',
                            height: '200px',
                        }}
                    >
                        <CircularProgress sx={{ color: theme.palette.text.primary }} />
                    </div>)}

                {/* Submit button */}
                {/* <button
                    className="btn btn-primary d-flex align-items-center justify-content-center"
                    onClick={handleLocalSubmit}
                    style={{ width: '60px', height: '25px', margin: '0.1vw 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    Submit
                </button> */}
            </div>
            {lineSeriesData ? (<Box sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                marginTop: '5px',
                backgroundColor: theme.palette.table.odd,
                padding: isMobile ? '2px 2px 0 2px' : '5px 10px 0 10px',
                borderRadius: '5px',
            }}>
                {/* TradingViewChart */}
                <TradingViewChart
                    lineSeriesData={lineSeriesData}
                    symbol={symbol || ''}
                    date={selectedDate || ''}
                    isOpen={setIsPopupOpen}

                />

                {/* IconButton for Zoom */}
                <IconButton
                    onClick={() =>
                        handleChartClick({
                            lineSeriesData: lineSeriesData,
                            symbol: symbol || '',
                            date: selectedDate || '',
                        })
                    }
                    sx={{
                        position: 'absolute',
                        top: 8, // Distance from top
                        right: 64, // Distance from right
                        zIndex: 10, // Ensure it appears above the chart
                        backgroundColor: 'transparent', // Optional: background color for better visibility
                        '&:hover': {
                            backgroundColor: 'lightgray', // Optional: hover effect
                        },
                    }}
                >
                    <ZoomInIcon /> {/* Replace with any Material-UI icon */}
                </IconButton>
            </Box>) : (<Box sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                marginTop: '5px',
                backgroundColor: theme.palette.table.odd,
                padding: isMobile ? '2px 2px 0 2px' : '5px 10px 0 10px',
                borderRadius: '5px',
            }}></Box>)
            }
            <Dialog open={isPopupOpen} onClose={handleClose} fullWidth maxWidth="lg" className="DialogBox">
                <IconButton
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 1,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
                        {popupData && (
                            <>
                                <Typography variant="h3">
                                    Symbol: {popupData.symbol.toUpperCase()}
                                </Typography>
                                <Typography variant="h4">
                                    Date: {popupData.date}
                                </Typography>
                            </>
                        )}
                    </Box>
                    <Box sx={{
                        height: {
                            xs: '300px', // mobile
                            sm: '300px',
                            md: '300px', // tablet
                            lg: '500px', // desktop
                        },
                        width: '100%',
                    }}>
                        {popupData && (
                            <TradingViewChart
                                lineSeriesData={popupData.lineSeriesData}
                                symbol={popupData.symbol}
                                date={popupData.date}
                                sx={{
                                    height: '300px'
                                }}
                            />
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default function Flow() {
    const csrfToken = useSelector((state) => state.csrfToken);

    const isMobile = useIsMobile();
    // console.log("is Mobile?", isMobile)
    const isTablet = useIsTablet();
    // console.log("is Tablet?", isTablet)

    const isLongPage = useMediaQuery('(min-height:1025px)');
    const isMobilePage = useMediaQuery('(min-height:741px)');
    const isLaptop = useMediaQuery('(max-height:1340px)');

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const [symbolArray, setSymbolArray] = useState([]);

    // const applySymbolsToContainers = () => {
    //     containers.forEach((cont, index) => {
    //         const symbol = symbolArray[index] || "";
    //         handleSymbolChange(cont.chartId, symbol); // ✅ เรียกใช้ onSymbolChange พร้อม chartId
    //     });
    // };

    // // ✨ ฟังก์ชั่นสำหรับแก้ไข Symbol ใน Array
    // const handleSymbolEdit = (index, newSymbol) => {
    //     const updatedSymbols = [...symbolArray];
    //     updatedSymbols[index] = newSymbol;
    //     setSymbolArray(updatedSymbols);
    // };



    const initialContainers = Array.from({ length: 12 }).map((_, i) => ({
        chartId: `chart${i + 1}`,
        selectedDate: '',
        symbol: '',
        lineSeriesData: null,
    }));

    const [containers, setContainers] = useState(initialContainers);

    const fetchData = async (date, symbol) => {
        if (!date || !symbol) {
            console.error('Please provide both symbol and date');
            return null;
        }

        const flowUrl = `${window.config.base_api_url}/flow?symbol=${symbol}&date=${date}`;
        try {
            const response = await fetch(flowUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });
            if (!response.ok) throw new Error('Network response was not ok');
            const rawData = await response.json();
            if (!rawData || rawData.length === 0) {
                console.error('No data received');
                return null;
            }
            return rawData;
        } catch (error) {
            console.error('Error during fetch operation:', error.message);
            return null;
        }
    };

    const handleSubmit = async (chartId, date, symbol) => {
        const rawData = await fetchData(date, symbol);
        if (!rawData) return;
        const newLineSeriesData = {
            price: convertData(rawData, 'time', 'last'),
            flow: convertData(rawData, 'time', 'flow'),
        };

        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, lineSeriesData: newLineSeriesData } : c)
        );
    };

    const handleDateChange = (chartId, newDate) => {
        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, selectedDate: newDate } : c)
        );
    };

    // const handleSymbolChange = (chartId, newSymbol) => {
    //     setContainers(prev =>
    //         prev.map(c => c.chartId === chartId ? { ...c, symbol: newSymbol } : c)
    //     );
    // };

    const handleSymbolChange = (chartId, newSymbol) => {
        console.log(`handleSymbolChange called with chartId: ${chartId}, symbol: ${newSymbol}`);
        setContainers(prev =>
            prev.map(c => c.chartId === chartId ? { ...c, symbol: newSymbol } : c)
        );
    };

    useEffect(() => {
        console.log('Updated containers:', containers);
    }, [containers]);

    const handleDateReplicate = (chartId) => {
        const sourceIndex = containers.findIndex(c => c.chartId === chartId);
        if (sourceIndex === -1) return;
        const sourceDate = containers[sourceIndex].selectedDate;
        if (!sourceDate) return;

        setContainers(prev => prev.map((c, idx) => {
            if (idx > sourceIndex) {
                return { ...c, selectedDate: sourceDate };
            }
            return c;
        }));
    };

    const handleSymbolReplicate = (chartId) => {
        const sourceIndex = containers.findIndex(c => c.chartId === chartId);
        if (sourceIndex === -1) return;
        const sourceSymbol = containers[sourceIndex].symbol;
        if (!sourceSymbol) return;

        setContainers(prev => prev.map((c, idx) => {
            if (idx > sourceIndex) {
                return { ...c, symbol: sourceSymbol };
            }
            return c;
        }));
    };

    // console.log(containers);

    return (
        <>
            <Container sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%',
                height: '100vh',
                padding: isMobile ? '0px 10px' : 'unset',
                marginTop: isMobile ? '10px' : 'unset',
                overflow: 'auto', // Enables scrolling if needed
                backgroundColor: theme.palette.background.sidebar,
                maxWidth: {
                    xl: '98%'
                }

            }}>
                <Box
                    sx={{
                        maxWidth: 2000,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: isTablet ? '10vh' : isMobile ? '3vh' : '6vh',
                        gap: isMobile ? '6vh' : isTablet ? '20px' : '10px'
                    }}
                >
                    {/* <div style={{ marginBottom: '10px', position: 'absolute', right: '10%', top: '10px', }}>
                        <Autocomplete
                            disablePortal
                            id='favoriteList'
                            size='small'
                            renderInput={(params) => <TextField {...params} label="" />}
                        />
                        <Button
                            variant='contained'
                            startIcon={<FavoriteIcon />}
                            sx={{ backgroundColor: colors.orangeChart[200] }}
                        >
                            Add to favorite
                        </Button>
                        <Tooltip title="Apply Symbols to All Charts">
                            <IconButton color="primary" onClick={applySymbolsToContainers}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>

                        
                    </div> */}
                    <FavoriteStocks
                        containers={containers}
                        handleSymbolChange={handleSymbolChange}
                        setContainers={setContainers}
                    // initialSymbols={initialSymbols}
                    />
                    {(!isMobile && !isTablet) && (
                        <>
                            <Grid container className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(0, 3).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(3, 6).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(6, 9).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(9, 12).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                        </>
                    )}
                    {(isMobile || isTablet) && (
                        <>
                            <Grid container spacing={0} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}>
                                {containers.slice(0, 2).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>
                            <Grid container spacing={0} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}>
                                {containers.slice(2, 4).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>

                            <Grid container spacing={0} className="chart-row"
                                sx={{
                                    height: '15em',
                                    justifyContent: 'space-around',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                            >
                                {containers.slice(4, 6).map((cont) => (
                                    <ChartContainer
                                        chartId={cont.chartId}
                                        containerData={cont}
                                        onSubmit={handleSubmit}
                                        onDateChange={handleDateChange}
                                        onSymbolChange={handleSymbolChange}
                                        onDateReplicate={handleDateReplicate}
                                        onSymbolReplicate={handleSymbolReplicate}
                                    />
                                ))}
                            </Grid>

                            {!isMobilePage && (<>

                                <Grid container spacing={0} className="chart-row"
                                    sx={{
                                        height: '15em',
                                        justifyContent: 'space-around',
                                        width: '100%',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {containers.slice(6, 8).map((cont) => (
                                        <ChartContainer
                                            chartId={cont.chartId}
                                            containerData={cont}
                                            onSubmit={handleSubmit}
                                            onDateChange={handleDateChange}
                                            onSymbolChange={handleSymbolChange}
                                            onDateReplicate={handleDateReplicate}
                                            onSymbolReplicate={handleSymbolReplicate}
                                        />
                                    ))}
                                </Grid></>)}

                            {isLongPage && (
                                <Grid container spacing={2} className="chart-row"
                                    sx={{
                                        height: '15em',
                                        justifyContent: 'space-around',
                                        width: '100%',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {containers.slice(8, 10).map((cont) => (
                                        <ChartContainer
                                            chartId={cont.chartId}
                                            containerData={cont}
                                            onSubmit={handleSubmit}
                                            onDateChange={handleDateChange}
                                            onSymbolChange={handleSymbolChange}
                                            onDateReplicate={handleDateReplicate}
                                            onSymbolReplicate={handleSymbolReplicate}
                                        />
                                    ))}
                                </Grid>)}
                        </>
                    )}
                </Box>
            </Container>
        </>);
}
