// PriceChart.js
import React, { useRef, useLayoutEffect, memo } from "react";
import { createChart, CrosshairMode } from "lightweight-charts"; // Removed LineSeries
import { tokens } from "../../theme";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Tooltip, Dialog } from '@mui/material';

// Simple deep equality using JSON.stringify.
function deepEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

/**
 * Convert each data point's "value" from string to number
 * so that line series data is numeric.
 */
function parseChartData(data) {
  if (!Array.isArray(data)) return [];
  return data.map(item => {
    if (item && typeof item === "object" && item.value !== undefined) {
      return {
        ...item,
        value: parseFloat(item.value),
      };
    }
    return item;
  });
}

const ChatbotPriceChart = memo(
  ({
    priceData = [],
    chartTitle,
    symbol,
    seriesNames = [],
    onChartReady,
    onSeriesDataReady
  }) => {
    const chartContainer = useRef(null);
    const chart = useRef(null);
    const lineSeriesRef = useRef([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Create the chart instance once.
    useLayoutEffect(() => {
      if (!chartContainer.current) return;

      // Determine if data is empty.
      const isEmpty =
        Array.isArray(priceData) &&
        priceData.every(series =>
          Array.isArray(series) ? series.length === 0 : false
        );

      const chartOptions = {
        width: chartContainer.current.offsetWidth,
        height: chartContainer.current.offsetHeight,
        watermark: {
          text: isEmpty ? `${chartTitle}-NoData` : symbol,
          fontSize: 18,
          color: theme.palette.chart.watermark,
          visible: true,
          horzAlign: 'center',
          vertAlign: 'top',
        },
        rightPriceScale: { visible: true },
        leftPriceScale: { visible: true },
        timeScale: {
          rightOffset: 5,
          barSpacing: 6,
          fixLeftEdge: true,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: true,
          visible: true,
          timeVisible: false,
          secondsVisible: true,
        },
        crosshair: {
          mode: CrosshairMode.Magnet,
        },
        layout: {
          background: { color: theme.palette.table.odd },
          textColor: theme.palette.text.secondary,
        },
        grid: {
          vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
          horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
        },
      };

      chart.current = createChart(chartContainer.current, chartOptions);
      console.log("Chart instance:", chart.current);

      // For the "Predicted Trend" chart, apply a custom price formatter.
      if (chartTitle === "Predicted Trend") {
        chart.current.applyOptions({
          localization: {
            priceFormatter: (price) =>
              (price / 1_000_000).toLocaleString("en-US", {
                maximumFractionDigits: 2,
              }),
          },
        });
      }

      if (typeof chart.current.addLineSeries !== "function") {
        console.error("Chart instance missing addLineSeries function!");
      }
      if (onChartReady) {
        onChartReady(chart.current, chartTitle);
      }

      return () => {
        if (chart.current) {
          chart.current.remove();
          chart.current = null;
        }
        lineSeriesRef.current = [];
      };
    }, [symbol, chartTitle, onChartReady]);

    // Update series data when priceData changes.
    useLayoutEffect(() => {
      if (!chart.current) return;

      // Define colors based on chartTitle.
      const lineColors = {
        "Last": [colors.greenChart[100]],
        "%short": [colors.redChart[100], colors.greenChart[100]],
        "Predicted Trend": ["#2ec6f4"],
      }[chartTitle] || ["gray"];

      if (chartTitle === "%short" && Array.isArray(priceData) && priceData.length >= 2) {
        const data1 = parseChartData(priceData[0]);
        const data2 = parseChartData(priceData[1]);

        if (lineSeriesRef.current.length === 2) {
          // Update the existing series.
          lineSeriesRef.current[0].setData(data1);
          lineSeriesRef.current[1].setData(data2);
        } else {
          // Remove any existing series and add new ones.
          lineSeriesRef.current.forEach(s => chart.current.removeSeries(s));
          lineSeriesRef.current = [];
          const series1 = chart.current.addLineSeries({
            color: lineColors[0],
            lineWidth: 2,
            priceScaleId: "right",
          });
          const series2 = chart.current.addLineSeries({
            color: lineColors[1],
            lineWidth: 2,
            priceScaleId: "left",
          });
          series1.setData(data1);
          series2.setData(data2);
          lineSeriesRef.current.push(series1, series2);
        }
      } else {
        // Single series case.
        const numericData = parseChartData(priceData);
        if (lineSeriesRef.current.length === 1) {
          lineSeriesRef.current[0].setData(numericData);
        } else {
          lineSeriesRef.current.forEach(s => chart.current.removeSeries(s));
          lineSeriesRef.current = [];
          const singleSeries = chart.current.addLineSeries({
            color: lineColors[0],
            lineWidth: 2,
            lastValueVisible: true,
          });
          singleSeries.setData(numericData);
          lineSeriesRef.current.push(singleSeries);
        }
      }

      if (onSeriesDataReady && lineSeriesRef.current.length > 0) {
        onSeriesDataReady(chart.current, lineSeriesRef.current[0], priceData);
      }
    }, [priceData, chartTitle, symbol, onSeriesDataReady]);

    const outerStyle = {
      position: "relative",
      width: "100%",
      height: "100%",
    };

    return <div ref={chartContainer} style={outerStyle} />;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.chartTitle === nextProps.chartTitle &&
      prevProps.symbol === nextProps.symbol &&
      deepEqual(prevProps.priceData, nextProps.priceData) &&
      deepEqual(prevProps.seriesNames, nextProps.seriesNames)
    );
  }
);

export default ChatbotPriceChart;
