import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef, } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';
// import axios from 'axios';
import { FaSearch, FaTimes } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยายและปิด
import { useDispatch, useSelector } from 'react-redux';
import Loading from './Loading';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { IconButton, Box, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoopIcon from '@mui/icons-material/Loop';  // นำเข้า LoopIcon
import FontLoader from '../../components/FontLoader';

const API_BASE_URL = "http://172.18.1.81:3100/money_in_out/hist/";

let colorIndex = 0;
const softDarkColors = [
  "#ffcc00", // เหลืองสดใส (Vivid Yellow)
  "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
  "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
  "#ff9966", // ส้มสด (Bright Orange)
  "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
  "#ffffff", // ขาว (White)
  "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
  "#ff66b3", // ชมพูสดใส (Hot Pink)
  "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
  "#3399ff", // ฟ้าสดใส (Sky Blue)
  "#ff9933", // ส้มอ่อนสด (Bright Peach)
  "#ff3399", // ชมพูบานเย็น (Magenta Pink)
  "#33ff66", // เขียวสด (Vivid Green)
  "#ff9966", // พีชสดใส (Peach Orange)
  "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
  "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
  "#66ccff", // ฟ้าสดกลาง (Electric Blue)
  "#33ccff", // ฟ้าสด 
  "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
  "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
];

const getRandomColor = () => {
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
  return color;
};


// ฟังก์ชันปรับเวลาให้ตรงกับเขตเวลาท้องถิ่น
const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // สร้าง Date จาก timestamp
  const timezoneOffset = date.getTimezoneOffset() * 60; // หาค่าชดเชย timezone ในหน่วยวินาที
  return timestamp - timezoneOffset; // ปรับ timestamp ให้ตรงกับ Local Time
};

const ChartSectionWithMultipleLines = ({ title, market, type, selectedDate, }) => {
  const chartContainerRef = useRef(null);
  const chartTableContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());
  const highlightedSeriesRef = useRef(null); // เก็บซีรีส์ที่ถูกไฮไลต์
  const [tableData, setTableData] = useState([]);
  const [previousData, setPreviousData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null); // เก็บ symbol ของแถวที่ถูกเลือก
  const [isFullscreen, setIsFullscreen] = useState(false); // สถานะโหมดขยาย
  const priceLinesRef = useRef(new Map());  // ใช้ useRef เพื่อเก็บเส้นราคา
  const priceToSymbolMap = useRef(new Map());
  const [highlightedSymbol, setHighlightedSymbol] = useState(null);
  const csrfToken = useSelector((state) => state.csrfToken)
  const [highlightedRow, setHighlightedRow] = useState(null);

  const [isCollapsed, setIsCollapsed] = useState(false);

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();

  const historicalDataRef = useRef({});

  // ฟังก์ชันรีเซ็ตไฮไลต์
  const resetHighlight = () => {
    lineSeriesRef.current.forEach((seriesData) => {
      seriesData.series.applyOptions({
        color: getRandomColor(), // ใช้สีสุ่ม
        lineWidth: 2, // รีเซ็ตความหนาเส้น
        visible: true, // ทำให้เส้นอื่นๆ แสดง
      });
    });

    // รีเซ็ตข้อมูลการไฮไลท์
    highlightedSeriesRef.current = null;
    setHighlightedSymbol(null); // รีเซ็ตสถานะสัญลักษณ์ที่ไฮไลต์
  };

  // ฟังก์ชันไฮไลต์เส้นกราฟ
  const highlightLineSeries = (symbol) => {
    const seriesData = lineSeriesRef.current.get(symbol);
    if (!seriesData) {
      console.warn(`Series not found for symbol: ${symbol}`);
      return;
    }

    // ไฮไลต์เส้นที่เลือก
    const series = seriesData.series;
    if (series) {
      // ตั้งค่าความโปร่งใสให้เส้นอื่น ๆ
      lineSeriesRef.current.forEach((data) => {
        if (data.series !== series) {
          data.series.applyOptions({
            color: 'rgba(181, 101, 29, 0.5)', // สีพื้นฐาน
            lineWidth: 2, // ความหนาพื้นฐาน
          });
        }
      });

      series.applyOptions({
        lineWidth: 4,
        color: '#ffffb3',
        visible: true, // ทำให้เส้นที่เลือกแสดง
      });

      highlightedSeriesRef.current = series;
      console.log(`Highlighted series for symbol: ${symbol}`);
    } else {
      console.warn(`Series object not valid for symbol: ${symbol}`);
    }
  };

  const onClickRow = (symbol) => {
    console.log("Row clicked:", symbol);

    if (!historicalDataRef.current[symbol]) {
      console.warn(`No data available for symbol: ${symbol}`);
      return;
    }

    const selectedRank = tableData.find((row) => row.symbol === symbol)?.rank;
    if (!selectedRank) {
      console.warn(`Could not determine rank for symbol: ${symbol}`);
      return;
    }

    const top5Symbols = tableData
      .sort((a, b) => a.rank - b.rank)
      .slice(0, 5)
      .map((row) => row.symbol);

    if (symbol === highlightedSymbol) {
      // 🟢 Click same symbol again → Restore full Top 5 with NEW RANDOM COLORS
      console.log(`Restoring full Top 5 with random colors`);

      lineSeriesRef.current.forEach(({ series }) => {
        chartRef.current.removeSeries(series);
      });
      lineSeriesRef.current.clear();

      top5Symbols.forEach((topSymbol) => {
        const newColor = getRandomColor(); // สุ่มสีใหม่ทุกครั้งที่รีเซ็ต
        const series = chartRef.current.addLineSeries({
          color: newColor,
          lineWidth: 2,
          title: topSymbol,
          priceLineVisible: false,
        });

        series.setData(historicalDataRef.current[topSymbol]);
        lineSeriesRef.current.set(topSymbol, { series, symbol: topSymbol });

        // 🟢 บันทึกสีใหม่ของแต่ละ symbol
        originalColorsRef.current.set(topSymbol, newColor);
      });

      setHighlightedSymbol(null); // ล้างไฮไลต์
      setHighlightedRow(null);
    } else if (top5Symbols.includes(symbol)) {
      // 🟢 Click Top 5 → Highlight selected & dim others
      console.log(`Highlighting ${symbol} within Top 5`);

      lineSeriesRef.current.forEach(({ series }) => {
        chartRef.current.removeSeries(series);
      });
      lineSeriesRef.current.clear();

      top5Symbols.forEach((topSymbol) => {
        const isHighlighted = topSymbol === symbol;
        const originalColor = originalColorsRef.current.get(topSymbol) || getRandomColor();

        const series = chartRef.current.addLineSeries({
          color: isHighlighted ? "#ffffb3" : "rgba(181, 101, 29, 0.5)", // ไฮไลต์เฉพาะเส้นที่กด
          lineWidth: isHighlighted ? 4 : 2,
          title: topSymbol,
          priceLineVisible: false,
        });

        series.setData(historicalDataRef.current[topSymbol]);
        lineSeriesRef.current.set(topSymbol, { series, symbol: topSymbol });
      });

      setHighlightedSymbol(symbol);
      setHighlightedRow(symbol);
    } else if (selectedRank > 5) {
      // 🟢 Click Rank 6-20 → Remove all existing lines & show only clicked one
      console.log(`Displaying ONLY ${symbol} (Rank ${selectedRank})`);

      lineSeriesRef.current.forEach(({ series }) => {
        chartRef.current.removeSeries(series);
      });
      lineSeriesRef.current.clear();

      const series = chartRef.current.addLineSeries({
        color: getRandomColor(),
        lineWidth: 2,
        title: symbol,
        priceLineVisible: false,
      });

      series.setData(historicalDataRef.current[symbol]);
      lineSeriesRef.current.set(symbol, { series, symbol });

      setHighlightedSymbol(symbol);
      setHighlightedRow(symbol);
    }
  };

  // ฟังก์ชันจัดการคลิกที่พื้นที่ว่างในกราฟ
  const handleChartClick = (e) => {
    if (!e.target.closest('.rank-table')) {
      if (highlightedSeriesRef.current) {
        console.log("Skip reset on chart click. Highlighted series exists.");
        return; // ถ้ามีเส้นไฮไลต์อยู่ ไม่รีเซ็ต
      }
      resetHighlight();
    }
  };



  // ฟังก์ชันขยายกราฟ
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleExpandClick = () => {
    onExpand(title, market, type, selectedDate);
  };

  // ตั้งค่า Event Listener สำหรับการคลิก
  useEffect(() => {
    document.addEventListener('click', handleChartClick);

    return () => {
      document.removeEventListener('click', handleChartClick);
    };
  }, []);

  // ฟังก์ชันกำหนดสีของแถวในตาราง
  const getRowColor = (symbol, value) => {
    if (symbol === highlightedRow) return '#B8860B'; // สีฟ้าสำหรับแถวที่ถูกเลือก
    const previousValue = previousData[symbol];
    if (previousValue === undefined) return 'white';
    if (value > previousValue) return '#e8f5e9'; // สีเขียวสำหรับค่าที่เพิ่มขึ้น
    if (value < previousValue) return '#ffebee'; // สีแดงสำหรับค่าที่ลดลง
    return 'white';
  };

  const fetchHistoricalData = async (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    try {
      setIsLoading(true);
      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/hist/${market}?date=${formattedDate}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      });

      const data = await fetch_response.json();
      if (data && data.data && data.data[type]) {
        const dataSource = data.data[type];
        const historicalData = {};

        Object.keys(dataSource.rank).forEach((time) => {
          if (dataSource.rank[time] && dataSource.value[time]) {
            Object.keys(dataSource.rank[time]).forEach((symbol) => {
              if (!historicalData[symbol]) {
                historicalData[symbol] = [];
              }

              const timestamp = Math.floor(new Date(`${formattedDate}T${time}`).getTime() / 1000);
              const value = dataSource.value[time][symbol];
              if (value === undefined || isNaN(value)) return;

              const adjustedValue = type === 'out' ? -value / 1000000 : value / 1000000;
              const previousData = historicalData[symbol][historicalData[symbol].length - 1];
              const pct_change = previousData ? ((adjustedValue - previousData.value) / Math.abs(previousData.value)) * 100 : null;

              historicalData[symbol].push({
                time: adjustToLocalTime(timestamp),
                rank: dataSource.rank[time][symbol],
                value: adjustedValue,
                pct_change: pct_change,  // เพิ่ม pct_change ที่นี่
              });

              priceToSymbolMap.current.set(value, symbol);
            });
          }
        });

        // ✅ Store historicalData in useRef so that onClickRow can access it
        historicalDataRef.current = historicalData;

        // Show only top 5 symbols on initial render
        const top5Symbols = Object.keys(historicalData)
          .map((symbol) => {
            const latestData = historicalData[symbol][historicalData[symbol].length - 1];
            return { symbol, rank: latestData.rank };
          })
          .sort((a, b) => a.rank - b.rank)
          .slice(0, 5);

        top5Symbols.forEach(({ symbol }) => {
          if (!lineSeriesRef.current.has(symbol)) {
            const series = chartRef.current.addLineSeries({
              color: getRandomColor(),
              lineWidth: 2,
              title: symbol,
              priceLineVisible: false,
            });
            lineSeriesRef.current.set(symbol, { series, symbol });
          }

          lineSeriesRef.current.get(symbol).series.setData(
            historicalData[symbol].map((dataPoint) => ({
              time: dataPoint.time,
              value: dataPoint.value,
            }))
          );
        });


        chartRef.current.timeScale().fitContent();
        chartRef.current.timeScale().applyOptions({ rightOffset: 50 });

        setTableData(Object.keys(historicalData).map(symbol => {
          const latestData = historicalData[symbol][historicalData[symbol].length - 1];
          return {
            symbol,
            value: latestData.value,
            rank: latestData.rank,
            pct_change: latestData.pct_change,
          };
        }));
        setIsLoading(false);
      } else {
        console.warn("Data is not available or incomplete.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(`Error fetching data:`, error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // สร้างกราฟเมื่อคอมโพเนนต์ถูก mount
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'white', background: { type: 'solid', color: '#575b69' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
    });

    // 🟢 ทำให้ขอบของ container มีมุมโค้งมน
    chartContainerRef.current.style.borderRadius = '10px';
    chartContainerRef.current.style.height = (isMobile || isTablet || isPTablet) ? '250px' : '305px';
    chartContainerRef.current.style.overflow = 'hidden'; // ป้องกันกราฟล้นขอบ
    chartContainerRef.current.style.outline = '1px solid #ddd'; // ใช้ outline แทนเส้นขอบ



    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
        borderColor: '#ddd',
        scaleMargins: {
          top: 0.2,
          bottom: 0.2,
        },
      },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },

    });
    // เพิ่มการตั้งค่า textColor ของ price scale
    chartRef.current.priceScale('right').applyOptions({
      textColor: '#575b69', // ตั้งสีข้อความของ price scale
    });



    fetchHistoricalData(selectedDate);

    // ทำความสะอาดเมื่อคอมโพเนนต์ unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
  }, [market, type, selectedDate]);

  const renderTables = () => {
    // const firstTableData = tableData.sort((a, b) => b.value - a.value).slice(0, 10);
    // const secondTableData = tableData.sort((a, b) => b.value - a.value).slice(10, 20);

    const sortedData = tableData.sort((a, b) => b.value - a.value);

    const renderTable = (data, startIndex) => (
      <table
        className="rank-table"
        style={{
          marginLeft: (isMobile || isPTablet) ? '0' : (isTablet && !isPTablet) ? '0' : '5px',
          marginTop: (isMobile || isPTablet) ? '0' : (isTablet && !isPTablet) ? '0' : '70px',
          maxHeight: '230px',
          overflowY: 'auto',
          fontFamily: 'Lato, Sans-serif',
          fontSize: '10.5px', // ลดขนาดฟอนต์
          borderCollapse: 'collapse', // ทำให้เส้นชิดกัน
          width: (isMobile || isPTablet) ? '40%' : (isTablet && !isPTablet) ? '40%' : '80%', // ปรับขนาดตาราง
          border: '1px solid #ddd', // เส้นขอบตาราง




        }}>
        <thead>
          <tr>
            <th style={{ padding: '5px', backgroundColor: '#283593', color: 'white' }}>Rank</th>
            <th style={{ padding: '5px', backgroundColor: '#283593', color: 'white' }}>Symbol</th>
            <th style={{ padding: '5px', backgroundColor: '#283593', color: 'white' }}>Value</th>
            <th style={{ padding: '5px', backgroundColor: '#283593', color: 'white' }}>%change</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            const isHighlighted = highlightedSymbol === row.symbol; // ตรวจสอบว่าแถวนี้ถูกไฮไลต์หรือไม่

            return (
              <tr
                key={index}
                style={{
                  backgroundColor: isHighlighted ? getRowColor(row.symbol, row.value) : "#f8f9fa", // สีปกติ vs ไฮไลต์
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  borderBottom: "1px solid #ddd",
                }}
                onClick={() => onClickRow(row.symbol)}
              >
                <td style={{ padding: "5px  0px ", textAlign: "center", backgroundColor: isHighlighted ? getRowColor(row.symbol, row.value) : "#636a7e", color: "#fff" }}>
                  {startIndex + index + 1}
                </td>
                <td style={{ padding: "5px  0px ", textAlign: "center", backgroundColor: isHighlighted ? getRowColor(row.symbol, row.value) : "#636a7e", color: "#fff" }}>
                  {row.symbol}
                </td>
                <td style={{ padding: "5px  0px ", textAlign: "center", backgroundColor: isHighlighted ? getRowColor(row.symbol, row.value) : "#636a7e", color: "#fff" }}>
                  {type === "out" ? `-${Math.abs(row.value).toLocaleString()}` : row.value.toLocaleString()}
                </td>
                <td style={{ padding: "5px  0px ", textAlign: "center", backgroundColor:
                    row.pct_change > 0
                      ? '#228B22'
                      : row.pct_change < 0
                      ? '#C8342A'
                      : '#575b69' }}>
                  {row.pct_change !== undefined && row.pct_change !== null ? row.pct_change.toFixed(2)  : "-"}
                </td>

              </tr>
            );
          })}
        </tbody>

      </table>
    );

    if (isMobile || isPTablet) {
      // สำหรับ mobile: แสดง 20 แถวในตารางเดียว
      return (
        <div style={{
          display: 'flex', justifyContent: 'center',
        }}>
          {renderTable(sortedData.slice(0, 20), 0)}
        </div>
      );
    } else if (isTablet & !isPTablet) {
      // สำหรับ mobile: แสดง 20 แถวในตารางเดียว
      return (
        <div style={{
          display: 'flex', justifyContent: 'center',
        }}>
          {renderTable(sortedData.slice(0, 20), 0)}
        </div>
      );
    } else {
      // สำหรับหน้าจอใหญ่: แบ่งตารางเป็น 2 ส่วน
      const firstTableData = sortedData.slice(0, 10);
      const secondTableData = sortedData.slice(10, 20);

      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            columnGap: '-80px',
            marginTop: '-70px',


          }}
        >
          {renderTable(firstTableData, 0)}
          {renderTable(secondTableData, 10)}
        </div>
      );
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // เปลี่ยนสถานะพับ/ขยาย
  };

  // Dynamic Chart Width

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isCollapsed]);

  const onChartTouch = (event, allowTouchMove) => {
    if (chartTableContainerRef.current && chartTableContainerRef.current.contains(event.target)) {
      window.mySwiper.allowTouchMove = allowTouchMove;
    }
  };


  return (
    <div
      className={`chart-section ${isFullscreen ? 'fullscreen' : ''}`}
      style={
        isFullscreen
          ? {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 1000,
            backgroundColor: 'white', // เปลี่ยนสีพื้นหลัง
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // เพิ่มเงา
          }
          : {
            width: '100%',
          }
      }
    >
      {/* ส่วนหัวข้อกราฟพร้อมปุ่ม Expand */}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px', marginTop: '10px', fontFamily: 'Lato, Sans-serif', }}>
        <FontLoader/>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 style={{
            margin: 0,
            marginLeft: isFullscreen ? '750px' : '10px',
            textAlign: isFullscreen ? 'center' : 'left',

          }}>{title}</h3>
          {isLoading ? (
            <div style={{ marginTop: '20px' }}>
              <Loading />
            </div>
          ) : tableData.length === 0 ? (
            <span style={{ fontStyle: 'italic', color: '#888', marginLeft: '10px' }}>No Data Available</span>
          ) : null}


        </div>


        {/* <button
          onClick={handleExpandClick}
          style={{
            padding: '5px 15px',
            marginRight: '550px',
            backgroundColor: 'none',
            color: 'black',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {isFullscreen ? <FaTimes size={20} /> : <FaSearch size={20} />}
        </button> */}
        {(isMobile || isTablet) && (<IconButton
          variant="contained"
          onClick={toggleCollapse}

        // sx={{ marginBottom: '10px' }}
        >
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>)}
      </div>

      {/* ส่วนของกราฟและตาราง */}
      {(!isMobile && !isTablet) && (
        <div style={{ display: 'flex', flexDirection: isFullscreen ? 'column' : 'row', gap:'100px' }}>

          <div
            ref={chartContainerRef}
            style={{
              flex: isFullscreen ? 0 : isMobile ? 1 : 1,
              marginLeft: isFullscreen ? '550px' : isMobile ? '0' : '-20px', // ระยะห่างจากกราฟหลักเมื่ออยู่ในโหมด fullscreen
              maxWidth: isMobile ? '100vw' : 'none',
              minWidth: '150px', // กำหนดขนาดขั้นต่ำ
              width: isFullscreen ? '700px' : isMobile ? '100vw' : '200px', // กราฟขยายเต็มจอในโหมด Fullscreen
              height: isFullscreen ? '60vh' : '290px', // ความสูงของกราฟในโหมด Fullscreen
              marginTop: isFullscreen ? '50px' : '0', // เว้นระยะในโหมด Fullscreen
              transition: 'all 0.3s ease',


            }}
          ></div>


          <div style={{
            flex: isFullscreen ? 0 : isMobile ? 1 : 0.8,
            padding: isFullscreen ? '10px' : '0px',
            width: isFullscreen ? '500px' : '0px',
            height: isFullscreen ? '500px' : '0px',
            marginTop: isFullscreen ? '100px' : '0',
            marginLeft: isFullscreen ? '600px' : '-40px',

          }}>{renderTables()}</div>
        </div>
      )}

      {(isMobile || isTablet || isPTablet) && (
        <div className="Chart-and-Table" ref={chartTableContainerRef} style={{
          display: 'flex',
          flexDirection: isFullscreen ? 'column' : 'row',
          gap: '10px'
        }}>
          <div
            ref={chartContainerRef}
            style={{
              // flex: isFullscreen ? 0 : isMobile || isTablet ? 1 : 1,
              marginLeft: isFullscreen ? '550px' : isMobile || isTablet ? '0' : '-10px', // ระยะห่างจากกราฟหลักเมื่ออยู่ในโหมด fullscreen
              maxWidth: isMobile || isTablet ? '100vw' : 'none',
              width: isFullscreen ? '1700px' : (isMobile || isTablet) ? isCollapsed ? '100%' : '55%' : '660px', // กราฟขยายเต็มจอในโหมด Fullscreen
              height: isFullscreen ? '60vh' : '290px', // ความสูงของกราฟในโหมด Fullscreen
              marginTop: isFullscreen ? '50px' : '0', // เว้นระยะในโหมด Fullscreen
              transition: 'all 0.3s ease',
            }}
            onTouchStart={(event) => onChartTouch(event, false)}
            onTouchEnd={(event) => onChartTouch(event, true)}
          >
          </div>


          {!isCollapsed && (
            <Box className="table-of-chart" sx={{
              // flex: isFullscreen ? 0 : isMobile || isTablet ? 1 : 0.8,
              padding: isFullscreen ? '10px' : '0px',
              maxWidth: isFullscreen ? '500px' : isMobile || isTablet ? '50%' : '40%',
              // height: isFullscreen ? '500px' : isMobile || isTablet ? '300px' : '0',
              marginTop: isFullscreen ? '100px' : '0',
              marginLeft: isFullscreen ? '600px' : '0',
              maxHeight: '250px',
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },

            }}>{renderTables()}</Box>
          )}
        </div>
      )}

    </div>
  );

};

export default ChartSectionWithMultipleLines;
