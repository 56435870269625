import React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { tokens } from '../../../theme';

function Navbar() {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // ฟังก์ชันเช็คว่าเส้นทางตรงกับลิงค์หรือไม่
  const isActive = (path) => location.pathname === path;

  return (
    <Box
  sx={{
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Box
    sx={{
      display: 'flex',
      gap: '12px', // เพิ่มระยะห่างระหว่างปุ่ม
      padding: '10px',
      borderRadius: '8px',
      backgroundColor: colors.surface[20], // พื้นหลังรองปุ่มให้ดูมีมิติ
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    }}
  >
    {[
      { label: "News", href: "/allnews", color: "#1E3A8A" }, // น้ำเงินกรมท่าเข้ม
      { label: "Accept News", href: "/acceptnews", color: "#1B5E20" }, // เขียวเข้ม
      { label: "Decline News", href: "/declinenews", color: "#B71C1C" }, // แดงเข้ม
    ].map((item) => (
      <Button
        key={item.href}
        href={item.href}
        sx={{
          backgroundColor: isActive(item.href) ? item.color : "#3B4252", // พื้นหลังปุ่มเมื่อถูกเลือก
          color: "#FFFFFF", // สีตัวอักษรขาว
          fontWeight: 'bold',
          padding: '12px 20px', // ขนาดกำลังดี
          borderRadius: '8px',
          textTransform: 'none',
          transition: 'all 0.3s ease-in-out',
          boxShadow: isActive(item.href) 
            ? '0px 4px 8px rgba(0, 0, 0, 0.3)' 
            : '2px 2px 5px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            backgroundColor: isActive(item.href) ? item.color : "#525C69", // Hover เป็นโทนเข้มขึ้น
            transform: 'scale(1.05)',
          },
          '&:active': {
            backgroundColor: item.color, // Active เป็นสีเข้มสุด
            transform: 'scale(0.95)',
          },
        }}
      >
        {item.label}
      </Button>
    ))}
  </Box>
</Box>
  );
}

export default Navbar;
