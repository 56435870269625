import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from 'react-redux';

// const HOTSTOCK_API = `${window.config.base_api_url}/api/hotstock`;

function HotStockSection() {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const [hotStockData, setHotStockData] = useState([]);
  const csrfToken = useSelector(state => state.csrfToken);

  useEffect(() => {
    async function fetchHotStockData() {
      try {
        const response = await fetch(`${window.config.base_api_url}/api/hotstock`, {
          headers: {
            'Content-Type': 'application/json',
            // Uncomment below if you require the xsrf-token:
            'xsrf-token': csrfToken,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        // Example response shape: { "XRPTHB": -12, "DELTA": 5, ... }
        const data = await response.json();
        const arrayData = Object.keys(data).map((symbol) => ({
          symbol,
          score: data[symbol],
        }));
        // Sort: negative scores first
        arrayData.sort((a, b) => a.score - b.score);
        setHotStockData(arrayData);
      } catch (error) {
        console.error('Error fetching hotstock data:', error);
      }
    }
    fetchHotStockData();
  }, [csrfToken]);

  useEffect(() => {
    if (!chartContainerRef.current || !hotStockData.length) return;
    if (chartRef.current) {
      chartRef.current.remove();
    }
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        background: { type: 'solid', color: 'white' },
        textColor: '#333',
      },
      rightPriceScale: {
        visible: true,
        borderVisible: false,
      },
      timeScale: {
        timeVisible: false, // we fake the x-axis
        borderVisible: false,
      },
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
    });
    chartRef.current = chart;

    const histogramSeries = chart.addHistogramSeries({
      base: 0,
      priceFormat: {
        type: 'price',
        precision: 2,
        minMove: 0.01,
      },
    });

    const histogramData = hotStockData.map((item, index) => ({
      time: index + 1, // fake x-axis index
      value: item.score,
      color: item.score < 0 ? '#ff4d4f' : '#4caf50',
    }));
    histogramSeries.setData(histogramData);

    // Customize x-axis labels to display the symbol instead of a date/time
    chart.applyOptions({
      timeScale: {
        tickMarkFormatter: (time) => {
          const itemIndex = time - 1;
          return hotStockData[itemIndex] ? hotStockData[itemIndex].symbol : '';
        },
      },
    });

    chart.timeScale().fitContent();

    return () => {
      chart.remove();
    };
  }, [hotStockData]);

  return (
    <div style={{ marginBottom: '40px' }}>
      <h2>Hot Stock (Scores)</h2>
      <div ref={chartContainerRef} style={{ width: '100%', height: '400px' }} />
    </div>
  );
}

export default HotStockSection;
