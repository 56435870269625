import { useState, useEffect, useRef, useCallback } from 'react'

import { createChart } from 'lightweight-charts';
import { Box, Autocomplete, TextField, useTheme, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../../theme";
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import LoopIcon from '@mui/icons-material/Loop';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../../components/hooks';


import { useDispatch, useSelector } from "react-redux";


//const symbolsToSelect = ['segs','rgwesge','rsgsegs','esgaewsg','esfgsg']

const FavoriteListPopup = ({
  selectedFavorite,
  setSelectedFavorite,
  symbolSelected,  // Make sure to pass this from the parent component
  applyFavoriteSymbols,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [favorites, setFavorites] = useState([]);
  const [openSettings, setOpenSettings] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const [editedFavoriteName, setEditedFavoriteName] = useState('');
  const [editedSymbols, setEditedSymbols] = useState([]);
  const [symbolArray, setSymbolArray] = useState([]); // Track selected symbols

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();


  // Load favorites from localStorage on mount
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favoriteLists22')) || [];
    setFavorites(storedFavorites);
  }, []);

  // Save favorites to localStorage whenever the favorites list changes
  useEffect(() => {
    localStorage.setItem('favoriteLists22', JSON.stringify(favorites));
  }, [favorites]);

  // Update symbolArray whenever symbolSelected changes
  useEffect(() => {
    const selectedSymbols = symbolSelected;
    // console.log('symbol in fav is', symbolSelected);
    setSymbolArray(selectedSymbols);  // Update symbolArray
  }, [symbolSelected]);
  // console.log('array is', symbolArray);

  const handleFavoriteSelect = (event, value) => {
    if (value) {
      setSelectedFavorite(value);
      setSymbolArray(value.symbols);  // Set the symbols for the selected favorite
      applyFavoriteSymbols(value.symbols);  // Apply the selected symbols
    }
  };

  const addToFavorites = () => {
    if (!symbolArray.length) return; // Ensure we have symbols to add

    const updatedFavorites = selectedFavorite
      ? favorites.map((fav) =>
        fav.name === selectedFavorite.name
          ? { ...fav, symbols: [...symbolArray] } // Update symbols of existing favorite
          : fav
      )
      : [...favorites, { name: `Favorite ${favorites.length + 1}`, symbols: [...symbolArray] }]; // Add new favorite

    setFavorites(updatedFavorites);
    setSelectedFavorite(null);
    setSymbolArray([]); // Clear symbolArray after adding to favorites
  };

  const handleOpenSettings = (option) => {
    setEditedFavoriteName(option.name);
    setEditedSymbols(option.symbols);
    setSelectedFavorite(option);
    setOpenSettings(true);
  };

  const handleSaveSettings = () => {
    const updatedFavorites = favorites.map((fav) =>
      fav.name === selectedFavorite.name
        ? { ...fav, name: editedFavoriteName, symbols: editedSymbols } // Save edited symbols and name
        : fav
    );
    setFavorites(updatedFavorites);

    const updatedSelected = updatedFavorites.find(fav => fav.name === editedFavoriteName);
    setSelectedFavorite(updatedSelected || null);

    setOpenSettings(false);
  };

  const handleOpenDeleteConfirm = (option) => {
    setOptionToDelete(option);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteFavorite = () => {
    const updatedFavorites = favorites.filter((fav) => fav.name !== optionToDelete.name);
    setFavorites(updatedFavorites);
    if (selectedFavorite?.name === optionToDelete.name) {
      setSelectedFavorite(null);
    }
    setOpenDeleteConfirm(false);
  };

  useEffect(() => {
    if (selectedFavorite) {
      const updatedFav = favorites.find(fav => fav.name === selectedFavorite.name);
      if (updatedFav) {
        setSelectedFavorite(updatedFav); // อัปเดตค่าใหม่
      }
    }
  }, [favorites]);

  return (
    <div style={{ marginBottom: '10px', position: 'absolute', right: isMobile ? '15%' : '10%', top: '10px', display: 'flex', flexDirection: 'row', gap: '1vw' }}>
      <Autocomplete
        disablePortal
        id='favoriteList'
        size='small'
        options={favorites}
        value={selectedFavorite}
        getOptionLabel={(option) => option.name}
        onChange={(event, value) => {
          if (!value) {
            // กดล้างหรือเลือก 'null' -> ล้าง symbolArray
            setSelectedFavorite(null);
            setSymbolArray([]);
          } else {
            handleFavoriteSelect(event, value);
          }
        }}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{option.name}</span>
            <div>
              <IconButton size='small' onClick={() => handleOpenSettings(option)}>
                <EditIcon />
              </IconButton>
              <IconButton size='small' onClick={() => handleOpenDeleteConfirm(option)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </li>
        )}
        renderInput={(params) => <TextField {...params} label='Favorite List' />}
        sx={{ width: '150px' }}
      />
      <Button
        variant='contained'
        startIcon={selectedFavorite ? <SaveIcon /> : <FavoriteIcon />}
        onClick={addToFavorites}
        sx={{ backgroundColor: colors.orangeChart[200] }}
      >
        {selectedFavorite ? `Save` : 'Add to favorite'}
      </Button>

      <Dialog open={openSettings} onClose={() => setOpenSettings(false)}>
        <DialogTitle>Edit Favorite</DialogTitle>
        <DialogContent>
          <TextField
            label='Favorite Name'
            fullWidth
            value={editedFavoriteName}
            onChange={(e) => setEditedFavoriteName(e.target.value)}
            sx={{ mt: '1em' }}
          />
          {/* <Grid container spacing={2}>
                      {editedSymbols.map((symbol, index) => (
                          <Grid item xs={6} key={index}>
                              <TextField
                                  label={`Symbol ${index + 1}`}
                                  fullWidth
                                  value={symbol}
                                  onChange={(e) => handleSymbolChangeInSettings(index, e.target.value)}
                              />
                          </Grid>
                      ))}
                  </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSettings(false)} variant='text' sx={{ backgroundColor: 'transparent' }}>Cancel</Button>
          <Button onClick={handleSaveSettings} variant='contained' sx={{ backgroundColor: 'green' }} >Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {optionToDelete?.name} from your favorites?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(false)} variant='text' sx={{ backgroundColor: 'transparent' }}>Cancel</Button>
          <Button onClick={handleDeleteFavorite} variant='contained' sx={{ backgroundColor: 'red' }}>Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

import { useFetchingData } from '../../../components/api.js';

function App() {
  const { __fetching_data__ } = useFetchingData();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();

  const [selectedFavorite, setSelectedFavorite] = useState(null);

  const [symbols, setSymbols] = useState(null);
  const [symbolsToSelect, setSymbolsToSelect] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [selectedSymbol2, setSelectedSymbol2] = useState(null);
  const [selectedSymbol3, setSelectedSymbol3] = useState(null);
  const [selectedSymbol4, setSelectedSymbol4] = useState(null);
  const [showAutocomplete, setShowAutocomplete] = useState(true);
  const [showAutocomplete2, setShowAutocomplete2] = useState(true);
  const [showAutocomplete3, setShowAutocomplete3] = useState(true);
  const [showAutocomplete4, setShowAutocomplete4] = useState(true);
  const [prevSymbolRef, setPrevSymbol] = useState(null);
  const [prevSymbolRef2, setPrevSymbol2] = useState(null);
  const [prevSymbolRef3, setPrevSymbol3] = useState(null);
  const [prevSymbolRef4, setPrevSymbol4] = useState(null);


  const [layout, SetLayout] = useState(null);
  const [data, setData] = useState(null);
  const [data2, setData2] = useState(null);
  const [data3, setData3] = useState(null);
  const [data4, setData4] = useState(null);


  const [flow_acc, setFlow_acc] = useState(null);
  const [flow_acc2, setFlow_acc2] = useState(null);
  const [flow_acc3, setFlow_acc3] = useState(null);
  const [flow_acc4, setFlow_acc4] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);



  const chartContainerRef = useRef(null);
  const chartContainerRef2 = useRef(null);
  const chartContainerRef3 = useRef(null);
  const chartContainerRef4 = useRef(null);

  const wsRef = useRef(null);
  const wsRef2 = useRef(null);
  const wsRef3 = useRef(null);
  const wsRef4 = useRef(null);

  const chartInitializedRef = useRef(false);
  const chartInitializedRef2 = useRef(false);
  const chartInitializedRef3 = useRef(false);
  const chartInitializedRef4 = useRef(false);

  const [isZoomed, setIsZoomed] = useState(false);
  const [isZoomed2, setIsZoomed2] = useState(false);
  const [isZoomed3, setIsZoomed3] = useState(false);
  const [isZoomed4, setIsZoomed4] = useState(false);

  let previousLogicalRange = useRef(null);
  let previousLogicalRange2 = useRef(null);
  let previousLogicalRange3 = useRef(null);
  let previousLogicalRange4 = useRef(null);


  const updateInterval = useRef(null);
  const chartRef = useRef(null);

  const csrfToken = useSelector((state) => state.csrfToken);
  const base_api_url =
    process.env.NODE_ENV === "production"
      ? window.location.protocol === "https:"
        ? process.env.REACT_APP_API_HTTPS_URL
        : process.env.REACT_APP_API_HTTP_URL
      : "/application";

  const handleResetZoom = (chartId, setZoomState) => {
    setZoomState(false);
    if (chartId.current?.chart) {

      chartId.current.chart.timeScale().fitContent();
    }
  }

  const handleResetAllZoom = () => {
    if (chartContainerRef.current?.chart) {
      setIsZoomed(false);
      chartContainerRef.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef2.current?.chart) {
      setIsZoomed2(false);
      chartContainerRef2.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef3.current?.chart) {
      setIsZoomed3(false);
      chartContainerRef3.current.chart.timeScale().fitContent();
    }
    if (chartContainerRef4.current?.chart) {
      setIsZoomed4(false);
      chartContainerRef4.current.chart.timeScale().fitContent();
    }
  }

  //function press Home to timeScale().fitContent()
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Home') {
        if (chartContainerRef.current?.chart) {
          setIsZoomed(false);
          chartContainerRef.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef2.current?.chart) {
          setIsZoomed2(false);
          chartContainerRef2.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef3.current?.chart) {
          setIsZoomed3(false);
          chartContainerRef3.current.chart.timeScale().fitContent();
        }
        if (chartContainerRef4.current?.chart) {
          setIsZoomed4(false);
          chartContainerRef4.current.chart.timeScale().fitContent();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const fetchSymbol = async () => {
      try {
        const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_symbols`, headers: { "xsrf-token": csrfToken } });
        // const jsonData = await response.json();
        const symbolsData = jsonData.data.symbols
        setSymbolsToSelect(symbolsData)
        //console.log(symbolsToSelect)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSymbol();
  }, []);

  // //fetch data 1

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedSymbol || selectedSymbol == null || selectedSymbol == undefined) return;
      try {
        // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol)}` });
        const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol.toUpperCase())}` });

        if (!Array.isArray(jsonData.data)) {
          throw new Error('Response data is not an array');
        }

        const now = new Date();
        const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
        const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

        const extractedData = jsonData.data
          .map(item => {
            const date = new Date(item.time); // Assuming date is directly accessible now
            // Adjust the time to Thailand timezone
            date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
            return {
              time: date.getTime() / 1000,
              value: item.last,
            };
          })
          .filter(item => {
            const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
            return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
          });

        if (extractedData.length === 0) {
          setData(null);
        }
        else {
          setData(extractedData);
        }

        const extractedAcc = jsonData.data
          .map(item => {
            const date = new Date(item.time); // Assuming date is directly accessible now
            // Adjust the time to Thailand timezone
            date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
            return {
              time: date.getTime() / 1000,
              value: item.flow,
            };
          })
          .filter(item => {
            const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
            return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
          });
        if (extractedAcc.length === 0) {
          setFlow_acc(null);
        }
        else {
          setFlow_acc(extractedAcc);
        }



      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };

    // Fetch data initially
    fetchData();

    setTimeout(() => {
      fetchData(); // เรียกครั้งที่ 2 หลังจาก 1 วินาที
    }, 1000);


    if (wsRef.current) {
      console.log("Closing WebSocket...");
      wsRef.current.close();
    }

    try {
      const ws = new WebSocket(`ws://172.18.1.81:5002/ws/flow/${selectedSymbol.toUpperCase()}`);

      wsRef.current = ws;

      ws.onopen = () => console.log(`✅ WebSocket connected: ${selectedSymbol.toUpperCase()}`);

      ws.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          if (response.action === "re-fetch") {
            console.log(`🔄 Updated data for ${selectedSymbol.toUpperCase()}`);

            fetchData();
          }
        } catch (error) {
          console.error(" Error parsing WebSocket message:", error);
        }
      };

      ws.onerror = (error) => console.error(" WebSocket Error:", error);
      ws.onclose = () => console.log(" WebSocket disconnected");

      return () => {
        if (wsRef.current) {
          console.log(" Closing WebSocket...");
          wsRef.current.close();
        }
      };

    } catch {
      // Start a timer to fetch data every second
      const intervalId = setInterval(fetchData, 10000);

      // Cleanup function to clear the interval
      return () => clearInterval(intervalId);
    }
  }, [selectedSymbol]);

  // //fetch data 2
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!selectedSymbol2 || selectedSymbol2 == null || selectedSymbol2 == undefined) return;
  //     try {
  //       // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol2)}` });
  //       const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol2.toUpperCase())}` });
  //       // const jsonData = await response.json();

  //       if (!Array.isArray(jsonData.data)) {
  //         throw new Error('Response data is not an array');
  //       }

  //       const now = new Date();
  //       const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
  //       const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

  //       const extractedData = jsonData.data
  //         .map(item => {
  //           const date = new Date(item.time); // Assuming date is directly accessible now
  //           // Adjust the time to Thailand timezone
  //           date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
  //           return {
  //             time: date.getTime() / 1000,
  //             value: item.last,
  //           };
  //         })
  //         .filter(item => {
  //           const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
  //           return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
  //         });

  //       if (extractedData.length === 0) {
  //         setData2(null);
  //       }
  //       else {
  //         setData2(extractedData);
  //       }

  //       const extractedAcc = jsonData.data
  //         .map(item => {
  //           const date = new Date(item.time); // Assuming date is directly accessible now
  //           // Adjust the time to Thailand timezone
  //           date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
  //           return {
  //             time: date.getTime() / 1000,
  //             value: item.flow,
  //           };
  //         })
  //         .filter(item => {
  //           const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
  //           return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
  //         });
  //       if (extractedAcc.length === 0) {
  //         setFlow_acc2(null);
  //       }
  //       else {
  //         setFlow_acc2(extractedAcc);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching or processing data:', error);
  //     }
  //   };

  //   // Fetch data initially
  //   fetchData();

  //   setTimeout(() => {
  //     fetchData(); // เรียกครั้งที่ 2 หลังจาก 1 วินาที
  //   }, 1000);

  //   if (wsRef2.current) {
  //     console.log("Closing WebSocket...");
  //     wsRef2.current.close();
  //   }

  //   try {
  //     const ws = new WebSocket(`ws://172.18.1.81:5002/ws/flow/${selectedSymbol2.toUpperCase()}`);

  //     wsRef2.current = ws;

  //     ws.onopen = () => console.log(`✅ WebSocket connected: ${selectedSymbol2.toUpperCase()}`);

  //     ws.onmessage = (event) => {
  //       try {
  //         const response = JSON.parse(event.data);
  //         if (response.action === "re-fetch") {
  //           console.log(`🔄 Updated data for ${selectedSymbol2.toUpperCase()}`);

  //           fetchData();
  //         }
  //       } catch (error) {
  //         console.error(" Error parsing WebSocket message:", error);
  //       }
  //     };

  //     ws.onerror = (error) => console.error(" WebSocket Error:", error);
  //     ws.onclose = () => console.log(" WebSocket disconnected");

  //     return () => {
  //       if (wsRef2.current) {
  //         console.log(" Closing WebSocket...");
  //         wsRef2.current.close();
  //       }
  //     };

  //   } catch {
  //     // Start a timer to fetch data every second
  //     const intervalId = setInterval(fetchData, 10000);

  //     // Cleanup function to clear the interval
  //     return () => clearInterval(intervalId);
  //   }
  // }, [selectedSymbol2]);

  // //fetch data 3
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!selectedSymbol3 || selectedSymbol3 == null || selectedSymbol3 == undefined) return;
  //     try {
  //       // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol3)}` });
  //       const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol3.toUpperCase())}` });
  //       // const jsonData = await response.json();

  //       if (!Array.isArray(jsonData.data)) {
  //         throw new Error('Response data is not an array');
  //       }

  //       const now = new Date();
  //       const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
  //       const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

  //       const extractedData = jsonData.data
  //         .map(item => {
  //           const date = new Date(item.time); // Assuming date is directly accessible now
  //           // Adjust the time to Thailand timezone
  //           date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
  //           return {
  //             time: date.getTime() / 1000,
  //             value: item.last,
  //           };
  //         })
  //         .filter(item => {
  //           const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
  //           return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
  //         });

  //       if (extractedData.length === 0) {
  //         setData3(null);
  //       }
  //       else {
  //         setData3(extractedData);
  //       }

  //       const extractedAcc = jsonData.data
  //         .map(item => {
  //           const date = new Date(item.time); // Assuming date is directly accessible now
  //           // Adjust the time to Thailand timezone
  //           date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
  //           return {
  //             time: date.getTime() / 1000,
  //             value: item.flow,
  //           };
  //         })
  //         .filter(item => {
  //           const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
  //           return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
  //         });
  //       if (extractedAcc.length === 0) {
  //         setFlow_acc3(null);
  //       }
  //       else {
  //         setFlow_acc3(extractedAcc);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching or processing data:', error);
  //     }
  //   };

  //   // Fetch data initially
  //   fetchData();

  //   setTimeout(() => {
  //     fetchData(); // เรียกครั้งที่ 2 หลังจาก 1 วินาที
  //   }, 1000);

  //   if (wsRef3.current) {
  //     console.log("Closing WebSocket...");
  //     wsRef3.current.close();
  //   }

  //   try {
  //     const ws = new WebSocket(`ws://172.18.1.81:5002/ws/flow/${selectedSymbol3.toUpperCase()}`);

  //     wsRef3.current = ws;

  //     ws.onopen = () => console.log(`✅ WebSocket connected: ${selectedSymbol3.toUpperCase()}`);

  //     ws.onmessage = (event) => {
  //       try {
  //         const response = JSON.parse(event.data);
  //         if (response.action === "re-fetch") {
  //           console.log(`🔄 Updated data for ${selectedSymbol3.toUpperCase()}`);

  //           fetchData();
  //         }
  //       } catch (error) {
  //         console.error(" Error parsing WebSocket message:", error);
  //       }
  //     };

  //     ws.onerror = (error) => console.error(" WebSocket Error:", error);
  //     ws.onclose = () => console.log(" WebSocket disconnected");

  //     return () => {
  //       if (wsRef3.current) {
  //         console.log(" Closing WebSocket...");
  //         wsRef3.current.close();
  //       }
  //     };

  //   } catch {
  //     // Start a timer to fetch data every second
  //     const intervalId = setInterval(fetchData, 10000);

  //     // Cleanup function to clear the interval
  //     return () => clearInterval(intervalId);
  //   }
  // }, [selectedSymbol3]);

  // //fetch data 4
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!selectedSymbol4 || selectedSymbol4 == null || selectedSymbol4 == undefined) return;
  //     try {
  //       // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol4)}` });
  //       const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol4.toUpperCase())}` });
  //       // const jsonData = await response.json();

  //       if (!Array.isArray(jsonData.data)) {
  //         throw new Error('Response data is not an array');
  //       }


  //       const now = new Date();
  //       const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
  //       const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

  //       const extractedData = jsonData.data
  //         .map(item => {
  //           const date = new Date(item.time); // Assuming date is directly accessible now
  //           // Adjust the time to Thailand timezone
  //           date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
  //           return {
  //             time: date.getTime() / 1000,
  //             value: item.last,
  //           };
  //         })
  //         .filter(item => {
  //           const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
  //           return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
  //         });

  //       if (extractedData.length === 0) {
  //         setData4(null);
  //       }
  //       else {
  //         setData4(extractedData);
  //       }

  //       const extractedAcc = jsonData.data
  //         .map(item => {
  //           const date = new Date(item.time); // Assuming date is directly accessible now
  //           // Adjust the time to Thailand timezone
  //           date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
  //           return {
  //             time: date.getTime() / 1000,
  //             value: item.flow,
  //           };
  //         })
  //         .filter(item => {
  //           const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
  //           return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
  //         });
  //       if (extractedAcc.length === 0) {
  //         setFlow_acc4(null);
  //       }
  //       else {
  //         setFlow_acc4(extractedAcc);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching or processing data:', error);
  //     }
  //   };

  //   // Fetch data initially
  //   fetchData();

  //   setTimeout(() => {
  //     fetchData(); // เรียกครั้งที่ 2 หลังจาก 1 วินาที
  //   }, 1000);

  //   if (wsRef4.current) {
  //     console.log("Closing WebSocket...");
  //     wsRef4.current.close();
  //   }

  //   try {
  //     const ws = new WebSocket(`ws://172.18.1.81:5002/ws/flow/${selectedSymbol4.toUpperCase()}`);

  //     wsRef4.current = ws;

  //     ws.onopen = () => console.log(`✅ WebSocket connected: ${selectedSymbol4.toUpperCase()}`);

  //     ws.onmessage = (event) => {
  //       try {
  //         const response = JSON.parse(event.data);
  //         if (response.action === "re-fetch") {
  //           console.log(`🔄 Updated data for ${selectedSymbol4.toUpperCase()}`);

  //           fetchData();
  //         }
  //       } catch (error) {
  //         console.error(" Error parsing WebSocket message:", error);
  //       }
  //     };

  //     ws.onerror = (error) => console.error(" WebSocket Error:", error);
  //     ws.onclose = () => console.log(" WebSocket disconnected");

  //     return () => {
  //       if (wsRef4.current) {
  //         console.log(" Closing WebSocket...");
  //         wsRef4.current.close();
  //       }
  //     };

  //   } catch {
  //     // Start a timer to fetch data every second
  //     const intervalId = setInterval(fetchData, 10000);

  //     // Cleanup function to clear the interval
  //     return () => clearInterval(intervalId);
  //   }
  // }, [selectedSymbol4]);





  //fetch data 1 old
  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (!selectedSymbol || selectedSymbol == null || selectedSymbol == undefined) return;
  //     try {
  //       // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol)}` });
  //       const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol.toUpperCase())}` });
  //       // const jsonData = await response.json();

  //       if (!Array.isArray(jsonData.data)) {
  //         throw new Error('Response data is not an array');
  //       }

  //       const now = new Date();
  //       const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
  //       const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

  //       const extractedData = jsonData.data
  //         .map(item => {
  //           const date = new Date(item.time); // Assuming date is directly accessible now
  //           // Adjust the time to Thailand timezone
  //           date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7

  //           return {
  //             time: date.getTime() / 1000,
  //             value: item.last,
  //           };
  //         })
  //         .filter(item => {
  //           const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
  //           return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
  //         });
  //       // console.log(date.getTime() / 1000)

  //       if (extractedData.length === 0) {
  //         setData(null);
  //       }
  //       else {
  //         setData(extractedData);
  //       }

  //       const extractedAcc = jsonData.data
  //         .map(item => {
  //           const date = new Date(item.time); // Assuming date is directly accessible now
  //           // Adjust the time to Thailand timezone
  //           date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
  //           return {
  //             time: date.getTime() / 1000,
  //             value: item.flow,
  //           };
  //         })
  //         .filter(item => {
  //           const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
  //           return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
  //         });
  //       if (extractedAcc.length === 0) {
  //         setFlow_acc(null);
  //       }
  //       else {
  //         setFlow_acc(extractedAcc);
  //       }

  //     } catch (error) {
  //       console.error('Error fetching or processing data:', error);
  //     }
  //   };

  //   // Fetch data initially
  //   fetchData();

  //   // Start a timer to fetch data every second
  //   const intervalId = setInterval(fetchData, 10000);

  //   // Cleanup function to clear the interval
  //   return () => clearInterval(intervalId);
  // }, [selectedSymbol]);

  //fetch data 2 old
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedSymbol2 || selectedSymbol2 == null || selectedSymbol2 == undefined) return;
      try {
        // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol2)}` });
        const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol2.toUpperCase())}` });
        // const jsonData = await response.json();

        if (!Array.isArray(jsonData.data)) {
          throw new Error('Response data is not an array');
        }
        const now = new Date();
        const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
        const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

        const extractedData = jsonData.data
          .map(item => {
            const date = new Date(item.time); // Assuming date is directly accessible now
            // Adjust the time to Thailand timezone
            date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
            return {
              time: date.getTime() / 1000,
              value: item.last,
            };
          })
          .filter(item => {
            const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
            return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
          });

        if (extractedData.length === 0) {
          setData2(null);
        }
        else {
          setData2(extractedData);
        }

        const extractedAcc = jsonData.data
          .map(item => {
            const date = new Date(item.time); // Assuming date is directly accessible now
            // Adjust the time to Thailand timezone
            date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
            return {
              time: date.getTime() / 1000,
              value: item.flow,
            };
          })
          .filter(item => {
            const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
            return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
          });
        if (extractedAcc.length === 0) {
          setFlow_acc2(null);
        }
        else {
          setFlow_acc2(extractedAcc);
        }
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, [selectedSymbol2]);

  //fetch data 3 old
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedSymbol3 || selectedSymbol3 == null || selectedSymbol3 == undefined) return;
      try {
        // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol3)}` });
        const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol3.toUpperCase())}` });
        // const jsonData = await response.json();

        if (!Array.isArray(jsonData.data)) {
          throw new Error('Response data is not an array');
        }
        const now = new Date();
        const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
        const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

        const extractedData = jsonData.data
          .map(item => {
            const date = new Date(item.time); // Assuming date is directly accessible now
            // Adjust the time to Thailand timezone
            date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
            return {
              time: date.getTime() / 1000,
              value: item.last,
            };
          })
          .filter(item => {
            const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
            return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
          });

        if (extractedData.length === 0) {
          setData3(null);
        }
        else {
          setData3(extractedData);
        }

        const extractedAcc = jsonData.data
          .map(item => {
            const date = new Date(item.time); // Assuming date is directly accessible now
            // Adjust the time to Thailand timezone
            date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
            return {
              time: date.getTime() / 1000,
              value: item.flow,
            };
          })
          .filter(item => {
            const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
            return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
          });
        if (extractedAcc.length === 0) {
          setFlow_acc3(null);
        }
        else {
          setFlow_acc3(extractedAcc);
        }
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, [selectedSymbol3]);

  //fetch data 4 old
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedSymbol4 || selectedSymbol4 == null || selectedSymbol4 == undefined) return;
      try {
        // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol4)}` });
        const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol4.toUpperCase())}` });
        // const jsonData = await response.json();

        if (!Array.isArray(jsonData.data)) {
          throw new Error('Response data is not an array');
        }

        const now = new Date();
        const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
        const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

        const extractedData = jsonData.data
          .map(item => {
            const date = new Date(item.time); // Assuming date is directly accessible now
            // Adjust the time to Thailand timezone
            date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
            return {
              time: date.getTime() / 1000,
              value: item.last,
            };
          })
          .filter(item => {
            const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
            return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
          });

        if (extractedData.length === 0) {
          setData4(null);
        }
        else {
          setData4(extractedData);
        }

        const extractedAcc = jsonData.data
          .map(item => {
            const date = new Date(item.time); // Assuming date is directly accessible now
            // Adjust the time to Thailand timezone
            date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
            return {
              time: date.getTime() / 1000,
              value: item.flow,
            };
          })
          .filter(item => {
            const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
            return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
          });
        if (extractedAcc.length === 0) {
          setFlow_acc4(null);
        }
        else {
          setFlow_acc4(extractedAcc);
        }
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };

    // Fetch data initially
    fetchData();

    // Start a timer to fetch data every second
    const intervalId = setInterval(fetchData, 10000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, [selectedSymbol4]);






  //chart1
  useEffect(() => {
    // console.log('data to chart1', data)
    if (data && chartContainerRef.current) {
      let chart = chartContainerRef.current.chart;

      // console.log('data Chart 1 is', data)

      if (!chart) {

        chart = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,

          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data.length = 1 ? 1 : data.length < 20 ? 0 : data.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });
        // Define layout options for two price scales
        const layoutOptions = {

        };
        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'

          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },
        });

        chartContainerRef.current.style.borderRadius = '10px';
        chartContainerRef.current.style.overflow = 'hidden';
        chartContainerRef.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange) {
            previousLogicalRange = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }
          previousLogicalRange = newLogicalRange;
        });


        // ✅ Cleanup เมื่อ component ถูก unmount
        return () => {
          // chart.unsubscribeCrosshairMove();
          chart.timeScale().unsubscribeVisibleLogicalRangeChange();
        };


      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data);
      const filteredFlowAcc = filterAndSortData(flow_acc);

      // console.log('filter data',filteredData)

      if (chartContainerRef.current.lineSeries) {
        chart.removeSeries(chartContainerRef.current.lineSeries);
        chartContainerRef.current.lineSeries = null;
      }
      if (chartContainerRef.current.baselineSeries) {
        chart.removeSeries(chartContainerRef.current.baselineSeries);
        chartContainerRef.current.baselineSeries = null;
      }

      let lineSeries = chartContainerRef.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef.current) {
        chart.timeScale().fitContent();
        chartInitializedRef.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading(false);
    }
  }, [data, flow_acc]);

  //chart2
  useEffect(() => {
    // console.log('data to chart2', data2)
    if (data2 && chartContainerRef2.current) {
      let chart = chartContainerRef2.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef2.current, {
          width: chartContainerRef2.current.clientWidth,
          height: chartContainerRef2.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data2.length < 20 ? 0 : data2.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef2.current.style.borderRadius = '10px';
        chartContainerRef2.current.style.overflow = 'hidden';
        chartContainerRef2.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange2) {
            previousLogicalRange2 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange2.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange2.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed2(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed2(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange2 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol2.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data2);
      const filteredFlowAcc = filterAndSortData(flow_acc2);

      if (chartContainerRef2.current.lineSeries) {
        chart.removeSeries(chartContainerRef2.current.lineSeries);
        chartContainerRef2.current.lineSeries = null;
      }
      if (chartContainerRef2.current.baselineSeries) {
        chart.removeSeries(chartContainerRef2.current.baselineSeries);
        chartContainerRef2.current.baselineSeries = null;
      }

      let lineSeries = chartContainerRef2.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef2.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data2);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef2.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef2.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc2);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef2.current) {
        chart.timeScale().fitContent();
        chartInitializedRef2.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed2) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading2(false);
    }
  }, [data2, flow_acc2]);

  //chart3
  useEffect(() => {
    if (data3 && chartContainerRef3.current) {
      let chart = chartContainerRef3.current.chart;
      if (!chart) {
        chart = createChart(chartContainerRef3.current, {
          width: chartContainerRef3.current.clientWidth,
          height: chartContainerRef3.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data3.length < 20 ? 0 : data3.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef3.current.style.borderRadius = '10px';
        chartContainerRef3.current.style.overflow = 'hidden';
        chartContainerRef3.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange3) {
            previousLogicalRange3 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange3.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange3.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed3(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed3(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange3 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol3.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data3);
      const filteredFlowAcc = filterAndSortData(flow_acc3);


      if (chartContainerRef3.current.lineSeries) {
        chart.removeSeries(chartContainerRef3.current.lineSeries);
        chartContainerRef3.current.lineSeries = null;
      }
      if (chartContainerRef3.current.baselineSeries) {
        chart.removeSeries(chartContainerRef3.current.baselineSeries);
        chartContainerRef3.current.baselineSeries = null;
      }

      let lineSeries = chartContainerRef3.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef3.current.lineSeries = lineSeries;
      }

      lineSeries.setData(filteredData);
      // lineSeries.setData(data3);

      // Add a second line series
      let baselineSeries = chartContainerRef3.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef3.current.baselineSeries = baselineSeries;
      }
      baselineSeries.setData(filteredFlowAcc);
      // baselineSeries.setData(flow_acc3);
      if (!chartInitializedRef3.current) {
        chart.timeScale().fitContent();
        chartInitializedRef3.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed3) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading3(false);
    }
  }, [data3, flow_acc3]);

  //chart4
  useEffect(() => {
    if (data4 && chartContainerRef4.current) {
      let chart = chartContainerRef4.current.chart;

      if (!chart) {
        chart = createChart(chartContainerRef4.current, {
          width: chartContainerRef4.current.clientWidth,
          height: chartContainerRef4.current.clientHeight,
          timeScale: {
            timeVisible: true,
            secondsVisible: true,
            rightOffset: data4.length < 20 ? 0 : data4.length < 50 ? 1 : 5
          },
          rightPriceScale: {
            visible: true,

          },
          leftPriceScale: {
            visible: true,
          },


        });

        // Apply layout options
        chart.applyOptions({
          priceScale: {
            position: 'left'
          },
          priceScale2: {
            position: 'right'
          },
          priceFormat: {
            type: 'custom',
            minMove: 0.02,
            formatter: price => '$' + price.toFixed(2),
          },
          localization: {
            locale: 'th-TH',
          },
          layout: {
            background: { color: theme.palette.table.odd },
            textColor: theme.palette.text.secondary,
          },
          grid: {
            vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
            horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
          },


        });
        chartContainerRef4.current.style.borderRadius = '10px';
        chartContainerRef4.current.style.overflow = 'hidden';
        chartContainerRef4.current.chart = chart;

        let minFrom = null;
        let maxTo = null;

        chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
          if (!newLogicalRange) return;

          // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
          if (minFrom === null || maxTo === null) {
            minFrom = newLogicalRange.from;
            maxTo = newLogicalRange.to;
          }

          if (!previousLogicalRange4) {
            previousLogicalRange4 = newLogicalRange;
            return;
          }

          const epsilon = 0.000001;
          const fromChanged = Math.abs(previousLogicalRange4.from - newLogicalRange.from) > epsilon;
          const toChanged = Math.abs(previousLogicalRange4.to - newLogicalRange.to) > epsilon;
          const rangeChanged = fromChanged || toChanged;

          if (rangeChanged) {
            // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
            const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

            if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
              setIsZoomed4(false);
              console.log('Reset zoom to full range');
            } else {
              setIsZoomed4(true);
              console.log('User zoomed the chart:', newLogicalRange);
            }
          }

          previousLogicalRange4 = newLogicalRange;
        });

      }
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'center',
          vertAlign: 'top',
          color: theme.palette.chart.watermark,
          text: selectedSymbol4.toUpperCase(),
        },
      });

      // const filterAndSortData = (data) => {
      //     return [...new Map(data.map(item => [item.time, item])).values()]
      //         .sort((a, b) => a.time - b.time);
      // };
      const filterAndSortData = (data) => {
        // return [...new Map(data.map(item => [item.time, item])).values()]
        //   .sort((a, b) => a.time - b.time);
        const today = new Date();

        // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
        const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
        const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

        return [...new Map(data
          .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
          .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
        ).values()]
          .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
      };

      // กรองข้อมูลก่อนแสดงผล
      const filteredData = filterAndSortData(data4);
      const filteredFlowAcc = filterAndSortData(flow_acc4);

      if (chartContainerRef4.current.lineSeries) {
        chart.removeSeries(chartContainerRef4.current.lineSeries);
        chartContainerRef4.current.lineSeries = null;
      }
      if (chartContainerRef4.current.baselineSeries) {
        chart.removeSeries(chartContainerRef4.current.baselineSeries);
        chartContainerRef4.current.baselineSeries = null;
      }


      let lineSeries = chartContainerRef4.current.lineSeries;

      if (!lineSeries) {
        lineSeries = chart.addLineSeries({
          color: colors.whiteChart[200],
          priceScaleId: 'right'
        });
        chartContainerRef4.current.lineSeries = lineSeries;
      }

      // lineSeries.setData(data4);
      lineSeries.setData(filteredData);

      // Add a second line series
      let baselineSeries = chartContainerRef4.current.baselineSeries;
      if (!baselineSeries) {
        baselineSeries = chart.addBaselineSeries({
          priceScaleId: 'left',
          baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
          topLineColor: colors.greenChart[100],
          bottomLineColor: colors.redChart[100],
          topFillColor1: 'rgba(0, 0, 0, 0)',
          topFillColor2: 'rgba(0, 0, 0, 0)',
          bottomFillColor1: 'rgba(0, 0, 0, 0)',
          bottomFillColor2: 'rgba(0, 0, 0, 0)',
          priceFormat: {
            type: 'custom',
            minMove: 0.00000001,
            formatter: price => {
              const absPrice = Math.abs(price);
              if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
              if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
              if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
              if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
              if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
              return price.toFixed(0);
            },
          },
        });
        chartContainerRef4.current.baselineSeries = baselineSeries;
      }
      // baselineSeries.setData(flow_acc4);
      baselineSeries.setData(filteredFlowAcc);
      if (!chartInitializedRef4.current) {
        chart.timeScale().fitContent();
        chartInitializedRef4.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
      } else if (!isZoomed4) {
        console.log('just do it');
        chart.timeScale().fitContent();
      }
      setIsLoading4(false);
    }
  }, [data4, flow_acc4]);


  const symbolSelected = [
    selectedSymbol,
    selectedSymbol2,
    selectedSymbol3,
    selectedSymbol4,

  ];

  const applyFavoriteSymbols = (symbols) => {
    if (symbols && symbols.length) {
      // อัปเดต selectedSymbol แต่ละตัวจาก array ของ symbols
      setSelectedSymbol(symbols[0] || null);
      setSelectedSymbol2(symbols[1] || null);
      setSelectedSymbol3(symbols[2] || null);
      setSelectedSymbol4(symbols[3] || null);


      // ตรวจสอบค่าว่ามีค่าอยู่หรือไม่ ถ้ามีให้ปิด Autocomplete สำหรับแต่ละตัว
      if (symbols[0]) setShowAutocomplete(false);
      if (symbols[1]) setShowAutocomplete2(false);
      if (symbols[2]) setShowAutocomplete3(false);
      if (symbols[3]) setShowAutocomplete4(false);

    }
  };




  // console.log("This is data : ", data)

  return (
    <>

      <style>
        {`
      
      .chartBox {
        position: relative;
        /*
        width: calc(25vw - 5px);  For 4 columns on desktop 
        height: calc(33.3vh - 5px);  Adjust height as needed */
      
        width: calc(38vw - 5px);  
        height: calc(45vh - 5px) !important;
        
        margin: 2px;
        
        background-color: #575b69; /* Or any color you want */
      }

      div .tv-lightweight-charts {
        border-radius: '10px'
      }
          
      
    `}
      </style>
      <Box id='main' className='container' sx={{
        // position: 'relative',
        // width: '100vw',
        maxWidth: 'xl',
        height: '90vh',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'flex-start',
        flexDirection: 'column'
        /* Adjust line spacing */
      }}>
        <FavoriteListPopup
          selectedFavorite={selectedFavorite}
          setSelectedFavorite={setSelectedFavorite}
          symbolSelected={symbolSelected}   // ส่งฟังก์ชันไปยัง FavoriteListPopup
          applyFavoriteSymbols={applyFavoriteSymbols}  // ส่งฟังก์ชันไปยัง FavoriteListPopup
        />

        <IconButton children={<LoopIcon />} onClick={() => handleResetAllZoom()}
          sx={{
            position: 'absolute',
            right: '5%',
            top: '10px',
            zIndex: 10,
            backgroundColor: "#575b69",
            // padding: '4px',
            // margin: "1%",
            "&:hover": { backgroundColor: 'transparent' }
          }} />

        {/* chart 1*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '50% !important' : 'unset', }}>
          {showAutocomplete ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol(newValue);
                setIsLoading(true);
                setShowAutocomplete(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol(typedValue);
                  setIsLoading(true);
                  setShowAutocomplete(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol ? `/realtime_fullscreen?symbol=${selectedSymbol.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />

          )}

          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef, setIsZoomed)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol != null ? selectedSymbol.toUpperCase() : null}
              </Typography>
            </Box>
          )}
          <div ref={chartContainerRef} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '0', zIndex: 0 }} />

        </Box>
        {/* chart 2*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '50% !important' : 'unset', }}>
          {showAutocomplete2 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol2(newValue);
                setIsLoading2(true);
                setShowAutocomplete2(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol2(typedValue);
                  setIsLoading2(true);
                  setShowAutocomplete2(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete2(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol2 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol2 ? `/realtime_fullscreen?symbol=${selectedSymbol2.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />

          )}

          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef2, setIsZoomed2)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />

          {isLoading2 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol2 != null ? selectedSymbol2.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef2} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 3*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '50% !important' : 'unset', }}>
          {showAutocomplete3 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol3(newValue);
                setIsLoading3(true);
                setShowAutocomplete3(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol3(typedValue);
                  setIsLoading3(true);
                  setShowAutocomplete3(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete3(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol3 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol3 ? `/realtime_fullscreen?symbol=${selectedSymbol3.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}

              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef3, setIsZoomed3)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading3 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol3 != null ? selectedSymbol3.toUpperCase() : null}
              </Typography>
            </Box>
          )}
          <div ref={chartContainerRef3} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>
        {/* chart 4*/}
        <Box className='chartBox' sx={{ borderRadius: '10px', width: isMobile ? '50% !important' : 'unset', }}>
          {showAutocomplete4 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={symbolsToSelect}
              // options={Object.keys(mockData)}
              size="small"
              className="sl-symbol"
              onChange={(event, newValue) => {
                setSelectedSymbol4(newValue);
                setIsLoading4(true);
                setShowAutocomplete4(false); // ซ่อน Autocomplete หลังเลือกค่า
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  const typedValue = event.target.value.trim();
                  setSelectedSymbol4(typedValue);
                  setIsLoading4(true);
                  setShowAutocomplete4(false); // ซ่อน Autocomplete หลังกด Enter
                }
              }}
              sx={{
                margin: "1%",
                width: "25%",
                height: "20px",
                zIndex: 10,
                position: "absolute",
                left: "11%",
              }}
              renderInput={(params) => <TextField {...params} label="" />}
            />
          ) : (
            // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
            <IconButton
              onClick={() => setShowAutocomplete4(true)} // กดแล้วให้ Autocomplete กลับมา
              sx={{
                position: "absolute",
                left: "15%",
                padding: '4px',
                margin: "1%",
                backgroundColor: "#575b69",
                color: "white",
                zIndex: 10,
                "&:hover": { backgroundColor: "#44495a" },
              }}
            >
              <SearchIcon />
            </IconButton>
          )}
          {selectedSymbol4 && (
            <IconButton children={<ZoomInIcon />} onClick={() => {
              const url = selectedSymbol4 ? `/realtime_fullscreen?symbol=${selectedSymbol4.toUpperCase()}` : '/realtime_fullscreen';
              window.open(url, '_blank');
            }}
              sx={{
                position: 'absolute',
                right: '16%',
                zIndex: 10,
                backgroundColor: "#575b69",
                padding: '4px',
                margin: "1%",
                "&:hover": { backgroundColor: 'transparent' }
              }} />
          )}
          <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef4, setIsZoomed4)}
            sx={{
              position: 'absolute',
              right: '8%',
              zIndex: 10,
              backgroundColor: "#575b69",
              padding: '4px',
              margin: "1%",
              "&:hover": { backgroundColor: 'transparent' }
            }} />
          {isLoading4 && (
            <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
              <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                Searching for {selectedSymbol4 != null ? selectedSymbol4.toUpperCase() : null}
              </Typography>
            </Box>
          )}

          <div ref={chartContainerRef4} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '50', zIndex: 0 }} />

        </Box>

      </Box>

    </>
  );
}

export default App
