import React from 'react';
import { Box, Container, Paper, useTheme, Typography, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, } from '@mui/material';
import FontLoader from '../../components/FontLoader';

const DataTable = ({ exceedData, title }) => {
  const adjustToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const timezoneOffset = date.getTimezoneOffset() * 60;
    return timestamp - timezoneOffset;
  };

  const renderTableRows = (data) => {
    if (!data || data.length === 0) {
      return (
        <tr>
          <td colSpan="7" style={{ textAlign: 'center', padding: '10px' }}>
            No data available
          </td>
        </tr>
      );
    }

    return data.map((row, i) => {
      const adjustedTimestamp = adjustToLocalTime(Math.floor(new Date(row.time).getTime() / 1000));
      const dateObj = new Date(adjustedTimestamp * 1000);

      if (isNaN(dateObj.getTime())) {
        console.error('Invalid time value:', row.time);
        return null;
      }

      const formatNumber = (number) => isNaN(number) ? '' : number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

      const date = dateObj.toISOString().slice(0, 10);
      const time = dateObj.toISOString().slice(11, 19);

      return (
        <tr key={i} style={{ 
          borderBottom: '1px solid #eee', 
          fontSize: '0.7rem',
          backgroundColor: i % 2 === 0 ? '#3a4564' : '#636a84' // สีสลับระหว่างแถวคู่และคี่
        }}>
          <td style={{ padding: '3px',fontFamily: 'Lato, Sans-serif', width: '70px', textAlign: 'center' }}>{date}</td>
        <td style={{ padding: '3px',fontFamily: 'Lato, Sans-serif', width: '50px', textAlign: 'center' }}>{time}</td>
        <td style={{ padding: '3px',fontFamily: 'Lato, Sans-serif', width: '50px', textAlign: 'center' }}>{row.last ? formatNumber(row.last) : ''}</td>
        <td style={{ padding: '3px',fontFamily: 'Lato, Sans-serif', textAlign: 'center' }}>{row.bid_vol ? row.bid_vol : '0'}</td>
        <td style={{ padding: '3px',fontFamily: 'Lato, Sans-serif', textAlign: 'center' }}>{row.ask_vol ? row.ask_vol : ''}</td>
        <td style={{ padding: '3px',fontFamily: 'Lato, Sans-serif', textAlign: 'center' }}>{row.spread_vol ? row.spread_vol : ''}</td>
        <td style={{ padding: '3px',fontFamily: 'Lato, Sans-serif', textAlign: 'center' }}>{row.flow ? row.flow : ''}</td>
      </tr>
      );
    });
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <FontLoader/>
      <Box>
        <h3 style={{ textAlign: 'center' }}>{title}</h3>
        <Box sx={{
          maxHeight: '200px',
          overflowY: 'auto',
          '::-webkit-scrollbar': { width: '5px' },
          '::-webkit-scrollbar-thumb': { backgroundColor: '#888', borderRadius: '4px' },
          '::-webkit-scrollbar-thumb:hover': { backgroundColor: '#555' },
        }}>
          <table style={{ border: '1px solid #ccc', borderCollapse: 'collapse', fontSize: '0.75rem', width: '100%' }}>
            <thead>
              <tr style={{ borderBottom: '2px solid #ccc', fontSize: '0.75rem' }}>
                <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', backgroundColor: '#283593',fontFamily: 'Lato, Sans-serif', }}>Date</th>
                <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', backgroundColor: '#283593',fontFamily: 'Lato, Sans-serif', }}>Time</th>
                <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', backgroundColor: '#283593',fontFamily: 'Lato, Sans-serif', }}>Last</th>
                <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', backgroundColor: '#283593',fontFamily: 'Lato, Sans-serif', }}>Bid Vol</th>
                <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', backgroundColor: '#283593',fontFamily: 'Lato, Sans-serif', }}>Ask Vol</th>
                <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', backgroundColor: '#283593',fontFamily: 'Lato, Sans-serif', }}>Spread Vol</th>
                <th style={{ textAlign: 'center', padding: '3px', border: '1px solid #ccc', backgroundColor: '#283593',fontFamily: 'Lato, Sans-serif', }}>Flow</th>
              </tr>
            </thead>
            <tbody>{renderTableRows(exceedData)}</tbody>
          </table>
        </Box>
      </Box>
    </div>
  );
};

export default DataTable;



