import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { Box, Typography } from '@mui/material';
import FontLoader from '../../components/FontLoader';

let colorIndex = 0;
const softDarkColors = [
  "#ffcc00", // เหลืองสดใส (Vivid Yellow)
  "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
  "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
  "#ff9966", // ส้มสด (Bright Orange)
  "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
  "#ffffff", // ขาว (White)
  "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
  "#ff66b3", // ชมพูสดใส (Hot Pink)
  "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
  "#3399ff", // ฟ้าสดใส (Sky Blue)
  "#ff9933", // ส้มอ่อนสด (Bright Peach)
  "#ff3399", // ชมพูบานเย็น (Magenta Pink)
  "#33ff66", // เขียวสด (Vivid Green)
  "#ff9966", // พีชสดใส (Peach Orange)
  "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
  "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
  "#66ccff", // ฟ้าสดกลาง (Electric Blue)
  "#33ccff", // ฟ้าสด 
  "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
  "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
];

const getRandomColor = () => {
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
  return color;
};


const earthToneColor = 'rgba(181, 101, 29, 0.5)';
const getOppositeColor = () => '#ffffb3';

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

/**
 * Fullscreen page for Historical Flow
 * ------------------------------------
 * Displays a single big chart (no date picker).
 * The main Hisflow page navigates here (e.g. `/fullscreenhisflow?market=SET100&type=in&date=2024-03-12`)
 */
const FullscreenPageHisflow = () => {
  const location = useLocation();
  const csrfToken = useSelector((state) => state.csrfToken);

  // Extract query params: market, type, date, etc.
  // e.g. /fullscreenhisflow?market=SET100&type=in&date=2024-03-12
  const params = new URLSearchParams(location.search);
  const market = params.get('market');       // "SET100" | "MAI" | ...
  const type = params.get('type');           // "in" or "out"
  const dateString = params.get('date');     // e.g. "2024-03-12"
  const title = params.get('title') || 'Hist Fullscreen Chart';

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  // Refs
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  // Local state
  const [tableData, setTableData] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const historicalDataRef = useRef({});


  // Inversion logic for "out" => negative values and inverted scale
  const shouldInvert = (type === 'out');

  /**
   * Fetch Historical Data for the fullscreen chart
   */
  // const fetchHistoricalData = async () => {
  //   try {
  //     if (!market || !dateString) {
  //       console.warn('No market or date provided, skipping fetch.');
  //       return;
  //     }

  //     // e.g. /flow/hist/SET100&2024-03-12
  //     const url = `${window.config.base_api_url}/flow/hist/${market}&${dateString}`;
  //     const response = await fetch(url, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'xsrf-token': csrfToken,
  //       },
  //     });
  //     const jsonData = await response.json();

  //     if (!jsonData?.data?.[type]) {
  //       console.warn(`No data found for market=${market}, date=${dateString}, type=${type}`);
  //       return;
  //     }

  //     const data = jsonData.data[type];
  //     const historicalData = {};
  //     const times = Object.keys(data.rank).sort();

  //     if (times.length === 0) {
  //       console.warn('No rank times found in data.');
  //       return;
  //     }

  //     const latestTime = times[times.length - 1];
  //     const latestSymbols = Object.keys(data.rank[latestTime]);

  //     // Build chart data
  //     times.forEach((time) => {
  //       const timestamp = Math.floor(new Date(time).getTime() / 1000);

  //       Object.keys(data.rank[time]).forEach((symbol) => {
  //         if (!historicalData[symbol]) {
  //           historicalData[symbol] = [];
  //         }
  //         const rankValue = data.rank[time][symbol];
  //         const rawValue = data.value[time][symbol];
  //         const finalValue = shouldInvert ? -1 * rawValue : rawValue;

  //         historicalData[symbol].push({
  //           time: adjustToLocalTime(timestamp),
  //           rank: rankValue,
  //           value: finalValue,
  //         });
  //       });
  //     });

  //     // Update or create line series
  //     latestSymbols.forEach((symbol) => {
  //       const symData = historicalData[symbol];
  //       if (!symData || symData.length === 0) return;

  //       symData.sort((a, b) => a.time - b.time);

  //       let series = lineSeriesRef.current.get(symbol);
  //       if (!series) {
  //         const color = getRandomColor();
  //         series = chartRef.current.addLineSeries({
  //           color,
  //           lineWidth: 2,
  //           lastValueVisible: true,
  //           priceLineVisible: false,
  //           title: symbol,
  //         });
  //         lineSeriesRef.current.set(symbol, series);
  //         originalColorsRef.current.set(symbol, color);
  //       }

  //       const seriesData = symData.map((dp) => ({
  //         time: dp.time,
  //         value: dp.value,
  //       }));
  //       series.setData(seriesData);
  //       seriesDataRef.current.set(symbol, seriesData);
  //     });

  //     // Remove any symbols not in the latest set
  //     const allSymbols = Array.from(lineSeriesRef.current.keys());
  //     const toRemove = allSymbols.filter((s) => !latestSymbols.includes(s));
  //     toRemove.forEach((sym) => {
  //       const s = lineSeriesRef.current.get(sym);
  //       if (s) {
  //         chartRef.current.removeSeries(s);
  //       }
  //       lineSeriesRef.current.delete(sym);
  //       seriesDataRef.current.delete(sym);
  //       originalColorsRef.current.delete(sym);
  //     });

  //     // Prepare table data (simply the final "snapshot")
  //     const newTableData = latestSymbols.map((symbol) => {
  //       const arr = historicalData[symbol];
  //       const lastItem = arr[arr.length - 1];
  //       return {
  //         rank: lastItem.rank,
  //         symbol,
  //         value: lastItem.value,
  //         color: 'transparent',
  //       };
  //     });
  //     setTableData(newTableData);
  //   } catch (err) {
  //     console.error('Error fetching historical flow data in fullscreen:', err);
  //   }
  // };
  const fetchHistoricalData = async () => {
    try {
      if (!market || !dateString) {
        console.warn('No market or date provided, skipping fetch.');
        return;
      }
  
      const url = `${window.config.base_api_url}/flow/hist/${market}&${dateString}`;
      const response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      });
  
      const jsonData = await response.json();
      if (!jsonData?.data?.[type]) {
        console.warn(`No data found for market=${market}, date=${dateString}, type=${type}`);
        return;
      }
  
      const data = jsonData.data[type];
      const historicalData = {};
      const times = Object.keys(data.rank).sort();
  
      if (times.length === 0) {
        console.warn('No rank times found in data.');
        return;
      }
  
      // Build chart data
      times.forEach((time) => {
        const timestamp = Math.floor(new Date(time).getTime() / 1000);
  
        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const rankValue = data.rank[time][symbol];
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;
  
          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: rankValue,
            value: finalValue,
          });
        });
      });

      // ✅ Store historicalData in useRef
      historicalDataRef.current = historicalData;  // เก็บข้อมูลใน historicalDataRef
      console.log("Historical data stored:", historicalDataRef.current);  // ดีบักข้อมูลใน historicalDataRef

      // Get top 5 symbols (based on the latest rank for each symbol)
      const top5Symbols = Object.keys(historicalData)
        .map((symbol) => {
          const latestData = historicalData[symbol][historicalData[symbol].length - 1];
          return { symbol, rank: latestData.rank };
        })
        .sort((a, b) => a.rank - b.rank) // sort by rank
        .slice(0, 5); // get top 5
  
      // Create line series for top 5 symbols
      top5Symbols.forEach(({ symbol }) => {
        const symData = historicalData[symbol];
        if (!symData || symData.length === 0) return;
  
        symData.sort((a, b) => a.time - b.time);
  
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }
  
        const seriesData = symData.map((dp) => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });
  
      // Remove any symbols that are not in the top 5
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter((s) => !top5Symbols.some((top) => top.symbol === s));
      symbolsToRemove.forEach((sym) => {
        const s = lineSeriesRef.current.get(sym);
        if (s) {
          chartRef.current.removeSeries(s);
        }
        lineSeriesRef.current.delete(sym);
        seriesDataRef.current.delete(sym);
        originalColorsRef.current.delete(sym);
      });
  
      // Prepare table data for all symbols, not just top 5
      const allTableData = Object.keys(historicalData).map((symbol) => {
        const arr = historicalData[symbol];
        const lastItem = arr[arr.length - 1];
        return {
          symbol,
          value: lastItem.value,
          rank: lastItem.rank,
          color: 'transparent', // set your color here
        };
      });
      setTableData(allTableData); // Update table with all symbols
  
    } catch (err) {
      console.error('Error fetching historical flow data in fullscreen:', err);
    }
};

  

  /**
   * Handler to highlight or unhighlight a symbol
   */
  // const handleRowClick = (symbol) => {
  //   if (selectedSymbol === symbol) {
  //     // Deselect
  //     setSelectedSymbol(null);
  //     lineSeriesRef.current.forEach((series, sym) => {
  //       const origColor = originalColorsRef.current.get(sym);
  //       series.applyOptions({
  //         color: origColor,
  //         lineWidth: 2,
  //       });
  //     });
  //   } else {
  //     // Highlight
  //     setSelectedSymbol(symbol);

  //     const selectedSeries = lineSeriesRef.current.get(symbol);
  //     const data = seriesDataRef.current.get(symbol);
  //     if (selectedSeries && chartRef.current) {
  //       chartRef.current.removeSeries(selectedSeries);

  //       const newSeries = chartRef.current.addLineSeries({
  //         color: selectedSeries.options().color,
  //         lineWidth: selectedSeries.options().lineWidth,
  //         lastValueVisible: true,
  //         priceLineVisible: false,
  //         title: symbol,
  //       });
  //       newSeries.setData(data);
  //       lineSeriesRef.current.set(symbol, newSeries);

  //       // Switch to "opposite" color
  //       newSeries.applyOptions({
  //         color: getOppositeColor(),
  //         lineWidth: 4,
  //       });

  //       // Fade others
  //       lineSeriesRef.current.forEach((s, otherSymbol) => {
  //         if (otherSymbol !== symbol) {
  //           s.applyOptions({
  //             color: earthToneColor,
  //             lineWidth: 2,
  //           });
  //         }
  //       });
  //     }
  //   }
  // };

  const handleRowClick = (symbol) => {
    if (selectedSymbol === symbol) {
      // Deselect
      setSelectedSymbol(null);

      // Revert to 5 lines when deselected
      const top5Symbols = Array.from(lineSeriesRef.current.keys()).slice(0, 5);
      top5Symbols.forEach((sym) => {
        const origColor = originalColorsRef.current.get(sym);
        const series = lineSeriesRef.current.get(sym);
        if (series) {
          series.applyOptions({
            color: origColor,
            lineWidth: 2,
          });
        }
      });
    } else {
      // Highlight
      setSelectedSymbol(symbol);

      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);
      if (selectedSeries && chartRef.current) {
        chartRef.current.removeSeries(selectedSeries);

        // Create a new series for the selected symbol
        const newSeries = chartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        // Change to "opposite" color for the highlighted symbol
        newSeries.applyOptions({
          color: getOppositeColor(),
          lineWidth: 4,
        });

        // If the symbol is the 6th one or more, show one by one
        const allSymbols = Array.from(lineSeriesRef.current.keys());
        if (allSymbols.length > 5) {
          // Start hiding all but the first 5 symbols
          allSymbols.slice(5).forEach((otherSymbol) => {
            const s = lineSeriesRef.current.get(otherSymbol);
            if (s) {
              s.applyOptions({
                color: earthToneColor, // Fade other symbols
                lineWidth: 1,
              });
            }
          });
        }

        // Fade the remaining symbols
        lineSeriesRef.current.forEach((s, otherSymbol) => {
          if (otherSymbol !== symbol && !allSymbols.slice(5).includes(otherSymbol)) {
            s.applyOptions({
              color: earthToneColor,
              lineWidth: 2,
            });
          }
        });
      }
    }
  };


  
  

  // Chart initialization
  useEffect(() => {
    if (!chartContainerRef.current) return;

    // เพิ่ม outline, border-radius และ overflow: hidden ให้กับ chartContainerRef
    if (chartContainerRef.current) {
      chartContainerRef.current.style.outline = "2px solid #fff"; // เปลี่ยนสีตามต้องการ
      chartContainerRef.current.style.boxSizing = "border-box"; // ป้องกัน outline เล็กกว่า chart
      chartContainerRef.current.style.borderRadius = "10px"; // ขอบมน (10px)
      chartContainerRef.current.style.overflow = "hidden"; // ซ่อนส่วนที่เกินออกจากขอบ
    }

    // Create chart
    chartRef.current = createChart(chartContainerRef.current, {
      layout: {
        textColor: 'white',
        background: { type: 'solid', color: '#575b69' },
      },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: chartContainerRef.current.offsetHeight,
      width: chartContainerRef.current.offsetWidth,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: { mode: 0 },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
    });
    // เพิ่มการตั้งค่า textColor ของ price scale
    chartRef.current.priceScale('right').applyOptions({
      textColor: '#575b69', // ตั้งสีข้อความของ price scale
    });

    // Invert if type === "out"
    if (shouldInvert) {
      chartRef.current.applyOptions({ invertScale: true });
    }

    // Fetch the historical data once
    fetchHistoricalData();

    //Dynamic Chart Size

    const resizeChart = () => {
      if (chartRef.current && chartContainerRef.current) {
        requestAnimationFrame(() => {
          chartRef.current.resize(
            chartContainerRef.current.offsetWidth,
            chartContainerRef.current.offsetHeight
          );
        });
      }
    };

    const resizeObserver = new ResizeObserver(resizeChart);
    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [market, dateString, type]);



  // Example "navigation" button handlers
  const handleShowAll = () => {
    const timeScale = chartRef.current?.timeScale();
    if (!timeScale) return;

    let earliestTime = Infinity;
    let latestTime = -Infinity;
    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });
    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      timeScale.setVisibleRange({ from: earliestTime, to: latestTime });

      timeScale.applyOptions({ rightOffset: 16 });
    }
  };

  // Render table
  const renderTable = (startIndex, endIndex) => {
    const sortedData = [...tableData].sort((a, b) => a.rank - b.rank).slice(startIndex, endIndex);
    return (
      <table className="rank-table" style={{
        fontSize: '13px',
        fontFamily: 'Lato, Sans-serif',
        border: '1px solid #ddd',
        borderCollapse: 'collapse'
      }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Rank</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Symbol</th>
            <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, idx) => {
            const isSelected = row.symbol === selectedSymbol;
            return (
              <tr
                key={idx}
                style={{
                  backgroundColor: isSelected ? "#B8860B" : "#636a7e", // เปลี่ยนสีเมื่อกด
                  cursor: "pointer",
                  fontWeight: isSelected ? "bold" : "normal",
                  transition: "background-color 0.3s ease",
                  borderBottom: "1px solid #ddd",
                }}
                onClick={() => handleRowClick(row.symbol)}
              >
                <td style={{ padding: "3px 0 7.2px", textAlign: "center" }}>{row.rank}</td>
                <td style={{ padding: "3px 0 7.2px", textAlign: "center" }}>{row.symbol}</td>
                <td style={{ padding: "3px 0 7.2px", textAlign: "center" }}>
                  {(row.value / 1_000_000).toLocaleString("en-US", { maximumFractionDigits: 2 })}
                </td>
              </tr>
            );
          })}
        </tbody>

      </table>
    );
  };

  return (
    <div style={{ fontFamily: 'Lato, Sans-serif', padding: '20px' }}>
      <FontLoader/>
      <h3>{title}</h3>
      <div style={{ marginBottom: '10px' }}>
        <button onClick={handleShowAll} className="nav-button" style={{ borderRadius: '10px', backgroundColor: '#FFCD77',fontFamily: 'Lato, Sans-serif' }}>Show All</button>
      </div>
      <div style={{ display: 'flex', flexDirection: (isMobile || isPTablet) ? 'column' : 'row', gap: '20px' }}>
        <div
          ref={chartContainerRef}
          style={{
            width: (isMobile || isPTablet) ? '100%' : '80%',
            height: (isMobile || isPTablet) ? '40vh' : '80vh',
            position: 'relative'
          }}
        />
        {(!isMobile && !isPTablet) && (<> <div
          className="table-container"
          style={{
            width: '20%',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            overflowY: 'auto',
            maxHeight: '80vh',
          }}
        >
          {renderTable()}
        </div>
        </>)
        }

        {(isMobile || isPTablet) && (
          <>
            <div
              className="table-container"
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'center',
                // overflowY: 'auto',
                // maxHeight: '80vh',
                fontFamily: 'Lato, Sans-serif',
              }}
            >
              <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {renderTable(0, 10)}
              </div>
              <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {renderTable(10, 20)}
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  );
};

export default FullscreenPageHisflow;
