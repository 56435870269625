import React, { useState } from "react";
import "./FormCol.css";

import { useTheme } from "@mui/material";

import { Box, Typography, TextField, Button, Input, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { useIsMobile, useIsTablet, useIsPortraitTablet } from "../../components/hooks";
import FontLoader from "../../components/FontLoader";

const Form = ({ symbol, setSymbol, startDate, setStartDate, endDate, setEndDate, nStd, setNStd, window, setWindow, fetchData }) => {
  const [loading, setLoading] = useState(false); // สถานะการโหลด

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  const theme = useTheme();
  const colors = theme.palette.mode;

  const handleSubmit = async () => {
    setLoading(true); // เริ่มโหลดเมื่อกด Submit
    await fetchData(); // เรียกใช้ fetchData เพื่อดึงข้อมูล
    setLoading(false); // หยุดโหลดเมื่อข้อมูลเสร็จ
  };



  return (
    <Box className="form-container"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        margin: '0',
        gap: '20px',
        justifyContent: 'center'
      }}
    >
      <FontLoader/>
      {/* Symbol */}

      {(!isMobile && !isPTablet) && (
        <>

          <TextField
            label="Symbol"
            id="symbol"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
            required
            fullWidth
            size="small"
          />


          {/* Start Date และ End Date */}

          <TextField
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }} // ทำให้ label ไม่ซ้อนทับ
          />
          <TextField
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            required
            fullWidth
            size="small"
            InputLabelProps={{ shrink: true }}
          />


          {/* n_std และ Window */}

          <TextField
            type="number"
            id="nStd"
            label="n_std"
            value={nStd}
            onChange={(e) => setNStd(e.target.value)}
            required
            fullWidth
            size="small"
          />
          <TextField
            type="number"
            id="window"
            label="Window"
            value={window}
            onChange={(e) => setWindow(e.target.value)}
            required
            fullWidth
            size="small"
          />

        <Button
        className="btn-hover color-3"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        SUBMIT
      </Button>

        </>
      )}
      {(isMobile || isPTablet) &&  (<>

        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
              < TextField
                label="Symbol"
                id="symbol"
                value={symbol}
                onChange={(e) => setSymbol(e.target.value)}
                required
                fullWidth
                size="small"
              />

<Button
        className="btn-hover color-3"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        SUBMIT
      </Button>

            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
              <TextField
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }} // ทำให้ label ไม่ซ้อนทับ
              />
              <TextField
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'row' , gap: '10px'}}>
              {/* n_std และ Window */}

              <TextField
                type="number"
                id="nStd"
                label="n_std"
                value={nStd}
                onChange={(e) => setNStd(e.target.value)}
                required
                fullWidth
                size="small"
              />
              <TextField
                type="number"
                id="window"
                label="Window"
                value={window}
                onChange={(e) => setWindow(e.target.value)}
                required
                fullWidth
                size="small"
              />
            </Box>
          </Box>

              
      
      
            
      
      
            
       </>)
      }



      {/* ปุ่ม Submit */}
      
    </Box>
  );
};

export default Form;
