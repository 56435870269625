import { useState, useEffect, useRef, useCallback } from 'react'

import { createChart } from 'lightweight-charts';
import { Box, Autocomplete, TextField, useTheme, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress, Paper, Container } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../../theme";
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import LoopIcon from '@mui/icons-material/Loop';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useFetchingData } from '../../../components/api.js';

function FullscreenFlowIntraday() {
    const { __fetching_data__ } = useFetchingData();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const wsRef = useRef(null);

    const [selectedFavorite, setSelectedFavorite] = useState(null);
    const [symbols, setSymbols] = useState(null);
    const [symbolsToSelect, setSymbolsToSelect] = useState([]);
    // const symbolsToSelect = Object.keys(mockData);
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [showAutocomplete, setShowAutocomplete] = useState(true);

    const [data, setData] = useState(null);

    const [flow_acc, setFlow_acc] = useState(null);

    const chartContainerRef = useRef(null);

    const updateInterval = useRef(null);
    const chartRef = useRef(null);

    const chartInitializedRef = useRef(false);


    const [isZoomed, setIsZoomed] = useState(false);



    let previousLogicalRange = useRef(null);


    const [isLoading, setIsLoading] = useState(false);


    const [openPopup, setOpenPopup] = useState(false);

    // เปิด-ปิด Popup
    const handleOpenPopup = () => setOpenPopup(true);
    const handleClosePopup = () => setOpenPopup(false);


    const base_api_url =
        process.env.NODE_ENV === "production"
            ? window.location.protocol === "https:"
                ? process.env.REACT_APP_API_HTTPS_URL
                : process.env.REACT_APP_API_HTTP_URL
            : "/application";




    const handleResetZoom = (chartId, setZoomState) => {
        setZoomState(false);
        if (chartId.current?.chart) {
            // console.log('handle Reset Click')

            chartId.current.chart.timeScale().fitContent();
        }
    }

    const handleResetAllZoom = () => {
        if (chartContainerRef.current?.chart) {
            setIsZoomed(false);
            chartContainerRef.current.chart.timeScale().fitContent();
        }
    }

    //function press Home to timeScale().fitContent()
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Home') {
                if (chartContainerRef.current?.chart) {
                    setIsZoomed(false);
                    chartContainerRef.current.chart.timeScale().fitContent();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search); // แปลง query string เป็น object
        const symbolFromURL = params.get("symbol"); // ดึงค่าของ 'symbol'

        if (symbolFromURL) {
            setSelectedSymbol(symbolFromURL.toUpperCase()); // อัปเดตค่า selectedSymbol
            setIsLoading(true);
            setShowAutocomplete(false);
        }
        console.log('yes is url symbol', selectedSymbol)
    }, [location.search]);


    useEffect(() => {
        const fetchSymbol = async () => {
            try {
                const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_symbols` });
                // const jsonData = await response.json();
                const symbolsData = jsonData.data.symbols
                // const symbolsData = ['test1','test2'];

                setSymbolsToSelect(symbolsData)
                // console.log(symbolsToSelect)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchSymbol();
    }, []);

    //fetch data 1
    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (!selectedSymbol || selectedSymbol == null || selectedSymbol == undefined) return;
    //         try {
    //             const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol)}` });
    //             // const jsonData = await response.json();

    //             if (!Array.isArray(jsonData.data)) {
    //                 // setData([]);
    //                 // setFlow_acc([]);
    //                 throw new Error('Response data is not an array');
    //             }

    //             // const extractedData = jsonData.data.map(item => {
    //             //     const date = new Date(item.time); // Assuming date is directly accessible now
    //             //     // Adjust the time to Thailand timezone
    //             //     date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
    //             //     return {
    //             //         time: date.getTime() / 1000,
    //             //         value: item.last,
    //             //     };
    //             // });
    //             // setData(extractedData);

    //             // const extractedAcc = jsonData.data.map(item => {
    //             //     const date = new Date(item.time); // Assuming date is directly accessible now
    //             //     // Adjust the time to Thailand timezone
    //             //     date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
    //             //     return {
    //             //         time: date.getTime() / 1000,
    //             //         value: item.flow,
    //             //     };
    //             // });
    //             // setFlow_acc(extractedAcc);

    //             const now = new Date();
    //             const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
    //             const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

    //             const extractedData = jsonData.data
    //                 .map(item => {
    //                     const date = new Date(item.date); // Assuming date is directly accessible now
    //                     // Adjust the time to Thailand timezone
    //                     date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7

    //                     return {
    //                         time: date.getTime() / 1000,
    //                         value: item.close,
    //                     };
    //                 })
    //                 .filter(item => {
    //                     const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
    //                     return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
    //                 });
    //             // console.log(date.getTime() / 1000)

    //             if (extractedData.length === 0) {
    //                 setData(null);
    //             }
    //             else {
    //                 setData(extractedData);
    //             }

    //             const extractedAcc = jsonData.data
    //                 .map(item => {
    //                     const date = new Date(item.date); // Assuming date is directly accessible now
    //                     // Adjust the time to Thailand timezone
    //                     date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
    //                     return {
    //                         time: date.getTime() / 1000,
    //                         value: item.flow_accum,
    //                     };
    //                 })
    //                 .filter(item => {
    //                     const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
    //                     return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
    //                 });
    //             if (extractedAcc.length === 0) {
    //                 setFlow_acc(null);
    //             }
    //             else {
    //                 setFlow_acc(extractedAcc);
    //             }

    //         } catch (error) {
    //             console.error('Error fetching or processing data:', error);
    //         }
    //     };

    //     // Fetch data initially
    //     fetchData();

    //     // Start a timer to fetch data every second
    //     const intervalId = setInterval(fetchData, 1000);

    //     // Cleanup function to clear the interval
    //     return () => clearInterval(intervalId);
    // }, [selectedSymbol]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //       if (!selectedSymbol || selectedSymbol == null || selectedSymbol == undefined) return;
    //       try {
    //         // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol)}` });
    //         const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol.toUpperCase())}` });
    
    //         if (!Array.isArray(jsonData.data)) {
    //           throw new Error('Response data is not an array');
    //         }
    
    //         const now = new Date();
    //         const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
    //         const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));
    
    //         const extractedData = jsonData.data
    //           .map(item => {
    //             const date = new Date(item.time); // Assuming date is directly accessible now
    //             // Adjust the time to Thailand timezone
    //             date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
    //             return {
    //               time: date.getTime() / 1000,
    //               value: item.last,
    //             };
    //           })
    //           .filter(item => {
    //             const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
    //             return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
    //           });
    
    //         if (extractedData.length === 0) {
    //           setData(null);
    //         }
    //         else {
    //           setData(extractedData);
    //         }
    
    //         const extractedAcc = jsonData.data
    //           .map(item => {
    //             const date = new Date(item.time); // Assuming date is directly accessible now
    //             // Adjust the time to Thailand timezone
    //             date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
    //             return {
    //               time: date.getTime() / 1000,
    //               value: item.flow,
    //             };
    //           })
    //           .filter(item => {
    //             const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
    //             return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
    //           });
    //         if (extractedAcc.length === 0) {
    //           setFlow_acc(null);
    //         }
    //         else {
    //           setFlow_acc(extractedAcc);
    //         }
    
    
    
    //       } catch (error) {
    //         console.error('Error fetching or processing data:', error);
    //       }
    //     };
    
    //     // Fetch data initially
    //     fetchData();
    
    //     setTimeout(() => {
    //       fetchData(); // เรียกครั้งที่ 2 หลังจาก 1 วินาที
    //     }, 1000);
    
    //     if (wsRef.current) {
    //       console.log("Closing WebSocket...");
    //       wsRef.current.close();
    //     }
    
    //     try {
    //       const ws = new WebSocket(`/ws/flow/${selectedSymbol.toUpperCase()}`);
    
    //       wsRef.current = ws;
    
    //       ws.onopen = () => console.log(`✅ WebSocket connected: ${selectedSymbol.toUpperCase()}`);
    
    //       ws.onmessage = (event) => {
    //         try {
    //           const response = JSON.parse(event.data);
    //           if (response.action === "re-fetch") {
    //             console.log(`🔄 Updated data for ${selectedSymbol.toUpperCase()}`);
    
    //             fetchData();
    //           }
    //         } catch (error) {
    //           console.error(" Error parsing WebSocket message:", error);
    //         }
    //       };
    
    //       ws.onerror = (error) => console.error(" WebSocket Error:", error);
    //       ws.onclose = () => console.log(" WebSocket disconnected");
    
    //       return () => {
    //         if (wsRef.current) {
    //           console.log(" Closing WebSocket...");
    //           wsRef.current.close();
    //         }
    //       };
    
    //     } catch {
    //       // Start a timer to fetch data every second
    //       const intervalId = setInterval(fetchData, 10000);
    
    //       // Cleanup function to clear the interval
    //       return () => clearInterval(intervalId);
    //     }
    
    //   }, [selectedSymbol]);

    //testing ws
    // useEffect(() => {
    //     if (!selectedSymbol) return;

    //     const fetchData = async () => {
    //       try {
    //         // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol)}` });
    //         const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol.toUpperCase())}` });
    //         // const response = await fetch(`http://172.18.1.81:5001/api/realtime/flow/${selectedSymbol.toUpperCase()}`);
    //         // const jsonData = await response.json();

    //         if (!Array.isArray(jsonData.data)) throw new Error('Response data is not an array');

    //         const today = new Date();
    //         const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    //         const endOfToday = new Date(today.setHours(23, 59, 59, 999));

    //         const filterToday = (item) => {
    //           const date = new Date(item.time);
    //           date.setHours(date.getHours() + 7);
    //           return date >= startOfToday && date <= endOfToday;
    //         };

    //         const extractedData = jsonData.data
    //           .filter(filterToday)
    //           .map(item => ({
    //             time: new Date(item.time).getTime() / 1000,
    //             value: item.last,
    //           }));

    //         setData(extractedData.length ? extractedData : null);

    //         const extractedAcc = jsonData.data
    //           .filter(filterToday)
    //           .map(item => ({
    //             time: new Date(item.time).getTime() / 1000,
    //             value: item.flow,
    //           }));

    //         setFlow_acc(extractedAcc.length ? extractedAcc : null);
    //       } catch (error) {
    //         console.error('Error fetching or processing data:', error);
    //       }
    //     };

    //     fetchData();

    //     // ปิด WebSocket ก่อนเปิดใหม่
    //     if (wsRef.current) {
    //       console.log("🚪 Closing WebSocket...");
    //       wsRef.current.close();
    //     }

    //     const ws = new WebSocket(`ws://172.18.1.81:5002/ws/flow/${selectedSymbol.toUpperCase()}`);
    //     wsRef.current = ws;

    //     ws.onopen = () => console.log(`✅ WebSocket connected: ${selectedSymbol.toUpperCase()}`);

    //     ws.onmessage = (event) => {
    //       try {
    //         const response = JSON.parse(event.data);
    //         if (response.action === "re-fetch") {
    //           console.log("🔄 Updated data:", response.data);
    //           setData(response.data);
    //         }
    //       } catch (error) {
    //         console.error(" Error parsing WebSocket message:", error);
    //       }
    //     };

    //     ws.onerror = (error) => console.error(" WebSocket Error:", error);
    //     ws.onclose = () => console.log(" WebSocket disconnected");

    //     return () => {
    //       if (wsRef.current) {
    //         console.log(" Closing WebSocket...");
    //         wsRef.current.close();
    //       }
    //     };

    //   }, [selectedSymbol]);

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedSymbol || selectedSymbol == null || selectedSymbol == undefined) return;
            try {
                // const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_data?symbol=${encodeURIComponent(selectedSymbol)}` });
                const jsonData = await __fetching_data__({ url: `${base_api_url}/realtime_ws_data?symbol=${encodeURIComponent(selectedSymbol.toUpperCase())}` }); // ***** API ตัวใหม่บังคับให้เป็น ตัวใหญ่เท่านั้นถึงจะหาเจอ
                // const jsonData = await response.json();

                if (!Array.isArray(jsonData.data)) {
                    throw new Error('Response data is not an array');
                }

                const now = new Date();
                const startOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));
                const endOfToday = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59, 999));

                const extractedData = jsonData.data
                    .map(item => {
                        const date = new Date(item.time); // API เก่าใช้ item.time API ใหม่ใช้ item.time
                        // Adjust the time to Thailand timezone
                        date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7

                        return {
                            time: date.getTime() / 1000,
                            // value: item.close, //API ตัวเก่าใช้ item.close
                            value: item.last, //API ตัวใหม่ใช้ item.last
                        };
                    })
                    .filter(item => {
                        const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                        return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                    });

                if (extractedData.length === 0) {
                    setData(null);
                }
                else {
                    setData(extractedData);
                }

                const extractedAcc = jsonData.data
                    .map(item => {
                        const date = new Date(item.time); // API เก่าใช้ item.time API ใหม่ใช้ item.time
                        // Adjust the time to Thailand timezone
                        date.setHours(date.getHours() + 7); // Assuming Thailand is GMT+7
                        return {
                            time: date.getTime() / 1000,
                            // value: item.flow_accum, // API เก่าใช้ item.flow_accum
                            value: item.flow, // API ใหม่ใช้ item.flow
                        };
                    })
                    .filter(item => {
                        const date = new Date(item.time * 1000); // แปลง timestamp กลับมาเป็นวันที่เพื่อกรอง
                        return date >= startOfToday && date <= endOfToday; // กรองข้อมูลเฉพาะวันที่อยู่ภายในวันนี้
                    });
                if (extractedAcc.length === 0) {
                    setFlow_acc(null);
                }
                else {
                    setFlow_acc(extractedAcc);
                }

            } catch (error) {
                console.error('Error fetching or processing data:', error);
            }
        };

        // Fetch data initially
        fetchData();

        // Start a timer to fetch data every second
        const intervalId = setInterval(fetchData, 10000);

        // Cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }, [selectedSymbol]);




    //chart1
    useEffect(() => {
        if (data && chartContainerRef.current) {
            // console.log('chartContainerRef:', chartContainerRef.current);
            let chart = chartContainerRef.current.chart;

            // console.log('data Chart 1 is', data)

            if (!chart) {

                chart = createChart(chartContainerRef.current, {
                    width: chartContainerRef.current.clientWidth,
                    height: chartContainerRef.current.clientHeight,

                    timeScale: {
                        timeVisible: true,
                        secondsVisible: true,
                        rightOffset: data.length = 1 ? 1 : data.length < 20 ? 0 : data.length < 50 ? 1 : 5
                    },
                    rightPriceScale: {
                        visible: true,

                    },
                    leftPriceScale: {
                        visible: true,
                    },


                });
                // Define layout options for two price scales
                const layoutOptions = {

                };
                // Apply layout options
                chart.applyOptions({
                    priceScale: {
                        position: 'left'
                    },
                    priceScale2: {
                        position: 'right'

                    },
                    priceFormat: {
                        type: 'custom',
                        minMove: 0.02,
                        formatter: price => '$' + price.toFixed(2),
                    },
                    localization: {
                        locale: 'th-TH',
                    },
                    layout: {
                        background: { color: theme.palette.table.odd },
                        textColor: theme.palette.text.secondary,
                    },
                    grid: {
                        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
                    },




                });

                chartContainerRef.current.style.borderRadius = '10px';
                chartContainerRef.current.style.overflow = 'hidden';
                chartContainerRef.current.chart = chart;
                // chart.timeScale().subscribeVisibleTimeRangeChange(() => {
                //     isZoomedRef.current = true; // ผู้ใช้ทำการซูม/เลื่อน
                // });

                // chart.subscribeCrosshairMove((param) => {
                //     if (!param || !param.point) {
                //         isZoomedRef.current = false;
                //         console.log('Mouse left chart or param is undefined');
                //         return;
                //     }

                //     isZoomedRef.current = true;
                //     console.log('Mouse moved on chart');
                // });



                let minFrom = null;
                let maxTo = null;

                chart.timeScale().subscribeVisibleLogicalRangeChange((newLogicalRange) => {
                    if (!newLogicalRange) return;

                    // หาค่า minFrom และ maxTo ครั้งแรกที่สร้าง Chart
                    if (minFrom === null || maxTo === null) {
                        minFrom = newLogicalRange.from;
                        maxTo = newLogicalRange.to;
                    }

                    if (!previousLogicalRange) {
                        previousLogicalRange = newLogicalRange;
                        return;
                    }

                    const epsilon = 0.000001;
                    const fromChanged = Math.abs(previousLogicalRange.from - newLogicalRange.from) > epsilon;
                    const toChanged = Math.abs(previousLogicalRange.to - newLogicalRange.to) > epsilon;
                    const rangeChanged = fromChanged || toChanged;

                    if (rangeChanged) {
                        // ✅ ตรวจสอบว่ากลับมาอยู่ในช่วงเต็มของข้อมูลหรือไม่
                        const isResetZoom = newLogicalRange.from <= minFrom && newLogicalRange.to >= maxTo;

                        if (isResetZoom || (newLogicalRange.from >= 0 && newLogicalRange.from <= 3)) {
                            setIsZoomed(false);
                            console.log('Reset zoom to full range');
                        } else {
                            setIsZoomed(true);
                            console.log('User zoomed the chart:', newLogicalRange);
                        }
                    }

                    previousLogicalRange = newLogicalRange;
                });


                // ✅ Cleanup เมื่อ component ถูก unmount
                return () => {
                    // chart.unsubscribeCrosshairMove();
                    chart.timeScale().unsubscribeVisibleLogicalRangeChange();
                };
            }


            chart.applyOptions({
                watermark: {
                    visible: true,
                    fontSize: 36,
                    horzAlign: 'center',
                    vertAlign: 'top',
                    color: theme.palette.chart.watermark,
                    text: selectedSymbol.toUpperCase(),
                },
            });

            // const filterAndSortData = (data) => {
            //     return [...new Map(data.map(item => [item.time, item])).values()]
            //         .sort((a, b) => a.time - b.time);
            // };
            const filterAndSortData = (data) => {
                // return [...new Map(data.map(item => [item.time, item])).values()]
                //   .sort((a, b) => a.time - b.time);
                const today = new Date();

                // ตั้งค่าเวลาเริ่มต้นและสิ้นสุดของวันนี้
                const startOfToday = new Date(today.setHours(0, 0, 0, 0)).getTime() / 1000;
                const endOfToday = new Date(today.setHours(23, 59, 59, 999)).getTime() / 1000;

                return [...new Map(data
                    .filter(item => item.time >= startOfToday && item.time <= endOfToday) // กรองเฉพาะวันที่ปัจจุบัน
                    .map(item => [item.time, item]) // ลบข้อมูลซ้ำด้วย Map โดยใช้เวลา (timestamp) เป็น key
                ).values()]
                    .sort((a, b) => a.time - b.time); // จัดเรียงข้อมูลตามเวลา
            };

            // กรองข้อมูลก่อนแสดงผล
            const filteredData = filterAndSortData(data);
            const filteredFlowAcc = filterAndSortData(flow_acc);

            if (chartContainerRef.current.lineSeries) {
                chart.removeSeries(chartContainerRef.current.lineSeries);
                chartContainerRef.current.lineSeries = null;
            }
            if (chartContainerRef.current.baselineSeries) {
                chart.removeSeries(chartContainerRef.current.baselineSeries);
                chartContainerRef.current.baselineSeries = null;
            }

            let lineSeries = chartContainerRef.current.lineSeries;

            if (!lineSeries) {
                lineSeries = chart.addLineSeries({
                    color: colors.whiteChart[200],
                    priceScaleId: 'right'
                });
                chartContainerRef.current.lineSeries = lineSeries;
            }

            // lineSeries.setData(data);
            lineSeries.setData(filteredData);

            // Add a second line series
            let baselineSeries = chartContainerRef.current.baselineSeries;
            if (!baselineSeries) {
                baselineSeries = chart.addBaselineSeries({
                    priceScaleId: 'left',
                    baseValue: { type: 'price', price: filteredFlowAcc[0]?.value || 0 },
                    topLineColor: colors.greenChart[100],
                    bottomLineColor: colors.redChart[100],
                    topFillColor1: 'rgba(0, 0, 0, 0)',
                    topFillColor2: 'rgba(0, 0, 0, 0)',
                    bottomFillColor1: 'rgba(0, 0, 0, 0)',
                    bottomFillColor2: 'rgba(0, 0, 0, 0)',
                    priceFormat: {
                        type: 'custom',
                        minMove: 0.00000001,
                        formatter: price => {
                            const absPrice = Math.abs(price);
                            if (absPrice >= 1e9) return (price / 1e9).toFixed(3) + 'B';
                            if (absPrice >= 1e6) return (price / 1e6).toFixed(1) + 'M';
                            if (absPrice >= 1e5) return (price / 1e5).toFixed(1) + 'K';
                            if (absPrice >= 1e4) return (price / 1e4).toFixed(2) + 'K';
                            if (absPrice >= 1e3) return (price / 1e3).toFixed(3) + 'K';
                            return price.toFixed(0);
                        },
                    },
                });
                chartContainerRef.current.baselineSeries = baselineSeries;
            }
            // baselineSeries.setData(flow_acc);
            baselineSeries.setData(filteredFlowAcc);
            // if (!chartInitializedRef.current) {
            //     chart.timeScale().fitContent();
            //     chartInitializedRef.current = true; // ทำเครื่องหมายว่า fitContent ถูกเรียกไปแล้ว
            // }

            // 🔄 **เงื่อนไขการใช้ `fitContent()`**
            if (!chartInitializedRef.current) {
                chart.timeScale().fitContent();
                chartInitializedRef.current = true; // ป้องกันการเรียก fitContent() ซ้ำ
            } else if (!isZoomed) {
                console.log('just do it');
                chart.timeScale().fitContent();
            }
            console.log('zoom ref', isZoomed);


            setIsLoading(false);
        }
    }, [data, flow_acc]);




    console.log("This is data : ", data)


    const symbolSelected = [
        selectedSymbol,

    ];

    const applyFavoriteSymbols = (symbols) => {
        if (symbols && symbols.length) {
            const setters = [
                setSelectedSymbol
            ];

            const autocompleteSetters = [
                setShowAutocomplete,
            ];

            const loadingSetters = [
                setIsLoading
            ];

            symbols.forEach((symbol, index) => {
                if (setters[index]) setters[index](symbol || null);
                if (symbol) {
                    if (autocompleteSetters[index]) autocompleteSetters[index](false);
                    if (loadingSetters[index]) loadingSetters[index](true);
                }
            });
        }
    };


    return (
        <>
            <Box id='main' className='container' sx={{
                // position: 'relative',
                // width: '100vw',
                // maxWidth: 'xl',
                height: '99vh',
                display: 'flex',
                // flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>

                {/* <IconButton children={<LoopIcon />} onClick={() => handleResetAllZoom()}
                    sx={{
                        position: 'absolute',
                        right: '5%',
                        top: '10px',
                        zIndex: 10,
                        backgroundColor: "#575b69",
                        // padding: '4px',
                        // margin: "1%",
                        "&:hover": { backgroundColor: 'transparent' }
                    }} /> */}

                {/* chart 1*/}
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 2 }}>
                    <Typography variant='h2'>
                        Fullscreen Flow Intraday
                    </Typography>

                </Box>

                <Container maxWidth="xl" sx={{ flex: 1, display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <Paper sx={{
                        // flex: 1,
                        overflowY: 'hidden',
                        width: '100%',
                        heigh: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 2
                        // backgroundColor: theme.palette.background.container1,

                    }} >
                        <Box className='chartBox2' sx={{ borderRadius: '10px', position: 'relative', height: '80vh', width: '100%', backgroundColor: '#575b69', margin: '2px' }}>
                            {showAutocomplete ? (
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={symbolsToSelect}
                                    // options={Object.keys(mockData)}
                                    size="small"
                                    className="sl-symbol"
                                    onChange={(event, newValue) => {
                                        setSelectedSymbol(newValue);
                                        setIsLoading(true);
                                        setShowAutocomplete(false); // ซ่อน Autocomplete หลังเลือกค่า
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            const typedValue = event.target.value.trim();
                                            setSelectedSymbol(typedValue);
                                            setIsLoading(true);
                                            setShowAutocomplete(false); // ซ่อน Autocomplete หลังกด Enter
                                        }
                                    }}
                                    sx={{
                                        margin: "1%",
                                        width: "25%",
                                        height: "20px",
                                        zIndex: 10,
                                        position: "absolute",
                                        left: "11%",
                                    }}
                                    renderInput={(params) => <TextField {...params} label="" />}
                                />
                            ) : (
                                // ถ้า showAutocomplete เป็น false ให้แสดงปุ่ม Search แทน
                                <IconButton children={<SearchIcon sx={{ display: 'none', }} />}
                                    onClick={() => setShowAutocomplete(true)} // กดแล้วให้ Autocomplete กลับมา
                                    sx={{
                                        position: "absolute",
                                        left: "15%",
                                        padding: '8px',
                                        margin: "1%",
                                        backgroundColor: "transparent",
                                        zIndex: 10,
                                        "&:hover": { backgroundColor: "#44495a", color: 'white' },
                                    }}
                                >
                                </IconButton>
                            )}
                            <IconButton children={<LoopIcon />} onClick={() => handleResetZoom(chartContainerRef, setIsZoomed)}
                                sx={{
                                    position: 'absolute',
                                    right: '12%',
                                    zIndex: 10,
                                    backgroundColor: "#575b69",
                                    padding: '8px',
                                    margin: "1%",
                                    "&:hover": { backgroundColor: 'transparent' }
                                }} />
                            {isLoading && (
                                <Box className='loading' sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%', mb: '50px' }}>
                                    <CircularProgress sx={{ zIndex: 1111, color: 'white' }} />
                                    <Typography variant="body1" sx={{ color: 'white', marginTop: 1 }}>
                                        Searching for {selectedSymbol != null ? selectedSymbol.toUpperCase() : null}
                                    </Typography>
                                </Box>
                            )}

                            <div ref={chartContainerRef} style={{ width: '100%', height: '100%', position: 'relative', top: '0', left: '0', zIndex: 0 }} />



                        </Box>

                    </Paper>

                </Container>


            </Box >

        </>
    );
}

export default FullscreenFlowIntraday