import React, { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { useDispatch, useSelector } from "react-redux";
// import './ValueOfTime.css';
// import config from '../config/config.json';
import { TextField, Button, Box, Stack, useTheme, Typography } from '@mui/material';
import { tokens } from "../../theme";
import Preloading from '../../components/Preloading';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

const ValueOfTime = () => {

    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isPTablet = useIsPortraitTablet();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [symbol, setSymbol] = useState('');
    const [date, setDate] = useState('');
    const [dailyData, setDailyData] = useState(null);
    const [intradayData, setIntradayData] = useState(null);
    const [loading, setLoading] = useState(false);

    const priceChartRef = useRef();
    const exceedFreqChartRef = useRef();
    const intradayPriceChartRef = useRef();
    const exceedValueChartRef = useRef();
    const intradayNetChartRef = useRef();

    const csrfToken = useSelector((state) => state.csrfToken);

    const clearCharts = () => {
        [priceChartRef, exceedFreqChartRef, exceedValueChartRef, intradayPriceChartRef, intradayNetChartRef].forEach(ref => {
            if (ref.current && ref.current._chart) {
                ref.current._chart.remove();
                ref.current._chart = null;
            }
        });
    };

    const fetchDailyData = async (symbol) => {
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setDailyData(data.dailyData);
            console.log("VOT Daily response data:", data);
            return data;
        } catch (error) {
            console.error('Error fetching daily data:', error);
            return { error: "Unable to fetch VOT daily response" };
        }
    };

    const fetchIntradayData = async (symbol, date) => {
        try {
            const response = await fetch(`${window.config.base_api_url}/vot?symbol=${symbol}&date=${date}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'xsrf-token': csrfToken,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setIntradayData(data);
            console.log("VOT Intraday response data:", data);
            return data;
        } catch (error) {
            console.error('Error fetching intraday data:', error);
            return { error: "Unable to fetch VOT intraday response" };
        }
    };


    const fetchData = async () => {
        if (!symbol || !date) {
            console.error('Both Symbol and Date are required');
            return;
        }

        setLoading(true);
        try {
            // Clear existing charts before creating new ones
            clearCharts();

            // Fetch daily and intraday data simultaneously
            const [dailyResponse, intradayResponse] = await Promise.all([
                fetchDailyData(symbol),
                fetchIntradayData(symbol, date)
            ]);

            // You can update the state or handle the fetched data as needed
            console.log('Fetched data:', { dailyResponse, intradayResponse });

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Utility function to sort data by time in ascending order
    const sortByTimeAscending = (data) => {
        return data.sort((a, b) => a.time - b.time);
    };

    // Check if both inputs are filled
    const areInputsFilled = () => symbol && date;

    // Sync visible range between two charts
    const syncVisibleRange = (chart1, chart2) => {
        chart1.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            chart2.timeScale().setVisibleLogicalRange(timeRange);
        });

        chart2.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
            chart1.timeScale().setVisibleLogicalRange(timeRange);
        });
    };

    // Sync crosshair between two charts
    const getCrosshairDataPoint = (series, param) => {
        if (!param.time) {
            return null;
        }
        const dataPoint = param.seriesData.get(series);
        return dataPoint || null;
    };

    const syncCrosshair = (chart, series, dataPoint) => {
        if (dataPoint) {
            chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
        } else {
            chart.clearCrosshairPosition();
        }
    };

    const syncCrosshairMove = (chart1, series1, chart2, series2) => {
        chart1.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(series1, param);
            syncCrosshair(chart2, series2, dataPoint);
        });

        chart2.subscribeCrosshairMove((param) => {
            const dataPoint = getCrosshairDataPoint(series2, param);
            syncCrosshair(chart1, series1, dataPoint);
        });
    };

    const createChartContainer = (container, options) => {
        // const chart = createChart(container, options);
        // container._chart = chart; // Save reference to prevent duplicates
        // return chart;

        if (!container) return;

        // ตรวจสอบว่ามี _chart อยู่แล้วหรือไม่ ถ้ามีให้ลบก่อน
        if (container._chart) {
            container._chart.remove();
        }

        // สร้าง wrapper ป้องกันปัญหากราฟซ้อน
        const wrapper = document.createElement('div');
        wrapper.style.width = '100%';
        wrapper.style.height = '100%';
        wrapper.style.borderRadius = '12px';
        wrapper.style.overflow = 'hidden';
        wrapper.style.position = 'relative'; // ✅ ป้องกันซ้อนกันมั่ว
        wrapper.style.backgroundColor = theme.palette.table.odd;

        container.innerHTML = ''; // ✅ ล้าง element เก่าก่อนใส่ wrapper
        container.appendChild(wrapper);

        const chart = createChart(wrapper, options);
        container._chart = chart; // ✅ ป้องกันสร้างซ้ำ

        return chart;
    };

    // Create chart for price data
    const createPriceChart = (container, priceData) => {
        // const chart = createChartContainer(container, {
        //     width: container.clientWidth,
        //     height: container.clientHeight,
        //     timeScale: {
        //         rightOffset: 5
        //     }
        // });
        if (!container) return;

        container.innerHTML = ''; // ✅ ล้างเนื้อหาเก่า
        const wrapper = document.createElement('div');
        wrapper.style.width = '100%';
        wrapper.style.height = '100%';
        wrapper.style.borderRadius = '12px'; // ✅ ทำให้มุมโค้ง
        wrapper.style.overflow = 'hidden';  // ✅ ป้องกันไม่ให้กราฟทะลุขอบ
        wrapper.style.backgroundColor = theme.palette.table.odd;

        container.appendChild(wrapper); // ✅ ใส่ wrapper ลงไปก่อน

        // ✅ ใช้ wrapper เป็น container ของ chart
        const chart = createChartContainer(wrapper, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                rightOffset: 5
            }
        });
        const series = chart.addLineSeries({ color: "#95fe51", priceLineVisible: false });
        chart.applyOptions({
            layout: {
                background: { color: theme.palette.table.odd },
                textColor: theme.palette.text.secondary,
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
            },
            watermark: {  
                visible: true,
                fontSize: 24,  
                horzAlign: 'center', 
                vertAlign: 'top',  
                color: theme.palette.chart.watermark,
                text: symbol.toUpperCase(),
            },
        });
        series.setData(priceData.map(item => ({
            time: new Date(item.date).getTime() / 1000, // Convert to timestamp
            value: item.close,
        })));


        return { chart, series };
    };

    // Create chart for exceed frequency
    const createExceedFreqChart = (container, exceedData) => {
        // const chart = createChartContainer(container, {
        //     width: container.clientWidth,
        //     height: container.clientHeight,
        //     timeScale: {
        //         rightOffset: 5
        //     }
        // });
        if (!container) return;

        container.innerHTML = ''; // ✅ ล้างเนื้อหาเก่า
        const wrapper = document.createElement('div');
        wrapper.style.width = '100%';
        wrapper.style.height = '100%';
        wrapper.style.borderRadius = '12px'; // ✅ ทำให้มุมโค้ง
        wrapper.style.overflow = 'hidden';  // ✅ ป้องกันไม่ให้กราฟทะลุขอบ
        wrapper.style.backgroundColor = theme.palette.table.odd;

        container.appendChild(wrapper); // ✅ ใส่ wrapper ลงไปก่อน

        // ✅ ใช้ wrapper เป็น container ของ chart
        const chart = createChartContainer(wrapper, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                rightOffset: 5
            }
        });

        chart.applyOptions({
            layout: {
                background: { color: theme.palette.table.odd },
                textColor: theme.palette.text.secondary,
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
            },
            watermark: {  
                visible: true,
                fontSize: 24,  
                horzAlign: 'center', 
                vertAlign: 'top',  
                color: theme.palette.chart.watermark,
                text: symbol.toUpperCase(),
            },
        });
        const series = chart.addLineSeries({
            color: "#5efee8",
            priceLineVisible: false
        });
        series.setData(exceedData.map(item => ({
            time: new Date(item.date).getTime() / 1000, // Convert to timestamp
            value: item.n_exceed,
        })));
        return { chart, series };
    };

    // Create chart for exceed value
    const createExceedValueChart = (container, exceedData) => {
        if (!container) return;

        container.innerHTML = ''; // ✅ ล้างเนื้อหาเก่า
        const wrapper = document.createElement('div');
        wrapper.style.width = '100%';
        wrapper.style.height = '100%';
        wrapper.style.borderRadius = '12px'; // ✅ ทำให้มุมโค้ง
        wrapper.style.overflow = 'hidden';  // ✅ ป้องกันไม่ให้กราฟทะลุขอบ
        wrapper.style.backgroundColor = theme.palette.table.odd;

        container.appendChild(wrapper); // ✅ ใส่ wrapper ลงไปก่อน

        // ✅ ใช้ wrapper เป็น container ของ chart
        const chart = createChartContainer(wrapper, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                rightOffset: 5
            }
        });
        // const chart = createChartContainer(container, {
        //     width: container.clientWidth,
        //     height: container.clientHeight,
        //     timeScale: {
        //         rightOffset: 5
        //     }
        // });
        const series = chart.addLineSeries({
            color: "#ffdf4f",
            priceLineVisible: false,
            priceFormat: {
                type: 'volume',
                minMove: 1,
                precision: 2,
            },
        });

        chart.applyOptions({
            layout: {
                background: { color: theme.palette.table.odd },
                textColor: theme.palette.text.secondary,
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
            },
            watermark: {  
                visible: true,
                fontSize: 24,  
                horzAlign: 'center', 
                vertAlign: 'top',  
                color: theme.palette.chart.watermark,
                text: symbol.toUpperCase(),
            },
        });
        series.setData(exceedData.map(item => ({
            time: new Date(item.date).getTime() / 1000, // Convert to timestamp
            value: item.val_exceed,
        })));
        return { chart, series };
    };

    // Add this function to convert the timestamp to UNIX
    const parseDateString = (dateString) => {
        return new Date(dateString.replace(" ", "T") + "Z").getTime() / 1000;
    };

    // Create chart for intraday price
    const createIntradayPriceChart = (container, priceData, exceedDate) => {
        // const chart = createChartContainer(container, {
        //     width: container.clientWidth,
        //     height: container.clientHeight,
        //     timeScale: {
        //         timeVisible: true,
        //         secondsVisible: true,
        //         rightOffset: 5
        //     },
        // });
        if (!container) return;
        container.innerHTML = '';
        const wrapper = document.createElement('div');
        wrapper.style.width = '100%';
        wrapper.style.height = '100%';
        wrapper.style.borderRadius = '12px'; // ✅ ทำให้มุมโค้ง
        wrapper.style.overflow = 'hidden';  // ✅ ป้องกันไม่ให้กราฟทะลุขอบ
        wrapper.style.backgroundColor = theme.palette.table.odd;

        container.appendChild(wrapper); // ✅ ใส่ wrapper ลงไปก่อน

        // ✅ ใช้ wrapper เป็น container ของ chart
        const chart = createChartContainer(wrapper, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                rightOffset: 5
            }
        });
        chart.applyOptions({
            layout: {
                background: { color: theme.palette.table.odd },
                textColor: theme.palette.text.secondary,
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
            },
            watermark: {  
                visible: true,
                fontSize: 24,  
                horzAlign: 'center', 
                vertAlign: 'top',  
                color: theme.palette.chart.watermark,
                text: symbol.toUpperCase(),
            },
        });
        const series = chart.addLineSeries({ color: "#df82ff", priceLineVisible: false });
        series.setData(sortByTimeAscending(priceData.map(item => ({
            time: new Date(item.time).getTime() / 1000,
            value: item.last,
        }))));

        // Add markers
        const markers = [];
        exceedDate.up.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'aboveBar',
                color: colors.greenChart[100],
                shape: 'arrowUp',
                text: 'U',
            });
        });

        exceedDate.down.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'belowBar',
                color: 'red',
                shape: 'arrowDown',
                text: 'D',
            });
        });

        // Sort markers by ascending time
        markers.sort((a, b) => a.time - b.time);
        series.setMarkers(markers);

        return { chart, series };
    };

    // Create chart for intraday net value
    const createIntradayNetChart = (container, exceedData, exceedDate) => {
        // const chart = createChartContainer(container, {
        //     width: container.clientWidth,
        //     height: container.clientHeight,
        //     timeScale: {
        //         timeVisible: true,
        //         secondsVisible: true,
        //         rightOffset: 5
        //     },
        // });
        if (!container) return;

        container.innerHTML = '';
        const wrapper = document.createElement('div');
        wrapper.style.width = '100%';
        wrapper.style.height = '100%';
        wrapper.style.borderRadius = '12px'; // ✅ ทำให้มุมโค้ง
        wrapper.style.overflow = 'hidden';  // ✅ ป้องกันไม่ให้กราฟทะลุขอบ
        wrapper.style.backgroundColor = theme.palette.table.odd;

        container.appendChild(wrapper); // ✅ ใส่ wrapper ลงไปก่อน

        // ✅ ใช้ wrapper เป็น container ของ chart
        const chart = createChartContainer(wrapper, {
            width: container.clientWidth,
            height: container.clientHeight,
            timeScale: {
                rightOffset: 5
            }
        });

        chart.applyOptions({
            layout: {
                background: { color: theme.palette.table.odd },
                textColor: theme.palette.text.secondary,
            },
            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
            },
            watermark: {  
                visible: true,
                fontSize: 24,  
                horzAlign: 'center', 
                vertAlign: 'top',  
                color: theme.palette.chart.watermark,
                text: symbol.toUpperCase(),
            },
        });
        const series = chart.addLineSeries({
            color: "#a8d2ff",
            priceLineVisible: false,
            priceFormat: {
                type: 'volume',
                minMove: 1,
                precision: 2,
            },
        });
        series.setData(sortByTimeAscending(exceedData.map(item => ({
            time: new Date(item.time).getTime() / 1000,
            value: item.net_val,
        }))));

        // Add markers
        const markers = [];
        exceedDate.up.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'aboveBar',
                color: colors.greenChart[100],
                shape: 'arrowUp',
                text: 'U',
            });
        });

        exceedDate.down.forEach((time) => {
            markers.push({
                time: parseDateString(time),
                position: 'belowBar',
                color: 'red',
                shape: 'arrowDown',
                text: 'D',
            });
        });

        // Sort markers by ascending time
        markers.sort((a, b) => a.time - b.time);
        series.setMarkers(markers);

        return { chart, series };
    };

    useEffect(() => {
        clearCharts();
        if (dailyData && !priceChartRef.current._chart) {
            const { chart: priceChart, series: priceSeries } = createPriceChart(priceChartRef.current, dailyData.dailyPrice);
            const { chart: freqChart, series: freqSeries } = createExceedFreqChart(exceedFreqChartRef.current, dailyData.exceedData);
            const { chart: valueChart, series: valueSeries } = createExceedValueChart(exceedValueChartRef.current, dailyData.exceedData);

            // Sync charts' visible range
            syncVisibleRange(priceChart, freqChart);
            syncVisibleRange(priceChart, valueChart);
            syncVisibleRange(freqChart, valueChart);

            // Sync crosshair
            syncCrosshairMove(priceChart, priceSeries, freqChart, freqSeries);
            syncCrosshairMove(priceChart, priceSeries, valueChart, valueSeries);
            syncCrosshairMove(freqChart, freqSeries, valueChart, valueSeries);
        }

        if (intradayData && !intradayPriceChartRef.current._chart && !intradayNetChartRef.current._chart) {
            const { chart: intradayPriceChart, series: intradayPriceSeries } = createIntradayPriceChart(intradayPriceChartRef.current, intradayData.priceData, intradayData.exceedDate);
            const { chart: intradayNetChart, series: intradayNetSeries } = createIntradayNetChart(intradayNetChartRef.current, intradayData.exceedData, intradayData.exceedDate);

            // Sync charts' visible range
            syncVisibleRange(intradayPriceChart, intradayNetChart);

            // Sync crosshair
            syncCrosshairMove(intradayPriceChart, intradayPriceSeries, intradayNetChart, intradayNetSeries);
        }
    }, [dailyData, intradayData]);

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '1800px', paddingLeft: '20px', paddingRight: '20px' }}>

            <Typography variant='h3' sx={{ fontWeight: 'bold', padding: '10px 0 20px 0', display: 'flex', justifyContent: 'center' }}>Value of Time

            </Typography>
            {/* <div className='vot-header-input'>
                <div className='vot-input'>
                    <label>Symbol:</label>
                    <input
                        value={symbol}
                        onChange={(e) => setSymbol(e.target.value)}
                        placeholder="Enter symbol"
                    />
                </div>

                <div className='vot-input'>
                    <label>Select date:</label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                </div>

                <button onClick={fetchData} disabled={!areInputsFilled()}>Submit</button>
            </div> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    marginBottom: 2,
                }}
            >
                <Box>
                    <TextField
                        label="Symbol"
                        value={symbol}
                        onChange={(e) => setSymbol(e.target.value)}
                        placeholder="Enter symbol"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{
                            '& .MuiInputBase-input': {
                                padding: "10px",
                            },
                        }}
                    />
                </Box>
                <Box>
                    <TextField
                        label="Select Date"
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                        sx={{
                            '& .MuiInputBase-input': {
                                padding: "10px",
                            },
                        }}
                    />
                </Box>

                <Button
                    variant="contained"
                    onClick={fetchData}
                    disabled={!areInputsFilled()}
                    sx={{
                        padding: "10px",
                        backgroundColor: colors.blueAccent[500],
                    }}
                >
                    Submit
                </Button>
            </Box>
            <Preloading open={loading} />
            {loading && (
                <p style={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#000000',
                    textAlign: 'center',
                    marginTop: '20px'
                }}>
                    {/* Loading... */}
                </p>
            )}

            <Box sx={{
                gap: '10px', display: 'flex', flexDirection: 'column',
                //  margin: '0 10px 0 10px', padding: 2 , backgroundColor: theme.palette.background.sidebar, boxShadow: theme.palette.shadow.primary
            }}>

                <Box>
                    <Typography variant='h5' sx={{ backgroundColor: theme.palette.background.card, padding: 0.75, width: 'fit-content', borderRadius: '5px', marginBottom: '5px' }}>
                        Price
                    </Typography>
                    <div
                        ref={priceChartRef}
                        style={{
                            position: 'relative',
                            height: (isMobile || isTablet) ? '200px' : '230px',
                            width: '100%',
                            backgroundColor: theme.palette.table.odd,
                            borderRadius: '10px'
                        }}
                    >
                        {/* <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20 }}>Price</div> */}
                    </div>
                </Box>



                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                    <Box>
                        <Typography variant='h5' sx={{ backgroundColor: theme.palette.background.card, padding: 0.75, width: 'fit-content', borderRadius: '5px', marginBottom: '5px' }}>
                            Frequency Exceed
                        </Typography>
                        <div
                            ref={exceedFreqChartRef}
                            style={{
                                position: 'relative',
                                height: (isMobile || isTablet) ? '200px' : '230px',
                                width: '100%',
                                backgroundColor: theme.palette.table.odd,
                                borderRadius: '10px'
                            }}
                        >
                            {/* <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20 }}>Frequency Exceed</div> */}
                        </div>
                    </Box>
                    <Box>
                        <Typography variant='h5' sx={{ backgroundColor: theme.palette.background.card, padding: 0.75, width: 'fit-content', borderRadius: '5px', marginBottom: '5px' }}>
                            Intraday Price
                        </Typography>
                        <div
                            ref={intradayPriceChartRef}
                            style={{
                                position: 'relative',
                                height: (isMobile || isTablet) ? '200px' : '230px',
                                width: '100%',
                                backgroundColor: theme.palette.table.odd,
                                borderRadius: '10px'
                            }}
                        >
                            {/* <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20 }}>Intraday Price</div> */}
                        </div>
                    </Box>


                </div>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                    <Box>
                        {/* <Box sx={{}}>
                        Exceed Value
                    </Box> */}
                        <Typography variant='h5' sx={{ backgroundColor: theme.palette.background.card, padding: 0.75, width: 'fit-content', borderRadius: '5px', marginBottom: '5px' }}>
                            Exceed Value
                        </Typography>
                        <div
                            ref={exceedValueChartRef}
                            style={{
                                position: 'relative',
                                height: (isMobile || isTablet) ? '200px' : '230px',
                                width: '100%',
                                backgroundColor: theme.palette.table.odd,
                                borderRadius: '10px'
                            }}
                        >
                            {/* <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20 }}>Exceed Value</div> */}
                        </div>
                    </Box>

                    <Box>
                        <Typography variant='h5' sx={{ backgroundColor: theme.palette.background.card, padding: 0.75, width: 'fit-content', borderRadius: '5px', marginBottom: '5px' }}>
                            Intraday Net Value
                        </Typography>
                        <div
                            ref={intradayNetChartRef}
                            style={{
                                position: 'relative',
                                height: (isMobile || isTablet) ? '200px' : '230px',
                                width: '100%',
                                backgroundColor: theme.palette.table.odd,
                                borderRadius: '10px'
                            }}
                        >
                            {/* <div style={{ position: "absolute", top: '2%', left: '2%', zIndex: 20 }}>Intraday Net Value</div> */}
                        </div>
                    </Box>

                </div>

            </Box>



        </div>
    );
};

export default ValueOfTime;
