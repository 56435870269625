import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createChart, LineStyle } from 'lightweight-charts';
// import axios from 'axios';
import { FaSearch, FaTimes, FaSearchMinus } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยายและปิด
import Loading from '../Loading';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../../components/hooks';
import FontLoader from '../../../components/FontLoader';

const API_BASE_URL = "http://172.18.1.81:3100/money_in_out/hist/";

let colorIndex = 0;
const softDarkColors = [
  "#ffcc00", // เหลืองสดใส (Vivid Yellow)
  "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
  "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
  "#ff9966", // ส้มสด (Bright Orange)
  "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
  "#ffffff", // ขาว (White)
  "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
  "#ff66b3", // ชมพูสดใส (Hot Pink)
  "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
  "#3399ff", // ฟ้าสดใส (Sky Blue)
  "#ff9933", // ส้มอ่อนสด (Bright Peach)
  "#ff3399", // ชมพูบานเย็น (Magenta Pink)
  "#33ff66", // เขียวสด (Vivid Green)
  "#ff9966", // พีชสดใส (Peach Orange)
  "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
  "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
  "#66ccff", // ฟ้าสดกลาง (Electric Blue)
  "#33ccff", // ฟ้าสด 
  "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
  "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
];

const getRandomColor = () => {
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
  return color;
};

// ฟังก์ชันปรับเวลาให้ตรงกับเขตเวลาท้องถิ่น
const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // สร้าง Date จาก timestamp
  const timezoneOffset = date.getTimezoneOffset() * 60; // หาค่าชดเชย timezone ในหน่วยวินาที
  return timestamp - timezoneOffset; // ปรับ timestamp ให้ตรงกับ Local Time
};


const ChartSectionWithMultipleLinesPre = ({ title, market, type, selectedDate }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const highlightedSeriesRef = useRef(null); // เก็บซีรีส์ที่ถูกไฮไลต์
  const [tableData, setTableData] = useState([]);
  const [previousData, setPreviousData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null); // เก็บ symbol ของแถวที่ถูกเลือก
  const [isFullscreen, setIsFullscreen] = useState(false); // สถานะโหมดขยาย
  const [highlightedSymbol, setHighlightedSymbol] = useState(null);
  const [tag, setTag] = useState(null); // เพิ่ม state สำหรับ tag
  const [visibleSymbols, setVisibleSymbols] = useState([]); // เก็บชื่อหุ้นที่จะแสดงใน UI
  const priceToSymbolMap = useRef(new Map());
  const [highlightedRow, setHighlightedRow] = useState(null);
  const csrfToken = useSelector((state) => state.csrfToken)
  const historicalDataRef = useRef(null);
  const originalColorsRef = useRef(new Map());



  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();

  // ฟังก์ชันรีเซ็ตไฮไลต์
  const resetHighlight = () => {
    lineSeriesRef.current.forEach((seriesData) => {
      seriesData.series.applyOptions({
        color: getRandomColor(), // ใช้สีสุ่ม
        lineWidth: 2, // รีเซ็ตความหนาเส้น
        visible: true, // ทำให้เส้นอื่นๆ แสดง
      });
    });

    // รีเซ็ตข้อมูลการไฮไลท์
    highlightedSeriesRef.current = null;
    setHighlightedSymbol(null); // รีเซ็ตสถานะสัญลักษณ์ที่ไฮไลต์
  };

  // ฟังก์ชันไฮไลต์เส้นกราฟ
  const highlightLineSeries = (symbol) => {
    const seriesData = lineSeriesRef.current.get(symbol);
    if (!seriesData) {
      console.warn(`Series not found for symbol: ${symbol}`);
      return;
    }

    // ไฮไลต์เส้นที่เลือก
    const series = seriesData.series;
    if (series) {
      // ตั้งค่าความโปร่งใสให้เส้นอื่น ๆ
      lineSeriesRef.current.forEach((data) => {
        if (data.series !== series) {
          data.series.applyOptions({
            color: 'rgba(181, 101, 29, 0.5)', // สีพื้นฐาน
            lineWidth: 2, // ความหนาพื้นฐาน
          });
        }
      });

      series.applyOptions({
        lineWidth: 4,
        color: '#ffffb3',
        visible: true, // ทำให้เส้นที่เลือกแสดง
      });

      highlightedSeriesRef.current = series;
      console.log(`Highlighted series for symbol: ${symbol}`);
    } else {
      console.warn(`Series object not valid for symbol: ${symbol}`);
    }
  };

  const onClickRow = (symbol) => {
    console.log("Row clicked:", symbol);

    if (!historicalDataRef.current[symbol]) {
        console.warn(`No data available for symbol: ${symbol}`);
        return;
    }

    const selectedRank = tableData.find((row) => row.symbol === symbol)?.rank;
    if (!selectedRank) {
        console.warn(`Could not determine rank for symbol: ${symbol}`);
        return;
    }

    const top5Symbols = tableData
        .sort((a, b) => a.rank - b.rank)
        .slice(0, 5)
        .map((row) => row.symbol);

    if (symbol === highlightedSymbol) {
        // 🟢 Click same symbol again → Restore full Top 5 with NEW RANDOM COLORS
        console.log(`Restoring full Top 5 with random colors`);

        lineSeriesRef.current.forEach(({ series }) => {
            chartRef.current.removeSeries(series);
        });
        lineSeriesRef.current.clear();

        top5Symbols.forEach((topSymbol) => {
            const newColor = getRandomColor(); // สุ่มสีใหม่ทุกครั้งที่รีเซ็ต
            const series = chartRef.current.addLineSeries({
                color: newColor,
                lineWidth: 2,
                title: topSymbol,
                priceLineVisible: false,
            });

            series.setData(historicalDataRef.current[topSymbol]);
            lineSeriesRef.current.set(topSymbol, { series, symbol: topSymbol });

            // 🟢 บันทึกสีใหม่ของแต่ละ symbol
            originalColorsRef.current.set(topSymbol, newColor);
        });

        setHighlightedSymbol(null); // ล้างไฮไลต์
        setHighlightedRow(null);
    } else if (top5Symbols.includes(symbol)) {
        // 🟢 Click Top 5 → Highlight selected & dim others
        console.log(`Highlighting ${symbol} within Top 5`);

        lineSeriesRef.current.forEach(({ series }) => {
            chartRef.current.removeSeries(series);
        });
        lineSeriesRef.current.clear();

        top5Symbols.forEach((topSymbol) => {
            const isHighlighted = topSymbol === symbol;
            const originalColor = originalColorsRef.current.get(topSymbol) || getRandomColor();

            const series = chartRef.current.addLineSeries({
                color: isHighlighted ? "#ffffb3" : "rgba(181, 101, 29, 0.5)", // ไฮไลต์เฉพาะเส้นที่กด
                lineWidth: isHighlighted ? 4 : 2,
                title: topSymbol,
                priceLineVisible: false,
            });

            series.setData(historicalDataRef.current[topSymbol]);
            lineSeriesRef.current.set(topSymbol, { series, symbol: topSymbol });
        });

        setHighlightedSymbol(symbol);
        setHighlightedRow(symbol);
    } else if (selectedRank > 5) {
        // 🟢 Click Rank 6-20 → Remove all existing lines & show only clicked one
        console.log(`Displaying ONLY ${symbol} (Rank ${selectedRank})`);

        lineSeriesRef.current.forEach(({ series }) => {
            chartRef.current.removeSeries(series);
        });
        lineSeriesRef.current.clear();

        const series = chartRef.current.addLineSeries({
            color: getRandomColor(),
            lineWidth: 2,
            title: symbol,
            priceLineVisible: false,
        });

        series.setData(historicalDataRef.current[symbol]);
        lineSeriesRef.current.set(symbol, { series, symbol });

        setHighlightedSymbol(symbol);
        setHighlightedRow(symbol);
    }
};

  // ฟังก์ชันจัดการคลิกที่พื้นที่ว่างในกราฟ
  const handleChartClick = (e) => {
    if (!e.target.closest('.rank-table')) {
      if (highlightedSeriesRef.current) {
        console.log("Skip reset on chart click. Highlighted series exists.");
        return; // ถ้ามีเส้นไฮไลต์อยู่ ไม่รีเซ็ต
      }
      resetHighlight();
    }
  };


  // ฟังก์ชันขยายกราฟ
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleExpandClick = () => {
    onExpand(title, market, type, selectedDate);
  };

  // ตั้งค่า Event Listener สำหรับการคลิก
  useEffect(() => {
    document.addEventListener('click', handleChartClick);

    return () => {
      document.removeEventListener('click', handleChartClick);
    };
  }, []);

  // ฟังก์ชันกำหนดสีของแถวในตาราง
  const getRowColor = (symbol, value) => {
    if (symbol === highlightedRow) return '#B8860B'; // สำหรับแถวที่ถูกเลือก
    const previousValue = previousData[symbol];
    if (previousValue === undefined) return 'white';
    if (value > previousValue) return '#e8f5e9'; // สีเขียวสำหรับค่าที่เพิ่มขึ้น
    if (value < previousValue) return '#ffebee'; // สีแดงสำหรับค่าที่ลดลง
    return 'white';
  };

  const getSymbolForPrice = (price) => {
    const matchedData = tableData.find((item) => item.value === price);
    return matchedData ? matchedData.symbol : ''; // คืนชื่อหุ้นถ้าหาพบ
  };


  const fetchHistoricalData = async (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    try {
      setIsLoading(true);

      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/hist/${market}?date=${formattedDate}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      });

      const data = await fetch_response.json();
      console.log(data);

      if (data && data.data && data.data[type]) {
        const dataSource = data.data[type]; // ใช้ type ที่กำหนด
        const historicalData = {};

        Object.keys(dataSource.rank).forEach((time) => {
          if (dataSource.rank[time] && dataSource.value[time]) {
            Object.keys(dataSource.rank[time]).forEach((symbol) => {
              if (!historicalData[symbol]) {
                historicalData[symbol] = [];
              }

              const timestamp = Math.floor(new Date(`${formattedDate}T${time}`).getTime() / 1000);

              // เข้าถึง value โดยตรง
              const value = dataSource.value[time][symbol];

              if (value === undefined || isNaN(value)) {
                console.warn(`Invalid value for ${symbol} at time ${time}:`, value);
                return; // ข้ามหากไม่มีข้อมูล
              }

              // ถ้า type เป็น 'out' ให้ใส่เครื่องหมาย - หน้าค่า value
              const adjustedValue = type === 'out' ? -value / 1000000 : value / 1000000;

              historicalData[symbol].push({
                time: adjustToLocalTime(timestamp),
                rank: dataSource.rank[time][symbol],
                value: adjustedValue,
              });

              priceToSymbolMap.current.set(value, symbol);
            });
          }
        });


 // ✅ Store historicalData in useRef so that onClickRow can access it
 historicalDataRef.current = historicalData;

 // Show only top 5 symbols on initial render
 const top5Symbols = Object.keys(historicalData)
   .map((symbol) => {
     const latestData = historicalData[symbol][historicalData[symbol].length - 1];
     return { symbol, rank: latestData.rank };
   })
   .sort((a, b) => a.rank - b.rank)
   .slice(0, 5);

 top5Symbols.forEach(({ symbol }) => {
   if (!lineSeriesRef.current.has(symbol)) {
     const series = chartRef.current.addLineSeries({
       color: getRandomColor(),
       lineWidth: 2,
       title: symbol,
       priceLineVisible: false,
     });
     lineSeriesRef.current.set(symbol, { series, symbol });
   }

   lineSeriesRef.current.get(symbol).series.setData(
     historicalData[symbol].map((dataPoint) => ({
       time: dataPoint.time,
       value: dataPoint.value,
     }))
   );
 });

 chartRef.current.timeScale().fitContent(); // Fit data to screen
 setTableData(Object.keys(historicalData).map(symbol => ({
   symbol,
   value: historicalData[symbol][historicalData[symbol].length - 1].value,
   rank: historicalData[symbol][historicalData[symbol].length - 1].rank,
 })));
 setIsLoading(false);
      } else {
        console.warn("Data is not available or incomplete.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(`Error fetching data:`, error);
      setIsLoading(false);
    }
  };



  useEffect(() => {
    // สร้างกราฟเมื่อคอมโพเนนต์ถูก mount
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'white', background: { type: 'solid', color: '#575b69' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 590,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
    });

    // 🟢 ทำให้ขอบของ container มีมุมโค้งมน
    chartContainerRef.current.style.borderRadius = '10px';
    chartContainerRef.current.style.height = '595px';
    chartContainerRef.current.style.overflow = 'hidden'; // ป้องกันกราฟล้นขอบ
    chartContainerRef.current.style.outline = '1px solid #ddd'; // เพิ่มเส้นขอบ


    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => price.toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
        borderColor: '#ddd',
        scaleMargins: {
          top: 0.2,
          bottom: 0.2,
        },
      },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
    });
    // เพิ่มการตั้งค่า textColor ของ price scale
    chartRef.current.priceScale('right').applyOptions({
      textColor: '#575b69', // ตั้งสีข้อความของ price scale
    });

    fetchHistoricalData(selectedDate);

    // ทำความสะอาดเมื่อคอมโพเนนต์ unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
  }, [market, type, selectedDate]);

  const scrollToTime = useCallback((time) => {
    if (chartRef.current) {
      // เลื่อนกราฟไปยังช่วงเวลาที่เลือก
      chartRef.current.timeScale().setVisibleRange({
        from: time - 1800,  // 30 นาทีก่อน
        to: time + 1800,    // 30 นาทีหลัง
      });

      // อัปเดตการแสดงผลของชื่อ symbol หลังจากเลื่อนกราฟ
      updatePriceFormatter();

      // ถ้ามีการเลือก symbol ให้ไฮไลท์เส้น
      if (highlightedSymbol) {
        highlightLineSeries(highlightedSymbol); // ไฮไลท์เส้นกราฟที่มีการเลือก symbol
      }
    }
  }, [highlightedSymbol]);



  // ฟังก์ชันเพื่อแปลงเวลาให้เป็น timestamp
  const getTimestampForTime = (hours, minutes) => {
    const date = new Date(selectedDate);
    date.setHours(hours, minutes, 0, 0);
    return Math.floor(date.getTime() / 1000);  // แปลงเป็น timestamp
  };

  const zoomOut = () => {
    if (chartRef.current) {
      const currentRange = chartRef.current.timeScale().getVisibleLogicalRange();
      if (currentRange) {
        const center = (currentRange.from + currentRange.to) / 2;
        const newRange = {
          from: center - (center - currentRange.from) * 3.25,  // ซูมออก
          to: center + (currentRange.to - center) * 1.25,
        };

        // ตรวจสอบว่าไม่เกินขีดสุดของการซูม
        const maxVisibleRange = 2592000; // ขีดสุดของช่วงเวลา (เช่น 30 วัน = 2592000 วินาที)
        if (newRange.to - newRange.from <= maxVisibleRange) {
          chartRef.current.timeScale().setVisibleLogicalRange(newRange);
        }
      }
    }
  };

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const renderTables = (start, end) => {
    // กรองข้อมูลเฉพาะช่วงที่ต้องการ
    const slicedData = tableData
      .sort((a, b) => b.value - a.value) // เรียงข้อมูล
      .slice(start, end); // เลือกเฉพาะช่วงที่กำหนด

    return (
      <table
        className="rank-table"
        style={{
          // marginTop: (isMobile || isPTablet || isTablet) ? '0' : '-130px',
          fontFamily: 'Lato, Sans-serif',
          fontSize: '11.5px',
          borderCollapse: 'collapse',
          width: '100%',
          border: '1px solid #ddd',
          // maxWidth: isMobile || isPTablet ? '30vh' : '50vh',
        }}
      >
        <thead>
          <tr>
            <th style={{ padding: '5px', height: '30px', textAlign: 'center', backgroundColor: '#283593', color: 'white' }}>Rank</th>
            <th style={{ padding: '5px', height: '30px', textAlign: 'center', backgroundColor: '#283593', color: 'white' }}>Symbol</th>
            <th style={{ padding: '5px', height: '30px', textAlign: 'center', backgroundColor: '#283593', color: 'white' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {slicedData.map((row, index) => {
            const isHighlighted = highlightedSymbol === row.symbol; // ตรวจสอบว่าถูกไฮไลต์หรือไม่

            return (
              <tr
                key={index}
                style={{
                  backgroundColor: isHighlighted ? getRowColor(row.symbol, row.value) : "#636a7e", // ไฮไลต์เมื่อเลือก
                  cursor: "pointer",
                  transition: "background-color 0.3s ease",
                  borderBottom: "1px solid #ddd",
                }}
                onClick={() => onClickRow(row.symbol)} // เรียกใช้งานฟังก์ชันคลิก
              >
                <td style={{ padding: "5px", textAlign: "center" }}>{start + index + 1}</td>
                <td style={{ padding: "5px", textAlign: "center" }}>{row.symbol}</td>
                <td style={{ padding: "5px", textAlign: "center" }}>
                  {type === "out" ? `-${Math.abs(row.value).toLocaleString()}` : row.value.toLocaleString()}
                </td>
              </tr>
            );
          })}
        </tbody>

      </table>
    );
  };



  //   return (
  //     <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-70px',fontFamily: 'Arial, Helvetica, sans-serif' }}>
  //       {renderTable(firstTableData, 0)}
  //       {renderTable(secondTableData, 10)}
  //     </div>
  //   );
  // };

  return (
    <div
      className={`chart-section ${isFullscreen ? 'fullscreen' : ''}`}
      style={
        isFullscreen
          ? {
            position: 'absolute',
            top: 0,
            left: 0,
            padding: '20px',
            width: '100vw',
            height: '100vh',
            zIndex: 1000,
            backgroundColor: 'white', // เปลี่ยนสีพื้นหลัง
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // เพิ่มเงา
          }
          : {
            width: '100%',
            // height: '100vh',
            overflowX: 'hidden'
          }
      }
    >
      {/* ส่วนหัวข้อกราฟพร้อมปุ่ม Expand */}
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
        fontFamily: 'Lato, Sans-serif',
      }}>
        <div style={{ display: 'flex', alignItems: 'center', }}>
          <h3 style={{
            margin: 0,
            marginLeft: isFullscreen ? '750px' : '10px',
            textAlign: isFullscreen ? 'center' : 'left',

          }}>{title}</h3>
          {isLoading ? (
            <div style={{ marginTop: '20px' }}>
              <Loading />
            </div>
          ) : tableData.length === 0 ? (
            <span style={{ fontStyle: 'italic', color: '#888', marginLeft: '10px' }}>No Data Available</span>
          ) : null}
        </div>
        {/* <button
          onClick={toggleFullscreen}
          style={{
            padding: '5px 15px',
            marginRight: '550px',
            backgroundColor: 'none',
            color: 'black',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          {isFullscreen ? <FaTimes size={20} /> : <FaSearch size={20} />}
        </button> */}
      </div>
      {/* <div style={{ marginLeft: '20px' }}></div> */}
      <Box className="controller-items" sx={{ marginLeft: '20px', display: 'flex', flexDirection: 'row' }}>
        <button style={{ marginRight: '10px', backgroundColor: '#FFCD77', fontFamily: 'Lato, Sans-serif', border: 'none', padding: '10px', borderRadius: '10px' }} onClick={() => scrollToTime(getTimestampForTime(17, 0))}>
          Start Graph
        </button>
        <button style={{ marginRight: '10px', backgroundColor: '#FFCD77', fontFamily: 'Lato, Sans-serif', border: 'none', padding: '10px', borderRadius: '10px' }} onClick={() => scrollToTime(getTimestampForTime(19, 0))}>
          Half Graph
        </button>
        <button style={{ marginRight: '10px', backgroundColor: '#FFCD77', fontFamily: 'Lato, Sans-serif', border: 'none', padding: '10px', borderRadius: '10px' }} onClick={() => scrollToTime(getTimestampForTime(23, 55))}>
          End Graph
        </button>

        {tag && <span
          style={{
            marginLeft: '400px',
            backgroundColor: '#ebe3d8',
            padding: '12px',
            borderRadius: '10px',
          }}>{tag}</span>}

        <Box sx={{
          marginLeft: {
            xs: '10%',
            sm: '25%',
            md: '30%',
            lg: '40%',
            xl: '57%'
          }
        }}>
          <button onClick={zoomOut} style={{ backgroundColor: '#FFCD77', border: 'none', padding: '10px', borderRadius: '10px' }}>
            <FaSearchMinus size={20} /> {/* ซูมออก */}
          </button>
        </Box>

      </Box >




      {/* ส่วนของกราฟและตาราง */}
      <Box className="chart-and-table" sx={{
        display: 'flex',
        flexDirection: (isMobile || isPTablet) ? 'column' : 'row',
        padding: (isMobile || isTablet) ? '10px 10px 0 10px' : '10px',
        gap: '20px',
        width: '100%',
        height: '80vh',

      }}>
        <div
          ref={chartContainerRef}
          style={{
            // flex: isFullscreen ? 0 : 1,
            marginLeft: isFullscreen ? '550px' : '0', // ระยะห่างจากกราฟหลักเมื่ออยู่ในโหมด fullscreen
            width: isFullscreen ? '1300px' : (isMobile || isPTablet) ? '100%' : '100%', // กราฟขยายเต็มจอในโหมด Fullscreen
            height: isFullscreen ? '60vh' : (isMobile || isPTablet) ? '40vh' : '70vh', // ความสูงของกราฟในโหมด Fullscreen
            marginTop: isFullscreen ? '50px' : '0', // เว้นระยะในโหมด Fullscreen
            transition: 'all 0.3s ease', // การเปลี่ยนแปลงแบบนุ่มนวล
          }}
        ></div>
        {(!isMobile && !isPTablet) && (
          <Box sx={{ // flex: isFullscreen ? 0 : 0.3,
            padding: isFullscreen ? '10px' : '0px',
            width: isFullscreen ? '500px' : '20%',
            maxWidth: '40%',
            // height: isFullscreen ? '500px' : '',
            maxHeight: '50vh',
            marginTop: isFullscreen ? '100px' : '0',
            marginLeft: isFullscreen ? '600px' : '-5px',
          }}>
            {renderTables(0, 20)}
          </Box>
        )}

        {(isMobile || isPTablet) && (<>
          <Box sx={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', gap: '10px',
            maxHeight: isMobile ? '40vh' : '50vh',
          }}>
            <Box sx={{
              width: '40%', display: 'flex', flexDirection: 'column',
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}>
              {renderTables(0, 10)}
            </Box>
            <Box sx={{
              width: '40%', display: 'flex', flexDirection: 'column',
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}>
              {renderTables(10, 20)}
            </Box>
          </Box>

        </>
        )}

      </Box>


    </div>
  );

};

export default ChartSectionWithMultipleLinesPre;
