/**
 * Example: C:\project\rest_api\client\src\scenes\Flip\FlipPage.jsx
 */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createChart } from 'lightweight-charts';
import { FaSearch  } from 'react-icons/fa'; // import plus icon

// --------------------- Utility Parsers ---------------------
function datetimeToTimestamp(datetimeStr) {
  if (!datetimeStr || typeof datetimeStr !== 'string') return null;
  // e.g. "2025-01-10 13:45:00" => "2025-01-10T13:45:00Z"
  const dt = new Date(datetimeStr.replace(' ', 'T') + 'Z');
  if (isNaN(dt.getTime())) return null;
  return Math.floor(dt.getTime() / 1000);
}

/** Accumulates daily:
 *   ( negative_to_positive - positive_to_negative )
 *   resets each new date
 */
function convertApiDataToSeries(data) {
  // Sort by ascending time
  const sortedData = [...data]
    .filter(d => d.datetime)
    .sort((a, b) => {
      const ta = datetimeToTimestamp(a.datetime);
      const tb = datetimeToTimestamp(b.datetime);
      return (ta ?? 0) - (tb ?? 0);
    });

  let accumulatedValue = 0;
  let currentDay = null;
  const resultSeries = [];
  const seenTimestamps = new Set();

  for (const d of sortedData) {
    const timestamp = datetimeToTimestamp(d.datetime);
    if (timestamp === null) continue;

    const dateObj = new Date(d.datetime.replace(' ', 'T') + 'Z');
    const day = dateObj.toISOString().split('T')[0];
    if (day !== currentDay) {
      accumulatedValue = 0;
      currentDay = day;
    }

    const val = (d.negative_to_positive || 0) - (d.positive_to_negative || 0);
    accumulatedValue += val;

    if (!seenTimestamps.has(timestamp)) {
      resultSeries.push({ time: timestamp, value: accumulatedValue });
      seenTimestamps.add(timestamp);
    }
  }

  return resultSeries;
}

/** Quick check for strictly ascending .time */
function validateSeriesData(series) {
  for (let i = 1; i < series.length; i++) {
    if (series[i].time <= series[i - 1].time) {
      console.error("Data not strictly ascending:", series[i], series[i - 1]);
      return false;
    }
  }
  return true;
}

// --------------------- Plain fetch helpers (NO Hooks) ---------------------
async function fetchHistoryData(market, csrfToken) {
  // For example, 2 requests:
  const baseApiUrl = window.config.base_api_url;
  const t1HistoryUrl = `${baseApiUrl}/t1/history/${market}`;
  const intradayHistoryUrl = `${baseApiUrl}/intraday/history/${market}`;

  const [t1Res, intradayRes] = await Promise.all([
    fetch(t1HistoryUrl, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
    }),
    fetch(intradayHistoryUrl, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
    }),
  ]);
  if (!t1Res.ok || !intradayRes.ok) {
    throw new Error(`Failed to fetch history data for market=${market}`);
  }

  const [t1Json, intradayJson] = await Promise.all([t1Res.json(), intradayRes.json()]);
  return {
    t1History: t1Json.data || [],
    intradayHistory: intradayJson.data || [],
  };
}

async function fetchRealtimeData(market, csrfToken) {
  const baseApiUrl = window.config.base_api_url;
  const t1RealtimeUrl = `${baseApiUrl}/t1/realtime/${market}`;
  const intradayRealtimeUrl = `${baseApiUrl}/intraday/realtime/${market}`;

  const [t1Res, intradayRes] = await Promise.all([
    fetch(t1RealtimeUrl, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
    }),
    fetch(intradayRealtimeUrl, {
      headers: {
        'Content-Type': 'application/json',
        'xsrf-token': csrfToken,
      },
    }),
  ]);
  if (!t1Res.ok || !intradayRes.ok) {
    throw new Error(`Failed to fetch realtime data for market=${market}`);
  }
  const [t1Json, intradayJson] = await Promise.all([t1Res.json(), intradayRes.json()]);
  return {
    t1Realtime: t1Json.data || [],
    intradayRealtime: intradayJson.data || [],
  };
}

// --------------------- ChartSection component ---------------------
const ChartSection = ({ title, market }) => {
  const csrfToken = useSelector(state => state.csrfToken);
  const initialLoadRef = useRef(false);

  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);

  const t1SeriesRef = useRef(null);
  const intradaySeriesRef = useRef(null);
  const baselineSeriesRef = useRef(null);

  // States for data
  const [t1HistoryData, setT1HistoryData] = useState([]);
  const [intradayHistoryData, setIntradayHistoryData] = useState([]);
  const [t1RealtimeData, setT1RealtimeData] = useState([]);
  const [intradayRealtimeData, setIntradayRealtimeData] = useState([]);

  // Toggle for lines
  const [showT1, setShowT1] = useState(true);
  const [showIntraday, setShowIntraday] = useState(true);

  const [allTimeRange, setAllTimeRange] = useState({ from: null, to: null });

  const firstLoadRef = useRef(true);
  
  // 1) On mount => create chart (your current logic)
  useEffect(() => {
    if (!chartContainerRef.current) return;
    chartContainerRef.current.style.outline = "2px solid #fff";
    chartContainerRef.current.style.boxSizing = "border-box";
    chartContainerRef.current.style.borderRadius = "10px";
    chartContainerRef.current.style.overflow = "hidden";

    const chart = createChart(chartContainerRef.current, {
      layout: { textColor: '#FFFFFF', background: { color: '#575b69' } },
      timeScale: { timeVisible: true, rightOffset: 20 },
      shiftVisibleRangeOnNewBar: false,
      height: 300,
      width: chartContainerRef.current.offsetWidth || 600,
      localization: {
        priceFormatter: (price) => price.toLocaleString('en-US', { maximumFractionDigits: 0 }),
      },
      priceScale: { mode: 0 },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
    });
    chartRef.current = chart;

    // Baseline line series => 0 line
    baselineSeriesRef.current = chart.addLineSeries({
      color: '#df82ff',
      priceLineVisible: false,
      lineWidth: 1,
      lineStyle: 0, // dotted or something
      lastValueVisible: false,
      priceLineVisible: false,
    });

    // T1 line
    t1SeriesRef.current = chart.addLineSeries({
      color: '#5efee8',
      priceLineVisible: false,
      lineWidth: 2,
      title: 'Flip T-1 -> T',
    });

    // Intraday line
    intradaySeriesRef.current = chart.addLineSeries({
      color: '#ffdf4f',
      priceLineVisible: false,
      lineWidth: 2,
      title: 'Intraday',
    });

    // Resize observer
    const ro = new ResizeObserver(() => {
      if (chartRef.current && chartContainerRef.current) {
        chartRef.current.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
        });
      }
    });
    ro.observe(chartContainerRef.current);

    return () => {
      // clean-up
      chart.remove();
    };
  }, []);

  // 2) On mount => fetch history + realtime once, then poll
  useEffect(() => {
    let isMounted = true;

    async function initLoad() {
      try {
        const { t1History, intradayHistory } = await fetchHistoryData(market, csrfToken);
        if (isMounted) {
          setT1HistoryData(t1History);
          setIntradayHistoryData(intradayHistory);
        }

        const { t1Realtime, intradayRealtime } = await fetchRealtimeData(market, csrfToken);
        if (isMounted) {
          setT1RealtimeData(t1Realtime);
          setIntradayRealtimeData(intradayRealtime);
        }
      } catch (err) {
        console.error('initLoad error:', err);
      }
    }
    initLoad();

    // Poll realtime
    const intervalId = setInterval(async () => {
      try {
        const { t1Realtime, intradayRealtime } = await fetchRealtimeData(market, csrfToken);
        if (isMounted) {
          setT1RealtimeData(t1Realtime);
          setIntradayRealtimeData(intradayRealtime);
        }
      } catch (err) {
        console.error('realtime polling error:', err);
      }
    }, 10000);

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, [market, csrfToken]);

  // 3) Each time data changes => re-set chart lines
  useEffect(() => {
    if (!chartRef.current || !t1SeriesRef.current || !intradaySeriesRef.current || !baselineSeriesRef.current) {
      return;
    }

    // 3a) combine + convert => T1
    const t1Data = convertApiDataToSeries([...t1HistoryData, ...t1RealtimeData]);
    // 3b) combine + convert => Intraday
    const intradayData = convertApiDataToSeries([...intradayHistoryData, ...intradayRealtimeData]);

    t1Data.sort((a, b) => a.time - b.time);
    intradayData.sort((a, b) => a.time - b.time);

    // Validate
    if (!validateSeriesData(t1Data) || !validateSeriesData(intradayData)) {
      console.warn('Invalid data => skipping chart update');
      return;
    }

    // 3c) setData
    t1SeriesRef.current.setData(t1Data);
    intradaySeriesRef.current.setData(intradayData);

    // baseline => if intraday has data => from first => last
    if (intradayData.length > 0) {
      baselineSeriesRef.current.setData([
        { time: intradayData[0].time, value: 0 },
        { time: intradayData[intradayData.length - 1].time, value: 0 },
      ]);
    }

    // // ** ขยายช่วงเวลาเต็มช่วงของข้อมูล **
    // const allTimes = [...t1Data, ...intradayData].map(d => d.time);

    // if (allTimes.length > 0) {
    //     const minTime = Math.min(...allTimes);
    //     const maxTime = Math.max(...allTimes);
    //     setAllTimeRange({ from: minTime, to: maxTime });
    
    //     if (chartRef.current) {
    //         // chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
    //         // chartRef.current.timeScale().applyOptions({ rightOffset: 180 });
    //         // chartRef.current.timeScale().fitContent();
    //         // chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
    //         chartRef.current.timeScale().applyOptions({ rightOffset: 20 });
    //         // chartRef.current.timeScale().fitContent();
    //         initialLoadRef.current = true;
    //     }
    // }
  }, [t1HistoryData, t1RealtimeData, intradayHistoryData, intradayRealtimeData]);

  // 4) Toggle T1
  useEffect(() => {
    if (t1SeriesRef.current) {
      t1SeriesRef.current.applyOptions({ visible: showT1 });
    }
  }, [showT1]);

  // 5) Toggle Intraday
  useEffect(() => {
    if (intradaySeriesRef.current) {
      intradaySeriesRef.current.applyOptions({ visible: showIntraday });
    }
  }, [showIntraday]);

  return (
    <div style={{ marginBottom: '0px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between', // Title on left, icon/buttons on right
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        {/* Left side: Title */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2 style={{ margin: 0 }}>{title}</h2>
        </div>

        {/* Right side: Icon + Toggle Buttons */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px', // Space between icon and buttons
          }}
        >
          <FaSearch
            onClick={() => {
              window.open(
                `/fullscreenflip?market=${market}&title=${encodeURIComponent(title)}`,
                '_blank'
              );
            }}
            style={{ cursor: 'pointer', fontSize: '20px' }}
            title="Open fullscreen"
          />

          <button
            onClick={() => setShowT1(prev => !prev)}
            style={{
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: 'bold',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: showT1 ? '#1976d2' : '#e0e0e0',
              color: showT1 ? 'white' : 'black',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, transform 0.2s',
            }}
            onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
            onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
          >
            {showT1 ? 'T1 Line' : 'T1 Line'}
          </button>

          <button
            onClick={() => setShowIntraday(prev => !prev)}
            style={{
              padding: '8px 16px',
              fontSize: '14px',
              fontWeight: 'bold',
              border: 'none',
              borderRadius: '5px',
              backgroundColor: showIntraday ? '#1976d2' : '#e0e0e0',
              color: showIntraday ? 'white' : 'black',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease, transform 0.2s',
            }}
            onMouseOver={(e) => (e.target.style.transform = 'scale(1.05)')}
            onMouseOut={(e) => (e.target.style.transform = 'scale(1)')}
          >
            {showIntraday ? 'Intraday Line' : 'Intraday Line'}
          </button>
        </div>
      </div>


      <div style={{ width: '100%', height: '200px', }} ref={chartContainerRef} />
    </div>
  );
};

// Page that renders multiple charts
const FlipPage = () => {
  return (
    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', padding: '30px', marginTop: '-15px', }}>
      <ChartSection title="SET" market="SET" />
      <ChartSection title="MAI" market="MAI" />
      <ChartSection title="WARRANT" market="WARRANT" />
    </div>
  );
};

export default FlipPage;
