import { Box, Typography, useTheme, useMediaQuery, createTheme } from "@mui/material";
import Form from "./form1";

const LoginPage = () => {
    const theme = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    return (
        <html>
            <head>

            </head>
            <Form />
        </html>
    );
};

export default LoginPage;







