import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from 'react-redux'; // if you need the token from Redux
import { Box, Typography, Grid, useTheme, Container, Paper, Select, MenuItem, InputLabel, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

// Color utilities
let colorIndex = 0;
function getRandomColor() {
  // const letters = '0123456789ABCDEF';
  // let color = '#';
  // for (let i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * 16)];
  // }
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length;
  return color;
}

const softDarkColors = [
  // "#ffee33", "#66d9ff", "#66ff99", "#ffcc80", "#ffd966",
  // "#ffffff", "#ffffb3", "#ffddff", "#b3ffe6", "#99ccff",
  // "#ffeeaa", "#ffb3e6", "#b3ffb3", "#ffddaa", "#b3ffff",
  // "#f0ffa6", "#c2e6ff", "#e6c2ff", "#eaffb3", "#c2ffea",
  // "#ffff80", "#e6ffe6", "#cce6ff", "#ffeb99", "#e6f7ff",
  // "#d9ffb3", "#ccffcc", "#f5e6ff", "#e6e6ff", "#d9f7ff",
  // "#b3f0ff", "#ffdbac", "#c2ffd2", "#f0f5ff", "#d2f7ff",
  // "#e6ffd9", "#b3ffe0", "#b3f7ff", "#d9e6ff", "#e6d9ff",
  // "#f2ffcc", "#c2ffe6", "#f0d9ff", "#e6ccff", "#ccf2ff",
  // "#e6ffff", "#e0ffcc", "#ffffd2", "#f2ffe6", "#ccffff"


  // "#ffee33", "#66d9ff", "#66ff99", "#ffcc80", "#ffd966",
  // "#ffffff", "#ffffb3", "#ffddff", "#b3ffe6", "#99ccff",
  // "#ffeeaa", "#ffb3e6", "#b3ffb3", "#ffddaa", "#b3ffff",
  // "#f0ffa6", "#c2e6ff", "#e6c2ff", "#eaffb3", "#c2ffea"

  '#FF3B30', '#FF9500', '#FFCC00', '#34C759', '#32D74B',
  '#5AC8FA', '#007AFF', '#AF52DE', '#BF5AF2', '#FF2D55',
  '#FF6B6B', '#FF8200', '#FF5E3A', '#FFB300', '#66D4CF',
  '#FFC300', '#50C878', '#5856D6', '#8E44AD', '#1ABC9C',
];

// const earthToneColor = '#D2B48C';
const earthToneColor = 'rgba(181, 101, 29, 0.5)';
// const getOppositeColor = () => '#0d0082';
const getOppositeColor = () => '#ffffb3';

// Convert "YYYY-MM-DD" => numeric timestamp
function dateToTimestamp(dateStr) {
  return Math.floor(new Date(dateStr + 'T00:00:00').getTime() / 1000);
}

export default function YTDPage() {
  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);

  const theme = useTheme();
  const colors = theme.palette.mode;

  // NEW: year selection
  const [selectedYear, setSelectedYear] = useState('2025');

  // Historical & realtime data
  const [histData, setHistData] = useState([]);
  const [rtData, setRtData] = useState([]);
  const [combinedData, setCombinedData] = useState([]); // final merged data

  // For chart lines
  const lineSeriesRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());

  // For the table
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [tableData, setTableData] = useState([]);

  // If your Redux store has an xsrf token
  const csrfToken = useSelector((state) => state.csrfToken);

  // ------------------------------------------------------------------
  // 1) Create Chart On Mount
  // ------------------------------------------------------------------
  useEffect(() => {
    const chart = createChart(chartContainerRef.current, {
      // layout: { background: { type: 'solid', color: 'white' } },
      layout: {
        type: 'solid',
        background: { color: theme.palette.table.odd },
        textColor: theme.palette.text.secondary,
      },
      grid: {
        vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
        horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
      },
      timeScale: {
        timeVisible: true,
        rightOffset: 2,
      },
      height: 600,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
      localization: {
        // Show Y-values as percentages
        priceFormatter: (val) => (val * 100).toFixed(2),
      },
      priceScale: {
        mode: 0,
      },
    });
    chartContainerRef.current.style.borderRadius = '10px';
    chartContainerRef.current.style.overflow = isMobile ? 'normal' : 'hidden';
    chartRef.current = chart;

    const resizeObserver = new ResizeObserver(() => {
      if (chartContainerRef.current) {
        chart.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
        });
      }
    });
    resizeObserver.observe(chartContainerRef.current);

    return () => {
      if (chartRef.current) {
        chartRef.current.remove();
      }
      resizeObserver.disconnect();
    };
  }, []);

  // ------------------------------------------------------------------
  // 2) Fetch data for the selected year (with fetch & XSRF token)
  // ------------------------------------------------------------------
  useEffect(() => {
    async function fetchDataForYear(year) {
      try {
        // If year != 2025 => single fetch to /api/ytd/:year
        if (year !== '2025') {
          const res = await fetch(
            `${window.config.base_api_url}/api/ytd/${year}`,
            {
              headers: {
                'Content-Type': 'application/json',
                'xsrf-token': csrfToken,
              },
            }
          );
          if (!res.ok) {
            throw new Error(`Failed to fetch /api/ytd/${year}, status=${res.status}`);
          }
          const data = await res.json();
          setHistData(data);
          setRtData([]); // no realtime for older years

        } else {
          // year=2025 => do 2 fetches: /api/ytd + /api/ytd/realtime
          const [histRes, rtRes] = await Promise.all([
            fetch(`${window.config.base_api_url}/api/ytd`, {
              headers: {
                'Content-Type': 'application/json',
                'xsrf-token': csrfToken,
              },
            }),
            fetch(`${window.config.base_api_url}/api/ytd/realtime`, {
              headers: {
                'Content-Type': 'application/json',
                'xsrf-token': csrfToken,
              },
            }),
          ]);
          if (!histRes.ok) {
            throw new Error(`Failed to fetch /api/ytd, status=${histRes.status}`);
          }
          if (!rtRes.ok) {
            throw new Error(`Failed to fetch /api/ytd/realtime, status=${rtRes.status}`);
          }
          const [histJson, rtJson] = await Promise.all([histRes.json(), rtRes.json()]);
          setHistData(histJson);
          setRtData(rtJson);
        }

      } catch (err) {
        console.error('Failed to fetch data for year=', year, err);
        setHistData([]);
        setRtData([]);
      }
    }

    fetchDataForYear(selectedYear);
  }, [selectedYear, csrfToken]);

  // ------------------------------------------------------------------
  // 3) Combine histData + rtData (only if year=2025)
  // ------------------------------------------------------------------
  useEffect(() => {
    if (selectedYear !== '2025') {
      setCombinedData(histData);
      return;
    }
    // year=2025 => merge
    if (!histData.length && !rtData.length) {
      setCombinedData([]);
      return;
    }
    // Merge
    const merged = [...histData];
    for (const row of rtData) {
      merged.push({
        date: row.date,
        symbol: row.symbol,
        percentChange: row.percentChange,
        cumulative: row.cumulative || null,
      });
    }
    setCombinedData(merged);
  }, [histData, rtData, selectedYear]);

  // ------------------------------------------------------------------
  // 4) Build Chart From combinedData
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!combinedData.length || !chartRef.current) {
      // Clear table + lines if no data
      setTableData([]);
      lineSeriesRef.current.forEach((s) => chartRef.current.removeSeries(s));
      lineSeriesRef.current.clear();
      originalColorsRef.current.clear();
      seriesDataRef.current.clear();
      return;
    }

    // Group by date => date => array of { symbol, percentChange, cumulative }
    const byDate = {};
    for (const row of combinedData) {
      const { date, symbol, percentChange, cumulative } = row;
      if (!byDate[date]) {
        byDate[date] = [];
      }
      byDate[date].push({ symbol, percentChange, cumulative });
    }

    const allDates = Object.keys(byDate).sort();
    if (!allDates.length) {
      setTableData([]);
      return;
    }

    // find the newest date
    const latestDate = allDates[allDates.length - 1];
    let latestArr = byDate[latestDate];

    // If year=2025 => filter cumulative >= 1e6 (unless cumulative===null => real-time => keep)
    if (selectedYear === '2025') {
      latestArr = latestArr.filter(item => {
        if (item.cumulative == null) return true;
        return item.cumulative >= 1_000_000;
      });
    }

    // Sort by %desc => top 50
    const sorted = [...latestArr].sort((a, b) => b.percentChange - a.percentChange);
    const topSymbols = sorted.slice(0, 50).map(x => x.symbol);
    const top5Symbols = sorted.slice(0, 5).map(x => x.symbol);
    // console.log('sorted = ',sorted.length)

    // table data
    const finalTable = sorted.slice(0, 50).map((item, idx) => ({
      rank: idx + 1,
      symbol: item.symbol,
      percentChange: item.percentChange,
    }));
    setTableData(finalTable);


    // buildChartLines(top5Symbols, byDate, allDates);

    // Build lines => topSymbols
    // const symbolSeriesData = new Map();
    // for (const d of allDates) {
    //   const dayRows = byDate[d];
    //   const dayMap = {};
    //   for (const row of dayRows) {
    //     dayMap[row.symbol] = row;
    //   }
    //   for (const sym of topSymbols) {
    //     if (dayMap[sym]) {
    //       if (!symbolSeriesData.has(sym)) {
    //         symbolSeriesData.set(sym, []);
    //       }
    //       symbolSeriesData.get(sym).push({
    //         time: dateToTimestamp(d),
    //         value: dayMap[sym].percentChange,
    //       });
    //     }
    //   }
    // }

    const symbolSeriesData = new Map();
    for (const d of allDates) {
      const dayRows = byDate[d];
      const dayMap = {};
      for (const row of dayRows) {
        dayMap[row.symbol] = row;
      }
      for (const sym of topSymbols) {  // เตรียมข้อมูลสำหรับทุกตัว
        if (dayMap[sym]) {
          if (!symbolSeriesData.has(sym)) {
            symbolSeriesData.set(sym, []);
          }
          symbolSeriesData.get(sym).push({
            time: dateToTimestamp(d),
            value: dayMap[sym].percentChange,
          });
        }
      }
    }

    // Clear old lines
    lineSeriesRef.current.forEach((series) => {
      chartRef.current.removeSeries(series);
    });
    lineSeriesRef.current.clear();
    originalColorsRef.current.clear();
    seriesDataRef.current.clear();

    // Add new lines
    topSymbols.forEach((sym, rank) => {
      if (rank >= 10) { }
      const dataArr = symbolSeriesData.get(sym) || [];
      if (!dataArr.length) return;
      // console.log('data is', dataArr.length);

      // top5Symbols.forEach((sym) => {  // <-- เปลี่ยนจาก topSymbols เป็น top5Symbols
      //   const dataArr = symbolSeriesData.get(sym) || [];
      //   if (!dataArr.length) return;





      const color = getRandomColor();
      // const color = softDarkColors;


      const series = chartRef.current.addLineSeries({
        color: color,
        lineWidth: 2,
        lastValueVisible: true,
        priceLineVisible: false,
        title: sym,
        visible: rank < 10 ? true : false
      });




      series.setData(dataArr);

      lineSeriesRef.current.set(sym, series);
      originalColorsRef.current.set(sym, color);
      seriesDataRef.current.set(sym, dataArr);


    });

    // fit
    chartRef.current.timeScale().fitContent();
  }, [combinedData, selectedYear]);

  // ------------------------------------------------------------------
  // 5) Highlight line on table row click
  // ------------------------------------------------------------------

  // const handleRowClick = (symbol, rank) => {
  //   if (!symbol) return;

  //   if (selectedSymbol === symbol) {
  //     // unselect
  //     setSelectedSymbol(null);
  //     lineSeriesRef.current.forEach((series, s2) => {
  //       const origColor = originalColorsRef.current.get(s2);
  //       series.applyOptions({ color: origColor, lineWidth: 2,});
  //     });
  //   } else {
  //     setSelectedSymbol(symbol);

  //     // fade others
  //     lineSeriesRef.current.forEach((series) => {
  //       series.applyOptions({ color: earthToneColor, lineWidth: 2});
  //     });
  //     // remove & re-add
  //     const target = lineSeriesRef.current.get(symbol);
  //     if (target) {
  //       chartRef.current.removeSeries(target);
  //       lineSeriesRef.current.delete(symbol);

  //       const dataArr = seriesDataRef.current.get(symbol) || [];
  //       const highlightSeries = chartRef.current.addLineSeries({
  //         color: getOppositeColor(),
  //         lineWidth: 4,
  //         lastValueVisible: true,
  //         priceLineVisible: false,
  //         title: symbol,
  //       });

  //       highlightSeries.setData(dataArr);
  //       lineSeriesRef.current.set(symbol, highlightSeries);
  //     }
  //   }
  // };

  const handleRowClick = (symbol, rank) => {
    if (!symbol) return;
  
    if (selectedSymbol === symbol) {
      // Unselect symbol
      setSelectedSymbol(null);
  
      lineSeriesRef.current.forEach((series, s2) => {
        const origColor = originalColorsRef.current.get(s2);
        series.applyOptions({ color: origColor, lineWidth: 2 });
      });
    } else {
      setSelectedSymbol(symbol);
  
      if (rank > 10) {
        // ถ้า rank > 10 ให้แสดงแค่เส้นของ symbol นั้นๆ
        lineSeriesRef.current.forEach((series, key) => {
          if (key !== symbol) {
            chartRef.current.removeSeries(series);
            lineSeriesRef.current.delete(key);
          }
        });
      } else {
        // ถ้า rank <= 10 ให้ fade เส้นอื่น
        lineSeriesRef.current.forEach((series) => {
          series.applyOptions({ color: earthToneColor, lineWidth: 2 });
        });
      }
  
      // Remove & re-add selected series
      const target = lineSeriesRef.current.get(symbol);
      if (target) {
        chartRef.current.removeSeries(target);
        lineSeriesRef.current.delete(symbol);
      }
  
      const dataArr = seriesDataRef.current.get(symbol) || [];
      const highlightSeries = chartRef.current.addLineSeries({
        color: getOppositeColor(),
        lineWidth: 4,
        lastValueVisible: true,
        priceLineVisible: false,
        title: symbol,
      });
  
      highlightSeries.setData(dataArr);
      lineSeriesRef.current.set(symbol, highlightSeries);
    }
  };
  


  // const handleRowClick = (symbol, rank) => {
  //   if (!symbol) return;

  //   if (selectedSymbol === symbol) {
  //     // Unselect
  //     setSelectedSymbol(null);
  //     lineSeriesRef.current.forEach((series, s2) => {
  //       const origColor = originalColorsRef.current.get(s2);
  //       series.applyOptions({ color: origColor, lineWidth: 2 });
  //     });
  //   } else {
  //     setSelectedSymbol(symbol);

  //     // Fade เส้นอื่นๆ ให้เป็นสีจาง
  //     lineSeriesRef.current.forEach((series) => {
  //       series.applyOptions({ color: earthToneColor, lineWidth: 2 });
  //     });

  //     // Highlight เส้นที่เลือก
  //     const target = lineSeriesRef.current.get(symbol);
  //     if (target) {
  //       chartRef.current.removeSeries(target);
  //       lineSeriesRef.current.delete(symbol);

  //       const dataArr = seriesDataRef.current.get(symbol) || [];
  //       const highlightSeries = chartRef.current.addLineSeries({
  //         color: getOppositeColor(),
  //         lineWidth: 4,
  //         lastValueVisible: true,
  //         priceLineVisible: false,
  //         title: symbol,
  //       });

  //       highlightSeries.setData(dataArr);
  //       lineSeriesRef.current.set(symbol, highlightSeries);
  //     }

  //     // ตรวจสอบจำนวนเส้นไม่ให้เกิน 5
  //     if (lineSeriesRef.current.size > 5) {
  //       // ลบเส้นแรกที่ไม่ใช่เส้น Highlight
  //       for (const [key, series] of lineSeriesRef.current.entries()) {
  //         if (key !== symbol) {
  //           chartRef.current.removeSeries(series);
  //           lineSeriesRef.current.delete(key);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // };



  // const handleRowClick = (symbol, rank) => {
  //   if (!symbol) return;

  //   if (selectedSymbol === symbol) {
  //     // Unselect: คืนค่าเดิมให้ทุกเส้น
  //     setSelectedSymbol(null);
  //     lineSeriesRef.current.forEach((series, s2) => {
  //       const origColor = originalColorsRef.current.get(s2);
  //       series.applyOptions({ color: origColor, lineWidth: 2, visible: rank <= 5 ? true : false });
  //     });
  //   } else {
  //     setSelectedSymbol(symbol);

  //     // Fade สีของเส้นอื่น
  //     lineSeriesRef.current.forEach((series, s2) => {
  //       series.applyOptions({ color: earthToneColor, lineWidth: 2 });
  //     });

  //     // นำ Series ออกและเพิ่มเข้าใหม่เพื่อให้แสดงอยู่ด้านหน้า
  //     const target = lineSeriesRef.current.get(symbol);
  //     if (target) {
  //       chartRef.current.removeSeries(target);
  //       lineSeriesRef.current.delete(symbol);

  //       const dataArr = seriesDataRef.current.get(symbol) || [];
  //       const highlightSeries = chartRef.current.addLineSeries({
  //         color: getOppositeColor(),
  //         lineWidth: 4,
  //         lastValueVisible: true,
  //         priceLineVisible: false,
  //         title: symbol,
  //       });
  //       highlightSeries.setData(dataArr);
  //       lineSeriesRef.current.set(symbol, highlightSeries);
  //     }

  //     // ✅ กรณีที่ rank > 5: แสดงอันดับ 1-5 + เส้นที่ถูกเลือก
  //     if (rank > 5) {
  //       lineSeriesRef.current.forEach((series, s2) => {
  //         const row = tableData.find(r => r.symbol === s2);
  //         if (s2 === symbol) {
  //           series.applyOptions({ visible: true, });
  //         } else {
  //           series.applyOptions({ color: earthToneColor, lineWidth: 2, visible: false });
  //         }
  //       });
  //     } else {
  //       // ✅ แสดงแค่กลุ่มของอันดับ 1-5 และลดความเด่นของเส้นที่ไม่ได้เลือก
  //       lineSeriesRef.current.forEach((series, s2) => {
  //         const row = tableData.find(r => r.symbol === s2);
  //         if (row && row.rank <= 5) {
  //           if (s2 === symbol) {
  //             series.applyOptions({ visible: true });
  //           } else {
  //             series.applyOptions({ color: earthToneColor, lineWidth: 2, visible: true });
  //           }
  //         } else {
  //           // ซ่อนเส้นที่ไม่อยู่ในกลุ่ม 1-5
  //           series.applyOptions({ visible: false });
  //         }
  //       });
  //     }
  //   }
  // };










  // ------------------------------------------------------------------
  // 6) Render table
  // ------------------------------------------------------------------
  function renderTable(start = 0, end = tableData.length) {
    const tableContainerRef = useRef(null);
    const [highlightIndex, setHighlightIndex] = useState(-1); // เก็บ index ของแถวที่ถูกเลือกด้วยคีย์บอร์ด

    const onClickTable = (symbol) => {
      setSelectedSymbol(symbol); // อัปเดต symbol ที่ถูกเลือก
    };

    useEffect(() => {
      const handleKeyDown = (event) => {
        if (!tableData.length) return;

        setHighlightIndex((prevIndex) => {
          let newIndex = prevIndex;

          if (event.key === "ArrowDown") {
            newIndex = prevIndex + 1 < tableData.length ? prevIndex + 1 : prevIndex;
          } else if (event.key === "ArrowUp") {
            newIndex = prevIndex - 1 >= 0 ? prevIndex - 1 : -1;
          } else if (event.key === "Escape") {
            setSelectedSymbol(null);
            return -1; // Unselect everything
          }

          if (newIndex !== -1) {
            handleRowClick(tableData[newIndex].symbol);
          }

          adjustScrollPosition(newIndex);
          return newIndex;
        });
      };

      const adjustScrollPosition = (index) => {
        if (!tableContainerRef.current) return;
        const tableDiv = tableContainerRef.current;
        const rows = tableDiv.querySelectorAll("tbody tr");

        if (index >= 0 && index < rows.length) {
          const row = rows[index];
          const containerHeight = tableDiv.clientHeight;
          const rowTop = row.offsetTop - tableDiv.scrollTop;
          const rowHeight = row.clientHeight;

          const bufferRows = 5; // Scroll when reaching row 5
          const bufferHeight = bufferRows * rowHeight;

          if (rowTop + rowHeight > containerHeight - bufferHeight) {
            tableDiv.scrollTop += rowHeight;
          } else if (rowTop < bufferHeight) {
            tableDiv.scrollTop -= rowHeight;
          }
        }
      };

      const tableDiv = tableContainerRef.current;
      if (tableDiv) {
        tableDiv.addEventListener('keydown', handleKeyDown);
      }

      return () => {
        if (tableDiv) {
          tableDiv.removeEventListener('keydown', handleKeyDown);
        }
      };
    }, [tableData]);



    return (

      <Box
        ref={tableContainerRef}
        tabIndex={0}
        sx={{
          height: '600px',
          // overflowY: 'auto',
          borderRadius: '10px',
          // '::-webkit-scrollbar': {
          //   width: '8px',
          // },
          // '::-webkit-scrollbar-thumb': {
          //   backgroundColor: '#888',
          //   borderRadius: '4px',
          // },
          // '::-webkit-scrollbar-thumb:hover': {
          //   backgroundColor: '#555',
          // },
        }}
        onClick={() => tableContainerRef.current?.focus()}
      >
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            fontSize: '14px',
            // borderRadius: '10px',
          }}
        >
          <thead style={{ position: 'sticky', top: 0, backgroundColor: theme.palette.background.container1 }}>
            <tr style={{ backgroundColor: theme.palette.table.thead }}>
              <th style={{ textAlign: 'center', padding: '4px' }}>Rank</th>
              <th style={{ textAlign: 'center', padding: '4px' }}>Symbol</th>
              <th style={{ textAlign: 'center', padding: '4px' }}>% Change</th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: theme.palette.background.container }}>
            {tableData.slice(start, end).map((row, idx) => {
              const isSelected = row.symbol === selectedSymbol || highlightIndex === idx;
              const isHighlighted = highlightIndex === idx;
              return (
                <tr
                  key={idx}
                  style={{
                    backgroundColor: isSelected
                      ? '#ffe0cc' // สีเมื่อเลือกด้วย Enter หรือ Mouse Click
                      : isHighlighted
                        ? '#fff3eb' // สีเมื่อใช้ Arrow Key
                        : idx % 2 === 0
                          ? theme.palette.table.odd
                          : theme.palette.table.even,
                    cursor: 'pointer',
                    fontWeight: isSelected ? 'bold' : 'normal',
                    color: isSelected ? 'black' : theme.palette.text.primary,
                  }}
                  onClick={() => {
                    //   if (selectedSymbol === row.symbol) {
                    //     setHighlightIndex(-1);
                    //     setSelectedSymbol(null);
                    // } else {
                    selectedSymbol === row.symbol ? setHighlightIndex(-1) : setHighlightIndex(idx);
                    handleRowClick(row.symbol, row.rank);
                    // }
                  }}
                >
                  <td style={{ padding: '4px' }}>{row.rank}</td>
                  <td style={{ padding: '4px' }}>{row.symbol}</td>
                  <td style={{ padding: '4px', textAlign: 'right' }}>
                    {(row.percentChange * 100).toFixed(2)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box >



    );
  }

  // ------------------------------------------------------------------
  // 7) Final Render
  // ------------------------------------------------------------------
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', padding: isMobile ? '5px' : '20px' }}>
      <Box style={{
        display: 'flex',
        // flexDirection: (isMobile || isTablet) ? 'column' : 'row',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // gap: (isMobile || isTablet) ? '5px' : '20px'
        gap: '5px'
      }}>
        <h1 style={{ margin: 0 }}>YTD Top 50</h1>
        {/* Year dropdown */}
        <div>
          <FormControl size="small" sx={{ minWidth: 120 }}>
            <InputLabel>Select Year</InputLabel>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              label="Select Year"
            >
              {["2021", "2022", "2023", "2024", "2025"].map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Box>

      <Box sx={{
        padding: {
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        },
        backgroundColor: theme.palette.background.sidebar,
        marginTop: {
          xs: '5px',
          sm: '5px',
          md: '10px',
          lg: '10px',
          xl: '20px',
        },
        borderRadius: '5px', boxShadow: theme.palette.shadow.primary
      }}>
        <div
          style={{
            display: 'flex',
            flexDirection: (isMobile || isPTablet) ? 'column' : 'row',
            gap: '20px',
            width: '100%',
          }}
        >
          {/* Chart Container */}
          <div
            ref={chartContainerRef}
            style={{
              flex: '1 1 0px',
              // minWidth: '0',
              maxWidth: (isMobile || isPTablet) ? '100%' : '80vw',
              // outline: '1px solid #ccc', // ขอใช้เป็น outline แทน border นะครับมันมีผลต่อขนาดของ chart เดี๋ยวมันขยายเองอัตโนมัติ
              height: (isMobile || isPTablet) ? '40vh' : 'auto',
            }}
          />
          {/* Table side */}
          {(!isMobile && !isPTablet) && (
            <>
              <Box sx={{
                width: '260px',
              }}>
                <Typography variant="h4" sx={{ marginTop: 0, display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>Top 50</Typography>
                <Box sx={{
                  // border: '1px solid #ccc',
                  maxHeight: '600px',
                  overflowY: 'auto',
                  '::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                    borderRadius: '4px',
                  },
                  '::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                }}>
                  {renderTable()}
                </Box>

              </Box>
            </>
          )}

          {(isMobile || isPTablet) && (
            <>
              <Box sx={{
                // width: '260px',
              }}>
                <h3 style={{ marginTop: 0, display: 'flex', justifyContent: 'center' }}>Top 50</h3>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
                  <Box sx={{
                    // border: '1px solid #ccc',
                    maxHeight: '35vh',
                    overflowY: 'auto',
                    '::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '::-webkit-scrollbar-thumb': {
                      backgroundColor: '#888',
                      borderRadius: '4px',
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#555',
                    },
                  }}>
                    {renderTable(0, 25)}
                  </Box>
                  <Box sx={{
                    // border: '1px solid #ccc',
                    maxHeight: '35vh',
                    overflowY: 'auto',
                    '::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '::-webkit-scrollbar-thumb': {
                      backgroundColor: '#888',
                      borderRadius: '4px',
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                      backgroundColor: '#555',
                    },
                  }}>
                    {renderTable(25, 50)}
                  </Box>
                </Box>


              </Box>
            </>
          )}

        </div>
      </Box>


    </div>
  );
}
