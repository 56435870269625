import React from 'react';

function LookerEmbed() {
  return (
    <div style={{ width: '100%', height: 'calc(100vh - 60px)' }}>
      <iframe
        width= "100%"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/848dcc3d-1842-41cc-a267-1482a99ddad6/page/p_9o6ei4yhcd"
        frameBorder="0"
        style={{ width: '100%', height: '100%', border: 'none' }}
        allowFullScreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        title="Looker Studio Report"
      />
    </div>
  );
}

export default LookerEmbed;
