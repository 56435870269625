import { useState, useEffect, forwardRef, createRef, useContext } from "react";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Box, Typography, useTheme, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment, IconButton, Select, MenuItem, InputLabel, Paper,
  OutlinedInput, Chip
} from "@mui/material";

import {
  AdminPanelSettingsOutlinedIcon, LockOpenOutlinedIcon, SecurityOutlinedIcon, Visibility, VisibilityOff
} from '@mui/icons-material'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { tokens } from "../../theme";
import { useFetchingData } from '../../components/api.js';
import Header from "../../components/Header";
import DataGridComponent from "../../components/DataGrid";
import DialogComponent from "../../components/Dialog";

const UserComponent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { __fetching_data__ } = useFetchingData();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const columns = [
    // { field: "_id", headerName: "ID" },
    {
      field: "fullname",
      headerName: "ชื่อ-นามสกุล",
      flex: 1,

      // cellClassName: "name-column--cell",
      valueGetter: (params) =>
        `${params.row.firstname || ''} ${params.row.lastname || ''}`,

    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      // cellClassName: "name-column--cell",
    },
    {
      field: "user_type",
      headerName: "ประเภท",
      flex: 1,
    },
    {
      field: "user_group",
      headerName: "Group",
      flex: 1,
    },
    // {
    //   field: "user_type",
    //   headerName: "ประเภท",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <button onClick={() => handleButtonClick(params.row)}>
    //       {params.row.user_type === "customer" ? "Customer Button" : "Employee Button"}
    //     </button>
    //   ),
    // },
    // {
    //   field: "user_type",
    //   headerName: "ประเภท",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <Select
    //       value={params.row.user_type}
    //       onChange={(e) => handleSelectChange(params.row, e.target.value)}
    //       size="small"
    //       labelId="demo-simple-select-label1"
    //       id="demo-simple-select1"
    //     >
    //       <MenuItem value="customer">Customer</MenuItem>
    //       <MenuItem value="employee">Employee</MenuItem>
    //     </Select>
    //   ),
    // },

  ];



  const csrfToken = useSelector((state) => state.csrfToken);

  const [users, setUsers] = useState(null);


  const getUsers = async () => {
    const usersResponse = await __fetching_data__({ url: `${window.config.base_api_url}/users`, method: "GET", headers: { "xsrf-token": csrfToken } });
    if (usersResponse.status) {
      setUsers(usersResponse.data)
    }
  }
  const getRoles = async () => {
    const roleResponse = await __fetching_data__({ url: `${window.config.base_api_url}/roles`, method: "GET", headers: { "xsrf-token": csrfToken } });
    if (roleResponse.status) {
      setRoles(roleResponse.data)
    }
  }

  const [roles, setRoles] = useState(null);

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);

  var initialValueForm = {
    firstname: '',
    lastname: '',
    password: '',
    user_type: 'customer',
    permission: '',
    role_id: [],
  }

  const [openForm, setOpenForm] = useState();
  const [formData, setFormData] = useState(initialValueForm);



  const handleOpenForm = () => {
    resetForm();
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };
  const handleSubmitForm = async () => {
    console.log(formData)
    setOpenForm(false);
    const response = await __fetching_data__({ url: `${window.config.base_api_url}/users`, method: "POST", headers: { "xsrf-token": csrfToken }, body: JSON.stringify(formData) });
    if (response.status) {
      getUsers();
      resetForm();
    }
  };
  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    if (name === 'role_id') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: typeof value === 'string' ? value.split(',') : value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const resetForm = () => {
    setFormData(initialValueForm);
  };


  const EditDialog = ({ open, handleClose, rowData, handleSave }) => {
    const [editedData, setEditedData] = useState(rowData);

    const [roleIdSelected, setRoleIdSelected] = useState([]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      // setEditedData({ ...editedData, [name]: value });
      if (name === 'roleIdSelected') {
        console.log(name, value)
        setEditedData({
          ...editedData,
          [name]: typeof value === 'string' ? value.split(',') : value,
        });
      } else {
        setEditedData({ ...editedData, [name]: value });
      }
    };

    const handleChangeSelected = (event) => {
      const {
        target: { value },
      } = event;
      setRoleIdSelected(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

    };

    const handleSaveClick = () => {
      handleSave(editedData);
      handleClose();
    };

    useEffect(() => {
      // setEditedData(rowData)
      // console.log(editedData)
    }, []);

    return (
      <Dialog open={open} onClose={handleEditClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1, padding: 1 }}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField name="firstname" label="First Name" value={(editedData && editedData.firstname) || ""} onChange={handleChange} fullWidth size="small" autoComplete="off" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField name="lastname" label="Last Name" value={(editedData && editedData.lastname) || ""} onChange={handleChange} fullWidth size="small" autoComplete="off" />
              </Grid>
            </Grid>



            <Grid container justifyContent="center" spacing={2} sx={{
              marginTop: '0.01rem'
            }}>
              <Grid item xs={12} md={8}>
                <Select
                  labelId="edit_select_role_id_label"
                  id="edit_select_role_id"
                  multiple
                  // value={roleIdSelected}
                  value={(editedData && editedData.role_id) || []}
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-role-id" label="เมนู" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={roles.find(role => parseInt(role.role_id) === parseInt(value))?.name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  fullWidth
                  size="small"
                  name="role_id"
                >
                  {roles && (
                    roles.map(role => (
                      <MenuItem key={role.role_id} value={role.role_id}>
                        {role.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={2} sx={{ marginTop: '0.01rem' }}>
              <Grid item xs={12} md={8} direction="row" container
                justifyContent="flex-end"
                alignItems="center"
              >
                <Select
                  labelId="edit_select_user_type_label"
                  id="edit_select_user_type"
                  value={(editedData && editedData.user_type) || "customer"}
                  label="ประเภท"
                  onChange={handleChange}
                  size="small"
                  name="user_type"
                  sx={{
                    minWidth: 120,
                    maxWidth: 120,
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <MenuItem value={"customer"}>customer</MenuItem>
                  <MenuItem value={"employee"}>employee</MenuItem>
                </Select>
              </Grid>
            </Grid>







          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditClose}
            sx={{
              backgroundColor: colors.redAccent[500]
            }}
          >Cancel</Button>
          <Button
            onClick={handleSaveClick}
            sx={{
              backgroundColor: colors.blueAccent[500]
            }}>Save</Button>
        </DialogActions>
      </Dialog >
    );
  };


  const [openEdit, setEditOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleEdit = (rowData) => {
    console.log(rowData)
    setSelectedRow(rowData);
    setEditOpen(true);
  };

  const handleEditSave = async (editedData) => {
    const response = await __fetching_data__({ url: `${window.config.base_api_url}/users`, method: "PUT", headers: { "xsrf-token": csrfToken }, body: JSON.stringify(editedData) });
    if (response.status) {
      getUsers();
    }
  };

  const [expirations, setExpirations] = useState({});
  const handleDateChange = (roleId, date) => {
    setExpirations((prevExp) => ({
      ...prevExp,
      [roleId]: date,
    }));
  };


  return (
    <Box m="20px">
      {/* <Header title="Users" subtitle="Managing User Members" />
      <Box display="flex" justifyContent="end" mt="20px">
        <Button type="submit" color="secondary" variant="contained">
          Create New User
        </Button>
      </Box> */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Header title="Users" subtitle="Managing User Members" />
        <Box>
          <Button type="submit" variant="contained" onClick={handleOpenForm}
            sx={{
              backgroundColor: colors.blueAccent[400]
            }} >
            Create New User
          </Button>
        </Box>
      </Grid>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            color: 'black',
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "transparent", //cheng background
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",

            backgroundColor: "#686868",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: `${colors.tonal[20]} !important`,
            // color: "black",
          },
        }}
      >
        {users && (
          <DataGridComponent
            rows={users}
            columns={columns}
            getRowId={(row) => row._id}
            components={{
              Toolbar: GridToolbarContainer,
              ToolbarExport: GridToolbarExport,
            }}
            disableRowSelectionOnClick
            onRowClick={(rowData) => handleEdit(rowData.row)}
          />
        )}
        <EditDialog open={openEdit} handleClose={handleEditClose} rowData={selectedRow} handleSave={handleEditSave} />
      </Box>

      <DialogComponent open={openForm} onClose={handleCloseForm} title="New User"
        content={
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
              <Box sx={{
                flexGrow: 1,
                //  padding: 1
              }}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="First Name"
                      name="firstname"
                      value={formData.firstName}
                      onChange={handleChangeForm}
                      fullWidth
                      margin="normal"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Last Name"
                      name="lastname"
                      value={formData.lastName}
                      onChange={handleChangeForm}
                      fullWidth
                      margin="normal"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChangeForm}
                      fullWidth
                      // margin="normal"
                      size="small"
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChangeForm}
                      fullWidth
                      // margin="normal"
                      size="small"
                      autoComplete="off"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePassword} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>


                <Grid container justifyContent="center" spacing={2} sx={{
                  marginTop: '0.01rem'
                }}>
                  <Grid item xs={12} md={8}>
                    <Select
                      labelId="edit_select_role_id_label"
                      id="edit_select_role_id"
                      multiple
                      // value={roleIdSelected}
                      value={(formData && formData.role_id) || []}
                      onChange={handleChangeForm}
                      input={<OutlinedInput id="select-multiple-role-id" label="เมนู" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={roles.find(role => parseInt(role.role_id) === parseInt(value))?.name} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                      fullWidth
                      size="small"
                      name="role_id"
                    >
                      {roles && (
                        roles.map(role => (
                          <MenuItem key={role.role_id} value={role.role_id}>
                            {role.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </Grid>
                </Grid>



                <Grid container justifyContent="center" spacing={2} sx={{ marginTop: '0.01rem' }}>
                  <Grid item xs={12} md={4}>
                  </Grid>
                  <Grid item xs={12} md={4} direction="row" container
                    justifyContent="flex-end"
                    alignItems="center">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formData.user_type || "customer"}
                      label="ประเภท"
                      onChange={handleChangeForm}
                      size="small"
                      name="user_type"
                    >
                      <MenuItem value={"customer"}>customer</MenuItem>
                      <MenuItem value={"employee"}>employee</MenuItem>
                    </Select>
                  </Grid>
                </Grid>


                {
                  // <Grid item xs={12} key={roleId} md={4}>
                  //   <DatePicker
                  //     label={`Expiration Date for ${roles.find((role) => parseInt(role.role_id) === parseInt(roleId))?.name}`}
                  //     value={expirations[roleId] || null}
                  //     onChange={(date) => handleDateChange(roleId, date)}
                  //     renderInput={(params) => <TextField {...params} fullWidth />}
                  //     slotProps={{ textField: { size: 'small' } }}
                  //   />
                  //   <Typography>
                  //     {`Expiration Date for ${roles.find((role) => parseInt(role.role_id) === parseInt(roleId))?.name}`}
                  //   </Typography>
                  // </Grid>
                }
                {/* <Paper sx={{ maxHeight: '100px', overflowY: 'auto', mt: 2, p: 2 }}>
              <Grid container spacing={2}>
                {(formData.role_id || []).map((roleId) => {
                  const roleName = roles.find((role) => parseInt(role.role_id) === parseInt(roleId))?.name;

                  return (
                    <>
                      < Grid item xs={12} sm={2} >
                        <Box component="span" sx={{ fontWeight: 'bold' }}>
                          {`Expiration Date for ${roleName}`}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <DatePicker
                          value={expirations[roleId] || null}
                          onChange={(date) => handleDateChange(roleId, date)}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>

            </Paper> */}
                <Paper sx={{ maxHeight: '300px', overflowY: 'auto', mt: 2, p: 2 }}>
                  <Grid container spacing={2}>
                    {(formData.role_id || []).map((roleId) => {
                      const roleName = roles.find((role) => parseInt(role.role_id) === parseInt(roleId))?.name;

                      return (
                        <Grid container key={roleId} spacing={2} marginTop={1}>
                          <Grid item xs={12} sm={4}>
                            <Box component="span" sx={{ fontWeight: 'bold' }}>
                              {`Expiration Date for ${roleName}`}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <DatePicker
                              value={expirations[roleId] || null}
                              onChange={(date) => handleDateChange(roleId, date)}
                              renderInput={(params) => <TextField {...params} fullWidth />}
                              slotProps={{ textField: { size: 'small' } }}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Paper>


              </Box>
            </LocalizationProvider>
          </>
        }
        action={
          <>
            <Button variant="contained" type="button" onClick={handleCloseForm} sx={{ backgroundColor: colors.redAccent[500] }}>
              Cancel
            </Button>
            <Button variant="contained" type="button" onClick={handleSubmitForm} sx={{ backgroundColor: colors.greenAccent[500] }}>
              Submit
            </Button>
          </>
        }
      />

    </Box >








  );
};

export default UserComponent;
