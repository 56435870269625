import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
      grey: {
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
      },
      primary: {
        100: "#d0d1d5",
        200: "#a1a4ab",
        300: "#727681",
        400: "#1F2A40",
        500: "#141b2d",
        600: "#101624",
        700: "#0c101b",
        800: "#080b12",
        900: "#040509",
      },
      greenAccent: {
        100: "#dbf5ee",
        200: "#b7ebde",
        300: "#94e2cd",
        400: "#70d8bd",
        500: "#4cceac",
        600: "#3da58a",
        700: "#2e7c67",
        800: "#1e5245",
        900: "#0f2922",
      },
      redAccent: {
        100: "#f8dcdb",
        200: "#f1b9b7",
        300: "#e99592",
        400: "#e2726e",
        500: "#db4f4a",
        600: "#af3f3b",
        700: "#832f2c",
        800: "#58201e",
        900: "#2c100f",
      },
      blueAccent: {
        100: "#e1e2fe",
        200: "#c3c6fd",
        300: "#a4a9fc",
        400: "#868dfb",
        500: "#6870fa",
        600: "#535ac8",
        700: "#3e4396",
        800: "#2a2d64",
        900: "#151632",
      },
      yellowAccent: {
        100: "",
        200: "",
        300: "#ff9800",
        400: "",
        500: "",
        600: "",
        700: "",
        800: "",
        900: "",
      },
      surface: {
        0: "#202431",
        10: "#353845",
        20: "#4b4e5a",
        30: "#62656f",
        40: "#7b7d86",
        50: "#94959d"
      },
      tonal: {
        0: "#303440",
        10: "#444853",
        20: "#595c66",
        30: "#6f717b",
        40: "#85878f",
        50: "#9c9ea5",
      },
      redChart: {
        50: '#D32F2F',
        100: '#FF4444',
        200: '#F60000',
        300: '#FF3B30',
      },
      orangeChart: {
        50: '#ff6f46',
        100: '#FF9500',
        200: '#FF6B6B',
        300: '#FF8200',
        400: '#FF5E3A',
      },
      blueChart: {
        50: '#5efee8',
        60: '#44ebda',
        70: '#5AC8FA',
        100: '#007AFF'
      },
      greenChart: {
        50: '#3CB371',
        100: '#00FF00',
        200: '#1A6F00',
        300: '#34C759',
        400: '#32D74B',
      },
      purpleChart: {
        100: '#9747FF',
        200: '#AF52DE',
        300: '#BF5AF2'
      },
      whiteChart: {
        100: '#ffffff',
        200: '#ffffff'
      },
      yellowChart: {
        100: '#FFCC00',
        200: '#FFC300',
        300: '#FFB300'
      }

    }
    : {
      grey: {
        100: "#141414",
        200: "#292929",
        300: "#3d3d3d",
        400: "#525252",
        500: "#666666",
        600: "#858585",
        700: "#a3a3a3",
        800: "#c2c2c2",
        900: "#e0e0e0",
      },
      primary: {
        100: "#040509",
        200: "#080b12",
        300: "#0c101b",
        400: "#f2f0f0", // manually changed
        500: "#141b2d",
        600: "#1F2A40",
        700: "#727681",
        800: "#a1a4ab",
        900: "#d0d1d5",
      },
      greenAccent: {
        100: "#0f2922",
        200: "#1e5245",
        300: "#2e7c67",
        400: "#3da58a",
        500: "#4cceac",
        600: "#70d8bd",
        700: "#94e2cd",
        800: "#b7ebde",
        900: "#dbf5ee",
      },
      redAccent: {
        100: "#2c100f",
        200: "#58201e",
        300: "#832f2c",
        400: "#af3f3b",
        500: "#db4f4a",
        600: "#e2726e",
        700: "#e99592",
        800: "#f1b9b7",
        900: "#f8dcdb",
      },
      blueAccent: {
        100: "#151632",
        200: "#2a2d64",
        300: "#3e4396",
        400: "#535ac8",
        500: "#6870fa",
        600: "#868dfb",
        700: "#a4a9fc",
        800: "#c3c6fd",
        900: "#e1e2fe",
      },
      yellowAccent: {
        100: "",
        200: "",
        300: "#ff9800",
        400: "",
        500: "",
        600: "",
        700: "",
        800: "",
        900: "",
      },
      surface: {
        0: "#202431",
        10: "#353845",
        20: "#4b4e5a",
        30: "#62656f",
        40: "#7b7d86",
        50: "#94959d"
      },
      tonal: {
        0: "#303440",
        10: "#444853",
        20: "#595c66",
        30: "#6f717b",
        40: "#85878f",
        50: "#9c9ea5",
      },
      redChart: {
        50: '#D32F2F',
        100: '#FF4444',
        200: '#F60000',
        300: '#FF3B30',
      },
      orangeChart: {
        50: '#ff6f46',
        100: '#FF9500',
        200: '#FF6B6B',
        300: '#FF8200',
        400: '#FF5E3A',
      },
      blueChart: {
        50: '#5efee8',
        60: '#44ebda',
        70: '#5AC8FA',
        100: '#007AFF'
      },
      greenChart: {
        50: '#3CB371',
        100: '#00FF00',
        200: '#1A6F00',
        300: '#34C759',
        400: '#32D74B',
      },
      purpleChart: {
        100: '#9747FF',
        200: '#AF52DE',
        300: '#BF5AF2'
      },
      whiteChart: {
        100: '#ffffff',
        200: '#ffffff'
      },
      yellowChart: {
        100: '#FFCC00',
        200: '#FFC300',
        300: '#FFB300'
      }
    }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            main: colors.primary[500],
          },
          secondary: {
            main: colors.greenAccent[500],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: '#2E2E2E',
            sidebar: '#030304',
            card: '#636a7e'
          },
          text: {
            primary: '#ffffff',
            primary2: "#000000",
            secondary: '#ffffff',
            sidebar: '#ffffff',
            table: '#E0E0E0'
          },
          table: {
            primary: '#030304',
            even: '#1E1E1E',
            odd: '#2A2A2A'
          },
          chart: {
            primary: '#202431',
            watermark: 'rgba(255, 255, 255, 0.1)'
          },
          shadow: {
            primary: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
          }
        }
        : {
          // palette values for light mode
          primary: {
            main: colors.primary[100],
          },
          secondary: {
            main: colors.greenAccent[500],
          },
          neutral: {
            dark: "#5C667D", // เทาเข้ม
            main: "#AAB1C4", // เทากลาง
            light: "#DCE1EB", // เทาอ่อน
          },
          background: {
            default: "#2e3343", //สีกรมท่า
            sidebar: '#202431', //สีกรมท่าเข้มกว่านิดนหน่อย
            card: '#636a7e', //กรมท่าไปออกเทาอ่อนๆมาก
            container: '#424756', //กรมท่าออกเทาเข้ม
            container1: '#575b69', //กรมท่าออกเทาอ่อน
          },

          text: {
            primary: "#ffffff",
            primary2: "#000000",
            secondary: "#AAB1C4", // เทาอ่อน
            sidebar: "#ffffff", // ขาว
            sidebar2: "#E0E0E0", // ขาวอมเทา
            a20: '#ffd286'
          },
          table: {
            primary: "#575b69", // ขาว
            even: "#3B4252",
            odd: "#434C5E",
            even2: '#1E1E1E',
            odd2: '#2A2A2A',
            thead1: '#004080',
            thead: '#283593', //น้ำเงินกรมท่าหัวตาราง
          },
          chart: {
            primary: '#575b69',
            watermark: 'rgba(255, 255, 255, 0.4)'
          },
          shadow: {
            primary: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);'
          }
        }),
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#3B4252" : "transparent", // พื้นหลัง Input
            color: mode === "dark" ? "#E0E0E0" : "#ffffff", // สีข้อความ
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#AAB1C4", // สีขอบปกติ
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#7986CB", // สีขอบตอน hover (ฟ้าอ่อน)
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#64B5F6",
              borderWidth: "2px", // ขอบหนาขึ้นตอน focus
              color: '#ffffff'
            },

            "&.Mui-disabled": {
              '-webkit-text-fill-color': 'unset',
              backgroundColor: mode === "dark" ? "#2E3440" : "#transparent",
              color: mode === "dark" ? "#A0A0A0" : "#9095A0",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#5C667D",
              },
            },
          },
        },
      },

      MuiDatePicker: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-root": {
              backgroundColor: mode === "dark" ? "#3B4252" : "#ffffff",
              color: mode === "dark" ? "#E0E0E0" : "#ffffff",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#5C667D",
              },
            },
            "& .MuiInputLabel-root": {
              color: mode === "dark" ? "#AAB1C4" : "#ffffff",
            },
            "& .Mui-disabled": {
              // backgroundColor: mode === "dark" ? "#2E3440" : "#fffff",
              color: mode === "dark" ? "#A0A0A0" : "#ffffff",
            },
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#E0E0E0" : "#ffffff",
            "&.MuiTextField": {
              color: mode === "dark" ? "#E0E0E0" : "#ffffff",
            },
            "&.Mui-disabled": {
              color: mode === "dark" ? "#E0E0E0" : "#ffffff",
            }
          },

        },
      },

      MuiSvgIcon: { // 🎯 เพิ่มส่วนควบคุมสี Icon
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#E0E0E0" : "#ffffff",
          },
        },
      },

      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#E0E0E0" : "#ffffff", // สีตัวเลขวันปกติ
            ":hover": {
              backgroundColor: mode === "dark" ? '64B5F6' : "#4b4e5a"
            },
            "&.Mui-selected": {
              backgroundColor: "#64B5F6 !important", // สีของวันเลือก
              color: "#ffffff",
            },
            "&.Mui-selected:hover": {
              backgroundColor: mode == 'dark' ? 'E0E0E0' : '#64B5F6 !important'
            },
            "&.MuiPickersDay-today": {
              border: "1px solid #64B5F6", // สีขอบของวันที่เป็นวันนี้
              color: "#ffffff"
            },
            "&.Mui-disabled": {
              color: mode === "dark" ? "#6B7280" : "#AAB1C4",
            },
            "&.Mui-disabled:not(.Mui-selected)": {
              color: mode === "dark" ? "#6B7280 !important" : "#AAB1C4 !important",
            },
          },
        },
      },


      MuiPickersPopper: {
        styleOverrides: {
          root: {
            root: {

              "& .MuiPaper-root": {
                backgroundColor: mode === "dark" ? "#202431" : "#202431", // พื้นหลังของ DatePicker Popup
                color: mode === "dark" ? "#E0E0E0" : "#ffffff",
              },
            },
            "& .Mui-disabled": {
              color: mode === "dark" ? "#6B7280" : "#9095A0",
            },
          },
        },
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? "#202431" : "#202431", // พื้นหลังของทุก Paper component
            color: mode === "dark" ? "#E0E0E0" : "#ffffff",
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            "& .Mui-disabled": {
              color: mode === "dark" ? "#A0A0A0" : "#9095A0",
            },
          },
        },
      },

      MuiSlider: {
        styleOverrides: {
          root: {
            color: 'unset'
          },
        },
      },


      MuiInputBase: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#E0E0E0" : "#ffffff",
            "&.Mui-disabled": {
              '-webkit-text-fill-color': 'unset',
              opacity: 1,
            },
            "& input::-webkit-calendar-picker-indicator": {
              filter: "invert(1)", // ทำให้ไอคอนปฏิทินเป็นสีขาว
            },
          },
          input: {
            "&.Mui-disabled": {
              '-webkit-text-fill-color': 'unset',
              opacity: 1,
            },
          }
        },
      },
      MuiAutoComplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              color: mode === 'dark' ? "#E0E0E0" : "#ffffff",
            },
            '& .MuiAutocomplete-input': {
              color: 'white',
            },

          }
        }
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: mode === "dark" ? "#AAB1C4" : "#ffffff", // สีของ label ปกติ
            "&.Mui-focused": {
              color: "#ffffff", // สีตอน Focus
            },
            "&.Mui-disabled": {
              color: 'unset',
              // opacity: 1,
            },
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#62656f", // สีพื้นหลังปกติ
            color: "#fff", // สีตัวอักษร
            // borderRadius: "8px", // ทำให้ปุ่มโค้งมน
            // padding: "10px 20px",
            // textTransform: "none", // ปิดการเปลี่ยนเป็นตัวพิมพ์ใหญ่
            transition: "all 0.3s ease-in-out",

            "&:hover": {
              backgroundColor: "#9c9ea5", // สีตอน hover
              transform: "scale(1.02)", // ขยายเล็กน้อยตอน hover
              color: "#202431"
            },

            "&:active": {
              backgroundColor: "#ffcd77", // สีตอนคลิก
              transform: "scale(0.98)", // ย่อเล็กลงตอนคลิก
              color: "#202431"
            },

            // "&:focus": {
            //   boxShadow: "0 0 0 4px rgba(0, 123, 255, 0.4)", // ขอบโฟกัส
            // },

            "&.Mui-disabled": {
              backgroundColor: "#AAB1C4", // สีตอน disabled
              color: "#fff",
              opacity: 0.6,
            },
          },
        },
        defaultProps: {
          disableElevation: true, // ปิดเงาเริ่มต้น
          disableRipple: true, // ปิด effect ripple
        },
      },

      MuiSelect: {
        styleOverrides: {
          icon: {
            color: mode === "dark" ? "#E0E0E0" : "#ffffff !important",
          },
          root: {
            backgroundColor: mode === "dark" ? "#3B4252" : "transparent",
            color: mode === "dark" ? "#E0E0E0" : "#ffffff",
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#7986CB",
            },
          },
        },
      },
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
