import { useState, useEffect, forwardRef, createRef, useContext, useRef } from "react";
import {
    Box, Button, TextField, useMediaQuery, Typography, useTheme, Grid, Select, MenuItem, FormControl, InputLabel, Chip, OutlinedInput, FormLabel, FormGroup,
    FormControlLabel, Checkbox, Card, CardContent, CardActions, RadioGroup, Radio, InputAdornment, IconButton, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Slide, Backdrop, CircularProgress, Alert, AlertTitle
} from "@mui/material";
import { styled } from '@mui/material/styles';
import {
    Visibility, VisibilityOff,
    EditOutlined as EditOutlinedIcon,
    CloudUpload as CloudUploadIcon
} from '@mui/icons-material';

import { Formik } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import ReCAPTCHA from "react-google-recaptcha";

import { ColorModeContext, tokens } from "../../../theme";
import { setLogin, setCsrfToken, setMode } from "../../../state";
import FlexBetween from "../../../components/FlexBetween";
import { useFetchingData } from '../../../components/api.js';
import KbankIcon from '../../../images/kbank_icon.png';
import "./form1.css";
import logo from '../../../images/logo.png'
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Preloading from "../../../components/Preloading";
import Rocket from "../../../components/Rocket";


const registerSchema = yup.object().shape({
    firstname: yup.string().required(""),
    lastname: yup.string().required(""),
    email: yup.string().email("invalid email").required(""),
    password: yup.string().required(""),
    packages: yup.array().min(1, "Select at least one package").notRequired(),
    plan: yup.string().required("required"),
    slip_image: yup.string().required("required"),
});
// password: yup.string().required("required"),
//picture: yup.string().required("required"),

const loginSchema = yup.object().shape({
    email: yup.string().email("invalid email").required(""),
    password: yup.string().required(""),
});

const initialValuesRegister = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    picture: "",
    packages: [],
    total_price: 0,
    slip_image: null,
    plan: "monthly"
};

const initialValuesLogin = {
    email: "",
    password: "",
};

const forgetPassword = () => {
    //alert('Your ID is : your email address\nYour Password is : your phone number');
    <Dialog>

    </Dialog>
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

const Form = () => {
    // const classes = useOTPStyles();
    const { __fetching_data__ } = useFetchingData();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    // const colors = colorTokens(theme.palette.mode);
    const colors = tokens(theme.palette.mode);
    const { palette } = useTheme();
    const colorMode = useContext(ColorModeContext);

    const [pageType, setPageType] = useState("login");
    const isLogin = pageType === "login";
    const isRegister = pageType === "register";
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const google = window.google ? window.google : {};

    const [open, setOpen] = useState(false);
    const [openSignUp, setOpenSignUp] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openDialogOtp, setOpenDialogOtp] = useState(false);
    const [dialogValue, setDialogValue] = useState(null);
    const [user, setUser] = useState(null);

    const csrfToken = useSelector((state) => state.csrfToken);
    const [fileName, setFileName] = useState('Upload Slip');

    const recaptchaRef = createRef();
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [loading, setLoading] = useState(false);







    const handleReCaptchaOnChange = async (value) => {
        setRecaptchaValue(value);
        // var url =
        //     "https://www.google.com/recaptcha/api/siteverify?secret=6Lc7WYwjAAAAAFSA7L9up4GG0gwIHI7M4Os7WAub&response=" +
        //     value;
    };

    const AlertMessage = ({ severity, title, message }) => {
        return (
            <Alert severity={severity} variant="filled">
                {title && <AlertTitle>{title}</AlertTitle>}
                {message}
            </Alert>
        );
    };

    const forgetPasswordClick = () => {
        setOpen(true);
    };
    const forgetPasswordClose = () => {
        setOpen(false);
    };
    const signUpClick = () => {
        setOpenSignUp(true);
        setPageType(isLogin ? "register" : "login");
    };
    const signUpClose = () => {
        setOpenSignUp(false);
        setPageType(isLogin ? "register" : "login");
    };

    const handleOpenBackdrop = () => {
        setOpenBackdrop(true);
    };
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleToggleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    };
    const [isRegisterStatus, setRegisterStatus] = useState(false);

    const [packages, setPackage] = useState(null);
    const getPackage = async () => {
        const roleResponse = await __fetching_data__({ url: `${window.config.base_api_url}/get_package`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log("📌 API Response:", roleResponse.data);
        setPackage(roleResponse.data)
    }
    useEffect(() => {
        getPackage();
    }, []);
    const handleFormSubmit = async (values, onSubmitProps) => {
        if (isLogin) await login(values, onSubmitProps);
        if (isRegister) await register(values, onSubmitProps);
    };
    const register = async (values, onSubmitProps) => {
        const formData = new FormData();
        for (let value in values) {
            if (value != "slip_image") {
                formData.append(value, values[value]);
            }
        }
        if (values.slip_image) {
            formData.append('slip_image', values.slip_image);
        }

        const registerResponse = await __fetching_data__({ url: `${window.config.base_api_url}/register`, method: "POST", headers: { "xsrf-token": csrfToken }, body: formData, isUploadImg: true });

        if (registerResponse.status) {
            setRegisterStatus(true);
            onSubmitProps.resetForm();
            setTimeout(() => {
                signUpClose();
                setRegisterStatus(false);
            }, 10000);
        }

    };
    const login = async (values, onSubmitProps) => {
        handleLogin(values, "local", onSubmitProps);
    };
    const loginGoogle = async (values, onSubmitProps) => {
        handleLogin(values, "google");
    };
    const handleLogin = async (values, type, onSubmitProps) => {
        setLoading(true); // เปิด Loading

        setLoginStatus(false);
        var value = null;
        if (type === "local") {
            value = values;
        }
        if (type === "google") {
            var decodedGoogle = parseJwt(values.credential);
            value = {
                email: decodedGoogle.email,
                firstname: decodedGoogle.given_name,
                lastname: decodedGoogle.family_name,
                auth_is_google: true,

                aud: decodedGoogle.aud,
                azp: decodedGoogle.azp,
                email_verified: decodedGoogle.email_verified,
                exp: decodedGoogle.exp,
                iat: decodedGoogle.iat,
                iss: decodedGoogle.iss,
                jti: decodedGoogle.jit,
                locale: decodedGoogle.locale,
                fullname: decodedGoogle.name,
                nbf: decodedGoogle.nbf,
                picture: decodedGoogle.picture,
                sub: decodedGoogle.sub,
            };
        }
        handleToggleBackdrop();
        const loggedInResponse = await __fetching_data__({ url: `${window.config.base_api_url}/login`, method: "POST", headers: { "xsrf-token": csrfToken }, body: JSON.stringify(value) });
        if (loggedInResponse.status) {
            setUser({
                userId: loggedInResponse.userId,
                token: loggedInResponse.token,
                // userType: loggedInResponse.user.user_type,
            });
            handleOpenDialogOtp();

        } else {
            setLoginStatus(true);
            setLoginStatusText(loggedInResponse.message);
        } if (!loggedInResponse.status && loggedInResponse.code === 403) {
        }
        setLoading(false); // ปิด Loading เมื่อเสร็จ
        handleCloseBackdrop();
        setOtp(new Array(6).fill(''));
    };
    const handleOpenDialogOtp = () => {
        setOpenDialogOtp(true);
        setTimeout(() => {
            inputRefs.current[0]?.focus();
        }, 100);
    };

    const handleCloseDialogOtp = () => {
        setOpenDialogOtp(false);
        setOtpVerify(null);
    };

    const [otpMessage, setOtpMessage] = useState("");
    const [otpVerify, setOtpVerify] = useState(null);
    const handleSubmitOTP = async (e) => {
        var value = {
            userId: user.userId,
            otp: otp.join(''),
            type: "VERIFICATION",
        };
        const otpResponse = await __fetching_data__({ url: `${window.config.base_api_url}/email/otp`, method: "POST", headers: { "xsrf-token": csrfToken }, body: JSON.stringify(value) });
        // if (recaptchaValue) {
        if (otpResponse.status) {
            dispatch(
                setLogin({
                    user: user.userId,
                    token: user.token,
                    // userType: user.user_type,
                })
            );
            setOtpVerify(true);
            setOpenDialogOtp(false);
            navigate("/");
        } else {
            setOtpMessage("");
            setOtpVerify(false);
        }
        // }
    };


    useEffect(() => {
        if (otpVerify === false) {
            // setOtpVerify(null);
            setTimeout(() => {
                inputRefs.current[inputRefs.current.length - 1].focus();
            }, 100);
        }
    }, [otpVerify]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (window.google) {
                clearInterval(interval);
                google.accounts.id.initialize({
                    client_id:
                        "304685095232-2ptjt24p392eqi9qlr4ot6fs1g51ekje.apps.googleusercontent.com",
                    callback: loginGoogle,
                });
                google.accounts.id.renderButton(document.getElementById("signInDiv"), {
                    theme: "outline",
                    size: "large",
                });
                google.accounts.id.prompt();
            }
        }, 300);
    }, []);

    const [isLoginStatus, setLoginStatus] = useState(false);
    const [isLoginStatusText, setLoginStatusText] = useState("");

    const handleFileChange = (setFieldValue) => (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
            setFieldValue("slip_image", file);
            setFileName(file.name);
        }
    };

    const updateTotalPrice = (selectedPackage, plan) => {
        var total = packages
            .filter(pack => selectedPackage.includes(pack.role_id))
            .reduce((total, pack) => total + pack.package_price[plan], 0);
        return total
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();


    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef([]);

    const handleChangeOTP = (element, index) => {
        if (isNaN(element.value)) return;
        setOtpVerify(null);
        // if (!/^\d$/.test(element.value)) return;
        if (element.value && !/^\d$/.test(element.value)) return;

        const newOtp = [...otp];
        newOtp[index] = element.value;

        setOtp(newOtp);
        if (element.value && index < 6 - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleKeyUp = (e, index) => {
        if (index == 5 && otp[index] && otp.length == 6) {
            setTimeout(() => {
                handleSubmitOTP();
            }, 100);
        }
    }



    return (

        <Formik
            key={isLogin ? 'login' : 'register'}
            onSubmit={handleFormSubmit}
            initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
            validationSchema={isLogin ? loginSchema : registerSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        // display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                            height: "100vh", // ทำให้ความสูงพอดีกับหน้าจอ
                            width: "100%", // ทำให้ความกว้างเต็มหน้าจอ


                        }}
                    >
                        {isLoginStatus && (
                            <Grid item xs={12} md={12} sx={{ gridColumn: "span 4", marginTop: '-3.5rem' }}>
                                <AlertMessage severity="error" title="Login Failed" message={isLoginStatusText} />
                            </Grid>
                        )}

                        {isLogin && (
                            <div className="login-container">
                                <div className="back1"></div>
                                <div className="back3"></div>
                                <div className="back2"></div>

                                <Box className="login-box" sx={{
                                    position: 'fixed',
                                    backgroundColor: '#202431',
                                    borderRadius: '30px',
                                    overflow: 'auto',
                                    boxShadow: '0px 4px 10px rgba(255, 255, 255, 0.3)',
                                    display: 'flex',
                                    flexDirection: {
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row'
                                    },
                                    maxWidth: '1000px',
                                    width: '80%',
                                    height: '100%',
                                    maxHeight: {
                                        xs: '90vh',
                                        md: '600px'
                                    }

                                }}>
                                    <Box className="left-section" sx={{
                                        backgroundColor: '#202431',
                                        padding: {
                                            xs: '20px',
                                            md: '40px'
                                        },
                                        flex: { xs: 0, md: 1 },
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                    }}>
                                        {/* <h1 className="logo">
                                            <img src={logo} alt="IDEAa TRADE" />
                                        </h1> */}

                                        <Box display="flex" alignItems="center" justifyContent='center'
                                        // className="logo"
                                        >
                                            <Typography variant="h1" sx={{ m: 0, p: 0, lineHeight: 0 }}>
                                                <Box
                                                    component="img"
                                                    src={logo}
                                                    alt="IDEAa TRADE"
                                                    sx={{
                                                        width: '100%', maxWidth: '400px', height: 'auto',
                                                        mt: {
                                                            xs: '0px',
                                                            md: '-50px'
                                                        },
                                                        mr: {
                                                            xs: '0px',
                                                            md: '-80px',
                                                        }
                                                    }} // กำหนดขนาดตามต้องการ
                                                />
                                            </Typography>
                                        </Box>
                                        {/* <p className="subtext">Special Features for our customers</p> */}
                                        <Typography variant='h3' sx={{
                                            background: 'linear-gradient(90deg, #febd4c, #8655f8)',
                                            backgroundClip: 'text',
                                            '-webkit-text-fill-color': 'transparent',
                                            fontSize: '25px',
                                            fontWeight: 'bold',
                                            ml: {
                                                xs: '0px',
                                                md: '50px'
                                            }

                                        }}>Special Features for our customers</Typography>

                                    </Box>
                                    <Box
                                        className="right-section"
                                        sx={{
                                            backgroundColor: '#202431',
                                            padding: {
                                                xs: '20px',
                                                md: '40px'
                                            },
                                            flex: '1',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',

                                        }}
                                    >
                                        {/* <h2>Welcome to Idea Trade</h2> */}
                                        <Typography variant='h2' sx={{
                                            fontWeight: 'bold',
                                            fontSize: '2rem',
                                            textAlign: 'center',
                                            mb: {
                                                xs: '10px',
                                                md: '40px'
                                            },
                                            background: 'linear-gradient(90deg, #FFCD77, #5DD1FF)',
                                            backgroundClip: 'text',
                                            '-webkit-text-fill-color': 'transparent',
                                            fontFamily: 'nunito'
                                        }}>
                                            Welcome to Idea Trade
                                        </Typography>
                                        <form>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'column', alignItems: {
                                                    xs: 'center',
                                                    md: 'unset'
                                                }
                                            }}>
                                                <div className="inputBox1">
                                                    <input
                                                        type="email"
                                                        required
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.email}
                                                        name="email"
                                                    />
                                                    <span className="user">Email</span>
                                                    {touched.email && errors.email && <div className="error">{errors.email}</div>}
                                                </div>

                                                <div className="inputBox1" style={{ position: 'relative' }}>
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        required
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.password}
                                                        name="password"
                                                        style={{ paddingRight: '30px' }} // เพิ่มพื้นที่ทางขวาให้ไอคอน
                                                    />
                                                    <span>Password</span>
                                                    {touched.password && errors.password && <div className="error">{errors.password}</div>}

                                                    {/* ใส่ไอคอนตาเปิด/ปิดภายใน input */}
                                                    <Button
                                                        type="button"
                                                        className="showpass"
                                                        onClick={handleClickShowPassword}
                                                        sx={{
                                                            position: 'absolute',
                                                            right: {
                                                                xs: '-60px',
                                                                md: '-100px'
                                                            }, // จัดตำแหน่งของไอคอนทางขวา
                                                            top: '60%',
                                                            transform: 'translateY(-50%)',
                                                            background: 'none',
                                                            border: 'none',
                                                            cursor: 'pointer',
                                                            fontSize: '24px',
                                                            color: 'white',
                                                        }}
                                                    >
                                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                    </Button>
                                                </div>

                                            </Box>

                                            <div className="options">
                                                <label className="checkbox-wrapper">
                                                    <input type="checkbox" />
                                                    <div className="checkmark">
                                                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                            <path
                                                                strokeLinejoin="round"
                                                                strokeLinecap="round"
                                                                strokeWidth="3"
                                                                d="M20 6L9 17L4 12"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    <span className="label">Remember Me</span>
                                                </label>

                                                {isLogin && (
                                                    <a href="#" onClick={
                                                        forgetPasswordClick
                                                    }>Forget your ID or password?</a>)}

                                            </div>




                                            {/* {isLogin && (
                                                <button
                                                    type="submit"
                                                    className="color-7"
                                                    onClick={handleFormSubmit}
                                                >
                                                    {isLogin ? "SIGN IN" : "REGISTER"}
                                                </button>
                                            )} */}

                                        </form>
                                        <Box sx={{
                                            display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: '10px', mb: {
                                                sm: 'unset',
                                                xs: '80px'
                                            }
                                        }}>
                                            {isLogin && (<>
                                                <Button
                                                    type="submit"
                                                    // className="color-7"
                                                    sx={{
                                                        backgroundImage: 'linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645)',
                                                        boxShadow: '0 4px 15px 0 rgba(126, 52, 161, 0.75)',
                                                        width: '200px',
                                                        fontSize: '16px',
                                                        fontWeight: '600px',
                                                        margin: {
                                                            xs: '0px',
                                                            md: '20px'
                                                        },
                                                        borderRadius: '50px',

                                                    }}
                                                    onClick={handleFormSubmit}
                                                >
                                                    {isLogin ? "SIGN IN" : "REGISTER"}
                                                </Button>
                                                {/* <p
                                            // className="signup-text"
                                            >Don't have an account? <a href="#" onClick={
                                                signUpClick
                                            }>Sign Up</a> </p> */}
                                                <Typography variant='body1'>
                                                    Don't have an account? <a style={{ color: '#FCA331', textDecoration: 'none' }} href="#" onClick={
                                                        signUpClick
                                                    }>Sign Up</a>
                                                </Typography>
                                            </>
                                            )}

                                        </Box>


                                    </Box>

                                </Box>
                                <Preloading open={loading} />




                                <div className="shape1"></div>
                                <div className="shape2"></div>
                                <div className="shape3"></div>
                                <div className="shape4"></div>
                                <div className="shape5"></div>
                                <div className="shape6"></div>
                                <div className="shape7"></div>
                                {/* {isLogin && (
                                    
                                )} */}

                            </div>
                        )}
                    </Box>

                    {/* BUTTONS */}
                    <Box>
                        {/* <Box
                            //Forget Password
                            position='absolute'
                            width='31.5vw'
                            height='6vh'
                            paddingLeft='0vw'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                        >

                            {isLogin && (
                                <a href="#" style={{ color: 'black', textDecoration: 'none' }}
                                    onClick={
                                        forgetPasswordClick
                                    }

                                >Forget your ID or password?</a>
                            )}



                        </Box> */}

                        {/* {isLogin && (
                            <Button
                                type="submit"
                                sx={{
                                    m: "2rem 0",
                                    p: "0.8rem",
                                    width: "46%",
                                    top: '3vh',
                                    left: "27%",
                                    backgroundColor: palette.primary.main,
                                    color: palette.primary.contrastText,
                                    "&:hover": { color: palette.primary.main },
                                }}
                            >
                                {isLogin ? "SIGN IN" : "REGISTER"}
                            </Button>
                        )} */}

                        {/* {isLogin && (
                            <button
                                type="submit"
                                className="color-7"
                                onClick={handleFormSubmit}
                            >
                                {isLogin ? "SIGN IN" : "REGISTER"}
                            </button>
                        )} */}

                        <Box
                            //register
                            sx={{
                                width: '35vw',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                left: '0'
                            }}
                        >
                            {/* {isLogin && (
                                <p>Don't have an account? <a href="#" style={{ textDecoration: 'none', fontSize: '1.1rem', color: '#3083FF', fontWeight: 'bold' }} onClick={
                                    signUpClick
                                }>Sign Up</a> </p>
                            )} */}

                            <Dialog
                                open={openSignUp}
                                onClose={signUpClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth={'md'}
                                fullWidth={true}
                                fullScreen={true}


                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        width: 'fit-content',
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.3)', // Adjust the color and opacity as needed
                                            borderRadius: '4px', // Match the border-radius of the Alert component
                                            zIndex: 1,
                                        }
                                    }}
                                >
                                    {isRegisterStatus && (
                                        <Alert
                                            severity="success"
                                            sx={{
                                                position: 'relative',
                                                zIndex: 2,
                                                backgroundColor: 'secondary.main', // Secondary color
                                                color: 'white', // Text color to ensure readability
                                            }}
                                        >
                                            สมัครสมาชิกสำเร็จ.
                                        </Alert>
                                    )}
                                </Box>

                                <div className="register-container">
                                    <div className="backg1"></div>
                                    <div className="backg3"></div>
                                    <div className="backg2"></div>

                                    <Box className="register-box" sx={{
                                        position: 'fixed',
                                        display: 'flex',
                                        backgroundColor: '#202431',
                                        borderRadius: '30px',
                                        overflow: 'hidden',
                                        boxShadow: '0px 4px 10px rgba(255, 255, 255, 0.3)',
                                        width: {
                                            xs: '95%',
                                            md: '100%'
                                        },
                                        maxWidth: '1100px',
                                        height: '100%',
                                        maxHeight: '630px',
                                        // flexDirection: {
                                        //     xs: 'column',
                                        //     md: 'row'
                                        // }
                                    }}>
                                        <Box className="left-sections" sx={{
                                            backgroundColor: '#202431',
                                            padding: {
                                                xs: '10px',
                                                md: '50px'
                                            },
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: {
                                                xs: 'center',
                                                md: 'flex-start'
                                            },
                                            // alignItems: 'flex-start',

                                            mr: {
                                                xs: 'unset',
                                                md: '50px'
                                            },
                                            // maxWidth: '100%'
                                            width: '100%'
                                        }}>
                                            {/* <h2>Registration</h2> */}
                                            <Typography variant='h2' sx={{
                                                fontWeight: 'bold',
                                                mb: '20px',
                                                textAlign: 'center',
                                                background: 'linear-gradient(90deg, #FFCD77, #3c4ef6)',
                                                backgroundClip: 'text',
                                                '-webkit-text-fill-color': 'transparent',
                                            }}>
                                                Registration
                                            </Typography>

                                            {isRegister && (
                                                <form style={{ width: '100%' }}>
                                                    <Box className="input-containers" sx={{
                                                        display: 'flex',
                                                        justifyContent: {
                                                            xs: 'center',
                                                            md: 'flex-start'
                                                        },
                                                        mr: {
                                                            xs: 'unset',
                                                            md: '250px'
                                                        },
                                                        mt: '5px',
                                                        maxWidth: '100vw'

                                                    }}>
                                                        <div className="inputBoxs">
                                                            <input
                                                                type="text"
                                                                name="firstname"
                                                                value={values.firstname}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                required
                                                                // autoComplete="off"
                                                                className={`input-field ${touched.firstname && errors.firstname ? "input-error" : ""}`}
                                                            />
                                                            <span className="user">First Name</span>
                                                            {touched.firstname && errors.firstname && (
                                                                <p className="error-text">{errors.firstname}</p>
                                                            )}
                                                        </div>

                                                        <div className="inputBoxs">
                                                            <input
                                                                type="text"
                                                                name="lastname"
                                                                value={values.lastname}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                required
                                                                // autoComplete="off"
                                                                className={`input-field ${touched.lastname && errors.lastname ? "input-error" : ""}`}
                                                            />
                                                            <span className="user">Last Name</span>
                                                            {touched.lastname && errors.lastname && (
                                                                <p className="error-text">{errors.lastname}</p>
                                                            )}
                                                        </div>

                                                    </Box>
                                                    <Box className="input-containers" sx={{
                                                        display: 'flex',
                                                        justifyContent: {
                                                            xs: 'center',
                                                            md: 'flex-start'
                                                        },
                                                        mr: {
                                                            xs: 'unset',
                                                            md: '250px'
                                                        },
                                                        mt: '5px',
                                                        maxWidth: '100vw'
                                                    }}>
                                                        <div className="inputBoxs">
                                                            <input
                                                                type="email"
                                                                name="email"
                                                                value={values.email}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                required
                                                                // autoComplete="off"
                                                                className={`input-field ${touched.email && errors.email ? "input-error" : ""}`}
                                                            />
                                                            <span className="user">Email</span>
                                                            {touched.email && errors.email && (
                                                                <p className="error-text">{errors.email}</p>
                                                            )}
                                                        </div>


                                                        <div className="inputBoxs">
                                                            <input
                                                                type="tel"
                                                                name="tel"
                                                                value={values.tel}
                                                                onBlur={handleBlur}
                                                                onChange={(e) => {
                                                                    const { value } = e.target;
                                                                    const numericValue = value.replace(/[^0-9]/g, ''); // กรองเฉพาะตัวเลข
                                                                    handleChange({
                                                                        target: { name: "tel", value: numericValue },
                                                                    });
                                                                }}
                                                                required
                                                                autoComplete="off"
                                                                className={`input-field ${touched.tel && errors.tel ? "input-error" : ""}`}
                                                                inputMode="numeric"
                                                                pattern="[0-9]*"
                                                            />
                                                            <span>Tel</span>
                                                            {touched.tel && errors.tel && (
                                                                <p className="error-text">{errors.tel}</p>
                                                            )}
                                                        </div>

                                                    </Box>

                                                    <FormControl component="fieldset" fullWidth sx={{ gridColumn: "span 6", mb: 2 }}>
                                                        <div className="options">
                                                            <div className="package1">
                                                                {/* <h3>Package :</h3> */}
                                                                <Typography variant='h3' sx={{
                                                                    color: 'white', textAlign: 'start', fontSize: '1.3rem', ml: {
                                                                        xs: '40px',
                                                                        md: '70px'
                                                                    },
                                                                    mr: '10px'
                                                                }}>Package: </Typography>
                                                            </div>
                                                            <div className="package">
                                                                <div className="uv-checkbox-wrapper">
                                                                    {/* Checkbox รายเดือน */}
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            id="monthly-checkbox"
                                                                            className="uv-checkbox"
                                                                            checked={values.plan === "monthly"}
                                                                            onChange={(e) => {
                                                                                setFieldValue("plan", e.target.checked ? "monthly" : "annually");
                                                                                const selectedPackage = Array.isArray(values.packages) ? values.packages : [];
                                                                                setFieldValue("total_price", updateTotalPrice(selectedPackage, e.target.checked ? "monthly" : "annually"));
                                                                            }}
                                                                        />
                                                                        <label htmlFor="monthly-checkbox" className="uv-checkbox-label">
                                                                            <div className="uv-checkbox-icon">
                                                                                <svg viewBox="0 0 24 24" className="uv-checkmark">
                                                                                    <path d="M4.1,12.7 9,17.6 20.3,6.3" fill="none"></path>
                                                                                </svg>
                                                                            </div>
                                                                            <span className="uv-checkbox-text">รายเดือน</span>
                                                                        </label>
                                                                    </div>

                                                                    {/* Checkbox รายปี */}
                                                                    <div>
                                                                        <input
                                                                            type="checkbox"
                                                                            id="yearly-checkbox"
                                                                            className="uv-checkbox"
                                                                            checked={values.plan === "annually"}
                                                                            onChange={(e) => {
                                                                                setFieldValue("plan", e.target.checked ? "annually" : "monthly");
                                                                                const selectedPackage = Array.isArray(values.packages) ? values.packages : [];
                                                                                setFieldValue("total_price", updateTotalPrice(selectedPackage, e.target.checked ? "annually" : "monthly"));
                                                                            }}
                                                                        />
                                                                        <label htmlFor="yearly-checkbox" className="uv-checkbox-label">
                                                                            <div className="uv-checkbox-icon">
                                                                                <svg viewBox="0 0 24 24" className="uv-checkmark">
                                                                                    <path d="M4.1,12.7 9,17.6 20.3,6.3" fill="none"></path>
                                                                                </svg>
                                                                            </div>
                                                                            <span className="uv-checkbox-text">รายปี</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* List of options */}
                                                        <Box className="checkbox-container" sx={{
                                                            display: 'grid',
                                                            gridTemplateColumns: 'repeat(3, 1fr)',
                                                            gap: '10px',
                                                            mt: '5px',
                                                            maxWidth: {
                                                                xs: '80vw',
                                                                md: '500px'
                                                            }
                                                        }}>
                                                            {packages.map((pack) => (
                                                                <Box className="content22" key={pack.role_id}>
                                                                    <label className="checkBox">
                                                                        <input
                                                                            type="checkbox"
                                                                            id={pack.role_id}
                                                                            checked={Array.isArray(values.packages) && values.packages.includes(pack.role_id)}
                                                                            onChange={(e) => {
                                                                                const { checked } = e.target;
                                                                                let selectedPackage;
                                                                                if (!Array.isArray(values.packages)) {
                                                                                    selectedPackage = checked ? [pack.role_id] : [];
                                                                                } else {
                                                                                    selectedPackage = checked
                                                                                        ? [...values.packages, pack.role_id]
                                                                                        : values.packages.filter((p) => p !== pack.role_id);
                                                                                }
                                                                                setFieldValue("packages", selectedPackage);
                                                                                setFieldValue("total_price", updateTotalPrice(selectedPackage, values.plan || "monthly"));
                                                                            }}
                                                                        />
                                                                        <div className="transition"></div>
                                                                    </label>
                                                                    <div className="text-desc">
                                                                        <label htmlFor={pack.role_id} className="text">
                                                                            {pack.name}
                                                                        </label>
                                                                        <div className="checkbox-price">
                                                                            {pack.package_price[values.plan || "monthly"].toLocaleString()}฿
                                                                        </div>
                                                                    </div>
                                                                </Box>
                                                            ))}
                                                            <div className="totalprice">
                                                                {/* แสดงข้อความ "Package total price" ถ้ายังไม่เลือกอะไร */}
                                                                <span className="price">
                                                                    {values.total_price === 0 ? "total price" : values.total_price.toLocaleString() + "฿"}
                                                                </span>
                                                            </div>

                                                            <button className="button" onClick={() => document.getElementById('image-upload-button').click()}>
                                                                <span className="button-content">Upload Slip</span>
                                                            </button>
                                                            <VisuallyHiddenInput
                                                                type="file"
                                                                id="image-upload-button"
                                                                onChange={handleFileChange(setFieldValue)}
                                                                accept="image/*"
                                                                name="slip_image"
                                                            />

                                                        </Box>

                                                        {touched.packets && errors.packets && (
                                                            <Box sx={{ color: 'error.main', mt: 1 }}>{errors.packets}</Box>
                                                        )}
                                                    </FormControl>


                                                    <Box className="card" sx={{
                                                        width: '100%', maxWidth: '430px',
                                                        height: '60px',
                                                        backgroundColor: '#353535',
                                                        borderRadius: '20px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'left',
                                                        transition: '0.5s ease-in-out',
                                                        ml: {
                                                            xs: '10px',
                                                            md: '50px'
                                                        },
                                                        mr: {
                                                            xs: '10px',
                                                            md: 'unset'
                                                        },
                                                        boxShadow: '#6253e1 0px 2px 2px 0px',
                                                    }}>
                                                        <div className="img">

                                                        </div>
                                                        <div className="textBox">
                                                            {/* <div className="textContent">
                                                                <p className="h1">
                                                                    <span className="span10">โอนผ่านบัญชีธนาคาร</span>
                                                                    เลขที่บัญชี 047-2-27169-7</p>

                                                            </div> */}
                                                            <Box>
                                                                <Typography variant='body1' sx={{ fontSize: '15px', color: '#e5bf13', fontWeight: 'bold', fontFamily: 'nunito' }}>
                                                                    โอนผ่านบัญชีธนาคาร
                                                                </Typography>
                                                                <Typography variant='body1' sx={{ fontSize: '15px', fontWeight: 'bold', fontFamily: 'nunito' }}>
                                                                    เลขที่บัญชี 047-2-27169-7
                                                                </Typography>
                                                            </Box>
                                                            <p className="p">MR.CHALEARMPOL NEAMSRI</p>
                                                            <div></div>
                                                        </div>
                                                    </Box>

                                                    <div className="buttonRegister">
                                                        <button className="btn-hover1 color-7" onClick={handleSubmit}>
                                                            REGISTER
                                                        </button>
                                                        <button
                                                            className="btn-hover2 color-7"
                                                            onClick={() => {
                                                                signUpClose();
                                                                resetForm();
                                                            }}
                                                        >
                                                            CANCEL
                                                        </button>
                                                    </div>

                                                </form>
                                            )}
                                        </Box>

                                        <Box className="right-section" sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'unset'
                                            }
                                        }}>
                                            <Rocket />
                                        </Box>
                                    </Box>
                                    <div className="shape11"></div>
                                    <div className="shape22"></div>
                                    <div className="shape33"></div>
                                    <div className="shape44"></div>
                                    <div className="shape55"></div>
                                    <div className="shape66"></div>
                                    <div className="shape77"></div>
                                </div>




                                {/* <DialogActions>
                                    <Button
                                        type="submit"
                                        sx={{
                                            m: "0rem 0",
                                            // p: "1rem",
                                            backgroundColor: '#2664b1',//palette.primary.main,
                                            color: palette.primary.contrastText,
                                            "&:hover": { color: palette.primary.main },
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {isLogin ? "LOGIN" : "REGISTER"}
                                    </Button>
                                    <Button onClick={() => {
                                        signUpClose()
                                        resetForm()
                                    }}>Close</Button>
                                </DialogActions> */}
                            </Dialog>

                        </Box>


                        <Typography
                            onClick={() => {
                                setPageType(isLogin ? "register" : "login");
                                resetForm();
                            }}
                            sx={{
                                textDecoration: "underline",
                                color: palette.primary.main,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                            }}
                        >
                            {isLogin
                                ? ""
                                : ""}
                            {/* {isLogin
                                ? "ไม่มีบัญชีผู้ใช้? ลงทะเบียนที่นี่"
                                : "มีบัญชีผู้ใช้อยู่แล้ว? เข้าสู่ระบบที่นี่"} */}
                        </Typography>

                        {/* <div id="signInDiv"></div> */}


                    </Box>

                    <Box>
                        <div>
                            <Dialog
                                open={openDialogOtp}
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={handleCloseDialogOtp}
                                aria-describedby="alert-dialog-slide-description"
                                maxWidth={"xs"}
                                fullWidth={true}
                                sx={{
                                    zIndex: theme.zIndex.modal,

                                }}
                            >
                                <DialogTitle
                                    sx={{
                                        fontSize: "24px",
                                        backgroundImage: "linear-gradient(to right, #FFCD77, #A672C1)",

                                    }}
                                >
                                    {"กรุณากรอก OTP !"}
                                </DialogTitle>
                                <DialogContent
                                    sx={{ backgroundImage: "linear-gradient(to right, #FFCD77, #A672C1)", }}
                                >
                                    <DialogContentText id="alert-dialog-slide-description"></DialogContentText>

                                    <Box display="flex" justifyContent="center" alignItems="center"
                                    >
                                        {otp.map((data, index) => (
                                            <TextField
                                                // disabled={otpVerify === false ? true : false}
                                                key={index}
                                                // type="text"
                                                type="tel"  // ใช้ type="tel" เพื่อช่วยให้มือถือบางรุ่นแสดงแป้นพิมพ์ตัวเลข
                                                inputMode="numeric"  // บอกเบราว์เซอร์ให้ใช้คีย์บอร์ดตัวเลข
                                                pattern="[0-9]*"  // บังคับให้รับแค่ตัวเลข
                                                inputProps={{
                                                    maxLength: 1,
                                                    style: {
                                                        textAlign: 'center',
                                                        color: otpVerify === false ? 'red' : '#333',

                                                    }
                                                }}
                                                value={data}
                                                onChange={e => handleChangeOTP(e.target, index)}
                                                onKeyDown={e => handleKeyDown(e, index)}
                                                onKeyUp={e => handleKeyUp(e, index)}
                                                inputRef={el => (inputRefs.current[index] = el)}
                                                autoComplete="off"
                                                sx={{
                                                    marginRight: index < 6 - 1 ? 1 : 0, width: '40px',
                                                    background: '#D7AE97',
                                                    borderRadius: '15px',
                                                    border: 0,

                                                    // border: otpVerify === false ? '1px solid red' : '1px solid #cccccc',
                                                    padding: '10px',
                                                    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2), 2px 0px 3px rgba(0, 0, 0, 0.5)',

                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '30px',
                                                        background: 'inherit',
                                                        '& fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&:hover fieldset': {
                                                            border: 'none',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: 'none',

                                                        },
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        padding: 0,
                                                        textAlign: 'center',
                                                        fontSize: '1rem',
                                                        fontWeight: 600,
                                                        letterSpacing: '1px',
                                                        color: '#333',
                                                    },

                                                    // '& .MuiOutlinedInput-root': {
                                                    //     borderRadius: '8px',
                                                    //     background: 'inherit',
                                                    //     border: 'none',
                                                    //     '&:hover fieldset': {
                                                    //         border: 'none',
                                                    //     },
                                                    //     '&.Mui-focused fieldset': {
                                                    //         border: 'none',
                                                    //     },
                                                    // },

                                                }}

                                            />
                                        ))}
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        {otpVerify === false && (
                                            <Typography variant="body2" color="error">
                                                OTP verification failed. Please check your OTP.
                                            </Typography>
                                        )}
                                    </Box>
                                </DialogContent>

                            </Dialog>
                        </div>
                    </Box>



                    <Dialog
                        open={open}
                        onClose={forgetPasswordClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" fontSize='24px'>
                            {"Forget your ID or password?"}
                        </DialogTitle>
                        <DialogContent>
                            <Box
                                width='70vw'
                                height='7vh'
                                sx={{
                                    width: {
                                        xs: '70vw',
                                        sm: '50vw',
                                        md: '30vw',
                                        lg: '30vw'
                                    },
                                    height: {
                                        xs: '7vh',
                                        sm: '5vh',
                                        md: '5vh',
                                        lg: '7vh'
                                    }
                                }}

                            >
                                <Typography color='white' fontSize='16px'>
                                    <p style={{
                                        margin: '0',
                                        padding: '0',
                                    }}>Your ID is: your  <span style={{ fontSize: '20px', color: '#3445DD', fontWeight: 'bold', paddingLeft: '5px' }}>Email address.</span></p>
                                </Typography>
                                <Typography color='white' fontSize='16px'>
                                    <p style={{
                                        margin: '0',
                                        padding: '0'
                                    }}>Your Password is: your  <span style={{ fontSize: '20px', color: '#3445DD', fontWeight: 'bold', paddingLeft: '5px' }}>Phone number.</span></p>
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={forgetPasswordClose}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <div>
                        <Backdrop
                            sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 9999,
                            }}
                            open={openBackdrop}
                            onClick={handleCloseBackdrop}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </div>
                </form>
            )
            }
        </Formik >
    )



}

export default Form;