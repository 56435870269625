import React, { useState, useEffect, useRef, createRef, useContext } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem, useProSidebar, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, useMediaQuery, Popper, Grow, Paper, Avatar, Button, Switch, Dialog, DialogActions, DialogContent, DialogTitle, } from "@mui/material";
import { Link, NavLink, Navigate, useLocation } from "react-router-dom";
// import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";


import ChatIcon from '@mui/icons-material/Chat';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import { useDispatch, useSelector } from "react-redux";
import { setLeftMenu } from "../../state";

import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import LeftArrowSvg from '../../images/sidebar/sidebar-left.svg';

import RightArrowSvg from '../../images/sidebar/sidebar-right.svg';

import GroupsIcon from '@mui/icons-material/Groups';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Cookies from "js-cookie";
import { styled } from '@mui/system';

import TimelineIcon from '@mui/icons-material/Timeline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Profile from '@mui/icons-material/AccountCircle';

import { ColorModeContext } from "../../theme";


const ItemNew1 = ({ title, to, icon, selected, setSelected, isMobile, user, permissionKey, IsSideBarAppear }) => {

    // console.log(permissionKey, user.permissions.permission, user.permissions.permission[permissionKey])
    // console.log((
    //   !user ||
    //   !user.permissions ||
    //   !user.permissions.permission ||
    //   !user.permissions.permission[permissionKey] ||
    //   !user.permissions.permission[permissionKey].read
    // ))
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { collapseSidebar, collapsed } = useProSidebar();
    // const [isSubscriber, setIsSubscriber] = useState(false);
    // const location = useLocation();
    // console.log((location.pathname), (selected), (to));
    // console.log("Check Active Menu", selected == to)


    const textStyle = {
        fontSize: IsSideBarAppear ? '1rem' : '0.8rem', // Set the desired font size
        textAlign: IsSideBarAppear ? 'normal' : 'center',
        // padding: '10px', // Set the desired padding
        // color: setSelected ? colors.primary[400] : 'red',
        fontFamily: 'Sora, sans-serif',
        fontWeight: '300',
        fontStyle: 'normal',
        //color: '#3083FF',
        display: 'flex',
        flexDirection: IsSideBarAppear ? 'row' : 'column',
        alignItems: 'center',
        justifyContent: IsSideBarAppear ? 'flex-start' : 'center',
        gap: IsSideBarAppear ? '1rem' : '0',

    };
    const handleItemClick = (event) => {
        setSelected(to);
        isMobile ? collapseSidebar(true) : collapseSidebar(false);
    };

    // console.log('Breakpoints:', theme.breakpoints.values);
    let isSubscriber = false;
    if (
        !user ||
        !user.permissions ||
        !user.permissions.permission ||
        !user.permissions.permission[permissionKey] ||
        !user.permissions.permission[permissionKey].read
    ) {
        // return <Navigate to="/401" replace />;
        // setIsSubscriber(true);
        isSubscriber = true;
        // to = 'upgrade';
    }

    const Ribbon = styled('div')(({ theme }) => ({
        right: IsSideBarAppear ? '1vw' : '0vw',
        width: '50px', // Default size
        height: '50px',
        backgroundColor: 'red',
        color: 'white',
        position: 'absolute',
        // top: '0.5rem',
        top: '0rem',
        // right: '-10px',
        // transform: 'rotate(45deg)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.5rem',
        fontWeight: '300',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            width: IsSideBarAppear ? '60px' : '50px',
            height: IsSideBarAppear ? '20px' : '15px',
            fontSize: IsSideBarAppear ? '0.45rem' : '0.55rem',
        },
        [theme.breakpoints.up('md')]: {
            width: IsSideBarAppear ? '60px' : '50px',
            height: IsSideBarAppear ? '20px' : '15px',
            fontSize: '0.55rem',
        },
        [theme.breakpoints.up('lg')]: {
            width: IsSideBarAppear ? '70px' : '50px',
            height: IsSideBarAppear ? '20px' : '15px',
            fontSize: IsSideBarAppear ? '0.7rem' : '0.55rem',
        },
    }));

    const NavLinkWithRibbon = styled(NavLink)(({ theme }) => ({
        position: 'relative',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        // left: '1vw',
        '&.active': {
            borderRadius: '15px',
            color: 'white',
        },
        marginTop: '3px'
    }));

    const StyledBox = styled(Box)(({ theme, selected, title, colors }) => ({
        // backgroundColor: isSubscriber ? '#E5E5E5' : 'white',
        // backgroundImage: selected == to ? 'linear-gradient(90deg, #3083FF, #1D4E99)' : 'white',
        backgroundColor: selected == to ? theme.palette.background.default : 'transparent',
        borderRadius: '15px 0 0 15px',
        color: selected == to ? theme.palette.text.sidebar : "white",


        position: 'relative',
        width: '100%',
        // padding: '1.5vh 4rem 1.5vh 3vw',
        // [theme.breakpoints.between('sm', '770')]: {
        //     padding: '1.3vh 3.5rem 1.3vh 3.2vw',
        // },
        // [theme.breakpoints.between('sm', '900')]: {
        //     padding: '1vh 1rem 1vh 3vw',
        // },
        // [theme.breakpoints.between('md', '1030')]: {
        //     padding: '0.7vh 1rem 0.7vh 2.2vw',
        // },
        // [theme.breakpoints.between('md', '1200')]: {
        //     padding: '1vh 1rem 1vh 3.4vw',
        // },
        // [theme.breakpoints.between('md', '1270')]: {
        //     padding: '1vh 1rem 1vh 2vw',
        // },
        // [theme.breakpoints.between('lg', '1370')]: {
        //     padding: '1vh 1rem 1vh 2vw',
        // },
        // [theme.breakpoints.up('lg', '1400')]: {
        //     padding: '1vh 1rem 1vh 3.2vw',
        // },

        padding: IsSideBarAppear
            ? '1.5vh 4rem 1.5vh 3vw' // Sidebar เปิด
            : '1vh 1rem 1vh 1vw', // Sidebar ปิด (ลด padding)

        [theme.breakpoints.between('sm', '770')]: {
            padding: IsSideBarAppear ? '1.3vh 3.5rem 1.3vh 3.2vw' : '0.8vh 0rem 0.8vh 0vw',
        },
        [theme.breakpoints.between('sm', '900')]: {
            padding: IsSideBarAppear ? '1vh 1rem 1vh 3vw' : '0.7vh 0rem 0.7vh 0vw',
        },
        [theme.breakpoints.between('md', '1030')]: {
            padding: IsSideBarAppear ? '0.7vh 1rem 0.7vh 2.2vw' : '0.5vh 0rem 0.5vh 0vw',
        },
        [theme.breakpoints.between('md', '1200')]: {
            padding: IsSideBarAppear ? '1vh 1rem 1vh 3.4vw' : '0.7vh 0rem 0.7vh 0vw',
        },
        [theme.breakpoints.between('md', '1270')]: {
            padding: IsSideBarAppear ? '1vh 1rem 1vh 2vw' : '0.7vh 0rem 0.7vh 0vw',
        },
        [theme.breakpoints.between('lg', '1370')]: {
            padding: IsSideBarAppear ? '1vh 1rem 1vh 2vw' : '0.8vh 0rem 0.8vh 0vw',
        },
        [theme.breakpoints.up('lg', '1400')]: {
            padding: IsSideBarAppear ? '1vh 1rem 1vh 1.5vw' : '0.8vh 0rem 0.8vh 0vw',
        },


        display: 'flex',
        alignItems: 'center',
        justifyContent: IsSideBarAppear ? 'flex-start' : 'center',
        flexDirection: 'row',
        // ':hover': {
        //     backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99)',
        //     borderRadius: '15px',
        //     color: 'white !important',
        // },
        // ':after': {
        //     backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99)',
        // },
        ':hover': {
            // backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99)',
            borderRadius: IsSideBarAppear ? '15px 0 0 15px' : '20px 0 0 20px',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.sidebar,
        },
        ':after': {
            // backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99)',
            backgroundColor: theme.palette.background.default,
        },
    }));
    return (
        <NavLinkWithRibbon to={isSubscriber ? 'upgrade' : to} style={{
            position: 'relative',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            left: IsSideBarAppear ? '1vw' : '0vw'
        }}>
            <StyledBox selected={selected} title={title} colors={colors} onClick={handleItemClick} icon={icon}>
                <Typography style={textStyle}>{icon}{title}</Typography>
                {isSubscriber && <Ribbon>Subscription</Ribbon>}
            </StyledBox>
        </NavLinkWithRibbon >
    );
};

const ListItemLink = (props) => {
    const { icon, primary, to, itemStyle } = props;

    return (
        <li>
            <ListItem button component={Link} to={to}>
                {icon ? icon : null}
                <ListItemText primary={primary} style={{ ...itemStyle, marginLeft: '8px' }} />
            </ListItem>
        </li>
    );
}

const SideBarV2 = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);
    const location = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selected, setSelected] = useState(location.pathname);

    const { collapseSidebar, collapsed } = useProSidebar();
    // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const leftMenuSelect = useSelector((state) => state.leftMenu);
    const userDetail = useSelector((state) => state.userDetail);

    const { toggleColorMode } = useContext(ColorModeContext);
    const [isChecked, setIsChecked] = useState(theme.palette.mode === "dark");

    const handleToggle = () => {
        setIsChecked(!isChecked);
        toggleColorMode();
    };

    //new width model
    const sidebarWidths = {
        active: {

            [theme.breakpoints.up('lg', '1400')]: {
                width: '15vw',
                position: 'relative',
                overflowX: 'hidden',
                transition: 'width 0.25s'
            },//Higher screen

            [theme.breakpoints.between('lg', '1370')]: {
                width: '15vw',
                position: 'relative',
                overflowX: 'hidden',
                transition: 'width 0.25s'
            },//IPadProHorizontal

            [theme.breakpoints.between('md', '1200')]: {
                width: '18vw',
                position: 'relative',
                overflowX: 'hidden',
                transition: 'width 0.25s'
            },//IPadAirHorizontal
            [theme.breakpoints.between('md', '1030')]: {
                // width: '32vw',
                width: '20vw',
                position: 'relative',
                overflowX: 'hidden',
                transition: 'width 0.25s'
            },//IPadPro
            [theme.breakpoints.between('sm', '900')]: {
                width: '25vw',
                position: 'relative',
                overflowX: 'hidden',
                transition: 'width 0.25s'
            },//IPadAir
            [theme.breakpoints.between('sm', '770')]: {
                width: '31vw',
                position: 'relative',
                overflowX: 'hidden',
                transition: 'width 0.25s'
            },//IPadMini

        },
        inactive: {
            width: '5rem',
            transition: 'width 0.25s'

        }
    };

    const [SideBarActive, setSideBarActive] = useState(sidebarWidths.active);

    const [IsSideBarAppear, setIsSideBarAppear] = useState(true);

    useEffect(() => {
        const savedSidebarState = localStorage.getItem('sidebarState');
        if (savedSidebarState) {
            const parsedState = JSON.parse(savedSidebarState);
            setIsSideBarAppear(parsedState.IsSideBarAppear);
            setSideBarActive(parsedState.sideBarActive);
        }
    }, []);
    const hideSideBar = () => {
        setIsSideBarAppear(false)
        setSideBarActive(!sidebarWidths.inactive);
        localStorage.setItem('sidebarState', JSON.stringify({
            IsSideBarAppear: false,
            sideBarActive: false,
        }));
    };

    const showSideBar = () => {
        setIsSideBarAppear(true)
        setSideBarActive(!sidebarWidths.active);
        localStorage.setItem('sidebarState', JSON.stringify({
            IsSideBarAppear: true,
            sideBarActive: true,
        }));
    };



    //const [sidebarWidth, setSidebarWidth] = useState(sidebarWidths.active);

    useEffect(() => {
        // console.log(`lef menu ${leftMenuSelect}`)
        isMobile ? collapseSidebar(true) : collapseSidebar(false);
        if (isMobile) {
            // setIsSidebarHovered(!isSidebarHovered);
        }
    }, [isMobile]);

    const csrfToken = useSelector((state) => state.csrfToken);
    const isAuth = (Boolean(useSelector((state) => state.token))) && (Boolean(Cookies.get("access_token")));
    const userId = useSelector((state) => state.user);

    useEffect(() => {
        // console.log(isAuth, csrfToken, userId)
        // console.log(userDetail)
    }, [userDetail]);


    const [isSidebarHovered, setIsSidebarHovered] = useState(false);
    const [isClickCollapsed, setIsClickCollapsed] = useState(false);
    const handleSidebar = () => {
        if (isSidebarHovered && !collapsed) {
            collapseSidebar(false);
            setIsSidebarHovered(false);
        } else {
            collapseSidebar();
            setIsSidebarHovered(!isSidebarHovered);
        }
        setIsClickCollapsed(false);
    }
    const handleMouseEnter = () => {
        setIsClickCollapsed(true);
        if (isClickCollapsed) {
            if (isSidebarHovered && collapsed) {
                collapseSidebar(false);

            }
        }
    };
    const handleMouseLeave = () => {
        if (isSidebarHovered && !collapsed) {
            collapseSidebar(true);
            // if (sidebarRef.current) {
            //   // console.log('Sidebar width:', sidebarRef.current.clientWidth);
            //   // setDivPosition({ left: sidebarRef.current.clientWidth + 1 });
            //   console.log('Sidebar width:', sidebarRef.current.clientWidth);
            // }
            // setDivPosition({ left: 10 });
        }
    };


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [open1, setOpen1] = useState(false);

    const handleOpen = () => {
        console.log('Modal opening');
        setOpen1(true);  // เปิด Modal
    };

    const handleClose1 = () => {
        setOpen1(false); // ปิด Modal
    };

    const handleConfirmLogout = () => {
        // console.log("Logging out...");
        // ทำการ Logout เช่น redirect หรือเรียก API
        window.location.href = "/logout"; // ตัวอย่าง redirect
    };


    const [showDiv, setShowDiv] = useState(false);

    const toggleDiv = () => {
        setShowDiv(!showDiv);
    };



    const [divPosition, setDivPosition] = useState({ left: 0, top: 0 });
    const iconButtonRef = useRef();
    const sideDivRef = useRef();
    const sidebarRef = useRef(null);

    useEffect(() => {
        sidebarRef.current = sidebarRef.current;
    }, [sidebarRef]);

    useEffect(() => {
        if (showDiv && iconButtonRef.current) {
            const rect = iconButtonRef.current.getBoundingClientRect();
            setDivPosition({ left: rect.left + rect.width, top: rect.top + 5 });
        }
    }, [showDiv]);

    useEffect(() => {
        // const handleClickOutside = (event) => {
        //   if (showDiv) {
        //     setShowDiv(false);
        //   }
        // };

        // document.addEventListener('mousedown', handleClickOutside);
        // return () => {
        //   document.removeEventListener('mousedown', handleClickOutside);
        // };
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.target === sidebarRef.current) {
                    const width = entry.contentRect.width;
                    setDivPosition(prevData => ({
                        ...prevData,
                        left: width
                    }));
                    if (isSidebarHovered && !collapsed) {
                        if (showDiv && iconButtonRef.current) {
                            const rect = iconButtonRef.current.getBoundingClientRect();
                            setDivPosition({ left: rect.left + rect.width, top: rect.top + 5 });
                        }
                    }
                }
            }
        });

        if (sidebarRef.current) {
            resizeObserver.observe(sidebarRef.current);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, [collapsed]);


    return (
        <Box sx={{ position: 'relative' }}>


            <Box
                sx={{
                    "& .pro-sidebar-inner": {
                        background: `${colors.primary[400]} !important`,
                    },
                    "& .pro-icon-wrapper": {
                        backgroundColor: "transparent !important",
                    },
                    "& .pro-inner-item": {
                        padding: "5px 35px 5px 20px !important",
                    },
                    "& .pro-inner-item:hover": {
                        color: "#868dfb !important",
                    },
                    "& .pro-menu-item.active": {
                        color: "#6870fa !important",
                    },

                    marginLeft: isSidebarHovered ? '3rem' : 0,
                    transition: 'margin-right 0.5s ease',


                }}
            >
                <Box
                    ref={sidebarRef}
                    collapsed={isCollapsed}

                    sx={{
                        // backgroundColor: '#202431',
                        backgroundColor: theme.palette.background.sidebar,
                        // boxShadow: '2px 12px 10.8px #ECECEC',
                        width: IsSideBarAppear ? sidebarWidths.active : sidebarWidths.inactive,
                        height: '100vh',
                        overflowY: 'auto',
                        '::-webkit-scrollbar': {
                            width: '0px',
                        },
                        zIndex: '100',
                        // transition: 'width 0.5s ease', //
                        position: isSidebarHovered ? 'relative' : 'relative',
                        top: 0,
                        left: 0,


                    }}
                    collapsedWidth='3rem'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >




                    <Menu iconShape="square" width='100%' backgroundColor={theme.palette.background.sidebar}>
                        <Box
                            className="action-controls"
                            sx={{
                                padding: '1vh 1vw',
                                display: 'flex',
                                flexDirection: IsSideBarAppear ? 'row' : 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            {/* <Typography onClick={IsSideBarAppear ? hideSideBar : showSideBar}>
                    <img
                        src={IsSideBarAppear ? LeftArrowSvg : RightArrowSvg}
                        alt="Toggle Sidebar"
                        style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                    />
                </Typography> */}

                            <Typography sx={{
                                color: 'white'
                            }} onClick={IsSideBarAppear ? hideSideBar : showSideBar}> {IsSideBarAppear ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />} </Typography>

                            <Switch
                                checked={isChecked}
                                onChange={handleToggle}
                                sx={{
                                    '& .MuiSwitch-thumb': {

                                        backgroundImage: isChecked ? 'linear-gradient(90deg, #8E8E8E, #413F3B)' : 'linear-gradient(90deg, #FEE8C1, #FFCD77)',
                                        // backgroundColor: isChecked ? "#C4C9D1" : "#ECCA2F",
                                        transition: "background-color 0.5s ease-in-out",
                                        boxShadow: '0px 0px 3px 1px #888888',
                                    },
                                    '& .MuiSwitch-track': {
                                        // backgroundColor: 'transparent',
                                        // height: '40px',
                                        backgroundColor: isChecked ? "#837E7E" : "#57CFFF",
                                        opacity: '1',
                                        boxShadow: isChecked ? '2px 1px 3px 0px inset #323232' : '1px 2px 3px 0px inset #ffffff',
                                        borderRadius: '40px',
                                    },
                                }}
                            />
                        </Box>
                        {/* LOGO AND MENU ICON */}



                        {!collapsed && (<></>
                            // <Box mb="25px">
                            //     <Box textAlign="center">
                            //         <Typography
                            //             variant="h2"
                            //             color={colors.grey[100]}
                            //             fontWeight="bold"
                            //             sx={{ m: "10px 0 0 0" }}
                            //         >
                            //             {/* Ed Roh */}
                            //         </Typography>
                            //         <Typography variant="h5" color={colors.greenAccent[500]}>
                            //             {/* VP Fancy Admin */}
                            //         </Typography>
                            //     </Box>
                            // </Box>
                        )}


                        <Box className='sidebar-logo' sx={{
                            width: '100%',
                            height: '100%'
                        }}>
                            <Box variant="h2" color='black' overflow='visible'
                                sx={{
                                    // width: {
                                    //     [theme.breakpoints.up('lg', '1400')]: {
                                    //         width: '21vw',
                                    //         left: '-0.1vw',
                                    //         marginBottom: '2.1rem',
                                    //         top: '-5vh'
                                    //     },//Higher screen

                                    //     [theme.breakpoints.between('lg', '1370')]: {
                                    //         width: '25vw',
                                    //         left: '-0.8vw',
                                    //         marginBottom: '2rem'
                                    //     },//IPadProHorizontal

                                    //     [theme.breakpoints.between('md', '1200')]: {
                                    //         width: '24vw',
                                    //         left: '0.1vw',
                                    //         marginBottom: '2rem'
                                    //     },//IPadAirHorizontal

                                    //     [theme.breakpoints.between('md', '1030')]: {
                                    //         // width: '34vw',
                                    //         width: '24vw',
                                    //         left: '-0.6vw',
                                    //         marginBottom: '2rem'
                                    //     },//IPadPro
                                    //     [theme.breakpoints.between('sm', '900')]: {
                                    //         width: '37vw',
                                    //         left: '-0.8vw',
                                    //         marginBottom: '2rem'
                                    //     },//IPadAir
                                    //     [theme.breakpoints.between('sm', '770')]: {
                                    //         width: '35vw',
                                    //         left: '-0.7vw',
                                    //         marginBottom: '2.5rem'
                                    //     },//IPadMini
                                    // },
                                    width: '100%',
                                    position: 'relative',
                                    display: IsSideBarAppear ? 'flex' : 'none',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    top: '0vh',
                                    // left: '1vw'
                                    //top: '20vh'
                                }}>
                                {/* <img src="https://i.postimg.cc/GmshdzQ6/logo-Black-01.png" alt="" width='70%' height='auto' /> */}
                                <img src="https://i.postimg.cc/TP1gJwyz/logo-white-02.png" alt="" width='70%' height='auto' />
                            </Box>
                        </Box>

                        <Divider sx={{
                            marginTop: '10px',
                            backgroundColor: '#FFCD77',
                            // width: '90%',
                            height: '0.1vh'
                        }} />

                        <Box classNme="list-items"
                        // paddingLeft={collapsed ? undefined : "5%"}
                        // sx={{
                        //     position: 'relative',
                        //     // top: '-6.2vh'
                        // }}
                        >
                            <Typography
                                variant="h5"
                                color={colors.grey[300]}
                                sx={{
                                    // m: "15px 0 5px 20px",
                                    // marginBottom: '1.5rem',
                                    // fontSize: '1rem',
                                    // fontFamily: '"Sora", sans-serif',
                                    // fontWeight: '400',
                                    // fontStyle: 'normal',
                                    // color: '#FFCD77',

                                    color: '#FFCD77',
                                    display: 'flex',
                                    justifyContent: IsSideBarAppear ? 'normal' : 'center',
                                    position: 'relative',
                                    left: IsSideBarAppear ? '1vw' : 'normal',
                                    fontSize: IsSideBarAppear ? '1rem' : '0.8rem',
                                    fontFamily: '"Sora", sans-serif',
                                    fontWeight: '400',
                                    // fontWeight: 'bold',
                                    padding: IsSideBarAppear ? '10px 0 10px 0' : '10px 0 10px 2px'
                                }}
                            >
                                Data
                            </Typography>

                            <ItemNew1
                                title='หมอดูหุ้น'
                                to="/chatbot"
                                // to="/chatbot"
                                // component={(props) => (
                                //     <Link to="/chatbot" state={{ isS50: false }} {...props} />
                                // )}
                                icon={<ChatIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'chatBot9Chanel'}
                                IsSideBarAppear={IsSideBarAppear}
                            />
                            <ItemNew1
                                title='Petroleum'
                                to="/petroleum"
                                icon={<OilBarrelIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'petroleumChart'}
                                IsSideBarAppear={IsSideBarAppear}
                            />

                            <ItemNew1
                                title="Rubber Thai"
                                to="/rubber"
                                icon={<SsidChartIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'rubberThaiChart'}
                                IsSideBarAppear={IsSideBarAppear}
                            />


                            <ItemNew1
                                title="Flow Intraday"
                                to="/realtime"
                                icon={<BarChartIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'realTimeChart'}
                                IsSideBarAppear={IsSideBarAppear}
                            />

                            <ItemNew1
                                title='S50'
                                to="/s50"
                                // to="/chatbot"
                                // component={(props) => (
                                //     <Link to="/chatbot" state={{ isS50: true }} {...props} />
                                // )}
                                icon={<ChatIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'chatBot9Chanel'}
                                IsSideBarAppear={IsSideBarAppear}
                            />

                            <ItemNew1
                                title="Gold"
                                to="/gold"
                                icon={<InsightsIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'goldChart'}
                                IsSideBarAppear={IsSideBarAppear}
                            />

                            < ItemNew1
                                title="BidAsk"
                                to="/dejavu/bidask"
                                icon={<TimelineIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'bidaskDejavu'}
                                IsSideBarAppear={IsSideBarAppear}
                            />

                            < ItemNew1
                                title="TickMatch"
                                to="/dejavu/tickmatch"
                                icon={<FormatListBulletedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'tickmatchDejavu'}
                                IsSideBarAppear={IsSideBarAppear}
                            />

                            < ItemNew1
                                title="DR"
                                to="/dr"
                                icon={<FormatListBulletedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'tickmatchDejavu'}
                                IsSideBarAppear={IsSideBarAppear}
                            />



                            {userDetail.user_type == 'employee' && (<>

                                <Divider sx={{
                                    marginTop: '5px',
                                    backgroundColor: '#FFCD77',
                                    // width: '90%',
                                    height: '0.1vh'
                                }} />
                                <Typography
                                    variant="h5"
                                    color={colors.grey[300]}
                                    sx={{
                                        // // m: "15px 0 5px 20px",
                                        // display: 'flex', justifyContent: IsSideBarAppear ? 'normal' : 'center',
                                        // marginBottom: '1.5rem',
                                        // fontSize: '1rem',
                                        // fontFamily: '"Sora", sans-serif',
                                        // fontWeight: '400',
                                        // fontStyle: 'normal',
                                        // color: '#FFCD77',
                                        color: '#FFCD77',
                                        display: 'flex',
                                        justifyContent: IsSideBarAppear ? 'normal' : 'center',
                                        position: 'relative',
                                        left: IsSideBarAppear ? '1vw' : 'normal',
                                        fontSize: IsSideBarAppear ? '1rem' : '0.7rem',
                                        fontFamily: '"Sora", sans-serif',
                                        fontWeight: '400',
                                        // fontWeight: 'bold',
                                        padding: IsSideBarAppear ? '10px 0 10px 0' : '10px 0 10px 2px'
                                    }}
                                >
                                    Administrator
                                </Typography>
                            </>)}
                            {/* {userDetail.user_type === 'employee' && (
              
              )}
              {userDetail.user_type === 'employee' && (
              
              )} */}
                            {userDetail.user_type === 'employee' && (
                                < ItemNew1
                                    title="Manage User"
                                    to="/admin/users"
                                    icon={<PeopleOutlinedIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    isMobile={isMobile}
                                    user={userDetail}
                                    permissionKey={'manageUser'}
                                    IsSideBarAppear={IsSideBarAppear}
                                />
                            )}
                            {userDetail.user_type === 'employee' && (
                                < ItemNew1
                                    title="Manage Role"
                                    to="/admin/roles"
                                    icon={<GroupsIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    isMobile={isMobile}
                                    user={userDetail}
                                    permissionKey={'manageUser'}
                                    IsSideBarAppear={IsSideBarAppear}
                                />
                            )}







                        </Box>
                        <Divider sx={{
                            marginTop: '5px',
                            backgroundColor: '#FFCD77',
                            // width: '90%',
                            height: '0.1vh'
                        }} />

                        <Box className="profile">
                            <Typography variant='5' sx={{
                                color: '#FFCD77',
                                display: 'flex',
                                justifyContent: IsSideBarAppear ? 'normal' : 'center',
                                position: 'relative',
                                left: IsSideBarAppear ? '1vw' : 'normal',
                                fontSize: IsSideBarAppear ? '1rem' : '0.8rem',
                                fontFamily: '"Sora", sans-serif',
                                fontWeight: '400',
                                // fontWeight: 'bold',
                                padding: IsSideBarAppear ? '10px 0 10px 0' : '10px 0 10px 2px'
                            }}>
                                Account</Typography>

                            <ItemNew1
                                title="My Account"
                                to="/profile"
                                icon={<PersonOutlinedIcon />}
                                selected={selected}
                                setSelected={setSelected}
                                isMobile={isMobile}
                                user={userDetail}
                                permissionKey={'chatBot9Chanel'}
                                IsSideBarAppear={IsSideBarAppear}
                            />

                            <Box
                                onClick={handleOpen}>
                                <ItemNew1
                                    title="Logout"
                                    to="#"
                                    icon={<LogoutIcon />}
                                    selected={selected}
                                    setSelected={setSelected}
                                    isMobile={isMobile}
                                    user={userDetail}
                                    permissionKey={'chatBot9Chanel'}
                                    IsSideBarAppear={IsSideBarAppear}
                                />
                            </Box>




                        </Box>
                    </Menu>
                </Box>
                <Dialog open={open1} onClose={handleClose1}>
                    <DialogTitle>< Typography variant='h4'>ออกจากระบบ?</Typography></DialogTitle>
                    <DialogContent>
                        Are you sure you want to logout?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose1} sx={{ color: theme.palette.text.primary }}>Cancel</Button>
                        <Button onClick={handleConfirmLogout} sx={{ backgroundColor: colors.redAccent[500], color: 'white' }}>Logout</Button>
                    </DialogActions>
                </Dialog>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 99999
                    }}
                >
                    {
                        showDiv && (
                            <Box
                                className="sideDiv"
                                sx={{
                                    position: 'absolute',
                                    width: '200px',
                                    height: '150px',
                                    top: divPosition.top,
                                    left: divPosition.left,
                                    fontSize: '10px'
                                }}
                            >

                                <Card sx={{ minWidth: 275 }}>
                                    <CardContent
                                        style={{
                                            // backgroundColor: 'red',
                                            padding: 0
                                        }}>

                                        <MenuList
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            // onKeyDown={handleListKeyDown}
                                            style={{
                                                padding: 0
                                            }}
                                        >

                                            <li>
                                                <ListItem >
                                                    <ListItemText
                                                        primary={<Typography style={{ marginLeft: '8px' }} variant="h3" gutterBottom>IDEATRADE</Typography>}
                                                    />
                                                </ListItem>
                                            </li>
                                            <Divider variant="middle" component="li" />
                                            <ListItemLink
                                                to="/profile"
                                                primary={
                                                    <Typography>Account</Typography>
                                                }
                                                icon={<PersonOutlinedIcon />}
                                            />
                                            <ListItemLink
                                                to="/logout"
                                                primary={
                                                    <Typography>Logout</Typography>
                                                }
                                                icon={<LogoutIcon style={{ color: 'red' }} />}
                                                itemStyle={{ color: 'red' }}
                                            />
                                        </MenuList>
                                    </CardContent>

                                </Card>
                            </Box>
                        )

                    }



                </div>





            </Box >
        </Box >




    );
};

export default SideBarV2;