// FullscreenPageRealflow.js

import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useIsMobile, useIsTablet, useIsPortraitTablet } from '../../components/hooks';
import { Box, Typography, useTheme } from '@mui/material';
import FontLoader from '../../components/FontLoader';
// import axios from 'axios';

let colorIndex = 0;
const softDarkColors = [
  "#ffcc00", // เหลืองสดใส (Vivid Yellow)
  "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
  "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
  "#ff9966", // ส้มสด (Bright Orange)
  "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
  "#ffffff", // ขาว (White)
  "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
  "#ff66b3", // ชมพูสดใส (Hot Pink)
  "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
  "#3399ff", // ฟ้าสดใส (Sky Blue)
  "#ff9933", // ส้มอ่อนสด (Bright Peach)
  "#ff3399", // ชมพูบานเย็น (Magenta Pink)
  "#33ff66", // เขียวสด (Vivid Green)
  "#ff9966", // พีชสดใส (Peach Orange)
  "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
  "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
  "#66ccff", // ฟ้าสดกลาง (Electric Blue)
  "#33ccff", // ฟ้าสด
  "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
  "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
];

const getRandomColor = () => {
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
  return color;
};

const earthToneColor = '#D2B48C';
const getOppositeColor = () => '#0d0082';

const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

const FullscreenPageRealflow = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const market = params.get('market');
  const type = params.get('type');
  const title = params.get('title');

  const fullScreenChartContainerRef = useRef(null);
  const fullScreenChartRef = useRef(null);

  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());
  // For tracking highlight colors in the current update:
  const highlightColorsRef = useRef({});

  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef({});
  const [lastRankChange, setLastRankChange] = useState({});
  const lastRankChangeRef = useRef({});
  const [selectedModalSymbol, setSelectedModalSymbol] = useState(null);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  const csrfToken = useSelector((state) => state.csrfToken);

  const theme = useTheme();

  // Decide whether to invert the values for type "out"
  const shouldInvert = (type === "out");

  // Keep refs in sync with state changes
  useEffect(() => {
    previousRanksRef.current = previousRanks;
  }, [previousRanks]);

  useEffect(() => {
    lastRankChangeRef.current = lastRankChange;
  }, [lastRankChange]);

  useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  // ======================
  // DATA FETCHING FUNCTIONS
  // ======================

  const [allTimeRange, setAllTimeRange] = useState({ from: null, to: null });

  const fetchHistoricalData = async () => {
    try {
      const fetch_response = await fetch(`${window.config.base_api_url}/flow/today/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      console.log(response);

      const data = response.data[type]; // 'in' or 'out'
      const historicalData = {};

      const times = Object.keys(data.rank);
      times.sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      // Build historical data
      times.forEach((time) => {
        const timestamp = Math.floor(new Date(time).getTime() / 1000);

        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const rawValue = data.value[time][symbol];
          const finalValue = shouldInvert ? -1 * rawValue : rawValue;

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: finalValue,
          });
        });
      });

      const newPreviousRanks = {};
      let lastChangeTime = -Infinity;
      const rankChanges = []; // {symbol, time, oldRank, newRank}

      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null;
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);
        const latestRank = symbolData[symbolData.length - 1].rank;
        newPreviousRanks[symbol] = latestRank;

        // Track rank changes
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            const changeTime = symbolData[i].time;
            rankChanges.push({
              symbol,
              time: changeTime,
              oldRank: symbolData[i - 1].rank,
              newRank: symbolData[i].rank
            });
            if (changeTime > lastChangeTime) {
              lastChangeTime = changeTime;
            }
          }
        }

        // Create or update series
        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = fullScreenChartRef.current.addLineSeries({
            color: color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        const seriesData = symbolData.map((dp) => ({
          time: dp.time,
          value: dp.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      // คำนวณช่วงเวลาทั้งหมดของข้อมูล
      const allTimes = [];
      latestSymbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (symbolData && symbolData.length > 0) {
          symbolData.forEach((dataPoint) => {
            allTimes.push(dataPoint.time);
          });
        }
      });

      if (allTimes.length > 0) {
        const minTime = Math.min(...allTimes);
        const maxTime = Math.max(...allTimes);
        setAllTimeRange({ from: minTime, to: maxTime });
        if (fullScreenChartRef.current) {
          fullScreenChartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
          fullScreenChartRef.current.timeScale().fitContent();
          fullScreenChartRef.current.timeScale().applyOptions({ rightOffset: 20 });
        }
      }

      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // Remove symbols no longer in the latest data
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          fullScreenChartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      });

      if (selectedModalSymbol && !latestSymbols.includes(selectedModalSymbol)) {
        setSelectedModalSymbol(null);
      }

      // Determine highlight colors (for table rows) based on the most recent change
      const lastChanges = rankChanges.filter(rc => rc.time === lastChangeTime);
      const symbolColors = new Map();
      if (lastChangeTime !== -Infinity && lastChanges.length > 0) {
        lastChanges.forEach(change => {
          if (change.newRank < change.oldRank) {
            symbolColors.set(change.symbol, '#ccffcc');
          } else if (change.newRank > change.oldRank) {
            symbolColors.set(change.symbol, '#ffcccc');
          } else {
            symbolColors.set(change.symbol, 'transparent');
          }
        });
      }

      // Update table data
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const color = symbolColors.get(symbol) || 'transparent';

        return {
          rank: latestData.rank,
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);
      tableDataRef.current = updatedTableData;
      updateSeriesVisibility();
    } catch (error) {
      console.error(`Error fetching historical data for ${market}:`, error);
    }
  };

  const fetchCurrentData = async () => {
    try {
      if (Object.keys(previousRanksRef.current).length === 0) {
        return;
      }
      const fetch_response = await fetch(`${window.config.base_api_url}/flow/now/${market}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      const data = response.data[type]; // "in" or "out"
      const timeKey = Object.keys(data.rank)[0];
      const timestamp = Math.floor(new Date(timeKey).getTime() / 1000);

      const latestSymbols = Object.keys(data.rank[timeKey]);

      // Compare ranks for changes and assign highlight colors
      const newPreviousRanks = {};
      latestSymbols.forEach((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const oldRank = previousRanksRef.current[symbol];

        if (oldRank === undefined) {
          highlightColorsRef.current[symbol] = '#ccffcc'; // new symbol => green
        } else if (currentRank < oldRank) {
          highlightColorsRef.current[symbol] = '#ccffcc'; // green
        } else if (currentRank > oldRank) {
          highlightColorsRef.current[symbol] = '#ffcccc'; // red
        }
        newPreviousRanks[symbol] = currentRank;
      });

      // Update table data
      const updatedTableData = latestSymbols.map((symbol) => {
        const symbolData = seriesDataRef.current.get(symbol) || [];
        const latestData = symbolData[symbolData.length - 1];
        const color = highlightColorsRef.current[symbol] || 'transparent';

        return {
          rank: newPreviousRanks[symbol],
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);
      tableDataRef.current = updatedTableData;
      setPreviousRanks(newPreviousRanks);
      previousRanksRef.current = newPreviousRanks;

      // Remove missing symbols
      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(sym => !latestSymbols.includes(sym));
      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          fullScreenChartRef.current.removeSeries(series);
        }
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
        delete highlightColorsRef.current[symbol];
      });

      if (selectedModalSymbol && !latestSymbols.includes(selectedModalSymbol)) {
        setSelectedModalSymbol(null);
      }

      // Update the chart series with new data points
      latestSymbols.forEach((symbol) => {
        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: (shouldInvert ? -1 : 1) * data.value[timeKey][symbol],
        };

        let series = lineSeriesRef.current.get(symbol);
        if (series) {
          const existingData = seriesDataRef.current.get(symbol) || [];
          if (existingData.length > 0 && existingData[existingData.length - 1].time === updatePoint.time) {
            existingData[existingData.length - 1] = updatePoint;
          } else {
            existingData.push(updatePoint);
          }
          series.setData(existingData);
          seriesDataRef.current.set(symbol, existingData);
        } else {
          // new symbol: create a new series
          const color = getRandomColor();
          series = fullScreenChartRef.current.addLineSeries({
            color,
            lineWidth: 2,
            lastValueVisible: true,
            priceLineVisible: false,
            title: symbol,
          });
          series.setData([updatePoint]);
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
          seriesDataRef.current.set(symbol, [updatePoint]);
        }
      });

      // Update table data (again) if needed
      const finalTableData = latestSymbols.map((symbol) => {
        const dataArr = seriesDataRef.current.get(symbol) || [];
        const latestData = dataArr[dataArr.length - 1];
        const color = highlightColorsRef.current[symbol] || 'transparent';

        return {
          rank: newPreviousRanks[symbol],
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(finalTableData);
      tableDataRef.current = finalTableData;
      updateSeriesVisibility();
    } catch (error) {
      console.error(`Error fetching current data for ${market}:`, error);
    }
  };

  // ======================
  // CHART INITIALIZATION & RESIZING
  // ======================

  useEffect(() => {
    if (fullScreenChartContainerRef.current) {
      fullScreenChartRef.current = createChart(fullScreenChartContainerRef.current, {
        layout: { textColor: '#FFFFFF', background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' } },
        timeScale: { timeVisible: true, rightOffset: 10 },
        height: fullScreenChartContainerRef.current.offsetHeight,
        width: fullScreenChartContainerRef.current.offsetWidth,
        crosshair: {
          horizontalLine: { visible: false },
          verticalLine: { visible: true },
        },
        localization: {
          priceFormatter: (price) =>
            (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
        },
        priceScale: {
          mode: 0,
        },
        grid: {
          vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
          horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
        },
      });

      fullScreenChartRef.current.priceScale('right').applyOptions({
        textColor: 'white',
      });

      if (shouldInvert) {
        fullScreenChartRef.current.applyOptions({
          invertScale: true,
        });
      }

      fullScreenChartRef.current.applyOptions({
        localization: {
          priceFormatter: (price) =>
            (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
        },
        priceScale: {
          mode: 0,
        },
      });

      const initializeData = async () => {
        await fetchHistoricalData();
        await fetchCurrentData();
      };

      initializeData();

      const intervalID = setInterval(() => {
        fetchCurrentData();
      }, 10000);

      const resizeObserver = new ResizeObserver(() => {
        if (fullScreenChartRef.current && fullScreenChartContainerRef.current) {
          fullScreenChartRef.current.resize(
            fullScreenChartContainerRef.current.offsetWidth,
            fullScreenChartContainerRef.current.offsetHeight
          );
        }
      });

      resizeObserver.observe(fullScreenChartContainerRef.current);

      return () => {
        clearInterval(intervalID);
        resizeObserver.disconnect();
        if (fullScreenChartRef.current) {
          fullScreenChartRef.current.remove();
          fullScreenChartRef.current = null;
        }
      };
    }
  }, [market, type]);

  // ======================
  // HELPER: UPDATE SERIES VISIBILITY
  // ======================

  const updateSeriesVisibility = () => {
    if (selectedModalSymbol) {
      const selRow = tableDataRef.current.find(row => row.symbol === selectedModalSymbol);
      if (selRow && selRow.rank > 10) {
        lineSeriesRef.current.forEach((series, sym) => {
          series.applyOptions({ visible: sym === selectedModalSymbol });
        });
        return;
      }
    }
    lineSeriesRef.current.forEach((series, sym) => {
      const row = tableDataRef.current.find(r => r.symbol === sym);
      series.applyOptions({ visible: row && row.rank <= 5 });
    });
  };

  // ======================
  // INTERACTION: ROW CLICK HANDLER
  // ======================

  const handleRowClickModal = (symbol, rank) => {
    if (selectedModalSymbol === symbol) {
      // Deselect the symbol and reset series styles
      setSelectedModalSymbol(null);
      lineSeriesRef.current.forEach((series, sym) => {
        const originalColor = originalColorsRef.current.get(sym);
        series.applyOptions({
          color: originalColor,
          lineWidth: 2,
        });
      });
      updateSeriesVisibility();
    } else {
      setSelectedModalSymbol(symbol);
      const selectedSeries = lineSeriesRef.current.get(symbol);
      const data = seriesDataRef.current.get(symbol);

      if (selectedSeries && fullScreenChartRef.current) {
        // Remove and re-add to bring the series to the front
        fullScreenChartRef.current.removeSeries(selectedSeries);
        const newSeries = fullScreenChartRef.current.addLineSeries({
          color: selectedSeries.options().color,
          lineWidth: selectedSeries.options().lineWidth,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        newSeries.setData(data);
        lineSeriesRef.current.set(symbol, newSeries);

        const originalColor = originalColorsRef.current.get(symbol);
        const oppositeColor = getOppositeColor();
        newSeries.applyOptions({
          color: '#66ffff',
          lineWidth: 4,
        });
      }

      if (rank > 5) {
        // If a series with rank 11-20 is selected, show only that series.
        lineSeriesRef.current.forEach((series, sym) => {
          series.applyOptions({ visible: sym === symbol });
        });
      } else {
        // For top-10 series, highlight the selected one and fade others.
        lineSeriesRef.current.forEach((series, sym) => {
          const row = tableDataRef.current.find(r => r.symbol === sym);
          if (row && row.rank <= 5) {
            if (sym === symbol) {
              series.applyOptions({ visible: true });
            } else {
              series.applyOptions({
                color: earthToneColor,
                lineWidth: 2,
                visible: true,
              });
            }
          } else {
            series.applyOptions({ visible: false });
          }
        });
      }
    }
  };

  // ======================
  // RENDERING THE TABLE
  // ======================

  const renderTable = (startIndex, endIndex, onRowClick, selectedSym) => {
    // 1) Sort the data by rank
    const sortedData = [...tableData].sort((a, b) => a.rank - b.rank);

    // 2) Build an array of exactly (endIndex - startIndex) rows
    //    For example, if startIndex=0 and endIndex=10, this creates 10 rows: rank 1..10
    //    If startIndex=10 and endIndex=20, that's rank 11..20, etc.
    const rowCount = endIndex - startIndex; // e.g. 10
    const tableRows = Array.from({ length: rowCount }, (_, i) => {
      const rankWanted = startIndex + i + 1; // rankWanted goes from (startIndex+1) to endIndex
      // Find if there's an existing row with this rank
      const existingRow = sortedData.find((r) => r.rank === rankWanted);

      if (existingRow) {
        return existingRow;
      } else {
        // 3) Create a placeholder row if no data for that rank
        return {
          rank: rankWanted,
          symbol: '',
          value: 0,
          color: 'transparent',
          placeholder: true, // custom flag so we know this is just a mock row
        };
      }
    });

    return (
      <table
        className="rank-table"
        style={{
          fontSize: '11.5px',
          fontFamily: 'Lato, Sans-serif',
          border: '1px solid #ddd',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            <th style={{ textAlign: 'center', padding: '3px' }}>Rank</th>
            <th style={{ textAlign: 'center', padding: '3px' }}>Symbol</th>
            <th style={{ textAlign: 'center', padding: '3px' }}>Value</th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: row.color,
                cursor: row.placeholder ? 'default' : 'pointer',
                fontWeight: row.symbol === selectedSym ? 'bold' : 'normal',
                transition: 'background-color 0.3s ease',
                borderBottom: '1px solid #ddd',
              }}
              onClick={() => {
                // 4) If it's a placeholder row, do nothing on click
                if (row.placeholder) return;
                onRowClick(row.symbol, row.rank);
              }}
            >
              <td style={{ padding: '3px', textAlign: 'center' }}>{row.rank}</td>
              <td style={{ padding: '3px', textAlign: 'center' }}>
                {row.symbol || '' /* empty if placeholder */}
              </td>
              <td style={{ padding: '3px', textAlign: 'center' }}>
                {/* Show nothing or 0 if placeholder */}
                {row.value
                  ? (row.value / 1_000_000).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })
                  : ''}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };


  // ======================
  // NAVIGATION BUTTON HANDLERS
  // ======================

  const handleStartButtonClick = () => {
    if (!fullScreenChartRef.current) return;
    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;
    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });
    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: earliestTime,
        to: earliestTime + rangeDuration,
      });
    }
  };

  const handleHalfDayButtonClick = () => {
    if (!fullScreenChartRef.current) return;
    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;
    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });
    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      const middleTime = (earliestTime + latestTime) / 2;
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: middleTime - rangeDuration / 2,
        to: middleTime + rangeDuration / 2,
      });
    }
  };

  const handleEndDayButtonClick = () => {
    if (!fullScreenChartRef.current) return;
    const timeScale = fullScreenChartRef.current.timeScale();
    let latestTime = -Infinity;
    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });
    if (latestTime !== -Infinity) {
      const visibleRange = timeScale.getVisibleRange();
      const rangeDuration = visibleRange.to - visibleRange.from;
      timeScale.setVisibleRange({
        from: latestTime - rangeDuration,
        to: latestTime,
      });
    }
  };

  const handleShowAllButtonClick = () => {
    if (!fullScreenChartRef.current) return;
    const timeScale = fullScreenChartRef.current.timeScale();
    let earliestTime = Infinity;
    let latestTime = -Infinity;
    seriesDataRef.current.forEach((data) => {
      if (data.length > 0) {
        earliestTime = Math.min(earliestTime, data[0].time);
        latestTime = Math.max(latestTime, data[data.length - 1].time);
      }
    });
    if (earliestTime !== Infinity && latestTime !== -Infinity) {
      timeScale.setVisibleRange({
        from: earliestTime,
        to: latestTime,
      });
    }
  };

  // ======================
  // RENDERING THE COMPONENT
  // ======================

  return (
    <div style={{ fontFamily: 'Lato, Sans-serif', padding: '20px' }}>
      <FontLoader/>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant='h3' sx={{ fontWeight: 'bold', fontFamily: 'Lato, Sans-serif' }}>Real flow</Typography>
      </Box>
      <h3>{title}</h3>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px'
      }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button onClick={handleStartButtonClick} className="nav-button start" style={{ borderRadius: '10px', backgroundColor: '#FFCD77', color: '#000000', fontFamily: 'Lato, Sans-serif' }}>
            Start
          </button>
          <button onClick={handleHalfDayButtonClick} className="nav-button half-day" style={{ borderRadius: '10px', backgroundColor: '#FFCD77', color: '#000000', fontFamily: 'Lato, Sans-serif' }}>
            Half-Day
          </button>
          <button onClick={handleEndDayButtonClick} className="nav-button end-day" style={{ borderRadius: '10px', backgroundColor: '#FFCD77', color: '#000000', fontFamily: 'Lato, Sans-serif' }}>
            End-Day
          </button>
          <button onClick={handleShowAllButtonClick} className="nav-button show-all" style={{ borderRadius: '10px', backgroundColor: '#FFCD77', color: '#000000', fontFamily: 'Lato, Sans-serif' }}>
            Show All
          </button>
          <button onClick={handleShowAllButtonClick} className="nav-button show-all" style={{ borderRadius: '10px', backgroundColor: '#FFCD77', color: '#000000', fontFamily: 'Lato, Sans-serif' }}>
            Show All
          </button>
          {/* <button
            onClick={() => {
              if (fullScreenChartRef.current && allTimeRange.from && allTimeRange.to) {
                fullScreenChartRef.current.timeScale().setVisibleRange(allTimeRange);
              }
            }}
            className="nav-button reset-zoom"
            style={{ borderRadius: '10px', backgroundColor: '#FF5733', color: '#FFFFFF' }}
          >
            Reset Zoom
          </button> */}
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: (isMobile || isPTablet) ? 'column' : 'row',
        gap: '20px'
      }}>
        <div
          ref={fullScreenChartContainerRef}
          className="chart-container"
          style={{
            width: (isMobile || isPTablet) ? '100%' : '80%',
            height: (isMobile || isPTablet) ? '40vh' : '80vh',
            position: 'relative',
            fontFamily: 'Lato, Sans-serif'
          }}
        ></div>

        {/* Right-side Table for Desktop */}
        {(!isMobile && !isPTablet) &&
          (
            <div
              className="table-container"
              style={{
                width: '20%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                overflowY: 'auto',
                maxHeight: '80vh',
                fontFamily: 'Lato, Sans-serif',
              }}
            >
              <table className="rank-table" style={{
                fontSize: '11.5px',
                fontFamily: 'Lato, Sans-serif',
                borderCollapse: 'collapse',
                border: '1px solid #ddd'
              }}>
                <thead style={{ backgroundColor: '#0D47A1', color: 'white' }}>
                  <tr>
                    <th style={{ textAlign: 'center', padding: '5px', }}>Rank</th>
                    <th style={{ textAlign: 'center', padding: '5px', }}>Symbol</th>
                    <th style={{ textAlign: 'center', padding: '5px', }}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData
                    .sort((a, b) => a.rank - b.rank)
                    .map((row, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: row.color,
                          cursor: 'pointer',
                          fontWeight: row.symbol === selectedModalSymbol ? 'bold' : 'normal',
                          borderBottom: '1px solid #ddd'
                        }}
                        onClick={() => handleRowClickModal(row.symbol, row.rank)}
                      >
                        <td style={{ padding: '5px', textAlign: 'center', backgroundColor: '#636a7e' }}>{row.rank}</td>
                        <td style={{ padding: '5px', textAlign: 'center', backgroundColor: '#636a7e' }}>{row.symbol}</td>
                        <td style={{ padding: '5px', textAlign: 'center', backgroundColor: '#636a7e' }}>
                          {(row.value / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )
        }
        {/* For Mobile/Portrait Tablet: split table view */}
        {(isMobile || isPTablet) && (
          <div
            className="table-container"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'center',
              fontFamily: 'Lato, Sans-serif',
            }}
          >
            <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {renderTable(0, 10, handleRowClickModal, selectedModalSymbol)}
            </div>
            <div style={{ width: '40%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
              {renderTable(10, 20, handleRowClickModal, selectedModalSymbol)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FullscreenPageRealflow;
