import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useSelector } from "react-redux";
import './Hisflow.css'; // Basic styling as needed
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Box, useMediaQuery, useTheme, IconButton } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FaSearch } from 'react-icons/fa'; // นำเข้าไอคอนแว่นขยาย
import LoopIcon from '@mui/icons-material/Loop';
import FontLoader from '../../components/FontLoader';

let colorIndex = 0;
const softDarkColors = [
  "#ffcc00", // เหลืองสดใส (Vivid Yellow)
  "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
  "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
  "#ff9966", // ส้มสด (Bright Orange)
  "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
  "#ffffff", // ขาว (White)
  "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
  "#ff66b3", // ชมพูสดใส (Hot Pink)
  "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
  "#3399ff", // ฟ้าสดใส (Sky Blue)
  "#ff9933", // ส้มอ่อนสด (Bright Peach)
  "#ff3399", // ชมพูบานเย็น (Magenta Pink)
  "#33ff66", // เขียวสด (Vivid Green)
  "#ff9966", // พีชสดใส (Peach Orange)
  "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
  "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
  "#66ccff", // ฟ้าสดกลาง (Electric Blue)
  "#33ccff", // ฟ้าสด 
  "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
  "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
];

const getRandomColor = () => {
  const color = softDarkColors[colorIndex];
  colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
  return color;
};


const earthToneColor = 'rgba(181, 101, 29, 0.5)';
const getOppositeColor = () => '#ffffb3';

const formatDateString = (date) => {
  if (!(date instanceof Date) || isNaN(date)) return '';
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const adjustToLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const timezoneOffset = date.getTimezoneOffset() * 60;
  return timestamp - timezoneOffset;
};

const ChartSectionWithMultipleLines = ({ title, market, type, selectedDate }) => {
  const chartContainerRef = useRef(null);
  const chartTableContainerRef = useRef(null);
  const chartRef = useRef(null);

  const lineSeriesRef = useRef(new Map());
  const seriesDataRef = useRef(new Map());
  const originalColorsRef = useRef(new Map());

  const [tableData, setTableData] = useState([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const csrfToken = useSelector((state) => state.csrfToken);

  const [selectedSymbols, setSelectedSymbols] = useState([]);
  const [historicalData, setHistoricalData] = useState({});



  const shouldInvert = (type === "out");

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  /** Main fetch function */
  // const fetchHistoricalData = async () => {
  //   if (!selectedDate) {
  //     console.warn('No date selected, skipping fetch.');
  //     return;
  //   }

  //   const formattedDate = formatDateString(selectedDate);
  //   if (!formattedDate) {
  //     console.warn('Invalid date, skipping fetch.');
  //     return;
  //   }

  //   // e.g. /flow/hist/SET100&2024-03-12
  //   const url = `${window.config.base_api_url}/flow/hist/${market}&${formattedDate}`;
  //   let response;
  //   try {
  //     const resp = await fetch(url, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'xsrf-token': csrfToken,
  //       },
  //     });
  //     response = await resp.json();
  //   } catch (err) {
  //     console.error(`Network/JSON error fetching historical data for ${market}, date=${selectedDate}:`, err);
  //     return; // Don’t blow away the table entirely; user can see old data or a partial result
  //   }

  //   const flowData = response?.data?.[type];
  //   if (!flowData) {
  //     console.warn(`No data found for type=${type} in response`);
  //     setTableData([]); // If truly no data for that day, table is empty
  //     return;
  //   }

  //   // Gather all time slots that exist in both rank + value
  //   const allTimes = Object.keys(flowData.rank)
  //     .filter((t) => flowData.value[t])
  //     .sort();

  //   if (allTimes.length === 0) {
  //     console.warn("No valid times found for rank/value data");
  //     setTableData([]);
  //     return;
  //   }

  //   /** Build historicalData[symbol] = array of {time, rank, value} */
  //   const historicalData = {};

  //   for (const timeStr of allTimes) {
  //     const timestamp = Math.floor(new Date(timeStr).getTime() / 1000);
  //     const rankObj = flowData.rank[timeStr] || {};
  //     const valueObj = flowData.value[timeStr] || {};

  //     for (const symbol of Object.keys(rankObj)) {
  //       const rankVal = rankObj[symbol];
  //       const rawVal = valueObj[symbol];

  //       if (rankVal === undefined || rawVal === undefined) {
  //         // Just skip this symbol/time, don't crash the entire load
  //         console.warn(`Skipping ${symbol} at ${timeStr}: rank or value missing`);
  //         continue;
  //       }

  //       if (!historicalData[symbol]) {
  //         historicalData[symbol] = [];
  //       }

  //       const finalVal = shouldInvert ? -1 * rawVal : rawVal;
  //       historicalData[symbol].push({
  //         time: adjustToLocalTime(timestamp),
  //         rank: rankVal,
  //         value: finalVal,
  //       });
  //     }
  //   }

  //   /** Sort each symbol’s data by time */
  //   for (const symbol of Object.keys(historicalData)) {
  //     historicalData[symbol].sort((a, b) => a.time - b.time);
  //   }

  //   // Create or update chart series
  //   const symbolList = Object.keys(historicalData);
  //   const newPreviousRanks = {};

  //   for (const sym of symbolList) {
  //     const symData = historicalData[sym];
  //     if (!symData || symData.length === 0) {
  //       // Possibly remove an old series if it exists
  //       const existing = lineSeriesRef.current.get(sym);
  //       if (existing) {
  //         try {
  //           chartRef.current?.removeSeries(existing);
  //         } catch (e) {
  //           console.warn(`removeSeries error for symbol=${sym}`, e);
  //         }
  //         lineSeriesRef.current.delete(sym);
  //         seriesDataRef.current.delete(sym);
  //         originalColorsRef.current.delete(sym);
  //       }
  //       continue;
  //     }

  //     const latestPoint = symData[symData.length - 1];
  //     newPreviousRanks[sym] = latestPoint.rank;

  //     let series = lineSeriesRef.current.get(sym);
  //     if (!series) {
  //       // Add a new line series
  //       const color = getRandomColor();
  //       try {
  //         series = chartRef.current.addLineSeries({
  //           color,
  //           lineWidth: 2,
  //           lastValueVisible: true,
  //           priceLineVisible: false,
  //           title: sym,
  //         });
  //       } catch (e) {
  //         console.warn(`Error adding new line series for symbol=${sym}`, e);
  //         continue; // Don’t crash everything
  //       }
  //       lineSeriesRef.current.set(sym, series);
  //       originalColorsRef.current.set(sym, color);
  //     }

  //     // Set data on the series
  //     const seriesData = symData.map(dp => ({ time: dp.time, value: dp.value }));
  //     try {
  //       series.setData(seriesData);
  //     } catch (e) {
  //       console.warn(`Error setting data for symbol=${sym}`, e);
  //       // If it fails, remove the series so we don’t break chart next time
  //       chartRef.current?.removeSeries(series);
  //       lineSeriesRef.current.delete(sym);
  //       continue;
  //     }
  //     seriesDataRef.current.set(sym, seriesData);
  //   }

  //   // Remove leftover series for symbols not in symbolList
  //   const existingSymbols = Array.from(lineSeriesRef.current.keys());
  //   for (const sym of existingSymbols) {
  //     if (!symbolList.includes(sym)) {
  //       const s = lineSeriesRef.current.get(sym);
  //       if (s) {
  //         try {
  //           chartRef.current?.removeSeries(s);
  //         } catch (err) {
  //           console.warn(`removeSeries error for leftover symbol=${sym}`, err);
  //         }
  //       }
  //       lineSeriesRef.current.delete(sym);
  //       seriesDataRef.current.delete(sym);
  //       originalColorsRef.current.delete(sym);
  //     }
  //   }

  //   setPreviousRanks(newPreviousRanks);

  //   /** Build table data for each symbol’s final point */
  //   const updatedTableData = symbolList.map((symbol) => {
  //     const sData = historicalData[symbol];
  //     if (!sData || sData.length === 0) return null;
  //     const lastPoint = sData[sData.length - 1];
  //     return {
  //       rank: lastPoint.rank,
  //       symbol,
  //       value: lastPoint.value,
  //       color: 'transparent',
  //     };
  //   }).filter(Boolean);

  //   setTableData(updatedTableData);
  // };


  const fetchHistoricalData = async () => {
    if (!selectedDate) {
      console.warn('No date selected, skipping fetch.');
      return;
    }

    const formattedDate = formatDateString(selectedDate);
    if (!formattedDate) {
      console.warn('Invalid date, skipping fetch.');
      return;
    }

    // e.g. /flow/hist/SET100&2024-03-12
    const url = `${window.config.base_api_url}/flow/hist/${market}&${formattedDate}`;
    let response;
    try {
      const resp = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken,
        },
      });
      response = await resp.json();
    } catch (err) {
      console.error(`Network/JSON error fetching historical data for ${market}, date=${selectedDate}:`, err);
      return; // Don’t blow away the table entirely; user can see old data or a partial result
    }

    const flowData = response?.data?.[type];
    if (!flowData) {
      console.warn(`No data found for type=${type} in response`);
      setTableData([]); // If truly no data for that day, table is empty
      return;
    }

    // Gather all time slots that exist in both rank + value
    const allTimes = Object.keys(flowData.rank)
      .filter((t) => flowData.value[t])
      .sort();

    if (allTimes.length === 0) {
      console.warn("No valid times found for rank/value data");
      setTableData([]);
      return;
    }

    /** Build historicalData[symbol] = array of {time, rank, value} */
    const historicalData = {};

    for (const timeStr of allTimes) {
      const timestamp = Math.floor(new Date(timeStr).getTime() / 1000);
      const rankObj = flowData.rank[timeStr] || {};
      const valueObj = flowData.value[timeStr] || {};

      for (const symbol of Object.keys(rankObj)) {
        const rankVal = rankObj[symbol];
        const rawVal = valueObj[symbol];

        if (rankVal === undefined || rawVal === undefined) {
          // Just skip this symbol/time, don't crash the entire load
          console.warn(`Skipping ${symbol} at ${timeStr}: rank or value missing`);
          continue;
        }

        if (!historicalData[symbol]) {
          historicalData[symbol] = [];
        }

        const finalVal = shouldInvert ? -1 * rawVal : rawVal;
        historicalData[symbol].push({
          time: adjustToLocalTime(timestamp),
          rank: rankVal,
          value: finalVal,
        });
      }
    }

    /** Sort each symbol’s data by time */
    for (const symbol of Object.keys(historicalData)) {
      historicalData[symbol].sort((a, b) => a.time - b.time);
    }

    // Create or update chart series
    const symbolList = Object.keys(historicalData);
    const newPreviousRanks = {};

    // Handle data for 5 highest-ranked symbols
    const top5Symbols = symbolList
      .map((symbol) => ({
        symbol,
        rank: historicalData[symbol][historicalData[symbol].length - 1].rank,
      }))
      .sort((a, b) => a.rank - b.rank) // sort based on rank (ascending)
      .slice(0, 5); // only top 5

    // Update chart only for top 5 symbols
    const newLineSeries = new Map();
    top5Symbols.forEach(({ symbol }) => {
      const symData = historicalData[symbol];
      if (!symData || symData.length === 0) return;

      const latestPoint = symData[symData.length - 1];
      newPreviousRanks[symbol] = latestPoint.rank;

      let series = lineSeriesRef.current.get(symbol);
      if (!series) {
        // Add a new line series if not exists
        const color = getRandomColor();
        series = chartRef.current.addLineSeries({
          color,
          lineWidth: 2,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });
        lineSeriesRef.current.set(symbol, series);
        originalColorsRef.current.set(symbol, color);
      }

      // Set data on the series
      const seriesData = symData.map(dp => ({ time: dp.time, value: dp.value }));
      series.setData(seriesData);
      seriesDataRef.current.set(symbol, seriesData);
      newLineSeries.set(symbol, series);
    });

    // Remove series for symbols not in the top 5
    lineSeriesRef.current.forEach((series, symbol) => {
      if (!newLineSeries.has(symbol)) {
        chartRef.current?.removeSeries(series);
        lineSeriesRef.current.delete(symbol);
        seriesDataRef.current.delete(symbol);
        originalColorsRef.current.delete(symbol);
      }
    });

    // Set updated ranks and table data
    setPreviousRanks(newPreviousRanks);
    chartRef.current.timeScale().fitContent(); // Fit data to screen

    // Build table data for each symbol’s final point (only for top 20)
    const updatedTableData = symbolList.slice(0, 20).map((symbol) => {
      const sData = historicalData[symbol];
      if (!sData || sData.length === 0) return null;
      const lastPoint = sData[sData.length - 1];
      const prevPoint = sData.length > 1 ? sData[sData.length - 2] : null; // ค่าก่อนหน้า
      const pctChange = prevPoint
        ? ((lastPoint.value - prevPoint.value) / prevPoint.value) * 100
        : null; // คำนวณ % การเปลี่ยนแปลง

      return {
        rank: lastPoint.rank,
        symbol,
        value: lastPoint.value,
        color: 'transparent',
        pct_change: pctChange, // เพิ่ม % การเปลี่ยนแปลงเข้าไป

      };
    }).filter(Boolean);

    setTableData(updatedTableData);
  };


  // const handleRowClick = (symbol) => {
  //   const isSelected = selectedSymbols.includes(symbol);  // ใช้ includes แทน has

  //   if (isSelected) {
  //     // ถ้า symbol ถูกเลือกอยู่แล้ว จะเอาออกจาก selectedSymbols
  //     setSelectedSymbols(prev => prev.filter(s => s !== symbol));

  //     // ซ่อนกราฟที่ไม่ถูกเลือก
  //     lineSeriesRef.current.forEach((series, sym) => {
  //       const row = tableData.find(r => r.symbol === sym);
  //       if (row) {
  //         // ไม่จำเป็นต้องซ่อน Top 5 ทุกตัว ถ้ากราฟอยู่ใน Top 5 หรือถูกเลือก
  //         if (sym !== symbol) {
  //           series.applyOptions({ visible: false });
  //         } else {
  //           series.applyOptions({ visible: true });
  //         }
  //       }
  //     });
  //   } else {
  //     // ถ้า symbol ยังไม่ถูกเลือก จะเพิ่มเข้าไปใน selectedSymbols
  //     setSelectedSymbols(prev => [...prev, symbol]);

  //     // แสดงกราฟที่ถูกเลือก และซ่อนกราฟที่ไม่เกี่ยวข้อง
  //     lineSeriesRef.current.forEach((series, sym) => {
  //       if (sym === symbol) {
  //         series.applyOptions({ visible: true, color: getOppositeColor(), lineWidth: 4 });
  //       } else {
  //         // สำหรับกราฟที่ไม่ใช่ Symbol ที่เลือก ให้ซ่อน
  //         series.applyOptions({ visible: false });
  //       }
  //     });

  //     // ถ้า Symbol อันดับ 6-20 ถูกเลือก → ต้องเพิ่มเข้าไปถ้ายังไม่มี Series
  //     if (!lineSeriesRef.current.has(symbol)) {
  //       const color = getRandomColor();
  //       const newSeries = chartRef.current.addLineSeries({
  //         color,
  //         lineWidth: 2,
  //         lastValueVisible: true,
  //         priceLineVisible: false,
  //         title: symbol,
  //       });

  //       const symData = historicalData[symbol];
  //       const seriesData = symData ? symData.map(dp => ({ time: dp.time, value: dp.value })) : [];
  //       newSeries.setData(seriesData);

  //       lineSeriesRef.current.set(symbol, newSeries);
  //       seriesDataRef.current.set(symbol, seriesData);
  //       originalColorsRef.current.set(symbol, color);
  //     }
  //   }
  // };


  useEffect(() => {

    // เพิ่ม outline, border-radius และ overflow: hidden ให้กับ chartContainerRef
    if (chartContainerRef.current) {
      chartContainerRef.current.style.outline = "2px solid #fff"; // เปลี่ยนสีตามต้องการ
      chartContainerRef.current.style.boxSizing = "border-box"; // ป้องกัน outline เล็กกว่า chart
      chartContainerRef.current.style.borderRadius = "10px"; // ขอบมน (10px)
      chartContainerRef.current.style.overflow = "hidden"; // ซ่อนส่วนที่เกินออกจากขอบ
    }
    // Initialize chart
    chartRef.current = createChart(chartContainerRef.current, {
      layout: { textColor: 'white', background: { type: 'solid', color: '#575b69' } },
      timeScale: { timeVisible: true, rightOffset: 10 },
      height: 290,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      crosshair: {
        horizontalLine: { visible: false },
        verticalLine: { visible: true },
      },
    });





    // Invert scale for 'out'
    if (shouldInvert) {
      chartRef.current.applyOptions({ invertScale: true });
    }

    // Price format
    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: { mode: 0 },
      grid: {
        vertLines: { color: '#323856' },
        horzLines: { color: '#323856' },
      },
    });

    // เพิ่มการตั้งค่า textColor ของ price scale
    chartRef.current.priceScale('right').applyOptions({
      textColor: '#575b69', // ตั้งสีข้อความของ price scale
    });

    setTableData([]); // Clear table before fetch
    fetchHistoricalData();

    return () => {
      // Cleanup
      if (chartRef.current) {
        try {
          chartRef.current.remove();
        } catch (err) {
          // If already disposed, ignore
        }
      }
    };
  }, [selectedDate, market, type]);

  // // Highlight on row click
  // const handleRowClick = (symbol) => {
  //   if (symbol === selectedSymbol) {
  //     // Deselect
  //     setSelectedSymbol(null);
  //     lineSeriesRef.current.forEach((series, sym) => {
  //       const origColor = originalColorsRef.current.get(sym);
  //       series.applyOptions({ color: origColor, lineWidth: 2 });
  //     });
  //   } else {
  //     setSelectedSymbol(symbol);
  //     const chosenSeries = lineSeriesRef.current.get(symbol);
  //     if (!chosenSeries) return;

  //     const seriesData = seriesDataRef.current.get(symbol);
  //     // “Remove and re-add” to bring on top
  //     try {
  //       chartRef.current?.removeSeries(chosenSeries);
  //     } catch (err) {
  //       console.warn(`removeSeries error for highlight symbol=${symbol}`, err);
  //     }

  //     let newSeries;
  //     try {
  //       newSeries = chartRef.current.addLineSeries({
  //         color: chosenSeries.options().color,
  //         lineWidth: chosenSeries.options().lineWidth,
  //         lastValueVisible: true,
  //         priceLineVisible: false,
  //         title: symbol,
  //       });
  //       newSeries.setData(seriesData);
  //     } catch (err) {
  //       console.warn(`Error re-adding line series for highlight symbol=${symbol}`, err);
  //       return;
  //     }

  //     lineSeriesRef.current.set(symbol, newSeries);

  //     // Highlight chosen line
  //     newSeries.applyOptions({ color: getOppositeColor(), lineWidth: 4 });

  //     // Fade out others
  //     lineSeriesRef.current.forEach((s, otherSym) => {
  //       if (otherSym !== symbol) {
  //         s.applyOptions({ color: earthToneColor, lineWidth: 2 });
  //       }
  //     });
  //   }
  // };

  const handleRowClick = (symbol) => {
    // ตรวจสอบว่ากราฟนี้ถูกเลือกหรือไม่
    if (selectedSymbols.includes(symbol)) {
      // ถ้าเลือกแล้ว คลิกซ้ำจะยกเลิกการไฮไลท์
      setSelectedSymbols(prev => prev.filter(s => s !== symbol));

      // รีเซ็ตการแสดงผลของกราฟทั้งหมด
      lineSeriesRef.current.forEach((series, sym) => {
        const color = originalColorsRef.current.get(sym);
        // แสดงกราฟทุกตัวที่ยังไม่ได้ถูกยกเลิก
        series.applyOptions({ visible: true, color: color, lineWidth: 2 });
      });
    } else {
      // ถ้ายังไม่ได้เลือก เพิ่มเข้าไปใน selectedSymbols
      setSelectedSymbols(prev => [...prev, symbol]);

      // ซ่อนกราฟที่ไม่ได้เลือก และไฮไลท์กราฟที่เลือก
      lineSeriesRef.current.forEach((series, sym) => {
        if (sym === symbol) {
          // ปรับกราฟที่เลือก
          series.applyOptions({ visible: true, color: getOppositeColor(), lineWidth: 4 });
        } else {
          // ซ่อนกราฟที่ไม่ได้เลือก
          series.applyOptions({ visible: false });
        }
      });

      // ถ้า symbol นี้ไม่มีใน series จะเพิ่มเข้าไป
      if (!lineSeriesRef.current.has(symbol)) {
        const color = getRandomColor();
        const newSeries = chartRef.current.addLineSeries({
          color,
          lineWidth: 2,
          lastValueVisible: true,
          priceLineVisible: false,
          title: symbol,
        });

        const symData = historicalData[symbol];
        const seriesData = symData ? symData.map(dp => ({ time: dp.time, value: dp.value })) : [];
        newSeries.setData(seriesData);

        lineSeriesRef.current.set(symbol, newSeries);
        seriesDataRef.current.set(symbol, seriesData);
        originalColorsRef.current.set(symbol, color);
      }
    }
  };

  const handleRezoom = () => {
    if (!chartRef.current || !chartRef.current.timeScale) return;

    const minTime = 1610000000000;
    const maxTime = 1620000000000;

    chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
    chartRef.current.timeScale().fitContent();
    chartRef.current.timeScale().applyOptions({ rightOffset: 50 });
  };


  // Keep chart size dynamic

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  useEffect(() => {
    if (!chartContainerRef.current || !chartRef.current) return;

    const updateChartSize = () => {
      if (chartRef.current) {
        chartRef.current.resize(
          chartContainerRef.current.offsetWidth,
          chartContainerRef.current.offsetHeight
        );
      }
    };

    // ปรับขนาดเมื่อ isCollapsed เปลี่ยน
    updateChartSize();

    // สร้าง ResizeObserver
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateChartSize);
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [isCollapsed]);



  // useEffect(() => {
  //   if (!chartContainerRef.current || !chartRef.current) return;
  //   const updateSize = () => {
  //     chartRef.current.resize(
  //       chartContainerRef.current.offsetWidth,
  //       chartContainerRef.current.offsetHeight
  //     );
  //   };
  //   updateSize();

  //   const ro = new ResizeObserver(() => requestAnimationFrame(updateSize));
  //   ro.observe(chartContainerRef.current);
  //   return () => ro.disconnect();
  // }, [isCollapsed]);


  const onChartTouch = (event, allowTouchMove) => {
    if (chartTableContainerRef.current && chartTableContainerRef.current.contains(event.target)) {
      window.mySwiper.allowTouchMove = allowTouchMove;
    }
  };

  const renderTable = (start, end) => (
    <table style={{ fontSize: '11.5px', border: '1px solid #ddd', borderCollapse: 'collapse', fontFamily: 'Lato, Sans-serif' }}>
      <thead>
        <tr style={{ backgroundColor: '#fdf5e6' }}>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593', }}>Rank</th>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593', }}>Symbol</th>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593', }}>Value</th>
          <th style={{ textAlign: 'center', padding: '3px', backgroundColor: '#283593' }}>%Change</th>
        </tr>
      </thead>
      <tbody>
        {tableData
          .sort((a, b) => a.rank - b.rank)
          .slice(start, end)
          .map((row, idx) => {
            const isSelected = row.symbol === selectedSymbol;
            return (
              <tr
                key={idx}
                style={{
                  backgroundColor: isSelected ? "#B8860B" : "#636a7e", // เปลี่ยนสีเมื่อกด
                  cursor: "pointer",
                  fontWeight: isSelected ? "bold" : "normal",
                  transition: "background-color 0.3s ease",
                  borderBottom: "1px solid #ddd",
                }}
                onClick={() => handleRowClick(row.symbol)}
              >
                <td style={{ textAlign: "center", padding: "3px 0 5px" }}>{row.rank}</td>
                <td style={{ textAlign: "center", padding: "3px 0 5px" }}>{row.symbol}</td>
                <td style={{ textAlign: "center", padding: "3px 0 5px" }}>
                  {(row.value / 1_000_000).toLocaleString("en-US", { maximumFractionDigits: 2 })}
                </td>
                <td style={{
                  textAlign: "center", padding: "3px 0 5px", backgroundColor:
                    row.pct_change > 0
                      ? '#228B22'
                      : row.pct_change < 0
                        ? '#C8342A'
                        : '#575b69'
                }}>
                  {row.pct_change != null ? row.pct_change.toFixed(2) : "-"}
                </td> {/* เพิ่มแสดง % Change */}
              </tr>
            );
          })}
      </tbody>

    </table>
  );

  return (
    <div className="chart-section" style={{ fontFamily: 'Lato, Sans-serif' }}>
      <FontLoader />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3 style={{
          position: 'relative', // ให้ปุ่มอิงจากตำแหน่งของ h3
          display: 'flex',
          alignItems: 'center',
          flex: '1',
        }}>
          {title}

          {/* ปุ่มแว่นขยาย */}
          <FaSearch
            style={{
              position: 'absolute', // ป้องกันไม่ให้ขยับตามเนื้อหา
              top: (isMobile || isTablet) ? 2 : '50%', // จัดให้อยู่กึ่งกลางแนวตั้ง
              right: (isMobile || isTablet) ? '50%' : '390px', // ชิดขวา
              transform: (isMobile || isTablet) ? 'unset' : 'translateY(-50%)', // ปรับให้อยู่ตรงกลางพอดี
              cursor: 'pointer',
              fontSize: '20px',
              zIndex: '10', // ให้ปุ่มอยู่เหนือเนื้อหา

            }}
            onClick={() => {
              const dateStr = formatDateString(selectedDate);
              const url = `/fullscreenhisrealflow?market=${market}&type=${type}&date=${dateStr}&title=${encodeURIComponent(title)}`;
              window.open(url, '_blank');
            }}
          // className="expand-button"
          />

          {/* ปุ่ม Rezoom */}
          <LoopIcon
            onClick={handleRezoom}
            style={{
              position: 'absolute', // ป้องกันไม่ให้ขยับตามเนื้อหา
              top: (isMobile || isTablet) ? 0 : '50%', // จัดให้อยู่กึ่งกลางแนวตั้ง
              right: (isMobile || isTablet) ? '40%' : '355px', // ให้ห่างจาก FaSearch ไปทางซ้าย
              transform: (isMobile || isTablet) ? 'unset' : 'translateY(-50%)', // ปรับให้อยู่ตรงกลางพอดี
              cursor: 'pointer',
              fontSize: '25px',
              color: '#febc69', // สามารถเปลี่ยนสีตามต้องการ
              zIndex: '10', // ให้ปุ่มอยู่เหนือเนื้อหา
            }}
          />
        </h3>

        {/* Icon พับเก็บ Table สำหรับ Mobile และ Tablet */}
        {(isMobile || isTablet) && (
          <IconButton variant="contained" onClick={toggleCollapse}>
            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        )}
      </Box>



      <div
        className="chart-table-container"
        ref={chartTableContainerRef}
        style={{ display: 'flex', flexDirection: 'row', gap: isMobile ? '2vw' : '5px', justifyContent: isMobile ? 'center' : 'start', alignItems: 'flex-start' }}>


        <div
          ref={chartContainerRef}
          className="chart-container"
          // style={{ width: '60%', position: 'relative', border: '1px solid #ccc' }}
          style={{
            width: isCollapsed ? '100%' : (isTablet) ? '58%' : isMobile ? '54%' : '50%',
            position: 'relative',

            // เปลี่ยนจาก border เป็น outline แทนนะครับเพราะ border มันมีผลกับ width, height ในบรรทัดที่ 339
            // ทำให้ chart มันคำนวณขนาดใหม่ตลอดเวลา
            outline: '1px solid #ccc',


            // overflow: 'hidden'
          }}
          onTouchStart={(event) => onChartTouch(event, false)}
          onTouchEnd={(event) => onChartTouch(event, true)}>

        </div>




        {(!isMobile && !isTablet) && (
          <>
            <div style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column',
              gap: '5px', // เปลี่ยนจาก -5px เป็น 5px
              minHeight: '100px', // ป้องกันการซ้อนกัน
              alignItems: 'stretch', // ให้ตารางขยายเต็มพื้นท
            }}>
              {renderTable(0, 10)}
            </div>
            <div style={{
              width: '30%',
              display: 'flex',
              flexDirection: 'column',
              gap: '5px', // เปลี่ยนจาก -5px เป็น 5px
              minHeight: '100px', // ป้องกันการซ้อนกัน
              alignItems: 'stretch', // ให้ตารางขยายเต็มพื้นท
            }}>
              {renderTable(10, 20)}
            </div>
          </>
        )}

        {(isMobile || isTablet) && (
          <>
            {/* หากเข้าสู่โหมด Mobile */}

            {!isCollapsed && (<Box sx={{
              display: 'flex',
              // gap: '10px',
              width: '45%',
              flexDirection: 'column',
              maxHeight: '290px',
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#888',
                borderRadius: '4px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555',
              },
            }}>
              {renderTable(0, 20)}
            </Box>)}

          </>
        )
        }
      </div>
    </div>
  );
};

const Hisflow = () => {
  function getDefaultDate() {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    if (d.getDay() === 6) d.setDate(d.getDate() - 1);
    else if (d.getDay() === 0) d.setDate(d.getDate() - 2);
    return d;
  }

  const [selectedDate, setSelectedDate] = useState(() => getDefaultDate());
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isPTablet = useIsPortraitTablet();

  const datePickerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: isTablet || isMobile ? '5px 0' : '10px 0',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: (isMobile || isTablet) ? '10px' : '20px', fontFamily: 'Lato, Sans-serif', }}>
      <h1 style={{ margin: '0px' }}>Realflow History</h1>
      <div style={datePickerStyle}>
        <label style={{ fontWeight: 'bold', fontFamily: 'Lato, Sans-serif' }}>Select Date:</label>
        <input
          type="date"
          value={selectedDate.toISOString().split('T')[0]}
          onChange={(e) => setSelectedDate(new Date(e.target.value))}
          style={{ padding: '4px' }}
        />
      </div>

      <div style={{
        display: 'grid',
        gridTemplateColumns: (isMobile || isTablet) ? '1fr' : '1fr 1fr',
        gap: '20px',
        // width: '100%',
      }}>
        {/* เมื่อแสดงผลโหมด Desktop */}
        {(!isMobile && !isTablet) && (
          <>
            {/* SET100 & NON-SET100 => in */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <ChartSectionWithMultipleLines
                title="SET100 Hist In (+)"
                market="SET100"
                type="in"
                selectedDate={selectedDate}
              />
              <ChartSectionWithMultipleLines
                title="NON-SET100 Hist In (+)"
                market="NON-SET100"
                type="in"
                selectedDate={selectedDate}
              />
            </div>

            {/* SET100 & NON-SET100 => out */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <ChartSectionWithMultipleLines
                title="SET100 Hist Out (-)"
                market="SET100"
                type="out"
                selectedDate={selectedDate}
              />
              <ChartSectionWithMultipleLines
                title="NON-SET100 Hist Out (-)"
                market="NON-SET100"
                type="out"
                selectedDate={selectedDate}
              />
            </div>

            {/* MAI & WARRANT => in */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <ChartSectionWithMultipleLines
                title="MAI Hist In (+)"
                market="MAI"
                type="in"
                selectedDate={selectedDate}
              />
              <ChartSectionWithMultipleLines
                title="WARRANT Hist In (+)"
                market="WARRANT"
                type="in"
                selectedDate={selectedDate}
              />
            </div>

            {/* MAI & WARRANT => out */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <ChartSectionWithMultipleLines
                title="MAI Hist Out (-)"
                market="MAI"
                type="out"
                selectedDate={selectedDate}
              />
              <ChartSectionWithMultipleLines
                title="WARRANT Hist Out (-)"
                market="WARRANT"
                type="out"
                selectedDate={selectedDate}
              />
            </div>
          </>
        )}

        {/* แสดงผลบน Tablet */}

        {(isTablet && !isPTablet) && (
          <Swiper
            style={{ width: '100%', maxWidth: '1200px' }}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={1}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            onSwiper={(swiper) => {
              window.mySwiper = swiper;
            }}
          >
            {/* Slide 1 */}
            <SwiperSlide>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                  gap: '10px', // ระยะห่างระหว่าง Grid
                }}
              >
                <ChartSectionWithMultipleLines
                  title="SET100 Hist In (+)"
                  market="SET100"
                  type="in"
                  selectedDate={selectedDate}
                />
                <ChartSectionWithMultipleLines
                  title="NON-SET100 Hist In (+)"
                  market="NON-SET100"
                  type="in"
                  selectedDate={selectedDate}
                />
                <ChartSectionWithMultipleLines
                  title="SET100 Hist Out (-)"
                  market="SET100"
                  type="out"
                  selectedDate={selectedDate}
                />
                <ChartSectionWithMultipleLines
                  title="NON-SET100 Hist Out (-)"
                  market="NON-SET100"
                  type="out"
                  selectedDate={selectedDate}
                />
              </div>
            </SwiperSlide>

            {/* Slide 2 */}
            <SwiperSlide>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)', // 2 columns ใน Tablet
                  gap: '10px',
                }}
              >
                <ChartSectionWithMultipleLines
                  title="MAI Hist In (+)"
                  market="MAI"
                  type="in"
                  selectedDate={selectedDate}
                />
                <ChartSectionWithMultipleLines
                  title="WARRANT Hist In (+)"
                  market="WARRANT"
                  type="in"
                  selectedDate={selectedDate}
                />

                {/* MAI & WARRANT => out */}
                <ChartSectionWithMultipleLines
                  title="MAI Hist Out (-)"
                  market="MAI"
                  type="out"
                  selectedDate={selectedDate}
                />
                <ChartSectionWithMultipleLines
                  title="WARRANT Hist Out (-)"
                  market="WARRANT"
                  type="out"
                  selectedDate={selectedDate}
                />
              </div>
            </SwiperSlide>
          </Swiper>
        )}

        {/* หากเข้าสู่ Mobile mode */}
        {(isMobile || isPTablet) && (
          <>

            <Swiper
              style={{ width: '100%', maxWidth: '600px' }}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{
                clickable: true, dynamicBullets: true,
              }}
              onSwiper={(swiper) => {
                window.mySwiper = swiper;
              }}
            >
              <SwiperSlide>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <ChartSectionWithMultipleLines
                    title="SET100 Hist In (+)"
                    market="SET100"
                    type="in"
                    selectedDate={selectedDate}
                  />
                  <ChartSectionWithMultipleLines
                    title="SET100 Hist Out (-)"
                    market="SET100"
                    type="out"
                    selectedDate={selectedDate}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <ChartSectionWithMultipleLines
                    title="NON-SET100 Hist In (+)"
                    market="NON-SET100"
                    type="in"
                    selectedDate={selectedDate}
                  />
                  <ChartSectionWithMultipleLines
                    title="NON-SET100 Hist Out (-)"
                    market="NON-SET100"
                    type="out"
                    selectedDate={selectedDate}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <ChartSectionWithMultipleLines
                    title="MAI Hist In (+)"
                    market="MAI"
                    type="in"
                    selectedDate={selectedDate}
                  />
                  <ChartSectionWithMultipleLines
                    title="MAI Hist Out (-)"
                    market="MAI"
                    type="out"
                    selectedDate={selectedDate}
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

                  <ChartSectionWithMultipleLines
                    title="WARRANT Hist In (+)"
                    market="WARRANT"
                    type="in"
                    selectedDate={selectedDate}
                  />
                  <ChartSectionWithMultipleLines
                    title="WARRANT Hist Out (-)"
                    market="WARRANT"
                    type="out"
                    selectedDate={selectedDate}
                  />
                </div>
              </SwiperSlide>

            </Swiper>
          </>
        )}

      </div>
    </div>
  );
};

export default Hisflow
