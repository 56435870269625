import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColorType, createChart, Rectangle } from 'lightweight-charts';
import { useMediaQuery, useTheme, Box, Grid, Typography, Container, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, IconButton, CircularProgress, keyframes } from '@mui/material';
import DataTable from "./table";
import BasicTable from "./table";
import EnhancedTable from "./table";
import { __fetching_data__ } from '../../components/api.js';
import { useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { tokens } from "../../theme";
import FontLoader from "../../components/FontLoader";




import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

import './index.css';
// import { ids } from "webpack";

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";



const EachTable = ({ RegionData, collapsedTables, toggleTable, onClickTable, backgroundColors }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const blinkAnimation = keyframes`
  0% { background-color: transparent; }
  100% { background-color: green; }
`;

    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isPTablet = useIsPortraitTablet();

    const [selectedRow, setSelectedRow] = useState({});
    const [lastSelectedRegion, setLastSelectedRegion] = useState(null);

    const handleKeyPress = (event, items, region) => {

        setSelectedRow((prev) => {
            const currentIndex = prev[region] ?? -1; // ถ้าไม่มีค่า ให้เริ่มจาก -1 (ไม่มีแถวถูกเลือก)
            let newIndex = currentIndex;

            if (event.key === 'ArrowDown') {
                newIndex = currentIndex + 1 < items.length ? currentIndex + 1 : currentIndex;
            } else if (event.key === 'ArrowUp') {
                newIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : -1; // ถ้าถึงแถวแรกแล้ว ให้ unselect (-1)
            } else if (event.key === 'Enter' && newIndex !== -1) {
                onClickTable(items[newIndex][0]);
            } else if (event.key === 'Escape') {
                return {}; // Unselect ทั้งหมด
            }

            if (newIndex !== currentIndex && newIndex !== -1) {
                onClickTable(items[newIndex][0]);
            }

            return newIndex === -1 ? {} : { [region]: newIndex };
        });

        setLastSelectedRegion(region); // อัปเดต region ที่เลือกล่าสุด




    };

    const handleBlur = () => {
        setSelectedRow({});
        setLastSelectedRegion(null);
    };

    return (
        <Box className="dr-content" sx={{ display: 'flex', flexDirection: 'column', maxWidth: isMobile ? '100%' : '250px' }} >
            {Object.entries(RegionData).map(([region, items]) => (
                <Box key={region} className='dr-n-table' style={{ borderRadius: '10px' }} >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: '#004080',
                        borderRadius: '10px 10px 0 0',
                        justifyContent: 'space-between',
                    }}>

                        <Typography variant="h5" style={{
                            // backgroundImage: 'linear-gradient(90deg, #3083FF, #1D4E99)',

                            textAlign: 'center',
                            display: 'flex',
                            marginLeft: '30px',
                            alignItems: 'center',
                            color: theme.palette.text.sidebar2
                        }}>

                            {region}{" "}

                        </Typography>
                        <IconButton
                            onClick={() => toggleTable(region)}
                            style={{
                                color: theme.palette.text.sidebar2,
                                cursor: "pointer"
                            }}
                        >
                            {collapsedTables[region] ? (
                                <ExpandMoreIcon />
                            ) : (
                                <ExpandLessIcon />
                            )}
                        </IconButton>

                    </Box>

                    {!collapsedTables[region] && (
                        <TableContainer
                            tabIndex={0}
                            onKeyDown={(e) => handleKeyPress(e, items, region)}
                            onBlur={handleBlur} sx={{
                                outline: "none",
                                color: theme.palette.text.primary,
                                padding: '0 5px 5px 5px',

                                color: theme.palette.text.primary,
                                "& *": { color: theme.palette.text.primary },
                                backgroundColor: theme.palette.background.default,
                                // backgroundColor: '#1A1A1A',
                                borderRadius: '0 0 10px 10px',
                                boxShadow: 'rgba(0,0,0,0.1) 0px 2px 4px',
                            }}>
                            <Table sx={{

                                fontFamily: `'Lato, Sans-serif',`,

                            }} >
                                <TableHead sx={{
                                    fontFamily: `"Lato, Sans-serif,", sans-serif`,
                                    color: theme.palette.text.primary,
                                    backgroundColor: theme.palette.background.sidebar
                                }} >
                                    <TableRow>
                                        <TableCell sx={{ padding: '3px', fontWeight: 'bold', fontSize: '13px', }}>
                                            DR/DRx
                                        </TableCell>
                                        <TableCell sx={{ padding: '3px', fontWeight: 'bold', fontSize: '13px', }}>
                                            TradingView
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((item, index) => (
                                        <TableRow
                                            key={item[0]}

                                            onClick={() => {
                                                //เช็คว่าคลิกที่แถวที่เลือกอยู่หรือไม่
                                                if (selectedRow[region] === index) {
                                                    setSelectedRow({}); // ถ้าใช่ ให้ unselect
                                                    setLastSelectedRegion(null);
                                                } else {
                                                    setSelectedRow({ [region]: index }); // ถ้าไม่ใช่ ให้เลือกแถวใหม่
                                                    setLastSelectedRegion(region);
                                                    onClickTable(item[0]);
                                                }
                                            }}
                                            sx={{
                                                cursor: 'pointer',
                                                backgroundColor:
                                                    backgroundColors?.[item[0]] === "green"
                                                        ? "green"
                                                        : lastSelectedRegion === region && selectedRow[region] === index ? theme.palette.background.default :
                                                            index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even,

                                                animation:
                                                    backgroundColors[item[0]] === "green"
                                                        ? `${blinkAnimation} 1s infinite alternate`
                                                        : "none",
                                                fontFamily: `"Mitr", "Open Sans", "Source Sans Pro", sans-serif`,
                                                color: theme.palette.text.primary,
                                                "&:hover": {
                                                    backgroundColor: theme.palette.background.sidebar // เปลี่ยนสีอ่อนเมื่อ hover
                                                }
                                            }}
                                        >
                                            <TableCell sx={{ padding: '1px ', fontSize: '13px', color: theme.palette.text.primary, borderBottom: '0' }}>
                                                {item[1]}
                                            </TableCell>
                                            <TableCell sx={{ padding: '1px', fontSize: '13px', color: theme.palette.text.primary, borderBottom: '0' }}>
                                                <Tooltip title={item[4]}>
                                                    <Box>{item[2]}
                                                    </Box>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>))}


        </Box>
    );
}

function DRPage() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const csrfToken = useSelector((state) => state.csrfToken);
    const [symbol, setSymbol] = useState([])
    let [data, setData] = useState([])
    const [abc, setAbc] = useState(0)
    const [isLoad, setLoad] = useState(false)

    const [backgroundColors, setBackgroundColors] = useState([]);
    console.log('bg', backgroundColors)

    const [collapsedTables, setCollapsedTables] = useState({});
    // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    // const isTablet = useMediaQuery(theme.breakpoints.down('1024'));

    // let chart1, chart2, chart3;

    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const isPTablet = useIsPortraitTablet();

    const toggleTable = (region) => {
        setCollapsedTables((prev) => ({
            ...prev,
            [region]: !prev[region], // Toggle collapsed state for the region
        }));
    };

    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         if (event.key === 'Home') {
    //             if (chart1.current?.chart) {
    //                 chart1.current.chart.timeScale().fitContent();
    //             }
    //             if (chart2.current?.chart) {
    //                 chart2.current.chart.timeScale().fitContent();
    //             }
    //             if (chart3.current?.chart) {
    //                 chart3.current.chart.timeScale().fitContent();
    //             }
    //             console.log('home press');
    //         }
    //     };

    //     window.addEventListener('keydown', handleKeyDown);

    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown);
    //     };
    // }, []);

    useEffect(() => {
        callAPI()
    }, [])

    useEffect(() => {
        if (!isMobile || !isTablet) {
            setCollapsedTables({});
        }

    }, [isMobile, isTablet]);

    async function callAPI() {
        const response = await __fetching_data__({ url: `${base_api_url}/dr_list_symbol`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log('response', response)
        if (response.status) {
            // data = response.data
            setData(response.data)
            // console.log(data)
            // setAbc(20)
        }
    }

    useEffect(() => {
        if (document.getElementById("chart-div")) {
            // console.log("Initializing empty charts...");
            bgChart("chart1", [], "", "blue");
            bgChart("chart2", [], "", "red");
            bgChart("chart3", [], "", "green");

        }
    }, []);




    function bgChart(name, data, chartName, lineColor) {
        const chartDiv = document.getElementById('chart-div')
        const filterContainer = document.createElement('div')
        filterContainer.setAttribute('id', name)
        filterContainer.style.width = "100%"
        filterContainer.style.borderRadius = '10px'
        filterContainer.style.overflow = 'hidden'

        isMobile || isTablet ? filterContainer.style.height = "200px" : filterContainer.style.height = "250px"
        if (!document.getElementById(name)) {
            chartDiv.append(filterContainer);
        }
        // chartDiv.append(filterContainer)
        // filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
        const chart = createChart(document.getElementById(name),
            {
                width: filterContainer.clientWidth,
                height: filterContainer.clientHeight,
                timeScale: {
                    rightOffset: data.length < 10 ? 0 : data.length < 100 ? 0.5 : 5
                }
            }
        );
        chart.applyOptions({
            layout: {
                background: { color: theme.palette.table.odd },
                textColor: theme.palette.text.secondary,

            },
            watermark: {
                visible: true,
                fontSize: 28,
                horzAlign: 'center',
                vertAlign: 'top',
                color: theme.palette.chart.watermark,
                text: `${chartName}`,
            },

            grid: {
                vertLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวตั้ง
                horzLines: { color: theme.palette.neutral.dark }, // เปลี่ยนสีเส้น Grid แนวนอน
            },
        });

        function resizeChart() {
            const containerWidth = filterContainer.clientWidth;
            const containerHeight = (isMobile || isPTablet) ? 200 : 250;
            chart.resize(containerWidth, containerHeight);
        }

        const resizeObserver = new ResizeObserver(() => {
            resizeChart();
        });

        resizeObserver.observe(filterContainer);

        window.addEventListener("resize", () => {
            const containerWidth = filterContainer.clientWidth;
            const containerHeight = (isMobile || isPTablet) ? 200 : 250;
            chart.resize(containerWidth, containerHeight);
        });
        resizeChart();

        const lineSeries = chart.addLineSeries({ color: lineColor, priceLineVisible: false });
        lineSeries.setData(data);
        return [chart, lineSeries]


    }





    const inList = (data, lst) => {
        for (let i = 0; i < lst.length; i++) {
            if (data == lst[i]['time']) {
                return i
            }
        }
        return undefined
    }

    const clearDiv = () => {
        document.getElementById('chart1').remove()
        document.getElementById('chart2').remove()
        document.getElementById('chart3').remove()
    }


    const checkLatest10Data = async (data) => {
        const today = new Date();
        let backgroundStatus = {};

        // ดึง 10 ตัวท้ายของ data
        const latest10 = data.slice(-10);
        // const latest10 = data.slice(-1);
        const dt = data;
        console.log('data is ', dt);

        // ใช้ Promise.all เพื่อรอให้ทุกการเรียก API เสร็จ
        await Promise.all(latest10.map(async (item) => {
            const id = item[0]; // ดึง id จาก data

            try {
                // เรียก API ดึงข้อมูลของ id นี้
                const response = await __fetching_data__({
                    url: `${base_api_url}/dr_detail_symbol?id=${id}`,
                    method: "GET",
                    headers: { "xsrf-token": csrfToken }
                });

                console.log('response is', response);

                // ตรวจสอบว่ามีข้อมูลหรือไม่
                if (!response || !response.data || !response.data.chart1 || response.data.chart1.length === 0) {
                    backgroundStatus[id] = "odd-even"; // ถ้าไม่มีข้อมูล ใช้สีปกติ
                    console.log('ไม่ response');
                    return;
                }

                // หา "วันที่เก่าที่สุด" ใน chart1
                let oldestDate = response.data.chart1
                    .map(entry => new Date(entry.time)) // ใช้ `time` จากข้อมูล
                    .sort((a, b) => a - b)[0]; // เรียงจากเก่าสุดไปใหม่สุด แล้วเลือกตัวแรก

                // คำนวณจำนวนวันระหว่างวันนี้กับวันที่เก่าสุด
                const today = new Date();
                const diffDays = (today - oldestDate) / (1000 * 60 * 60 * 24);

                // ถ้าน้อยกว่าหรือเท่ากับ 7 วัน ใช้สีเขียว, ถ้ามากกว่าใช้ odd-even
                backgroundStatus[id] = diffDays <= 7 ? "green" : "odd-even";
                // backgroundStatus[id] = diffDays >= 7 ? "green" : "odd-even";

            } catch (error) {
                console.error(`Error fetching data for id ${id}:`, error);
                backgroundStatus[id] = "odd-even"; // หากมี error ให้ใช้สีปกติ
            }
        }));

        return backgroundStatus;
    };

    useEffect(() => {
        const fetchData = async () => {
            const result = await checkLatest10Data(data);
            setBackgroundColors(result);
        };
        fetchData();
    }, [data]);




    const onClickTable = async (id) => {
        setLoad(true)
        const response = await __fetching_data__({ url: `${base_api_url}/dr_detail_symbol?id=${id}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        console.log('response click', response)
        let data = []
        if (response.status) {
            for (let i = 0; i < response.data.chart3.length; i++) {
                const chartData = response.data.chart3[i]
                // const dateData = chartData['date'].split(' ')[0]
                const dateTime = chartData['date'].includes(' ')
                    ? chartData['date'].split(' ')[0]
                    : chartData['date'].split('T')[0];
                let dateData;

                if (typeof chartData['date'] === 'string') { // หากวันที่เป็น String (เช่น "2024-11-19T00:00:00")
                    // dateData = chartData['date'].split('T')[0].replaceAll('.', '-')
                    // const dateTime = chartData['date'].split(' ')[0]; // แยกเฉพาะส่วนวันที่
                    dateData = dateTime.replaceAll('.', '-');
                    // console.log(dateData)
                } else if (chartData['date'] instanceof Date) { // หากวันที่เป็น date object

                    const year = chartData['date'].getFullYear()
                    const month = String(chartData['date'].getMonth() + 1).padStart(2, '0')
                    const day = String(chartData['date'].getDate()).padStart(2, '0')
                    dateData = `${year}-${month}-${day}`
                    // console.log(dateData)
                } else {
                    continue;
                }

                // const dateData =
                //     typeof chartData['date'] === 'string'
                //         ? chartData['date'].split(' ')[0].replaceAll('.', '-')
                //         : chartData['date'] instanceof Date
                //         ? `${chartData['date'].getFullYear()}-${String(chartData['date'].getMonth() + 1).padStart(2, '0')}-${String(chartData['date'].getDate()).padStart(2, '0')}`
                //         : null;
                // if (!dateData) continue;

                // const dateData = chartData['date'].split(' ')[0].replaceAll('.', '-')

                let dataInList = inList(dateData, data)
                if (dataInList != undefined) {
                    data[dataInList]['value'] = Number(chartData['value'])
                } else {
                    data.push({ 'time': dateData, 'value': Number(chartData['value']) })
                }
            }
            clearDiv()
            function compare(a, b) {
                if (a.time < b.time) {
                    return -1;
                }
                if (a.time > b.time) {
                    return 1;
                }
                return 0;
            }
            data.sort(compare)
            response.data.chart1.sort(compare)
            response.data.chart2.sort(compare)

            // const getCommonDates = (chart1, chart2, chart3) => {
            //     const dates1 = new Set(chart1.map(item => item.time));
            //     const dates2 = new Set(chart2.map(item => item.time));
            //     const dates3 = new Set(chart3.map(item => item.time));

            //     // หาวันที่ที่มีในทุก chart
            //     const commonDates = [...dates1].filter(date => dates2.has(date) && dates3.has(date));
            //     return commonDates;
            // }

            // // ตัดข้อมูลให้เหลือเฉพาะวันที่ที่มีร่วมกัน
            // const filterDataByCommonDates = (data, commonDates) => {
            //     return data.filter(item => commonDates.includes(item.time));
            // }

            // // ก่อนสร้าง chart ให้ทำการเปรียบเทียบและตัดข้อมูล
            // const commonDates = getCommonDates(response.data.chart1, response.data.chart2, data);
            // response.data.chart1 = filterDataByCommonDates(response.data.chart1, commonDates);
            // response.data.chart2 = filterDataByCommonDates(response.data.chart2, commonDates);
            // data = filterDataByCommonDates(data, commonDates);

            const getCommonDates = (chart1, chart2, chart3) => {
                const dates1 = new Set(chart1.map(item => item.time));
                const dates3 = new Set(chart3.map(item => item.time));

                if (!chart2 || chart2.length === 0) {
                    // ถ้า Chart 2 ไม่มีข้อมูล ให้ข้ามไป และเปรียบเทียบเฉพาะ Chart 1 กับ Chart 3
                    return [...dates1].filter(date => dates3.has(date));
                } else {
                    const dates2 = new Set(chart2.map(item => item.time));
                    // เปรียบเทียบวันที่ในทั้ง 3 Chart
                    return [...dates1].filter(date => dates2.has(date) && dates3.has(date));
                }
            }

            // ฟังก์ชันตัดข้อมูลให้เหลือเฉพาะวันที่ที่มีร่วมกัน
            const filterDataByCommonDates = (data, commonDates) => {
                return data.filter(item => commonDates.includes(item.time));
            }

            // ตรวจสอบว่าข้อมูล Chart 2 มีหรือไม่
            const isChart2Empty = !response.data.chart2 || response.data.chart2.length === 0;

            // ก่อนสร้าง Chart ให้เปรียบเทียบและตัดข้อมูล
            const commonDates = getCommonDates(response.data.chart1, response.data.chart2, data);

            response.data.chart1 = filterDataByCommonDates(response.data.chart1, commonDates);

            if (!isChart2Empty) {
                response.data.chart2 = filterDataByCommonDates(response.data.chart2, commonDates);
            }

            data = filterDataByCommonDates(data, commonDates);

            var [chart1, mainSeries1] = bgChart('chart1', response.data.chart1, response.data.name.chart1, colors.blueChart[50])
            chart1.timeScale().fitContent();
            var [chart2, mainSeries2] = bgChart('chart2', response.data.chart2, response.data.name.chart2, colors.redChart[300])
            chart2.timeScale().fitContent();
            var [chart3, mainSeries3] = bgChart('chart3', data, response.data.name.chart3, colors.greenChart[50])
            chart3.timeScale().fitContent();
            // var [chart1, mainSeries1] = bgChart('chart1', response.data.chart1, response.data.name.chart1, 'blue')
            // var [chart2, mainSeries2] = bgChart('chart2', data, response.data.name.chart2, 'black')
            // var [chart3, mainSeries3] = bgChart('chart3', data, response.data.name.chart3, 'green')
            // var [chart3, mainSeries3] = bgChart('chart3',  response.data.chart1, response.data.name.chart3, 'green')
            chart1.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            chart2.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            chart3.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
                chart1.timeScale().setVisibleLogicalRange(timeRange);
                chart2.timeScale().setVisibleLogicalRange(timeRange);
                chart3.timeScale().setVisibleLogicalRange(timeRange);
            });

            function getCrosshairDataPoint(series, param) {
                if (!param.time) {
                    return null;
                }
                const dataPoint = param.seriesData.get(series);
                return dataPoint || null;
            }

            // function syncCrosshair(chart, series, dataPoint) {
            //     if (dataPoint) {
            //         chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            //         return;
            //     }
            //     chart.clearCrosshairPosition();
            // }

            function syncCrosshair(chart, series, dataPoint) {
                try {
                    if (dataPoint && dataPoint.value != null && dataPoint.time != null) {
                        // Set crosshair position only if dataPoint has valid value and time
                        chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
                    } else {
                        // Clear the crosshair if dataPoint is invalid or missing
                        chart.clearCrosshairPosition();
                    }
                } catch (error) {
                    // Suppress any unexpected errors
                    console.error("Error in syncCrosshair: ", error);
                }
            }


            chart1.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries1, param);
                // syncCrosshair(chart2, mainSeries2, dataPoint);
                // syncCrosshair(chart3, mainSeries3, dataPoint);
                if (dataPoint) {
                    syncCrosshair(chart2, mainSeries2, dataPoint);
                    syncCrosshair(chart3, mainSeries3, dataPoint);
                }
            });
            chart2.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries2, param);
                syncCrosshair(chart1, mainSeries1, dataPoint);
                syncCrosshair(chart3, mainSeries3, dataPoint);
            });
            chart3.subscribeCrosshairMove(param => {
                const dataPoint = getCrosshairDataPoint(mainSeries3, param);
                syncCrosshair(chart1, mainSeries1, dataPoint);
                syncCrosshair(chart2, mainSeries2, dataPoint);
                // syncCrosshair(chart3, mainSeries3, dataPoint);
            });
            setLoad(false)
        }
    }
    // 


    const categorizeByStaticId = (data) => {
        // const regions = {
        //     "USA": [215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 229, 230, 231, 232, 233, 234],
        //     "Europe": [171, 179, 182, 190, 191, 197, 199],
        //     "ETC": [181],
        // };

        // const usaList = {
        //     "USA": [229, 230, 231, 232, 233, 234]
        // }

        return {
            "USA": data.filter((item) => item[2].includes("NYSE") || item[2].includes("NASDAQ")
                // ||  usaList["USA"].includes(item[0])
            ),
            "Europe": data.filter((item) => item[2].includes("MIL") || item[2].includes("EURONEXT") || item[2].includes("OMXCOP")),
            "ETC": data.filter((item) => [181, 241, 243].includes(item[0]))
        }

        // return Object.fromEntries(
        //     Object.entries(regions).map(([region, ids]) => [
        //         region,
        //         data.filter((item) => ids.includes(item[0])),
        //     ])
        // );
    };


    const categorizeByStaticId2 = (data) => {
        const regions = {
            "Japan": [187, 192, 193, 196, 202, 203, 209, 210],
            "Singapore": [177, 188, 200, 201, 206, 208, 211, 212],
            "Taiwan": [228, 237, 238]
        };

        return {
            "Japan": data.filter((item) => item[2].includes("TSE")),
            "Singapore": data.filter((item) => item[2].includes("SGX") && item[0] !== 181),

            "Vietnam": data.filter((item) => item[2].includes("HOSE")),
        }

        // return Object.fromEntries(
        //     Object.entries(regions).map(([region, ids]) => [
        //         region,
        //         data.filter((item) => ids.includes(item[0])),
        //     ])
        // );
    };
    const categorizeByStaticId3 = (data) => {
        const regions = {
            "China": [172, 173, 174, 175, 176, 183, 184, 185, 186, 189, 194, 195, 198, 204, 205, 207, 214],
            "Vietnam": [178, 236, 180, 213]

        };

        return {
            "China": data.filter((item) => item[2].includes("HKEX") && ![241, 243].includes(item[0])),
            "Taiwan": data.filter((item) => item[2].includes("TWSE")),

        }

        // return Object.fromEntries(
        //     Object.entries(regions).map(([region, ids]) => [
        //         region,
        //         data.filter((item) => ids.includes(item[0])),
        //     ])
        // );
    };

    const groupedDataLeft = categorizeByStaticId(data);
    const groupedDataRight = categorizeByStaticId2(data);
    const groupedDataRight2 = categorizeByStaticId3(data);




    return (<div>
        <Box className="main-content" sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            // maxWidth: '100vw',
            flexDirection: 'column',

            paddingTop: isMobile ? '0px' : '10px',
            paddingLeft: (isMobile || isPTablet) ? '0px' : '10px',
            // gap: '1vw'
        }}>
            <Box sx={{ display: isMobile ? 'none' : 'flex', justifyContent: 'space-around' }}>
                <Box sx={{ height: '40px', backgroundColor: theme.palette.background.card, borderRadius: '20px', display: 'flex', alignItems: 'center', padding: '5px 1vw 5px 1vw', gap: '10px' }}>
                    <Typography variant="h6" sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontFamily: 'Lato, Sans-serif', }}>
                        ราคาอ้างอิง:
                    </Typography>
                    <div style={{ height: '4px', width: '50px', backgroundColor: colors.blueChart[50] }}>

                    </div>
                </Box>
                <Box sx={{ height: '40px', backgroundColor: theme.palette.background.card, borderRadius: '20px', display: 'flex', alignItems: 'center', padding: '5px 1vw 5px 1vw', gap: '10px' }}>
                    <Typography variant="h6" sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontFamily: 'Lato, Sans-serif', }}>
                        PE Ratio:
                    </Typography>
                    <div style={{ height: '4px', width: '50px', backgroundColor: colors.redChart[300] }}>

                    </div>
                </Box>
                <Box sx={{ height: '40px', backgroundColor: theme.palette.background.card, borderRadius: '20px', display: 'flex', alignItems: 'center', padding: '5px 1vw 5px 1vw', gap: '10px' }}>
                    <Typography variant="h6" sx={{ color: theme.palette.text.primary, fontWeight: 'bold', fontFamily: 'Lato, Sans-serif', }}>
                        Last:
                    </Typography>
                    <div style={{ height: '4px', width: '50px', backgroundColor: colors.greenChart[50] }}>

                    </div>
                </Box>
            </Box>
            <Container maxWidth='xl' sx={{ display: 'flex', padding: ' 1vh 2vw 2vh 2vw', }}>
                <Paper sx={{
                    padding: '1vw',
                    display: 'flex',
                    width: '100%',
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                        lg: 'row',
                        xl: 'row'
                    },
                    alignItems: {
                        xs: 'center',
                        sm: 'center',
                        md: 'normal',
                        lg: 'normal',
                        xl: 'normal',
                    },
                    gap: (isMobile || isPTablet) ? '1vh' : 'normal',
                    justifyContent: 'space-around',
                    backgroundColor: theme.palette.background.sidebar
                }}>

                    {(!isMobile && !isTablet) &&
                        <EachTable
                            RegionData={groupedDataLeft}
                            collapsedTables={collapsedTables}
                            toggleTable={toggleTable}
                            onClickTable={onClickTable}
                            backgroundColors={backgroundColors}
                        />
                    }
                    {(isTablet && !isPTablet) &&

                        <Box className="big-block" sx={{
                            borderRadius: '10px',
                            display: 'flex',
                            height: 'fit-content',
                            // height: ''
                            backgroundColor: theme.palette.table.primary,
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
                            flexDirection: 'column',
                            gap: '10px',
                            maxHeight: '80vh',
                            overflowY: 'auto',
                            '::-webkit-scrollbar': {
                                width: '5px',
                                height: '5px'
                            },
                            '::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '4px',
                            },
                            '::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#555',
                            },

                        }}>
                            <div className="big-block-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px 10px 0 0', backgroundColor: '#004080', margin: '0' }}>
                                <h3 style={{ margin: '0', padding: '3px', color: theme.palette.text.sidebar2, zIndex: 1111 }}>EU/USA</h3>
                            </div>
                            <Box className="right-block" sx={{
                                gap: '1vw',
                                padding: '0 5px 0 5px',
                                display: 'flex',
                                flexDirection: (isTablet || isPTablet || isMobile) ? 'column' : 'unset',

                            }} >
                                <EachTable
                                    RegionData={groupedDataLeft}
                                    collapsedTables={collapsedTables}
                                    toggleTable={toggleTable}
                                    onClickTable={onClickTable}
                                    backgroundColors={backgroundColors}
                                />
                            </Box>
                        </Box>
                    }
                    {isLoad && <div style={{ zIndex: 100, position: 'absolute', top: '30%', left: '60%' }}><CircularProgress sx={{ color: theme.palette.text.primary }} /></div>}
                    {/* <div id="chart-div" className="all-chart">
                        <div><div id="chart1"></div></div>
                        <div><div id="chart2"></div></div>
                        <div><div id="chart3"></div></div>
                    </div> */}
                    {/* {!isLoad && <div id="chart-div" style={{ width: '850px', marginLeft: '5px' }}>
                <div><div id="chart1"></div></div>
                <div><div id="chart2"></div></div>
                <div><div id="chart3"></div></div>
            </div>} */}
                    {/*  */}
                    <Box className="all-chart" id='chart-div' sx={{
                        width: {
                            xs: '90%',
                            sm: '90%',
                            md: '50%',
                            lg: '50%',
                            xl: '60%'
                        },
                        height: 'fit-content',
                        backgroundColor: theme.palette.chart.primary,
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                        // zIndex: 111,
                        padding: '1vw',
                        gap: (isMobile || isTablet) ? '5px' : '2vh',
                        '& > div': {
                            borderRadius: '10px'
                        }

                        // outline: '1px solid #3f3f3f'
                    }}>

                        {/* <Box >
                            <Box id="chart1" />
                        </Box>
                        <Box>
                            <Box id="chart2" />
                        </Box>
                        <Box>
                            <Box id="chart3" />
                        </Box> */}

                    </Box>

                    <Box className="all-contents" sx={{
                        maxHeight: (isMobile || isPTablet) ? '30vh' : '90vh',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1vw',


                    }}>
                        <Box className="big-block" sx={{
                            borderRadius: '10px',
                            display: 'flex',
                            height: 'fit-content',
                            // height: ''
                            backgroundColor: theme.palette.table.primary,
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
                            flexDirection: 'column',
                            gap: '10px',
                            maxHeight: isMobile ? '30vh' : '86vh',
                            overflowY: 'auto',
                            '::-webkit-scrollbar': {
                                width: '5px',
                                height: '5px'
                            },
                            '::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '4px',
                            },
                            '::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#555',
                            },

                        }}>
                            <div className="big-block-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px 10px 0 0', backgroundColor: '#004080', margin: '0' }}>
                                <h3 style={{ margin: '0', padding: '3px', color: theme.palette.text.sidebar2, zIndex: 1111 }}>Asia</h3>
                            </div>
                            <Box className="right-block" sx={{ gap: '1vw', padding: '0 5px 0 5px', display: 'flex', flexDirection: (isTablet || isPTablet || isMobile) ? 'column' : 'unset' }} >
                                <EachTable
                                    RegionData={groupedDataRight}
                                    collapsedTables={collapsedTables}
                                    toggleTable={toggleTable}
                                    onClickTable={onClickTable}
                                    backgroundColors={backgroundColors}
                                />
                                <EachTable
                                    RegionData={groupedDataRight2}
                                    collapsedTables={collapsedTables}
                                    toggleTable={toggleTable}
                                    onClickTable={onClickTable}
                                    backgroundColors={backgroundColors}
                                />
                            </Box>




                        </Box>
                        {(isPTablet || isMobile) && <Box className="right-content2" sx={{
                            gap: '10px',
                            borderRadius: '10px',
                            display: 'flex',
                            height: 'fit-content',
                            maxHeight: isMobile ? '30vh' : 'unset',
                            // height: ''
                            backgroundColor: theme.palette.table.primary,
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px',
                            flexDirection: 'column',
                            overflowY: 'auto',
                            '::-webkit-scrollbar': {
                                width: '5px',
                                height: '5px'
                            },
                            '::-webkit-scrollbar-thumb': {
                                backgroundColor: '#888',
                                borderRadius: '4px',
                            },
                            '::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: '#555',
                            },

                        }}>

                            {(isPTablet || isMobile) && (
                                <>
                                    <div className="big-block-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px 10px 0 0', backgroundColor: '#004080', margin: '0' }}>
                                        <h3 style={{ margin: '0', padding: '3px', color: theme.palette.text.sidebar2, zIndex: 1111 }}>America / EU</h3>
                                    </div>
                                    {/* <div className="each-column">
                                {Object.entries(groupedDataLeft).map(([region, items]) => (
                                    <div key={region} style={{ width: "200px" }} className="dr-n-table">
                                        {isMobile || isTablet ? (
                                            <h3>
                                                {region}{" "}
                                                <IconButton
                                                    onClick={() => toggleTable(region)}
                                                    style={{ color: "white", cursor: "pointer" }}
                                                >
                                                    {collapsedTables[region] ? (
                                                        <ExpandMoreIcon />
                                                    ) : (
                                                        <ExpandLessIcon />
                                                    )}
                                                </IconButton>
                                            </h3>
                                        ) : (
                                            <h3>{region}</h3>
                                        )}
                                        {!collapsedTables[region] && (
                                            <table style={{ marginBottom: "10px", width: "200px" }}>
                                                <thead>
                                                    <tr>
                                                        <th>DR/DRx</th>
                                                        <th>TradingView</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items.map((item) => (
                                                        <tr key={item[0]} onClick={() => onClickTable(item[0])} style={{ cursor: "pointer" }}>
                                                            <td>{item[1]}</td>
                                                            <td>
                                                                <div className="tooltip">{item[2]}
                                                                    <span className="tooltiptext">{item[4]}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>)
                                        }
                                    </div>
                                ))}
                            </div> */}
                                    <Box sx={{ padding: '0 5px 0 5px' }}>
                                        <EachTable
                                            RegionData={groupedDataLeft}
                                            collapsedTables={collapsedTables}
                                            toggleTable={toggleTable}
                                            onClickTable={onClickTable}
                                            backgroundColors={backgroundColors}
                                        />

                                    </Box>

                                </>
                            )}

                        </Box>}
                    </Box>

                </Paper>
            </Container>



        </Box>
    </div>)
}

export default DRPage
