import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle, CrosshairMode, PriceScaleMode } from 'lightweight-charts';
import { useSelector } from "react-redux";

import { TextField, Container, InputAdornment, CircularProgress, Grid, Button, Box, IconButton, Autocomplete } from "@mui/material";
import { __fetching_data__ } from '../../components/api';
import ReactDOM from 'react-dom';
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../theme';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

import LoopIcon from '@mui/icons-material/Loop';

var selectedTimezone = 'Asia/Bangkok';
const App = ({ }) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const lineSeriesRef = useRef(new Map());
  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef([]);
  const [previousRanks, setPreviousRanks] = useState({});
  const previousRanksRef = useRef(previousRanks);
  const [lastRankChange, setLastRankChange] = useState({});
  const lastRankChangeRef = useRef(lastRankChange);
  const seriesDataRef = useRef(new Map());
  const isMounted = useRef(true);
  const isMounted2 = useRef(true);
  const originalColorsRef = useRef(new Map());
  const priceLinesRef = useRef(new Map());
  const csrfToken = useSelector((state) => state.csrfToken);
  const [selectedMarket, setSelectedMarket] = React.useState("SET100");
  const [type, setType] = useState("in");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useIsMobile();
  const isPTablet = useIsPortraitTablet();
  const isTablet = useIsTablet();
  const [inputValue, setInputValue] = useState('');


  const [allTimeRange, setAllTimeRange] = useState({ from: null, to: null });



  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    previousRanksRef.current = previousRanks;
  }, [previousRanks]);
  useEffect(() => {
    lastRankChangeRef.current = lastRankChange;
  }, [lastRankChange]);
  useEffect(() => {
    tableDataRef.current = tableData;
    console.log('table data now', tableData)
  }, [tableData]);

  // console.log('input is', inputValue);
  const getTodayDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const adjustToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const timezoneOffset = date.getTimezoneOffset() * 60;
    return timestamp - timezoneOffset;
  };

  useEffect(() => {
    isMounted2.current = true;
    return () => {
      isMounted2.current = false;
    };
  }, []);

  // const fetchHistoricalData = async () => {
  //   try {
  //     const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/today/${selectedMarket}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'xsrf-token': csrfToken
  //       },
  //     });

  //     const response = await fetch_response.json();
  //     console.log('rank is',response);

  //     const data = response.data[type];
  //     const historicalData = {};
  //     const todayDateString = getTodayDateString();
  //     const times = Object.keys(data.rank);
  //     times.sort();
  //     const latestTime = times[times.length - 1];
  //     const latestSymbols = Object.keys(data.rank[latestTime]);
  //     // เรียง symbol ตาม rank ก่อน
  //     const sortedSymbols = latestSymbols.sort((a, b) => data.rank[latestTime][a] - data.rank[latestTime][b]);
  //     // เลือกแค่ 5 อันดับแรกสำหรับกราฟ
  //     const top5Symbols = sortedSymbols.slice(0, 5);
  //     times.forEach((time) => {
  //       Object.keys(data.rank[time]).forEach((symbol) => {
  //         if (!historicalData[symbol]) {
  //           historicalData[symbol] = [];
  //         }
  //         const dateTimeString = `${todayDateString}T${time}`;
  //         const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

  //         historicalData[symbol].push({
  //           time: adjustToLocalTime(timestamp),
  //           rank: data.rank[time][symbol],
  //           value: data.value[time][symbol],
  //         });
  //       });
  //     });

  //     const newPreviousRanks = {};
  //     const newLastRankChange = {};
  //     top5Symbols.forEach((symbol) => {
  //       const symbolData = historicalData[symbol];

  //       if (!symbolData || symbolData.length === 0) {
  //         newPreviousRanks[symbol] = null;
  //         newLastRankChange[symbol] = null;
  //         return;
  //       }

  //       symbolData.sort((a, b) => a.time - b.time);
  //       let lastRankChangeRank = symbolData[0].rank;
  //       for (let i = 1; i < symbolData.length; i++) {
  //         if (symbolData[i].rank !== symbolData[i - 1].rank) {
  //           lastRankChangeRank = symbolData[i - 1].rank;
  //         }
  //       }

  //       const latestRank = symbolData[symbolData.length - 1].rank;

  //       newPreviousRanks[symbol] = latestRank;
  //       newLastRankChange[symbol] = lastRankChangeRank;

  //       let series = lineSeriesRef.current.get(symbol);
  //       if (!series) {
  //         const color = getRandomColor();
  //         series = chartRef.current.addLineSeries({
  //           color: color,
  //           lineWidth: 2,
  //           lastValueVisible: false,
  //           priceLineVisible: false
  //         });
  //         lineSeriesRef.current.set(symbol, series);
  //         originalColorsRef.current.set(symbol, color);
  //       }

  //       const seriesData = symbolData.map((dataPoint) => ({
  //         time: dataPoint.time,
  //         value: dataPoint.value,
  //       }));
  //       series.setData(seriesData);
  //       seriesDataRef.current.set(symbol, seriesData);

  //       const lastDataPoint = seriesData[seriesData.length - 1];
  //       if (lastDataPoint) {
  //         if (priceLinesRef.current.has(symbol)) {
  //           const oldPriceLine = priceLinesRef.current.get(symbol);
  //           series.removePriceLine(oldPriceLine);
  //         }

  //         const priceLine = series.createPriceLine({
  //           price: lastDataPoint.value,
  //           color: series.options().color,
  //           lineWidth: 1,
  //           lineStyle: LineStyle.Solid,
  //           axisLabelVisible: true,
  //           title: symbol,
  //           lineVisible: false,
  //         });
  //         priceLinesRef.current.set(symbol, priceLine);
  //       }
  //     });

  //     const allTimes = [];
  //     top5Symbols.forEach((symbol) => {
  //       const symbolData = historicalData[symbol];
  //       if (symbolData && symbolData.length > 0) {
  //         symbolData.forEach((dataPoint) => {
  //           allTimes.push(dataPoint.time);
  //         });
  //       }
  //     });

  //     if (allTimes.length > 0) {
  //       const minTime = Math.min(...allTimes);
  //       const maxTime = Math.max(...allTimes);

  //       setAllTimeRange({ from: minTime, to: maxTime });
  //       chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
  //       chartRef.current.timeScale().applyOptions({ rightOffset: 20 });
  //       chartRef.current.timeScale().fitContent();
  //     }

  //     setPreviousRanks(newPreviousRanks);
  //     setLastRankChange(newLastRankChange);

  //     const allSymbols = Array.from(lineSeriesRef.current.keys());
  //     const symbolsToRemove = allSymbols.filter(
  //       (symbol) => !top5Symbols.includes(symbol)
  //     );

  //     symbolsToRemove.forEach((symbol) => {
  //       const series = lineSeriesRef.current.get(symbol);
  //       if (series) {
  //         const priceLine = priceLinesRef.current.get(symbol);
  //         if (priceLine) {
  //           series.removePriceLine(priceLine);
  //           priceLinesRef.current.delete(symbol);
  //         }
  //         chartRef.current.removeSeries(series);
  //       }
  //       lineSeriesRef.current.delete(symbol);
  //       seriesDataRef.current.delete(symbol);
  //       originalColorsRef.current.delete(symbol);
  //     });


  //     top5Symbols.forEach((symbol) => {
  //       if (!lineSeriesRef.current.has(symbol)) {
  //         const color = getRandomColor();
  //         lineSeriesRef.current.set(
  //           symbol,
  //           chartRef.current.addLineSeries({
  //             color: color,
  //             lineWidth: 2,
  //           })
  //         );
  //         originalColorsRef.current.set(symbol, color);
  //       }
  //       const seriesData = historicalData[symbol].map((dataPoint) => ({
  //         time: dataPoint.time,
  //         value: dataPoint.value,
  //       }));
  //       lineSeriesRef.current.get(symbol).setData(seriesData);
  //       seriesDataRef.current.set(symbol, seriesData);
  //     });

  //     const updatedTableData = latestSymbols.slice(0, 20).map((symbol) => {
  //       const symbolData = historicalData[symbol];
  //       const latestData = symbolData[symbolData.length - 1];
  //       const latestRank = latestData.rank;
  //       const lastChangeRank = newLastRankChange[symbol];

  //       let color = '#fbfdb9';
  //       if (lastChangeRank != null) {
  //         if (latestRank < lastChangeRank) {
  //           color = "#affeb3";
  //         } else if (latestRank > lastChangeRank) {
  //           color = "#ffaba7";
  //         }
  //       }
  //       return {
  //         rank: latestRank,
  //         symbol,
  //         value: latestData.value,
  //         color,
  //       };
  //     });

  //     setTableData(updatedTableData);
  //     console.log('table now data', tableData);

  //   } catch (error) {
  //     console.error(`Error fetching historical data for ${selectedMarket}:`, error);
  //   }
  // };

  const fetchHistoricalData = async () => {
    try {
      if (!isMounted2.current) return;

      const fetch_response = await fetch(
        `${window.config.base_api_url}/money_in_out/today/${selectedMarket}`,
        {
          headers: {
            "Content-Type": "application/json",
            "xsrf-token": csrfToken,
          },
        }
      );

      const response = await fetch_response.json();
      console.log("rank is", response);

      if (!isMounted2.current) return;

      const data = response.data[type];
      console.log("rank data is", data);
      const historicalData = {};
      const todayDateString = getTodayDateString();
      const times = Object.keys(data.rank);
      times.sort();
      const latestTime = times[times.length - 1];
      const latestSymbols = Object.keys(data.rank[latestTime]);

      const sortedSymbols = latestSymbols.sort(
        (a, b) => data.rank[latestTime][a] - data.rank[latestTime][b]
      );
      const top5Symbols = sortedSymbols.slice(0, 5);
      console.log('top 5 is', top5Symbols);

      times.forEach((time) => {
        Object.keys(data.rank[time]).forEach((symbol) => {
          if (!historicalData[symbol]) {
            historicalData[symbol] = [];
          }
          const dateTimeString = `${todayDateString}T${time}`;
          const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

          historicalData[symbol].push({
            time: adjustToLocalTime(timestamp),
            rank: data.rank[time][symbol],
            value: data.value[time][symbol],
          });
        });
      });

      if (!isMounted2.current) return;

      const newPreviousRanks = {};
      const newLastRankChange = {};

      top5Symbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];

        if (!symbolData || symbolData.length === 0) {
          newPreviousRanks[symbol] = null;
          newLastRankChange[symbol] = null;
          return;
        }

        symbolData.sort((a, b) => a.time - b.time);
        let lastRankChangeRank = symbolData[0].rank;
        for (let i = 1; i < symbolData.length; i++) {
          if (symbolData[i].rank !== symbolData[i - 1].rank) {
            lastRankChangeRank = symbolData[i - 1].rank;
          }
        }

        const latestRank = symbolData[symbolData.length - 1].rank;
        console.log('last Rank', latestRank)

        newPreviousRanks[symbol] = latestRank;
        newLastRankChange[symbol] = lastRankChangeRank;

        let series = lineSeriesRef.current.get(symbol);
        if (!series) {
          const color = getRandomColor();
          series = chartRef.current.addLineSeries({
            color: color,
            lineWidth: 2,
            lastValueVisible: false,
            priceLineVisible: false,
          });
          lineSeriesRef.current.set(symbol, series);
          originalColorsRef.current.set(symbol, color);
        }

        const seriesData = symbolData.map((dataPoint) => ({
          time: dataPoint.time,
          value: dataPoint.value,
        }));
        series.setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);

        const lastDataPoint = seriesData[seriesData.length - 1];
        if (lastDataPoint) {
          if (priceLinesRef.current.has(symbol)) {
            const oldPriceLine = priceLinesRef.current.get(symbol);
            series.removePriceLine(oldPriceLine);
          }

          const priceLine = series.createPriceLine({
            price: lastDataPoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false,
          });
          priceLinesRef.current.set(symbol, priceLine);
        }
      });

      if (!isMounted2.current) return;

      const allTimes = [];
      top5Symbols.forEach((symbol) => {
        const symbolData = historicalData[symbol];
        if (symbolData && symbolData.length > 0) {
          symbolData.forEach((dataPoint) => {
            allTimes.push(dataPoint.time);
          });
        }
      });
      //   top5Symbols.forEach((symbol) => {
      //     const symbolData = historicalData[symbol];
      //     if (symbolData && Array.isArray(symbolData) && symbolData.length > 0) {
      //         symbolData.forEach((dataPoint) => {
      //             if (dataPoint.time !== null && dataPoint.time !== undefined) {
      //                 allTimes.push(dataPoint.time);
      //             }
      //         });
      //     }
      // });

      // if (allTimes.length > 0) {
      //   const minTime = Math.min(...allTimes);
      //   const maxTime = Math.max(...allTimes);

      //   setAllTimeRange({ from: minTime, to: maxTime });
      //   chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime }); //411
      //   chartRef.current.timeScale().applyOptions({ rightOffset: 20 });
      //   chartRef.current.timeScale().fitContent();
      // }
      console.log('all Time', allTimes);

      if (allTimes.length > 0 && allTimes.every(time => time !== null && time !== undefined)) {
        const minTime = Math.min(...allTimes);
        const maxTime = Math.max(...allTimes);

        setAllTimeRange({ from: minTime, to: maxTime });
        chartRef.current.timeScale().setVisibleRange({ from: minTime, to: maxTime });
        chartRef.current.timeScale().applyOptions({ rightOffset: 20 });
        chartRef.current.timeScale().fitContent();
      } else {
        console.warn("No valid time range found for selected market:", selectedMarket);
      }

      setPreviousRanks(newPreviousRanks);
      setLastRankChange(newLastRankChange);

      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(
        (symbol) => !top5Symbols.includes(symbol)
      );

      // symbolsToRemove.forEach((symbol) => {
      //   const series = lineSeriesRef.current.get(symbol);
      //   if (series) { // เช็คก่อนลบ
      //     const priceLine = priceLinesRef.current.get(symbol);
      //     if (priceLine) {
      //       series.removePriceLine(priceLine);
      //       priceLinesRef.current.delete(symbol);
      //     }
      //     if (chartRef.current && series) {
      //       chartRef.current.removeSeries(series); //460
      //     }
      //   }
      //   lineSeriesRef.current.delete(symbol);
      //   seriesDataRef.current.delete(symbol);
      //   originalColorsRef.current.delete(symbol);
      // });
      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) { 
            const priceLine = priceLinesRef.current.get(symbol);
            if (priceLine) {
                series.removePriceLine(priceLine);
                priceLinesRef.current.delete(symbol);
            }
            if (chartRef.current) {
                try {
                    chartRef.current.removeSeries(series);
                } catch (error) {
                    console.error("Error removing series for symbol:", symbol, error);
                }
            }
            lineSeriesRef.current.delete(symbol);
            seriesDataRef.current.delete(symbol);
            originalColorsRef.current.delete(symbol);
        } else {
            console.warn(`Series for ${symbol} not found in lineSeriesRef`);
        }
    });


      top5Symbols.forEach((symbol) => {
        if (!lineSeriesRef.current.has(symbol)) {
          const color = getRandomColor();
          lineSeriesRef.current.set(
            symbol,
            chartRef.current.addLineSeries({
              color: color,
              lineWidth: 2,
            })
          );
          originalColorsRef.current.set(symbol, color);
        }
        const seriesData = historicalData[symbol].map((dataPoint) => ({
          time: dataPoint.time,
          value: dataPoint.value,
        }));
        lineSeriesRef.current.get(symbol).setData(seriesData);
        seriesDataRef.current.set(symbol, seriesData);
      });

      if (!isMounted2.current) return;

      const updatedTableData = latestSymbols.slice(0, 20).map((symbol) => {
        const symbolData = historicalData[symbol];
        const latestData = symbolData[symbolData.length - 1];
        const latestRank = latestData.rank;
        const lastChangeRank = newLastRankChange[symbol];

        let color = "#fbfdb9";
        if (lastChangeRank != null) {
          if (latestRank < lastChangeRank) {
            color = "#affeb3";
          } else if (latestRank > lastChangeRank) {
            color = "#ffaba7";
          }
        }
        return {
          rank: latestRank,
          symbol,
          value: latestData.value,
          color,
        };
      });

      setTableData(updatedTableData);
      // console.log("table now data", tableData);
    } catch (error) {
      console.error(`Error fetching historical data for ${selectedMarket}:`, error);
    }
  };

  // const fetchCurrentData = async () => {
  //   try {
  //     if (Object.keys(previousRanksRef.current).length === 0) {
  //       console.warn('Previous ranks not initialized, skipping rank comparison.');
  //       return;
  //     }

  //     const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${selectedMarket}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'xsrf-token': csrfToken
  //       },
  //     });
  //     const response = await fetch_response.json();


  //     const data = response.data[type];
  //     console.log('data is', data);
  //     const timeKey = Object.keys(data.rank)[0];
  //     const todayDateString = getTodayDateString();
  //     const dateTimeString = `${todayDateString}T${timeKey}`;
  //     const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);


  //     const latestSymbols = Object.keys(data.rank[timeKey]);
  //     // เรียง symbol ตาม rank ก่อน
  //     // const sortedSymbols = latestSymbols.sort((a, b) => data.rank[latestTime][a] - data.rank[latestTime][b]);
  //     const sortedSymbols = latestSymbols.sort((a, b) => data.rank[timeKey][a] - data.rank[timeKey][b]);
  //     // เลือกแค่ 5 อันดับแรกสำหรับกราฟ
  //     const top5Symbols = sortedSymbols.slice(0, 5);

  //     const updatedTableData = latestSymbols.map((symbol) => {
  //       const currentRank = data.rank[timeKey][symbol];
  //       const lastChangeRank = lastRankChangeRef.current[symbol] || currentRank;

  //       const previousEntry = tableDataRef.current.find(
  //         (entry) => entry.symbol === symbol
  //       );
  //       const previousColor = previousEntry ? previousEntry.color : 'transparent';

  //       let color = previousColor;

  //       if (currentRank === lastChangeRank) {
  //         color = '#fbfdb9';
  //         lastRankChangeRef.current = {
  //           ...lastRankChangeRef.current,
  //           [symbol]: currentRank,
  //         };
  //       } else {
  //         if (currentRank < lastChangeRank) {
  //           color = '#affeb3';
  //         } else if (currentRank > lastChangeRank) {
  //           color = '#ffaba7';
  //         }
  //       }
  //       // กำหนดสีของตัวอักษรให้เป็นสีดำ
  //       const textColor = 'black';

  //       return {
  //         rank: currentRank,
  //         symbol,
  //         time: timeKey,
  //         value: data.value[timeKey][symbol],
  //         color,
  //       };
  //     });

  //     setTableData(updatedTableData);
  //     console.log('table data', tableData);

  //     const newPreviousRanks = {};
  //     updatedTableData.forEach((row) => {
  //       newPreviousRanks[row.symbol] = row.rank;
  //     });
  //     setPreviousRanks(newPreviousRanks);
  //     previousRanksRef.current = newPreviousRanks;

  //     const newLastRankChange = {};
  //     top5Symbols.forEach((symbol) => {
  //       if (lastRankChangeRef.current.hasOwnProperty(symbol)) {
  //         newLastRankChange[symbol] = lastRankChangeRef.current[symbol];
  //       }
  //     });
  //     setLastRankChange(newLastRankChange);
  //     lastRankChangeRef.current = newLastRankChange;

  //     const allSymbols = Array.from(lineSeriesRef.current.keys());
  //     const symbolsToRemove = allSymbols.filter(
  //       (symbol) => !top5Symbols.includes(symbol)
  //     );

  //     symbolsToRemove.forEach((symbol) => {
  //       const series = lineSeriesRef.current.get(symbol);
  //       if (series) {
  //         chartRef.current.removeSeries(series);
  //       }
  //       lineSeriesRef.current.delete(symbol);
  //       seriesDataRef.current.delete(symbol);
  //       originalColorsRef.current.delete(symbol);
  //     });

  //     top5Symbols.forEach((symbol) => {
  //       const updatePoint = {
  //         time: adjustToLocalTime(timestamp),
  //         value: data.value[timeKey][symbol],
  //       };

  //       let series = lineSeriesRef.current.get(symbol);
  //       if (series) {
  //         series.update(updatePoint);

  //         const existingData = seriesDataRef.current.get(symbol) || [];

  //         if (
  //           existingData.length > 0 &&
  //           existingData[existingData.length - 1].time === updatePoint.time
  //         ) {
  //           existingData[existingData.length - 1] = updatePoint;
  //         } else {
  //           existingData.push(updatePoint);
  //         }

  //         seriesDataRef.current.set(symbol, existingData);
  //         if (priceLinesRef.current.has(symbol)) {
  //           const oldPriceLine = priceLinesRef.current.get(symbol);
  //           series.removePriceLine(oldPriceLine);
  //         }

  //         const newPriceLine = series.createPriceLine({
  //           price: updatePoint.value,
  //           color: series.options().color,
  //           lineWidth: 1,
  //           lineStyle: LineStyle.Solid,
  //           axisLabelVisible: true,
  //           title: symbol,
  //           lineVisible: false,

  //         });

  //         priceLinesRef.current.set(symbol, newPriceLine);
  //       } else {
  //         const color = getRandomColor();
  //         series = chartRef.current.addLineSeries({
  //           color,
  //           lineWidth: 2,
  //           lastValueVisible: false,
  //           priceLineVisible: false
  //         });
  //         series.setData([updatePoint]);
  //         lineSeriesRef.current.set(symbol, series);
  //         originalColorsRef.current.set(symbol, color);
  //         seriesDataRef.current.set(symbol, [updatePoint]);

  //         const priceLine = series.createPriceLine({
  //           price: updatePoint.value,
  //           color: series.options().color,
  //           lineWidth: 1,
  //           lineStyle: LineStyle.Solid,
  //           axisLabelVisible: true,
  //           title: symbol,
  //           lineVisible: false,

  //         });
  //         priceLinesRef.current.set(symbol, priceLine);

  //       }

  //     });

  //   } catch (error) {
  //     console.error(`Error fetching current data for ${selectedMarket}:`, error);
  //   }
  // };



  const fetchCurrentData = async () => {
    try {
      if (!isMounted2.current) return;

      if (Object.keys(previousRanksRef.current).length === 0) {
        console.warn('Previous ranks not initialized, skipping rank comparison.');
        return;
      }

      const fetch_response = await fetch(`${window.config.base_api_url}/money_in_out/now/${selectedMarket}`, {
        headers: {
          'Content-Type': 'application/json',
          'xsrf-token': csrfToken
        },
      });
      const response = await fetch_response.json();
      if (!isMounted2.current) return;

      const data = response.data[type];
      console.log('data is', data);
      const timeKey = Object.keys(data.rank)[0];
      const todayDateString = getTodayDateString();
      const dateTimeString = `${todayDateString}T${timeKey}`;
      const timestamp = Math.floor(new Date(dateTimeString).getTime() / 1000);

      const latestSymbols = Object.keys(data.rank[timeKey]);
      const sortedSymbols = latestSymbols.sort((a, b) => data.rank[timeKey][a] - data.rank[timeKey][b]);
      const top5Symbols = sortedSymbols.slice(0, 5);

      const updatedTableData = latestSymbols.map((symbol) => {
        const currentRank = data.rank[timeKey][symbol];
        const lastChangeRank = lastRankChangeRef.current[symbol] || currentRank;

        const previousEntry = tableDataRef.current.find(
          (entry) => entry.symbol === symbol
        );
        const previousColor = previousEntry ? previousEntry.color : 'transparent';

        let color = previousColor;
        if (currentRank === lastChangeRank) {
          color = '#fbfdb9';
          lastRankChangeRef.current = {
            ...lastRankChangeRef.current,
            [symbol]: currentRank,
          };
        } else {
          color = currentRank < lastChangeRank ? '#affeb3' : '#ffaba7';
        }

        return {
          rank: currentRank,
          symbol,
          time: timeKey,
          value: data.value[timeKey][symbol],
          color,
        };
      });

      if (isMounted2.current) {
        setTableData(updatedTableData);
      }

      const newPreviousRanks = {};
      updatedTableData.forEach((row) => {
        newPreviousRanks[row.symbol] = row.rank;
      });

      if (isMounted2.current) {
        setPreviousRanks(newPreviousRanks);
        previousRanksRef.current = newPreviousRanks;
      }

      const newLastRankChange = {};
      top5Symbols.forEach((symbol) => {
        if (lastRankChangeRef.current.hasOwnProperty(symbol)) {
          newLastRankChange[symbol] = lastRankChangeRef.current[symbol];
        }
      });

      if (isMounted2.current) {
        setLastRankChange(newLastRankChange);
        lastRankChangeRef.current = newLastRankChange;
      }

      if (!chartRef.current) return;

      const allSymbols = Array.from(lineSeriesRef.current.keys());
      const symbolsToRemove = allSymbols.filter(
        (symbol) => !top5Symbols.includes(symbol)
      );

      symbolsToRemove.forEach((symbol) => {
        const series = lineSeriesRef.current.get(symbol);
        if (series) {
          try {
            chartRef.current.removeSeries(series);
          } catch (error) {
            console.warn("Failed to remove series:", error);
          }
          lineSeriesRef.current.delete(symbol);
          seriesDataRef.current.delete(symbol);
          originalColorsRef.current.delete(symbol);
        }
      });

      top5Symbols.forEach((symbol) => {
        const updatePoint = {
          time: adjustToLocalTime(timestamp),
          value: data.value[timeKey][symbol],
        };

        let series = lineSeriesRef.current.get(symbol);
        if (series && !series.isDisposed) {
          series.update(updatePoint);

          const existingData = seriesDataRef.current.get(symbol) || [];
          if (
            existingData.length > 0 &&
            existingData[existingData.length - 1].time === updatePoint.time
          ) {
            existingData[existingData.length - 1] = updatePoint;
          } else {
            existingData.push(updatePoint);
          }

          seriesDataRef.current.set(symbol, existingData);
          if (priceLinesRef.current.has(symbol)) {
            const oldPriceLine = priceLinesRef.current.get(symbol);
            series.removePriceLine(oldPriceLine);
          }

          const newPriceLine = series.createPriceLine({
            price: updatePoint.value,
            color: series.options().color,
            lineWidth: 1,
            lineStyle: LineStyle.Solid,
            axisLabelVisible: true,
            title: symbol,
            lineVisible: false,
          });

          priceLinesRef.current.set(symbol, newPriceLine);
        } else {
          const color = getRandomColor();
          try {
            series = chartRef.current.addLineSeries({
              color,
              lineWidth: 2,
              lastValueVisible: false,
              priceLineVisible: false,
            });
            series.setData([updatePoint]);
            lineSeriesRef.current.set(symbol, series);
            originalColorsRef.current.set(symbol, color);
            seriesDataRef.current.set(symbol, [updatePoint]);

            const priceLine = series.createPriceLine({
              price: updatePoint.value,
              color: series.options().color,
              lineWidth: 1,
              lineStyle: LineStyle.Solid,
              axisLabelVisible: true,
              title: symbol,
              lineVisible: false,
            });

            priceLinesRef.current.set(symbol, priceLine);
          } catch (error) {
            console.warn(`Failed to add series for ${symbol}:`, error);
          }
        }
      });

    } catch (error) {
      console.error(`Error fetching current data for ${selectedMarket}:`, error);
    }
  };

  const renderTable = (startIndex, endIndex) => (
    <table className="rank-tablee" style={{ fontSize: '11.5px', fontFamily: 'Arial, sans-serif', borderCollapse: 'collapse', }}>
      <thead style={{ backgroundColor: '#003366', color: 'white', fontSize: '14px', height: '40px', borderRadius: '10px' }}>
        <tr>
          <th>No.</th>
          <th>Symbol</th>
        </tr>
      </thead>
      <tbody>
        {tableData
          .sort((a, b) => a.rank - b.rank)
          .slice(startIndex, endIndex)
          .map((row, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: row.color,
                cursor: 'pointer',
                fontWeight: 'normal',
                height: '30px',
                color: 'black',
                textAlign: 'center',
                borderBottom: '1px solid #000'

              }}
            >
              <td>{row.rank}</td>
              <td onClick={() => {

                handleSendMessage(row.symbol);
                console.log('symbol is', row.symbol);
              }}>{row.symbol}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
  useEffect(() => {

    chartRef.current = createChart(chartContainerRef.current, {
      height: 340,
      width: chartContainerRef.current ? chartContainerRef.current.offsetWidth : 600,
      layout: {
        background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
        textColor: "#FFFFFF",
        borderRadius: '10px'
      },
      timeScale: {
        timeVisible: true,
        // rightOffset: 10,
      },
      crosshair: {
        horizontalLine: {
          visible: false,
        },
        verticalLine: {
          visible: true,
        },
      },
      grid: {
        vertLines: { visible: true, color: theme.palette.neutral.dark },
        horzLines: { visible: true, color: theme.palette.neutral.dark },
      },
    });

    chartRef.current.applyOptions({
      localization: {
        priceFormatter: (price) => (price / 1_000_000).toLocaleString('en-US', { maximumFractionDigits: 2 }),
      },
      priceScale: {
        mode: 0,
      },
    });

    const initializeData = async () => {
      // await fetchHistoricalData();
      // await fetchCurrentData();

      const now = new Date();
      const currentHour = now.getHours();

      if (currentHour >= 0 && currentHour < 10) {
        console.log("🕛 ใช้ข้อมูลจาก fetchHistoricalData เท่านั้น");
        await fetchHistoricalData();
      } else {
        console.log("⏳ โหลดทั้ง Historical และ Current Data");
        await fetchHistoricalData();
        await fetchCurrentData();
      }
    };
    initializeData();

    const intervalID = setInterval(() => {
      const now = new Date();
      const currentHour = now.getHours();

      if (currentHour >= 0 && currentHour < 10) {
        console.log("🛑 อยู่ในช่วง 00:00 - 09:59 ไม่ต้อง fetchCurrentData");
      } else {
        console.log("🔄 ดึงข้อมูลปัจจุบัน fetchCurrentData()");
        fetchCurrentData();
      }
    }, 10000);

    const resizeObserver = new ResizeObserver(() => {
      if (chartContainerRef.current && chartRef.current) {
        chartRef.current.resize(chartContainerRef.current.offsetWidth, chartContainerRef.current.offsetHeight);
      }
    });

    resizeObserver.observe(chartContainerRef.current);

    return () => {
      clearInterval(intervalID);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
      }
    };
  }, [selectedMarket, type]);
  ////////////////////

  const userDetail = useSelector((state) => state.userDetail);
  const base_api_url =
    process.env.NODE_ENV === "production"
      ? window.location.protocol === "https:"
        ? process.env.REACT_APP_API_HTTPS_URL
        : process.env.REACT_APP_API_HTTP_URL
      : "/application";
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [messages, setMessages] = useState([]);
  const [points, setPoints] = useState([])

  const resChatBoxRef = useRef(null);

  const LoadingIndicator = () => (
    <div>
      <CircularProgress sx={{ color: '#FFFFFF', position: 'relative', }} />
    </div>
  );
  const responses = [];

  // const handleSendMessage = async (symbol) => {
  //   setIsMessageSent(true);
  //   if (!symbol) return;
  //   // if (!symbol && !inputValue.trim()) return;
  //   // setInputValue(symbol || '');
  //   const chatbox = document.getElementById('chatbox');
  //   console.log('input before value is', inputValue);
  //   const value = { user_input: symbol || inputValue };
  //   const response = await __fetching_data__({ url: `${base_api_url}/chat_bot_report`, method: "POST", body: JSON.stringify(value) });
  //   if (response.status) {
  //     window.data = response.data;
  //     window.bdata = response.bdata;
  //     if (response.data.bot_response) {
  //       responses.push(response.data);
  //     } else if (response.data.plot_data) {
  //       const responseContainer = displayPlot(response.data, response.bdata);
  //       chatbox.appendChild(responseContainer);
  //     }
  //   }

  //   setIsMessageSent(false);
  // };

  useEffect(() => {
    if (inputValue) {
      console.log('Updated inputValue:', inputValue);
      sendMessage(inputValue);  // เรียก sendMessage หลัง state อัปเดตแล้ว
    }
  }, [inputValue]);

  const handleSendMessage = (symbol) => {
    setIsMessageSent(true);
    if (!symbol && !inputValue.trim()) return;
    setInputValue(symbol);

    console.log('input before value is', symbol);
    // console.log('symbol is', symbol);

    // เรียกฟังก์ชัน async แยกออกไป
    // sendMessage(symbol);
  };

  const sendMessage = async (symbol) => {
    const chatbox = document.getElementById('chatbox');
    const value = { user_input: symbol };

    // console.log('value is', value);

    const response = await __fetching_data__({
      url: `${base_api_url}/chat_bot_report`,
      method: "POST",
      body: JSON.stringify(value),
    });

    if (response.status) {
      window.data = response.data;
      window.bdata = response.bdata;
      if (response.data.bot_response) {
        responses.push(response.data);
      } else if (response.data.plot_data) {
        const responseContainer = displayPlot(response.data, response.bdata);
        chatbox.appendChild(responseContainer);
      }
    }

    setIsMessageSent(false);
  };



  const displayPlot = (response, bresponse, sense = 0.05) => {
    console.log('input value is', inputValue);
    const chatbox = document.getElementById('chatbox');
    const res_chat_box = document.getElementById('resChatBox');
    const plotData = response.plot_data;
    const bData = bresponse
    const symbol = plotData['symbol'];
    window.plotData = plotData
    console.log('bdata', bData)
    const responseContainer = document.createElement('div');
    const containerWidth = '33%';
    const containerHeight = 'auto';
    responseContainer.style.display = 'grid';
    responseContainer.style.gridTemplateColumns = `repeat(3, 1fr)`;
    responseContainer.style.gap = '0.5vw';
    // const chartWidth = chatbox.offsetWidth / 2.9;
    // const chartHeight = chatbox.offsetHeight / 4;
    const chartOption = {

      // width: chartWidth,
      // height: chartHeight,
      crosshair: {
        mode: 0,
      },
      timeScale: {
        visible: true,
        rightOffset: 5,
      }
    }
    clearPreviousCharts(chatbox);
    function clearPreviousCharts(chatbox) {
      const previousCharts = chatbox.querySelectorAll('.chart-container');
      previousCharts.forEach(chart => {
        chart.remove();
      });
    }
    function chartAnnotation(chart, text) {
      chart.applyOptions({
        watermark: {
          visible: true,
          fontSize: 24,
          horzAlign: 'left',
          vertAlign: 'top',
          color: 'rgb(0,0,0)',
          text: text,
        },
        rightPriceScale: {
          visible: true,
        },
        leftPriceScale: {
          visible: true,
          mode: PriceScaleMode.Normal,
          autoScale: false,
          invertScale: false,
          alignLabels: false,
          minValue: -1,
          maxValue: 1,
        },
      });
    }
    // const applyFullTimeRange = (chart, series) => {
    //   if (!series || series.length === 0) return;

    //   let minTime = Infinity;
    //   let maxTime = -Infinity;

    //   series.forEach(s => {
    //     const data = s.data() || [];
    //     if (data.length > 0) {
    //       minTime = Math.min(minTime, data[0].time);
    //       maxTime = Math.max(maxTime, data[data.length - 1].time);
    //     }
    //   });

    //   if (minTime !== Infinity && maxTime !== -Infinity) {
    //     chart.timeScale().setVisibleRange({
    //       from: minTime,
    //       to: maxTime
    //     });

    //     chart.timeScale().applyOptions({ rightOffset: 30 });
    //     chartRef.current.timeScale().fitContent();
    //   }
    // };

    const applyFullTimeRange = (chart, series) => {
      if (!Array.isArray(series) || series.length === 0) {
        console.error("❌ series ไม่ใช่ array หรือว่าง:", series);
        return;
      }

      let minTime = Infinity;
      let maxTime = -Infinity;

      series.forEach(s => {
        if (!s || typeof s !== "object") {
          console.warn("⚠️ series item ไม่ใช่ object:", s);
          return;
        }

        const data = seriesDataRef.current.get(s) || []; // หรือใช้ getSeriesData(s)
        if (data.length > 0) {
          minTime = Math.min(minTime, data[0].time);
          maxTime = Math.max(maxTime, data[data.length - 1].time);
        }
      });

      if (minTime !== Infinity && maxTime !== -Infinity) {
        chart.timeScale().setVisibleRange({
          from: minTime,
          to: maxTime
        });

        // chart.timeScale().applyOptions({ rightOffset: 30 });
        chartRef.current.timeScale().fitContent();
      }
    };

    // const applyFullTimeRange = (chart, series) => {
    //   if (!series || series.length === 0) return;

    //   let minTime = Infinity;
    //   let maxTime = -Infinity;

    //   series.forEach(s => {
    //     if (!s || typeof s !== "object" || typeof s.setData !== "function") {
    //       console.warn("⚠️ series item ไม่ใช่ Lightweight Charts series:", s);
    //       return;
    //     }

    //     // ใช้ getData() แทน data()
    //     const data = s.getData ? s.getData() : [];

    //     if (Array.isArray(data) && data.length > 0) {
    //       minTime = Math.min(minTime, data[0].time);
    //       maxTime = Math.max(maxTime, data[data.length - 1].time);
    //     }
    //   });

    //   if (minTime !== Infinity && maxTime !== -Infinity) {
    //     chart.timeScale().setVisibleRange({
    //       from: minTime,
    //       to: maxTime
    //     });

    //     chart.timeScale().applyOptions({ rightOffset: 30 });
    //     chartRef.current.timeScale().fitContent();
    //   }
    // };


    //Chart 1
    function generateChartLast() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');

      chartContainer.style.borderRadius = '20px';
      chartContainer.style.overflow = 'hidden';
      chartContainer.style.marginBottom = '3px';
      chartContainer.style.width = isMobile || isPTablet ? '400px' : '99%';
      // chartContainer.style.height = (isMobile || isTablet) ? '220px' : '250px';
      chartContainer.style.height = '40vh';

      chartContainer.setAttribute('id', `chart-1`)
      const chart = createChart(chartContainer,
        {
          ...chartOption,
          width: chartContainer.offsetWidth,
          height: chartContainer.offsetHeight,
          leftPriceScale: {
            visible: true
          },

          layout: {
            background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
            textColor: "#FFFFFF",
          },
        });

      const resizeObserver = new ResizeObserver(() => {
        if (chartContainer.clientWidth > 0 && chartContainer.clientHeight > 0) {
          chart.resize(chartContainer.clientWidth, chartContainer.clientHeight);
        }
      });

      resizeObserver.observe(chartContainer);
      // chartAnnotation(chart, 'Last')

      const headerText = document.createElement('div');
      headerText.textContent = 'LAST';

      const addDivHeader = document.createElement('div')
      addDivHeader.setAttribute('class', 'divHeader');
      addDivHeader.appendChild(headerText)
      chartContainer.appendChild(addDivHeader);

      const sumVal = plotData['price'].reduce((acc, dict) => {
        if ('value' in dict) {
          return acc + dict['value'];
        }
        return acc;
      }, 0);

      let close_series = [];
      if (sumVal === 0 || plotData['price'].length <= 1) {
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 14,
            horzAlign: 'center',
            vertAlign: 'center',
            color: 'grey',
            text: "Last:None",
          },
        });
      } else {
        close_series = chart.addLineSeries({ title: '', color: '#33ff66', lastValueVisible: true, priceLineVisible: false, priceScaleId: 'right', CrosshairMarkerVisible: false })
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 24,
            horzAlign: 'center',
            vertAlign: 'top',
            color: theme.palette.chart.watermark,
            text: `${inputValue}`,
          },
        });
        chart.applyOptions({
          priceScale: {
            position: 'left',
            mode: 1,
            autoScale: true,
            invertScale: false,
            alignLabels: true,
            borderVisible: true,
            entireTextOnly: true,
          },
          grid: {
            vertLines: { visible: true, color: theme.palette.neutral.dark },
            horzLines: { visible: true, color: theme.palette.neutral.dark },
          },
        });
        const dataForSeries = generateData(plotData['price'], 'time', 'value');
        close_series.setData(dataForSeries);
      }
      setTimeout(() => {
        chart.timeScale().applyOptions({ rightOffset: 5 });
      }, 100);
      return [chart, [close_series], chartContainer]
    }
    //Chart 2
    function generateChartFlow() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');
      chartContainer.classList.add('flow-chart');

      chartContainer.style.borderRadius = '20px';
      chartContainer.style.overflow = 'hidden';
      chartContainer.style.marginBottom = '3px';
      chartContainer.style.width = isMobile || isPTablet ? '400px' : '99%';
      // chartContainer.style.height = (isMobile || isTablet) ? '220px' : '250px';
      chartContainer.style.height = '40vh';


      let flow_series = [];
      let markers = []
      const filterContainer = document.createElement('div');
      filterContainer.setAttribute('style', 'position: relative; overflow: visible; z-index:3;')
      const buttonContainer = document.createElement('div');
      buttonContainer.setAttribute('style', 'padding-left: 0%; position: absolute; z-index: 1;"')
      filterContainer.appendChild(buttonContainer);
      chartContainer.appendChild(filterContainer);
      const chart = createChart(chartContainer, {
        ...chartOption,
        width: chartContainer.offsetWidth,
        height: chartContainer.offsetHeight,
        layout: {
          background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
          textColor: "#FFFFFF",
        },
      });

      const resizeObserver = new ResizeObserver(() => {
        if (chartContainer.clientWidth > 0 && chartContainer.clientHeight > 0) {
          chart.resize(chartContainer.clientWidth, chartContainer.clientHeight);
        }
      });

      resizeObserver.observe(chartContainer);

      chart.applyOptions({
        crosshair: {
          mode: CrosshairMode.Normal,
          horzLine: {
            labelVisible: false
          },
        }
      })
      // chart.priceScale('right').applyOptions({
      //   visible: false,
      //   textColor: '#F5F5F5',
      // });
      // chart.priceScale('left').applyOptions({
      //   visible: false,
      //   textColor: '#F5F5F5'
      // });
      chartAnnotation(chart, 'Predicted Trend');
      const headerText = document.createElement('div');
      headerText.innerHTML = 'PREDICTED<br>TREND';
      headerText.style.textAlign = 'left';
      // headerText.style.marginLeft = '-60px';
      headerText.style.marginTop = '0px';

      const addDivHeader = document.createElement('div')
      addDivHeader.setAttribute('class', 'divHeader');
      addDivHeader.appendChild(headerText)
      chartContainer.appendChild(addDivHeader);
      const sumVal = plotData['flow_accum'].reduce((acc, dict) => {
        if ('value' in dict) {
          return acc + dict['value'];
        }
        return acc;
      }, 0);
      if (sumVal === 0 || plotData['flow_accum'].length <= 1) {
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 14,
            horzAlign: 'left',
            vertAlign: 'center',
            color: 'grey',
            text: "Flow:None",
          },
        });
      } else {
        const series3_1 = chart.addLineSeries({ color: '#66ffff', lineStyle: 0, lineWidth: 3, lastValueVisible: false, priceLineVisible: false, });
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 24,
            horzAlign: 'center',
            vertAlign: 'top',
            color: theme.palette.chart.watermark,
            text: `${inputValue}`,
          },
          grid: {
            vertLines: { visible: true, color: theme.palette.neutral.dark },
            horzLines: { visible: true, color: theme.palette.neutral.dark },
          },

        });
        const dataForSeries_1 = generateData(plotData['flow_accum'], 'time', 'value');
        series3_1.setData(dataForSeries_1);
        series3_1.applyOptions({
          priceFormat: {
            type: 'volume',
          },
        });
        console.log('marker', response)
        flow_series = [series3_1]
        let max_data = plotData['max_peaks']
        const flow_value = plotData['flow_accum'].map(items => items.value)
        console.log('points', points)
        var point_data = response['plot_data']['point_data'].filter(e => e[8] == 'predicted_trend')
        if (point_data) {
          for (var i = 0; i < point_data.length; i++) {
            var point = point_data[i]
            let colorCode = ''
            let charCode = ''
            let barPosition = ''
            if (point[9] == 'A') {
              colorCode = '#008000'
              charCode = 'U'
              barPosition = 'aboveBar'
            } else if (point[9] == 'V') {
              colorCode = '#FF0000'
              charCode = 'D'
              barPosition = 'belowBar'
            }
            markers.push({
              'time': parseInt(point[12]),
              position: barPosition,
              color: colorCode,
              shape: 'circle',
              text: charCode
            })
          }
        }
        function compare(a, b) {
          if (a.time < b.time) {
            return -1;
          }
          if (a.time > b.time) {
            return 1;
          }
          return 0;
        }
        markers.sort(compare)
        series3_1.setMarkers(markers)
        let isControl = false
        window.addEventListener('keydown', (event) => {
          if (event.ctrlKey) {
            isControl = true
            if (noti_group_id == '') {
              noti_group_id = generateGuid()
            }
          }
        });
        window.addEventListener('keyup', (event) => {
          isControl = false
          noti_group_id = ''
        });
        chart.subscribeDblClick((param) => {
          console.log('plot', max_data)
          let max_peaks = max_data['price']['max']
          let min_peaks = max_data['price']['min']
          let b = false
          for (let i = 0; i < markers.length; i++) {
            let obj = markers[i];
            if (param['time'] == obj['time']) {
              b = true;
              markers.splice(i, 1)
              let valueSelect = ''
              for (let [key, value] of param['seriesData']) {
                valueSelect = value['value']
              }
              __fetching_data__({
                url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
                  {
                    "chart_name": "predicted_trend",
                    "value": valueSelect,
                    "method": "del",
                    "timestamp": param['time']
                  }
                )
              }).then((res) => {
                console.log('res', res)
              })
            }
          }
          if (!b) {
            console.log('Hello')
            let bMax = false
            let bMin = false
            let valueSelect = ''
            let valuePosition = ''
            let barPosition = ''
            for (let [key, value] of param['seriesData']) {
              if (max_peaks.includes(value['value'])) {
                valueSelect = value['value']
                valuePosition = 'A'
                bMax = true
                barPosition = 'aboveBar'
              } else if (min_peaks.includes(value['value'])) {
                valueSelect = value['value']
                valuePosition = 'V'
                bMin = true
                barPosition = 'belowBar'
              }
            }
            let colorCode = ''
            let charCode = ''
            if (isControl) {
              if (bMax) {
                colorCode = '#00FF00'
                charCode = 'U'
              } else if (bMin) {
                colorCode = '#7b241c'
                charCode = 'D'
              }
            } else {
              if (bMax) {
                colorCode = '#008000'
                charCode = 'U'
              } else if (bMin) {
                colorCode = '#FF0000'
                charCode = 'D'
              }
            }
            markers = [...markers, {
              'time': param['time'],
              position: barPosition,
              color: colorCode,
              shape: 'circle',
              text: charCode
            }]

            __fetching_data__({
              url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
                {
                  "chart_name": "predicted_trend",
                  "value": valueSelect,
                  "position": valuePosition,
                  "last_value": flow_value[flow_value.length - 1],
                  "method": "add",
                  "symbol": plotData['symbol'],
                  "timestamp": param['time'],
                  'group_id': noti_group_id
                }
              )
            }).then((res) => {
              console.log('res', res)
            })
          }
          console.log('time', markers)
          function compare(a, b) {
            if (a.time < b.time) {
              return -1;
            }
            if (a.time > b.time) {
              return 1;
            }
            return 0;
          }
          markers.sort(compare)
          series3_1.setMarkers(markers)
        });
      }
      setTimeout(() => {
        chart.timeScale().applyOptions({ rightOffset: 5 });
      }, 100);
      return [chart, flow_series, chartContainer];
    }
    window.generateChartFlow = generateChartFlow;

    function generateChartShort() {
      const chartContainer = document.createElement('div');
      chartContainer.classList.add('chart-container');

      chartContainer.style.borderRadius = '20px';
      chartContainer.style.overflow = 'hidden';
      chartContainer.style.marginBottom = '3px';
      chartContainer.style.width = isMobile || isPTablet ? '400px' : '99%';
      // chartContainer.style.height = (isMobile || isTablet) ? '220px' : '250px';
      chartContainer.style.height = '40vh';

      const chart = createChart(chartContainer, {
        ...chartOption,
        leftPriceScale: {
          visible: true
        },
        layout: {
          background: { type: 'solid', color: 'rgba(53, 60, 83, 1)' },
          textColor: "#FFFFFF",
        },
      });

      const resizeObserver = new ResizeObserver(() => {
        if (chartContainer.clientWidth > 0 && chartContainer.clientHeight > 0) {
          chart.resize(chartContainer.clientWidth, chartContainer.clientHeight);
        }
      });

      resizeObserver.observe(chartContainer);
      // chartAnnotation(chart, '%short');

      console.log("plotdata", plotData)
      const sumVal = plotData['short'].reduce((acc, dict) => {
        return 'value' in dict ? acc + dict['value'] : acc;
      }, 0);

      let short_series = [];
      let short_series1 = [];
      let markers = []

      if (sumVal === 0 || plotData['short'].length <= 1) {
        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 28,
            horzAlign: 'center',
            vertAlign: 'center',
            color: 'grey',
            text: "%Short: None",
          },
        });
      } else {
        const dataForSeries = generateData(plotData['short'], 'time', 'value');
        short_series = chart.addLineSeries({ color: '#ff9933', lastValueVisible: true, title: '% daily short', priceLineVisible: false });
        short_series.setData(dataForSeries);

        const dataForSeries1 = generateData(plotData['outstanding_short'], 'time', 'value');
        short_series1 = chart.addLineSeries({ color: '#33ff66', lastValueVisible: true, title: 'outstandingshort', priceLineVisible: false, priceScaleId: 'left', });
        short_series1.setData(dataForSeries1);
        short_series1.applyOptions({
          priceFormat: {
            type: 'volume',
          }
        })

        chart.applyOptions({
          watermark: {
            visible: true,
            fontSize: 24,
            color: theme.palette.chart.watermark,
            text: `${inputValue}`,
            horzAlign: 'center',
            vertAlign: 'top',
          },
          grid: {
            vertLines: { visible: true, color: theme.palette.neutral.dark },
            horzLines: { visible: true, color: theme.palette.neutral.dark },
          },
        });

        chart.priceScale('left').applyOptions({
          autoScale: true,
          scaleMargins: { top: 0.1, bottom: 0.1 }
        })

      }
      const series = [short_series1, short_series]
      let titlesVisible = false;
      const toggleButton = document.createElement('button');
      toggleButton.textContent = 'Show/Hide';
      toggleButton.style.marginRight = '-30px';
      toggleButton.style.padding = '2px 3px';
      toggleButton.style.backgroundColor = '#4CAF50'; // สีเขียวอ่อน
      toggleButton.style.color = 'white';
      toggleButton.style.height = 'fit-content';
      toggleButton.style.border = 'none';
      toggleButton.style.borderRadius = '5px'; // ทำให้ปุ่มมน
      toggleButton.style.cursor = 'pointer';
      toggleButton.style.fontSize = '14px';
      // toggleButton.style.fontWeight = 'bold';
      // toggleButton.style.transition = '0.3s';
      toggleButton.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';

      // เอฟเฟกต์เมื่อโฮเวอร์ (hover)
      toggleButton.addEventListener('mouseover', () => {
        toggleButton.style.backgroundColor = '#45a049'; // สีเขียวเข้มขึ้น
      });
      toggleButton.addEventListener('mouseout', () => {
        toggleButton.style.backgroundColor = '#4CAF50'; // กลับเป็นสีเดิม
      });
      const headerText = document.createElement('div');
      headerText.textContent = '%SHORT';



      const reactButtonContainer = document.createElement('div');
      reactButtonContainer.setAttribute('class', 'reactButtonContainer');
      // reactButtonContainer.style.position = 'absolute';
      // reactButtonContainer.style.top = '10px';
      // reactButtonContainer.style.right = '10px';
      ReactDOM.render(
        <IconButton
          onClick={() => handleResetZoom2()}
          sx={{
            color: 'white',
            // position: 'absolute',
            zIndex: 111,
            right: '10px',
            top: isMobile || isTablet ? '30px' : '10px',
            backgroundColor: "transparent",
            "&:hover": { backgroundColor: "transparent" }
          }}
        >
          <LoopIcon />
        </IconButton>,
        reactButtonContainer
      );

      const addDivHeader = document.createElement('div')
      // addDivHeader.style.width = '100% !important'
      addDivHeader.setAttribute('class', 'divHeader');
      addDivHeader.appendChild(headerText)

      addDivHeader.appendChild(toggleButton)
      // addDivHeader.appendChild(reactButtonContainer);
      chartContainer.appendChild(addDivHeader);
      chartContainer.appendChild(reactButtonContainer);
      toggleButton.addEventListener('click', () => {
        titlesVisible = !titlesVisible;
        const buttonText = titlesVisible ? 'Hide/Show All' : 'Show/Hide All';
        toggleButton.textContent = buttonText;

        toggleButton.textContent = 'Show/Hide All';
        toggleButton.style.padding = '2px 3px';
        toggleButton.style.backgroundColor = '#4CAF50'; // สีเขียวอ่อน
        toggleButton.style.color = 'white';
        toggleButton.style.border = 'none';
        toggleButton.style.borderRadius = '5px'; // ทำให้ปุ่มมน
        toggleButton.style.cursor = 'pointer';
        toggleButton.style.fontSize = '14px';
        // toggleButton.style.fontWeight = 'bold';
        // toggleButton.style.transition = '0.3s';
        toggleButton.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';

        // เอฟเฟกต์เมื่อโฮเวอร์ (hover)
        toggleButton.addEventListener('mouseover', () => {
          toggleButton.style.backgroundColor = '#45a049'; // สีเขียวเข้มขึ้น
        });
        toggleButton.addEventListener('mouseout', () => {
          toggleButton.style.backgroundColor = '#4CAF50'; // กลับเป็นสีเดิม
        });

        series.forEach(s => {
          if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
            s.applyOptions({
              title: titlesVisible ? s.options().title : '',
            });
          } else if (s.options().title === '') {
            if (s === short_series) {
              s.applyOptions({

                title: titlesVisible ? '% daily short' : '',
              });
            } else if (s === short_series1) {
              s.applyOptions({
                title: titlesVisible ? 'outstandingshort' : '',
              });
            }
          }
        });
      });

      series.forEach(s => {
        if (s.length == 0) {
          return;
        }
        if (s.options().title === 'outstandingshort' || s.options().title === '% daily short') {
          s.applyOptions({
            title: titlesVisible ? s.options().title : '',
          });
        } else if (s.options().title === '') {
          if (s === short_series) {
            s.applyOptions({
              title: titlesVisible ? 'outstandingshort' : '',
            });
          } else if (s === short_series1) {
            s.applyOptions({
              title: titlesVisible ? '% daily short' : '',
            });
          }
        }
      });

      var point_data = response['plot_data']['point_data'].filter(e => e[8] == 'short')
      if (point_data) {
        for (var i = 0; i < point_data.length; i++) {
          var point = point_data[i]
          let colorCode = ''
          let charCode = ''
          let barPosition = ''
          if (point[9] == 'A') {
            colorCode = '#008000'
            charCode = 'U'
            barPosition = 'aboveBar'
          } else if (point[9] == 'V') {
            colorCode = '#FF0000'
            charCode = 'D'
            barPosition = 'belowBar'
          }
          markers.push({
            'time': parseInt(point[12]),
            position: barPosition,
            color: colorCode,
            shape: 'circle',
            text: charCode
          })
        }
      }
      function compare(a, b) {
        if (a.time < b.time) {
          return -1;
        }
        if (a.time > b.time) {
          return 1;
        }
        return 0;
      }
      markers.sort(compare)
      if (short_series1.length > 0) {
        short_series1.setMarkers(markers)
      }

      let max_data = plotData['max_peaks']
      const flow_value = plotData['flow_accum'].map(items => items.value)

      let isControl = false
      window.addEventListener('keydown', (event) => {
        if (event.ctrlKey) {
          isControl = true
          if (noti_group_id == '') {
            noti_group_id = generateGuid()
          }
        }
      });
      window.addEventListener('keyup', (event) => {
        isControl = false
        noti_group_id = ''
      });

      chart.subscribeDblClick((param) => {
        let max_peaks = max_data['outstanding_short']['max']
        let min_peaks = max_data['outstanding_short']['min']
        let b = false
        for (let i = 0; i < markers.length; i++) {
          let obj = markers[i];
          if (param['time'] == obj['time']) {
            b = true;
            markers.splice(i, 1)
            let valueSelect = ''
            for (let [key, value] of param['seriesData']) {
              valueSelect = value['value']
            }
            __fetching_data__({
              url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
                {
                  "chart_name": "short",
                  "value": valueSelect,
                  "method": "del",
                  "timestamp": param['time']
                }
              )
            }).then((res) => {
              console.log('res', res)
            })
          }
        }
        if (!b) {
          let bMax = false
          let bMin = false
          let valueSelect = ''
          let valuePosition = ''
          let barPosition = ''
          for (let [key, value] of param['seriesData']) {
            if (max_peaks.includes(value['value'])) {
              valueSelect = value['value']
              valuePosition = 'A'
              bMax = true
              barPosition = 'aboveBar'
            } else if (min_peaks.includes(value['value'])) {
              valueSelect = value['value']
              valuePosition = 'V'
              bMin = true
              barPosition = 'belowBar'
            }
          }
          let colorCode = ''
          let charCode = ''
          if (isControl) {
            if (bMax) {
              colorCode = '#00FF00'
              charCode = 'U'
            } else if (bMin) {
              colorCode = '#7b241c'
              charCode = 'D'
            }
          } else {
            if (bMax) {
              colorCode = '#008000'
              charCode = 'U'
            } else if (bMin) {
              colorCode = '#FF0000'
              charCode = 'D'
            }
          }
          markers = [...markers, {
            'time': param['time'],
            position: barPosition,
            color: colorCode,
            shape: 'circle',
            text: charCode
          }]
          __fetching_data__({
            url: `${base_api_url}/chat_bot_save_point`, method: "POST", body: JSON.stringify(
              {
                "chart_name": "short",
                "value": valueSelect,
                "position": valuePosition,
                "last_value": flow_value[flow_value.length - 1],
                "method": "add",
                "symbol": plotData['symbol'],
                "timestamp": param['time'],
                'group_id': noti_group_id
              }
            )
          }).then((res) => {
            console.log('res', res)
          })
        }
        console.log('time', markers)
        function compare(a, b) {
          if (a.time < b.time) {
            return -1;
          }
          if (a.time > b.time) {
            return 1;
          }
          return 0;
        }
        markers.sort(compare)
        short_series1.setMarkers(markers)
      });
      setTimeout(() => {
        chart.timeScale().applyOptions({ rightOffset: 5 });
      }, 100);
      return [chart, [short_series, short_series1], chartContainer];
    }
    const [chart1, series1, chartContainer1] = generateChartLast();
    applyFullTimeRange(chart1, series1);
    const [chart4, series4, chartContainer4] = generateChartFlow(sense);
    applyFullTimeRange(chart4, series4);
    const [chart8, series8, chartContainer8] = generateChartShort();
    // console.log(chart8, 'chart short');
    // console.log(series8, 'series short');
    console.log('symbol is', inputValue);

    applyFullTimeRange(chart8, series8);

    const handleResetZoom2 = () => {
      console.log("Resetting zoom for all charts...");
      applyFullTimeRange(chart1, series1);
      applyFullTimeRange(chart4, series4);
      applyFullTimeRange(chart8, series8);

    };







    let allChartContainers;
    let allGenerators;
    let allSeries;
    let charts;
    if (userDetail.permissions && userDetail.permissions.permission && userDetail.permissions.permission.chatBot9Chanel && userDetail.permissions.permission.chatBot9Chanel.read) {
      allChartContainers = [chartContainer1, chartContainer4, chartContainer8];
      allGenerators = [generateChartLast, generateChartFlow, generateChartShort];
      allSeries = [series1, series4, series8];
      charts = [chart1, chart4, chart8];
    } else {
      return
    }
    function syncChart() {
      charts.forEach((currentChart, currentIndex) => {
        currentChart.timeScale().subscribeVisibleLogicalRangeChange(timeRange => {
          charts.forEach((otherChart, otherIndex) => {
            if (currentIndex !== otherIndex) {
              otherChart.timeScale().setVisibleLogicalRange(timeRange);
            }
          });
        });
      });

      charts.forEach((currentChart, currentIndex) => {
        currentChart.subscribeCrosshairMove(param => {
          if (!allSeries[currentIndex] || !allSeries[currentIndex][0]) return;
          const dataPoint = getCrosshairDataPoint(allSeries[currentIndex][0], param);
          if (!dataPoint) return;

          charts.forEach((otherChart, otherIndex) => {
            if (currentIndex !== otherIndex && allSeries[otherIndex] && allSeries[otherIndex][0]) {
              allSeries[otherIndex].forEach(otherSeries => {
                syncCrosshair(otherChart, otherSeries, dataPoint);
              });
            }
          });
        });
      });
    }
    syncChart()
    window.syncChart = syncChart;
    allChartContainers.forEach((container, index) => {
      responseContainer.appendChild(container);
    });
    const divElements = responseContainer.querySelectorAll("div.tv-lightweight-charts > table > tr:nth-child(2) > td:nth-child(2) > div");
    divElements.forEach(element => { });
    return responseContainer;
  }
  let noti_group_id = ''
  function generateGuid() {
    return Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  }
  function generateData(data, date_columm = 'date', field = 'value') {
    const timeZoneOffset = 7 * 60 * 60 * 1000;
    const cdata = data.map(d => {
      let time = timeToTz(d[date_columm], selectedTimezone)
      let time2 = (new Date(d[date_columm]).getTime() + timeZoneOffset) / 1000

      return { time: time2, value: parseFloat(d[field]) || 0 }
    });
    return cdata;
  }
  function timeToTz(originalTime, timeZone) {
    const zonedDate = new Date(new Date(originalTime).toLocaleString('en-US', { timeZone }));
    const options = {
      timeZone: timeZone,
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    return zonedDate.getTime() / 1000;
  }
  let colorIndex = 0;
  const softDarkColors = [
    "#ffcc00", // เหลืองสดใส (Vivid Yellow)
    "#00c3ff", // ฟ้าสด (Bright Cyan Blue)
    "#00ff99", // เขียวมิ้นต์สด (Vivid Mint Green)
    "#ff9966", // ส้มสด (Bright Orange)
    "#ffcc33", // เหลืองทองสด (Vivid Golden Yellow)
    "#ffffff", // ขาว (White)
    "#ffff66", // เหลืองเลม่อนสด (Lemon Yellow)
    "#ff66b3", // ชมพูสดใส (Hot Pink)
    "#33ffcc", // เขียวฟ้านีออน (Neon Aqua)
    "#3399ff", // ฟ้าสดใส (Sky Blue)
    "#ff9933", // ส้มอ่อนสด (Bright Peach)
    "#ff3399", // ชมพูบานเย็น (Magenta Pink)
    "#33ff66", // เขียวสด (Vivid Green)
    "#ff9966", // พีชสดใส (Peach Orange)
    "#66ffff", // ฟ้าน้ำทะเลสด (Neon Blue)
    "#ccff33", // เหลืองเขียวสะท้อนแสง (Lime Green)
    "#66ccff", // ฟ้าสดกลาง (Electric Blue)
    "#9966ff", // ม่วงสด (Bright Purple)
    "#ccff66", // เหลืองมะนาวสด (Neon Lemon)
    "#66ffcc"  // เขียวฟ้าสดใส (Turquoise)
  ];
  const getRandomColor = () => {
    const color = softDarkColors[colorIndex];
    colorIndex = (colorIndex + 1) % softDarkColors.length; // วนกลับไปที่ index 0 เมื่อถึงตัวสุดท้าย
    return color;
  };
  function syncCrosshair(chart, series, dataPoint) {
    if (dataPoint) {
      chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
      return;
    }
    chart.clearCrosshairPosition();
  }
  function getCrosshairDataPoint(series, param) {
    if (!param.time) {
      return null;
    }
    const dataPoint = param.seriesData.get(series);
    return dataPoint || null;
  }
  const handleDropdownChange = (event) => {
    setSelectedMarket(event.target.value);
  };

  const handleResetZoom1 = () => {
    if (chartRef.current && allTimeRange.from && allTimeRange.to) {
      chartRef.current.timeScale().setVisibleRange({
        from: allTimeRange.from,
        to: allTimeRange.to,
      });

      // ✅ ใช้ applyOptions เพื่อเพิ่ม rightOffset อีกครั้ง
      chartRef.current.timeScale().applyOptions({ rightOffset: 20 });

      chartRef.current.timeScale().fitContent();
    }
  }







  const marketOptions = ["SET100", "NON-SET100", "MAI", "WARRANT"];
  return (
    <div className="dashboard" style={{ padding: '0px 5px' }}>
      <div style={{ width: '100%', height: '100%' }}>
        <h1 style={{ textAlign: 'center', marginTop: '-5px', fontFamily: 'Arial, sans-serif' }}>Index</h1>
        <div style={{ marginBottom: '5px', }}>
          {/* <label htmlFor="market-select" style={{ fontSize: '16px', marginRight: '10px' }}>Select Market: </label>
          <select
            id="market-select"
            value={selectedMarket}
            onChange={handleDropdownChange}
            style={{
              // padding: '8px 12px',
              borderRadius: '6px',
              border: '1px solid #ccc',
              backgroundColor: '#fff',
              fontSize: '16px',
              cursor: 'pointer',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
              transition: 'all 0.3s ease',
              marginRight: '5px',
            }}
            onMouseOver={(e) => (e.target.style.borderColor = '#007bff')}
            onMouseOut={(e) => (e.target.style.borderColor = '#ccc')}
          >
            <option value="SET100">SET100</option>
            <option value="NON-SET100">NON-SET100</option>
            <option value="MAI">MAI</option>
            <option value="WARRANT">WARRANT</option>
          </select> */}
          <Autocomplete
            size='small'
            disableClearable
            id="market-autocomplete"
            options={marketOptions}
            value={selectedMarket}
            onChange={(event, newValue) => setSelectedMarket(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Select Market" variant="outlined" />
            )}
            sx={{
              display: 'flex',
              justifyContent: (isMobile || isPTablet) ? 'center' : 'unset',
              width: '150px',
              ml: '10px',
              borderRadius: "6px",
              fontSize: "16px",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
              transition: "all 0.3s ease",
              "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#007bff" },
            }}
          />
          {/* <button
            onClick={() => {
              if (chartRef.current && allTimeRange.from && allTimeRange.to) {
                chartRef.current.timeScale().setVisibleRange({
                  from: allTimeRange.from,
                  to: allTimeRange.to,
                });

                // ✅ ใช้ applyOptions เพื่อเพิ่ม rightOffset อีกครั้ง
                chartRef.current.timeScale().applyOptions({ rightOffset: 20 });
              }
            }}
            className="nav-button reset-zoom"
            style={{ borderRadius: '10px', backgroundColor: '#FF5733', color: '#FFFFFF', padding: '8px 16px' }}
          >
            Reset Zoom
          </button> */}
        </div>
        {/* <br /> */}
        <div className="chart-section" style={{ fontFamily: 'Arial, sans-serif' }}>
          <Box className="chart-table-container" sx={{
            display: 'flex', flexDirection: 'row',
            gap: '20px',
            fontFamily: 'Arial, sans-serif',
            backgroundColor: theme.palette.background.container1,
            padding: '10px',
            borderRadius: '10px',
            border: '1px solid #ccc',
          }}>
            <div style={{
              width: '70%',
              maxHeight: '340px',
              // position: 'relative',
              fontFamily: 'Arial, sans-serif',
              // overflowY: 'hidden',
              border: '1px solid #ccc',
              borderRadius: '10px',
              // padding: '10px'
            }}>


              <div className="chart-container" style={{
                maxHeight: '338px',
                borderRadius: '10px',
                overflow: 'hidden',
                position: 'relative'
              }}
                ref={chartContainerRef}>
                <IconButton children={<LoopIcon />} onClick={() => handleResetZoom1()}
                  sx={{
                    position: 'absolute',
                    right: '10%',
                    top: '10px',
                    zIndex: 101,
                    backgroundColor: "#575b69",
                    // padding: '4px',
                    // margin: "1%",
                    "&:hover": { backgroundColor: 'transparent' }
                  }} />


              </div>
            </div>

            {(!isMobile && !isPTablet) && (<>
              <div className="table-container"
                style={{
                  width: '15%',
                  display: 'flex',
                  flexDirection: 'column',
                  // gap: '10px',
                  fontFamily: 'Arial, sans-serif',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  border: '1px solid #ddd',
                }}>
                {renderTable(0, 10)}
              </div>
              <div className="table-container"
                style={{
                  width: '15%',
                  display: 'flex',
                  flexDirection: 'column',
                  // gap: '10px',
                  fontFamily: 'Arial, sans-serif',
                  borderRadius: '5px',
                  overflow: 'hidden',
                  border: '1px solid #ddd',
                }}>
                {renderTable(10, 20)}
              </div>
            </>)}

            {(isMobile || isPTablet) && (
              <Box className="table-container"
                sx={{
                  width: '30%',
                  display: 'flex',
                  flexDirection: 'column',
                  // gap: '10px',
                  fontFamily: 'Arial, sans-serif',
                  borderRadius: '5px',
                  overflowY: 'auto',
                  '::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                    borderRadius: '4px',
                  },
                  '::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                  maxHeight: '340px',
                  border: '1px solid #ddd',
                }}>
                {renderTable(0, 20)}
              </Box>

            )}
          </Box>
        </div>
        <br />
        <style>
          {`
    .divHeader {
  position: relative;
  top: -96%;
  left: 16%;
  width: 60%;
  display: flex;
  justify-content: space-between;
  z-index: 90000000 !important;
  }

  .reactButtonContainer {
    position: relative;
    top: -105%;
    left: 16%;
    width: 75%;
    display: flex;
    justify-content: flex-end;
    z-index: 1111 !important;
    }

  `}
        </style>
        <Grid style={{ height: '42vh', display: 'flex', flexDirection: 'column' }}>

          <Box
            sx={{
              // marginTop: '-10px',
              width: '100%',
              height: '120%',
              display: 'flex',
              overflowY: 'hidden',

              border: '1px solid #ccc',
              padding: '5px',
              borderRadius: '10px',
              backgroundColor: theme.palette.background.container1
            }}>
            {isMessageSent && (<LoadingIndicator />)}
            <Box id="chatbox" sx={{ width: '100%', height: '100%', }}></Box>
          </Box>
        </Grid>
      </div>
    </div>
  );
};

export default App;