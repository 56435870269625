import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import { tokens } from "../../theme";
import { tokens } from "../../theme";
import { useTheme, Box, Container, Grid, Card, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper } from '@mui/material';
// import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import CardContent from '@mui/material/CardContent';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// import StatBox from "../../components/StatBox";
import EmailIcon from "@mui/icons-material/Email";

import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import { useIsMobile, useIsPortraitTablet, useIsTablet } from '../../components/hooks';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/es-us';
import { __fetching_data__ } from '../../components/api.js';

import dayjs from 'dayjs';

import { Formik } from 'formik'
import * as yup from "yup";


import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import TableContainer from '@mui/material/TableContainer';
import Slider from '@mui/material/Slider';

import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import Preloader from '../../components/Preloader';
import Preloading from '../../components/Preloading';

import { useProSidebar } from "react-pro-sidebar";

// import './bidask.css'
// import './index.css'

const base_api_url =
    process.env.NODE_ENV === "production"
        ? window.location.protocol === "https:"
            ? process.env.REACT_APP_API_HTTPS_URL
            : process.env.REACT_APP_API_HTTP_URL
        : "/application";

function createData(vol_bid, bid, ask, vol_ask) {
    return { vol_bid, bid, ask, vol_ask };
}

function createDataTickmatch(open, high, low, close) {
    return { open, high, low, close };
}

const BidAskComponent2 = ({ start_date, start_time, onClickSearch, symbol, onPlotGraph: onPlotGraph }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const csrfToken = useSelector((state) => state.csrfToken);
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const formatted_now = now.format('YYYY-MM-DD HH:mm:ss');
    const today_value = dayjs(today).subtract(0, 'day');

    const [stHour, stMinute] = [10, 0];
    const [enHour, enMinute] = [16, 30];

    const isMobile = useIsMobile();
    const isPTablet = useIsPortraitTablet();
    const isTablet = useIsTablet();


    const stEnTimeMin = dayjs().set('hour', stHour).set('minute', stMinute);
    const stEnTimeMax = dayjs().set('hour', enHour).set('minute', enMinute);

    const [loading, setLoading] = useState(false);

    const initialRow = [
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData(0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
        createData("", "", "", ""),
        createData("Sum Bid", "", "", "Sum Ask"),
        createData(0, "", "", 0),
    ]
    const initialInRange = [
        createDataTickmatch(0, 0, 0, 0),
    ]
    const initialActual = [
        createDataTickmatch(0, 0, 0, 0),
    ]

    const [rows, setRows] = useState(initialRow);
    const [rowsInRange, setRowsInRange] = useState(initialInRange);
    const [rowsActual, setRowsActual] = useState(initialActual);

    const [initialValues, setInitialValues] = useState({
        start_date: today_value,
        end_date: today_value,
        start_time: stEnTimeMin,
        end_time: stEnTimeMax,
        symbol: symbol,
        speed: 1
    })

    const formatTime = (time) => {
        if (typeof time === 'object') {
            return `${dayjs(time).hour()}:${String(dayjs(time).minute()).padStart(2, '0')}`;
        }
        return time;
    };
    const formatDate = (date) => {
        if (typeof date === 'object') {
            return `${dayjs(date).year()}-${String(dayjs(date).month() + 1).padStart(2, '0')}-${String(dayjs(date).date()).padStart(2, '0')}`;
        }
        return date;
    };
    const objectToQueryString = (obj) => {
        return Object.keys(obj)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
            .join('&');
    }

    const [countRecordBidAsk, setCountRecordBidAsk] = useState(0);
    const [bidaskLength, setBidAskLength] = useState(1);
    const [resultBidAsk, setResultBidAsk] = useState([]);
    const [resultTickMatch, setResultTickMatch] = useState([]);

    const resetProcess = () => {
        previousBidAsk.current = {};
        previousTickMatchInRange.current = {};
        previousTickMatchActual.current = {};
        document.querySelectorAll('.change-tracking td').forEach(cell => {
            cell.style.backgroundColor = '';
        });
        setRows(initialRow);
        setRowsInRange(initialInRange);
        setRowsActual(initialActual);
        setCountRecordBidAsk(0);
        setIsPlaying(false);
        setTime2Show('00:00:00');
    }

    useEffect(() => {
        setInitialValues(e => ({
            ...e,
            start_date: start_date,
            end_date: start_date,
            start_time: start_time,
            end_time: '16:30',
            symbol: symbol
        }))
    }, [symbol, start_date]);

    useEffect(() => {
        console.log('start use effect', loading, start_date, start_time)
        const fetchData = async () => {
            setLoading(true);
            resetProcess();
            const values = {
                ...initialValues,
                start_date: formatDate(start_date),
                end_date: formatDate(start_date),
                start_time: formatTime(start_time),
                end_time: '16:30',
                symbol: symbol
            };
            console.log(values);

            const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
            console.log(response)
            if (response.status) {
                setLoading(false);
                setResultBidAsk(response.data.bidask);
                setResultTickMatch(response.data.tickmatch);
                setBidAskLength(response.data.bidask.length);
                setIsPlaying(true);

            }
        }
        if (symbol != '') {
            fetchData()
        }


    }, [onClickSearch, symbol, start_date])


    const handleSubmitForm = async () => {
        setLoading(true);
        resetProcess();
        // cleanDiv();
        const values = {
            ...initialValues,
            start_date: formatDate(initialValues.start_date),
            // end_date: formatDate(initialValues.end_date),
            end_date: formatDate(initialValues.start_date),
            start_time: formatTime(initialValues.start_time),
            end_time: formatTime(initialValues.end_time),
        };
        console.log(values);
        const response = await __fetching_data__({ url: `${base_api_url}/bidask_dejavu_report?${objectToQueryString(values)}`, method: "GET", headers: { "xsrf-token": csrfToken } });
        // console.log(response)
        if (response.status) {
            setLoading(false);
            setResultBidAsk(response.data.bidask);
            setResultTickMatch(response.data.tickmatch);
            setBidAskLength(response.data.bidask.length);
            setIsPlaying(true);

        }
        onPlotGraph(initialValues.symbol, initialValues.start_date)
    }
    const handleChange = (e) => {
        console.log('on change', e.target.value)
        const { name, value } = e.target
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const handleDateChange = (name, value) => {
        setInitialValues((prevData) => {
            let updatedValues = { ...prevData, [name]: value };

            if (name === 'start_date') {
                if (dayjs(value).isSame(dayjs(), 'day')) {
                    updatedValues.end_time = dayjs().isAfter(stEnTimeMax)
                        ? stEnTimeMax.format("HH:mm")
                        : dayjs().format("HH:mm");
                }
            }

            return updatedValues;
        });
    };
    const handleTimeChange = (name, value) => {
        setInitialValues((prevData) => ({
            ...prevData,
            [name]: `${String(dayjs(value).hour()).padStart(2, '0')}:${String(dayjs(value).minute()).padStart(2, '0')}`
        }));
    };
    useEffect(() => {
        if (dayjs(initialValues.start_date).isSame(dayjs(), 'day')) {
            const interval = setInterval(() => {
                const now = dayjs();
                const maxTime = dayjs().set('hour', 16).set('minute', 30);

                setInitialValues((prevData) => ({
                    ...prevData,
                    end_time: now.isAfter(maxTime) ? maxTime.format("HH:mm") : now.format("HH:mm"),
                }));
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [initialValues.start_date]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],//"#B6DBFB",//theme.palette.common.black,
            color: theme.palette.common.white,//"#1B7FD6",//theme.palette.common.white,
            fontWeight: 'bold',

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            backgroundColor: colors.blueAccent[500],
            color: theme.palette.common.white,
            fontWeight: 'bold',
        },
    }));

    const [isPlaying, setIsPlaying] = useState(false);

    const handleClickPlayPause = () => {
        setIsPlaying(!isPlaying);
    };
    const handleSliderChange = (event, newValue) => {
        setCountRecordBidAsk(newValue);

        if (resultBidAsk[newValue - 1]) {
            const newTime = dayjs(resultBidAsk[newValue - 1].Time).format('HH:mm:ss');
            setTime2Show(newTime);
        }
    };
    const NumberWithCommasAndDecimal = (number, decimalPlaces) => {
        const formattedNumber = decimalPlaces === 2
            ? Number(number).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            : Number(number).toLocaleString('en-US');

        return <div>{formattedNumber}</div>;
    }

    const [Time2Show, setTime2Show] = useState('00:00:00');

    const hightLow = (values, key) => {
        var min = Math.min(...values.map(item => parseFloat(item[key])));
        var max = Math.max(...values.map(item => parseFloat(item[key])));
        return {
            max: max,
            min: min
        }
    }
    const previousBidAsk = useRef({});
    const previousTickMatchInRange = useRef({});
    const previousTickMatchActual = useRef({});

    const extractAndConvertChildren = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (obj[key]) {
                if (typeof obj[key] === 'object' && obj[key].props && obj[key].props.children) {
                    const childrenValue = obj[key].props.children.replace(/,/g, '');
                    acc[key] = parseFloat(childrenValue);
                } else {
                    acc[key] = obj[key];
                }
            }

            return acc;
        }, {});
    };
    const compareData = (oldData, newData) => {
        const differences = [];

        oldData.forEach((oldRow, index) => {
            const newRow = newData[index];
            if (!newRow) return;
            const diff = {};

            for (const key in oldRow) {
                if (oldRow[key] !== newRow[key]) {
                    const oldVal = parseFloat(oldRow[key]) || 0;
                    const newVal = parseFloat(newRow[key]) || 0;
                    const change = newVal - oldVal;
                    const changeType = change === 0 ? "" : (change > 0 ? 'positive' : 'negative');
                    diff[key] = {
                        old: oldVal,
                        new: newVal,
                        change: change,
                        type: changeType,
                        rowIndex: index
                    };
                }
            }

            if (Object.keys(diff).length > 0) {
                differences.push({ index, diff });
            }
        });

        return differences;
    };
    const isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const [speedChanged, setSpeedChanged] = useState(false);
    const prevSpeedRef = useRef(initialValues.speed);
    useEffect(() => {
        let interval;
        let speed = initialValues.speed == 0 || initialValues.speed == null ? 1 : initialValues.speed;
        if (isPlaying) {
            interval = setInterval(async () => {
                speed = initialValues.speed == 0 || initialValues.speed == null ? 1 : initialValues.speed;
                if (speedChanged) {
                    setLoading(true);
                    await delay(2000);
                    setLoading(false);
                }
                let time_minute_search = 0;
                setCountRecordBidAsk(prevCount => {
                    if (prevCount >= bidaskLength - 1) {
                        setIsPlaying(false);
                        clearInterval(interval);
                        return prevCount;
                    } else {
                        let bidask_per_sec = resultBidAsk[prevCount];
                        time_minute_search = dayjs(bidask_per_sec['Time']).format('YYYY-MM-DD HH:mm');
                        let time2show = dayjs(bidask_per_sec['Time']).format('HH:mm:ss');
                        setTime2Show(time2show);

                        const dateToCheck = dayjs(formatDate(initialValues.start_date))
                        const comparisonDate = dayjs('2023-05-08');
                        const isBefore = dateToCheck.isBefore(comparisonDate);

                        document.querySelectorAll('.change-tracking td').forEach(cell => {
                            cell.style.backgroundColor = '';
                        });
                        let currentBidAsk = []
                        const updatedRows = [...rows];
                        if (isBefore) {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk5))
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk4))
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk3))
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk2))
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk1))
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal(parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) + parseFloat(bidask_per_sec.VolBid5)),
                                    "", "",
                                    NumberWithCommasAndDecimal(parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) + parseFloat(bidask_per_sec.VolAsk5)),
                                )
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren);
                                if (!isEmpty(previousBidAsk.current)) {
                                    let compData = compareData(previousBidAsk.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    // cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                    cell.style.backgroundColor = value.type === 'positive' ? '#3CB371' : '#D32F2F';
                                                }
                                            });
                                        })
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk.current = extractedRows
                            }
                        } else {
                            if (bidask_per_sec != undefined) {
                                currentBidAsk[0] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid1), NumberWithCommasAndDecimal(bidask_per_sec.Bid1, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask10, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk10))
                                currentBidAsk[1] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid2), NumberWithCommasAndDecimal(bidask_per_sec.Bid2, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask9, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk9))
                                currentBidAsk[2] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid3), NumberWithCommasAndDecimal(bidask_per_sec.Bid3, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask8, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk8))
                                currentBidAsk[3] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid4), NumberWithCommasAndDecimal(bidask_per_sec.Bid4, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask7, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk7))
                                currentBidAsk[4] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid5), NumberWithCommasAndDecimal(bidask_per_sec.Bid5, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask6, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk6))
                                currentBidAsk[5] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid6), NumberWithCommasAndDecimal(bidask_per_sec.Bid6, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask5, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk5))
                                currentBidAsk[6] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid7), NumberWithCommasAndDecimal(bidask_per_sec.Bid7, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask4, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk4))
                                currentBidAsk[7] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid8), NumberWithCommasAndDecimal(bidask_per_sec.Bid8, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask3, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk3))
                                currentBidAsk[8] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid9), NumberWithCommasAndDecimal(bidask_per_sec.Bid9, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask2, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk2))
                                currentBidAsk[9] = createData(NumberWithCommasAndDecimal(bidask_per_sec.VolBid10), NumberWithCommasAndDecimal(bidask_per_sec.Bid10, 2), NumberWithCommasAndDecimal(bidask_per_sec.Ask1, 2), NumberWithCommasAndDecimal(bidask_per_sec.VolAsk1))
                                currentBidAsk[12] = createData(
                                    NumberWithCommasAndDecimal(
                                        parseFloat(bidask_per_sec.VolBid1) + parseFloat(bidask_per_sec.VolBid2) + parseFloat(bidask_per_sec.VolBid3) + parseFloat(bidask_per_sec.VolBid4) +
                                        parseFloat(bidask_per_sec.VolBid5) + parseFloat(bidask_per_sec.VolBid6) + parseFloat(bidask_per_sec.VolBid7) + parseFloat(bidask_per_sec.VolBid8) +
                                        parseFloat(bidask_per_sec.VolBid9) + parseFloat(bidask_per_sec.VolBid10)
                                    ),
                                    "", "",
                                    NumberWithCommasAndDecimal(
                                        parseFloat(bidask_per_sec.VolAsk1) + parseFloat(bidask_per_sec.VolAsk2) + parseFloat(bidask_per_sec.VolAsk3) + parseFloat(bidask_per_sec.VolAsk4) +
                                        parseFloat(bidask_per_sec.VolAsk5) + parseFloat(bidask_per_sec.VolAsk6) + parseFloat(bidask_per_sec.VolAsk7) + parseFloat(bidask_per_sec.VolAsk8) +
                                        parseFloat(bidask_per_sec.VolAsk9) + parseFloat(bidask_per_sec.VolAsk10)
                                    ),
                                )
                                const extractedRows = currentBidAsk.map(extractAndConvertChildren);
                                if (!isEmpty(previousBidAsk.current)) {
                                    let compData = compareData(previousBidAsk.current, extractedRows);
                                    if (compData.length > 0) {
                                        compData.map((obj, index) => {
                                            const entries = Object.entries(obj.diff);
                                            entries.map(([key, value]) => {
                                                const cell = document.querySelector(`#cell-${value.rowIndex}-${key}`);
                                                if (cell) {
                                                    // cell.style.backgroundColor = value.type === 'positive' ? 'lightgreen' : 'lightcoral';
                                                    cell.style.backgroundColor = value.type === 'positive' ? '#3CB371' : '#D32F2F';
                                                }
                                            });
                                        })
                                    }
                                }
                                updatedRows[0] = currentBidAsk[0];
                                updatedRows[1] = currentBidAsk[1];
                                updatedRows[2] = currentBidAsk[2];
                                updatedRows[3] = currentBidAsk[3];
                                updatedRows[4] = currentBidAsk[4];
                                updatedRows[5] = currentBidAsk[5];
                                updatedRows[6] = currentBidAsk[6];
                                updatedRows[7] = currentBidAsk[7];
                                updatedRows[8] = currentBidAsk[8];
                                updatedRows[9] = currentBidAsk[9];
                                updatedRows[12] = currentBidAsk[12];
                                previousBidAsk.current = extractedRows
                            }
                        }
                        setRows(updatedRows);
                        if (resultTickMatch != undefined) {
                            let tickmatch_hight_low;
                            let tickmatch_per_min = resultTickMatch.filter((x) => {
                                return x.DtMinOfRec.substring(0, x.DtMinOfRec.length - 3) === time_minute_search;
                            })
                            let tickmatch_in_range = resultTickMatch.filter((x) => {
                                let x1 = dayjs(x.DtMinOfRec);
                                let x21 = dayjs(formatDate(initialValues.start_date) + " " + formatTime(initialValues.start_time));
                                let x22 = dayjs(formatDate(initialValues.end_date) + " " + formatTime(initialValues.end_time));
                                return (x1 >= x21 && x1 <= x22)
                            });
                            if (tickmatch_per_min.length > 0) {
                                tickmatch_hight_low = hightLow(tickmatch_per_min, 'Last');
                            }
                            const updatedRowsInRange = [...rowsInRange];
                            let InRagneTickmatch = createDataTickmatch(
                                tickmatch_in_range.length > 0 ? NumberWithCommasAndDecimal(tickmatch_in_range[0].Last, 2) : 0,
                                tickmatch_hight_low?.max ?? previousTickMatchInRange?.current?.high ?? 0,
                                tickmatch_hight_low?.min ?? previousTickMatchInRange?.current?.low ?? 0,
                                tickmatch_in_range.length > 0 ? NumberWithCommasAndDecimal(tickmatch_in_range[tickmatch_in_range.length - 1].Last, 2) : 0
                            )
                            updatedRowsInRange[0] = InRagneTickmatch;
                            previousTickMatchInRange.current = {
                                open: InRagneTickmatch.open && InRagneTickmatch.open.props ? parseFloat(InRagneTickmatch.open.props.children) : 0,
                                high: parseFloat(InRagneTickmatch.high),
                                low: parseFloat(InRagneTickmatch.low),
                                close: InRagneTickmatch.close && InRagneTickmatch.close.props ? parseFloat(InRagneTickmatch.close.props.children) : 0
                            }
                            setRowsInRange(updatedRowsInRange);

                            const updatedRowsActual = [...rowsActual];
                            let allTickmatchHighLow = hightLow(resultTickMatch, 'Last');
                            let ActualTickmatch = createDataTickmatch(
                                resultTickMatch.length > 0 ? NumberWithCommasAndDecimal(resultTickMatch[0].Last, 2) : 0,
                                allTickmatchHighLow?.max ?? previousTickMatchActual?.current?.high ?? 0,
                                allTickmatchHighLow?.min ?? previousTickMatchActual?.current?.low ?? 0,
                                resultTickMatch.length > 0 ? NumberWithCommasAndDecimal(resultTickMatch[resultTickMatch.length - 1].Last, 2) : 0
                            )
                            updatedRowsActual[0] = ActualTickmatch
                            previousTickMatchActual.current = {
                                open: ActualTickmatch.open && ActualTickmatch.open.props ? parseFloat(ActualTickmatch.open.props.children) : 0,
                                high: ActualTickmatch.high ? parseFloat(ActualTickmatch.high) : 0,
                                low: ActualTickmatch.low ? parseFloat(ActualTickmatch.low) : 0,
                                close: ActualTickmatch.close && ActualTickmatch.close.props ? parseFloat(ActualTickmatch.close.props.children) : 0
                            }
                            setRowsActual(updatedRowsActual)
                        }
                        return prevCount + 1;
                    }
                });
            }, 1000 / speed);
        }
        if (speed !== prevSpeedRef.current) {
            setSpeedChanged(true);
        } else {
            setSpeedChanged(false);
        }
        prevSpeedRef.current = speed;
        return () => clearInterval(interval);
    }, [isPlaying, countRecordBidAsk, initialValues.speed]);

    return (
        <Box>
            {/* <Preloading open={loading} /> */}
            <Box p="0" display="Box"
                sx={{
                    // height: {
                    //     xs: '50vh', // height for extra-small screens
                    //     sm: '60vh', // height for small screens
                    //     md: '90vh', // height for medium screens
                    //     lg: '90vh', // height for large screens
                    //     xl: '90vh', // height for extra-large screens
                    // },
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Container 
                disableGutters='true' //Disable auto padding for Container
                 maxWidth="xl" sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Paper
                        sx={{
                            flex: 1,
                            overflowY: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            // p: 2,
                            // maxHeight: {
                            //     // xs: '50vh', // height for extra-small screens
                            //     // sm: '60vh', // height for small screens
                            //     xs: '90vh',
                            //     sm: '90vh',
                            //     md: '90vh', // height for medium screens
                            //     lg: '90vh', // height for large screens
                            //     xl: '90vh', // height for extra-large screens
                            // },
                        }}
                    >
                        <div style={{ marginTop: '10px' }}>
                            <form>
                                <div style={{
                                    // display: 'none'
                                }}>
                                    <div style={{ display: 'flex', marginLeft: '20px', marginRight: '20px', marginTop: '20px' }}>
                                        <TextField
                                            fullWidth
                                            id="symbol"
                                            name="symbol"
                                            label="Symbol"
                                            variant="outlined"
                                            required
                                            size='small'
                                            value={initialValues.symbol}
                                            onChange={handleChange}
                                        />

                                        <div style={{ marginLeft: '10px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <DatePicker
                                                    id="start_date"
                                                    name="start_date"
                                                    label="Start Date"
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    value={initialValues.start_date}
                                                    maxDate={today_value}
                                                    onChange={(value) => handleDateChange('start_date', value)}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <div style={{ marginLeft: '10px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <DatePicker
                                                    id="end_date"
                                                    name="end_date"
                                                    label="End Date"
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    value={initialValues.end_date}
                                                    maxDate={today_value}
                                                    onChange={(value) => handleDateChange('end_date', value)}
                                                    disabled
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', marginLeft: '20px', marginRight: '20px', marginTop: '10px' }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <TimePicker
                                                id="start_time"
                                                name="start_time"
                                                label="Start Time"
                                                slotProps={{ textField: { size: 'small' } }}
                                                defaultValue={initialValues.start_time}
                                                ampm={false}
                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                onChange={(value) => handleTimeChange('start_time', value)}

                                            />
                                        </LocalizationProvider>

                                        <div style={{ marginLeft: '10px' }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                                <TimePicker
                                                    id="end_time"
                                                    name="end_time"
                                                    label="End Time"
                                                    slotProps={{ textField: { size: 'small' } }}
                                                    value={dayjs(initialValues.end_time, "HH:mm")}
                                                    ampm={false}
                                                    minTime={stEnTimeMin.subtract(1, 'minute')}
                                                    maxTime={stEnTimeMax.add(1, 'minute')}
                                                    onChange={(value) => handleTimeChange('end_time', value)}
                                                    disabled={dayjs(initialValues.start_date).isSame(dayjs(), 'day')}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <div style={{ marginLeft: '10px' }}>
                                            <TextField
                                                fullWidth
                                                id="speed"
                                                name="speed"
                                                label="Speed"
                                                variant="outlined"
                                                size='small'
                                                value={initialValues.speed}
                                                onChange={handleChange}
                                                type='number'
                                            // inputProps={{ step: 2 }}
                                            />
                                        </div>

                                        <div style={{ marginLeft: '10px' }}>
                                            <Button variant="contained" fullWidth
                                                sx={{
                                                    backgroundColor: colors.blueAccent[500]
                                                }}
                                                type="button" onClick={handleSubmitForm}
                                            >
                                                Search
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                {/* <Grid container spacing={0} alignItems="center" justifyContent="center" xs={12} md={12} lg={12}
                                    sx={{
                                        // marginBottom: "1rem",
                                        padding: '0 10px 0 10px',
                                        gap: '10px'
                                    }}>
                                    <Grid item xs={4} md={10} lg={3.5}>
                                        <TextField
                                            fullWidth
                                            id="symbol"
                                            name="symbol"
                                            label="Symbol"
                                            variant="outlined"
                                            required
                                            size='small'
                                            value={initialValues.symbol}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={3} lg={3.5} >
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DatePicker
                                                id="start_date"
                                                name="start_date"
                                                label="Start Date"
                                                slotProps={{ textField: { size: 'small' } }}
                                                value={initialValues.start_date}
                                                maxDate={today_value}
                                                onChange={(value) => handleDateChange('start_date', value)}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} md={3} lg={3.5} >
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <DatePicker
                                                id="end_date"
                                                name="end_date"
                                                label="End Date"
                                                slotProps={{ textField: { size: 'small' } }}
                                                value={initialValues.end_date}
                                                maxDate={today_value}
                                                onChange={(value) => handleDateChange('end_date', value)}
                                                disabled
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} alignItems="center" justifyContent="center" xs={12} md={12}
                                    sx={{
                                        padding: '10px',
                                        gap: '10px'
                                    }}>
                                    <Grid item xs={4} md={3} lg={3.5} sx={{ flexGrow: 1 }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <TimePicker
                                                id="start_time"
                                                name="start_time"
                                                label="Start Time"
                                                slotProps={{ textField: { size: 'small' } }}
                                                defaultValue={initialValues.start_time}
                                                ampm={false}
                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                onChange={(value) => handleTimeChange('start_time', value)}

                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} md={3} lg={3.5} sx={{ flexGrow: 1 }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                            <TimePicker
                                                id="end_time"
                                                name="end_time"
                                                label="End Time"
                                                slotProps={{ textField: { size: 'small' } }}
                                                value={dayjs(initialValues.end_time, "HH:mm")}
                                                ampm={false}
                                                minTime={stEnTimeMin.subtract(1, 'minute')}
                                                maxTime={stEnTimeMax.add(1, 'minute')}
                                                onChange={(value) => handleTimeChange('end_time', value)}
                                                disabled={dayjs(initialValues.start_date).isSame(dayjs(), 'day')}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={4} md={2} lg={1.4} sx={{ flexGrow: 1 }}>
                                        <TextField
                                            fullWidth
                                            id="speed"
                                            name="speed"
                                            label="Speed"
                                            variant="outlined"
                                            size='small'
                                            value={initialValues.speed}
                                            onChange={handleChange}
                                            type='number'
                                        // inputProps={{ step: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={2} lg={1}>
                                        <Button variant="contained" fullWidth
                                            sx={{
                                                backgroundColor: colors.blueAccent[500]
                                            }}
                                            type="button" onClick={handleSubmitForm}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid> */}
                            </form>
                            <Grid container display="flex" alignItems="center" justifyContent="center">
                                <Grid item md={8}>
                                    <Box display="flex" alignItems="center" justifyContent="center" className='time-label'>
                                        <Typography variant={isMobile || isTablet ? 'h4' : 'h3'} sx={{ fontWeight: 'bold', padding: isMobile || isTablet ? '0px' : '10px 0' }}>{Time2Show}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <div style={{ textAlign: 'center', borderRadius: '10px', }}>
                                <TableContainer component={Paper} sx={{ width: '100%', padding: '0px 1vw', backgroundColor: 'transparent' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: theme.palette.table.thead, borderBottomStyle: 'hidden', }}>
                                                <TableCell sx={{fontWeight: 'bold', fontSize: '0.9rem'}} align="center">Vol BID</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', fontSize: '0.9rem'}} align="center">BID</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', fontSize: '0.9rem'}} align="center">ASK</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', fontSize: '0.9rem'}} align="center">Vol ASK</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, index) => {
                                                if (index < 10) {
                                                    return (
                                                        <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even, borderBottomStyle: 'hidden', }}>
                                                            <TableCell id={`cell-${index}-vol_bid`} align="center" sx={{ padding: '4px' }}>{row.vol_bid}</TableCell>
                                                            <TableCell id={`cell-${index}-bid`} align="center" sx={{ padding: '4px' }}>{row.bid}</TableCell>
                                                            <TableCell id={`cell-${index}-ask`} align="center" sx={{ padding: '4px' }}>{row.ask}</TableCell>
                                                            <TableCell id={`cell-${index}-vol_ask`} align="center" sx={{ padding: '4px' }}>{row.vol_ask}</TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                                if (index === 11) {
                                                    return (
                                                        <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even, borderBottomStyle: 'hidden', }} >
                                                            <TableCell id={`cell-${index}-vol_bid`} align="center" sx={{ padding: '4px' }}>{row.vol_bid}</TableCell>
                                                            <TableCell id={`cell-${index}-bid`} align="center" sx={{ padding: '4px' }}>{row.bid}</TableCell>
                                                            <TableCell id={`cell-${index}-ask`} align="center" sx={{ padding: '4px' }}>{row.ask}</TableCell>
                                                            <TableCell id={`cell-${index}-vol_ask`} align="center" sx={{ padding: '4px' }}>{row.vol_ask}</TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                                return (
                                                    <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.table.odd : theme.palette.table.even, borderBottomStyle: 'hidden',  }}>
                                                        <TableCell id={`cell-${index}-vol_bid`} align="center" style={{ padding: '5px' }}>{row.vol_bid}</TableCell>
                                                        <TableCell align="center" style={{ padding: '4px' }}>{row.bid}</TableCell>
                                                        <TableCell align="center" style={{ padding: '4px' }}>{row.ask}</TableCell>
                                                        <TableCell id={`cell-${index}-vol_ask`} align="center" style={{ padding: '5px' }}>{row.vol_ask}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <table className='bidask_table' style={{ width: '100%', padding: '0px 1vw 0px 1vw', borderSpacing: '0', }}>
                                            <thead>
                                                <tr style={{ backgroundColor: theme.palette.table.thead }}>
                                                    <td>Vol BID</td>
                                                    <td>Vol BID</td>
                                                    <td>Vol BID</td>
                                                    <td>Vol BID</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, index) => {
                                                    if (index < 10) {
                                                        return (
                                                            <tr key={index}>
                                                                <td id={`cell-${index}-vol_bid`} align="center" style={{ padding: '3px' }}>{row.vol_bid}</td>
                                                                <td id={`cell-${index}-bid`} align="center" style={{ padding: '3px' }}>{row.bid}</td>
                                                                <td id={`cell-${index}-ask`} align="center" style={{ padding: '3px' }}>{row.ask}</td>
                                                                <td id={`cell-${index}-vol_ask`} align="center" style={{ padding: '3px' }}>{row.vol_ask}</td>
                                                            </tr>
                                                        );
                                                    } if (index === 11) {
                                                        return (
                                                            <tr key={index}>
                                                                <td id={`cell-${index}-vol_bid`} align="center" style={{ padding: '3px' }}>{row.vol_bid}</td>
                                                                <td id={`cell-${index}-bid`} align="center" style={{ padding: '3px' }}>{row.bid}</td>
                                                                <td id={`cell-${index}-ask`} align="center" style={{ padding: '3px' }}>{row.ask}</td>
                                                                <td id={`cell-${index}-vol_ask`} align="center" style={{ padding: '3px' }}>{row.vol_ask}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <tr key={index}>
                                                                <td id={`cell-${index}-vol_bid`} align="center" style={{ padding: '3px' }}>{row.vol_bid}</td>
                                                                <td align="center" style={{ padding: '3px' }}>{row.bid}</td>
                                                                <td align="center" style={{ padding: '3px' }}>{row.ask}</td>
                                                                <td id={`cell-${index}-vol_ask`} align="center" style={{ padding: '3px' }}>{row.vol_ask}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                            </tbody>
                                        </table> */}
                            </div>
                            <div style={{ marginLeft: '20px', marginRight: '10px', }}>
                                <Grid container xl={12} lg={12} md={12} xs={12} sm={12} sx={{ justifyContent: 'center' }} >
                                    <Grid item xl={8} lg={8} md={10} xs={8} sm={8}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', padding: isMobile || isTablet ? '0px' : '10px 0' }}>
                                            <Slider
                                                defaultValue={1}
                                                aria-label="Time Slider"
                                                valueLabelDisplay="auto"
                                                min={1}
                                                max={bidaskLength}
                                                value={countRecordBidAsk}
                                                onChange={handleSliderChange}
                                                valueLabelFormat={(value) => `${Time2Show}`}
                                            // sx={{
                                            //     width: '100%',
                                            //     margin: '0 auto',
                                            // }}
                                            />
                                            <IconButton onClick={handleClickPlayPause}>
                                                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />} {/* Render play or pause icon based on the value of isPlaying */}
                                            </IconButton>
                                        </Box>

                                    </Grid>

                                    <Box >

                                    </Box>
                                </Grid>

                            </div>
                        </div>
                    </Paper >
                </Container>
            </Box>
        </Box >
    );
};

export default BidAskComponent2;
